import React, { ButtonHTMLAttributes } from 'react';

// Define props for the ButtonOutlined component
type ButtonOutlinedProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    customClass?: string; // Optional prop for additional classes
};

// ButtonOutlined component
const ButtonOutlined: React.FC<ButtonOutlinedProps> = ({ children, customClass, ...rest }) => {
    // Define the base button classes
    const buttonClasses =
        'px-4 py-1.5 flex justify-center items-center gap-3 border-2 cursor-pointer max-w-max  border-primary-500 self-center';

    return (
        // Button element with base classes and custom classes if provided
        <button
            {...rest} // Spread the remaining button props
            className={`${buttonClasses} ${customClass}`}
        >
            {/* Render the button text */}
            {children}
        </button>
    );
};

// Export the ButtonOutlined component
export default ButtonOutlined;
