import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertb_partnershipdata = (
    year: string,
    { taxprep_account_id, meta_data, business_partnership_llc_details }: any,
) => {
    const regEx = /^new/;
    const data = business_partnership_llc_details
        .map((item: any) => {
            let temp = item;
            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: meta_data.irs_account_id,
                    taxprep_account_id: taxprep_account_id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }
            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });
    return apiTaxPrep.post(`/businessPartnershipLlcByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data: data,
        taxprep_year: year,
    });
};

const b_partnershipForm: FormInterface = [
    {
        type: fieldTypes.RADIO,
        id: 'farm_income_losses',
        label: 'Do you have any farm income or losses?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'principal_crop',
                            label: 'Principal crop or activity',
                            placeholder: 'john',
                        },
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'code',
                            label: 'Enter Code',
                            placeholder: 'john',
                        },
                        {
                            type: fieldTypes.RADIO,
                            id: 'account_method',
                            label: 'Account Method',
                            options: [
                                { value: '1', label: 'Cash' },
                                { value: '0', label: 'Accurual' },
                            ],
                        },
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'employer_ein',
                            label: 'Employer ID (EIN)',
                            placeholder: 'john',
                        },
                        {
                            type: fieldTypes.RADIO,
                            id: 'materially_participate_year',
                            label: 'Did you “materially participate” in the operation of this business during 2022?',
                            options: [
                                { value: '1', label: 'Yes' },
                                { value: '0', label: 'No' },
                            ],
                        },
                    ],
                    inline: true,
                },
            ],
        },
    },
];

const b_partnership: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_partnership_llc_details',
        label: 'Do you have any Partnership LLC/S-Corp?',
        header: 'Bussiness',
        displayColumns: [
            {
                name: 'Farm income or losses?',
                key: 'farm_income_losses',
            },
        ],
        formStructure: b_partnershipForm,
    },
];

export const page: PageInputInterface = {
    address: 'business_partnership_llc_details',
    name: 'Partnership LLC/S-Corp',
    sectionId: enumSections.BUSINESS_INFORMATION,

    formStructure: b_partnership,
    save: upsertb_partnershipdata,
};
