/**
 * Parent UI Component for wrapping fields into
 **/

import classNames from 'classnames';
import { useState } from 'react';
import { useFormState } from '../../context/form';

interface ParentGroupProps {
    className?: string;
    styles?: any;
    error?: string;
}
interface ParentGroupWithLabelProps {
    className?: string;
    styles?: any;
    error?: string;
    id: string;
    label: string;
    value: any;
}

function isLabel(
    field: ParentGroupProps | ParentGroupWithLabelProps,
): field is ParentGroupWithLabelProps {
    return (field as ParentGroupWithLabelProps).id !== undefined;
}

const ParentGroup: React.FC<ParentGroupWithLabelProps | ParentGroupProps> = (props) => {
    if (isLabel(props)) {
        const { id, children, className, label, value, error } = props;

        return (
            <div
                className={classNames('m-2 relative flex flex-col', className)}
                style={props.styles}
            >
                <div
                    className={classNames('pointer-events-none text-xs', {
                        'text-red-600': error,
                        'text-gray-500': !error,
                    })}
                >
                    {label}
                </div>
                <div className="flex-grow w-10" />
                {children}
                {error && (
                    <div className="text-red-600 px-3 text-xs font-bold bg-red-100">{error}</div>
                )}
            </div>
        );
    } else {
        const { children, className } = props;
        return (
            <div className={classNames('m-1 relative flex-col', className)} style={props.styles}>
                {children}
                {props.error && (
                    <div className="text-red-600 px-3 text-xs font-bold bg-red-100">
                        {props.error}
                    </div>
                )}
            </div>
        );
    }
};

export default ParentGroup;
