import { upsertEmploymentData } from '../../api/finance';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const employmentForm: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'employee',
        label: 'For',
        placeholder: 'Who is this for',
        options: [
            { value: 'Taxpayer', label: 'Taxpayer' },
            { value: 'Spouse', label: 'Spouse' },
            { value: 'Dependent', label: 'Dependent' },
        ],
    },
    // {
    //     type: fieldTypes.DROPDOWN,
    //     id: 'wage_source',
    //     label: 'Source',
    //     placeholder: 'Select Employment Source',
    //     options: [
    //         { value: 'Stated', label: 'Stated' },
    //         { value: 'Pay Stub', label: 'Pay Stub' },
    //         { value: 'Return', label: 'Return' },
    //         { value: 'W+I', label: 'W+I' },
    //         { value: 'Other', label: 'Other' },
    //     ],
    // },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'employer_name',
        label: 'Employer Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'employer_phone',
        label: 'Employer Phone',
        placeholder: 'John',
        formatFunction: phone,
    },
    {
        type: fieldTypes.RADIO,
        id: 'allow_contact_at_work',
        label: 'Does employer allow contact at work?',
        options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
        ],
    },
    { type: fieldTypes.SEPERATOR, heading: 'Employer Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        span: 3,
        id: 'employer_address',
        label: 'Employer Address',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'employer_city',
        label: 'Employer City',
        placeholder: 'John',
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'employer_state',
        label: 'Employer State',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'employer_zip',
        label: 'Employer Zip',
        placeholder: 'John',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'occupation',
        label: 'Occupation',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'num_exemptions_claimed',
        label: 'No. Claimed on W4',
        placeholder: 'John',
    },

    { type: fieldTypes.SEPERATOR, heading: 'Time at Job' },
    {
        type: fieldTypes.TEXT_INPUT,
        inputType: 'number',
        id: 'years_with_employer',

        // label: 'Time at Job (Years)',
        placeholderText: 'Years',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        inputType: 'number',
        id: 'months_with_employer',

        // label: 'Time at Job (Months)',
        placeholderText: 'Months',
    },

    {
        type: fieldTypes.RADIO,
        id: 'has_ownership_interest',
        label: 'Do you have an ownership interest in this business?',
        options: [
            { value: 'y', label: 'Yes' },
            { value: 'n', label: 'No' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: 'y',
                    form: [
                        {
                            type: fieldTypes.DROPDOWN,
                            id: 'ownership_interest_type',
                            label: 'Interest Type',
                            options: [
                                { value: 'Partner', label: 'Partner' },
                                { value: 'Sole Proprietor', label: 'Sole Proprietor' },
                                { value: 'Other', label: 'Other' },
                            ],
                            placeholder: 'Partner',
                        },
                    ],
                },
            ],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.DROPDOWN,
        id: 'pay_period',
        label: 'Pay Period',
        placeholder: '$10000',
        options: [
            { label: 'Weekly', value: 'Weekly' },
            { label: 'Bi-Weekly', value: 'Bi-Weekly' },
            { label: 'Semi Monthly', value: 'Semi Monthly' },
            { label: 'Monthly', value: 'Monthly' },
            { label: 'Other', value: 'Other' },
        ],
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'gross_per_pay_period',
        label: 'Gross',
        placeholder: '$10000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'fed_taxes_per_pay_period',
        label: 'Federal',
        placeholder: '$10000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'medicare_per_pay_period',
        label: 'Medicare',
        placeholder: '$10000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'social_security_per_pay_period',
        label: 'Social Security',
        placeholder: '$10000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'state_taxes_per_pay_period',
        label: 'State',
        placeholder: '$10000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'local_taxes_per_pay_period',
        label: 'Local',
        placeholder: '$10000',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'net_month',
        label: 'Net/Month',
        placeholder: '$10000',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'employment_data',
        label: 'Are you, your spouse, or any dependents employed?',
        header: 'Employment Details',
        displayColumns: [
            {
                name: 'For',
                key: 'employee',
            },
            {
                name: 'Employer',
                key: 'employer_name',
            },
            {
                name: 'Gross',
                key: 'gross_per_pay_period',
            },
        ],
        formStructure: employmentForm,
    },
];

export const page: PageInputInterface = {
    address: 'employment',
    name: 'Employment Details',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertEmploymentData,
};
