import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
/* eslint-disable import/no-anonymous-default-export */
import { PageManager, Table } from '../Tables/Table';

import { PaymentMetricsColumns, TransactionMetricsColumns } from '../ColumnHeader';
import useTranscript from '../../../../api/hooks/useTranscript';
import { useState } from 'react';
import { transcriptApi } from '../../../../api/transcripts';
import { useQuery } from 'react-query';

export const UserPaymentTransaction: React.FC = () => {
    let { irs_account_id, type } = useParams();

    const [errMessage, setErrMessage] = useState<string | null>(null);

    // const { data: complianceData, isLoading: complianceLoading } = useTranscript(
    //     '/getComplianceData',
    //     { irs_account_id: irs_account_id, taxpayer_type: type },
    //     {
    //         enabled: !!irs_account_id,
    //         //refetchOnWindowFocus: false
    //     },
    // );

    const fetchTaxometricData = async (irs_account_id: string | undefined) => {
        try {
            const response = await transcriptApi.get(
                `/getComplianceData?irs_account_id=${irs_account_id}&taxpayer_type=${type}`,
            );
            console.log(response, 'response');
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.data.error) {
                setErrMessage(err.response.data.error);
            }
            console.log('Error fetching taxometric data:', err.response.data.error);
            throw err;
        }
    };

    const { data: complianceData, isLoading: complianceLoading } = useQuery(
        ['compliance', irs_account_id, type],
        () => fetchTaxometricData(irs_account_id),
        {
            enabled: !!irs_account_id,
        },
    );

    return (
        <div className="p-6 flex flex-col gap-4 border-0 border-[#CCCCCC] pb-10 ">
            <span className="font-semibold text-xl">Payment Metrics</span>
            <>
                {complianceLoading ? (
                    <div className="w-full">Loading...</div>
                ) : complianceData ? (
                    <Table data={complianceData} columns={PaymentMetricsColumns} />
                ) : (
                    errMessage && <div>{errMessage}</div>
                    // errMessage && <div>Error: Failed to fetch compliance data</div>
                )}
            </>
        </div>
    );
};
export const UserTransaction: React.FC = () => {
    let { irs_account_id, type } = useParams();

    const [errMessage, setErrMessage] = useState<string | null>(null);

    // const { data: complianceData, isLoading: complianceLoading } = useTranscript(
    //     '/getComplianceData',
    //     { irs_account_id: irs_account_id, taxpayer_type: type },
    //     {
    //         enabled: !!irs_account_id,
    //         //refetchOnWindowFocus: false
    //     },
    // );

    const fetchTransactionsData = async (irs_account_id: string | undefined) => {
        try {
            const response = await transcriptApi.get(
                `/getTransactionsData?irs_account_id=${irs_account_id}&taxpayer_type=${type}`,
            );
            console.log(response, 'response');
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.data.error) {
                setErrMessage(err.response.data.error);
            }
            console.log('Error fetching transactions data:', err.response.data.error);
            throw err;
        }
    };

    const { data: transactionsData, isLoading: transactionsLoading } = useQuery(
        ['transactions', irs_account_id, type],
        () => fetchTransactionsData(irs_account_id),
        {
            enabled: !!irs_account_id,
        },
    );

    return (
        <div className="p-6 flex flex-col gap-4 border-0 border-[#CCCCCC] pb-10 w-full">
            <span className="font-semibold text-xl">Transactions</span>
            <>
                {
                    transactionsLoading ? (
                        <div className="w-full">Loading...</div>
                    ) : transactionsData ? (
                        <Table data={transactionsData} columns={TransactionMetricsColumns} />
                    ) : errMessage ? (
                        <div>{errMessage}</div>
                    ) : (
                        <div>Error: Failed to fetch Transactions data</div>
                    )
                    // errMessage && <div>Error: Failed to fetch compliance data</div>
                }
            </>
        </div>
    );
};
