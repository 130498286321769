import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { ssn } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertFilingStatus = async (year: string, params: any) => {
    const { meta_data, irs_account_id, id } = params;
    const data = params;

    return await apiTaxPrep.put(`/updateTaxprep/${id}`, {
        irs_account_id: irs_account_id,
        meta_data: { ...meta_data, taxprep_account_id: id, irs_account_id: irs_account_id },
        data,
        taxprep_year: year,
    });
};

const spouseForm: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Spouse Details' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'individual_child_name',
        label: 'Enter your child’s name if the qualifying individual is your child but not your dependent',
        placeholder: 'John',
        span: 2,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'middle_initial',
        label: 'Your first name and middle initial ',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'last_business_name',
        label: 'Last name ',
        placeholder: 'Doe',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ssn_ein',
        label: 'Your social security number',
        placeholder: '778-62-8144',
        formatFunction: ssn,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_first_name',
        label: 'spouse’s first name and middle initial',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_last_name',
        label: 'Spouse’s Last name',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_ssn',
        label: 'Spouse’s social security number ',
        placeholder: '778-62-8144',
        formatFunction: ssn,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_home_address',
        label: 'Home address',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_apt_no',
        label: 'Apt. no',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_city',
        label: 'City, town, or post office',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_zip_code    ',
        label: 'Zip code',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.RADIO,
        id: 'liable',
        label: 'Do you have addresses from Foreign country?',
        options: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'foreign_county_name',
                            label: 'Name of foreign country ',
                            placeholder: 'Doe',
                        },
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'foreign_province',
                            label: 'Foreign province/state/county',
                            placeholder: 'Doe',
                        },
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'foreign_postal_code',
                            label: 'Foreign postal code',
                            placeholder: 'Doe',
                        },
                    ],
                },
            ],
        },
    },
    { type: fieldTypes.TERMINAL },
];

const jointlyForm: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Spouse Details' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_first_name',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_last_name',
        label: 'Last Name',
        placeholder: 'Doe',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_ssn',
        label: 'SSN',
        placeholder: '778-62-8144',
        formatFunction: ssn,
    },
    { type: fieldTypes.TERMINAL },
];

const form: FormInterface = [
    {
        type: fieldTypes.RADIO,
        crossroadVariant: true,
        id: 'marital_status',
        label: 'Filing Status',
        options: [
            { label: 'Single', value: 'single' },
            { label: 'Married, Filing Jointly', value: 'jointly' },
            { label: 'Married, Filing Separately', value: 'seperately' },
            { label: 'Head of Household', value: 'hoh' },
            { label: 'Qualifying Widower', value: 'qw' },
        ],
        span: 2,
        branches: {
            paths: [
                {
                    value: 'single',
                    form: [{ type: fieldTypes.TERMINAL, noAction: true }],
                },
                {
                    value: 'jointly',
                    form: jointlyForm,
                },
                {
                    value: 'seperately',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'spouse_first_name',
                            label: 'Enter the name of your spouse',
                            placeholder: 'John',
                        },
                        { type: fieldTypes.TERMINAL },
                    ],
                },
                {
                    value: 'hoh',
                    form: spouseForm,
                },
                {
                    value: 'qw',
                    form: spouseForm,
                },
            ],
        },
    },
];

export const page: PageInputInterface = {
    address: 'filing_status',
    name: 'Filing Status',
    sectionId: enumSections.YOUR_INFORMATION,

    formStructure: form,
    save: upsertFilingStatus,
};
