import { upsertBusinessData } from '../../api/business';
import { build } from '../../config';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';
import { businessBasicform } from './2_0_basic_information';

const optional: FormInterface = build.include_433_business
    ? [
          {
              type: fieldTypes.DROPDOWN,
              id: 'display_on_433',
              label: 'Include in 433 Form',
              options: [
                  { value: 'y', label: 'Yes' },
                  { value: 'n', label: 'No' },
              ],
              placeholder: 'Include in 433 Form',
          },
      ]
    : [];

const optionalDisplay: Array<{
    name: string;
    key: string;
    span?: number | undefined;
}> = build.include_433_business
    ? [
          {
              name: 'Include in 433 Form',
              key: 'display_on_433',
          },
      ]
    : [];

const businessForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'name',
        label: 'Business Name',
        placeholder: 'Tony Pharmaceutical',
        span: 2,
    },
    ...optional,
];

export const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_data',
        label: 'Do you own any businesses?',
        header: 'Businesses Information',
        formStructure: businessForm,
        displayColumns: [
            {
                name: 'Name of Business',
                key: 'name',
            },
            ...optionalDisplay,
        ],
        dynamicSave: {
            saveFunction: upsertBusinessData,
            sectionId: enumSections.BUSINESS_INFORMATION,
        },
        dynamicSaveMessage: 'Business has been updated successfully',
    },
];

export const page: PageInputInterface = {
    address: 'business_home',
    name: 'Business Information',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertBusinessData,
};
