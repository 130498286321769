import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    selectedSections: [
        {
            name: 'dependent_data',
            subName: 'I Have',
            dispName: 'Dependents',
            isActive: false,
            blockedSections: ['dependent_data'],
            blockedFormFields: [],
        },
        {
            name: 'income_details_data',
            subName: 'I have an',
            dispName: 'Income from an Employer',
            isActive: false,
            blockedSections: ['income_details_data'],
            blockedFormFields: [],
        },
        {
            name: 'divident_income_data',
            subName: 'I have',
            dispName: 'Dividents',
            isActive: false,
            blockedSections: ['divident_income_data'],
            blockedFormFields: [],
        },
        {
            name: 'donations_data',
            subName: 'I made',
            dispName: 'Donations',
            isActive: false,
            blockedSections: [],
            blockedFormFields: [],
        },
        {
            name: 'business_data',
            subName: 'I own',
            dispName: 'Bussiness',
            isActive: false,
            blockedSections: [
                'business_data',
                'income_data',
                'expense_data',
                'cost_of_goods_sold_details',
                'business_vehicle_details',
                'business_partnership_llc_details',
            ],
            blockedFormFields: [],
        },
        {
            name: 'foreign_account_data',
            subName: 'I have',
            dispName: 'Foreign Accounts',
            isActive: false,
            blockedSections: ['foreign_account_data'],
            blockedFormFields: [],
        },
        {
            name: 'interest_income_data',
            subName: 'I invest in',
            dispName: 'Stocks, crypto or own rental property',
            isActive: false,
            blockedSections: ['interest_income_data'],
            blockedFormFields: [],
        },
        {
            name: 'itemized_deduction_data',
            subName: 'I have',
            dispName: 'Itemized Deductions',
            isActive: false,
            blockedSections: ['itemized_deduction_data'],
            blockedFormFields: [],
        },
        {
            name: 'farm_sections',
            subName: 'I have',
            dispName: 'Farming Income',
            isActive: false,
            blockedSections: [
                'business_farm_income_cash_details',
                'business_expense_cash_accural_details',
                'business_farm_income_accural_details',
            ],
            blockedFormFields: [],
        },
    ],
    formState: {},
    serverState: {},
    serverMetaState: {},
    errorState: {},
    metaState: {},
    pageState: {},
};

const taxPrepSlice = createSlice({
    name: 'taxPrep',
    initialState,
    reducers: {
        formStateStore: (state, action: PayloadAction<{ data: any }>) => {
            state.formState = { ...state.formState, ...action.payload.data };
        },
        serverStateStore: (state, action: PayloadAction<{ data: any }>) => {
            state.serverState = { ...state.serverState, ...action.payload.data };
        },
        serverMetaStateStore: (state, action: PayloadAction<{ data: any }>) => {
            state.serverMetaState = { ...state.serverMetaState, ...action.payload.data };
        },
        errorStateStore: (state, action: PayloadAction<{ state: any }>) => {
            state.errorState = { ...state.errorState, ...action.payload.state };
        },
        metaStateStore: (state, action: PayloadAction<{ state: any }>) => {
            state.metaState = { ...state.metaState, ...action.payload.state };
        },
        pageStateStore: (state, action: PayloadAction<{ state: any }>) => {
            state.pageState = { ...state.pageState, ...action.payload.state };
        },
        sectionsStore: (state, action: PayloadAction<{ state: any }>) => {
            state.selectedSections = action.payload.state;
        },
    },
});

export const {
    formStateStore,
    errorStateStore,
    metaStateStore,
    serverStateStore,
    serverMetaStateStore,
    sectionsStore,
    pageStateStore,
} = taxPrepSlice.actions;
export default taxPrepSlice.reducer;
