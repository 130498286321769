import axios from 'axios';

const api = axios.create({
    baseURL: 'http://localhost:5001/buildmytaxes/us-central1',
});

export const helloWorld = async (): Promise<{ success: boolean; [key: string]: any }> => {
    const { data } = await api.get(`/helloWorld`);
    return data;
};

export const getAccessToken = async (): Promise<{ success: boolean; [key: string]: any }> => {
    const { data } = await api.get(`/accessToken`);
    return data;
};
