import { useState } from 'react';
import ButtonOutlined from './ButtonOutlined';
import InputGroup from '../../../admin/InputGroup';
import { fieldTypes } from '../../../../utils/enums';
import DateTimePicker from '../../../../components/admin/Calendar';
import { useMutation, useQueryClient } from 'react-query';
import { editClient } from '../../../../api/admin';
import { ssn, phone as phoneFormat } from '../../../../utils/format';
import { showToast } from '../../Toaster';
import { useTranslation } from 'react-i18next';

type Props = {
    data: any;
    isLoading: boolean;
    user_id: string;
    taxPrepYears: any;
};

const SpouseProfile: React.FC<Props> = ({ data, isLoading, user_id, taxPrepYears }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [firstName, setFirstName] = useState(data?.spouse_first_name);
    const [lastName, setLastName] = useState(data?.spouse_last_name);
    const [email, setEmail] = useState(data?.spouse_email);
    const [dob, setDob] = useState<string | undefined>(data?.spouse_birth_dt);
    const [marriageDate, setMarriageDate] = useState<string | undefined>(data?.marriage_date);
    const [phone, setPhone] = useState(data?.spouse_cell_phone);
    const [SSN, setSsn] = useState(data?.spouse_ssn);
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const [hide, setHide] = useState(true);
    const { t } = useTranslation();
    const toggleEdit = () => {
        setIsEditable(!isEditable);
    };

    const queryClient = useQueryClient();
    //mutation to edit client
    const mutation = useMutation(editClient, {
        onSuccess: async () => {
            await queryClient.invalidateQueries('TaxprepList');
            showToast('Client has been updated successfully!', 'success');
            toggleEdit();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const handleSubmit = () => {
        const formData: any = {
            spouse_first_name: firstName,
            spouse_last_name: lastName,
            spouse_email: email,
            spouse_birth_dt: dob,
            spouse_ssn: SSN,
            u_taxprep_years: taxPrepYears,
            spouse_cell_phone: phone,
            spouse_form: true,
            marriage_date: marriageDate,
        };
        mutation.mutate({ formData, user_id: user_id });
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <p className="text-xl text-black font-semibold">Spouse Information</p>
                <div className="flex items-center gap-4">
                    {isEditable && (
                        <span
                            className="material-symbols-outlined cursor-pointer text-red-400"
                            onClick={toggleEdit}
                        >
                            {t('cancel')}
                        </span>
                    )}
                    <ButtonOutlined
                        onClick={isEditable ? handleSubmit : toggleEdit}
                        customClass={`${
                            isEditable
                                ? 'bg-primary-500 text-white flex items-center'
                                : 'text-primary-500'
                        }`}
                    >
                        {!isEditable && (
                            <span
                                style={{ fontSize: '20px' }}
                                className="material-symbols-outlined text-primary-500"
                            >
                                edit
                            </span>
                        )}
                        {mutation.isLoading
                            ? 'Loading..'
                            : isEditable
                            ? t('Save Updates')
                            : t('Edit')}
                    </ButtonOutlined>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="firstName"
                            label="First Name"
                            placeholder=""
                            value={firstName}
                            setValue={(value) => {
                                setFirstName(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.spouse_first_name}

                            // staticError={error?.u_first_name}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('First Name')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.spouse_first_name}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="firstName"
                            label="Last Name"
                            placeholder=""
                            value={lastName}
                            setValue={(value) => {
                                setLastName(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.spouse_last_name}
                            // staticError={error?.u_first_name}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Last Name')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.spouse_last_name}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="email"
                            label="Email"
                            placeholder=""
                            value={email}
                            setValue={(value) => {
                                setEmail(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.spouse_email}
                            // staticError={error?.email}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Email')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.spouse_email}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <DateTimePicker
                            id="dob"
                            label="Date of Birth"
                            placeholder="Click to Select date"
                            value={dob}
                            className="h-full"
                            setValue={(value: any) => setDob(value)}
                            type={fieldTypes.CALENDAR}
                            error={error?.spouse_birth_dt}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Date of Birth')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.spouse_birth_dt}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <DateTimePicker
                            id="marry"
                            label="Marriage Date"
                            placeholder="Click to Select date"
                            value={marriageDate}
                            className="h-full"
                            setValue={(value: any) => setMarriageDate(value)}
                            type={fieldTypes.CALENDAR}
                            error={error?.marriage_date}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Marriage Date')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.marriage_date}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="phone"
                            label="Phone"
                            placeholder=""
                            value={phone}
                            setValue={(value) => {
                                setPhone(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            // staticError={error?.phone}
                            error={error?.spouse_cell_phone}
                            formatFunction={phoneFormat}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Phone')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.spouse_cell_phone}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="SSN"
                            label="SSN"
                            placeholder=""
                            value={SSN}
                            setValue={(value) => {
                                setSsn(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            // staticError={error?.status}
                            formatFunction={ssn}
                            hidden={true}
                            error={error?.spouse_ssn}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {'SSN'}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className={`text-black pb-1 flex font-semibold text-md w-full justify-between`}
                            >
                                {hide ? '•'.repeat(data?.spouse_ssn?.length) : data?.spouse_ssn}

                                <span
                                    onClick={() => setHide(!hide)}
                                    className="h-1 cursor-pointer mr-5"
                                >
                                    {SSN?.length > 1 ? (
                                        hide ? (
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        ) : (
                                            <span className="material-symbols-outlined">
                                                visibility_off
                                            </span>
                                        )
                                    ) : null}
                                </span>
                            </label>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default SpouseProfile;
