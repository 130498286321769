import { upsertCreditCards } from '../../api/finance';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const creditCardForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Account number',
        placeholder: 'ICICI Visa Platinum',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'type',
        label: 'Type',
        placeholder: '$150,000',
        options: [
            { label: 'Visa', value: 'Visa' },
            { label: 'MC', value: 'MC' },
            { label: 'Amex', value: 'Amex' },
            { label: 'Discover', value: 'Discover' },
            { label: 'Store Card', value: 'Store Card' },
            { label: 'Other', value: 'Other' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Name',
        placeholder: 'ICICI Visa Platinum',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        id: 'company_address',
        label: 'Address',
        placeholder: 'Car',
        span: 2,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'credit_limit',
        label: 'Credit Limit',
        placeholder: '1976',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'balance',
        label: 'Balance',
        placeholder: '$45,780',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'min_monthly_payment',
        label: 'Minimum Payment',
        placeholder: '$33,000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'available_credit',
        label: 'Available Credit',
        placeholder: '$40,000',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'credit_card_data',
        label: 'Do you have any Credit Cards?',
        formStructure: creditCardForm,
        header: 'Credit Card Details',
        displayColumns: [
            {
                name: 'Name',
                key: 'company_name',
            },
            {
                name: 'Type',
                key: 'type',
            },
            {
                name: 'Available Credit',
                key: 'available_credit',
            },
        ],
        preForm: [
            {
                type: fieldTypes.CALENDAR,
                id: 'available_credit_amount_owed_as_of_date',
                label: 'Amount Owed as of',
                placeholder: 'Tampa Bay',
            },
            {
                type: fieldTypes.CALENDAR,
                id: 'available_credit_as_of_date',
                label: 'Available Credit as of',
                placeholder: 'Florida',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'credit_card',
    name: 'Credit Card',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertCreditCards,
};
