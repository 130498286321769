/**
 * This is a file for build configuration
 * It maintains different versions for the app
 * for different subdomains
 */

import logo_dev_white from './assets/dev_white.png';
import logo_base_white from './assets/base_white.png';
import checkmark_base from './assets/checkmark_base.svg';

import logo_jtax_dark from './assets/jtax_dark.png';
import logo_jtax_white from './assets/jtax_white.png';

import logo_anthem_dark from './assets/anthem_dark.png';
import logo_anthem_white from './assets/anthem_white.png';
import checkmark_anthem from './assets/checkmark_anthem.svg';

/** Local configs */
const local = {
    // Local development configuration
    URL: 'http://localhost:3000',
    baseURL: 'https://taxprofilens.nablasol.net/api',
    taxPrepURL: 'https://taxprofilens.nablasol.net/apiTaxPrep',
    billingURL: 'https://taxprofilens.nablasol.net/apiAR',
    transcriptURL: 'https://taxprofilens.nablasol.net/apiTranscript',

    /** @Appearence */
    name: 'Dev',
    theme: 'base',
    logo_dark: logo_dev_white,
    logo_white: logo_dev_white,
    checkMarkLogo: checkmark_base,
    feature_data: [
        // Example feature data for local environment
        {
            id: 'afbfaa40-8e7c-471f-ae3d-da055c44f497',
            client_name: 'Testing 2',
            client_url: 'http://localhost:3000/login',
            feature_enable: 1,
            feature_name: 'Form',
            form_list: 'f433f,f433a,f433aoic,f433b,f433d,f656,f433boic',
            third_party: 'AWS',
        },
    ],

    /** @feature_list */
    tax_pro_table_label: true,
    enable_form433boic: true,
    dynamic_feature_enabled: true,
    document_upload: false,
    ms_sso_login: true,
    section_locking: true,
    local_storage: true,
    tp_local_storage: false,
    error_highlighting: true,
    include_433_business: true,
    table_sorting: true,
    admin_list: true,
    data_export: true,
    business_property_preform: false,
    tax_prep: true,
    additional_income_new_fetch: true,
    checkboxes_in_expenses: true,
    taxprep_module: true,
    login_filter: true,
    multiform: false,
    sign_up_page: false,
    arrow_icon: false,
    tp_audit_log: false,
    new_user_dashboard: false,
    notifications: false,
    virtual_currency_change: true,
    // ... add other local configurations
};

/** Development configs */
const dev = {
    // Development configuration
    URL: 'https://dev.mycase.tax',
    baseURL: 'https://taxprofilens.nablasol.net/api',
    taxPrepURL: 'https://taxprofilens.nablasol.net/apiTaxPrep',
    billingURL: 'https://taxprofilens.nablasol.net/apiAR',
    transcriptURL: 'https://taxprofilens.nablasol.net/apiTranscript',

    /** @Appearence */
    name: 'Dev',
    theme: 'base',
    logo_dark: logo_dev_white,
    logo_white: logo_dev_white,
    checkMarkLogo: checkmark_base,
    feature_data: [
        {
            id: 'afbfaa40-8e7c-471f-ae3d-da055c44f497',
            client_name: 'Testing 2',
            client_url: 'http://localhost:3000/login',
            feature_enable: 1,
            feature_name: 'Form',
            form_list: 'f433f,f433a,f433aoic,f433b,f433d,f656,f433boic',
            third_party: 'AWS',
        },
    ],

    /** @feature_list */
    tax_pro_table_label: true,
    dynamic_feature_enabled: true,
    document_upload: true,
    enable_form433boic: true,
    ms_sso_login: true,
    section_locking: true,
    include_433_business: true,
    error_highlighting: true,
    local_storage: true,
    tp_local_storage: true,
    table_sorting: true,
    admin_list: true,
    data_export: true,
    business_property_preform: true,
    additional_income_new_fetch: true,
    checkboxes_in_expenses: false,
    taxprep_module: true,
    login_filter: true,
    multiform: true,
    sign_up_page: true,
    sso_login: true,
    arrow_icon: true,
    tp_audit_log: true,
    new_user_dashboard: false,
    notifications: true,
    virtual_currency_change: true,
    // ... add other DEV configurations
};

const demo = {
    // Staging configuration
    URL: 'https://demo.mycase.tax',
    baseURL: 'https://mycasedemo.nablasol.net/api',
    taxPrepURL: 'https://mycasedemo.nablasol.net/apiTaxPrep',
    billingURL: 'https://taxprofilens.nablasol.net/apiAR',
    transcriptURL: 'https://taxprofilens.nablasol.net/apiTranscript',

    /** @Appearence */
    name: 'Dev',
    theme: 'base',
    logo_dark: logo_dev_white,
    logo_white: logo_dev_white,
    checkMarkLogo: checkmark_base,
    feature_data: [
        {
            id: 'afbfaa40-8e7c-471f-ae3d-da055c44f497',
            client_name: 'Testing 2',
            client_url: 'http://localhost:3000/login',
            feature_enable: 1,
            feature_name: 'Form',
            form_list: 'f433f,f433a,f433aoic,f433b,f433d,f656,f433boic',
            third_party: 'AWS',
        },
    ],

    /** @feature_list */
    tax_pro_table_label: true,
    dynamic_feature_enabled: true,
    document_upload: true,
    enable_form433boic: true,
    ms_sso_login: true,
    section_locking: true,
    include_433_business: true,
    error_highlighting: true,
    local_storage: true,
    tp_local_storage: true,
    table_sorting: true,
    admin_list: true,
    data_export: true,
    business_property_preform: true,
    additional_income_new_fetch: true,
    checkboxes_in_expenses: false,
    taxprep_module: true,
    login_filter: true,
    multiform: true,
    sign_up_page: true,
    sso_login: true,
    arrow_icon: true,
    tp_audit_log: true,
    new_user_dashboard: true,
    notifications: true,
    virtual_currency_change: true,
};

/** Staging configs */
const app = {
    // Staging configuration
    URL: 'https://app.mycase.tax',
    baseURL: 'https://mycase-uat.nablasol.net/api',
    taxPrepURL: 'https://mycase-uat.nablasol.net/apiTaxPrep',
    billingURL: 'https://taxprofilens.nablasol.net/apiAR',
    transcriptURL: 'https://taxprofilens.nablasol.net/apiTranscript',

    /** @Appearence */
    name: 'mycase.tax',
    theme: 'base',
    logo_dark: logo_base_white,
    logo_white: logo_base_white,
    checkMarkLogo: checkmark_base,
    feature_data: [
        {
            id: 'afbfaa40-8e7c-471f-ae3d-da055c44f497',
            client_name: 'Testing 2',
            client_url: 'http://localhost:3000/login',
            feature_enable: 1,
            feature_name: 'Form',
            form_list: 'f433f,f433a,f433aoic,f433b,f433d,f656,f433boic',
            third_party: 'AWS',
        },
    ],

    /** @feature_list */
    tax_pro_table_label: true,
    dynamic_feature_enabled: true,
    document_upload: true,
    enable_form433boic: true,
    ms_sso_login: true,
    section_locking: false,
    local_storage: true,
    tp_local_storage: true,
    error_highlighting: true,
    include_433_business: true,
    table_sorting: true,
    admin_list: true,
    data_export: true,
    business_property_preform: true,
    additional_income_new_fetch: false,
    checkboxes_in_expenses: false,
    taxprep_module: true,
    login_filter: true,
    multiform: false,
    sign_up_page: true,
    sso_login: true,
    arrow_icon: true,
    tp_audit_log: true,
    new_user_dashboard: false,
    notifications: true,
    virtual_currency_change: true,
    // ... add other Justice Tax configurations
};

/** Client configs */
const anthemTax = {
    // Anthem Tax configuration
    URL: 'https://anthem.mycase.tax',
    baseURL: 'https://api-tax-uat.mycase.tax/api',
    taxPrepURL: '',
    billingURL: 'https://taxprofilens.nablasol.net/apiAR',
    transcriptURL: 'https://taxprofilens.nablasol.net/apiTranscript',

    /** @Appearence */
    name: 'Anthem Tax',
    theme: 'anthem',
    logo_dark: logo_anthem_dark,
    logo_white: logo_anthem_white,
    checkMarkLogo: checkmark_anthem,

    feature_data: [
        {
            id: 'afbfaa40-8e7c-471f-ae3d-da055c44f497',
            client_name: 'Testing 2',
            client_url: 'http://localhost:3000/login',
            feature_enable: 1,
            feature_name: 'Form',
            form_list: 'f433f,f433a,f433aoic,f433b,f433boic,f433d,f656',
            third_party: 'AWS',
        },
    ],

    /** @feature_list */
    tax_pro_table_label: false,
    section_locking: false,
    dynamic_feature_enabled: false,
    document_upload: false,
    enable_form433boic: true,
    local_storage: true,
    tp_local_storage: false,
    ms_sso_login: false,
    error_highlighting: false,
    include_433_business: true,
    table_sorting: true,
    admin_list: false,
    data_export: false,
    business_property_preform: false,
    additional_income_new_fetch: false,
    checkboxes_in_expenses: false,
    taxprep_module: false,
    login_filter: true /** @depreciate */,
    multiform: false,
    sign_up_page: false,
    sso_login: false,
    arrow_icon: true,
    tp_audit_log: true,
    new_user_dashboard: false,
    notifications: false,
    virtual_currency_change: true,
    // ... add other Justice Tax configurations
};

/** Client configs */
const justiceTax = {
    // Justice Tax configuration
    URL: 'https://jtax.mycase.tax',
    baseURL: 'https://api-tax-uat.mycase.tax/api',
    taxPrepURL: '',
    billingURL: 'https://taxprofilens.nablasol.net/apiAR',
    transcriptURL: 'https://taxprofilens.nablasol.net/apiTranscript',

    name: 'Justice Tax',
    theme: 'base',
    logo_dark: logo_jtax_dark,
    logo_white: logo_jtax_white,
    checkMarkLogo: checkmark_base,

    feature_data: [
        {
            id: 'afbfaa40-8e7c-471f-ae3d-da055c44f497',
            client_name: 'Testing 2',
            client_url: 'http://localhost:3000/login',
            feature_enable: 1,
            feature_name: 'Form',
            form_list: 'f433f,f433a,f433aoic,f433b,f433boic,f433d,f656',
            third_party: 'AWS',
        },
    ],

    /** @feature_list */
    tax_pro_table_label: true,
    enable_form433boic: true,
    dynamic_feature_enabled: false,
    document_upload: true,
    local_storage: true,
    tp_local_storage: false,
    ms_sso_login: false,
    section_locking: false,
    error_highlighting: true,
    include_433_business: true,
    table_sorting: true,
    admin_list: true,
    data_export: true,
    business_property_preform: false,
    additional_income_new_fetch: false,
    checkboxes_in_expenses: false,
    taxprep_module: false,
    login_filter: true,
    multiform: false,
    sign_up_page: false,
    sso_login: false,
    arrow_icon: false,
    tp_audit_log: true,
    new_user_dashboard: false,
    notifications: false,
    virtual_currency_change: true,
    // ... add other Justice Tax configurations
};

export const build = anthemTax;
console.log('BUILD CONFIG A', build);
