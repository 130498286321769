import classNames from 'classnames';
import { useEffect } from 'react';
import { interface_field_textDisplay, interface_field_textInput } from '../../utils/interfaces';
import ParentGroup from './ParentGroup';

/**
 * UI Component for Text Display
 **/

type InputDisplayProps = interface_field_textDisplay & {
    className?: string;
    value: string | number;
};

const InputDisplay: React.FC<InputDisplayProps> = (props) => {
    const { id, value, label, className, span, formatFunction } = props;
    const displayValue = formatFunction ? formatFunction(value) : value;

    return (
        <ParentGroup
            id={id}
            className={classNames(className)}
            label={label}
            value={displayValue}
            styles={{ gridColumn: `span ${span}` }}
        >
            <div
                id={id}
                className={classNames('focus:outline-none h-full w-full', {
                    'bg-gray-100': displayValue !== undefined,
                })}
            >
                {displayValue}
            </div>
        </ParentGroup>
    );
};

export default InputDisplay;
