import React, { useEffect, useState } from 'react';
import Layout from './layout/UserDashLayout';
import { useParams } from 'react-router-dom';
import { useFormState } from '../../../context/form';
import InputGroup from './userBilling/InputGroup';
import { fieldTypes } from '../../../utils/enums';
// import Dropdown from '../../client/Dropdown';
import useTaxPrep from '../../../api/hooks/useTaxPrep';
import RadioGroup from './components/Radio';
import Dropdown from './userBilling/Dropdown';

type Props = {};

export const FormDownload: React.FC<{ form?: string }> = ({ form }) => {
    // const { form: url_form } = useParams<{ form: string }>(); // Get the form type from the URL parameter
    // const form = url_form?.replace('-', ' ');
    console.log('form: ', form);
    const [extraData, setExtraData] = useState<any>({});
    const [validateB, setValidateB] = useState<boolean>(false); // Assuming validateB is a state variable used for validation
    const formState = useFormState();
    const bussinessOptions = formState.business_data.map((obj: any) => {
        return { label: obj.name, value: obj.id };
    });
    const { taxPreparers } = useTaxPrep();
    const years_options = [...Array(30)].map((_, index) => {
        return { label: `${2020 - index}`, value: `${2020 - index}` };
    });
    const taxPrepOptions = taxPreparers
        ? taxPreparers.map((item: any) => ({
              label: item.u_first_name + ' ' + item.u_last_name,
              value: item.user_id,
          }))
        : [];
    const removeSelectedTaxPros = (item: any) => {
        for (let i = 1; i <= 4; i++) {
            if (item.value === extraData[`tax_preparer_id${i}`]) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        if (form === 'Form 433-B' || form === 'Form 433-B (OIC)') {
            if (bussinessOptions.length === 1) {
                setValidateB(false);
                setExtraData({ bussiness_id: bussinessOptions[0].value });
            }
            if (extraData.bussiness_id) {
                setValidateB(false);
            }
        } else {
            setExtraData({
                tax_preparer_id1: '',
            });
        }
    }, [form]);

    const renderFormFields = () => {
        switch (form) {
            case 'Form 433-D':
                return (
                    <>
                        <InputGroup
                            id="f433d_routing_number"
                            label="Routing Number"
                            placeholder=""
                            value={extraData.f433d_routing_number || ''}
                            setValue={(value) =>
                                setExtraData({ ...extraData, f433d_routing_number: value })
                            }
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                        />
                        <InputGroup
                            id="f433d_account_number"
                            label="Account Number"
                            placeholder=""
                            value={extraData.f433d_account_number || ''}
                            setValue={(value) =>
                                setExtraData({ ...extraData, f433d_account_number: value })
                            }
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                        />
                    </>
                );
            case 'Form 433-A':
            case 'Form 433-A(OIC)':
                return (
                    <>
                        <Dropdown
                            id="f656_1040_years"
                            label="Business Options"
                            placeholder=""
                            value={extraData.bussiness_id || ''}
                            options={bussinessOptions}
                            setValue={(value) => setExtraData({ bussiness_id: value })}
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1"
                        />
                        <div className="text-red-600 px-3 text-xs font-bold bg-red-100 mt-2">
                            {validateB === true && 'Select at least one'}
                        </div>
                    </>
                );
            case 'Form 656':
                return (
                    <div>
                        <div className="grid grid-cols-8">
                            <Dropdown
                                isMulti
                                id="f656_1040_years"
                                label="1040 Income Tax-Year(s)"
                                placeholder=""
                                value={extraData.f656_1040_years || ''}
                                options={years_options}
                                setValue={(value) =>
                                    setExtraData({ ...extraData, f656_1040_years: value })
                                }
                                type={fieldTypes.DROPDOWN}
                                className="w-full mt-1 col-span-3 "
                            />

                            <Dropdown
                                isMulti
                                id="f656_business_years"
                                label="Business 1120 Income Tax-Year(s)"
                                placeholder=""
                                value={extraData.f656_business_years || ''}
                                options={years_options}
                                setValue={(value) =>
                                    setExtraData({ ...extraData, f656_business_years: value })
                                }
                                type={fieldTypes.DROPDOWN}
                                className="w-full mt-1 col-span-3 col-start-5"
                            />
                        </div>
                        <div className="px-2 text-base font-semibold mt-14">
                            Do you qualify for Low-Income Certification?
                        </div>
                        <RadioGroup
                            column
                            isMulti
                            type={fieldTypes.RADIO}
                            id="f656_low_income_qualify"
                            label=""
                            options={[
                                {
                                    label: 'Because adjusted gross income <= the total amount',
                                    value: 'f656_low_income_qualify1',
                                },
                                {
                                    label: `Because household's size and gross monthly income x 12 <=  the total amount`,
                                    value: 'f656_low_income_qualify2',
                                },
                            ]}
                            setValue={(value) =>
                                setExtraData({ ...extraData, f656_low_income_qualify: value })
                            }
                            value={extraData.f656_low_income_qualify || ''}
                        />
                        <div className="grid grid-cols-8">
                            <InputGroup
                                id="f656_reason_for_offer"
                                label="Reason for offer"
                                placeholder=""
                                value={extraData.f656_reason_for_offer || ''}
                                setValue={(value) =>
                                    setExtraData({ ...extraData, f656_reason_for_offer: value })
                                }
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full mt-1 col-span-7"
                            />
                        </div>
                    </div>
                );
            case 'Form 2848':
            case 'Form 8821':
                return (
                    <div className="grid grid-col-2">
                        <Dropdown
                            id="tax_preparer_id1"
                            label="Tax Preparer 1"
                            placeholder=""
                            value={extraData.tax_preparer_id1 || ''}
                            options={taxPrepOptions.filter(removeSelectedTaxPros)}
                            setValue={(value) =>
                                setExtraData({ ...extraData, tax_preparer_id1: value })
                            }
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1 col-span-1"
                        />

                        <Dropdown
                            id="tax_preparer_id2"
                            label="Tax Preparer 2"
                            placeholder=""
                            value={extraData.tax_preparer_id2 || ''}
                            options={taxPrepOptions.filter(removeSelectedTaxPros)}
                            setValue={(value) =>
                                setExtraData({ ...extraData, tax_preparer_id2: value })
                            }
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1 col-span-1"
                        />

                        {form === 'Form 8821' && (
                            <div>
                                <Dropdown
                                    id="tax_preparer_id3"
                                    label="Tax Preparer 3"
                                    placeholder=""
                                    value={extraData.tax_preparer_id3 || ''}
                                    options={taxPrepOptions.filter(removeSelectedTaxPros)}
                                    setValue={(value) =>
                                        setExtraData({ ...extraData, tax_preparer_id3: value })
                                    }
                                    type={fieldTypes.DROPDOWN}
                                    className="w-full mt-1 col-span-1"
                                />

                                <Dropdown
                                    id="tax_preparer_id4"
                                    label="Tax Preparer 4"
                                    placeholder=""
                                    value={extraData.tax_preparer_id4 || ''}
                                    options={taxPrepOptions.filter(removeSelectedTaxPros)}
                                    setValue={(value) =>
                                        setExtraData({ ...extraData, tax_preparer_id4: value })
                                    }
                                    type={fieldTypes.DROPDOWN}
                                    className="w-full mt-1 col-span-1"
                                />
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    const downloadForm = () => {
        // Implement logic to download the form
        // This could be an API call or some client-side logic to generate and download the form
    };

    return (
        <div style={{ minHeight: '56vh' }} className="px-20 mt-8 flex flex-col justify-between">
            {renderFormFields()}
            {form && (
                <button
                    onClick={downloadForm}
                    className="text-center text-primary-500 text-2xl font-semibold w-full mt-20"
                >
                    {'Download Form'}
                </button>
            )}
        </div>
        // <Layout>
        //     {/* <div className=" px-5 py-8 bg-white shadow-md m-5 rounded-md" style={{ minWidth: 500 }}>
        //         <div className="font-title py-4">{`Generate Form`}</div>
        //         {renderFormFields()}
        //         {form && (
        //             <button
        //                 onClick={downloadForm}
        //                 className="text-center text-primary-500 text-2xl font-semibold w-full mt-2"
        //             >
        //                 {'Download Form'}
        //             </button>
        //         )}
        //     </div> */}
        //     <div className="flex flex-col w-full h-screen overflow-y-scroll bg-gray-100">
        //         {/* {!iframe && <NavBar />} */}
        //         <div className="w-full h-full" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
        //             <div>
        //                 <div className="col-span-3">
        //                     {' '}
        //                     <div className=" ml-3 bg-white rounded-lg shadow-sm mt-5  relative">
        //                         {/* new code */}

        //                         <div className=" flex flex-col  border-0 border-[#CCCCCC] pb-10 ">
        //                             <div className=" flex flex-col  items-start">
        //                                 <div className="flex justify-start gap-3 ">
        //                                     <div
        //                                         className={`text-center py-2.5 px-9 cursor-pointer ease-in-out duration-200 ${
        //                                             true ? 'border-b-2 border-primary-500' : ''
        //                                         }`}
        //                                     >
        //                                         State Forms
        //                                     </div>
        //                                 </div>
        //                                 <div className=" flex flex-col p-6 gap-4 border-0 border-[#CCCCCC] pb-10 w-full ">
        //                                     <div className="flex justify-between">
        //                                         <span className="font-semibold text-xl">
        //                                             IRS Forms{' '}
        //                                         </span>
        //                                         <div className="mr-4 flex space-x-5">
        //                                             <div className="flex pl-3 items-center border-b-2 w-96 border-primary-500 text-primary-500">
        //                                                 <span className="material-symbols-outlined text-primary-500 pr-2">
        //                                                     search
        //                                                 </span>
        //                                                 <input
        //                                                     type="text"
        //                                                     placeholder="Search..."
        //                                                     className="border-none outline-none w-full text-primary-500 placeholder-primary-500"
        //                                                 />
        //                                             </div>
        //                                             {/* <FiltersDropdown /> */}
        //                                         </div>
        //                                     </div>
        //                                     <div className="px-4">
        //                                         {renderFormFields()}
        //                                         {form && (
        //                                             <button
        //                                                 onClick={downloadForm}
        //                                                 className="text-center text-primary-500 text-2xl font-semibold w-full mt-2"
        //                                             >
        //                                                 {'Download Form'}
        //                                             </button>
        //                                         )}
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </Layout>
    );
};

export default FormDownload;
