import { upsertOtherAffilications } from '../../api/business';
import { upsertSafeDepositBox } from '../../api/other';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { ein } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const affiliationsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        label: 'Business Name',
        placeholder: '',
        id: 'company_name',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        label: 'Business EIN',
        placeholder: '',
        id: 'company_ein',
        formatFunction: ein,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Company Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'company_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        label: 'Does your business has any other affiliations',
        id: 'business_other_affilliation_data',
        header: 'Other Business Affiliations',
        formStructure: affiliationsForm,
        displayColumns: [
            {
                name: 'Company Name',
                key: 'company_name',
            },
            {
                name: 'Business EIN',
                key: 'company_ein',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'other_business_affiliations',
    name: 'Business Other Affiliations',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertOtherAffilications,
};
