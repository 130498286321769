import { useMutation } from 'react-query';
import { signInUserSso } from '../../api/login';
import { useEffect, useState } from 'react';
import { useAuthDispatch, useAuthState } from '../../context/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthError, setAuthLoading } from '../../stateManager/authSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../stateManager/rootReducers';
import { showToast } from '../../components/basic/Toaster';

const useSsoSignInMutation = () => {
    const [errors, setErrors] = useState<any>();
    const authDispatch = useAuthDispatch();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { success, role } = useAuthState();
    const [searchParams] = useSearchParams();
    const authState = useSelector((state: RootState) => state.auth);

    // // in case auth state contains then clear session store and redirect to login
    // if (authState?.authError) {
    //     sessionStorage.clear();
    //     navigate('/login');
    // }

    //sign in mutation
    const mutation = useMutation(signInUserSso, {
        onSuccess: (data) => {
            authDispatch('LOGIN', data.data);
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
            showToast(error.response.data.message, 'error');
            navigate('/login');
            dispatch(setAuthError({ authError: error.response.data.message }));
        },
        //set loading false after processing
        onSettled: () => {
            dispatch(setAuthLoading({ isLoading: false }));
        },
    });

    //mutate call function
    const signInUserWithSso = (u_email: string) => {
        dispatch(setAuthLoading({ isLoading: true }));
        mutation.mutate({
            u_email,
        });
    };

    //redirect based on user role
    useEffect(() => {
        if (success) {
            const client_id = searchParams.get('iframe-evaluate');
            const tax_pro_id = searchParams.get('tax_pro_id');

            if (client_id) {
                navigate(
                    '/iframe-evaluate/' +
                        client_id +
                        '?tax_pro_id=' +
                        tax_pro_id +
                        '&source=salesforce',
                );
                return;
            }

            switch (role) {
                case 'Client':
                    navigate('/home');
                    return;

                case 'Admin':
                    navigate('/taxprep');
                    return;

                case 'Tax Preparer':
                    navigate('/client');
                    return;

                default:
                    return;
            }
        }
    }, [success]);
    return { signInUserWithSso, isLoading: mutation.isLoading, errors };
};

export default useSsoSignInMutation;
