// useSectionSelection.tsx
import { useEffect, useState } from 'react';
import { useAuthState } from '../../context/auth';
import { useMutation, useQueryClient } from 'react-query';
import { editSections } from '../../api/admin';
import { showToast } from '../basic/Toaster';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';

interface SectionType {
    name: string;
    isActive: boolean;
    subName: string;
    dispName: string;
    blockedSections: string[];
    blockedFormFields: string[];
}

const defaultSections = [
    {
        name: 'dependent_data',
        subName: 'I Have',
        dispName: 'Dependents',
        isActive: true,
        blockedSections: ['dependent_data'],
        blockedFormFields: [],
    },
    {
        name: 'income_details_data',
        subName: 'I have an',
        dispName: 'Income from an Employer',
        isActive: true,
        blockedSections: ['income_details_data'],
        blockedFormFields: [],
    },
    {
        name: 'divident_income_data',
        subName: 'I have',
        dispName: 'Dividents',
        isActive: true,
        blockedSections: ['divident_income_data'],
        blockedFormFields: [],
    },
    {
        name: 'donations_data',
        subName: 'I made',
        dispName: 'Donations',
        isActive: true,
        blockedSections: [],
        blockedFormFields: [
            'Contributions/Donations',
            'churches',
            'youth_program',
            'missions',
            'heart_funds',
            'salvation_army',
            'others',
        ],
    },
    {
        name: 'business_data',
        subName: 'I own',
        dispName: 'Bussiness',
        isActive: true,
        blockedSections: [
            'business_data',
            'income_data',
            'business_details',
            'expense_data',
            'cost_of_goods_sold_details',
            'business_vehicle_details',
            'business_partnership_llc_details',
        ],
        blockedFormFields: [],
    },
    {
        name: 'foreign_account_data',
        subName: 'I have',
        dispName: 'Foreign Accounts',
        isActive: true,
        blockedSections: ['foreign_account_data'],
        blockedFormFields: [],
    },
    {
        name: 'interest_income_data',
        subName: 'I invest in',
        dispName: 'Stocks, crypto or own rental property',
        isActive: true,
        blockedSections: ['interest_income_data'],
        blockedFormFields: [],
    },
    {
        name: 'itemized_deduction_data',
        subName: 'I have',
        dispName: 'Itemized Deductions',
        isActive: true,
        blockedSections: ['itemized_deduction_data'],
        blockedFormFields: [],
    },
    {
        name: 'farm_sections',
        subName: 'I have',
        dispName: 'Farming Income',
        isActive: true,
        blockedSections: [
            'business_farm_income_cash_details',
            'business_expense_cash_accural_details',
            'business_farm_income_accural_details',
        ],
        blockedFormFields: [],
    },
];

const useSectionSelection = ({ Close, setLoading }: { Close?: any; setLoading?: any }) => {
    const formState = useSelector((state: RootState) => state.taxPrep.formState);
    const { irs_account_id: client_irs } = useAuthState();
    const { irs_account_id: admin_irs, year } = useParams();
    const queryClient = useQueryClient();
    const card_tax_id = sessionStorage.getItem('tax_id');

    //pre defining the sections for UI rendering;
    const [sections, setSections] = useState<SectionType[]>(defaultSections);

    //to get the state year wise
    let state = year && formState[year];
    useEffect(() => {
        // if (state?.section_selection) {
        const data = state && JSON.parse(state?.section_selection || `[]`);
        data &&
            setSections(
                defaultSections?.map((sec: any) => {
                    const match = data.find((item: any) => item?.name === sec.name);
                    if (match) {
                        return {
                            ...sec,
                            ...match,
                            // blockedSections: match?.blockedSections,
                            // blockedFormFields: match?.blockedFormFields,
                        };
                    } else {
                        return sec;
                    }
                }),
            );
        // }
    }, [state?.section_selection, year]);

    //to set active or inactive section
    const toggleSection = async (name: string) => {
        setSections((prevSections) =>
            prevSections?.map((section) =>
                section.name === name ? { ...section, isActive: !section.isActive } : section,
            ),
        );
    };

    //upload mutation
    const mutation = useMutation(editSections, {
        onSuccess: () => {
            showToast('Sections have been Edited!', 'success');
            queryClient.invalidateQueries(['get-client-data-', year]);
            setLoading(false);
            Close();
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.message, 'error');
        },
    });

    console.log('SELECTED SECTION B', sections);
    //saving the selected sections
    const saveSections = () => {
        mutation.mutate({
            irs_account_id: admin_irs ? admin_irs : client_irs,
            section_selection: sections,
            tax_id: state?.id ? state?.id : card_tax_id,
            action: 'update',
            taxprep_year: year,
        });
    };

    return {
        sections,
        toggleSection,
        saveSections,
    };
};

export default useSectionSelection;
