import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthDispatch } from '../../context/auth';

export type LoginArgs = {
    client_id: string;
    tax_pro_id: string;
};

export type LoginArgsV2 = {
    Encoded_Encrypted_Text: string;
    Encoded_Timestamp: string;
};

const useSalesForce = () => {
    const loginUser = async (loginArgs: LoginArgs) => {
        const { data } = await axios.post(
            'https://taxprofilens.nablasol.net/api/salesforce_login',
            // {
            //     client_id: 'b32be321-7012-46f2-85ff-0c3a3459b725',
            //     tax_pro_id: '31d7463b-5810-48f7-9e92-3af398797319',
            // },
            loginArgs,
        );

        return data.data;
    };

    const loginUserV2 = async (loginArgs: LoginArgsV2) => {
        const { data } = await axios.post(
            'https://taxprofilens.nablasol.net/api/salesforce_login_v2',
            // {
            //     client_id: 'b32be321-7012-46f2-85ff-0c3a3459b725',
            //     tax_pro_id: '31d7463b-5810-48f7-9e92-3af398797319',
            // },
            loginArgs,
        );

        return data.data;
    };

    const authDispatch = useAuthDispatch();
    const queryClient = useQueryClient();

    const dataKeys = ['1', '2', '3', '4'];
    const login = useMutation(loginUser, {
        onSuccess: (data) => {
            authDispatch('LOGIN', data);
        },
    });

    const loginV2 = useMutation(loginUserV2, {
        onSuccess: (data) => {
            authDispatch('LOGIN', data);
        },
    });

    return { login, loginV2 };
};

export default useSalesForce;
