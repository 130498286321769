import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';
import {
    formStateStore,
    metaStateStore,
    errorStateStore,
} from '../../stateManager/taxPreparerSlice';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { upsertBusinesses } from '../../resource/form_structures_taxprep/2_0_bussiness_section';
import useClientData from '../../api/tax-prep/useClientData';
import { build } from '../../config';
import { Dispatch, SetStateAction } from 'react';
import useAdminLocalStorage from './useAdminLocalStorage';

const useTaxPrepEvalData = (forceYear?: string) => {
    const {
        year: currentYear,
        address,
        currentPage: page,
        pagesRaw,
        businessId,
        irs_account_id,
    } = useTaxPrepStructure();

    const year = forceYear || currentYear;

    const { refetch: refreshData } = useClientData(irs_account_id, year);
    const serverMetaState = useSelector((state: RootState) => state.taxPrep.serverMetaState);

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.taxPrep.formState);
    let serverState = useSelector((state: RootState) => state.taxPrep.serverState);
    const meta = useSelector((state: RootState) => state.taxPrep.metaState);
    const error = useSelector((state: RootState) => state.taxPrep.errorState);

    const yearBasedState = (state && state[year]) || {};
    const yearBasedMetaState = (meta && meta[year]) || {};

    const yearBasedServerMetaState = (serverMetaState && serverMetaState[year]) || {};
    const yearBasedErrorState = (error && error[year]) || {};

    const { setLocalData, setLocalMetaData } = useAdminLocalStorage({
        id: yearBasedState.id,
        year,
    });

    console.log('setting value local : form state', state)

    function setStateForYear(year: string, update: any) {
        const data = {
            ...state,
            [year]: {
                ...(state[year] || {}),
                ...update,
            },
        };

        console.log('setting value local : dispatching update', data);

        dispatch(formStateStore({ data }));
        setLocalData(data);
    }



    function setMeta(updateState: any, localSetter?: true | false) {
        console.log('YEAR BASED META _> page_json', updateState);
        const data = {
            ...meta,
            [year]: {
                ...(meta[year] || {}),
                ...updateState,
            },
        };
        dispatch(metaStateStore({ state: data }));
        setLocalMetaData(data);
    }

    function setErrorForYear(year: string, error: any) {
        const data = {
            [year]: { ...error },
        };
        dispatch(errorStateStore({ state: data }));
    }


    function setStateForBusinessYear(businessId: string, year: string, update: any) {
        let business_data = state[year]?.['business_data'].map((business_id_data: any) => {
            if (!business_id_data) {
                return business_id_data;
            }

            if (business_id_data.id === businessId) {
                return {
                    ...business_id_data,
                    ...update,
                };
            }

            return business_id_data;
        });

        const data = {
            ...state,
            [year]: {
                ...(state[year] || {}),
                business_data: business_data,
            },
        };

        dispatch(formStateStore({ data }));
    }

    console.log('META', meta);
    console.log('STATE', state);


    const businessFormState = state[year]?.['business_data']?.find(
        (business_id_data: any) => business_id_data?.id === businessId,
    );

    const updateMutation = async () => {
        if (!businessId) {
            const { data } = await page?.save(year, { ...state[year], meta_data: yearBasedMetaState });
            return data;
        }

        const { data } = await page?.save(year, { ...(businessFormState || {}), meta_data: yearBasedMetaState });
        return data;
    };

    const queryClient = useQueryClient();

    const updateClient = useMutation({
        mutationFn: updateMutation,
        onMutate: () => {
            dispatch(errorStateStore({ state: [] }));
        },
        mutationKey: ['save-mutation'],
        onSuccess: (data) => {
            queryClient.invalidateQueries(['get-client-data-', year]);
        },
        onError: (error: any) => {
            setErrorForYear(year, error.response.data.message);
        },
    });

    const setFormState = (params: { id: string; value: any }) => {
        setStateForYear(year, { [params.id]: params.value });
    };

    const setBusinessFormState = (business_id: string, params: { id: string; value: any }) => {
        setStateForBusinessYear(business_id, year, { [params.id]: params.value });
    };

    const setOptionValue = (params: {
        path: string;
        value: 'one' | 'no' | 'multiple' | undefined | null;
    }) => {
        const initialFormStatus =
            typeof yearBasedMetaState?.form_status === 'string'
                ? {}
                : yearBasedMetaState?.form_status;

        setMeta({
            ...yearBasedMetaState,
            form_status: {
                ...initialFormStatus,
                [params.path]: params.value,
            },
        });
    };

    // to get calculated percentage
    function getCompletionPercentage(pageState: any, currentYear?: String) {
        //pass currentYear along with page_json to use this function globally
        let n_pageState = currentYear ? pageState : pageState;

        //to get selected pages
        const page = pagesRaw?.map((page: any) => {
            return page.address;
        });

        const page_json = Object.keys(n_pageState || {});

        const trueKeys = page_json.filter(
            (key) => n_pageState[key] && key !== 'percentage_complete',
        );

        return Math.floor((trueKeys.length / page.length) * 100);
    }

    const setPageStateValue = (params: { path: string; value: any }) => {
        // Check if meta.page_json is not null or undefined
        const initialPageJson = yearBasedMetaState?.page_json || {};
        const initialServerPageJson = yearBasedServerMetaState?.page_json || {};
        // Update the specified path directly in meta.page_json

        const serverValue = initialServerPageJson?.[params.path];

        /** Override undefined if the server was initially in the undefined state */
        if (serverValue === undefined && params.value === false) {
            params.value = undefined;
        }

        const updatedPageState = {
            ...initialPageJson,
            [params.path]: params.value,
        };
        //setting page_json in meta.page_json
        const updatedMeta = {
            ...yearBasedMetaState,
            page_json: {
                ...initialPageJson,
                ...updatedPageState,
                percentage_complete: getCompletionPercentage(updatedPageState),
            },
        };

        setMeta(updatedMeta);
    };

    const setBusinessOptionValue = (
        businessId: string,
        params: {
            path: string;
            value: 'one' | 'no' | 'multiple' | undefined | null;
        },
    ) => {
        const initialFormStatus =
            typeof yearBasedMetaState?.form_status === 'string'
                ? {}
                : yearBasedMetaState?.form_status;

        setMeta({
            ...yearBasedMetaState,
            form_status: {
                ...initialFormStatus,
                business_data: {
                    ...(initialFormStatus?.['business_data'] || {}),
                    [businessId]: {
                        ...(initialFormStatus?.['business_data']?.[
                            businessId
                        ] || {}),
                        [params.path]: params.value,
                    },
                },
            },
        });
    };

    const getBusinessOptionValue = (businessId: string, path: string, isServer?: boolean) => {
        if (isServer) {
            return yearBasedServerMetaState.form_status?.['business_data']?.[
                businessId
            ]?.[path + '_count'];
        }
        return yearBasedMetaState.form_status?.['business_data']?.[
            businessId
        ]?.[path + '_count'];
    };

    const getOptionValue = (path: string, isServer?: boolean) => {
        let response;
        if (isServer) {
            response =
                yearBasedServerMetaState.form_status?.[path + '_count'];
        } else {
            response = yearBasedMetaState.form_status?.[path + '_count'];
        }

        if (path === 'income_details_data') {
            console.log('GET OP VAL', path, response, { isServer, yearBasedMetaState });
        }

        return response;
    };

    const getPageJsonValue = (path: string, isServer?: boolean) => {
        if (isServer) {
            return yearBasedServerMetaState.page_json?.[path];
        }

        return yearBasedMetaState.page_json?.[path];
    };

    const businessGroup = Object.keys(state).reduce(
        (group, yearKey) => {
            if (!businessFormState) {
                return group;
            }

            const relatedBusiness = state[yearKey]?.['business_data']?.find((busData: any) => {
                if (!busData) {
                    return false;
                }

                const { business_group_id } = busData;
                return business_group_id === businessFormState.business_group_id;
            });

            if (!relatedBusiness) {
                return group;
            }

            return {
                ...group,
                [yearKey]: relatedBusiness,
            };
        },
        { [year]: businessFormState },
    );

    const copyBusinessData = useMutation({
        mutationKey: ['upsert-business'],
        mutationFn: async (params: { copyYear: string; selectedBusinesses: any[] }) => {
            const business_data = params.selectedBusinesses.map((b, index) => {
                const { id, taxprep_year, ...createData } = b;
                return {
                    ...createData,
                    id: 'new_000' + index,
                    action: 'create',
                    taxprep_year: year,
                };
            });

            const fromOptionValue = business_data.length > 1 ? 'multiple' : ' one';

            const initialFormStatus =
                (typeof yearBasedMetaState?.form_status === 'string'
                    ? {}
                    : yearBasedMetaState.meta_data?.form_status) || {};

            const meta_data = {
                ...yearBasedMetaState,
                form_status: {
                    ...initialFormStatus,

                    ['business_data_count']: fromOptionValue,
                },
            };

            const { data } = await upsertBusinesses(params.copyYear, {
                id: yearBasedMetaState.taxprep_account_id,
                irs_account_id: yearBasedMetaState.irs_account_id,
                meta_data,
                business_data,
            });

            return data;
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['get-client-data-', year]);
        },

        // () => {
        //
        // },
        onError: (data) => { },
    });
    console.log('YEAR BASED META _>', { ...yearBasedMetaState, meta });
    console.log('YEAR BASED META _> META', { meta });

    return {
        meta: yearBasedMetaState,
        state,
        isNew: yearBasedState?.[address]?.length === 0,
        // copyData,
        copyBusinessData,
        address,
        year,
        setOptionValue,
        getOptionValue,
        getPageJsonValue,
        setPageStateValue,
        getBusinessOptionValue,
        setBusinessOptionValue,
        formState: { ...yearBasedState, _errors: yearBasedErrorState },
        serverState,
        // handleLocalStorage,
        setFormState,
        businessFormState,
        setBusinessFormState,
        updateClient,
        businessId,
        businessGroup,
        setError: setErrorForYear,
        refreshData,
        getCompletionPercentage,
    };
};

export default useTaxPrepEvalData;
