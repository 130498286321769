import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../api';
import NavBar from '../components/basic/NavBar';
import { PageManager, Table } from './Table';
import useNotifications from '../api/hooks/useNotifications';
import { useIsMobile } from '../utils/helpers';
import AdminMenu from '../components/basic/AdminMenu';
import bell_icon from '../assets/big-bell.svg';
import { HeaderGroup } from 'react-table';
const paramString = (key: string, values: Array<any>): string => {
    let str: string = ``;

    if (values.length === 0) return str;

    values.forEach((value) => {
        if (value)
            if (!str) str = `${key}=${value}`;
            else str = str + `&${key}=${value}`;
    });

    return str;
};
const NotificationsFullView = () => {
    // Hooks
    const navigate = useNavigate();
    const [msgState, setMsgState] = useState<any>();
    const [filterParams, setFilterParams] = useState<{ [key: string]: any }>({ offset: 0 });
    const { clearNotifs, clearNotifLoading } = useNotifications(msgState);
    const [menu, setMenu] = useState<boolean>(false);
    const [isSortEnabled, setisSortEnabled] = useState(false);
    const isMobile = useIsMobile();
    const queryClient = useQueryClient();
    const [filterString, setFilterString] = useState<string>();
    useEffect(() => {
        let arrParams = ``,
            strFilters = {};

        Object.keys(filterParams).map((key) => {
            if (Array.isArray(filterParams[key])) {
                if (arrParams === ``) {
                    arrParams = paramString(key, filterParams[key]);
                } else {
                    arrParams += '&' + paramString(key, filterParams[key]);
                }
            } else {
                if (filterParams[key]) {
                    strFilters = { ...strFilters, [key]: filterParams[key] };
                }
            }
        });

        const strParams = new URLSearchParams({ ...strFilters });
        const queryParams = strParams + (arrParams !== `` ? '&' + arrParams : ``);

        setFilterString(queryParams);
    }, [filterParams]);
    // Fetch all notifications
    const getNotifs = async (): Promise<any> => {
        // const { data } = await api.get(
        //     `/getNotifications?${filterString ? filterString : ''}&get_all=${true}`,
        // );
        return data;
    };

    // Use react-query to fetch notifications and automatically refetch every 10 seconds
    const { data } = useQuery({
        queryKey: ['notifs-all', filterString],
        queryFn: getNotifs,
        staleTime: Infinity,
        refetchIntervalInBackground: true,
    });

    // Invalidate cache on component mount to ensure fresh data
    useEffect(() => {
        queryClient.invalidateQueries('notifs-all');
    }, []);

    // Define columns for the table
    const columns: Array<any> = useMemo(
        () => [
            {
                Header: 'Notifications',
                accessor: 'client_name',
                Cell: ({ row: { original } }: any) => (
                    <span className="text-base pl-1 font-normal text-gray-600">
                        <span
                            className="text-primary-500 cursor-pointer font-semibold hover:underline"
                            onClick={() => {
                                navigate(
                                    `/evaluate/${original?.irs_account_id}?scroll=basic_information`,
                                );
                            }}
                        >
                            {original?.client_name + ' '}
                        </span>
                        has updated the Tax Resolution Worksheet.
                    </span>
                ),
            },
            {
                Header: 'Date & Time',
                accessor: 'last_updated_date',
            },
            {
                Header: 'Read / Unread',
                accessor: '',
                Cell: ({ row: { original } }: any) => (
                    <button
                        onClick={() => {
                            clearNotifs({
                                irs_account_id: original?.irs_account_id,
                                read: original?.read,
                            });

                            if (original.read === true) {
                                setMsgState('Read');
                            } else {
                                setMsgState('Unread');
                            }
                        }}
                        className={classNames('text-base px-2 rounded-full', {
                            'bg-gray-200 text-gray-700': !original?.read,
                            'bg-primary-200 text-gray-700': original?.read,
                        })}
                    >
                        {clearNotifLoading ? 'Loading..' : original?.read ? 'Read' : 'Unread'}
                    </button>
                ),
            },
        ],
        [],
    );
    // to sort table columns wise
    const getSortString = (key: string) => {
        return `sort_by=${key}&order_by=${isSortEnabled ? 'ASC' : 'DESC'}`;
    };
    const dataSortByColumn = (props: HeaderGroup<object>) => {
        setisSortEnabled(!isSortEnabled);

        switch (props.Header) {
            case 'Notifications':
                setFilterString(getSortString('client_name'));
                break;
            case 'Date & Time':
                setFilterString(getSortString('last_updated_date'));
                break;
            default:
                console.error('Unknown header type: ' + props.Header);
        }
    };
    return (
        <>
            <div className={classNames('w-full h-screen flex', { 'flex-col': isMobile })}>
                {/* Render mobile navigation bar */}
                {isMobile && <NavBar toggleMenu={() => setMenu(!menu)} />}
                {/* Render admin menu */}
                {!isMobile ? (
                    <AdminMenu selected="notifications" />
                ) : (
                    menu && (
                        <div className="z-50 absolute h-screen">
                            <AdminMenu selected="notifications" close={() => setMenu(false)} />
                        </div>
                    )
                )}
                {/* Main content */}
                <div className="w-full flex h-screen flex-col items-center p-6 flex-grow">
                    <div className="w-full flex flex-col max-w-6xl max-h-full">
                        {/* Page title */}
                        <div
                            className={classNames('flex w-full items-center pb-6 pt-20', {
                                'mt-5 flex-col': isMobile,
                            })}
                        >
                            <div className="font-title font-medium flex-grow">
                                {'ALL NOTIFICATIONS'}
                            </div>
                        </div>
                        {/* Table of notifications */}
                        {data?.data?.notifications?.length < 1 && (
                            <div className="flex flex-col w-full my-40 px-4 py-1 text-center justify-center align-middle ">
                                <img src={bell_icon} alt="" className="h-20" />
                                <h3 className="font-semibold">No Notifications!</h3>
                            </div>
                        )}
                        {data && (
                            <Table
                                isSortEnabled={false}
                                data={data?.data?.notifications}
                                columns={columns}
                                sortByColumn={dataSortByColumn}
                            />
                        )}
                        {/* Pagination */}
                        <PageManager
                            total={data?.data?.Total_Notifications}
                            offset={filterParams?.offset}
                            setOffset={(offset) => setFilterParams((p) => ({ ...p, offset }))}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotificationsFullView;
