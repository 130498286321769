import classNames from 'classnames';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useDocuments from '../../api/hooks/useDocument';
import { useAuthState } from '../../context/auth';
import { useIsMobile, useLightHouse } from '../../utils/helpers';
import Button from '../basic/Button';
import ButtonSmall from '../basic/ButtonSmall';
import Modal from '../basic/Modal';
import CreateRequest from './CreateRequestModal';
import DeleteModal from './DeleteModal';
import UploadModal from './UploadModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { MobileRequestsTable, RequestsTable } from './RequestsTable';
import { MobileUploadsTable, UploadsTable } from './UploadsTable';

const menuOptions = [
    { id: 0, name: 'Uploads' },
    { id: 1, name: 'Required uploads' },
];

type MenuItemType = (typeof menuOptions)[0];

export const FilePreview: React.FC<{ type: string; url: string; closePreview: () => void }> = ({
    type,
    url,
    closePreview,
}) => {
    let regEx = /^image/;
    if (regEx.test(type)) {
        return (
            <div
                className="w-auto flex justify-center bg-gray-100"
                style={{ maxHeight: '50vh', height: '100vh' }}
            >
                <Lightbox
                    mainSrc={url}
                    onCloseRequest={closePreview}
                    reactModalStyle={{ style: { backgroundColor: '#fff' } }}
                />
            </div>
        );
    }

    if (['.pdf', 'application/pdf'].includes(type)) {
        return (
            <div className="flex-grow bg-gray-200" style={{ maxWidth: 800, height: '80vh' }}>
                <iframe height="100%" width="100%" src={url} className="" />
            </div>
        );
    }

    return (
        <div className="bg-gray-100">{`Unknown document type. Please select a PDF or image.`}</div>
    );
};

const Menu: React.FC<{ activeItem: MenuItemType; setMenuItem: (index: MenuItemType) => void }> = ({
    activeItem,
    setMenuItem,
}) => {
    return (
        <div className="w-full flex-col bg-gray-200 py-4" style={{ height: '100%', maxWidth: 250 }}>
            {menuOptions.map((item) => (
                <div
                    onClick={() => setMenuItem(item)}
                    className={classNames('px-3 py-1 cursor-pointer ', {
                        'text-white bg-primary-500 hover:bg-primary-600': activeItem.id === item.id,
                        'hover:bg-gray-200': activeItem.id !== item.id,
                    })}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
};
const Uploads: React.FC = () => {
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [viewDocument, setViewDcument] = useState<any>(null);
    const { currentPage } = useLightHouse();
    const { documents } = useDocuments();

    const isMobile = useIsMobile();

    return (
        <div className="w-full py-4 px-2">
            {!isMobile && (
                <div className="w-full flex justify-end px-2 mb-4">
                    <ButtonSmall
                        onClick={() => setUploadModal(true)}
                    >{`Upload New Document`}</ButtonSmall>
                </div>
            )}

            {isMobile && (
                <div
                    onClick={() => setUploadModal(true)}
                    className="w-full justify-end px-2 mb-4 bg-gray-100 py-2 border border-dashed text-center hover:bg-gray-200 cursor-pointer"
                >
                    {`Upload New Document`}
                </div>
            )}

            {!isMobile && (
                <UploadsTable
                    data={documents}
                    onDocumentClick={(doc: any) => setViewDcument(doc)}
                />
            )}
            {isMobile && (
                <MobileUploadsTable
                    data={documents}
                    onDocumentClick={(doc: any) => setViewDcument(doc)}
                />
            )}

            <UploadModal
                isOpen={uploadModal}
                close={() => setUploadModal(false)}
                page={currentPage.address}
            />

            <Modal isOpen={!!viewDocument} close={() => setViewDcument(null)}>
                <FilePreview
                    type={viewDocument?.document_type}
                    url={viewDocument?.document_url}
                    closePreview={() => setViewDcument(null)}
                />
            </Modal>
        </div>
    );
};

const RequiredUploads: React.FC = () => {
    const [requestModal, setRequestModal] = useState<boolean>(false);
    const { currentPage } = useLightHouse();

    const isMobile = useIsMobile();
    const { requests } = useDocuments();

    return (
        <div className="w-full py-4 px-2">
            {!isMobile && (
                <div className="w-full flex justify-end px-2 mb-4">
                    <ButtonSmall
                        onClick={() => setRequestModal(true)}
                    >{`Create New Request`}</ButtonSmall>
                </div>
            )}

            {isMobile && (
                <div
                    onClick={() => setRequestModal(true)}
                    className="w-full justify-end px-2 mb-4 bg-gray-100 py-2 border border-dashed text-center hover:bg-gray-200 cursor-pointer"
                >
                    {`Create New Request`}
                </div>
            )}

            {!isMobile && <RequestsTable data={requests} />}
            {isMobile && <MobileRequestsTable data={requests} />}

            <CreateRequest
                isOpen={requestModal}
                close={() => setRequestModal(false)}
                page={currentPage.address}
            />
        </div>
    );
};

const Manager: React.FC<{ isOpen: boolean; close: () => void }> = ({ isOpen, close }) => {
    const [activeMenuItem, setActiveMenuItem] = useState<MenuItemType>(menuOptions[0]);
    const isMobile = useIsMobile();

    return (
        <Modal isOpen={isOpen} close={close} title={'Upload Document'}>
            {!isMobile ? (
                <div
                    className="w-full flex flex-col m-2 bg-white rounded-md "
                    style={{ maxWidth: 1200, width: '100%', height: '90vh' }}
                >
                    <div className="flex px-3 py-2 rounded-md font-medium border-b border-gray-200">
                        <div className="flex-grow text-sm font-title">{`Document Manager`}</div>
                        <span
                            onClick={() => close()}
                            className="material-symbols-outlined cursor-pointer text-gray-500 hover:text-red-500"
                            style={{ fontSize: 20 }}
                        >
                            cancel
                        </span>
                    </div>

                    <div className="w-full flex flex-grow overflow-y-auto">
                        <Menu
                            activeItem={activeMenuItem}
                            setMenuItem={(item) => setActiveMenuItem(item)}
                        />
                        {activeMenuItem.id === 0 && <Uploads />}
                        {activeMenuItem.id === 1 && <RequiredUploads />}
                    </div>
                </div>
            ) : (
                <div
                    className="w-full flex flex-col m-2 bg-white rounded-md "
                    style={{ maxWidth: 1200, width: '100%', height: '90vh', minWidth: 300 }}
                >
                    <div className="flex px-3 py-2 rounded-md font-medium border-b border-gray-200">
                        <div className="flex-grow text-sm font-title">{`Document Manager`}</div>
                        <span
                            onClick={() => close()}
                            className="material-symbols-outlined cursor-pointer text-gray-500 hover:text-red-500"
                            style={{ fontSize: 20 }}
                        >
                            cancel
                        </span>
                    </div>
                    <div className="w-full bg-gray-200 flex items-center">
                        {menuOptions.map((item) => (
                            <div
                                className={classNames(
                                    'w-full text-sm cursor-pointer text-center p-2',
                                    {
                                        'text-white bg-primary-500 hover:bg-primary-700':
                                            activeMenuItem.id === item.id,
                                        'hover:bg-gray-300': activeMenuItem.id !== item.id,
                                    },
                                )}
                                onClick={() => setActiveMenuItem(item)}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                    <div className="w-full overflow-y-auto">
                        {activeMenuItem.id === 0 && <Uploads />}
                        {activeMenuItem.id === 1 && <RequiredUploads />}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default Manager;
