import moment from 'moment';
import { fieldTypes } from './enums';
import { FormInterface } from './interfaces';

export const dependents: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'first_name',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'last_name',
        label: 'Last Name',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'dob',
        label: 'Date of Birth',
        placeholder: '10 July 1996',
    },
    {
        type: fieldTypes.RADIO,
        id: 'claimed',
        label: 'Claimed?',
        options: [
            { label: 'Yes', value: true },
            { label: 'No', value: 'false' },
        ],
    },
    {
        type: fieldTypes.RADIO,
        id: 'contributes',
        label: 'Contributes to household income?',
        options: [
            { label: 'Yes', value: true },
            { label: 'No', value: 'false' },
        ],
    },
];

export const template: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'firstName',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'falvour',
        label: 'Flavour',
        placeholder: 'Click to Select Flavour',
        options: [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' },
        ],
    },
    {
        type: fieldTypes.RADIO,
        // isMulti: true,
        id: 'business',
        label: 'Have any business?',
        options: [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
        ],
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'dob',
        label: 'Date of Birth',
        placeholder: 'Date of Birth',
    },
    // {
    //     type: fieldTypes.TABLE,
    //     id: 'dependents',
    //     defaultValue: [
    //         {
    //             id: 0,
    //             first_name: 'John Doe',
    //             last_name: 'Son',
    //             dob: new Date(),
    //         },
    //         {
    //             id: 1,
    //             first_name: 'Jane Doe',
    //             last_name: 'Daughter',
    //             dob: new Date(),
    //         },
    //         {
    //             id: 2,
    //             first_name: 'John Jane',
    //             last_name: 'Maid',
    //             dob: new Date(),
    //         },
    //     ],
    //     displayColumns: [
    //         {
    //             name: 'First Name',
    //             key: 'first_name',
    //         },
    //         {
    //             name: 'Last Name',
    //             key: 'last_name',
    //         },
    //         {
    //             name: 'Date of Birth',
    //             key: 'dob',
    //         },
    //     ],
    //     rowStructure: dependents,
    // },
    {
        type: fieldTypes.SEPERATOR,
    },
];

export const yourInformation: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'firstName',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lastName',
        label: 'Last Name',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'dob',
        label: 'Date of Birth',
        placeholder: '10 August 1998',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'email',
        label: 'E mail',
        placeholder: 'johndoe@email.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'phone',
        label: 'Phone',
        placeholder: '(555) 555-1234',
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'address',
        label: 'Address',
        placeholder: `Street Address\nApartment, suite, unit, building, etc.\nTampa Bay`,
        area: true,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'state',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'zip',
        label: 'Zip',
        placeholder: '32003',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'county',
        label: 'County',
        placeholder: 'Brevard County',
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'dl',
        label: 'DL',
        placeholder: 'F255-921-50-094-0',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ssn',
        label: 'SSN',
        placeholder: '778-62-8144',
    },
];

export const businessInformation: FormInterface = [
    {
        type: fieldTypes.RADIO,
        id: 'business',
        label: 'How many businesses do you own?',
        options: [
            { value: 0, label: 'None' },
            { value: 1, label: 'Only one' },
            { value: 2, label: 'Multiple' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: 0,
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'business_none',
                            label: 'No Business Name',
                            placeholder: 'Brevard County',
                        },
                    ],
                },
                {
                    value: 1,
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'business_one',
                            label: 'One Business Name',
                            placeholder: 'Brevard County',
                        },
                    ],
                },
                {
                    value: 2,
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'business_many',
                            label: 'Multiple Business Name',
                            placeholder: 'Brevard County',
                        },
                    ],
                },
            ],
        },
    },
];
