import classNames from 'classnames';
import { enumSections, fieldTypes } from '../../utils/enums';
import { compareItemsforChange, useFeatureEnabled } from '../../utils/helpers';
import {
    BrickInterface,
    ConcreteInterface,
    FormInterface,
    FormStateInterface,
    PageInterface,
} from '../../utils/interfaces';
import FormConstructor from './AdminFormConstructor';
import CheckMarkLogo from '../assets/checkmark.svg';
import { useEffect, useState } from 'react';
import InputGroup from '../admin/InputGroup';
import Button from './Button';
import { useAuthState } from '../../context/auth';
import CommentThread from './CommentThread';
import { useFormDispatch, useFormState } from '../../context/form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSaveDispatch } from '../../context/save';
import { getfetchFunction } from '../../api';
import useDocuments from '../../api/hooks/useDocument';
import { ActionsCell as DocumentActionsCell } from '../document/UploadsTable';
import { ActionsCell as RequestActionsCell } from '../document/RequestsTable';
import UploadModal from '../document/UploadModal';
import { date } from '../../utils/format';
import { build } from '../../config';
import CreateRequest from '../document/CreateRequestModal';
import Modal from './Modal';
import { FilePreview } from '../document/Manager';
import useSectionLock from '../../api/hooks/useSectionLocks';

function isBrick(field: BrickInterface | ConcreteInterface): field is BrickInterface {
    return (field as BrickInterface).id !== undefined;
}

const analyzeFormStructure = (
    formStructure: FormInterface,
    form: FormStateInterface,
    second: any,
): boolean => {
    let isChanged = false;

    formStructure?.map((field) => {
        if (isBrick(field)) {
            switch (field.type) {
                case fieldTypes.EDITABLE_TABLE:
                    // DefaultRowKeys
                    let keys = [];
                    field.defaultRows.map((item: any) => {
                        keys.push(item[1].id);
                        keys.push(item[1].id + '_PS');
                        keys.push(item[1].id + '_IF');
                        keys.push(item[1].overrideBooleanId);
                        keys.push(item[1].overrideValueId);
                    });

                    if (field.addColumns) {
                        for (let i = 0; i <= 20; i++) {
                            const id_name =
                                field.addColumns[0].id_prefix +
                                `${i + 1}` +
                                field.addColumns[0].id_suffix;

                            const id_value =
                                field.addColumns[1].id_prefix +
                                `${i + 1}` +
                                field.addColumns[1].id_suffix;

                            keys.push(id_name);
                            keys.push(id_value);
                        }
                    }

                    field.totalRow?.map((item: any) => {
                        item.isInput && keys.push(item.id);
                        item.sharable && keys.push(item.sharable);
                        item.allowable && keys.push(item.allowable);
                        item.lesserGreaterId && keys.push(item.lesserGreaterId);
                    });

                    keys.map((key) => {
                        const isLocalChanged = compareItemsforChange(form[key], second[key]);
                        if (isLocalChanged) {
                            isChanged = true;
                        }
                    });

                    break;

                case fieldTypes.CROSSROAD:
                    const isCrossroadLocalChanged = compareItemsforChange(
                        form[field.id],
                        second[field.id],
                    );

                    if (isCrossroadLocalChanged) {
                        isChanged = true;
                    }

                    field.preForm?.map((field) => {
                        if (isBrick(field)) {
                            const isLocalChanged = compareItemsforChange(
                                form[field.id],
                                second[field.id],
                            );

                            if (isLocalChanged) {
                                isChanged = true;
                            }

                            if (isChanged === false) {
                                field.branches?.paths.forEach(({ form: BranchStructure }) => {
                                    if (isChanged) {
                                        return;
                                    }
                                    isChanged = analyzeFormStructure(BranchStructure, form, second);
                                });
                            }
                        }
                    });

                    break;

                default:
                    const isLocalChanged = compareItemsforChange(form[field.id], second[field.id]);
                    if (isLocalChanged) {
                        isChanged = true;
                    }
            }

            if (isChanged === false) {
                field.branches?.paths.forEach(({ form: BranchStructure }) => {
                    if (isChanged) {
                        return;
                    }
                    isChanged = analyzeFormStructure(BranchStructure, form, second);
                });
            }
        }
    });

    return isChanged;
};

const MainSection: React.FC<{ currentPage: PageInterface; business?: any }> = ({
    currentPage,
    business,
}) => {
    const { address, section, formStructure } = currentPage;

    const formData = useFormState();
    const dispatch = useFormDispatch();

    const setFormState = ({ id, value }: { id: string; value: any }) => {
        dispatch({
            type: 'UPDATE_FORM',
            payload: { data: { [id]: value } },
        });
    };

    const setFormStateBusiness = ({ id, value }: { id: string; value: any }) => {
        dispatch({
            type: 'UPDATE_FORM_BUSINESS',
            payload: { businessId: business.id, data: { [id]: value } },
        });
    };

    const { irs_account_id } = useParams();
    const { success } = useAuthState();
    const saveDispatch = useSaveDispatch();

    const fetchFunction = getfetchFunction(section.id);

    const { data: serverData } = useQuery(
        [`${section.id}`, irs_account_id],
        () => fetchFunction(irs_account_id as string),
        { enabled: !!irs_account_id && success },
    );

    useEffect(() => {
        if (!address) return;
        if (!formStructure) return;
        if (!serverData) return;
        if (!serverData.meta_data) return;

        if (compareItemsforChange(formData.meta_data, serverData.meta_data)) {
            saveDispatch('ADD_META_DATA', { data: formData });
        } else {
            saveDispatch('REMOVE_META_DATA', { data: formData });
        }

        if (business) {
            const serverBusiness = serverData.business_data.find(
                ({ id }: any) => id === business.id,
            );
            const formBusiness = formData.business_data.find(({ id }: any) => id === business.id);

            if (!serverBusiness) return;

            const isChanged = analyzeFormStructure(formStructure, formBusiness, serverBusiness);

            if (isChanged) {
                saveDispatch('ADD_BUSINESS_ADDRESS', { address, id: business.id, data: formData });
            } else {
                saveDispatch('REMOVE_BUSINESS_ADDRESS', { id: business.id, address });
            }
        } else {
            const isChanged = analyzeFormStructure(formStructure, formData, serverData);

            if (isChanged) {
                saveDispatch('ADD_ADDRESS', { address, data: formData });
            } else {
                saveDispatch('REMOVE_ADDRESS', { address });
            }
        }
    }, [formData]);

    let businessFormState =
        formData.business_data?.find(({ id }: any) => id === business?.id) || {};
    businessFormState = { ...businessFormState, _errors: formData._errors[business?.id || ''] };

    switch (currentPage.sectionId) {
        case enumSections.BUSINESS_INFORMATION:
            return (
                <div className="flex-grow flex justify-center">
                    <FormConstructor
                        className={classNames('flex flex-grow px-1 pb-16')}
                        structure={formStructure}
                        business={business}
                        formState={{ ...businessFormState, _errors: formData._errors[business.id] }}
                        setFormState={setFormStateBusiness}
                    />
                </div>
            );

        default:
            return (
                <div className="flex-grow flex justify-center p-2">
                    <FormConstructor
                        className={classNames('flex flex-grow')}
                        structure={formStructure}
                        formState={formData}
                        setFormState={setFormState}
                    />
                </div>
            );
    }
};

export type CommentType = {
    id: string;
    email_address: string;
    name: string;
    text: string;
    createdAt: string;
};

const Comments: React.FC<{ currentPage: PageInterface; business?: any }> = ({
    business,
    currentPage,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [comment, setComment] = useState('');
    const { user_id, name } = useAuthState();

    const formData = useFormState();
    const formDispatch = useFormDispatch();

    const saved_comments = business
        ? formData.meta_data?.form_status[business.id]
            ? formData.meta_data?.form_status[business.id][currentPage.address + '_comments']
            : undefined
        : formData.meta_data?.form_status
        ? formData.meta_data?.form_status[currentPage.address + '_comments']
        : undefined;

    const upsertComments = (comments: Array<CommentType>) => {
        if (business) {
            formDispatch({
                type: 'UPDATE_COMMENTS_BUSINESS',
                payload: {
                    businessId: business.id,
                    data: { [currentPage.address + '_comments']: comments },
                },
            });
        } else {
            formDispatch({
                type: 'UPDATE_COMMENTS',
                payload: { data: { [currentPage.address + '_comments']: comments } },
            });
        }
    };

    const addComment = () => {
        if ([undefined, null, ''].includes(comment)) {
            return;
        }

        const newComment: CommentType = {
            id: user_id!,
            email_address: formData.email_address,
            name: name!,
            text: comment,
            createdAt: new Date().toISOString(),
        };

        if (saved_comments) {
            upsertComments([...saved_comments, newComment]);
        } else {
            upsertComments([newComment]);
        }
        setComment('');
    };

    return (
        <>
            {saved_comments?.length > 0 ? (
                <>
                    <div className="bg-primary-500 text-xs font-bold text-white rounded-full h-4 w-4 flex mr-1 justify-center">
                        {saved_comments.length}
                    </div>

                    <span
                        onClick={() => setIsOpen(true)}
                        className="material-icons cursor-pointer text-primary-500 hover:text-primary-800"
                    >
                        comment
                    </span>
                </>
            ) : (
                <span
                    onClick={() => setIsOpen(true)}
                    className="material-icons cursor-pointer text-gray-500 hover:text-gray-800 "
                >
                    comment
                </span>
            )}

            {isOpen && (
                <div
                    className="absolute rounded-md flex flex-col h-full w-64 right-0 top-0 bg-white z-20 shadow-md"
                    style={{ minHeight: 300 }}
                >
                    <div className="w-full rounded-md flex items-center bg-gray-700 px-2">
                        <div className="font-title text-sm p-0.5 flex-grow text-white">
                            {'Comments'}
                        </div>
                        <span
                            onClick={() => setIsOpen(false)}
                            className="material-icons cursor-pointer text-white hover:text-primary-400 "
                        >
                            close
                        </span>
                    </div>

                    <div className="flex-grow overflow-y-auto">
                        <CommentThread comments={saved_comments} />
                    </div>

                    <div className="flex bg-gray-50">
                        <InputGroup
                            id="comment"
                            label=""
                            placeholder=""
                            setValue={(v) => setComment(v)}
                            type={fieldTypes.TEXT_INPUT}
                            value={comment}
                            area={true}
                        />
                        <div
                            onClick={addComment}
                            className="px-3 py-2 bg-primary-500 cursor-pointer hover:bg-primary-700 text-sm text-white font-title"
                        >
                            ADD
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const AttachedDocument: React.FC<any> = (document) => {
    const [isOpen, setIsOpen] = useState(false);
    const [viewDocument, setViewDocument] = useState<any>(null);

    return (
        <>
            <div
                className={
                    'w-full flex items-center justify-center border-t cursor-pointer px-2 py-2 bg-gray-100'
                }
            >
                {document.description && <div className="mr-2 text-sm">{document.description}</div>}
                <div
                    onClick={() => setViewDocument(document)}
                    className="flex-grow text-sm hover:underline text-primary-500"
                >
                    <div>{document.document_name}</div>
                </div>

                <div className="mr-2 text-sm text-gray-600">{date(document.last_updated_date)}</div>

                {document.request_fullfill ? (
                    <RequestActionsCell small original={document} />
                ) : (
                    <DocumentActionsCell small original={document} />
                )}
            </div>

            <Modal isOpen={!!viewDocument} close={() => setViewDocument(null)}>
                <FilePreview
                    type={viewDocument?.document_type}
                    url={viewDocument?.document_url}
                    closePreview={() => setViewDocument(null)}
                />
            </Modal>
        </>
    );
};

const EvalSection: React.FC<{ currentPage: PageInterface; business?: any; isLoading: boolean }> = ({
    business,
    currentPage,
    isLoading,
}) => {
    const formData = useFormState();
    const dispatch = useFormDispatch();

    const { success: isDocumentEnabled } = useFeatureEnabled('Document');

    const { irs_account_id } = useParams();

    const {
        lockDetails,
        setSectionLock,
        isLoading: isSectionLoading,
    } = useSectionLock(irs_account_id);

    const sectionKey = business ? currentPage.address + ':' + business.id : currentPage.address;
    const isSectionLocked = lockDetails[sectionKey];
    const lockSection = () => setSectionLock({ key: sectionKey, value: !isSectionLocked });

    const [seeAll, setSeeAll] = useState<boolean>(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [requestModal, setRequestModal] = useState<boolean>(false);

    const { allDocuments } = useDocuments();
    const attachedDocuments = allDocuments?.filter((item: any) => {
        return item.section === currentPage.address;
    });

    const completeFlag = business
        ? formData.meta_data?.page_json[business.id]
            ? formData.meta_data?.page_json[business.id][currentPage.address]
            : undefined
        : formData.meta_data?.page_json
        ? formData.meta_data?.page_json[currentPage.address]
        : undefined;

    const upsertPageJson = (data: any) => {
        if (business) {
            dispatch({
                type: 'UPDATE_PAGE_STATUS_BUSINESS',
                payload: { businessId: business.id, data },
            });
        } else {
            dispatch({
                type: 'UPDATE_PAGE_STATUS',
                payload: { data },
            });
        }
    };

    if (currentPage.address === 'business_home') return <></>;

    return (
        <div
            id={currentPage.address + business?.id}
            key={currentPage.address + business?.id}
            className="flex flex-col w-full  my-8 bg-white rounded-md relative"
            style={{ minWidth: 550 }}
        >
            <div className="w-full flex items-center border-b-2 border-primary-500 px-2">
                <div
                    className="cursor-pointer flex items-center"
                    onClick={() => upsertPageJson({ [currentPage.address]: !completeFlag })}
                >
                    {completeFlag ? (
                        <span
                            className="material-icons bg-primary-500 rounded-full text-white"
                            style={{ fontSize: 20 }}
                        >
                            check
                        </span>
                    ) : (
                        <div
                            className={
                                'h-5 w-5 bg-gray-50 rounded-3xl hover:bg-gray-100  border border-gray-300 p-px'
                            }
                        />
                    )}
                </div>
                <div className="font-title  p-2 flex-grow">
                    {business ? `${currentPage.name} | ${business.name}` : currentPage.name}
                </div>
                {build.section_locking && (
                    <span
                        data-tip={isSectionLocked ? 'Unlock Section' : 'Lock Section'}
                        onClick={lockSection}
                        className={classNames('cursor-pointer material-symbols-outlined mr-2', {
                            'animate-spin': isSectionLoading,
                            'text-gray-500 hover:text-gray-800': !isSectionLocked,
                            'text-primary-500 hover:text-primary-800': isSectionLocked,
                        })}
                    >
                        {isSectionLoading ? 'refresh' : isSectionLocked ? 'lock' : `lock_open`}
                    </span>
                )}
                {isDocumentEnabled && (
                    <>
                        <span
                            data-tip="Create Upload Request"
                            onClick={() => setRequestModal(true)}
                            className="cursor-pointer material-symbols-outlined text-gray-500 hover:text-gray-800 mr-2"
                        >
                            add_circle
                        </span>
                        <span
                            data-tip="Upload File"
                            onClick={() => setUploadModal(true)}
                            className="cursor-pointer material-symbols-outlined text-gray-500 hover:text-gray-800 mr-2"
                        >
                            upload_file
                        </span>
                        <div
                            onClick={() => setSeeAll(!seeAll)}
                            className="cursor-pointer  flex justify-center relative"
                        >
                            <span
                                data-tip="View Attached File"
                                className={classNames(
                                    'material-symbols-outlined text-gray-500 hover:text-gray-800 mr-2',
                                    {
                                        'text-primary-500 hover:text-primary-800': seeAll,
                                        'text-gray-500 hover:text-gray-800': !seeAll,
                                    },
                                )}
                            >
                                attach_file
                            </span>
                            {!!attachedDocuments?.length && (
                                <div
                                    className={classNames(
                                        'absolute w-4 text-center font-bold text-white bg-primary-500 rounded-full text-xs font-body top-3 left-2',
                                    )}
                                >
                                    {attachedDocuments.length}
                                </div>
                            )}
                        </div>
                    </>
                )}
                <Comments currentPage={currentPage} business={business} />
            </div>

            {isDocumentEnabled &&
                seeAll &&
                attachedDocuments
                    ?.filter((_: any, index: number) => {
                        return seeAll ? true : index < 2;
                    })
                    .map((item: any) => <AttachedDocument {...item} />)}

            {isLoading ? (
                <span className="flex font-title flex-grow items-center justify-center py-6 ">
                    <span
                        className="material-icons animate-spin h-5 w-5 flex items-center justify-center"
                        style={{ fontSize: 20 }}
                    >
                        refresh
                    </span>
                    <div className="ml-3">Loading</div>
                </span>
            ) : (
                <MainSection currentPage={currentPage} business={business} />
            )}

            <div className="p-2" />

            <UploadModal
                pageLock
                page={currentPage.address}
                isOpen={uploadModal}
                close={() => setUploadModal(false)}
            />

            <CreateRequest
                pageLock
                page={currentPage.address}
                isOpen={requestModal}
                close={() => setRequestModal(false)}
            />
        </div>
    );
};

export default EvalSection;
