import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertP5data = (
    year: string,
    { taxprep_account_id, business_vehicle_details, meta_data }: any,
) => {
    const regEx = /^new/;
    const data = business_vehicle_details
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: meta_data.irs_account_id,
                    taxprep_account_id: taxprep_account_id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/businessVehicleByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data,
        taxprep_year: year,
    });
};

const b_p5_secForm: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'no_miles_vehicle_drives',
        placeholder: 'Select',
        label: 'Of the total number of miles that you drove your vehicle during 2022, enter the number of miles you used your vehicle for: ',
        options: [
            { value: 'Bussiness', label: 'Bussiness' },
            { value: 'Commuting', label: 'Commuting' },
            { value: 'Other', label: 'Other' },
        ],
        span: 2,
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'vehicle_service_date',
        label: 'When did you place your vehicle in service for business purposes?',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.RADIO,
        id: 'vehicle_available',
        label: 'Was your vehicle available for personal use during off-duty hours?',
        options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
        ],
    },
    {
        type: fieldTypes.RADIO,
        id: 'another_vehicle',
        label: 'Do you (or your spouse) have another vehicle available for personal use?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
    {
        type: fieldTypes.RADIO,
        id: 'support_deduction',
        label: 'Do you have evidence to support your deduction?',
        options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: 'Yes',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'evidence_deduction',
                            label: 'Provide evidence If it is written',
                            placeholder: 'john',
                        },
                    ],
                },
            ],
        },
    },
];

const b_p5SecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_vehicle_details',
        label: 'Do you have Information On Your Vehicle?',
        header: 'Information On Your Vehicle',
        displayColumns: [
            {
                name: 'No miles vehicle drives',
                key: 'no_miles_vehicle_drives',
            },
            {
                name: 'Vehicle service date',
                key: 'vehicle_service_date',
            },
        ],
        formStructure: b_p5_secForm,
    },
];

export const page: PageInputInterface = {
    address: 'business_vehicle_details',
    name: 'Information On Your Vehicle',
    sectionId: enumSections.BUSINESS_INFORMATION,

    formStructure: b_p5SecForm,
    save: upsertP5data,
};
