import { toast } from 'react-toastify';

export const showToast = (message: string, toastType: 'success' | 'error', duration?: number) => {
    console.log(duration);
    toast(`${toastType === 'success' ? '🟢 ' : '🔴 '} ${message}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: duration ? duration : 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: toastType,
        className: 'dark-toast rounded',
    });
};
