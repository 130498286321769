import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { assignTaxPro } from '../../api/admin';
import { useAuthState } from '../../context/auth';
import { fieldTypes } from '../../utils/enums';
import Button from './Button';
import InputGroup from '../client/InputGroup';
import useTaxPrep from '../../api/hooks/useTaxPrep';
import Dropdown from '../client/Dropdown';
import { showToast } from './Toaster';

const AssignTaxPro: React.FC<{
    close: () => void;
    user: any;
}> = ({ close, user }) => {
    const [taxPro, setTaxPro] = useState<string>(user?.tax_preparer_id || '');

    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const { taxPreparers } = useTaxPrep();

    const taxProOptions = taxPreparers
        ? taxPreparers.map((item: any) => ({
              label: item.u_first_name + ' ' + item.u_last_name,
              value: item.user_id,
          }))
        : [];

    const queryClient = useQueryClient();

    const mutation = useMutation(assignTaxPro, {
        onSuccess: () => {
            queryClient.invalidateQueries('clients-list');
            showToast('Tax Pro has been Assigned !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const assignTaxProFunc = () => {
        console.log('tax pro', taxPro);
        // if (!taxPro) return;

        mutation.mutate([
            {
                user_id: user?.user_id || '',
                irs_account_id: user?.irs_account_id,
                tax_preparer_id: taxPro,
            },
        ]);
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Assign Tax Pro`}</div>

            <div className="flex flex-col w-full">
                <Dropdown
                    id="taxPro"
                    label="Assigned Tax Professional"
                    placeholder=""
                    value={taxPro}
                    setValue={(value) => {
                        setTaxPro(value);
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                    className="w-full mt-1"
                    staticError={error.u_email}
                    options={taxProOptions}
                />

                <Button
                    isLoading={mutation.isLoading}
                    onClick={assignTaxProFunc}
                    className="text-center w-full mt-8"
                >
                    {'CONFIRM ASSIGNMENT'}
                </Button>
            </div>
        </div>
    );
};

export default AssignTaxPro;
