import { enumSections, fieldTypes, enumPageTypes } from './enums';
import {
    BrickInterface,
    ConcreteInterface,
    FormInterface,
    FormStateInterface,
    PageInputInterface,
    PageInterface,
    SectionInterface,
} from './interfaces';

import pagesImport from '../resource';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getfetchFunction } from '../api';
import { useAuthState } from '../context/auth';
import { useEffect, useState } from 'react';
import { useFormState } from '../context/form';
import { fetchClientWithDependent } from '../api/yourInfo';
import { fetchClientWithFinance } from '../api/finance';
import { fetchClientWithBusiness } from '../api/business';
import { build } from '../config';

export let sectionsDefault: Array<SectionInterface> = [
    { id: enumSections.YOUR_INFORMATION, name: 'Your Information', subSections: [] },
    { id: enumSections.FINANCIAL_INFORMATION, name: 'Financial Information', subSections: [] },
    {
        id: enumSections.BUSINESS_INFORMATION,
        name: 'Business Information',
        tabbed: true,
        subSections: [],
    },
    { id: enumSections.OTHER_INFORMATION, name: 'Other Information', subSections: [] },
];

export const getPageSection = (page: PageInterface | PageInputInterface) => {
    return sectionsDefault.find(({ id }) => id === page.sectionId) as SectionInterface;
};

export const usePageType = () => {
    const { pathname } = useLocation();

    const pathnames = pathname?.split('/');
    switch (pathnames[1]) {
        case 'form':
            return enumPageTypes.FORM;

        case 'iframe-form':
            return enumPageTypes.FORM;

        case 'section':
            return enumPageTypes.WELCOME;

        case 'iframe-section':
            return enumPageTypes.WELCOME;

        default:
            return enumPageTypes.UNKNOWN;
    }
};

type URLParams = { address: string; sectionId: string; businessId: string };

export const useServerData = (irs_account_id: string | null | undefined) => {
    const { success } = useAuthState();

    const one = useQuery(
        [`1`, irs_account_id],
        () => fetchClientWithDependent(irs_account_id as string),
        { enabled: !!irs_account_id && success },
    );
    const two = useQuery(
        [`2`, irs_account_id],
        () => fetchClientWithFinance(irs_account_id as string),
        { enabled: !!irs_account_id && success },
    );
    const three = useQuery(
        [`3`, irs_account_id],
        () => fetchClientWithBusiness(irs_account_id as string),
        { enabled: !!irs_account_id && success },
    );
    const four = useQuery(
        [`4`, irs_account_id],
        () => fetchClientWithFinance(irs_account_id as string),
        { enabled: !!irs_account_id && success },
    );

    return {
        [enumSections.YOUR_INFORMATION]: one.data || {},
        [enumSections.FINANCIAL_INFORMATION]: two.data || {},
        [enumSections.BUSINESS_INFORMATION]: three.data || {},
        [enumSections.OTHER_INFORMATION]: four.data || {},
    };
};

export const useLightHouse = () => {
    const { irs_account_id, token } = useAuthState();
    const { address = '', businessId, sectionId } = useParams<URLParams>();
    const { pathname } = useLocation();
    const pageType = usePageType();
    const { pages, sections } = pageRefiner();

    let data = {
        knownPage: true,
        currentSectionId: enumSections.YOUR_INFORMATION,
        currentSection: sections[0],
        fetchFunction: async (irs_account_id: string) => null,
        serverData: {} as { [key: string]: any },
        address,
        pageType,
        businessId,
        pathname,
        pages,
        sections,
        currentPage: {} as PageInterface,
        isLastSubSection: false,
    };

    if (pageType === enumPageTypes.FORM) {
        data.currentSectionId = pages[address].sectionId;
        data.currentPage = pages[address];
        data.currentSection = sections.find(
            ({ id }) => id === data.currentSectionId,
        ) as SectionInterface;
        data.pageType = enumPageTypes.FORM;
    } else if (pageType === enumPageTypes.WELCOME) {
        data.currentSectionId = parseInt(sectionId || '0');
        data.currentSection = sections.find(
            ({ id }) => id === data.currentSectionId,
        ) as SectionInterface;
        data.pageType = enumPageTypes.WELCOME;
    }

    data.fetchFunction = getfetchFunction(data.currentSectionId);

    const { [data.currentSectionId]: fetchData } = useServerData(irs_account_id);
    data.serverData = fetchData;

    const currentSubSectionIndex = data.currentSection.subSections.findIndex(
        (i) => i.address === address,
    );
    data.isLastSubSection = currentSubSectionIndex === data.currentSection.subSections.length - 1;

    return data;
};

export const pageRefiner = () => {
    let pages: { [key: string]: PageInterface } = {};
    let sections = sectionsDefault;

    pagesImport.map((page, index) => {
        const section = getPageSection(page);
        const isLastPage = index === pagesImport.length - 1;

        if (index === 0) {
            // If first page iniitallize at zero
            let prev = null;
            let next = null;
            if (index + 1 < pagesImport.length) next = pagesImport[index + 1].address;

            pages[page.address] = {
                ...page,
                prev,
                next,
                section,
                overallPosition: 0,
                isLastPage,
                isLastSubSection: false,
            };
        } else {
            // Else calculate previous page and repeat the same process
            const prevPageAddress = pagesImport[index - 1].address;
            const prevPage = pages[prevPageAddress];

            let prev = prevPage.address;
            let next = null;
            if (index + 1 < pagesImport.length) next = pagesImport[index + 1].address;

            let isLastSubSection = false;
            if (!isLastPage) {
                const nextPage = pagesImport[index + 1];
                if (nextPage.sectionId !== page.sectionId) isLastSubSection = true;
            } else {
                isLastSubSection = true;
            }

            pages[page.address] = {
                ...page,
                prev,
                next,
                section,
                overallPosition: index,
                isLastSubSection,
                isLastPage,
            };
        }

        sections = sections.map((section) => {
            if (section.id === page.sectionId)
                return { ...section, subSections: [...section.subSections, pages[page.address]] };
            else return section;
        });
    });

    return { pages, sections };
};

export const compareItemsforChange = (first: any, second: any) => {
    if (first === undefined && second === null) return false;
    if (first === null && second === undefined) return false;

    if (first === '' && second === null) return false;
    if (first === null && second === '') return false;

    if (first === '' && second === undefined) return false;
    if (first === undefined && second === '') return false;

    if (typeof first !== typeof second) {
        return true;
    }

    if (first === null && second === null) return false;
    if (first === null && second !== null) {
        return true;
    }

    if (first === undefined && second === undefined) return false;
    if (first === undefined && second !== undefined) {
        return true;
    }

    if (typeof first === 'object') {
        const result = Object.keys(first).map((key) => {
            return compareItemsforChange(first[key], second[key]);
        });

        if (result.includes(true)) {
            return true;
        } else {
            return false;
        }
    }

    if (Array.isArray(first)) {
        if (Array.isArray(second)) {
            if (first.length !== second.length) {
                return true;
            }

            const result = first.map((item, index) => {
                return compareItemsforChange(item, second[index]);
            });

            if (result.includes(true)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    if (first !== second) {
        return true;
    }

    return false;
};

interface WindowSize {
    height?: number;
    width?: number;
}

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

const MOBILE_WIDTH = 800;

export const useIsMobile = () => {
    const { width } = useWindowSize();
    return width ? width <= MOBILE_WIDTH : false;
};

export const calculatePercentage = ({
    page_json,
    business_data,
}: {
    page_json: any;
    business_data: any[];
}) => {
    const { sections } = pageRefiner();

    let totalPages = 0;
    let completedPages = 0;
    const businessCount = business_data?.length;

    sections.forEach(({ id, subSections }) => {
        if (id !== enumSections.BUSINESS_INFORMATION) {
            subSections.forEach(({ address }) => {
                if (page_json?.[address]) {
                    completedPages++;
                }
                totalPages++;
            });
        } else {
            let businessPages = 0;

            subSections.forEach(({ address }) => {
                if (address !== 'business_home' && typeof page_json === 'object') {
                    Object.keys(page_json).map((key) => {
                        if (typeof page_json?.[key] === 'object') {
                            const activeId = business_data?.find((b) => b.id === key);

                            if (activeId && page_json?.[key][address]) {
                                completedPages++;
                            }
                        }
                    });
                    businessPages++;
                }
            });

            totalPages += businessPages * (businessCount || 0);
        }
    });

    return Math.floor((completedPages * 100) / totalPages);
};

export const useCompletedPercentage = (irs_account_id: string | null | undefined) => {
    const { meta_data: form_meta_data } = useFormState();

    const {
        [enumSections.BUSINESS_INFORMATION]: { meta_data, business_data },
    } = useServerData(irs_account_id);

    if (!form_meta_data) {
        return 0;
    }

    return calculatePercentage({
        page_json: Object.keys(form_meta_data?.page_json).length
            ? form_meta_data.page_json
            : meta_data?.page_json,
        business_data,
    });
};

export const useFeatureEnabled = (
    feature: 'Document' | 'Form' | 'Microsoft Login' | 'Export' | 'Tax Prep',
): { success: boolean; feature?: any } => {
    const auth = useAuthState();

    const isDynamicFeatureEnabled = build.dynamic_feature_enabled;

    switch (feature) {
        case 'Document': {
            const isConfigured = build.document_upload;
            const isEnabled = auth.feature_data.find(({ feature_enable, feature_name }) => {
                return [1, 'yes'].includes(feature_enable) && feature_name === 'Document';
            });

            return isDynamicFeatureEnabled
                ? { success: !!isEnabled, feature: isEnabled }
                : { success: isConfigured };
        }
        case 'Export': {
            const isConfigured = build.data_export;
            const isEnabled = auth.feature_data.find(({ feature_enable, feature_name }) => {
                return [1, 'yes'].includes(feature_enable) && feature_name === 'Export';
            });

            return isDynamicFeatureEnabled
                ? { success: !!isEnabled, feature: isEnabled }
                : { success: isConfigured };
        }

        case 'Form': {
            let isFormEnabled = {};
            if (isDynamicFeatureEnabled) {
                const isEnabled = auth.feature_data.find(
                    ({ feature_enable, feature_name }) =>
                        [1, 'yes'].includes(feature_enable) && feature_name === 'Form',
                );

                const { form_list } = isEnabled;

                form_list.split(',').forEach((f: string) => {
                    isFormEnabled = { ...isFormEnabled, [f]: true };
                });

                return { success: !!isEnabled, feature: isFormEnabled };
            } else {
                const isConfigured = build.feature_data.find(
                    ({ feature_enable, feature_name }) =>
                        [1, 'yes'].includes(feature_enable) && feature_name === 'Form',
                );
                const { form_list } = isConfigured!;

                form_list.split(',').forEach((f: string) => {
                    isFormEnabled = { ...isFormEnabled, [f]: true };
                });

                return { success: !!isConfigured, feature: isFormEnabled };
            }
        }

        case 'Microsoft Login': {
            const isConfigured = build.ms_sso_login;
            const isEnabled = build.ms_sso_login;

            return { success: isConfigured && isEnabled };
        }

        case 'Tax Prep': {
            const isEnabled = build.taxprep_module;
            return { success: isEnabled };
        }

        default:
            return { success: false };
    }
};

//function tyo decode JWT token
export const decodeJwtToken = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(jsonPayload);
};
