import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useSalesForce, { LoginArgsV2 } from '../api/hooks/useSalesForce';

import FormSection from './FormSection';
import EvalScreen from './EvalScreen';

const Loading: React.FC<{ errors: { [key: string]: string } }> = ({ errors }) => {
    const isError = Object.keys(errors).length;
    return (
        <div className="w-full h-screen flex flex-col items-center justify-center">
            {isError ? (
                <>
                    <div>{errors.message}</div>
                    <div>{JSON.stringify(errors.data)}</div>
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

const IFrameFormV2: React.FC<{}> = ({}) => {
    const [errors, setErrors] = useState({});
    const [searchParams] = useSearchParams();

    const { Encoded_Encrypted_Text, Encoded_Timestamp } = useParams();
    const { loginV2 } = useSalesForce();

    useEffect(() => {
        if (!Encoded_Encrypted_Text) {
            setErrors({ message: 'Encoded Text is Missing' });
            return;
        }

        if (!Encoded_Timestamp) {
            setErrors({ message: 'Encoded Timestamp is missing' });
            return;
        }

        loginV2.mutate({ Encoded_Encrypted_Text, Encoded_Timestamp });
    }, [Encoded_Encrypted_Text, Encoded_Timestamp]);

    return loginV2.isSuccess ? <EvalScreen iframe /> : <Loading errors={errors} />;
};

// const IFrameForm = () => {
//     return <FormSection iframe />;
// };

export default IFrameFormV2;
