import { useEffect, useState } from 'react';
import {
    FormStateInterface,
    interface_field_textInput,
    interface_terminal_message,
} from '../../utils/interfaces';
import Button from './Button';
import ParentGroup from '../client/ParentGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLightHouse } from '../../utils/helpers';
import { useFormDispatch, useFormState } from '../../context/form';
import { useMutation, useQueryClient } from 'react-query';

import classNames from 'classnames';
import { enumPageTypes, enumSections, fieldTypes } from '../../utils/enums';
import InputGroup from '../client/InputGroup';
import Modal from './Modal';
import CommentThread from './CommentThread';
import InlineUI from '../document/InlineUI';
import { build } from '../../config';
import check_img from '../../assets/check2.png';

/**
 * UI Component for Text Input and Text Area
 **/

type TerminalGroupProps = interface_terminal_message & {
    className?: string;
};

type CommentType = {
    id: string;
    email_address: string;
    name: string;
    text: string;
    createdAt: string;
};

const CompletionModal: React.FC<{ close: () => void }> = ({ close }) => {
    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ maxWidth: 500, width: '100%' }}>
            <div
                className="bg-white w-full max-w-3xl flex flex-col items-center justify-center rounded"
                style={{ maxHeight: '95%', minHeight: '40%' }}
            >
                <div className="p-2 border-2 border-primary-500 h-16 w-16 rounded-full">
                    <img src={check_img} className="h-full w-full" />
                </div>
                <div className="pt-5 pb-1">{`Form data has been successfully saved`}</div>

                <Button onClick={close} className="text-center w-full mt-8">
                    {'OKAY'}
                </Button>
            </div>
        </div>
    );
};

const AddComments: React.FC<{ close: () => void }> = ({ close }) => {
    const [comment, setComment] = useState<string>('');
    const dispatch = useFormDispatch();
    const { businessId, currentPage } = useLightHouse();
    const { meta_data, email_address, first_name, last_business_name } = useFormState();

    const saved_comments = businessId
        ? meta_data?.form_status[businessId]
            ? meta_data?.form_status[businessId][currentPage.address + '_comments']
            : undefined
        : meta_data?.form_status
        ? meta_data?.form_status[currentPage.address + '_comments']
        : undefined;

    const upsertComments = (comments: Array<CommentType>) => {
        if (businessId) {
            dispatch({
                type: 'UPDATE_COMMENTS_BUSINESS',
                payload: { businessId, data: { [currentPage.address + '_comments']: comments } },
            });
        } else {
            dispatch({
                type: 'UPDATE_COMMENTS',
                payload: { data: { [currentPage.address + '_comments']: comments } },
            });
        }
    };

    const addComment = () => {
        if ([undefined, null, ''].includes(comment)) {
            return;
        }

        const newComment: CommentType = {
            id: meta_data.id,
            email_address: email_address,
            name: first_name + ' ' + last_business_name,
            text: comment,
            createdAt: new Date().toISOString(),
        };

        if (saved_comments) {
            upsertComments([...saved_comments, newComment]);
        } else upsertComments([newComment]);

        close();
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="flex-grow overflow-y-auto">
                <CommentThread comments={saved_comments} />
            </div>

            <InputGroup
                area={true}
                id="comment"
                label="Comment"
                placeholder=""
                value={comment}
                setValue={(value: any) => setComment(value)}
                type={fieldTypes.TEXT_INPUT}
                className="w-full mt-1"
            />
            <Button onClick={addComment} className="text-center w-full mt-8">
                {'ADD COMMENT'}
            </Button>
        </div>
    );
};

const TerminalGroup: React.FC<TerminalGroupProps> = (props) => {
    const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
    const {
        pathname,
        currentPage,
        isLastSubSection,
        pageType,
        currentSectionId,
        currentSection,
        businessId,
    } = useLightHouse();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const isWelcome = pageType === enumPageTypes.WELCOME;

    const queryClient = useQueryClient();
    const { className, next } = props;

    const univarsalFormState = useFormState();
    const dispatch = useFormDispatch();

    const treatAsBusiness =
        currentSectionId === enumSections.BUSINESS_INFORMATION ||
        currentPage.sectionId === enumSections.BUSINESS_INFORMATION;

    const { business_data, meta_data } = univarsalFormState;
    const businessExtention = treatAsBusiness ? `/${businessId || business_data[0]?.id}` : ``;

    const completeFlag =
        pageType === enumPageTypes.FORM
            ? businessId
                ? meta_data?.page_json[businessId]
                    ? meta_data?.page_json[businessId][currentPage.address]
                    : undefined
                : meta_data?.page_json
                ? meta_data?.page_json[currentPage.address]
                : undefined
            : false;

    const saved_comments =
        pageType === enumPageTypes.FORM
            ? businessId
                ? meta_data?.form_status[businessId]
                    ? meta_data?.form_status[businessId][currentPage.address + '_comments']
                    : undefined
                : meta_data?.form_status
                ? meta_data?.form_status[currentPage.address + '_comments']
                : undefined
            : false;

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    let nextURL: string | undefined = isLastSubSection
        ? iframe
            ? `/iframe-section/${currentPage.sectionId + 1}`
            : `/section/${currentPage.sectionId + 1}`
        : iframe
        ? `/iframe-form/${currentPage.next}`
        : `/form/${currentPage.next}`;

    const upsertCompleteStatus = (data: any) => {
        if (businessId) {
            dispatch({
                type: 'UPDATE_PAGE_STATUS_BUSINESS',
                payload: { businessId, data },
            });
        } else {
            dispatch({
                type: 'UPDATE_PAGE_STATUS',
                payload: { data },
            });
        }
    };

    if (treatAsBusiness) {
        nextURL = nextURL + businessExtention;

        if (!businessExtention) nextURL = undefined;

        if (isLastSubSection) {
            let currentBusinessIndex = business_data.findIndex((b: any) => b.id === businessId);
            let nextBusiness = null;
            let isLastBusiness = false;

            isLastBusiness = currentBusinessIndex === business_data.length - 1;

            if (!isLastBusiness) {
                nextBusiness = business_data[currentBusinessIndex + 1].id;
                nextURL = iframe
                    ? `/iframe-form/basic_information/${nextBusiness}`
                    : `/form/basic_information/${nextBusiness}`;
            } else {
                nextURL = iframe
                    ? `/iframe-section/${currentPage.sectionId + 1}`
                    : `/section/${currentPage.sectionId + 1}`;
            }
        }
    }

    const [addComment, setAddComment] = useState(false);

    const { mutate, isLoading, isError } = useMutation(currentPage.save, {
        onMutate: () => {
            dispatch({ type: 'CLEAR_ERRORS', payload: { data: undefined } });
        },
        onSuccess: (data: any) => {
            if (currentPage.address === 'business_home') {
                if (data.data.length) {
                    let bId = data.data.find((item: any) => {
                        return item.action !== 'delete' && item.id;
                    })?.id;

                    if (bId) {
                        navigate(
                            iframe
                                ? `/iframe-form/basic_information/${bId}`
                                : `/form/basic_information/${bId}`,
                        );
                    } else {
                        navigate(
                            iframe
                                ? `/iframe-section/${enumSections.OTHER_INFORMATION}`
                                : `/section/${enumSections.OTHER_INFORMATION}`,
                        );
                    }
                } else {
                    navigate(
                        iframe
                            ? `/iframe-section/${enumSections.OTHER_INFORMATION}`
                            : `/section/${enumSections.OTHER_INFORMATION}`,
                    );
                }
            } else {
                if (iframe) {
                    if (currentPage.next) {
                        navigate(nextURL as string);
                    } else {
                        setShowCompletionModal(true);
                    }
                } else {
                    navigate(currentPage.next ? (nextURL as string) : '/home');
                }
            }
            queryClient.invalidateQueries(`${currentPage.sectionId}`);
            dispatch({ type: 'CLEAR_ERRORS', payload: { data: undefined } });
        },
        onError: (error: any) => {
            let message = error.response.data.message;
            if (typeof message !== 'object') {
                message = { message: 'Something went wwrong' };
            }

            dispatch({ type: 'SET_ERRORS', payload: { data: error.response.data.message } });

            // queryClient.invalidateQueries(`${currentPage.sectionId}`);
        },
    });

    return (
        <>
            {props.noAction && (
                <div className=" mt-4 mb-2 bg-gray-100 flex px-4 py-6 text-gray-600 border border-gray-300 col-span-2 rounded items-center justify-center">
                    <span className="material-symbols-outlined mr-2 text-gray-600">info</span>{' '}
                    <div>No More Action is required on this page, kindly move to the next page</div>
                </div>
            )}
            <div className="col-span-2 h-1 bg-gray-100 mt-8"></div>

            {!props.noDocument && pageType === enumPageTypes.FORM && (
                <ParentGroup className={classNames('col-span-2 mt-4', className)}>
                    <InlineUI />
                </ParentGroup>
            )}

            <ParentGroup className={classNames('col-span-2', className)}>
                <div
                    key={currentPage.address}
                    className={classNames('w-full flex flex-col bg-gray-50 justify-center', {
                        'mt-4 mb-52': !isWelcome,
                        'pt-4': isWelcome,
                    })}
                >
                    {!isWelcome && (
                        <>
                            <div
                                onClick={() =>
                                    upsertCompleteStatus({
                                        [currentPage.address]: !completeFlag,
                                    })
                                }
                                className={classNames(
                                    'flex w-full cursor-pointer items-center py-3 px-3 font-medium',
                                    {
                                        'bg-primary-500 text-white': completeFlag,
                                        'bg-gray-200': !completeFlag,
                                    },
                                )}
                            >
                                {completeFlag ? (
                                    <img
                                        src={build.checkMarkLogo}
                                        className={'h-5 w-5 bg-white rounded-3xl mr-2 p-px '}
                                    />
                                ) : (
                                    <div
                                        className={
                                            'h-5 w-5 bg-white rounded-3xl mr-2 border border-gray-300 p-px'
                                        }
                                    />
                                )}
                                <div>Mark section as completed</div>
                            </div>
                            <div className="flex-grow overflow-y-auto">
                                <CommentThread comments={saved_comments} />
                            </div>
                        </>
                    )}
                    <div className="flex justify-end flex-grow items-center  p-3">
                        <div
                            onClick={() => setAddComment(true)}
                            className="font-bold text-primary-500 font-title flex-grow cursor-pointer hover:text-primary-700"
                        >
                            {!isWelcome && `ADD COMMENT`}
                        </div>
                        <div className="flex w-80">
                            {pageType === enumPageTypes.FORM ? (
                                <>
                                    <Button
                                        secondary
                                        disabled={!currentPage.prev}
                                        link={{
                                            pathname: iframe
                                                ? `/iframe-form/${currentPage.prev}`
                                                : `/form/${currentPage.prev}`,
                                        }}
                                        className="w-1/2 mr-1 bg-white"
                                    >
                                        {isWelcome ? 'GO BACK' : 'PREVIOUS'}
                                    </Button>
                                    <Button
                                        className="w-1/2 ml-1"
                                        disabled={!nextURL}
                                        isLoading={isLoading}
                                        onClick={() => {
                                            if (businessId) {
                                                const business = business_data.find(
                                                    ({ id }: any) => id === businessId,
                                                );
                                                mutate({
                                                    business,
                                                    meta_data,
                                                });
                                            } else if (currentPage.address === 'business_home') {
                                                mutate({
                                                    business: business_data,
                                                    meta_data,
                                                });
                                            } else {
                                                mutate(univarsalFormState);
                                            }
                                        }}
                                    >
                                        {currentPage.next ? 'NEXT' : 'COMPLETE'}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button secondary link="/" className="w-1/2 mr-1 bg-white">
                                        {'PREVIOUS'}
                                    </Button>
                                    <Button
                                        link={
                                            next || iframe
                                                ? `/iframe-form/${currentSection.subSections[0]?.address}` +
                                                  businessExtention
                                                : `/form/${currentSection.subSections[0]?.address}` +
                                                  businessExtention
                                        }
                                        className="w-1/2 ml-1"
                                    >
                                        {'GET STARTED'}
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    {Object.keys(univarsalFormState._errors).length > 0 && (
                        <div className="w-full text-xs font-bold bg-red-100 text-red-600 px-3 py-1">
                            {`Please fix ${
                                Object.keys(univarsalFormState._errors).length
                            } error(s) to proceed`}
                        </div>
                    )}
                </div>
            </ParentGroup>

            <Modal close={() => setAddComment(false)} isOpen={addComment}>
                <AddComments close={() => setAddComment(false)} />
            </Modal>

            <Modal close={() => setShowCompletionModal(false)} isOpen={showCompletionModal}>
                <CompletionModal close={() => setShowCompletionModal(false)} />
            </Modal>
        </>
    );
};

export default TerminalGroup;
