import { Dispatch, useReducer } from 'react';
import Calendar from '../client/Calendar';
import Dropdown from '../client/Dropdown';
import InputGroup from '../client/InputGroup';
import RadioGroup from '../client/Radio';
import SeperatorGroup from '../client/SeperatorGroup';
import { useFormDispatch, useFormState } from '../../context/form';
import { fieldTypes } from '../../utils/enums';
import {
    BrickInterface,
    ConcreteInterface,
    FormStateInterface,
    FormActionInterface,
    FormInterface,
} from '../../utils/interfaces';
import { Table } from '../client/Table';
import classNames from 'classnames';
import Crossroad from '../client/Crossroad';
import TerminalGroup from './TerminalGroup';
import EditableTable from '../client/EditableTable';
import AmountInputGroup from '../client/AmountInput';

type WallInterface = {
    className?: string;
    structure: FormInterface;
    formState: FormStateInterface;
    disabled?: boolean;
    setFormState: (update: { id: string; value: any }) => void;
};

export function isBrick(field: BrickInterface | ConcreteInterface): field is BrickInterface {
    return (field as BrickInterface).id !== undefined;
}

type FieldResolverProps = {
    field: BrickInterface | ConcreteInterface;
    formState: FormStateInterface;
    disabled?: boolean;
    setFormState: (update: { id: string; value: any }) => void;
};

const FieldResolver: React.FC<FieldResolverProps> = ({
    field,
    formState = {},
    setFormState,
    disabled,
}) => {
    if (isBrick(field)) {
        /** Component is a brick, hence needs to utilize formStates */
        const brick = field as BrickInterface;

        const value = formState[brick.id];
        const error = formState._errors?.[brick.id];

        const setValue = (value: any) => {
            setFormState({ id: brick.id, value });
        };
        if (brick.id === 'additional_expense_data') {
        }

        switch (brick.type) {
            case fieldTypes.TEXT_INPUT:
                return <InputGroup {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.AMOUNT_INPUT:
                return <AmountInputGroup {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.DROPDOWN:
                return <Dropdown {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.RADIO:
                return <RadioGroup {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.CALENDAR:
                return <Calendar {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.TABLE:
                return <Table {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.EDITABLE_TABLE:
                return (
                    <EditableTable
                        {...{ ...brick, error, value, setValue, disabled, formState, setFormState }}
                    />
                );

            case fieldTypes.CROSSROAD:
                return (
                    <Crossroad
                        {...{ ...brick, value, setValue, disabled, formState, setFormState }}
                    />
                );

            case fieldTypes.TEXT_DISPLAY:
                return <></>;

            default:
                return <div>FAILED BRICK</div>;
        }
    } else {
        /** Component is a concrete, can't alter form State */
        const concrete = field as ConcreteInterface;

        switch (concrete.type) {
            case fieldTypes.SEPERATOR:
                return <SeperatorGroup {...{ ...concrete, disabled }} />;

            case fieldTypes.TERMINAL:
                return <TerminalGroup {...concrete} />;

            default:
                return <div>FAILED CONCRETE</div>;
        }
    }
};

const Branches: React.FC<FieldResolverProps> = ({ field, formState, setFormState, disabled }) => {
    if (!formState) return <></>;

    if (isBrick(field)) {
        /**
         * Component is a brick, hence can form branches
         * */
        if (field.type === fieldTypes.CROSSROAD) return <></>;

        if (field.branches) {
            const paths = field.branches.paths;

            if (paths) {
                const path = paths.find((b) => b.value === formState[field.id]);

                if (path)
                    return (
                        <FormConstructor
                            disabled={disabled}
                            structure={path.form}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    );

                if (!field.branches.inline)
                    return (
                        <>
                            <SeperatorGroup type={fieldTypes.SEPERATOR} />
                            <div className="flex flex-col col-span-2 w-full items-center">
                                <div className="w-full h-0.5 bg-gray-200"></div>
                                <div className="text-sm text-gray-300 font-semibold mt-5">{`Answer to continue`}</div>
                            </div>
                        </>
                    );
            }
        }

        /** Component is a concrete, can't extend branches */
    }

    return <></>;
};

const FormConstructor: React.FC<WallInterface> = (wall) => {
    const { structure, formState, setFormState, disabled } = wall;
    return (
        <div className={classNames('grid grid-cols-2 col-span-2 w-full max-w-2xl', wall.className)}>
            {structure.map((component, index) => {
                return (
                    <>
                        <FieldResolver
                            disabled={disabled}
                            key={index}
                            field={component}
                            {...{ formState, setFormState }}
                        />

                        <Branches
                            key={index}
                            field={component}
                            {...{ formState, setFormState, disabled }}
                        />
                    </>
                );
            })}
        </div>
    );
};

export default FormConstructor;
