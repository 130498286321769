import NavBar from '../../NavBar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFormsList from '../../../../api/tax-prep/useFormsList';
import ClientDashboardMenu from './Menu';
import { Outlet } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

const WorkBookDropdown: React.FC = () => {
    // State for dropdown visibility
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    // Ref for dropdown element
    const dropdownRef = useRef<HTMLDivElement>(null);
    // Get IRS account ID from URL params
    const { irs_account_id } = useParams();
    // Fetch forms list data
    const { isLoading, data } = useFormsList({ irs_account_id });

    // Toggle dropdown visibility
    const toggleDropdown = () => setIsOpen(!isOpen);

    // Close dropdown when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            {/* Dropdown toggle button */}
            <div
                onClick={toggleDropdown}
                className="px-2 py-1 my-2 flex justify-center w-36 items-center gap-1 cursor-pointer bg-primary-500"
            >
                <span className="text-white font-medium text-sm font-title">Worksheets</span>
                <span
                    style={{ fontSize: '22px' }}
                    className="material-symbols-outlined border-none text-white text-md"
                >
                    expand_more
                </span>
            </div>
            {/* Dropdown content */}
            {isOpen && (
                <ul className="z-50 w-full absolute  text-gray-700 shadow-md border">
                    {/* Render each form */}
                    {/* {data?.taxprep?.map((item: any, index: number) => (
                        <li className="border-b border-gray-300" key={item.id}>
                            <button
                                className="text-left text-xs font-semibold w-full bg-white hover:bg-gray-100 py-2 px-3 block whitespace-no-wrap"
                                onClick={() => {
                                    // Handle form selection
                                    setIsOpen(false);
                                }}
                            >
                                TaxPrep {item?.taxprep_year}
                            </button>
                        </li>
                    ))} */}

                    {data?.u_service_type?.includes('tax_resolution') && (
                        <li className="border-b border-gray-300">
                            <button
                                className="text-left cursor-pointer hover:underline text-xs font-semibold w-full bg-white hover:bg-gray-100 py-2 px-3 block whitespace-no-wrap"
                                onClick={() => {
                                    // Handle form selection
                                    navigate(
                                        `/evaluate/${irs_account_id}?scroll=${'basic_information'}`,
                                    );
                                    setIsOpen(false);
                                }}
                            >
                                Tax Resolution
                            </button>
                        </li>
                    )}
                    <li className="border-b border-gray-300 group ">
                        <span
                            onClick={() => {}}
                            className="w-full flex justify-between  bg-white hover:bg-gray-100  py-2 px-3"
                        >
                            <button className="text-left text-xs font-semibold  block whitespace-no-wrap">
                                Tax Prep
                            </button>
                            <span
                                style={{ fontSize: '16px' }}
                                className="material-symbols-outlined border-none text-black text-md transform group-hover:rotate-180"
                            >
                                expand_more
                            </span>
                        </span>

                        <li className="hidden group-hover:block">
                            <ul>
                                {data?.taxprep?.map((item: any, index: number) => {
                                    return (
                                        <li
                                            onClick={() => {
                                                navigate(
                                                    `/taxprep-evaluate/${irs_account_id}/${item.taxprep_year}`,
                                                );
                                                sessionStorage.setItem('tax_id', item.id);
                                            }}
                                            className="text-left hover:underline cursor-pointer text-xs font-semibold w-full bg-white hover:bg-gray-100 py-2 px-3 block whitespace-no-wrap"
                                        >
                                            {item?.taxprep_year}
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    </li>
                </ul>
            )}
        </div>
    );
};

const Layout: React.FC = ({ children }) => {
    // Get IRS account ID from URL params
    const { irs_account_id } = useParams();
    const location = useLocation();
    const [expand, setExpand] = useState(false);

    // Fetch user data
    const { data, business, isLoading } = useFormsList({ irs_account_id });
    // Check if the current location is within an iframe
    const iframe = location.pathname.includes('iframe');
    business && console.log(Object.entries(business).map(([name, value]) => ({ name, value })));
    business && console.log('b-data', data, business);
    const menuDropdown = {
        primaryClient: data?.primary_user?.u_first_name + ' ' + data?.primary_user?.u_last_name,
        spouse: data?.spouse?.spouse_first_name + ' ' + data?.spouse?.spouse_last_name,
        business: Object.entries(business ?? {}).map(([name, value]) => ({ name, value })),
    };

    return (
        <div className="flex flex-col w-full h-screen overflow-y-scroll">
            {/* Render the navigation bar if not in an iframe */}
            {!iframe && <NavBar />}
            <div
                style={{ paddingRight: '2%' }}
                className="flex bg-white fixed z-50 py-2 border-b w-full mt-10"
            >
                {/* User info section */}
                <div className="font-title flex items-center ml-3 text-2xl font-semibold flex-grow">
                    <span
                        onClick={() => setExpand(!expand)}
                        style={{ fontSize: '30px' }}
                        className="material-symbols-outlined pr-4 text-black cursor-pointer"
                    >
                        menu
                    </span>{' '}
                    {data && data?.u_first_name + ' ' + data?.u_last_name}
                </div>
                {/* Action buttons section */}
                <div className="flex gap-5">
                    <WorkBookDropdown />
                    <button className="px-2 my-2 text-white text-sm w-36 py-1 flex justify-center items-center gap-1 cursor-pointer bg-primary-500">
                        Client Portal
                    </button>
                </div>
            </div>
            <div style={{ marginTop: '98px' }} className="flex w-full bg-gray-100  flex-grow">
                {/* Sidebar menu */}
                <ClientDashboardMenu expand={expand} setExpand={setExpand} data={menuDropdown} />
                {/* Main content */}
                <div
                    style={{ maxHeight: '89vh' }}
                    className="flex-grow w-full bg-gray-100 overflow-y-auto"
                >
                    {/* Render child components */}
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
