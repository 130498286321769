import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { ssn } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertBussiness = (year: string, { meta_data, ...bussiness_data }: any) => {
    return apiTaxPrep.post(`/taxprepBusinessByAction`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data: [{ action: 'update', ...bussiness_data }],
        taxprep_year: year,
    });
};

const b_detailsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'name_proprietorship',
        label: 'Name of proprietor',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'business_ssn',
        label: 'Social Security Number (SSN)',
        placeholder: 'john',
        formatFunction: ssn,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'principal_business',
        label: 'Principal business or profession. including product or service',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'code',
        label: 'Enter code from instructions',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'business_name',
        label: 'Business name',
        placeholder: 'john',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'business_type',
        label: 'Business Type',
        placeholder: 'john',
        options: [
            { label: 'Single Member LLC / Sole Prop', value: 'Single Member LLC / Sole Prop' },
            { label: 'Partnership LLC/S-Corp', value: 'Partnership LLC/S-Corp' },
            { label: 'Any Other Corporation', value: 'Any Other Corporation' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'employer_ein',
        label: 'Employer ID number (EIN)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'business_address',
        label: 'Business address',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'city',
        label: 'City',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'town',
        label: 'Town',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'postoffice',
        label: 'PostOffice',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'state',
        label: 'State',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'zipcode',
        label: 'Zipcode',
        placeholder: 'john',
    },
    {
        type: fieldTypes.RADIO,
        id: 'accounting_method',
        label: 'Selecting Accounting method',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
    {
        type: fieldTypes.RADIO,
        id: 'business_start_year',
        label: 'Did you start/acquire the business in 2022?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
    {
        type: fieldTypes.RADIO,
        id: 'materially_participated_year',
        label: 'Did you "materially participate" in the operation of this business in 2022?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
    { type: fieldTypes.TERMINAL },
];

const b_detailsSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_data',
        label: 'Do you have Business details?',
        header: 'Business details',
        displayColumns: [
            {
                name: 'Business name',
                key: 'business_name',
            },
            {
                name: 'proprietor',
                key: 'name_proprietorship',
            },
            {
                name: 'Business Type',
                key: 'business_type',
            },
        ],
        formStructure: b_detailsForm,
    },
];

export const page: PageInputInterface = {
    address: 'business_details',
    name: 'Business details',
    sectionId: enumSections.BUSINESS_INFORMATION,

    formStructure: b_detailsForm,
    save: upsertBussiness,
};
