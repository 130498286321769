/**
 * UI for Checkboxes and Radio selectors
 */
import classNames from 'classnames';
import ParentGroup from './ParentGroup';
import { interface_option, interface_field_radio } from '../../utils/interfaces';
import { build } from '../../config';

type RadioGroupProps = interface_field_radio & {
    className?: string;
    styles?: { [key: string]: any };
    value: any | Array<any>;
    error?: string;
    disabled?: boolean;
    setValue: (value: any) => void;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
    id,
    value: formValue,
    error,
    setValue,
    isMulti,
    styles,
    label,
    options,
    className,
    span,
    crossroadVariant,
    column,
    disabled,
}) => {
    const resolveValue = (option: interface_option | undefined) => {
        if (option === undefined) setValue(undefined);
        else {
            const { value: optionValue } = option;

            if (isMulti) {
                /**
                 * Since value is an array, this means the component is multi select checkbox
                 * SO addition and removal has to be handled seperately
                 * */
                let tempFormValue = formValue || [];
                const index = tempFormValue.find((o: any) => o === optionValue);
                if (index) {
                    /** Since element already exits, fiond and remove it */
                    const newArray = tempFormValue.filter((o: any) => o !== optionValue);
                    setValue(newArray);
                } else {
                    /** Since element doesn't exits, add at the end */

                    setValue([...tempFormValue, optionValue]);
                }
            } else {
                /**
                 * This means the component is radio, so simply replace the value
                 * or remove the value if clicked again
                 *  */
                if (formValue === optionValue) {
                    /** Remove the value if none exist */
                    setValue(undefined);
                } else {
                    /** Replace or set current value */
                    setValue(optionValue);
                }
            }
        }
    };

    const currentOption = options.find((o) => o.value === formValue) as interface_option;

    return (
        <ParentGroup
            className={classNames(`col-span-${span || 1}`, className)}
            error={error}
            style={{ gridColumn: `span ${span || 1}` }}
        >
            {crossroadVariant ? (
                <div id={id} className="w-full grid grid-cols-5 mt-16">
                    <div className="col-span-2 text-right pr-4">{label}</div>
                    <div className="col-span-3">
                        {!formValue ? (
                            options.map((option: interface_option) => {
                                const { label: optionLabel, value: optionValue } = option;

                                return (
                                    <div
                                        onClick={() => !disabled && resolveValue(option)}
                                        className={classNames(
                                            ' border flex h-10 items-center border-gray-100 focus:outline-none px-3 py-1 mb-2  w-full cursor-pointer  ',
                                            {
                                                'bg-gray-100 hover:bg-gray-200': !disabled,
                                                'bg-gray-200': disabled,
                                            },
                                        )}
                                    >
                                        <div className="flex items-center">
                                            <div
                                                className="h-4 w-4 bg-white rounded-3xl mr-2 border border-gray-300 p"
                                                style={{ padding: 1 }}
                                            />

                                            <div>{optionLabel}</div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className=" flex flex-col items-end w-full ">
                                <div className="border h-10 flex focus:outline-none items-center text-white font-medium px-3 w-full py-1 mb-2 cursor-pointer bg-primary-500 hover:bg-primary-600 border-primary-500">
                                    <img
                                        src={build.checkMarkLogo}
                                        className={classNames('h-4 w-4 bg-white rounded-3xl mr-2')}
                                        style={{ padding: 1 }}
                                    />

                                    <div>{currentOption?.label}</div>
                                </div>

                                <div
                                    onClick={() => !disabled && resolveValue(undefined)}
                                    className={classNames('cursor-pointer text-xs font-semibold ', {
                                        'text-primary-500': !disabled,
                                        'text-gray-500': disabled,
                                    })}
                                >
                                    CHANGE RESPONSE
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div
                    className={classNames('w-full', { 'bg-gray-200': disabled })}
                    style={{ minHeight: 64 }}
                >
                    <div className={classNames('pointer-events-none text-sm')}>{label}</div>
                    <div className={classNames('flex  w-full', { 'flex-col': column })}>
                        {options.map((option: interface_option) => {
                            const { label: optionLabel, value: optionValue } = option;

                            let isSelected = false;
                            if (Array.isArray(formValue)) {
                                const index = formValue.find((o) => o === optionValue);
                                if (index) isSelected = true;
                            } else {
                                if (formValue === optionValue) isSelected = true;
                            }

                            return (
                                <>
                                    <label
                                        className={classNames('flex flex-grow items-center  py-2', {
                                            'hover:bg-gray-50': !disabled,
                                        })}
                                    >
                                        <input
                                            type="radio"
                                            name={id + optionValue}
                                            id={id + optionValue}
                                            checked={isSelected}
                                            onClick={() => !disabled && resolveValue(option)}
                                            className={classNames(
                                                'focus:outline-primary border-4 border-gray-200 rounded-md p-2 pt-5 h-4 w-4 mx-1',
                                            )}
                                        />

                                        <div
                                            className={classNames('flex-grow ml-1', {
                                                'text-gray-500': !isSelected,
                                            })}
                                        >
                                            {optionLabel}
                                        </div>
                                    </label>
                                </>
                            );
                        })}
                    </div>
                </div>
            )}
        </ParentGroup>
    );
};

export default RadioGroup;
