import { upsertAboradProperties } from '../../api/other';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const abroadAssetsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'real_property_name',
        label: 'Details',
        placeholder: 'Villa in spain',
        area: true,
        span: 2,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'real_property_outside_us_data',
        label: 'Do you have any assets or own any real property outside the U.S.?',
        header: 'Property Information',
        formStructure: abroadAssetsForm,
        displayColumns: [
            {
                name: 'Details',
                key: 'real_property_name',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'abroad_properties',
    name: 'Real Property outside US',

    sectionId: enumSections.OTHER_INFORMATION,
    formStructure: form,

    save: upsertAboradProperties,
};
