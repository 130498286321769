import { Navigate, useLocation } from 'react-router-dom';
import { enumSections, fieldTypes } from '../../utils/enums';
import TerminalGroup from './TerminalGroup';
import { useLightHouse } from '../../utils/helpers';
import { useFormState } from '../../context/form';

const WelcomeScreen: React.FC<{}> = () => {
    const { currentSectionId, sections } = useLightHouse();

    const { business_data } = useFormState();

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    const activeSection = sections.find(({ id }) => id === currentSectionId);
    if (!activeSection) return <>INVALID SECTION</>;

    if (enumSections.BUSINESS_INFORMATION === activeSection.id && business_data.length > 0)
        return <Navigate to={iframe ? '/iframe-form/business_home' : '/form/business_home'} />;

    return (
        <>
            <div className="flex flex-col w-full h-full items-center justify-center ">
                <div className="flex  w-full max-w-2xl justify-center items-center">
                    <div className=" px-3 flex-grow ">
                        <div className="font-title font-semibold text-gray-500">{`PART ${currentSectionId}`}</div>
                        <div className="font-title text-4xl font-medium">{activeSection.name}</div>
                    </div>
                    <div className="flex-grow">
                        <div className="text-sm pb-4 font-semibold">Section Overview</div>
                        {activeSection.subSections.map(({ name, address }, index) => (
                            <div
                                key={index}
                                className="flex items-center py-1 hover:bg-gray-50 px-1.5"
                            >
                                <div
                                    className={
                                        'h-5 w-5 rounded-2xl flex items-center justify-center bg-primary-500 mr-3'
                                    }
                                >
                                    <div className={'text-sm text-center font-semibold text-white'}>
                                        {index + 1}
                                    </div>
                                </div>
                                <div>{name}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className=" w-full justify-center max-w-3xl">
                    <TerminalGroup
                        type={fieldTypes.TERMINAL}
                        next={
                            enumSections.BUSINESS_INFORMATION === activeSection.id
                                ? iframe
                                    ? '/iframe-form/business_home'
                                    : `/form/business_home`
                                : undefined
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default WelcomeScreen;
