import classNames from 'classnames';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from '../../context/auth';
import { useFeatureEnabled, useIsMobile } from '../../utils/helpers';
import { useQueryClient } from 'react-query';
import { build } from '../../config';
import { useEffect, useState } from 'react';
import Manager from '../document/Manager';
import ManagerTP from '../document/ManagerTP';
import Notifications from './Notifications';
import useNotifications from './../../api/hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const lngs = [
        { value: 'en', label: 'English' },
        { value: 'es', label: 'Spanish' },
    ];

    const [hover, setHover] = useState<boolean>(false);
    const [currentLang, setCurrentLang] = useState<(typeof lngs)[number]>({
        value: 'en',
        label: 'English',
    });

    useEffect(() => {
        const lng = navigator.language;
        const initLanguage = lngs.find(({ value }) => value === lng);

        if (initLanguage && initLanguage?.value !== currentLang.value) {
            setCurrentLang(initLanguage);
        }
    }, []);

    const language = lngs.find(({ value }) => value === currentLang.value);
    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                const lngIndex = lngs.findIndex(({ value }) => value === currentLang.value);
                const nextIndex = (lngIndex + 1) % lngs.length;

                i18n.changeLanguage(lngs[nextIndex].value);
                setCurrentLang(lngs[nextIndex]);
            }}
            className="cursor-pointer font-title flex items-center justify-center z-20  mx-5"
        >
            <span
                style={{
                    fontSize: 18,
                    color: hover ? 'rgba(243, 244, 246)' : 'rgba(55, 65, 81)',
                }}
                className="material-symbols-outlined "
            >
                language
            </span>
            <div className=" text-gray-100 text-sm ml-1">{language?.label}</div>
        </div>
    );
};

const NavBar: React.FC<{ toggleMenu?: () => void; iframe?: boolean }> = ({
    toggleMenu,
    iframe,
}) => {
    const authDispatch = useAuthDispatch();
    const { success, loading, role } = useAuthState();
    const queryClient = useQueryClient();
    const { notify } = useNotifications('');
    const navigate = useNavigate();
    const { success: isDocumentEnabled } = useFeatureEnabled('Document');
    const [settingsHover, setSettingsHover] = useState(false);
    const [documentsHover, setDocumentsHover] = useState(false);
    const [documentsModal, setDocumentsModal] = useState(false);
    const [showNotif, setShowNotif] = useState(false);
    const [documentsModalTP, setDocumentsModalTP] = useState(false);
    const [logoutHover, setLogoutHover] = useState(false);
    const location = useLocation();
    const isMobile = useIsMobile();

    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY) {
                // if scroll down hide the navbar
                setShow(false);
            } else {
                // if scroll up show the navbar
                setShow(true);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [selected, setSelected] = useState<string>();

    useEffect(() => {
        if (location.pathname.includes('client')) {
            setSelected('client');
            return;
        }

        if (location.pathname.includes('taxprep')) {
            setSelected('taxprep');
            return;
        }

        if (location.pathname.includes('superAdmin')) {
            setSelected('superAdmin');
            return;
        }

        if (location.pathname.includes('admin')) {
            setSelected('admin');
            return;
        }
    }, []);

    if (!success && !loading && !location.pathname.includes('test')) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <div
                className="w-full h-10 flex fixed left-0 top-0 bg-gray-700 z-50"
                style={{ paddingLeft: '4%', paddingRight: '4%', minWidth: 550 }}
            >
                {isMobile && (
                    <div
                        onClick={() => {
                            toggleMenu && toggleMenu();
                        }}
                        className="flex mr-3 items-center justify-center"
                    >
                        <span className="material-symbols-outlined text-white">menu</span>
                    </div>
                )}

                {!iframe && (
                    <div className="font-title flex flex-grow items-center">
                        <Link
                            to={
                                role === 'Client'
                                    ? iframe
                                        ? '/iframe-home'
                                        : '/home'
                                    : role === 'Tax Preparer'
                                    ? '/client'
                                    : '/taxprep'
                            }
                            className="flex items-center justify-center p-2 mr-4"
                        >
                            <img
                                src={build.logo_white}
                                className={classNames('h-9 z-20')}
                                style={{ padding: 1 }}
                            />
                        </Link>

                        {/* {role !== 'Super Admin' && (
                            <Link to="/client">
                                <div
                                    className={classNames(
                                        'px-4 text-sm font-title text-white cursor-pointer h-10 flex items-center',
                                        {
                                            'bg-gray-600': selected === 'client',
                                        },
                                    )}
                                >
                                    CLIENTS
                                </div>
                            </Link>
                        )} */}

                        {/* {role === 'Admin' && (
                            <Link to="/taxprep">
                                <div
                                    className={classNames(
                                        'px-4 text-sm font-title text-white cursor-pointer h-10 flex items-center',
                                        {
                                            'bg-gray-600': selected === 'taxprep',
                                        },
                                    )}
                                >
                                    TAX PRO
                                </div>
                            </Link>
                        )} */}
                        {/* {role === 'Super Admin' && (
                            <>
                                <Link to="/superAdmin">
                                    <div
                                        className={classNames(
                                            'px-4 text-sm font-title text-white cursor-pointer h-10 flex items-center',
                                            {
                                                'bg-gray-600': selected === 'superAdmin',
                                            },
                                        )}
                                    >
                                        CLIENTS
                                    </div>
                                </Link>
                            </>
                        )} */}
                        {/* {build.admin_list && role === 'Admin' && (
                            <>
                                <Link to="/admin">
                                    <div
                                        className={classNames(
                                            'px-4 text-sm font-title text-white cursor-pointer h-10 flex items-center',
                                            {
                                                'bg-gray-600': selected === 'admin',
                                            },
                                        )}
                                    >
                                        ADMIN
                                    </div>
                                </Link>
                            </>
                        )} */}
                    </div>
                )}
                <div className=" flex flex-row overflow-x-auto ">
                    {isDocumentEnabled && (
                        <div
                            onMouseEnter={() => setDocumentsHover(true)}
                            onMouseLeave={() => setDocumentsHover(false)}
                            onClick={() => {
                                if (
                                    !(
                                        location.pathname.includes('taxprep-evaluate') ||
                                        location.pathname.includes('tax-prep')
                                    )
                                ) {
                                    setDocumentsModal(true);
                                } else {
                                    setDocumentsModalTP(true);
                                }
                            }}
                            className="cursor-pointer font-title flex items-center justify-center z-20  mx-3 my-2"
                        >
                            <span
                                style={{
                                    fontSize: 18,
                                    color: documentsHover
                                        ? 'rgba(243, 244, 246)'
                                        : 'rgba(55, 65, 81)',
                                }}
                                className="material-symbols-outlined text-gray-100"
                            >
                                folder_open
                            </span>
                            <div className="text-gray-100 text-sm ml-1 whitespace-nowrap">
                                {!(
                                    location.pathname.includes('tp-navigator') ||
                                    location.pathname.includes('home')
                                ) && (role === 'Client' ? `Documents` : `Client Documents`)}
                            </div>
                        </div>
                    )}

                    {!iframe && (
                        <>
                            {role === 'Client' && (
                                <div
                                    onMouseEnter={() => setSettingsHover(true)}
                                    onMouseLeave={() => setSettingsHover(false)}
                                    onClick={() => {
                                        navigate('/c/settings');
                                    }}
                                    className="cursor-pointer font-title flex items-center justify-center z-20  mx-3"
                                >
                                    <span
                                        style={{
                                            fontSize: 18,
                                            color: settingsHover
                                                ? 'rgba(243, 244, 246)'
                                                : 'rgba(55, 65, 81)',
                                        }}
                                        className="material-icons text-gray-100"
                                    >
                                        settings
                                    </span>
                                    <div className="text-gray-100 text-sm ml-1">Settings</div>
                                </div>
                            )}
                            {build.notifications && (
                                <div
                                    onClick={() => {
                                        setShowNotif(true);
                                    }}
                                    className="cursor-pointer font-title flex items-center justify-center z-20  mx-5"
                                >
                                    <div className="text-gray-100 text-sm ml-1 relative">
                                        <span className="material-symbols-outlined">
                                            {notify.icon}
                                        </span>
                                        {notify.notify && (
                                            <div className="px-1 text-white text-xs bg-red-500 rounded-full absolute -top-1 -right-1">
                                                {notify?.count}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {/* <LanguageSelector /> */}
                            <div
                                onMouseEnter={() => setLogoutHover(true)}
                                onMouseLeave={() => setLogoutHover(false)}
                                onClick={() => {
                                    queryClient.invalidateQueries();
                                    authDispatch('LOGOUT', null);
                                }}
                                className="cursor-pointer font-title flex items-center justify-center z-20  mx-3"
                            >
                                <span
                                    style={{
                                        fontSize: 18,
                                        color: logoutHover
                                            ? 'rgba(243, 244, 246)'
                                            : 'rgba(55, 65, 81)',
                                    }}
                                    className="material-icons text-gray-100"
                                >
                                    account_circle
                                </span>
                                <div className="text-gray-100 text-sm ml-1">Logout</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Notifications
                key={'notifs-modal' + showNotif}
                isOpen={showNotif}
                close={() => setShowNotif(false)}
            />
            <Manager isOpen={documentsModal} close={() => setDocumentsModal(false)} />
            <ManagerTP isOpen={documentsModalTP} close={() => setDocumentsModalTP(false)} />
        </>
    );
};

export default NavBar;
