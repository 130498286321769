import { upsertIncomeExpense } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const form: FormInterface = [
    {
        type: fieldTypes.RADIO,
        id: 'accounting_method_used',
        span: 2,
        label: 'Which Accounting Method Used?',
        options: [
            { value: 'Cash', label: 'Cash' },
            { value: 'Accrual', label: 'Accrual' },
        ],
    },
    { type: fieldTypes.SEPERATOR, heading: 'Income and expense during the period' },
    {
        type: fieldTypes.CALENDAR,
        id: 'income_expense_period_begin_date',
        label: 'Start Date',
        placeholder: '10 August 1998',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'income_expense_period_end_date',
        label: 'End Date',
        placeholder: '10 August 1998',
    },
    { type: fieldTypes.SEPERATOR, title: 'Basic Income (Monthly)' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'income',

        addLimit: 5,
        addColumns: [
            {
                name: 'Income Name',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'income_other_',
                id_suffix: '_prompt',
            },
            {
                name: 'Income Amount',
                type: fieldTypes.AMOUNT_INPUT,
                id_prefix: 'income_other_',
                id_suffix: '',
            },
        ],
        addButtonText: 'Add Other Income...',

        displayColumns: [
            { name: 'Expenses', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],

        defaultRows: [
            ['Gross Receipts', { id: 'income_gross_receipts_sales_service' }],
            ['Gross Rental Income', { id: 'income_gross_rental' }],
            ['Interest Income', { id: 'income_interest' }],
            ['Dividends', { id: 'income_dividends' }],
            ['Cash Receipts not in Gross', { id: 'income_cash_receipts' }],
        ],
    },
    { type: fieldTypes.SEPERATOR, title: 'Basic Expense (Monthly)' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'expenses',

        displayColumns: [
            { name: 'Expenses', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],

        addLimit: 20,
        addColumns: [
            {
                name: 'Expense Name',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'add_expense_',
                id_suffix: '_prompt',
            },
            {
                name: 'Expense Amount',
                type: fieldTypes.AMOUNT_INPUT,
                id_prefix: 'add_expense_',
                id_suffix: '',
            },
            {
                name: 'Secured / Unsecured',
                type: fieldTypes.DROPDOWN,
                options: [
                    { value: 'Secured', label: 'Secured' },
                    { value: 'Unsecured', label: 'Unsecured' },
                ],
                id_prefix: 'add_expense_',
                id_suffix: '_secure',
            },
        ],
        addButtonText: 'Add Other Expenses...',

        defaultRows: [
            ['Materials', { id: 'expense_materials_purchased' }],
            ['Inventory Purchased', { id: 'expense_inventory_purchased' }],
            ['Gross Wages / Salaries', { id: 'expense_gross_wages_salaries' }],
            ['Rent', { id: 'expense_rent' }],
            ['Supplies', { id: 'expense_supplies' }],
            ['Utilities / Telephone', { id: 'expense_utilities_telephone' }],
            ['Vehicle Gas / Oil', { id: 'expense_vehicle_gas_oil' }],
            ['Repairs and Maintenance', { id: 'expense_repairs_maintenance' }],
            ['Insurance', { id: 'expense_insurance' }],
            ['Current Taxes', { id: 'expense_current_taxes' }],
        ],
    },
    { type: fieldTypes.TERMINAL },
];

export const page: PageInputInterface = {
    address: 'income_expense',
    name: 'Business Income and Expense',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertIncomeExpense,
};
