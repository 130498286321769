/* eslint-disable import/no-anonymous-default-export */
import { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { addTaxPro, changeStatus, deleteUser, editTaxPro } from '../api/admin';
import useTaxPrep from '../api/hooks/useTaxPrep';
import AdminMenu from '../components/basic/AdminMenu';
import InputGroup from '../components/client/InputGroup';
import Button from '../components/basic/Button';
import Modal from '../components/basic/Modal';
import NavBar from '../components/basic/NavBar';
import DropdownAdmin from '../components/admin/Dropdown';
import { useAuthState } from '../context/auth';
import { fieldTypes } from '../utils/enums';
import InputGroupAdmin from '../components/admin/InputGroup';
import { PageManager, Table } from './Table';
import { useFeatureEnabled, useIsMobile } from '../utils/helpers';
import classNames from 'classnames';
import ResendCredentials from '../components/basic/ResendCredentials';
import { phone as phoneFormat } from '../utils/format';
import { showToast } from './../components/basic/Toaster';
import ConfirmExport from '../components/basic/ExportConfirmationModal';
import { HeaderGroup } from 'react-table';
import { build } from '../config';
import moment from 'moment';

const taxPreparerLoadingData = [
    {
        user_id: 'Loading...',
        u_first_name: 'Loading...',
        u_last_name: 'Loading...',
        u_user_name: 'Loading...',
        u_email: 'Loading...',
    },
];

const DeleteTaxPro: React.FC<{ close: () => void; user: any }> = ({ close, user }) => {
    const [error, setErrors] = useState<{ [key: string]: any }>({});

    const queryClient = useQueryClient();
    const { token, role } = useAuthState();

    const mutation = useMutation(deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('clients-list');
            showToast('Tax Pro has been Deleted !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const deleteTaxPro = () => {
        if (!token) return;

        mutation.mutate({
            user_id: user.user_id,
            client_type: 'taxprep',
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Delete Tax Pro`}</div>

            <div className="font-body py-2">{`Are you sure you want to delete ${user.name} from your tax preparers permanently?`}</div>

            <div className="flex w-full">
                <Button onClick={deleteTaxPro} className="text-center flex-grow mr-2">
                    {'DELETE TAX PRO'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const EditStatus: React.FC<{ close: () => void; user: any }> = ({ close, user }) => {
    const [error, setErrors] = useState<{ [key: string]: any }>({});

    const queryClient = useQueryClient();
    const { token, role } = useAuthState();
    const mutation = useMutation(changeStatus, {
        onSuccess: async () => {
            await queryClient.invalidateQueries('taxprep-list');
            showToast('Status has been Changed!', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const changeUserStatus = () => {
        if (!token) return;

        mutation.mutate({
            u_id: user.user_id,
            u_status: user.u_status === 'Active' ? 'Inactive' : 'Active',
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Change Status`}</div>

            <div className="font-body py-2">
                Are you sure you want to change status of{' '}
                <span className="font-semibold">{user.name}</span>
            </div>

            <div className="flex w-full">
                <Button
                    isLoading={mutation.isLoading}
                    onClick={changeUserStatus}
                    className="text-center flex-grow mr-2"
                >
                    {user.u_status === 'Active' ? 'INACTIVE' : 'ACTIVE'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const TaxProDetails: React.FC<{ close: () => void; edit?: any }> = ({ close, edit }) => {
    const [email, setEmail] = useState<string>(edit?.u_email);
    const [firstName, setFirstName] = useState<string>(edit?.u_first_name);
    const [lastName, setlastName] = useState<string>(edit?.u_last_name);
    const [phone, setPhone] = useState<string>(edit?.u_phone_no);
    const [fax, setFax] = useState<string>(edit?.u_fax);
    const [caf, setCaf] = useState<string>(edit?.u_caf_no);
    const [ptin, setPtin] = useState<string>(edit?.u_ptin);
    const [designation, setDesignation] = useState<string>(edit?.u_designation);
    const [jurisdiction, setJurisdiction] = useState<string>(edit?.u_jurisdiction);
    const [bar, setBar] = useState<string>(edit?.u_bar);
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const { plan_type } = useAuthState();
    const queryClient = useQueryClient();
    const { mutate: addMutation, isLoading: addLoading } = useMutation(addTaxPro, {
        onSuccess: () => {
            queryClient.invalidateQueries('taxprep-list');
            showToast('Tax Pro has been Added !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const { mutate: editMutation, isLoading: editLoading } = useMutation(editTaxPro, {
        onSuccess: () => {
            queryClient.invalidateQueries('taxprep-list');
            showToast('Tax Pro has been Edited !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const { token } = useAuthState();

    const registerTaxPro = () => {
        if (!token) return;

        addMutation({
            u_email: email,
            u_first_name: firstName,
            u_last_name: lastName,
            u_phone_no: phone,
            u_fax: fax,
            u_caf_no: caf,
            u_ptin: ptin,
            u_designation: designation,
            u_jurisdiction: jurisdiction,
            u_bar: bar,
            token: token,
        });
    };

    const updateTaxPro = () => {
        if (!token) return;

        editMutation({
            user_id: edit.user_id,
            u_id: edit.u_id,
            u_user_name: edit.u_user_name,
            u_email: email,
            u_first_name: firstName,
            u_last_name: lastName,
            u_phone_no: phone,
            u_fax: fax,
            u_caf_no: caf,
            u_ptin: ptin,
            u_designation: designation,
            u_jurisdiction: jurisdiction,
            u_bar: bar,
            token: token,
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`${edit ? 'Edit' : 'Add New'}  Tax Pro`}</div>
            {plan_type === 'Free' ? (
                <div className="bg-red-50 mb-5">
                    <div className="text-center text-sm font-semibold p-2">
                        Please Upgrade Your Plan!
                        <div className="text-gray-600 text-xs py-1">
                            Your current plan is{' '}
                            <span className="text-primary-400">{plan_type}</span>
                        </div>
                    </div>
                    <Button className="text-center w-auto">
                        <a
                            href="https://mycase.tax/pricing/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {'Upgrade'}
                        </a>
                    </Button>
                </div>
            ) : (
                <>
                    <InputGroup
                        id="email"
                        label="Email"
                        placeholder=""
                        value={email}
                        setValue={(value) => {
                            setEmail(value);
                            setErrors({});
                        }}
                        type={fieldTypes.TEXT_INPUT}
                        className="w-full mt-1"
                        staticError={error.u_email}
                    />

                    <div className="flex">
                        <InputGroup
                            id="firstName"
                            label="First Name"
                            placeholder=""
                            value={firstName}
                            setValue={(value) => {
                                setFirstName(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_first_name}
                        />

                        <InputGroup
                            id="lastName"
                            label="Last Name"
                            placeholder=""
                            value={lastName}
                            setValue={(value) => {
                                setlastName(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_last_name}
                        />
                    </div>

                    <div className="flex pt-6">
                        <InputGroup
                            id="phone"
                            label="Phone Number"
                            placeholder=""
                            value={phone}
                            setValue={(value) => {
                                setPhone(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_phone_no}
                            formatFunction={phoneFormat}
                        />

                        <InputGroup
                            id="fax"
                            label="Fax"
                            placeholder=""
                            value={fax}
                            setValue={(value) => {
                                setFax(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_fax}
                        />
                    </div>

                    <div className="flex">
                        <InputGroup
                            id="caf_no"
                            label="CAF Number"
                            placeholder=""
                            value={caf}
                            setValue={(value) => {
                                setCaf(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_caf_no}
                        />

                        <InputGroup
                            id="ptin"
                            label="PTIN"
                            placeholder=""
                            value={ptin}
                            setValue={(value) => {
                                setPtin(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_ptin}
                        />
                    </div>

                    <div className="flex  pt-6">
                        <InputGroup
                            id="u_designation"
                            label="Designation"
                            placeholder=""
                            value={designation}
                            setValue={(value) => {
                                setDesignation(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_designation}
                        />

                        <InputGroup
                            id="u_jurisdiction"
                            label="Jurisdiction"
                            placeholder=""
                            value={jurisdiction}
                            setValue={(value) => {
                                setJurisdiction(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1"
                            staticError={error.u_jurisdiction}
                        />
                    </div>
                    <InputGroup
                        id="u_bar"
                        label="BAR"
                        placeholder=""
                        value={bar}
                        setValue={(value) => {
                            setBar(value);
                            setErrors({});
                        }}
                        type={fieldTypes.TEXT_INPUT}
                        className="w-full mt-1"
                        staticError={error.u_bar}
                    />
                    {edit ? (
                        <Button
                            isLoading={addLoading}
                            onClick={updateTaxPro}
                            className="text-center w-full mt-8"
                        >
                            {'EDIT TAX PRO'}
                        </Button>
                    ) : (
                        <Button
                            isLoading={addLoading}
                            onClick={registerTaxPro}
                            className="text-center w-full mt-8"
                        >
                            {'ADD TAX PRO'}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

const ActionsCell = ({
    original,
    setConfirmDelete,
    setConfirmResend,
    setEditTaxPro,
    setStatusData,
}: any) => {
    return (
        <div className="flex mr-5 items-center">
            <span
                onClick={(e) => {
                    e.stopPropagation();

                    setEditTaxPro(original);
                }}
                className="cursor-pointer pr-1  font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
            >
                edit
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();

                    setConfirmResend(original['user_id']);
                }}
                className="cursor-pointer pr-1 font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
            >
                restart_alt
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDelete(original['user_id']);
                }}
                style={{ fontSize: '25px' }}
                className="cursor-pointer pr-1  material-icons text-gray-400 hover:text-red-400"
            >
                delete_forever
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    setStatusData(original['user_id']);
                }}
                style={{ fontSize: '29px' }}
                className={`cursor-pointer material-icons hover:text-primary-500' , ${
                    original['u_status'] === 'Inactive' ? 'text-gray-400' : 'text-primary-300'
                }`}
            >
                {original?.['u_status'] === 'Inactive' ? 'toggle_off' : 'toggle_on'}
            </span>
        </div>
    );
};

export default function () {
    const [addClient, setAddClient] = useState(false);
    const [editClient, setEditTaxPro] = useState(undefined);
    const [confirmExport, setConfirmExport] = useState(false);
    const { role } = useAuthState();
    const { success: isExportEnabled } = useFeatureEnabled('Export');
    const {
        taxPreparers,
        total,
        setFilterParams,
        setFilterString,
        setFilterLoginString,
        filterParams,
        isLoading,
    } = useTaxPrep();
    const [statusData, setStatusData] = useState<{} | undefined>();
    const [isSortEnabled, setisSortEnabled] = useState(false);
    const [cofirmDeleteId, setConfirmDelete] = useState<string | undefined>();
    const [cofirmResendId, setConfirmResend] = useState<string | undefined>();
    const [loginRange, setLoginRange] = useState<string | undefined>();
    const Status = (state: any) => {
        if (state.state === 'Active') {
            return <div className="p-1.5 rounded-full h-2 w-2 ml-5 bg-green-400"></div>;
        } else {
            return <div className="p-1.5 rounded-full h-2 w-2 ml-5 bg-gray-500"></div>;
        }
    };

    const data: Array<{
        user_id: string;
        u_first_name: string;
        u_last_name: string;
        u_user_name: string;
        u_email: string;
    }> = isLoading
        ? taxPreparerLoadingData
        : taxPreparers?.map((item: any) => {
              return { ...item, name: item.u_first_name + ' ' + item.u_last_name };
          }) || taxPreparerLoadingData;

    const columns: Array<any> = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Email',
                accessor: 'u_email',
            },
            {
                Header: 'Status',
                accessor: 'u_status',
                Cell: ({ row: { original } }: any) => {
                    return <Status state={original?.u_status} />;
                },
                sortable: false,
                canSort: false,
            },
            {
                Header: 'Last Login',
                accessor: 'u_last_logged_in',
                Cell: ({ value }: any) => {
                    if (value) return moment(value).format('MMM DD YYYY, hh:mm');
                    return '';
                },
            },
            {
                Header: 'Actions',
                Cell: ({ row: { original } }: any) => (
                    <ActionsCell
                        original={original}
                        setConfirmDelete={setConfirmDelete}
                        setConfirmResend={setConfirmResend}
                        setEditTaxPro={setEditTaxPro}
                        setStatusData={setStatusData}
                    />
                ),
                sortable: false,
                canSort: false,
            },
        ],
        [],
    );

    const isMobile = useIsMobile();
    const [menu, setMenu] = useState<boolean>(false);

    //to sort table columns wise
    const getSortString = (key: string) => {
        return `sort_by=${key}&order_by=${isSortEnabled ? 'ASC' : 'DESC'}`;
    };

    const sortByColumn = (props: HeaderGroup<object>) => {
        setisSortEnabled(!isSortEnabled);

        switch (props.Header) {
            case 'Name':
                setFilterString(getSortString('u_first_name'));
                break;

            case 'Email':
                setFilterString(getSortString('u_email'));
                break;
            case 'Status':
                setFilterString(getSortString('u_status'));
                break;
            case 'Last Login':
                setFilterString(getSortString('u_last_logged_in'));
                break;

            default:
                console.error('Unknown header type: ' + props.Header);
        }
    };

    return (
        <>
            <div className={classNames('w-full h-screen flex', { 'flex-col': isMobile })}>
                {isMobile && <NavBar toggleMenu={() => setMenu(!menu)} />}
                {!isMobile ? (
                    <AdminMenu selected="taxprep" />
                ) : (
                    menu && (
                        <div className="z-50 absolute h-screen">
                            <AdminMenu selected="taxprep" close={() => setMenu(false)} />
                        </div>
                    )
                )}
                <div className="w-full flex h-screen flex-col items-center p-6">
                    <div className="w-full flex flex-col max-w-6xl max-h-full">
                        <div
                            className={classNames('flex w-full items-center py-2', {
                                'mt-5': isMobile,
                            })}
                        >
                            <div className="font-title font-medium flex-grow">
                                {'TAX PRO MANAGER'}
                            </div>
                            <div
                                className={classNames('flex w-auto items-center py-2', {
                                    'mt-5 flex-row': isMobile,
                                })}
                            >
                                {/* {build.data_export && isExportEnabled && role === 'Admin' && (
                                    <Button
                                        onClick={() => setConfirmExport(true)}
                                        className="text-sm mr-2"
                                    >
                                        {'Download XLSX'}
                                    </Button>
                                )} */}
                                <Button onClick={() => setAddClient(true)} className="text-sm">
                                    {`ADD TAX PRO`}
                                </Button>
                            </div>
                        </div>

                        <div className="flex w-full">
                            <InputGroupAdmin
                                id="search"
                                label="Search Text"
                                placeholder="Search Text"
                                value={filterParams?.search}
                                setValue={(value) =>
                                    setFilterParams((p) => ({ ...p, search: value }))
                                }
                                onEnter={(value) =>
                                    setFilterParams((p) => ({ ...p, search: value }))
                                }
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full mt-1"
                            />
                            {build.login_filter && (
                                <DropdownAdmin
                                    isClearable
                                    formData={{}}
                                    id="login_time"
                                    label="Login Filter"
                                    placeholder="Select Range"
                                    options={[
                                        { label: '1-2', value: '1-2' },
                                        { label: '2-4', value: '2-4' },
                                        { label: '5-7', value: '5-7' },
                                        { label: '8-15', value: '8-15' },
                                        { label: '15-30', value: '15-30' },
                                        { label: '30+', value: '30' },
                                    ]}
                                    value={loginRange}
                                    setValue={(value) => {
                                        setFilterLoginString(value);
                                        setLoginRange(value);
                                    }}
                                    type={fieldTypes.DROPDOWN}
                                    className="w-full mt-1"
                                />
                            )}
                        </div>

                        <Table
                            data={data}
                            columns={columns}
                            sortByColumn={sortByColumn}
                            isSortEnabled={isSortEnabled}
                            // onRowClick={({ irs_account_id }) => redirectToEval(irs_account_id)}
                        />

                        <PageManager
                            total={total}
                            offset={filterParams?.offset}
                            setOffset={(offset) => setFilterParams((p) => ({ ...p, offset }))}
                        />
                    </div>
                </div>
            </div>

            <Modal close={() => setAddClient(false)} isOpen={!!addClient}>
                <TaxProDetails close={() => setAddClient(false)} />
            </Modal>

            <Modal close={() => setEditTaxPro(undefined)} isOpen={!!editClient}>
                <TaxProDetails edit={editClient} close={() => setEditTaxPro(undefined)} />
            </Modal>

            <Modal close={() => setConfirmResend(undefined)} isOpen={!!cofirmResendId}>
                <ResendCredentials
                    role="Tax Preparer"
                    user={data.find(({ user_id }) => user_id === cofirmResendId)}
                    close={() => setConfirmResend(undefined)}
                />
            </Modal>

            <Modal close={() => setConfirmDelete(undefined)} isOpen={!!cofirmDeleteId}>
                <DeleteTaxPro
                    user={data.find(({ user_id }) => user_id === cofirmDeleteId)}
                    close={() => setConfirmDelete(undefined)}
                />
            </Modal>
            <Modal close={() => setStatusData(undefined)} isOpen={!!statusData}>
                <EditStatus
                    user={data.find(({ user_id }) => user_id === statusData)}
                    close={() => setStatusData(undefined)}
                />
            </Modal>

            <Modal close={() => setConfirmExport(false)} isOpen={!!confirmExport}>
                <ConfirmExport request_type="Tax Preparer" close={() => setConfirmExport(false)} />
            </Modal>
        </>
    );
}
