import classNames from 'classnames';
import { useEffect } from 'react';
import { interface_field_textDisplay, interface_field_textInput } from '../../utils/interfaces';
import ParentGroup from './ParentGroup';

/**
 * UI Component for Text Display
 **/

type InputDisplayProps = interface_field_textDisplay & {
    className?: string;
    value: string | number;
};

const InputDisplay: React.FC<InputDisplayProps> = (props) => {
    const { id, value, label, className, span, formatFunction } = props;
    const displayValue = formatFunction ? formatFunction(value) : value;

    return (
        <ParentGroup
            id={id}
            className={classNames(`bg-gray-100`, className)}
            label={label}
            value={displayValue}
            style={{ gridColumn: `span ${span}` }}
        >
            <div className="focus:outline-none hover:bg-gray-50 focus:bg-gray-100 focus:border-primary-500 w-full h-16 border-b border-gray-200  px-3 pb-1 pt-5">
                {displayValue}
            </div>
        </ParentGroup>
    );
};

export default InputDisplay;
