/**
 * UI for Crossroad decider
 */
import classNames from 'classnames';
import ParentGroup from './ParentGroup';
import {
    interface_option,
    interface_field_crossroad,
    FormStateInterface,
} from '../../utils/interfaces';
import { useState } from 'react';
import { fieldTypes } from '../../utils/enums';
import SeperatorGroup from './SeperatorGroup';
import moment from 'moment';
import { build } from '../../config';
import Modal from '../basic/Modal';
import Button from '../basic/Button';
import FormConstructor from './FormContructor';

type CrossroadProps = interface_field_crossroad & {
    className?: string;
    disabled?: boolean;
    getOptionValue: (path: string) => string | undefined | null;
    setOptionValue: (update: { path: string; value: any }) => void;
    formState: FormStateInterface;
    setFormState: ({ id, value }: any) => void;
};

const defaultOptions: Array<interface_option> = [
    { label: 'No', value: 'no' },
    { label: 'Yes, Only one', value: 'one' },
    { label: 'Yes, Multiple', value: 'multiple' },
];

const SelectOneModal: React.FC<{
    value: any[];
    displayColumns: any[];
    resetAndClose: () => void;
    saveAndClose: (saveRow: any) => void;
}> = ({ value, displayColumns, resetAndClose, saveAndClose }) => {
    const [saveRow, setSaveRow] = useState();

    return (
        <div
            className="bg-white w-full max-w-4xl flex flex-col items-center "
            style={{ maxHeight: '95%', minHeight: '40%' }}
        >
            <div className="flex font-title text-lg font-medium px-5 py-3 w-full text-left bg-gray-100">
                <div className="flex-grow">{'Multiple Rows Found'}</div>
            </div>

            <div className="flex font-body px-5 pt-4 pb-1 w-full text-left">
                <div className="flex-grow">{`You have ${value.length} rows added in multiple. Only one row value can be saved when shifting from multiple to one`}</div>
            </div>

            <div className="flex font-body px-5 py-1 w-full text-left">
                <div className="flex-grow">{`Select the data row you wish to save`}</div>
            </div>
            <div className="px-5 py-2 mt-2 w-full flex-grow">
                <div className="w-full grid grid-cols-3 bg-gray-600">
                    {/**
                     *Title row
                     */}
                    {displayColumns.map(({ name }) => {
                        return (
                            <div className={classNames('font-semibold text-white px-1 text-sm')}>
                                {name}
                            </div>
                        );
                    })}
                    <div></div>
                </div>

                {value.map((item) => {
                    const isMatch = saveRow?.['id'] === item?.id;
                    return (
                        <div
                            onClick={() => setSaveRow(isMatch ? undefined : item)}
                            className={classNames(
                                'w-full grid grid-cols-3 border border-gray-100 rounded-sm py-1.5 cursor-pointer ',
                                {
                                    'bg-primary-500 hover:bg-primary-700': isMatch,
                                    'hover:bg-gray-100': !isMatch,
                                },
                            )}
                        >
                            {/**
                             *Title row
                             */}
                            {displayColumns.map(({ key }) => {
                                return (
                                    <div
                                        className={classNames('px-1 text-sm', {
                                            'text-white font-semibold': isMatch,
                                        })}
                                    >
                                        {item[key]}
                                    </div>
                                );
                            })}
                            <div></div>
                        </div>
                    );
                })}
            </div>

            {!saveRow ? (
                <div className="bg-gray-50 w-full px-5 mt-5 pt-3">
                    <div className="flex w-full items-center justify-end  pt-2 pb-3">
                        <Button
                            onClick={() => resetAndClose()}
                            secondary
                            className="w-1/4 mr-1 bg-white"
                        >{`CANCEL`}</Button>
                    </div>
                </div>
            ) : (
                <div className="bg-gray-300 w-full px-5 mt-5 pt-3">
                    <div className="flex w-full items-center justify-center  pt-2 pb-3">
                        <div className="flex-grow">
                            <div className="font-semibold">{`Confirm data row?`}</div>
                            <div className="text-sm">{`All the remaining rows will be removed`}</div>
                        </div>
                        <Button
                            onClick={() => resetAndClose()}
                            secondary
                            className="w-1/4 mr-1 bg-white"
                        >{`CANCEL`}</Button>
                        <Button onClick={() => saveAndClose(saveRow)} className="w-1/4 ml-1">
                            {'SAVE'}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const FormDeterminer: React.FC<CrossroadProps> = ({
    id,
    getOptionValue,
    identifier = 'id',
    formStructure,
    header,
    formState,
    setFormState,
    displayColumns,
    dynamicSave,
    preForm,
    disabled,
}) => {
    const value = formState[id];

    const oneError = value ? formState._errors?.[id]?.[value[0][identifier]] || {} : {};
    const oneFormValue = value
        ? value.find((item: any) => item.action !== 'delete') || value[0]
        : {};
    const oneFormState = value ? { ...oneFormValue, _errors: oneError } || {} : {};

    const manyFormState = formState; // formState already has formState._errors
    const manyError = formState._errors;

    const optionValue = getOptionValue(id);

    const setPreform = (state: any) => {
        setFormState(state);
    };

    const setOne = (state: any) => {
        let put_value = [{ [identifier]: `new_${moment.now()}`, [state.id]: state.value }];

        if (Array.isArray(value) && value?.length) {
            put_value = value.map((item: any, index: number) => {
                if (index === 0) return { ...item, [state.id]: state.value };
                else return item;
            });
        }

        setFormState({ id, value: put_value });
    };

    const setMany = (state: any) => {
        setFormState(state);
    };

    switch (optionValue) {
        case 'no':
            return (
                <FormConstructor
                    disabled={disabled}
                    structure={[{ type: fieldTypes.TERMINAL, noAction: true }]}
                    formState={{}}
                    setFormState={() => {}}
                    getOptionValue={() => {
                        return 'one';
                    }}
                    setOptionValue={() => {}}
                />
            );

        case 'one':
            return (
                <>
                    <FormConstructor
                        disabled={disabled}
                        structure={[
                            { type: fieldTypes.SEPERATOR, title: header },
                            ...(preForm || []),
                            { type: fieldTypes.SEPERATOR },
                        ]}
                        formState={formState}
                        setFormState={setPreform}
                        getOptionValue={() => 'one'}
                        setOptionValue={() => {}}
                    />
                    <FormConstructor
                        disabled={disabled}
                        structure={[...formStructure, { type: fieldTypes.TERMINAL }]}
                        formState={oneFormState}
                        setFormState={setOne}
                        getOptionValue={() => 'one'}
                        setOptionValue={() => {}}
                    />
                </>
            );

        case 'multiple':
            return (
                <>
                    <FormConstructor
                        disabled={disabled}
                        structure={[
                            { type: fieldTypes.SEPERATOR, title: header },
                            ...(preForm || []),
                            { type: fieldTypes.SEPERATOR },
                        ]}
                        formState={value}
                        setFormState={setPreform}
                        getOptionValue={() => 'one'}
                        setOptionValue={() => {}}
                    />
                    <FormConstructor
                        disabled={disabled}
                        structure={[
                            {
                                type: fieldTypes.TABLE,
                                id,
                                displayColumns,
                                rowStructure: formStructure,
                                identifier,
                                dynamicSave,
                                header,
                            },
                            { type: fieldTypes.TERMINAL },
                        ]}
                        formState={manyFormState}
                        setFormState={setMany}
                        getOptionValue={() => 'one'}
                        setOptionValue={() => {}}
                    />
                </>
            );

        default:
            return (
                <>
                    <SeperatorGroup type={fieldTypes.SEPERATOR} />
                    <div className="flex flex-col col-span-2 w-full items-center">
                        <div className="w-full h-0.5 bg-gray-200"></div>
                        <div className="text-sm text-gray-300 font-semibold mt-5">{`Answer to continue`}</div>
                    </div>
                </>
            );
    }
};

const Crossroad: React.FC<CrossroadProps> = (props) => {
    const {
        id,
        displayColumns,
        formStructure,
        label,
        className,
        formState,
        setFormState,
        options: providedOptions,
        getOptionValue,
        setOptionValue,
        disabled,
    } = props;

    const options = providedOptions || defaultOptions;

    const value = formState[id];
    const optionValue = getOptionValue(id);

    const setFormValue = (v: any) => {
        setFormState({ id, value: v });
    };

    const [selectOneModal, setSelectOneModal] = useState<boolean>(false);

    const currentOption = options.find(({ value }) => value === optionValue);

    // const resolveOptionValue = (option: interface_option) => {
    //     setOptionValue({ value: option.value, path: `${id}_count` });
    // };

    const setCrossroadOptionValue = ({
        value,
        saveRow,
    }: {
        value: string | undefined;
        saveRow?: any;
    }) => {
        setOptionValue({ value: value, path: `${id}_count` });
        let updatedData: any;
        switch (value) {
            case 'no':
            case undefined:
                updatedData = formState[id]?.map((item: any) => {
                    return { action: 'delete', ...item };
                });
                break;

            case 'one':
                updatedData = formState[id]?.map((item: any, index: number) => {
                    if (saveRow?.['id'] === item.id) {
                        const { action, ...original } = item;
                        return original;
                    } else return { action: 'delete', ...item };
                });
                break;

            case 'multiple':
                updatedData = formState[id]?.map((item: any) => {
                    const { action, ...original } = item;
                    return original;
                });
                break;

            default:
                updatedData = formState[id];
        }
        setFormValue(updatedData);
    };

    const resolveOptionValue = (option: interface_option) => {
        const { value: optionValue } = option;
        // setOptionValue({ value: option.value, path: `${id}_count` });
        const check = ['multiple', 'no'];
        if (check.includes(currentOption?.value) && optionValue === 'one' && !selectOneModal) {
            if (formState?.[id].length > 1) {
                setSelectOneModal(true);
                return;
            } else {
                setCrossroadOptionValue({
                    value: 'one',
                    saveRow: formState[id][0],
                });
                return;
            }
        }
        if (optionValue === currentOption?.value) {
            setCrossroadOptionValue({ value: undefined });
        } else {
            setCrossroadOptionValue({ value: optionValue });
        }
    };

    return (
        <ParentGroup className={classNames('col-span-4', className)}>
            <div className="w-full">
                <div className={classNames('pointer-events-none text-sm')}>{label}</div>
                <div className="flex items-center  w-full">
                    {options.map((option: interface_option) => {
                        const { label: optionLabel, value: optionValue } = option;

                        return (
                            <>
                                <label
                                    onChange={() => resolveOptionValue(option)}
                                    className="flex flex-grow items-center hover:bg-gray-50 py-2"
                                >
                                    <div
                                        className={classNames('h-4 w-4 rounded-full border-2', {
                                            'border-primary-500':
                                                currentOption?.value === optionValue,
                                            'border-gray-200': currentOption?.value !== optionValue,
                                        })}
                                    >
                                        {currentOption?.value === optionValue && (
                                            <div className="h-2 w-2 m-0.5 rounded-full bg-primary-500" />
                                        )}
                                    </div>
                                    <input
                                        name={id}
                                        id={optionValue}
                                        type="radio"
                                        checked={currentOption?.value === optionValue}
                                        className={classNames(
                                            'focus:outline-primary border-4 border-gray-200 hidden rounded-md p-2 pt-5 h-4 w-4 mx-1',
                                            {
                                                'bg-primary-500':
                                                    currentOption?.value === optionValue,
                                            },
                                        )}
                                    />

                                    <div
                                        className={classNames('flex-grow ml-1', {
                                            'text-gray-500': !currentOption?.value === optionValue,
                                        })}
                                    >
                                        {optionLabel}
                                    </div>
                                </label>
                            </>
                        );
                    })}
                </div>
            </div>

            {formStructure && displayColumns && <FormDeterminer {...{ ...props }} />}

            <Modal isOpen={selectOneModal} close={() => setSelectOneModal(false)}>
                <SelectOneModal
                    value={value}
                    displayColumns={displayColumns}
                    resetAndClose={() => setSelectOneModal(false)}
                    saveAndClose={(saveRow) => {
                        setSelectOneModal(false);
                        setCrossroadOptionValue({ value: 'one', saveRow });
                    }}
                />
            </Modal>
        </ParentGroup>
    );
};

export default Crossroad;
