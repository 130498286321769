import { api } from '.';

export const fetchClientWithFinance = async (irs_account_id: string) => {
    const response = await api.get(`/ClientWithFinance/${irs_account_id}`);

    let clientData = response.data;

    return { ...clientData.data[0], meta_data: clientData.meta_data };
};

const createPayload = (irs_account_id: string, data: any[], meta_data: any) => {
    if (!Array.isArray(data)) {
        return { irs_account_id, data };
    }

    const regEx = /^new/;
    const injectedData = data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: meta_data.irs_account_id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    const payload = {
        irs_account_id,
        meta_data: { ...meta_data, irs_account_id },
        data: injectedData,
    };

    return payload;
};

export const upsertOtherFinancialInfo = async ({ meta_data, irs_account_id, ...data }: any) => {
    const payload = {
        irs_account_id,
        action: 'update',
        meta_data,
        ...data,
    };

    const { data: responseData } = await api.post(`/otherFinInfoByAction`, payload);
    return responseData;
};

export const upsertSafeDepositBox = async ({
    irs_account_id,
    safe_deposit_data,
    meta_data,
}: any) => {
    const payload = createPayload(irs_account_id, safe_deposit_data, meta_data);

    const { data } = await api.post(`/safeDepositByAction`, payload);
    return data;
};

export const upsertTransferredAssets = async ({
    irs_account_id,
    transfer_assets_data,
    meta_data,
}: any) => {
    const payload = createPayload(irs_account_id, transfer_assets_data, meta_data);

    const { data } = await api.post(`/assetTransferByAction`, payload);
    return data;
};

export const upsertAboradProperties = async ({
    irs_account_id,
    real_property_outside_us_data,
    meta_data,
}: any) => {
    const payload = createPayload(irs_account_id, real_property_outside_us_data, meta_data);
    const { data } = await api.post(`/realPropertyOutsideUsByAction`, payload);
    return data;
};
