export default {
    'primary-100': '#d2dae8',
    'primary-200': '#d2dae8',
    'primary-300': '#a5b5d1',
    'primary-400': '#4c6ca2',
    'primary-500': '#1f478b',
    'primary-600': '#19396f',
    'primary-700': '#132b53',
    'primary-800': '#0c1c38',
    'primary-900': '#060e1c',
};
