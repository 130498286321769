export const user_dashboard = {
    Profile: 'Perfil',
    Transcripts: 'Transcripciones',
    Documents: 'Documentos',
    Forms: 'Formulario',
    Billing: 'Facturación',
    Tasks: 'Tareas',
    cancel: 'cancelar',
    Edit: 'Editar',
    'First Name': 'Nombre',
    'Last Name': 'Apellido',
    Email: 'Correo Electrónico',
    'Date of Birth': 'Fecha de Nacimiento',
    Phone: 'Teléfono',
    Fax: 'Fax',
    Status: 'Estado',
    'Assigned to': 'Asignado a',
    'Street Name': 'Nombre de la Calle',
    'Apartment/Unit No.': 'Número de Apartamento/Unidad',
    City: 'Ciudad',
    State: 'Estado',
    'Zip Code': 'Código Postal',
    Country: 'País',
    'Driver License': 'Licencia de Conducir',
    'Service type': 'Tipo de Servicio',
    'Save Updates': 'Guardar Actualizaciones',
};
