/**
 * UI for Checkboxes and Radio selectors
 */
import classNames from 'classnames';
import ParentGroup from './ParentGroup';
import CheckMarkLogo from '../../assets/checkmark.svg';
import { interface_option, interface_field_radio } from '../../utils/interfaces';

type RadioGroupProps = interface_field_radio & {
    className?: string;
    smallPadding?: boolean;
    styles?: { [key: string]: any };
    value: any | Array<any>;
    error?: string;
    setValue: (value: any) => void;
    business?: any;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
    id,
    value: formValue,
    error,
    setValue,
    label,
    options,
    className,
    smallPadding,
    business,
}) => {
    const resolveValue = (option: interface_option | undefined) => {
        if (option === undefined) setValue(undefined);
        else {
            const { value: optionValue } = option;

            if (Array.isArray(formValue)) {
                /**
                 * Since value is an array, this means the component is multi select checkbox
                 * SO addition and removal has to be handled seperately
                 * */

                const index = formValue.findIndex((o) => o === optionValue);
                if (index >= 0) {
                    /** Since element already exits, fiond and remove it */
                    const newArray = formValue.filter((o) => o !== optionValue);
                    setValue(newArray);
                } else {
                    /** Since element doesn't exits, add at the end */
                    setValue([...formValue, optionValue]);
                }
            } else {
                /**
                 * This means the component is radio, so simply replace the value
                 * or remove the value if clicked again
                 *  */

                if (formValue === optionValue) {
                    /** Remove the value if none exist */
                    setValue(undefined);
                } else {
                    /** Replace or set current value */
                    setValue(optionValue);
                }
            }
        }
    };

    return (
        <ParentGroup className={className} error={error} styles={{ gridColumn: `span 4` }}>
            <div className="w-full">
                <div className={classNames('pointer-events-none text-sm')}>{label}</div>
                <div className="flex flex-wrap items-center  w-full">
                    {options.map((option: interface_option) => {
                        const { label: optionLabel, value: optionValue } = option;

                        let isSelected = false;
                        if (Array.isArray(formValue)) {
                            const index = formValue.find((o) => o === optionValue);
                            if (index) isSelected = true;
                        } else {
                            if (formValue === optionValue) isSelected = true;
                        }

                        return (
                            <>
                                <label
                                    onChange={() => resolveValue(option)}
                                    className={classNames(
                                        'flex flex-grow items-center hover:bg-gray-50',
                                        {
                                            'py-1': smallPadding,
                                            'py-2': !smallPadding,
                                        },
                                    )}
                                    style={{ maxWidth: 180 }}
                                >
                                    <div
                                        className={classNames('h-4 w-4 rounded-full border-2', {
                                            'border-primary-500': isSelected,
                                            'border-gray-200': !isSelected,
                                        })}
                                    >
                                        {isSelected && (
                                            <div className="h-2 w-2 m-0.5 rounded-full bg-primary-500" />
                                        )}
                                    </div>
                                    <input
                                        id={id}
                                        value={optionValue}
                                        type="radio"
                                        checked={isSelected}
                                        className={classNames(
                                            'focus:outline-primary border-4 border-gray-200 hidden rounded-md p-2 pt-5 h-4 w-4 mx-1',
                                            {
                                                'bg-primary-500': isSelected,
                                            },
                                        )}
                                    />

                                    <div
                                        className={classNames('flex-grow ml-1', {
                                            'text-gray-500': !isSelected,
                                        })}
                                    >
                                        {optionLabel}
                                    </div>
                                    {/* <input
                                        type="radio"
                                        name={id + optionValue + business?.id}
                                        id={id + optionValue + business?.id}
                                        checked={isSelected}
                                        
                                        className={classNames(
                                            'focus:outline-primary border-4 border-gray-200 rounded-md p-2 pt-5 h-4 w-4 mx-1',
                                        )}
                                    />

                                    <div
                                        className={classNames('flex-grow ml-1', {
                                            'text-gray-500': !isSelected,
                                        })}
                                    >
                                        {optionLabel}
                                    </div> */}
                                </label>
                            </>
                        );
                    })}
                </div>
            </div>
        </ParentGroup>
    );
};

export default RadioGroup;
