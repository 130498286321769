import { useEffect, useState } from 'react';
import useFormsList from '../../api/tax-prep/useFormsList';
import Dropdown from '../client/Dropdown';
import { fieldTypes } from '../../utils/enums';
import classNames from 'classnames';
import useTaxPrepClientData from './useTaxPrepClientData';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { useAuthState } from '../../context/auth';
import { useParams } from 'react-router-dom';
import CopyBusinessClient from './CopyBusiness';
import useClientCopySectionData from './useClientCopySectionData';

const YearToggle = ({
    year,
    setYear: setClientYear,
}: {
    year: string;
    setYear: (year: string) => void;
}) => {
    const { irs_account_id } = useAuthState();

    const { isNew, businessId, businessGroup } = useTaxPrepClientData();
    const [copyBusinessModal, setCopyBusinessModal] = useState<string>();

    const [instantCopy, setInstantCopy] = useState<boolean>(isNew);
    useEffect(() => {
        setInstantCopy(isNew);
    }, [isNew]);

    const { address, year: currentYear } = useTaxPrepStructure();
    const { years } = useFormsList({ irs_account_id: irs_account_id! });
    const [index, setIndex] = useState<number>(0);

    const previousYearIndex = Math.abs(index - 1) % years.length;
    const nextYearIndex = Math.abs(index + 1) % years.length;

    const [copyMode, setCopyMode] = useState<boolean>(false);
    const [copyYear, setCopyYear] = useState<string | undefined>(years[previousYearIndex]);
    const { confirm, isSuccess, reset, isLoading } = useClientCopySectionData(copyYear);

    const setYear = (toYear: string) => {
        if (businessId) {
            setCopyBusinessModal(toYear);
            // } else if (address === 'business_data') {
            //     // Implement copy busniess from seveal years like in admin
            //     window.alert('Implementation Pending');
        } else {
            setClientYear(toYear);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            reset();
            setCopyMode(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (!years) {
            return;
        }

        const newIndex = years.findIndex((y) => y === currentYear);
        if (newIndex !== index) {
            setIndex(newIndex);
        }
    }, [currentYear, years]);

    useEffect(() => {
        if (years[previousYearIndex] !== copyYear) {
            setCopyYear(years[previousYearIndex]);
        }
    }, [previousYearIndex]);

    if (!years) {
        return (
            <div className="flex flex-col w-full border border-gray-300 rounded-md p-1">
                <div>Loading...</div>
            </div>
        );
    }

    if (copyMode) {
        return (
            <div className="flex flex-col w-full border border-gray-300 rounded-md p-1">
                <Dropdown
                    className="mt-0"
                    type={fieldTypes.DROPDOWN}
                    label="Select a year to copy data from"
                    id="copyYear"
                    placeholder="Year"
                    setValue={(value) => setCopyYear(value)}
                    value={copyYear}
                    options={(years || [])
                        .filter((y: string) => y !== currentYear)
                        .map((y: string) => ({ value: y, label: y }))}
                />

                {isLoading ? (
                    <div className="w-full flex flex-grow justify-end px-3">
                        <span
                            className="material-icons animate-spin w-5 h-5 flex items-center justify-center"
                            style={{ fontSize: 18 }}
                        >
                            refresh
                        </span>
                        <div className="m-0.5 cursor-pointer hover:text-gray-700 text-sm text-gray-500 font-semibold">
                            Copying data...
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex flex-grow justify-end px-3">
                        <div
                            onClick={() => copyYear && confirm(address)}
                            className={classNames(
                                'm-0.5 cursor-pointer mr-2 text-sm font-semibold',
                                {
                                    'text-primary-500 hover:text-primary-700': !!copyYear,
                                    'text-gray-500 hover:text-gray-700': !copyYear,
                                },
                            )}
                        >
                            Confirm
                        </div>
                        <div
                            onClick={() => {
                                setCopyYear(undefined);
                                setCopyMode(false);
                            }}
                            className="m-0.5 cursor-pointer hover:text-gray-700 text-sm text-gray-500 font-semibold"
                        >
                            Cancel
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col w-full  border border-gray-300 rounded-md">
                <div className="flex w-full p-2">
                    <div className="flex flex-col items-center">
                        <div className="text-xs">Current Year</div>
                        <div className="flex">
                            {years?.length > 1 && (
                                <span
                                    className="cursor-pointer hover:text-primary-700 text-primary-500 material-symbols-rounded transform rotate-180"
                                    onClick={() => setYear(years[previousYearIndex])}
                                >
                                    play_arrow
                                </span>
                            )}
                            <div className="w-11 text-center">{year}</div>
                            {years?.length > 1 && (
                                <span
                                    className="cursor-pointer hover:text-primary-700 text-primary-500 material-symbols-rounded"
                                    onClick={() => setYear(years[nextYearIndex])}
                                >
                                    play_arrow
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="flex-grow"></div>
                    <div className="flex flex-col items-end">
                        <div className="text-xs text-gray-400">{`Filing for sessions ${years?.map(
                            (y: string) => y,
                        )}`}</div>
                        <div
                            onClick={() => setCopyMode(true)}
                            className="mt-0.5 flex items-center justify-center text-primary-500 hover:text-primary-700 cursor-pointer"
                        >
                            {!businessId && (
                                <>
                                    <span
                                        style={{ fontSize: 18 }}
                                        className="text-lg mr-1 material-symbols-rounded"
                                    >
                                        file_copy
                                    </span>

                                    <div className="text-sm">{`Copy data from another year`}</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {isNew && instantCopy && (
                    <div className="flex bg-primary-500 py-1 px-2 w-full rounded-b-md text-sm">
                        {isLoading ? (
                            <>
                                <span
                                    className="text-white material-icons animate-spin w-5 h-5 flex items-center justify-center"
                                    style={{ fontSize: 18 }}
                                >
                                    refresh
                                </span>
                                <div className="ml-2 flex-grow text-white">{`Copying data from ${years[previousYearIndex]} ==> ${year}`}</div>
                            </>
                        ) : (
                            <>
                                <div className="flex-grow text-white">{`Copy data from the year ${years[previousYearIndex]}?`}</div>
                                <div className="flex">
                                    <div
                                        onClick={() => confirm(address)}
                                        className={classNames('m-0.5 cursor-pointer mr-3', {
                                            'text-white hover:text-gray-300': true,
                                        })}
                                    >
                                        Yes
                                    </div>
                                    <div
                                        onClick={() => {
                                            setCopyYear(undefined);
                                            setInstantCopy(false);
                                        }}
                                        className={classNames('m-0.5 cursor-pointer mr-2', {
                                            'text-white hover:text-gray-300': true,
                                        })}
                                    >
                                        No
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
            {copyBusinessModal && (
                <CopyBusinessClient
                    isOpen={copyBusinessModal ? true : false}
                    close={() => setCopyBusinessModal(undefined)}
                    toYear={copyBusinessModal || 'UNKNOWN'}
                />
            )}
        </>
    );
};

export default YearToggle;
