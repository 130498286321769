import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertInterestIncome = (
    year: string,
    { interest_income_data, meta_data, id, irs_account_id }: any,
) => {
    const regEx = /^new/;
    const data = interest_income_data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: irs_account_id, taxprep_account_id: id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/interestincomeByActionTP`, {
        irs_account_id: irs_account_id,
        meta_data: { ...meta_data, taxprep_account_id: id, irs_account_id: irs_account_id },
        data,
        taxprep_year: year,
    });
};

const interestIncomeForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'payer_name',
        label: 'Name of Payer',
        placeholder: 'john',
    },
    {
        type: fieldTypes.RADIO,
        id: 'income_interest_releted_real_estate',
        label: 'Is your Interest Income related to Real State?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.RADIO,
                            id: 'seller_financed_mortage',
                            label: 'Is it from seller financed mortage?',
                            options: [
                                { value: '1', label: 'Yes' },
                                { value: '0', label: 'No' },
                            ],
                            branches: {
                                inline: true,
                                paths: [
                                    {
                                        value: '1',
                                        form: [
                                            {
                                                type: fieldTypes.TEXT_INPUT,
                                                id: 'interest_income_percent',
                                                label: 'What is the Interest percentage?',
                                                placeholder: 'john',
                                            },
                                            {
                                                type: fieldTypes.TEXT_INPUT,
                                                id: 'buyer_ssn',
                                                label: "Buyer's Social Security Number",
                                                placeholder: 'john',
                                            },
                                            {
                                                type: fieldTypes.TEXT_INPUT,
                                                id: 'buyer_address',
                                                label: "Buyer's Address",
                                                placeholder: 'john',
                                            },
                                            {
                                                type: fieldTypes.AMOUNT_INPUT,
                                                id: 'interest_income_amount',
                                                label: 'Enter the Amount of Interest Income',
                                                placeholder: 'john',
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    },
];

const interestIncomeSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'interest_income_data',
        label: 'Do you have any Interest Income?',
        header: 'Interest Income Data',
        displayColumns: [
            {
                name: 'Name of Payer',
                key: 'payer_name',
            },
        ],
        formStructure: interestIncomeForm,
    },
];

export const page: PageInputInterface = {
    address: 'interest_income_data',
    name: 'Interest Income Information',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: interestIncomeSecForm,
    save: upsertInterestIncome,
};
