import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertIncomeDetails = (
    year: string,
    { income_details_data, meta_data, irs_account_id, id }: any,
) => {
    const regEx = /^new/;
    const data = income_details_data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: irs_account_id, taxprep_account_id: id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/incomeDetailsByActionTP`, {
        irs_account_id: irs_account_id,
        meta_data: { ...meta_data, taxprep_account_id: id, irs_account_id: irs_account_id },
        data,
        taxprep_year: year,
    });
};

const incomeForm: FormInterface = [
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'employee_wages',
        label: 'Household employee wages',
        placeholder: '',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'tip_income',
        label: 'Tip Income',
        placeholder: '',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'waiver_payment',
        label: 'Medical waiver payments',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'care_benefits',
        label: 'Taxable dependent care benefits',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'adoption_benefits',
        label: 'Employer-provided adoption benefits',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'wages',
        label: 'Wages',
        placeholder: '',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'other_earned_income',
        label: 'Other Earned Income',
        placeholder: '',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'irs_distributions',
        label: 'IRA distributions',
        placeholder: '',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'Pensions_annuities',
        label: 'Pensions Annuities',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'social_security_benefits',
        label: 'Social Security Benefits',
        placeholder: '',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'lump_sum_election',
        label: 'Do you elect to use the lump-sum election method?',
        placeholder: '',
        options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
        ],
    },
];

const incomeSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'income_details_data',
        label: 'Do you have any income streams?',
        header: 'Income Details',
        displayColumns: [
            {
                name: 'Employee Wage',
                key: 'employee_wages',
            },
            {
                name: 'Wages',
                key: 'wages',
            },
            {
                name: 'Lump Sum Election',
                key: 'lump_sum_election',
            },
        ],
        formStructure: incomeForm,
    },
];

export const page: PageInputInterface = {
    address: 'income_details_data',
    name: 'Income Details',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: incomeSecForm,
    save: upsertIncomeDetails,
};
