export const taxometrics_dashboard = {
    'Tax Return Status': 'Estado de la Declaración de Impuestos',
    Income: 'Ingresos',
    Levies: 'Embargos',
    Liens: 'Gravámenes',
    'IRS Debt Actions': 'Acciones de Deuda del IRS',
    Audits: 'Auditorías',
    'Passport Revocation': 'Revocación de Pasaporte',
    'Private Collections': 'Colecciones Privadas',
    'Total Balance Due:': 'Saldo Total Adeudado:',
    'Total Balance Due': 'Saldo Total Adeudado',
    'Total Interest': 'Intereses Totales',
    'Total Interest:': 'Intereses Totales:',
    'Civil Penalties Balance Due': 'Saldo por Penalidades Civiles',
    'In Compliance': 'Cumplimiento',
    'In Compliance:': 'Cumplimiento:',
    'No Return Filed:': 'Sin Declaración Presentada:',
    'Substitute for Return:': 'Sustituto de Declaración:',
    'Intent to Levy Notice:': 'Aviso de Intención de Embargo:',
    'Lien Notice Issued:': 'Aviso de Gravamen Emitido:',
    'IRS Collections Active:': 'Colecciones del IRS Activas:',
    'Installment Agreement Pending': 'Acuerdo de Pagos Pendiente',
    'In Installment Agreement': 'En Acuerdo de Pagos',
    'Currently Not Collectible': 'Actualmente No Cobrable',
    'No Active Audit/Examination': 'Sin Auditoría/Examen Activo',
    'Certified for Revocation': 'Certificado para Revocación',
    'Private Collection Agency Assigned:': 'Agencia de Cobranza Privada Asignada:',
    'Total Balances': 'Balances Totales',
    'Total Balances:': 'Balances Totales:',
    'Missing Information!': '¡Información faltante!',
    'No Intent to Levy Notice Issued': 'No se emitió un aviso de intención de embargo',
    'No Lien Notice Issued': 'No se emitió un aviso de gravamen',
    'No Active Collections': 'No hay cobros activos',
    'No Active Audits/Examinations': 'No hay auditorías/exámenes activos',
    'Passport Not Certified for Revocation': 'Pasaporte no certificado para revocación',
    'Not Assigned to Private Collection Agency': 'No asignado a una agencia de cobro privada',
    'No Balance Due': 'No hay saldo pendiente',
    'No Penalties': 'No hay penalidades',
    'No Interests': 'No hay intereses',
    'No Civil Penalties Balance Due': 'No hay saldo pendiente de multas civiles',
    'No Tax Filing Data': 'No hay datos de declaración de impuestos',
    'All Dates': 'Todas las fechas',
    'Bankruptcy/Other legal action': 'Quiebra/otra acción legal',
    'Account Collectible actions': 'Acciones coleccionables de cuenta',
    'Installment Agreement Actions': 'Acciones de acuerdo de pago a plazos',
    'Offer In Compromise Actions': 'Acciones de oferta en compromiso',
    'Audit/Eamination Active': 'Auditoría/examen activo',
    'Years with Interest': 'Años con interés',
    'Years with Interest:': 'Años con interés:',
    'Years with Penalties': 'Años con penalidades',
    'Total Penalties': 'Penalidades totales',
    'Total Penalties:': 'Penalidades totales:',
    'Years with Balances': 'Años con saldos',
    'No Data': 'Sin datos',
    'View Details': 'Ver Detalles',
    'No Data Available': 'Datos No Disponibles',
    'Taxpayer Information': 'Información del Contribuyente',
    'Client Authorizations': 'Autorizaciones del Cliente',
    'No authorizations yet!': '¡Aún no hay autorizaciones!',
    'authorizations data missing please add authorization':
        'datos de autorizaciones faltantes por favor agregue autorización',
    'using the button below.': 'usando el botón de abajo.',
    'New Authorization': 'Nueva Autorización',
    'Marriage Date': 'Fecha de matrimonio',
};
