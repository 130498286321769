import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertDeductions = (
    year: string,
    { itemized_deduction_data, meta_data, irs_account_id, id }: any,
) => {
    const regEx = /^new/;
    const data = itemized_deduction_data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: irs_account_id, taxprep_account_id: id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/itemizedDeductionByActionTP`, {
        irs_account_id: irs_account_id,
        meta_data: { ...meta_data, taxprep_account_id: id, irs_account_id: irs_account_id },
        data,
        taxprep_year: year,
    });
};

const deductionsForm: FormInterface = [
    { type: fieldTypes.SEPERATOR, heading: 'Medical' },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'medicines',
        label: 'Medicines',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'drugs',
        label: 'Drugs',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'doctors',
        label: 'doctors',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'specialist',
        label: 'specialist',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'dentists',
        label: 'dentists',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'chiropractors',
        label: 'chiropractors',
        placeholder: 'john',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Taxes & Fees' },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'auto_licence_fees',
        label: 'Auto Licence Fees',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'auto_sales_tax',
        label: 'Auto Sales Tax',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'real_estate_taxes',
        label: 'Real Estate Taxes',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'property_taxes',
        label: 'Property Taxes',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'other_taxes',
        label: 'Other Taxes',
        placeholder: 'john',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Contributions/Donations' },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'churches',
        label: 'Churches',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'youth_program',
        label: 'Youth Program',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'missions',
        label: 'Missions',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'heart_funds',
        label: 'Heart Funds',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'salvation_army',
        label: 'Salvation Army',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'others',
        label: 'Others',
        placeholder: 'john',
    },
];

const deductionsSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'itemized_deduction_data',
        label: 'Do you have any Deductions?',
        header: 'Itemized Deductions Data',
        displayColumns: [
            {
                name: 'Medicines',
                key: 'medicines',
            },
            {
                name: 'Drugs',
                key: 'drugs',
            },
            {
                name: 'Doctors',
                key: 'doctors',
            },
        ],
        formStructure: deductionsForm,
    },
];

export const page: PageInputInterface = {
    address: 'itemized_deduction_data',
    name: 'Itemized Deductions Information',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: deductionsSecForm,
    save: upsertDeductions,
};
