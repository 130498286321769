import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { enumSections } from '../../utils/enums';
import { pageRefiner, useCompletedPercentage, useFeatureEnabled } from '../../utils/helpers';
import { PageInterface, SectionInterface } from '../../utils/interfaces';
import CheckMarkLogo from '../assets/checkmark.svg';
import { Link as ScrollLink } from 'react-scroll';
import { useQuery } from 'react-query';
import { getfetchFunction } from '../../api';
import { useFormState } from '../../context/form';
import Modal from './Modal';
import GenerateFormModal from './GenerateForm';
import { AuditLog } from '../../pages/AuditLog';
import { build } from '../../config';

const AuditLogTaxRes: React.FC<{ close: () => void; irs_id: string }> = ({ close, irs_id }) => {
    return (
        <div className="px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="flex w-full">
                <AuditLog id={irs_id} auditType={'tax-res'} close={close} />
            </div>
        </div>
    );
};

const LineSection: React.FC<{ isSelected: boolean; isCurrent: boolean; index: number | null }> = ({
    isSelected,
    isCurrent,
    index,
}) => {
    return (
        <div className="flex items-center justify-center relative h-full w-12">
            <div
                className={classNames(
                    'h-5 w-5 rounded-2xl  z-20 flex items-center justify-center',
                    {
                        'bg-primary-500': isSelected && !isCurrent,
                        'bg-gray-700 ': isCurrent,
                        'bg-gray-300': !isSelected,
                    },
                )}
            >
                {index !== null && (
                    <div className={'text-sm text-center font-semibold text-white'}>
                        {typeof index === 'number' ? index + 1 : index}
                    </div>
                )}
            </div>
            {/* <div
                className={classNames('absolute w-1 top-0 h-1/2 z-10', {
                    'bg-gray-800': present || past,
                    'bg-gray-400': future || start,
                })}
                style={{ left: 22 }}
            />
            <div
                className={classNames('absolute w-1 top-1/2 h-1/2 z-10', {
                    'bg-gray-800': past,
                    'bg-gray-400': present || future || start,
                })}
                style={{ left: 22 }}
            /> */}

            {/* Section to render if current page is selected */}
            {isCurrent && <div className="absolute h-5 w-1/2 right-0 bg-gray-700 z-10" />}
        </div>
    );
};

const PageProducer: React.FC<{
    businessId?: string;
    currentPage: PageInterface;
    index: number;
    isSelected: boolean;
    isError: boolean;
    isCurrent: boolean;
}> = ({ currentPage, index, isSelected, isCurrent, businessId, isError }) => {
    return (
        <ScrollLink
            to={currentPage.address + businessId}
            offset={-50}
            duration={500}
            smooth
            className="w-full cursor-pointer "
        >
            <div
                className={classNames('flex items-center h-8', {
                    'bg-white hover:bg-gray-100': !isError,
                    'bg-red-100 hover:bg-red-200': isError,
                })}
            >
                <div className={classNames('flex-grow text-right font-body text-sm')}>
                    {currentPage.name}
                </div>

                <LineSection {...{ index, isSelected, isCurrent }} />
            </div>
        </ScrollLink>
    );
};

const BusinessSection: React.FC<{
    key: number;
    business: any;
    subSections: SectionInterface['subSections'];
}> = ({ business, subSections }) => {
    const { meta_data, _error_sections } = useFormState();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const SubSection = ({ currentPage, index }: { currentPage: PageInterface; index: number }) => {
        const isSelected = meta_data?.page_json[business.id]
            ? meta_data?.page_json[business.id][currentPage.address]
            : false;

        const isError = _error_sections[business.id + '.' + currentPage.address];

        useEffect(() => {
            if (isError) {
                setError(isError);
            }
        }, []);

        return (
            <PageProducer
                index={index}
                currentPage={currentPage}
                isCurrent={false}
                isSelected={isSelected}
                businessId={business.id}
                isError={isError}
            />
        );
    };

    return (
        <div className="w-full">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={classNames('cursor-pointer flex items-center bg-gray-50 h-9', {
                    'bg-white hover:bg-gray-100': !error,
                    'bg-red-100 hover:bg-red-200': error,
                })}
            >
                <div className="h-px flex-grow bg-gray-500 mr-2"></div>
                <div className={classNames('text-right text-gray-600 font-title text-sm')}>
                    {business.name}
                </div>

                <LineSection
                    {...{
                        index: null,
                        isSelected: false,
                        isCurrent: false,
                    }}
                />
            </div>

            {isOpen &&
                subSections
                    .filter(({ address }) => address !== 'business_home')
                    .map((currentPage, index) => <SubSection {...{ currentPage, index }} />)}
        </div>
    );
};

const SectionComponent: React.FC<{ key: number; section: SectionInterface }> = ({
    key,
    section,
}) => {
    const { business_data, meta_data, _error_sections } = useFormState();
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const isError = _error_sections['business_home'];

    const Section: React.FC = () => {
        return (
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={classNames('flex items-center h-9 bg-white pr-7')}
            >
                <div className="h-px flex-grow bg-primary-500 mr-2"></div>
                <div className={classNames('text-right text-gray-600 font-title text-sm')}>
                    {section.name}
                </div>
            </div>
        );
    };

    switch (section.id) {
        case enumSections.BUSINESS_INFORMATION:
            return (
                <div key={key} className="w-full px-2">
                    <Section />
                    <ScrollLink
                        to={'business_home'}
                        offset={-50}
                        duration={500}
                        smooth
                        className="w-full cursor-pointer "
                    >
                        <div
                            className={classNames(
                                'flex items-center bg-white hover:bg-gray-100 h-8',
                                {
                                    'bg-red-100': isError,
                                },
                            )}
                        >
                            <div className={classNames('flex-grow text-right font-body text-sm')}>
                                {`Business Information`}
                            </div>

                            <LineSection {...{ index: 0, isSelected: false, isCurrent: false }} />
                        </div>
                    </ScrollLink>
                    {business_data?.map((business: any, index: number) => {
                        return (
                            <BusinessSection
                                key={index}
                                subSections={section.subSections}
                                business={business}
                            />
                        );
                    })}
                </div>
            );

        default:
            return (
                <div key={key} className="w-full px-2">
                    <Section />
                    {section.subSections.map((subSection, index) => {
                        if (subSection.address === 'personal_information') {
                        }

                        const isPageSelected = meta_data?.page_json[subSection.address];
                        const isPageError = _error_sections[subSection.address];

                        return (
                            <PageProducer
                                index={index}
                                currentPage={subSection}
                                isCurrent={false}
                                isSelected={isPageSelected}
                                isError={isPageError}
                            />
                        );
                    })}
                </div>
            );
    }
};

const Menu: React.FC<{ close?: () => void; iframe?: boolean; closed?: boolean; user?: any }> = ({
    close,
    closed,
    iframe,
    user,
}) => {
    const { pages, sections } = pageRefiner();
    const navigate = useNavigate();
    const [formModal, setFormModal] = useState<boolean>(false);
    const [auditLog, setAuditLog] = useState(false);
    const { irs_account_id } = useParams();
    const completePercentage = useCompletedPercentage(irs_account_id);

    const { success: isFormEnabled } = useFeatureEnabled('Form');
    return (
        <div
            className={'relative flex flex-col flex-grow'}
            style={{
                maxWidth: 300,
                minWidth: 300,
                backgroundImage: 'url(/menu_pattern.png)',
                backgroundSize: '400px 400px',
            }}
        >
            {/* Background screen with opacity to manage visibility of the background */}
            <div className="absolute h-full w-full bg-gray-100 top-0 left-0 opacity-80"></div>

            {/* Menu Section cards */}

            <div className={'flex flex-col sticky top-10 z-10 h-screen overflow-y-auto py-2'}>
                <div className="w-full mt-1 px-2 mb-2">
                    <div className="bg-white py-3 px-2 flex">
                        {!closed && (
                            <span
                                onClick={() => navigate('/client')}
                                className="material-icons cursor-pointer hover:bg-gray-100 h-8 w-8 text-black-400 items-center"
                            >
                                arrow_back
                            </span>
                        )}
                        <div className="flex-grow">
                            <div className="w-full flex mb-1">
                                <div className="flex-grow font-title text-sm">{`Completed`}</div>
                                <div className="font-title text-sm">{`${completePercentage}%`}</div>
                            </div>
                            <div className=" w-full relative">
                                <div className="h-2 bg-gray-200 w-full" />
                                <div
                                    className="absolute left-0 top-0 h-2 bg-primary-500"
                                    style={{ width: `${completePercentage}%` }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isFormEnabled && build.tp_audit_log ? (
                    <div className="cursor-pointer font-title  text-white my-2 text-sm ml-2 flex items-center justify-around">
                        <span
                            onClick={() => setFormModal(true)}
                            className=" rounded-full bg-primary-400 px-3 py-1"
                        >{`Generate Forms`}</span>
                        {build.tp_audit_log && (
                            <span
                                onClick={() => setAuditLog(true)}
                                className=" rounded-full bg-primary-400 px-4 py-1"
                            >{`Change Log`}</span>
                        )}
                    </div>
                ) : (
                    <div className=" py-1 px-2 h-10">
                        <div className="w-full h-8 cursor-pointer hover:bg-primary-700 bg-primary-500 flex justify-center items-center">
                            <div
                                onClick={() => setFormModal(true)}
                                className="font-title text-sm font-medium text-white"
                            >
                                GENERATE FORM
                            </div>
                        </div>
                    </div>
                )}
                {sections.map((section, index) => {
                    return <SectionComponent key={index} section={section} />;
                })}
            </div>

            <Modal close={() => setFormModal(false)} isOpen={formModal}>
                <GenerateFormModal user={user} close={() => setFormModal(false)} />
            </Modal>
            <Modal close={() => setAuditLog(false)} isOpen={!!auditLog}>
                <AuditLogTaxRes close={() => setAuditLog(false)} irs_id={irs_account_id!} />
            </Modal>
        </div>
    );
};

export default Menu;
