export const user_dashboard = {
    Profile: 'Profile',
    Transcripts: 'Transcripts',
    Documents: 'Documents',
    Forms: 'Forms',
    Billing: 'Billing',
    Tasks: 'Tasks',
    cancel: 'cancel',
    Edit: 'Edit',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    Email: 'Email',
    'Date of Birth': 'Date of Birth',
    Phone: 'Phone',
    Fax: 'Fax',
    Status: 'Status',
    'Assigned to': 'Assigned to',
    'Street Name': 'Street Name',
    'Apartment/Unit No.': 'Apartment/Unit No.',
    City: 'City',
    State: 'State',
    'Zip Code': 'Zip Code',
    Country: 'Country',
    'Driver License': 'Driver License',
    'Service type': 'Service type',
    'Save Updates': 'Save Updates',
    'Taxpayer Information': 'Taxpayer Information',
};
