import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { build } from '../../config';
import { useFormState } from '../../context/form';
import { enumSections } from '../../utils/enums';
import { pageRefiner, useLightHouse } from '../../utils/helpers';
import { PageInterface, SectionInterface } from '../../utils/interfaces';

const LineSection: React.FC<{ isSelected: boolean; isCurrent: boolean; index: number | null }> = ({
    isSelected,
    isCurrent,
    index,
}) => {
    return (
        <div className="flex items-center justify-center relative h-full w-12">
            {isSelected ? (
                <img
                    src={build.checkMarkLogo}
                    className={classNames('h-5 w-5 z-20 bg-white rounded-3xl   ')}
                    style={{ padding: 1 }}
                />
            ) : (
                <div
                    className={classNames(
                        'h-5 w-5 rounded-2xl  z-20 flex items-center justify-center',
                        {
                            'bg-primary-500': isSelected && !isCurrent,
                            'bg-gray-700 ': isCurrent,
                            'bg-gray-300': !isSelected,
                        },
                    )}
                >
                    {index !== null && (
                        <div className={'text-sm text-center font-semibold text-white'}>
                            {typeof index === 'number' ? index + 1 : index}
                        </div>
                    )}
                </div>
            )}
            {/* <div
                className={classNames('absolute w-1 top-0 h-1/2 z-10', {
                    'bg-gray-800': present || past,
                    'bg-gray-400': future || start,
                })}
                style={{ left: 22 }}
            />
            <div
                className={classNames('absolute w-1 top-1/2 h-1/2 z-10', {
                    'bg-gray-800': past,
                    'bg-gray-400': present || future || start,
                })}
                style={{ left: 22 }}
            /> */}

            {/* Section to render if current page is selected */}
            {isCurrent && <div className="absolute h-5 w-1/2 right-0 bg-gray-700 z-10" />}
        </div>
    );
};

const PageProducer: React.FC<{
    currentPage: PageInterface;
    index: number;
    link: string;
    isSelected: boolean;
    isCurrent: boolean;
}> = ({ link, currentPage, index, isSelected, isCurrent }) => {
    return (
        <Link to={link} className="w-full">
            <div
                className={classNames('flex items-center ', {
                    'h-9 bg-gray-50': !isCurrent,
                    'h-12 bg-primary-500': isCurrent,
                    // 'text-gray-400': future || start,
                })}
            >
                <div
                    className={classNames('flex-grow text-right font-body text-sm', {
                        'font-semibold ': !isSelected && !isCurrent,
                        'font-semibold text-gray-300': isSelected && !isCurrent,
                        'text-gray-50': isCurrent,
                    })}
                >
                    {currentPage.name}
                </div>

                <LineSection {...{ index, isSelected, isCurrent }} />
            </div>
        </Link>
    );
};

const BusinessSection: React.FC<{
    key: number;
    business: any;
    subSections: SectionInterface['subSections'];
}> = ({ business, subSections }) => {
    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    const { businessId, currentPage } = useLightHouse();
    const { meta_data } = useFormState();

    return (
        <>
            <Link
                to={
                    iframe
                        ? `/iframe-form/basic_information/${business.id}`
                        : `/form/basic_information/${business.id}`
                }
                className="w-full"
            >
                <div
                    className={classNames('flex items-center bg-gray-50 h-9', {
                        'h-12 bg-gray-500 text-gray-50': business.id === businessId,
                        // 'text-gray-400': future || start,
                    })}
                >
                    <div
                        className={classNames('flex-grow text-right font-body text-sm', {
                            // 'font-semibold': present,
                        })}
                    >
                        {business.name}
                    </div>

                    <LineSection
                        {...{
                            index: null,
                            isSelected: false,
                            isCurrent: businessId === business.id,
                        }}
                    />
                </div>
            </Link>

            {business.id === businessId &&
                subSections
                    .filter(({ address }) => address !== 'business_home')
                    .map((subSection, index) => {
                        const isSelected = businessId
                            ? meta_data?.page_json[businessId]
                                ? meta_data?.page_json[businessId][subSection.address]
                                : false
                            : false;

                        return (
                            <PageProducer
                                index={index}
                                currentPage={subSection}
                                link={
                                    iframe
                                        ? `/iframe-form/${subSection.address}/${business.id}`
                                        : `/form/${subSection.address}/${business.id}`
                                }
                                isCurrent={currentPage.address === subSection.address}
                                isSelected={isSelected}
                            />
                        );
                    })}
        </>
    );
};

const SectionComponent: React.FC<{ key: number; section: SectionInterface }> = ({
    key,
    section,
}) => {
    const { currentPage, currentSection } = useLightHouse();
    const { business_data, meta_data } = useFormState();

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    const isCurrent = currentSection.id === section.id;

    const Section: React.FC = () => {
        return (
            <Link
                to={{
                    pathname: iframe ? `/iframe-section/${section.id}` : `/section/${section.id}`,
                }}
            >
                <div
                    className={classNames('flex items-center h-12 bg-white', {
                        'bg-gray-200 h-20 ': isCurrent,
                    })}
                >
                    <div
                        className={classNames('flex-grow text-right font-title text-sm', {
                            'font-medium': isCurrent,
                            'font-semibold ': isCurrent,
                        })}
                    >
                        {section.name}
                    </div>
                    <LineSection {...{ index: null, isSelected: false, isCurrent }} />
                </div>
            </Link>
        );
    };

    switch (section.id) {
        case enumSections.BUSINESS_INFORMATION:
            return (
                <div key={key} className="w-full">
                    <Section />
                    {currentSection.id === enumSections.BUSINESS_INFORMATION &&
                        business_data.map((business: any, index: number) => {
                            return (
                                <BusinessSection
                                    key={index}
                                    subSections={section.subSections}
                                    business={business}
                                />
                            );
                        })}
                </div>
            );

        default:
            return (
                <div key={key} className="w-full">
                    <Section />
                    {currentSection.id === section.id &&
                        section.subSections.map((subSection, index) => {
                            const isPageSelected = meta_data.page_json[subSection.address];

                            return (
                                <PageProducer
                                    link={
                                        iframe
                                            ? `/iframe-form/${subSection.address}`
                                            : `/form/${subSection.address}`
                                    }
                                    index={index}
                                    currentPage={subSection}
                                    isCurrent={currentPage.address === subSection.address}
                                    isSelected={isPageSelected}
                                />
                            );
                        })}
                </div>
            );
    }
};

const Menu: React.FC<{ close?: () => void }> = ({ close }) => {
    const { pages, sections } = pageRefiner();

    return (
        <div
            className="relative py- flex flex-col flex-grow"
            style={{
                maxWidth: 300,
                minWidth: 300,
                backgroundImage: 'url(/menu_pattern.png)',
                backgroundSize: '400px 400px',
            }}
        >
            <div className="z-30 h-10 w-full flex justify-start items-center">
                {close && (
                    <span onClick={close} className="material-symbols-outlined text-black ml-9">
                        menu
                    </span>
                )}
            </div>
            {/* Background screen with opacity to manage visibility of the background */}
            <div className="absolute h-full w-full bg-gray-100 top-0 left-0 z-10 opacity-80"></div>

            {/* Menu Section cards */}

            <div className="flex flex-col mt-5 sticky top-20 z-20">
                {sections.map((section, index) => {
                    return <SectionComponent key={index} section={section} />;
                })}
            </div>
        </div>
    );
};

export default Menu;
