import axios from 'axios';
import { COOKIE, enumSections } from '../utils/enums';
import { fetchClientWithBusiness } from './business';
import { fetchClientWithFinance } from './finance';
import { fetchClientWithDependent } from './yourInfo';
import { build } from '../config';

// export const CLIENT = 'd9b18bfe-0d65-451b-9256-b53aa2c461c9';
// export const CLIENT = '015ce098-cf0f-480f-affd-4a5e89890c8c';
// export const CLIENT = '15ce629c-5d62-4665-b7ee-935b9d39cad7';
// export const CLIENT = '4bce0aa0-1fab-4256-89a2-c0684260b682';
// export const CLIENT = '369ba4a6-3974-4edc-88b0-82735aa03860';
// export const CLIENT = '48f85033-e946-4552-845d-c31aa9f507b2';

export const api = axios.create({
    baseURL: build.baseURL,
    // baseURL: 'https://api-tax-uat.mycase.tax/api',
    // headers: { Authorization: `Bearer ${localStorage.getItem(COOKIE)}` },
    // transformRequest: [
    //     (data, headers) => {
    //         // You may modify the headers object here
    //         if (headers) {
    //             headers['Authorization'] = `Bearer ${localStorage.getItem(COOKIE)}`;
    //         }
    //         // Do not change data
    //         let formData = new FormData();
    //         Object.keys(data).forEach((attr) => {
    //             formData.append(attr, data[attr]);
    //         });
    //         return formData;
    //     },
    // ],
});

export const getfetchFunction = (sectionId: enumSections) => {
    switch (sectionId) {
        case enumSections.YOUR_INFORMATION:
            return fetchClientWithDependent;

        case enumSections.FINANCIAL_INFORMATION:
            return fetchClientWithFinance;

        case enumSections.BUSINESS_INFORMATION:
            return fetchClientWithBusiness;

        case enumSections.OTHER_INFORMATION:
            return fetchClientWithFinance;

        default:
            return async () => null;
    }
};

export const login = async (credentials: any) => {
    return api
        .post('/login', credentials)
        .then((response) => {

            if (response.data.success) return response.data;
            else {
                throw new Error('invalid credentials');
            }
        })
        .catch((e) => {
            console.error('API_AUTH: login error', e);
        });
};



export const createClient = async (params: any) => {
    return api.post('/createClient', params).then((response) => {
        return response.data;
    });
};
