import React, { useEffect, useState } from 'react';
import ButtonOutlined from './components/ButtonOutlined';
import { useParams } from 'react-router-dom';
import useFormsList from '../../../api/tax-prep/useFormsList';
import TextField from './components/TextField';
import { fieldTypes } from '../../../utils/enums';
import Dropdown from '../../client/Dropdown';
import InputGroup from '../../client/InputGroup';
import DateTimePicker from '../../../components/client/Calendar';
import PrimaryUserProfile from './components/PrimaryUserProfile';
import SpouseProfile from './components/SpouseProfile';
import { useTranslation } from 'react-i18next';

const clientAuth = [
    {
        AuthForm: '821',
        signed: '05-03-2024',
        taxpros: 'Jenny Willson, John Smith, Robert Fox, Jacob Jones, Jonnathan Smith',
    },
];

const ClientAuthorization: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <p className="text-xl text-black font-semibold mt-4">{t('Client Authorizations')}</p>
            {true ? (
                <div className="mx-8 bg-gray-100 mt-6 mb-8 border borprimarygray-300  flex flex-col text-center justify-center py-10">
                    <p className="text-lg font-semibold pb-1">{t('No authorizations yet!')}</p>
                    <p className="pt-2 text-sm text-gray-500">
                        {t('authorizations data missing: please add authorization')}
                        <br /> {t('using the button below.')}
                    </p>
                    <ButtonOutlined customClass="text-primary-500 mt-3 mb-1">
                        {' '}
                        <span
                            style={{
                                fontSize: '20px',
                                borderColor: '#222222',
                            }}
                            className="material-symbols-outlined bg-primary-500 text-white"
                        >
                            add
                        </span>
                        {t('New Authorization')}
                    </ButtonOutlined>
                </div>
            ) : (
                <div className="mx-8 grid grid-flow-row grid-cols-3 gap-3">
                    <div className="border border-gray-600 rounded-r-md">hey</div>
                </div>
            )}
        </>
    );
};
const getTabNames = (maritalStatus: any, data: any) => {
    if (
        maritalStatus === 'jointly' ||
        maritalStatus === null ||
        maritalStatus === undefined ||
        maritalStatus === '' ||
        maritalStatus?.value === 'jointly'
    ) {
        return [
            `${data?.primary_user?.u_first_name} ${data?.primary_user?.u_last_name}`,
            data?.spouse?.spouse_first_name
                ? `${data?.spouse?.spouse_first_name} ${data?.spouse?.spouse_last_name}`
                : 'Spouse Information',
        ];
    } else {
        return [`${data?.primary_user?.u_first_name} ${data?.primary_user?.u_last_name}`];
    }
};

const UserProfile = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { irs_account_id } = useParams();
    const [tabNames, setTabNames] = useState<any>();
    const { isLoading, data } = useFormsList({
        irs_account_id: irs_account_id,
    });
    const [maritalStatus, setMaritalStatus] = useState(data?.primary_user?.marital_status);
    console.log('client data', data);

    useEffect(() => {
        setTabNames(getTabNames(maritalStatus, data));
    }, [maritalStatus, data]);
    const renderTabContent = () => {
        switch (activeTab) {
            case 0:
                return (
                    <PrimaryUserProfile
                        isLoading={isLoading}
                        data={data?.primary_user}
                        maritalStatus={maritalStatus}
                        setMaritalStatus={setMaritalStatus}
                        taxPrepYears={data?.u_taxprep_years}
                    />
                );
            case 1:
                return (
                    <SpouseProfile
                        isLoading={isLoading}
                        data={data?.spouse}
                        user_id={data?.primary_user?.u_id}
                        taxPrepYears={data?.u_taxprep_years}
                    />
                );
            case 2:
                return <div>hello</div>;
            case 3:
                return <div>hello</div>;
            default:
                return null;
        }
    };

    if (isLoading) {
        return (
            <div className="h-full w-full flex justify-center items-center">
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <div className="flex flex-col m-6 bg-white shadow-md ">
            <div className="flex mb-2">
                {tabNames?.map((tabName: any, index: any) => (
                    <button
                        key={index}
                        style={{ minWidth: '12rem' }}
                        className={`py-2.5 text-center ${
                            activeTab === index ? 'border-b-2 border-primary-500' : 'bg-transparent'
                        }`}
                        onClick={() => setActiveTab(index)}
                    >
                        {tabName}
                    </button>
                ))}
            </div>
            <div className="p-5 flex flex-col">
                {renderTabContent()}
                <ClientAuthorization />
            </div>
        </div>
    );
};

export default UserProfile;
