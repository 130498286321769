import { useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from '..';
import { useAuthState } from '../../context/auth';

const paramString = (key: string, values: Array<any>): string => {
    let str: string = ``;

    if (values.length === 0) return str;

    values.forEach((value) => {
        if (value)
            if (!str) str = `${key}=${value}`;
            else str = str + `&${key}=${value}`;
    });

    return str;
};

const useTaxPrep = () => {
    const [errors, setErrors] = useState<any>({});
    const { token, u_email, user_id } = useAuthState();
    const [filterParams, setFilterParams] = useState<{ [key: string]: any }>({ offset: 0 });
    const [filterString, setFilterString] = useState<string>();
    const [filterLoginString, setFilterLoginString] = useState<string>('');
    const getTaxPreparer = async ({
        queryKey: [_, filterString],
    }: QueryFunctionContext): Promise<any> => {
        const { data } = await api.get(
            `/getAllTaxPreparer?${
                filterString ? filterString : 'sort_by=u_first_name&order_by=ASC'
            }${getFilteredLoginString(filterLoginString)}&u_id=${user_id}`,
        );
        return data;
    };

    const getFilteredLoginString = (filterLoginString: string) => {
        if (!filterLoginString) {
            return '';
        }
        return `&interval=${filterLoginString}`;
    };

    const { data: taxPreparersData, isLoading } = useQuery({
        queryKey: ['taxprep-list', filterString, filterLoginString],
        queryFn: getTaxPreparer,
        enabled: !!token,
        onError: (error: any) => setErrors(error.response.data),
    });

    //query to export all tax preparers data in the csv format
    const getTaxPreparerExportList = async ({
        queryKey: [_, filterString],
    }: QueryFunctionContext): Promise<any> => {
        const { data } = await api.get(`/getAllTaxPreparer?offset=ALL`);
        return data;
    };

    const { isFetching: exportLoading, refetch: handleTaxPreparerExportList } = useQuery({
        queryKey: ['taxprep-list-export'],
        queryFn: getTaxPreparerExportList,
        enabled: false,
        onError: (error: any) => setErrors(error.response.data),
    });

    useEffect(() => {
        let arrParams = ``,
            strFilters = {};

        Object.keys(filterParams).map((key) => {
            if (Array.isArray(filterParams[key])) {
                if (arrParams === ``) {
                    arrParams = paramString(key, filterParams[key]);
                } else {
                    arrParams += '&' + paramString(key, filterParams[key]);
                }
            } else {
                if (filterParams[key]) {
                    strFilters = { ...strFilters, [key]: filterParams[key] };
                }
            }
        });

        const strParams = new URLSearchParams({ ...strFilters });
        const queryParams = strParams + (arrParams !== `` ? '&' + arrParams : ``);

        setFilterString(queryParams);
    }, [filterParams]);

    return {
        isLoading,
        total: taxPreparersData?.total,
        taxPreparers: taxPreparersData?.data,
        filterParams,
        setFilterParams,
        setFilterString,
        setFilterLoginString,
        exportLoading,
        handleTaxPreparerExportList,
    };
};

export default useTaxPrep;
