import { upsertInvestments } from '../../api/finance';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const investmentAccountsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Bank Name',
        placeholder: 'Chase Bank',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_phone',
        label: 'Company Phone',
        placeholder: 'Chase Bank',
        formatFunction: phone,
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'type_of_investment',
        label: 'Type',
        options: [
            { value: '401K', label: '401K' },
            { value: 'IRA', label: 'IRA' },
            { value: 'Other Retirement', label: 'Other Retirement' },
            { value: 'Stocks', label: 'Stocks' },
            { value: 'Bonds', label: 'Bonds' },
            { value: 'Other Investment', label: 'Other Investment' },
        ],
        placeholder: 'Stated',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        id: 'company_address',
        label: 'Address',
        placeholder: 'Chase Bank Office, 270 Park Avenue, New York, NY',
        span: 2,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Account Number',
        placeholder: '$20,000',
    },
    // {
    //     type: fieldTypes.AMOUNT_INPUT,
    //     id: 'monthly_payment',
    //     label: 'Monthly Payment',
    //     placeholder: '$20,000',
    // },
    // {
    //     type: fieldTypes.CALENDAR,
    //     id: 'expiration_date',
    //     label: 'Expiration Date',
    //     placeholder: '23 March 2025',
    // },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Value',
        placeholder: '$20,000',
    },
    { type: fieldTypes.SEPERATOR },

    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'loan_balance',
        label: 'Loan Balance',
        placeholder: '23 March 2025',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'Quick Sale Value',
        placeholder: '$20,000',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '0.8',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'investment_data',
        label: 'Do you have any Investment Accounts?',
        header: 'Investment Accounts Details',
        formStructure: investmentAccountsForm,
        displayColumns: [
            {
                name: 'Bank Name',
                key: 'company_name',
            },
            {
                name: 'Account Number',
                key: 'account_number',
            },
            {
                name: 'Value',
                key: 'current_value',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'investment_accounts',
    name: 'Investment Accounts',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertInvestments,
};
