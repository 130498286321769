import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { interface_field_textInput } from '../../utils/interfaces';
import ParentGroup from './ParentGroup';

/**
 * UI Component for Text Input and Text Area
 **/

type InputGroupProps = interface_field_textInput & {
    className?: string;
    inputType?: string;
    value: string | number | undefined;
    error?: string;
    staticError?: string;
    disabled?: boolean;
    setValue: (str: any) => void;
    onEnter?: (str: any) => void;
};

const InputGroup: React.FC<InputGroupProps> = (props) => {
    const {
        id,
        placeholder,
        value,
        error,
        setValue,
        label,
        className,
        inputType: type = 'text',
        area,
        span,
        formatFunction,
        staticError,
        onEnter,
        disabled,
    } = props;

    const [inputValue, setInputValue] = useState<string | number | undefined>(undefined);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (value !== inputValue) setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (!isFocused && value !== inputValue && inputValue !== undefined) {
            setValue(inputValue);
        }
    }, [inputValue]);

    const handleChange = (e: any) => {
        let newValue = e.target.value;

        if (formatFunction) {
            newValue = formatFunction(newValue);
        }

        setInputValue(newValue);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter(inputValue);
        }
    };

    return (
        <ParentGroup
            id={id}
            className={className}
            label={label}
            value={inputValue || ''}
            error={error}
            style={{ gridColumn: `span ${span}` }}
            staticError={staticError}
        >
            {area ? (
                <textarea
                    disabled={disabled}
                    className={classNames(
                        'focus:outline-none focus:border-primary-500 w-full h-16 border-b border-gray-200  px-3 pb-1 pt-5',
                        {
                            'hover:bg-gray-50 focus:bg-gray-100': !disabled,
                            'bg-gray-200': disabled,
                        },
                    )}
                    style={{ minHeight: 64 }}
                    value={inputValue || ''}
                    onChange={handleChange}
                    rows={4}
                    onFocus={() => setIsFocused(true)}
                    onBlur={(e) => {
                        setIsFocused(false);
                        setValue(e.target.value);
                    }}
                />
            ) : (
                <input
                    disabled={disabled}
                    id={id + '_input'}
                    key={id + '_input'}
                    className={classNames(
                        'focus:outline-none focus:border-primary-500 w-full h-16 border-b border-gray-200 px-3 pb-1 pt-5',
                        {
                            'hover:bg-gray-50 focus:bg-gray-100': !disabled,
                            'bg-gray-200': disabled,
                        },
                    )}
                    type={type}
                    value={inputValue || ''}
                    onChange={handleChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={(e) => {
                        setIsFocused(false);
                        setValue(e.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                />
            )}
        </ParentGroup>
    );
};

export default InputGroup;
