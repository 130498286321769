/**
 * Parent UI Component for wrapping fields into
 **/

import classNames from 'classnames';
import { useEffect, useState } from 'react';
// import { useFormState } from '../../context/form';

interface ParentGroupProps {
    className?: string;
    style?: any;
    staticError?: string;
    value?: any;
    error?: string;
}
interface ParentGroupWithLabelProps {
    className?: string;
    id: string;
    label: string;
    value: any;
    error?: string;
    style?: any;
    staticError?: string;
}

function isLabel(
    field: ParentGroupProps | ParentGroupWithLabelProps,
): field is ParentGroupWithLabelProps {
    return (field as ParentGroupWithLabelProps).id !== undefined;
}

const ParentGroup: React.FC<ParentGroupWithLabelProps | ParentGroupProps> = (props) => {
    const [isFocus, setFocus] = useState<boolean>(false);

    const isValueFieldEmpty = [undefined, null, ''].includes(props?.value);
    const [isEmpty, setIsEmpty] = useState(isValueFieldEmpty);

    useEffect(() => {
        if (isEmpty !== isValueFieldEmpty) {
            setIsEmpty(isValueFieldEmpty);
        }
    }, [props?.value]);

    if (isLabel(props)) {
        const { id, children, className, label, value, error, staticError } = props;

        const displayError = error || staticError?.[0];

        return (
            <div
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={classNames('m-1 relative flex-col', className)}
                style={props.style}
            >
                {children}
                {displayError && (
                    <div className="text-red-600 px-3 text-xs font-bold bg-red-100">
                        {displayError}
                    </div>
                )}

                <div
                    className={classNames('absolute pointer-events-none font-semibold', {
                        ' top-8 text-custom-green-300 left-3':
                            isEmpty && !isFocus && (label?.length || 0) > 25,
                        'top-8 text-custom-green-300 left-3':
                            isEmpty && !isFocus && (label?.length || 0) <= 25,
                        'text-xs top-2 left-3 text-custom-green-300 ': !isEmpty || isFocus,
                    })}
                >
                    {label}
                </div>
            </div>
        );
    } else {
        const { children, className, error, staticError } = props;
        const displayError = error || staticError?.[0];

        return (
            <div className={classNames('m-1 relative flex-col', className)} style={props.style}>
                {children}
                {displayError && (
                    <div className="text-red-600 px-3 text-xs font-bold bg-red-100">
                        {displayError}
                    </div>
                )}
            </div>
        );
    }
};

export default ParentGroup;
