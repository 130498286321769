import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

interface ButtonSmallProps {
    link?: any;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement> | undefined;
    secondary?: boolean;
    textRight?: boolean;
    className?: string;
    isLoading?: boolean;
}

const LinkWrapper: React.FC<{ link?: any; className?: string }> = ({
    link,
    children,
    className,
}) => {
    if (link !== undefined)
        return (
            <Link className={className} to={link}>
                {children}
            </Link>
        );
    else return <div className={className}>{children}</div>;
};

const ButtonSmall: React.FC<ButtonSmallProps> = ({
    children,
    link,
    disabled,
    onClick,
    secondary,
    className,
    isLoading,
}) => {
    return (
        <LinkWrapper className={className} link={disabled ? undefined : link}>
            <div
                onClick={disabled || isLoading ? undefined : onClick}
                className={classNames('cursor-pointer border px-2 py-2 font-title text-xs', {
                    'bg-gray-300 text-white text-right font-semibold': disabled && secondary,
                    'bg-gray-300 text-white font-medium': disabled && !secondary,
                    'hover:bg-gray-200 font-semibold border-gray-200 text-primary-500 text-right':
                        !disabled && secondary,
                    'hover:bg-primary-600 font-medium text-white bg-primary-500 border-primary-500':
                        !disabled && !secondary,
                })}
                style={{ flexBasis: 0 }}
            >
                {isLoading ? (
                    <span className="flex font-title flex-grow items-center ">
                        <span
                            className="material-icons animate-spin h-5 w-5 flex items-center justify-center"
                            style={{ fontSize: 20 }}
                        >
                            refresh
                        </span>
                        <div className="ml-3">Loading</div>
                    </span>
                ) : (
                    <div>{children}</div>
                )}
            </div>
        </LinkWrapper>
    );
};

export default ButtonSmall;
