import { upsertLifeInsurance } from '../../api/finance';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const lifeInsuranceForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Company Name',
        placeholder: 'Maxx Life Insurance',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'policy_number',
        label: 'Policy Number',
        placeholder: '1123A547EER',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        span: 2,
        id: 'company_address',
        label: 'Company Address',
        placeholder: '270 Park Avenue, New York, NY',
    },
    { type: fieldTypes.SEPERATOR },

    {
        type: fieldTypes.TEXT_INPUT,
        id: 'owner_of_policy',
        label: 'Owner',
        placeholder: 'Metamask',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'insurance_value',
        label: 'Value',
        placeholder: '0.4',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'outstanding_loan_balance',
        label: 'Outstanding Loan Amount',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'insurance_data',
        label: 'Do you have any life insurance?',
        formStructure: lifeInsuranceForm,
        header: 'Life Insurance Details',
        displayColumns: [
            {
                name: 'Bank Name',
                key: 'company_name',
            },
            {
                name: 'Policy Number',
                key: 'policy_number',
            },
            {
                name: 'Value',
                key: 'insurance_value',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'life_insurance',
    name: 'Life Insurance with cash value',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertLifeInsurance,
};
