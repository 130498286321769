import Select from 'react-select';
import { interface_field_dropdown } from '../../utils/interfaces';
import ParentGroup from './ParentGroup';
import { themes } from '../../config/themes';
import { build } from '../../config';
import useTaxPrepClientData from '../taxprep/useTaxPrepClientData';

type DropdownGroupProps = interface_field_dropdown & {
    className?: string;
    value: any;
    error?: string;
    staticError?: string;
    disabled?: boolean;
    setValue: (str: any) => void;
    formData: any;
};

const Dropdown: React.FC<DropdownGroupProps> = (props) => {
    const {
        id,
        value,
        error,
        setValue,
        label,
        className,
        options,
        isMulti = false,
        span,
        staticError,
        disabled,
        formData,
    } = props;

    const customStyles = {
        container: (provided: any, state: any) => {
            return {
                /** main container */
                ...provided,
                padding: 0,
                margin: 0,
                // bold: options.bold,
                width: '100%',
                outlineColor: '#a00',
                '&:hover': {
                    backgroundColor: 'rgba(249, 250, 251, 1)',
                },
            };
        },

        option: (provided: any, state: any) => ({
            /** dropdown section containing all the options */
            ...provided,
            '&:hover': {
                backgroundColor: !state.isSelected
                    ? themes[build.theme]['primary-100']
                    : themes[build.theme]['primary-500'],
            },
            backgroundColor: state.isSelected ? themes[build.theme]['primary-500'] : '#fff',
            paddingTop: 2,
            paddingBottom: 2,
            paddingRight: 6,
            paddingLeft: 6,
            outlineColor: '#a00',
            '&:focus': {
                outlineColor: '#a00',
            },
        }),

        input: (provided: any, state: any) => {
            /** section that takes text input */
            return {
                ...provided,
                padding: 0,
                margin: 0,
            };
        },

        valueContainer: (provided: any, state: any) => {
            return {
                /** Section showing current value */
                ...provided,
                padding: 0,
                paddingTop: 0,
                margin: 0,
                outlineColor: '#a00',
                '&:focus': {
                    outlineColor: '#a00',
                },
            };
        },

        control: (provided: any, state: any) => {
            return {
                /** container that has input value and container showing current value */
                ...provided,
                borderRadius: 0,
                // backgroundColor: 'rgba(243, 244, 246, 1)',
                borderColor: state.isFocused
                    ? themes[build.theme]['primary-500']
                    : 'rgba(229, 231, 235, 1)',
                backgroundColor: state.isFocused ? 'rgba(249, 250, 251, 1)' : '#fff',
                borderStyle: 'solid',
                borderWidth: 0,
                borderBottomWidth: 1,
                minHeight: 0,
                boxShadow: 'none',

                '&:hover': {
                    // borderColor: 'rgba(209, 213, 219, 1)',
                    // borderWidth: 1,
                    backgroundColor: 'rgba(249, 250, 251, 1)',
                },

                outlineColor: '#a00',
            };
        },

        dropdownIndicator: (provided: any, state: any) => ({
            /** Arrow section on the right */
            ...provided,
            padding: 0,
            paddingRight: 0,
            paddingTop: 0,
        }),

        indicatorSeparator: (provided: any, state: any) => ({
            /** seperator between the conrol and the dropdown indicator */
            ...provided,
            width: 0,
        }),

        menu: (provided: any, state: any) => ({
            /** Container that has all the dropdown options */
            ...provided,
            borderRadius: 0,
        }),

        placeholder: (provided: any, state: any) => ({
            /** Container that has all the dropdown options */
            ...provided,
            color: '#9CA3AF',
        }),

        multiValue: (provided: any, state: any) => ({
            /** Container that has all the dropdown options */
            ...provided,
            backgroundColor: themes[build.theme]['primary-500'],
        }),

        multiValueLabel: (provided: any, state: any) => ({
            /** Container that has all the dropdown options */
            ...provided,
            color: '#fff',
        }),

        clearIndicator: (provided: any, state: any) => ({
            /** Container that has all the dropdown options */
            ...provided,
            padding: 0,
        }),
    };

    const resolveValue = (data: any) => {
        if (Array.isArray(data)) {
            const newValues = data.map((item) => {
                return item.value;
            });

            setValue(newValues);
        } else {
            setValue(data?.value);
        }
    };

    let currentValue;
    if (Array.isArray(value)) {
        currentValue = options.filter((o) => o.value === value.find((v) => v === o.value));
    } else currentValue = options.find((o) => o.value === value);

    let filteredOptions: any = options;
    if (id === 'county') {
        if (formData?.['state_cd']) {
            filteredOptions = options.filter(({ state }: any) => {
                if (formData['state_cd'] === state) return true;
                else return false;
            });
        } else {
            filteredOptions = [{ label: 'Select State First', value: undefined, isdisabled: true }];
        }
    }

    return (
        <ParentGroup
            id={id}
            className={className}
            label={label}
            value={value}
            error={error}
            style={{ gridColumn: `span ${span}` }}
            staticError={staticError}
        >
            <Select
                isDisabled={disabled}
                isClearable={true}
                isMulti={isMulti}
                styles={customStyles}
                options={filteredOptions}
                placeholder={''}
                defaultValue={value}
                value={currentValue}
                onChange={resolveValue}
                isOptionDisabled={(option: any) => option.isdisabled}
            />
        </ParentGroup>
    );
};

/**
 *  React-Select Stylings
 **/

export default Dropdown;
