import { updateClient } from '../../api/yourInfo';
import { fieldTypes, enumSections } from '../../utils/enums';
import { phone, ssn } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const spouseForm: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Spouse Details' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_first_name',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_last_name',
        label: 'Last Name',
        placeholder: 'Doe',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.CALENDAR,
        id: 'spouse_birth_dt',
        label: 'Date of Birth',
        placeholder: '10 August 1998',
        restrict: 'Future',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'date_of_marriage',
        label: 'Marriage date',
        placeholder: '10 August 1998',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_email',
        label: 'E mail',
        placeholder: 'johndoe@email.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_cell_phone',
        label: 'Phone',
        placeholder: '(555) 555-1234',
        formatFunction: phone,
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_drivers_license_number',
        label: 'DL',
        placeholder: 'F255-921-50-094-0',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'spouse_ssn',
        label: 'SSN',
        placeholder: '778-62-8144',
        formatFunction: ssn,
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'liable',
        label: 'Liable Party',
        placeholder: '',
        options: [
            // { label: 'Both', value: 'B' },
            // { label: 'Primary Client', value: 'C' },
            // { label: 'Spouse', value: 'S' },
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
    },

    { type: fieldTypes.TERMINAL },
];

const form: FormInterface = [
    {
        type: fieldTypes.RADIO,
        crossroadVariant: true,
        id: 'marital_status',
        label: 'Filing Status',
        options: [
            { label: 'Single', value: 'single' },
            { label: 'Married, Filing Jointly', value: 'jointly' },
            { label: 'Married, Filing Separately', value: 'seperately' },
            { label: 'Head of Household', value: 'hoh' },
            { label: 'Qualifying Widower', value: 'qw' },
        ],
        span: 2,
        branches: {
            paths: [
                {
                    value: 'single',
                    form: [{ type: fieldTypes.TERMINAL, noAction: true }],
                },
                {
                    value: 'jointly',
                    form: spouseForm,
                },
                {
                    value: 'seperately',
                    form: [{ type: fieldTypes.TERMINAL, noAction: true }],
                },
                {
                    value: 'hoh',
                    form: spouseForm,
                },
                {
                    value: 'qw',
                    form: [{ type: fieldTypes.TERMINAL, noAction: true }],
                },
            ],
        },
    },
];

export const page: PageInputInterface = {
    address: 'filing_status',
    name: 'Filing Status',

    sectionId: enumSections.YOUR_INFORMATION,
    formStructure: form,

    save: updateClient,
};
