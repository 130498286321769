import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { ssn } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertDependents = async (year: string, params: any) => {
    const { dependent_data, meta_data, irs_account_id, id } = params;
    const regEx = /^new/;
    const data = dependent_data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: irs_account_id,
                    taxprep_account_id: id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return await apiTaxPrep.post(`/dependentByActionTP`, {
        irs_account_id: irs_account_id,
        meta_data: { ...meta_data, taxprep_account_id: id, irs_account_id: irs_account_id },
        data,
        taxprep_year: year,
    });
};

const dependentsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'first_name',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'last_name',
        label: 'Last Name',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'relationship',
        label: 'Relationship',
        placeholder: 'Son',
        options: [
            { label: 'Son', value: 'Son' },
            { label: 'Daughter', value: 'Daughter' },
            { label: 'Parent', value: 'Parent' },
            { label: 'Grandparent', value: 'Grandparent' },
            { label: 'Stepson', value: 'Stepson' },
            { label: 'StepDaughter', value: 'StepDaughter' },
            { label: 'Niece', value: 'Niece' },
            { label: 'Nephew', value: 'Nephew' },
            { label: 'Other', value: 'Other' },
        ],
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'birth_dt',
        label: 'Date of Birth',
        placeholder: '',
        restrict: 'Future',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'months_lived_with_you',
        label: 'Months Lived with you',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ssn',
        label: 'Social Security Number (SSN)',
        placeholder: '778-62-8144',
        formatFunction: ssn,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'full_time_student',
        label: 'Full time student?',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'disabled',
        label: 'Disabled?',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'gross_income',
        label: 'Dependent’s Gross Income*',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'protection_pin',
        label: 'ID Protection PIN*',
        placeholder: '',
    },
    // {
    //     type: fieldTypes.TEXT_DISPLAY,
    //     id: 'age',
    //     label: 'Age',
    //     placeholder: '',
    // },
    // { type: fieldTypes.SEPERATOR },
    // {
    //     type: fieldTypes.RADIO,
    //     id: 'claimed_as_dependent',
    //     label: 'Claimed on Current Year Tax Return?',
    //     options: [
    //         { value: '1', label: 'Yes' },
    //         { value: '0', label: 'No' },
    //     ],
    // },
    // {
    //     type: fieldTypes.RADIO,
    //     id: 'contributes_to_household_income',
    //     label: 'Contributes to household income?',
    //     options: [
    //         { value: '1', label: 'Yes' },
    //         { value: '0', label: 'No' },
    //     ],
    // },
];

const dependentsSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'dependent_data',
        label: 'Do you have any dependents?',
        header: 'Dependent Details',
        displayColumns: [
            {
                name: 'First Name',
                key: 'first_name',
            },
            {
                name: 'Last Name',
                key: 'last_name',
            },
            {
                name: 'Relationship',
                key: 'relationship',
            },
        ],
        formStructure: dependentsForm,
    },
];

export const page: PageInputInterface = {
    address: 'dependent_data',
    name: 'Dependent Data Information',
    sectionId: enumSections.YOUR_INFORMATION,

    formStructure: dependentsSecForm,
    save: upsertDependents,
};
