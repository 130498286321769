import classNames from 'classnames';
import Select from 'react-select';
import { useFormState } from '../../context/form';
import { colors } from '../../utils/constants';
import { interface_field_dropdown, interface_option } from '../../utils/interfaces';
import ParentGroup from './ParentGroup';

type DropdownGroupProps = interface_field_dropdown & {
    className?: string;
    value: any;
    error?: string;
    staticError?: string;
    disabled?: boolean;
    setValue: (str: any) => void;
};

const Dropdown: React.FC<DropdownGroupProps> = (props) => {
    const {
        id,
        value,
        error,
        setValue,
        label,
        className,
        options,
        isMulti = false,
        span,
        staticError,
        disabled,
        defaultValue,
    } = props;

    const resolveValue = (data: any) => {
        if (Array.isArray(data)) {
            const newValues = data.map((item) => {
                return item.value;
            });

            setValue(newValues);
        } else {
            setValue(data?.value);
        }
    };

    let currentValue;
    if (Array.isArray(value)) {
        currentValue = options.filter((o) => o.value === value.find((v) => v === o.value));
    } else currentValue = options.find((o) => o.value === value);

    let filteredOptions: any = options;
    const formData = useFormState();
    if (id === 'county') {
        if (formData?.['state_cd']) {
            filteredOptions = options.filter(({ state }: any) => {
                if (formData['state_cd'] === state) return true;
                else return false;
            });
        } else {
            filteredOptions = [{ label: 'Select State First', value: undefined, isdisabled: true }];
        }
    }

    return (
        <ParentGroup
            id={id}
            className={className}
            label={label}
            value={value}
            error={error}
            style={{ gridColumn: `span ${span}` }}
            staticError={staticError}
        >
            <Select
                id={id}
                isDisabled={disabled}
                isClearable={true}
                isMulti={isMulti}
                styles={customStyles}
                options={filteredOptions}
                placeholder={''}
                defaultValue={value}
                value={currentValue}
                onChange={resolveValue}
                isOptionDisabled={(option: any) => option.isdisabled}
            />
        </ParentGroup>
    );
};

/**
 *  React-Select Stylings
 **/
const customStyles = {
    container: (provided: any, state: any) => {
        return {
            /** main container */
            ...provided,
            padding: 0,
            margin: 0,
            width: '100%',
            outlineColor: '#a00',
            '&:hover': {
                backgroundColor: 'rgba(249, 250, 251, 1)',
            },
        };
    },

    option: (provided: any, state: any) => ({
        /** dropdown section containing all the options */
        ...provided,
        '&:hover': { backgroundColor: !state.isSelected ? '#cde5e5' : colors.primary },
        backgroundColor: state.isSelected ? colors.primary : '#fff',
        // paddingTop: 4,
        // paddingBottom: 4,
        // paddingRight: 12,
        // paddingLeft: 12,
        outlineColor: '#a00',
        '&:focus': {
            outlineColor: '#a00',
        },
    }),

    input: (provided: any, state: any) => {
        /** section that takes text input */

        return {
            ...provided,
            // '&:focus': {
            //     borderColor: colors.primary,
            //     backgroundColor: 'rgba(243, 244, 246, 1)',
            // },
        };
    },

    valueContainer: (provided: any, state: any) => {
        return {
            /** Section showing current value */
            ...provided,
            padding: 8,
            paddingTop: 20,
            margin: 0,
            height: 64,
            outlineColor: '#a00',
            '&:focus': {
                outlineColor: '#a00',
            },
        };
    },

    control: (provided: any, state: any) => {
        return {
            /** container that has input value and container showing current value */
            ...provided,
            borderRadius: 0,
            // backgroundColor: 'rgba(243, 244, 246, 1)',
            borderColor: state.isFocused ? colors.primary : 'rgba(229, 231, 235, 1)',
            backgroundColor: state.isDisabled
                ? 'rgba(229, 231, 235, 1)'
                : state.isFocused
                ? 'rgba(249, 250, 251, 1)'
                : '#fff',
            minHeight: 64,
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: 1,
            boxShadow: 'none',

            '&:hover': {
                // borderColor: 'rgba(209, 213, 219, 1)',
                // borderWidth: 1,
                backgroundColor: 'rgba(249, 250, 251, 1)',
            },

            outlineColor: '#a00',
        };
    },

    dropdownIndicator: (provided: any, state: any) => ({
        /** Arrow section on the right */
        ...provided,
        padding: 0,
        paddingRight: 6,
        paddingTop: 20,
    }),

    indicatorSeparator: (provided: any, state: any) => ({
        /** seperator between the conrol and the dropdown indicator */
        ...provided,
        width: 0,
    }),

    menu: (provided: any, state: any) => ({
        /** Container that has all the dropdown options */
        ...provided,
        borderRadius: 0,
    }),

    placeholder: (provided: any, state: any) => ({
        /** Container that has all the dropdown options */
        ...provided,
        color: '#9CA3AF',
    }),

    multiValue: (provided: any, state: any) => ({
        /** Container that has all the dropdown options */
        ...provided,
        backgroundColor: colors.primary,
    }),

    multiValueLabel: (provided: any, state: any) => ({
        /** Container that has all the dropdown options */
        ...provided,
        color: '#fff',
    }),
};

export default Dropdown;
