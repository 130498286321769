import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from 'react-scroll';
import { useFormDispatch, useFormState } from '../../context/form';
import { useSaveDispatch, useSaveState } from '../../context/save';
import { page } from '../../resource/form_structures/0_0_personal_information';
import { pageRefiner } from '../../utils/helpers';
import pages from '../../resource';
import { enumSections } from '../../utils/enums';
import { isBrick } from './FormConstructor';
import { build } from '../../config';

const resolveSectionIssue = (errors: any, errorSections: any) => {
    const { pages } = pageRefiner();

    Object.keys(errorSections).map((p) => {
        console.log('SECTION ERR', p, pages);

        if (pages[p]?.section.id === enumSections['YOUR_INFORMATION']) {
            let foundKey = false;
            let pageFieldIds: Array<string> = [];

            pages[p].formStructure.forEach((f) => {
                if (isBrick(f)) {
                    pageFieldIds.push(f.id);
                    if (f.branches) {
                        f.branches.paths.forEach(({ form }) => {
                            form.forEach((f) => {
                                if (isBrick(f)) {
                                    pageFieldIds.push(f.id);
                                }
                            });
                        });
                    }
                }

                return 'CONCRETE';
            });
            pageFieldIds.forEach((id) => {
                if (errors[id]) {
                    foundKey = true;
                }
            });

            if (!foundKey) {
                delete errorSections[p];
            }
        }
    });
    return { errors, errorSections };
};

const countErrors = (data: any) => {
    let count = 0;

    if (!Array.isArray(data) && typeof data === 'object') {
        Object.keys(data).map((key) => {
            if (!Array.isArray(data[key])) {
                count += countErrors(data[key]);
            } else {
                if (key !== 'id') {
                    count++;
                }
            }
        });
    }

    return count;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { addresses, business } = useSaveState();
    const saveDispatch = useSaveDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const queryClient = useQueryClient();

    const { pages, sections } = pageRefiner();

    const formState = useFormState();
    const formDispatch = useFormDispatch();

    const { irs_account_id } = useParams();

    useEffect(() => {
        const saveTimeout = setTimeout(() => {
            if (isSuccess) {
                setIsSuccess(false);
            }
        }, 5000);

        return () => clearTimeout(saveTimeout);
    }, [isSuccess]);

    const saveData = async () => {
        formDispatch({ type: 'CLEAR_ERRORS', payload: { data: undefined } });
        setLoading(true);

        let promises: any = [];

        addresses.forEach((address) => {
            if (
                address === 'business_home' &&
                formState.meta_data.business_home_count !== 'multiple'
            ) {
                const { save } = pages[address];
                promises.push(
                    save({
                        business: formState.business_data,
                        meta_data: {
                            ...formState.meta_data,
                            _section: 'BUSINESS_SINGLE_ADD_ERROR.' + address,
                            _id: formState.business_data[0].id,
                        },
                    }),
                );
            } else if (address !== 'meta_data') {
                const { save } = pages[address];
                promises.push(
                    save({
                        ...formState,
                        meta_data: { ...formState.meta_data, _section: 'BASIC.' + address },
                    }),
                );
            }
        });

        Object.keys(business).map((businessId) => {
            business[businessId].map((adr) => {
                const { save } = pages[adr];
                const { business_data, meta_data } = formState;

                const business = business_data.find(({ id }: any) => id === businessId);
                promises.push(
                    save({
                        business,
                        meta_data: { ...meta_data, _section: 'BUSINESS.' + businessId + '.' + adr },
                    }),
                );
            });
        });

        if (promises.length === 0) {
            if (addresses.includes('meta_data')) {
                const { save } = pages['personal_information'];
                promises.push(save(formState));
            }
        }

        let errors = {};
        let errorSections = {};

        const ErrorHandler = (result: PromiseSettledResult<any>[]) => {
            for (let i = 0; i < result.length; i++) {
                const res = result[i];

                if (res.status === 'rejected') {
                    const foundSection = JSON.parse(res.reason.response.config.data).meta_data
                        ._section;

                    const sectionData = foundSection?.split('.');

                    console.log('ERRDBUUGGG', {
                        foundSection,
                        sectionData,
                        result: res.reason.response.data.message,
                    });

                    if (!sectionData) {
                        continue;
                    }

                    if (sectionData[0] === 'BUSINESS_SINGLE_ADD_ERROR') {
                        const foundId = JSON.parse(res.reason.response.config.data).meta_data._id;
                        errorSections = { ...errorSections, [sectionData[1]]: true };

                        errors = {
                            ...errors,
                            business_data: { [foundId]: res.reason.response.data.message[0] },
                        };

                        continue;
                    }

                    if (sectionData[0] === 'BASIC') {
                        errorSections = { ...errorSections, [sectionData[1]]: true };
                    }

                    if (sectionData[0] === 'BUSINESS') {
                        errorSections = {
                            ...errorSections,
                            [sectionData[1] + '.' + sectionData[2]]: true,
                        };
                    }

                    console.log('ERRDBUUGGG, Error', res.reason.response.data.message);

                    errors = { ...errors, ...res.reason.response.data.message };
                }
            }
        };

        const SuccessHandler = () => {
            setIsSuccess(true);

            formDispatch({ type: 'CLEAR_ERRORS', payload: { data: undefined } });
            saveDispatch('EMPTY_ADDRESSES', null);

            queryClient.invalidateQueries({
                predicate: (query) =>
                    !!sections.find(
                        ({ id }) =>
                            (query.queryKey[0] === `${id}` &&
                                query.queryKey[1] === irs_account_id) ||
                            query.queryKey[0] === 'irs-standard',
                    ),
            });

            localStorage.removeItem('save-state-' + irs_account_id);
            localStorage.removeItem('form-data-' + irs_account_id);
        };

        const result = await Promise.allSettled(promises);

        ErrorHandler(result);

        if (Object.keys(errors).length) {
            const resolved = resolveSectionIssue(errors, errorSections);
            formDispatch({
                type: 'SET_ERRORS',
                payload: {
                    data: resolved.errors,
                    sections: build.error_highlighting ? resolved.errorSections : undefined,
                },
            });
        } else {
            SuccessHandler();
        }

        setLoading(false);
    };

    const show = addresses.length || Object.keys(business).length;
    const errorCount = countErrors(formState._errors);

    return show ? (
        <div
            className={classNames(
                'flex flex-col items-center max-w-4xl bg-white w-full cursor-pointer rounded-md fixed bottom-2  border-b-2 border-t-2 border-gray-300 justify-center',
            )}
            style={{ boxShadow: '2px -5px 10px #ddd', minWidth: 550, minHeight: 48 }}
        >
            {isSuccess ? (
                <div>{`Changes saved successfully`}</div>
            ) : (
                <div className="w-full flex px-4 py-1 items-center">
                    <div className="flex-grow">{`You have unsaved changes, save once, after making all relevant changes`}</div>
                    <div
                        onClick={() => !loading && saveData()}
                        className="px-3 py-2 bg-primary-500 cursor-pointer hover:bg-primary-700 text-sm text-white font-title"
                    >
                        {loading ? `SAVING...` : `SAVE`}
                    </div>
                </div>
            )}
            {!!Object.keys(formState._errors).length && (
                <div className="w-full text-xs font-bold bg-red-100 text-red-600 px-3 py-1">
                    {formState._errors['crash']
                        ? 'Something went wrong'
                        : `Please fix ${errorCount} error(s) to proceed`}
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};
