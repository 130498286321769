import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes } from '../../../../utils/enums';
import NavBar from '../../NavBar';
import { useAuthState } from '../../../../context/auth';
import { useCompletedPercentage, useFeatureEnabled, useIsMobile } from '../../../../utils/helpers';
import { useFormState } from '../../../../context/form';
import useFormsList from '../../../../api/tax-prep/useFormsList';
import Modal from '../../Modal';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../Button';
import InputGroup from '../../../client/InputGroup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showToast } from '../../Toaster';
import { addNewTaxPrepYear, deleteTaxPrepYear, editClient, getTaxPro } from '../../../../api/admin';
import { build } from '../../../../config';
import Dropdown from '../../../client/Dropdown';

import { UserTaxometrics } from './UserTaxometrics';
import { UserCompliance, UserTaxLiabilty } from './UserCompliance';
import { UserPaymentTransaction, UserTransaction } from './UserTransaction';
import { UserIncome } from './UserIncome';
import { UserAllTranscripts } from './UserAllTranscripts';
import Layout from '../layout/UserDashLayout';

type accessOptns = {
    label: string;
    value: string;
};

const TranscriptDropdown: React.FC<{
    setTranscriptTab: (id: number) => void;
    transcriptTab: number;
    options: { id: number; label: string }[];
    label: string;
}> = ({ setTranscriptTab, transcriptTab, options, label }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                style={
                    options.some((option) => option.id === transcriptTab)
                        ? { borderBottomWidth: '2.75px' }
                        : {}
                }
                className={` outline-none text-center py-2.5 px-5 cursor-pointer ease-in-out duration-200  inline-flex items-center ${
                    options.some((option) => option.id === transcriptTab)
                        ? ' border-primary-500'
                        : ''
                }`}
                onClick={toggleDropdown}
            >
                <span className="mr-1">{label}</span>
                <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
            </button>
            {isOpen && (
                <ul className="z-50 absolute text-gray-700  shadow-md border">
                    {options.map((option) => (
                        <li
                            key={option.id}
                            className={`${
                                option.id !== options[options.length - 1].id &&
                                'border-b border-gray-300'
                            }`}
                        >
                            <button
                                className=" text-left text-xs font-semibold w-full  bg-white hover:bg-gray-200 py-2 px-3 block whitespace-no-wrap"
                                onClick={() => {
                                    setTranscriptTab(option.id);
                                    setIsOpen(false);
                                }}
                            >
                                {option.label}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
const Transcripts: React.FC = () => {
    const [editClient, setEditClient] = useState(false);
    const { irs_account_id } = useParams();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [deleteModal, setdeleteModal] = useState(false);
    const [deleteYear, setDeleteYear] = useState({ year: '', tax_id: '' });

    const [transcriptTab, setTranscriptTab] = useState(1);

    const { isLoading, data } = useFormsList({
        irs_account_id: irs_account_id,
    });

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    //to get the complete percentage for tax resolution
    const completePercentage = useCompletedPercentage(irs_account_id);

    if (isLoading) {
        return (
            <div>
                {!iframe && <NavBar />}
                <div
                    style={{ alignItems: 'center' }}
                    className="h-screen flex justify-center text-justify align-middle"
                >
                    <div>Loading...</div>
                </div>
            </div>
        );
    }

    //to get the service type text to show on screen
    const serviceType = data && JSON.parse(data?.u_service_type);
    const getServiceType = () => {
        const text1 = serviceType?.includes('tax_resolution') ? 'Tax Resolution' : '';
        const text2 = serviceType?.includes('tax_preparation') ? 'Tax Preparation' : '';

        return text1 && text2 ? `${text1} , ${text2}` : text1 || text2;
    };
    console.log(data);

    const transcripts = [
        { id: 1, transcript: 'Taxometrics' },
        { id: 2, transcript: 'Compliance' },
        { id: 3, transcript: 'Transaction' },
        { id: 4, transcript: 'Income' },
        { id: 5, transcript: 'All Transcripts' },
    ];

    const RenderComponent: React.FC = () => {
        switch (transcriptTab) {
            case 1:
                return (
                    <UserTaxometrics
                        setTranscriptTab={setTranscriptTab}
                        transcriptTab={transcriptTab}
                    />
                );
            case 3:
                return <UserTransaction />;
            case 4:
                return <UserIncome />;
            case 5:
                return <UserAllTranscripts transcriptTab={transcriptTab} />;
            case 6:
                return <UserCompliance />;
            case 7:
                return <UserTaxLiabilty />;
            case 8:
                return <UserPaymentTransaction />;
            case 9:
                return <UserTransaction />;
            default:
                return null;
        }
    };

    console.log(transcriptTab, 'transcript');
    return (
        <div>
            <div>
                <div className="flex flex-col w-full h-screen overflow-y-auto bg-gray-100">
                    {!iframe && <NavBar />}
                    <div
                        className="w-full h-full"
                        style={{ paddingLeft: '1%', paddingRight: '1%' }}
                    >
                        <div className="col-span-3">
                            {' '}
                            <div className=" ml-3 bg-white  shadow-sm mt-5  relative">
                                {/* new code */}

                                <div className=" flex flex-col  border-0 border-[#CCCCCC] pb-10 ">
                                    <div className=" flex flex-col  items-start overflow-x-hidden">
                                        <div className="flex justify-start gap-3 ">
                                            {transcripts.map((item, index) => (
                                                <div key={item.id}>
                                                    {item.id === 2 ? (
                                                        <TranscriptDropdown
                                                            setTranscriptTab={setTranscriptTab}
                                                            transcriptTab={transcriptTab}
                                                            options={[
                                                                { id: 6, label: 'Compliance' },
                                                                {
                                                                    id: 7,
                                                                    label: 'Tax Liability Metrics',
                                                                },
                                                            ]}
                                                            label="Compliance"
                                                        />
                                                    ) : (
                                                        // item.id === 3 ? (
                                                        //     <TranscriptDropdown
                                                        //         setTranscriptTab={setTranscriptTab}
                                                        //         transcriptTab={transcriptTab}
                                                        //         options={[
                                                        //             { id: 8, label: 'Payment Metrics' },
                                                        //             {
                                                        //                 id: 9,
                                                        //                 label: 'Transaction Metrics',
                                                        //             },
                                                        //         ]}
                                                        //         label="Transaction"
                                                        //     />
                                                        // ) :
                                                        <div
                                                            style={
                                                                transcriptTab === item.id
                                                                    ? {
                                                                          borderBottomWidth:
                                                                              '2.75px',
                                                                      }
                                                                    : {}
                                                            }
                                                            className={`text-center py-2.5 px-5 cursor-pointer ease-in-out duration-200 ${
                                                                transcriptTab === item.id
                                                                    ? ' border-primary-500'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                setTranscriptTab(item.id)
                                                            }
                                                        >
                                                            {item.transcript}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                        <RenderComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* modals for add, edit, delete  */}
            </div>
        </div>
    );
};

export default Transcripts;
