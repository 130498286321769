import React, { createContext, useReducer, useContext, Dispatch, useEffect, useState } from 'react';
import { build } from '../config';

interface SaveStateInterface {
    addresses: Array<string>;
    business: { [key: string]: Array<string> };
    data: { [key: string]: any };
    irs_account_id?: string;
}

const initialState: SaveStateInterface = {
    addresses: [],
    business: {},
    data: {},
    irs_account_id: undefined,
};

interface SET_DATA {
    type: 'SET_DATA';
    payload: SaveStateInterface;
}

interface ADD_ADDRESS {
    type: 'ADD_ADDRESS';
    payload: { address: string; data: any };
}

interface REMOVE_ADDRESS {
    type: 'REMOVE_ADDRESS';
    payload: { address: string };
}

interface ADD_META_DATA {
    type: 'ADD_META_DATA';
    payload: { data: any };
}

interface REMOVE_META_DATA {
    type: 'REMOVE_META_DATA';
    payload: { data: any };
}

interface ADD_BUSINESS_ADDRESS {
    type: 'ADD_BUSINESS_ADDRESS';
    payload: { id: string; address: string; data: any };
}

interface REMOVE_BUSINESS_ADDRESS {
    type: 'REMOVE_BUSINESS_ADDRESS';
    payload: { id: string; address: string };
}

interface EMPTY_ADDRESSES {
    type: 'EMPTY_ADDRESSES';
    payload: null;
}

type SaveActionInterface =
    | SET_DATA
    | ADD_META_DATA
    | REMOVE_META_DATA
    | ADD_ADDRESS
    | REMOVE_ADDRESS
    | ADD_BUSINESS_ADDRESS
    | REMOVE_BUSINESS_ADDRESS
    | EMPTY_ADDRESSES;

type SaveActionTypes =
    | SET_DATA['type']
    | ADD_META_DATA['type']
    | REMOVE_META_DATA['type']
    | ADD_ADDRESS['type']
    | REMOVE_ADDRESS['type']
    | ADD_BUSINESS_ADDRESS['type']
    | REMOVE_BUSINESS_ADDRESS['type']
    | EMPTY_ADDRESSES['type'];

type SaveActionPayloads =
    | SET_DATA['payload']
    | ADD_META_DATA['payload']
    | REMOVE_META_DATA['payload']
    | ADD_ADDRESS['payload']
    | REMOVE_ADDRESS['payload']
    | EMPTY_ADDRESSES['payload']
    | ADD_BUSINESS_ADDRESS['payload']
    | REMOVE_BUSINESS_ADDRESS['payload'];

const reducer = (state: SaveStateInterface, action: SaveActionInterface) => {
    switch (action.type) {
        case 'SET_DATA': {
            return { ...state, ...action.payload };
        }

        case 'ADD_META_DATA':
            const { data: metaData } = action.payload;
            if (!state.addresses.includes('meta_data')) {
                return {
                    ...state,
                    addresses: [...state.addresses, 'meta_data'],
                    data: { ...state.data, ...metaData },
                };
            } else
                return {
                    ...state,
                    data: { ...state.data, ...metaData },
                };

        case 'REMOVE_META_DATA':
            return {
                ...state,
                addresses: state.addresses.filter((adr) => adr !== 'meta_data'),
                irs_account_id: action.payload?.data.meta_data.irs_account_id,
            };

        case 'ADD_ADDRESS':
            const { address: addAdr } = action.payload;
            if (!state.addresses.includes(addAdr)) {
                return {
                    ...state,
                    addresses: [...state.addresses, action.payload.address],
                    data: { ...state.data, ...action.payload.data },
                };
            } else
                return {
                    ...state,
                    data: { ...state.data, ...action.payload.data },
                };

        case 'REMOVE_ADDRESS':
            return {
                ...state,
                addresses: state.addresses.filter((adr) => adr !== action.payload.address),
            };

        case 'ADD_BUSINESS_ADDRESS':
            const { id: addId, address: addAddress, data: addData } = action.payload;
            if (!state.business[addId]) {
                return {
                    ...state,
                    business: { ...state.business, [addId]: [addAddress] },
                    data: { ...state.data, ...addData },
                };
            } else {
                if (!state.business[addId].includes(addAddress)) {
                    return {
                        ...state,
                        business: {
                            ...state.business,
                            [addId]: [...state.business[addId], addAddress],
                        },
                        data: { ...state.data, ...action.payload.data },
                    };
                } else {
                    return {
                        ...state,
                        data: { ...state.data, ...action.payload.data },
                    };
                }
            }

        case 'REMOVE_BUSINESS_ADDRESS':
            const { id: rmId, address: rmAddress } = action.payload;
            if (!state.business[action.payload.id]) {
                return state;
            } else {
                const localState = {
                    ...state,
                    business: {
                        ...state.business,
                        [rmId]: state.business[rmId].filter((adr: string) => adr !== rmAddress),
                    },
                };

                if (localState.business[rmId].length === 0) {
                    delete localState.business[rmId];
                }

                return localState;
            }

        case 'EMPTY_ADDRESSES':
            return initialState;

        default:
            return state;
    }
};

const saveReducer = (state: SaveStateInterface, action: SaveActionInterface) => {
    const newState = reducer(state, action);

    if (build.local_storage && state.irs_account_id) {
        localStorage.setItem('save-state-' + state.irs_account_id, JSON.stringify(newState));
    }
    return newState;
};

type SaveDispatchContextType = (type: SaveActionTypes, payload: SaveActionPayloads) => void;
const SaveStateContext = createContext<SaveStateInterface>({} as SaveStateInterface);
const SaveDispatchContext = createContext<SaveDispatchContextType>({} as SaveDispatchContextType);

export const SaveProvider: React.FC = ({ children }) => {
    const [state, defaultDispatch] = useReducer(saveReducer, initialState);
    const dispatch = (type: SaveActionTypes, payload: any) => {
        defaultDispatch({ type, payload });
    };

    return (
        <SaveDispatchContext.Provider value={dispatch}>
            <SaveStateContext.Provider value={state}>{children}</SaveStateContext.Provider>
        </SaveDispatchContext.Provider>
    );
};

export const useSaveState = () => useContext(SaveStateContext);
export const useSaveDispatch = () => useContext(SaveDispatchContext);
