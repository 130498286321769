import { credential } from 'firebase-admin';
import { api } from '.';

export const getUserCredentials = async (
    token: string,
): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.get(`/getDataFromToken?token=${token}`);
    return { token, ...data.data };
};

export const defaultSuperAdmin = async (details: {
    u_email: string;
    u_password: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post('/mycaseSuperLogin', details);
    return data;
}

export const defaultLogin = async (credentials: {
    u_email: string;
    u_password: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post('/login', credentials);//
    return data;
};

export const signInUserSso = async (signInData: {
    u_email: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post('/mslogin', signInData);
    return data;
};

export const signUpUser = async (organizationData: {
    organization_email: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post('/createOrganizationRecord', organizationData);
    return data;
};

export const setUpOrganization = async (organizationData: {
    organization_name?: string;
    individual_name: string;
    organization_phone: string;
    user_type: string;
    id: string;
    organization_type: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post('/createMyCaseOrganization', organizationData);
    return data;
};
export const registerUser = async (userData: {
    name: string;
    email: string;
    password: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    let data = { success: false };

    await api
        .post('/auth/register', userData)
        .then((response) => {
            data = response.data;
        })
        .catch((err) => {
            data = err.response.data;
        });

    return data;
};

export const forgotPassword = async (forgotData: { u_email: string }) => {
    const { data } = await api.post('/forgotPassword', forgotData);
    return data;
};

export const changePassword = async (changeData: {
    u_email: string;
    u_password: string;
    new_password: string;
}) => {
    const { data } = await api.post('/changePassword', changeData);
    return data;
};
