export enum fieldTypes {
    TEXT_INPUT,
    AMOUNT_INPUT,
    DROPDOWN,
    RADIO,
    CALENDAR,
    SEPERATOR,
    TABLE,
    CROSSROAD,
    EDITABLE_TABLE,
    TERMINAL,
    TEXT_DISPLAY,
    MULTIFORM,
}

export enum enumActionTypes {
    UPDATE_COMMENTS,
    UPDATE_COMMENTS_BUSINESS,
    UPDATE_PAGE_STATUS,
    UPDATE_PAGE_STATUS_BUSINESS,
    UPDATE_CROSSROAD,
    UPDATE_CROSSROAD_BUSINESS,
    UPDATE_FORM,
    UPDATE_FORM_BUSINESS,
}

export enum sections {
    your_infomation = 'Your Information',
}

export enum routes {
    AUTH,
}

export enum AuthActionTypes {
    LOGIN,
    LOGOUT,
}

export const COOKIE = 'jwt';

export enum enumSections {
    YOUR_INFORMATION = 1,
    FINANCIAL_INFORMATION,
    BUSINESS_INFORMATION,
    OTHER_INFORMATION,
}

export enum enumPageTypes {
    FORM,
    WELCOME,
    UNKNOWN,
}
