import { upsertOtherFinancialInfo } from '../../api/other';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { ein } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const lawsuitForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_location_of_filing',
        label: 'Location of Filing',
        placeholder: '',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_represented_by',
        label: 'Represented By',
        placeholder: '$12,000',
    },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_docket_case_num',
        label: 'Docket/Case #',
        placeholder: '$12,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_amount_of_suit',
        label: 'Amount of Suit',
        placeholder: '$12,000',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'lawsuit_possible_completion_date',
        label: 'Possible Completion Date',
        placeholder: '$12,000',
    },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_subject_of_suit',
        label: 'Subject of Suit',
        placeholder: '$12,000',
        area: true,
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'lawsuit_plaintiff_defendant',
        label: 'Party to Lawsuit',
        placeholder: 'Yes / No',
        options: [
            { value: 'def', label: 'Defendant' },
            { value: 'pla', label: 'Plaintiff' },
        ],
    },
];

const bankruptcyForm: FormInterface = [
    {
        type: fieldTypes.CALENDAR,
        id: 'bankruptcy_filed_date',
        label: 'Date Filed',
        placeholder: '12 Aug 2012',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'bankruptcy_dismissed_date',
        label: 'Date Dismissed',
        placeholder: '12 Aug 2012',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'bankruptcy_discharged_date',
        label: 'Date Discharged',
        placeholder: '12 Aug 2012',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'bankruptcy_petition_num',
        label: 'Petition #',
        placeholder: '334568732322',
        area: true,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'bankruptcy_location',
        label: 'Location Filed',
        placeholder: 'Florida',
    },
];

const beneficiaryForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'beneficiary_place_recorded',
        label: 'Place Where Recorded',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'beneficiary_ein',
        label: 'EIN',
        placeholder: '455678933333',
        formatFunction: ein,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'beneficiary_name',
        label: 'Name',
        placeholder: 'ICICI',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'beneficiary_amount',
        label: 'Anticipated Amount',
        placeholder: '$12,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'beneficiary_when',
        label: 'When Will Amount Be Received',
        placeholder: '24 Aug 2026',
    },
];

const trustForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'trustee_fiduciary_contrib_trust_name',
        label: 'Name of Trust',
        placeholder: 'Education Trust',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'trustee_fiduciary_contrib_trust_ein',
        label: 'EIN',
        placeholder: '455678933333',
        formatFunction: ein,
    },
];

const litigationsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'of_tax_type_litigation_period',
        label: 'Type of Tax',
        placeholder: 'Antique Jwellery, World war 2 cannons',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'litigation_tax_period',
        label: 'Tax Period',
        placeholder: '8 months',
    },
];

const thirdPartyFundForm: FormInterface = [
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'funds_held_in_trust_how_much',
        label: 'Amount',
        placeholder: '$5,600',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'funds_held_in_trust_where',
        label: 'Location',
        placeholder: 'Florida',
    },
];

const abroadForm: FormInterface = [
    {
        type: fieldTypes.CALENDAR,
        id: 'outside_of_us_begin_date',
        label: 'Lived Abroad From',
        placeholder: '12 Aug 2012',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'outside_of_us_end_date',
        label: 'Until',
        placeholder: '18 Aug 2018',
    },
];

const form: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Other Financial Information' },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Are you party to a lawsuit?',
        id: 'lawsuit_party',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: lawsuitForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Have you ever filed for Bankruptcy?',
        id: 'bankruptcy_filed',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: bankruptcyForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'In the past 10 years have you lived outside of the U.S for 6 months or longer?',
        id: 'outside_of_us',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: abroadForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Are you the beneficiary of a trust, estate or life insurance policy?',
        id: 'beneficiary_trust_estate_life',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: beneficiaryForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Are you a Trustee, fiduciary, or contributor to a trust?',
        id: 'trustee_fiduciary_contrib_trust',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: trustForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Are you or have you ever been party to any litigation involving the IRS/United States (including any tax litigation)?',
        id: 'of_party_to_litigation_yn',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: litigationsForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Do you have any funds being held in trust by a third party?',
        id: 'funds_held_in_trust',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: thirdPartyFundForm }],
        },
    },
    { type: fieldTypes.TERMINAL },
];

export const page: PageInputInterface = {
    address: 'other_financials',
    name: 'Other Financial Information',

    sectionId: enumSections.OTHER_INFORMATION,
    formStructure: form,

    save: upsertOtherFinancialInfo,
};
