import { upsertAccountsRecievable } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const accountsReceivable: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_note_name',
        label: 'Name',
        placeholder: '',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'status',
        label: 'Status',
        placeholder: 'Queued/Processing/Sending...',
        options: [
            { label: 'Queued', value: 'Queued' },
            { label: 'Processing', value: 'Processing' },
            { label: 'Sending', value: 'Sending' },
            { label: 'Delivered', value: 'Delivered' },
            { label: 'Receiving', value: 'Receiving' },
            { label: 'Received', value: 'Received' },
            { label: 'No-Answer', value: 'No-Answer' },
            { label: 'Busy', value: 'Busy' },
            { label: 'Failed', value: 'Failed' },
            { label: 'Canceled', value: 'Canceled' },
            { label: 'Completed', value: 'Completed' },
            { label: 'Enrollment', value: 'Enrollment' },
        ],
    },
    { type: fieldTypes.SEPERATOR },

    {
        type: fieldTypes.CALENDAR,
        id: 'date_due',
        label: 'Date Due',
        placeholder: '10 August 2023',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'invoice_grant_contract_number',
        label: 'Invoice or Contract #',
        placeholder: '228388402',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'amount_due',
        label: 'Amount Owed',
        placeholder: '$12,000',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'contact_name',
        label: 'Contact Name',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_note_phone',
        label: 'Phone',
        placeholder: 'johndoe@hotmail.com',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Account Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_note_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_note_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'account_note_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_note_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_account_receivable_data',
        label: 'How many accounts receivable does your business has?',
        header: 'Accounts Receivable Information',
        formStructure: accountsReceivable,
        displayColumns: [
            {
                name: 'Type',
                key: 'account_note',
            },
            {
                name: 'Name',
                key: 'account_note_name',
            },
            {
                name: 'Status',
                key: 'status',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_accounts_receivable',
    name: 'Business Accounts Receivable',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertAccountsRecievable,
};
