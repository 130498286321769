import { useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from '..';
import { useAuthState } from '../../context/auth';

const paramString = (key: string, values: Array<any>): string => {
    let str: string = ``;

    if (values.length === 0) return str;

    values.forEach((value) => {
        if (value)
            if (!str) str = `${key}=${value}`;
            else str = str + `&${key}=${value}`;
    });

    return str;
};

const useSuperClients = () => {
    const [errors, setErrors] = useState<any>({});
    const { token } = useAuthState();

    const [filterParams, setFilterParams] = useState<{ [key: string]: any }>({ offset: 0 });
    const [filterString, setFilterString] = useState<string>();

    const getClients = async ({
        queryKey: [_, filterString],
    }: QueryFunctionContext): Promise<any> => {
        const { data } = await api.get(`/featureClientList?` + filterString);
        return data;
    };

    const { data:  superClientsData, isLoading } = useQuery({
        queryKey: ['super-clients-list'],
        queryFn: getClients,
        enabled: !!token,
        onError: (error: any) => setErrors(error.response.data),
    });

    useEffect(() => {
        let arrParams = ``,
            strFilters = {};

        Object.keys(filterParams).map((key) => {
            if (Array.isArray(filterParams[key])) {
                if (arrParams === ``) {
                    arrParams = paramString(key, filterParams[key]);
                } else {
                    arrParams += '&' + paramString(key, filterParams[key]);
                }
            } else {
                if (filterParams[key]) {
                    strFilters = { ...strFilters, [key]: filterParams[key] };
                }
            }
        });

        const strParams = new URLSearchParams({ ...strFilters });
        const queryParams = strParams + (arrParams !== `` ? '&' + arrParams : ``);

        setFilterString(queryParams);
    }, [filterParams]);

    return {
        isLoading,
        total:  superClientsData?.total,
        superClients:  superClientsData?.data,
        filterParams,
        setFilterParams,
    };
};

export default useSuperClients;