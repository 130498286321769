import { upsertAssets } from '../../api/business';
import { usa_counties, usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const assetForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        span: 2,
        id: 'description',
        label: 'Description',
        placeholder: 'Furniture',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_loan_balance',
        label: 'Loan Balance',
        placeholder: '$14,780',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'purchase_lease_date',
        label: 'Purchase Date',
        placeholder: 'Aug 2021',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'final_payment_date',
        label: 'Final Payment Date',
        placeholder: '$2000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'monthly_payment',
        label: 'Amt. of Monthly Pmt. $',
        placeholder: '12 June 2026',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Asset Location Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_address',
        area: true,
        span: 2,
        label: 'Address Line 1',
        placeholder: `15498 Plantation Oaks Drive`,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_city',
        label: 'City',
        placeholder: `Tampa Bay`,
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'location_state',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_counties,
        id: 'location_county',
        label: 'County',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_zip',
        label: 'Zip',
        placeholder: '32003',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_name',
        label: 'Lender Name',
        placeholder: 'Jane Doe',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_phone',
        label: 'Lender Phone',
        placeholder: '1234567890',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Lender Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_address',
        label: 'Address',
        placeholder: `15498 Plantation Oaks Drive`,
        span: 2,
        area: true,
    },

    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_city',
        label: 'City',
        placeholder: `Tampa Bay`,
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'lender_state',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_zip',
        label: 'ZIP',
        placeholder: '32003',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Current Fair Market Value (FMV)',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'intangible_ind',
        label: 'Intangible Asset',
        placeholder: 'johndoe@hotmail.com',
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
    },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_assets_data',
        label: 'How many assets does your business own?',
        header: 'Business Asset Information',
        formStructure: assetForm,
        displayColumns: [
            {
                name: 'Description',
                key: 'description',
            },
            {
                name: 'Current Fair Market Value (FMV)',
                key: 'current_value',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_assets',
    name: 'Business Assets Information',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertAssets,
};
