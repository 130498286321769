import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes } from '../utils/enums';
import NavBar from '../components/basic/NavBar';
import { useAuthState } from '../context/auth';
import { useCompletedPercentage, useFeatureEnabled } from '../utils/helpers';
import { useFormState } from '../context/form';
import moment from 'moment';
import useFormsList from '../api/tax-prep/useFormsList';
import Modal from '../components/basic/Modal';
import { useEffect, useState } from 'react';
import Button from '../components/basic/Button';
import InputGroup from '../components/client/InputGroup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showToast } from '../components/basic/Toaster';
import { addNewTaxPrepYear, deleteTaxPrepYear, editClient, getTaxPro } from '../api/admin';
import { build } from '../config';
import Dropdown from '../components/client/Dropdown';

type accessOptns = {
    label: string;
    value: string;
};

const accessOpts: accessOptns[] = [
    { label: 'Tax Resolution', value: 'tax_resolution' },
    { label: 'Tax Prepration', value: 'tax_preparation' },
];

const getYears = () => {
    //getting previous twenty years from current year for the adding years and edit years dropdown
    const currentYear = new Date().getFullYear();
    let temp = currentYear - 20;
    let year = [];
    for (let i = currentYear; i > temp; i--) {
        year.push(i);
    }
    return year;
};

const EditClient: React.FC<{ close: () => void; data: any; user_id: string }> = ({
    close,
    data,
    user_id,
}) => {
    console.log('token id - data', data);
    const [email, setEmail] = useState<string>(data?.u_email);
    const [firstName, setFirstName] = useState<string>(data?.u_first_name);
    const [lastName, setlastName] = useState<string>(data?.u_last_name);
    const [taxPro, setTaxPro] = useState<any>({
        label: data?.taxpro,
        value: data?.tax_preparer_id,
    });
    const [formAccess, setFormAccess] = useState<string[]>(JSON.parse(data?.u_service_type));
    const { success: isTaxPrepEnabled } = useFeatureEnabled('Tax Prep');
    const [years, setYears] = useState<number[]>(JSON.parse(data?.u_taxprep_years));
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const [isClientAdded, setClientAdded] = useState(false);

    //effect to close client added to close modal
    useEffect(() => {
        const saveTimeout = setTimeout(() => {
            if (isClientAdded) {
                setClientAdded(false);
                close();
            }
        }, 3000);

        return () => clearTimeout(saveTimeout);
    }, [isClientAdded]);

    const queryClient = useQueryClient();
    //mutation to edit client
    const mutation = useMutation(editClient, {
        onSuccess: async () => {
            await queryClient.invalidateQueries('TaxprepList');
            showToast('Client has been updated successfully!', 'success');
            setClientAdded(true);
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const [taxPros, setTaxPros] = useState([
        {
            user_id: 'Loading...',
            u_first_name: 'Loading...',
            u_last_name: 'Loading...',
            u_user_name: 'Loading...',
            u_email: 'Loading...',
        },
    ]);

    //formatting data
    const taxProOptions = taxPros.map((t) => ({
        label: t.u_first_name + ' ' + t.u_last_name,
        value: t.user_id,
    }));

    const { data: taxProData } = useQuery('get-taxpros', getTaxPro);

    //seting tax pro data
    useEffect(() => {
        if (!taxProData) return;

        if (taxProData.success) {
            if (Array.isArray(taxProData.data)) setTaxPros(taxProData.data);
        }
    }, [taxProData]);

    const { token, role } = useAuthState();

    //function to register the user also fields checks added
    const registerClient = () => {
        if (!token) return;

        const formData: any = {
            u_email: email,
            u_first_name: firstName,
            u_last_name: lastName,
            tax_preparer_id:
                data?.tax_preparer_id === taxPro?.value ? data.tax_preparer_id : taxPro,
            token: token,
        };
        if (isTaxPrepEnabled) {
            formData.u_service_type = formAccess;
            if (formAccess.includes('tax_preparation')) {
                formData.u_taxprep_years = years;
            }
        }
        mutation.mutate({ formData, user_id });
    };
    console.log(error);

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ maxWidth: 500, width: '100%' }}>
            <>
                <div className="font-title py-4">{`Edit Personal Info.`}</div>
                <InputGroup
                    id="email"
                    label="Email"
                    placeholder=""
                    value={email}
                    setValue={(value) => {
                        setEmail(value);
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    staticError={error.u_email}
                />

                <InputGroup
                    id="firstName"
                    label="First Name"
                    placeholder=""
                    value={firstName}
                    setValue={(value) => {
                        setFirstName(value);
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    staticError={error.u_first_name}
                />

                <InputGroup
                    id="lastName"
                    label="Last Name"
                    placeholder=""
                    value={lastName}
                    setValue={(value) => {
                        setlastName(value);
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    staticError={error.u_last_name}
                />

                {role === 'Admin' && (
                    <>
                        <Dropdown
                            id="tax_pro"
                            label="Assigned To Professional"
                            placeholder="Select Tax Preparer..."
                            options={taxProOptions}
                            value={taxPro}
                            setValue={(value) => {
                                setTaxPro(value);
                                setErrors({});
                            }}
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1"
                            staticError={error.taxPro}
                        />
                        {build.taxprep_module && (
                            <>
                                <Dropdown
                                    isMulti
                                    id="service_type "
                                    label="Service Type"
                                    placeholder="Select "
                                    options={accessOpts}
                                    value={formAccess}
                                    setValue={(value) => {
                                        setFormAccess(value);
                                        setErrors({});
                                    }}
                                    type={fieldTypes.DROPDOWN}
                                    className="w-full mt-1"
                                    staticError={error.taxPro}
                                />
                                {formAccess.includes('tax_preparation') && (
                                    <>
                                        <Dropdown
                                            isMulti
                                            id="tp_initial_years "
                                            label="Tax Prep Years"
                                            placeholder="Select "
                                            options={getYears().map((year: number) => {
                                                return {
                                                    label: `${year}`,
                                                    value: `${year}`,
                                                };
                                            })}
                                            value={years}
                                            setValue={(value) => {
                                                setYears(value);
                                                setErrors({});
                                            }}
                                            type={fieldTypes.DROPDOWN}
                                            className="w-full mt-1"
                                            staticError={error.taxPro}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                <Button
                    isLoading={mutation.isLoading}
                    onClick={registerClient}
                    className="text-center w-full mt-8"
                >
                    {'Save'}
                </Button>
            </>
        </div>
    );
};

const AddYearCard: React.FC<{ close: () => void; user: any }> = ({ close, user }) => {
    const [years, setYears] = useState<Array<number>>([]);
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const queryClient = useQueryClient();
    const { token } = useAuthState();
    //mutation to add tax prep year
    const mutation = useMutation(addNewTaxPrepYear, {
        onSuccess: () => {
            queryClient.invalidateQueries('TaxprepList');
            showToast('Year has been Added !', 'success');
            close();
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.message, 'error');
            setErrors(error.response.data.message);
        },
    });

    //handle submitting
    const handleSubmit = () => {
        console.log('year-b', years);
        if (!token) return;
        mutation.mutate({
            irs_account_id: user,
            years: years,
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Add Year`}</div>
            {/* <InputGroup
                id="year"
                label="Add Year"
                placeholder=""
                value={year}
                setValue={(value) => {
                    setYear(value);
                    setErrors({});
                }}
                type={fieldTypes.TEXT_INPUT}
                className="w-full mt-1 mb-6"
                staticError={error.u_ptin}
            /> */}

            <Dropdown
                isMulti
                key={'year-selector'}
                id="tp_initial_years "
                label="Tax Prep Years"
                placeholder="Select Years"
                options={getYears().map((year: number) => {
                    return {
                        label: `${year}`,
                        value: `${year}`,
                    };
                })}
                value={years}
                setValue={(value) => {
                    setYears(value);
                    setErrors({});
                }}
                type={fieldTypes.DROPDOWN}
                className="w-full mb-3"
                staticError={error.taxPro}
            />

            <div className="flex w-full">
                <Button
                    onClick={handleSubmit}
                    isLoading={mutation.isLoading}
                    className="text-center flex-grow mr-2"
                >
                    {'ADD YEAR'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const DeleteYearCard: React.FC<{ close: () => void; user: any; deleteYear: any }> = ({
    close,
    user,
    deleteYear,
}) => {
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    // error will be used later on

    const queryClient = useQueryClient();
    const { token } = useAuthState();

    //mutation to delete
    const mutation = useMutation(deleteTaxPrepYear, {
        onSuccess: () => {
            queryClient.invalidateQueries('TaxprepList');
            showToast('Year has been Deleted!', 'success');
            close();
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.message, 'error');
            setErrors(error.response.data.message);
        },
    });

    //handle submitting
    const handleSubmit = () => {
        if (!token) return;
        mutation.mutate({ tax_id: deleteYear?.tax_id });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`DELETE YEAR`}</div>
            <div className="mb-2">
                Are you sure you want to delete Year
                <span className="font-semibold">{deleteYear?.year}</span>
            </div>
            <div className="flex w-full">
                <Button
                    onClick={handleSubmit}
                    isLoading={mutation.isLoading}
                    className="text-center flex-grow mr-2"
                >
                    {'DELETE YEAR'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const TPNavigator: React.FC<{}> = ({}) => {
    const [editClient, setEditClient] = useState(false);
    const { irs_account_id } = useParams();
    const navigate = useNavigate();
    const { meta_data } = useFormState();
    const [modal, setModal] = useState(false);
    const [deleteModal, setdeleteModal] = useState(false);
    const [deleteYear, setDeleteYear] = useState({ year: '', tax_id: '' });
    const { isLoading, data } = useFormsList({
        irs_account_id: irs_account_id,
    });

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    //to get the complete percentage for tax resolution
    const completePercentage = useCompletedPercentage(irs_account_id);

    if (isLoading) {
        return (
            <div>
                {!iframe && <NavBar />}
                <div
                    style={{ alignItems: 'center' }}
                    className="h-screen flex justify-center text-justify align-middle"
                >
                    <div>Loading...</div>
                </div>
            </div>
        );
    }

    //to get the service type text to show on screen
    const serviceType = data && JSON.parse(data?.u_service_type);
    const getServiceType = () => {
        const text1 = serviceType?.includes('tax_resolution') ? 'Tax Resolution' : '';
        const text2 = serviceType?.includes('tax_preparation') ? 'Tax Preparation' : '';

        return text1 && text2 ? `${text1} , ${text2}` : text1 || text2;
    };

    return (
        <>
            <div className="flex flex-col w-full h-screen overflow-y-scroll bg-gray-100">
                {!iframe && <NavBar />}
                <div className="w-full h-full" style={{ paddingLeft: '4%', paddingRight: '4%' }}>
                    <div
                        className={`grid mt-16 ${
                            data?.Taxpro.length &&
                            serviceType?.includes('tax_preparation') &&
                            build.taxprep_module
                                ? 'grid-cols-1 xl:grid-cols-3 lg:grid-cols-2'
                                : 'grid-cols-2'
                        } `}
                    >
                        <div className="col-span-1">
                            <div className="font-title ml-3 text-2xl font-semibold text-gray-400 ">
                                Hello, {data?.u_first_name + ' ' + data?.u_last_name}
                            </div>
                            <div className=" ml-3 bg-white rounded-lg shadow-sm mt-5 p-4 relative">
                                {/* Edit Icon */}
                                <span
                                    onClick={() => setEditClient(true)}
                                    className="cursor-pointer font-bold bg-gray-100 p-1 rounded-full material-symbols-outlined text-gray-500 hover:text-primary-400 absolute top-2 right-2"
                                >
                                    edit
                                </span>

                                {/* Client Information */}
                                <div className="grid mb-4 mt-2 grid-cols-3">
                                    <div className="col-span-3 mb-3">
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">
                                            First Name
                                        </label>
                                        <div className="bg-gray-100 mr-2 h-10 pl-4 pt-2 rounded-lg text-md  text-gray-600">
                                            {data?.u_first_name}
                                        </div>
                                    </div>
                                    <div className="col-span-3 mb-3">
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">
                                            Last Name
                                        </label>
                                        <div className="bg-gray-100 mr-2 h-10 pl-4 pt-2 rounded-lg text-md  text-gray-600">
                                            {data?.u_last_name}
                                        </div>
                                    </div>
                                    <div className="col-span-3 mb-3">
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">
                                            Email
                                        </label>
                                        <div className="bg-gray-100 mr-2 h-10 pl-4 pt-2 rounded-lg text-md text-gray-600">
                                            {data?.u_email}
                                        </div>
                                    </div>
                                    <div className="col-span-3 mb-3">
                                        <label className="block text-sm font-semibold text-gray-600 mb-2">
                                            Assigned to
                                        </label>
                                        <div className="bg-gray-100 mr-2 h-10 pl-4 pt-2 rounded-lg text-md text-gray-600">
                                            {data?.taxpro}
                                        </div>
                                    </div>
                                    {build.taxprep_module && (
                                        <div className="col-span-3 mb-3">
                                            <label className="block text-sm font-semibold text-gray-600 mb-2">
                                                Service Type
                                            </label>
                                            <div className="bg-gray-100 mr-2 h-10 pl-4 pt-2 rounded-lg text-md text-gray-600">
                                                {getServiceType()}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {data?.Taxpro.length ? (
                            <div className="col-span-1 flex justify-start my-5 lg:my-0 lg:justify-center">
                                <div>
                                    <div className="font-title ml-3 text-2xl font-semibold text-gray-400">
                                        Tax Resolution
                                    </div>
                                    {data?.Taxpro.map((data: any) => {
                                        return (
                                            <div className="ml-3 bg-white rounded-lg shadow-sm w-96 mt-5 ">
                                                <div className="flex w-full items-end p-4">
                                                    <div className="font-title font-medium flex-grow">
                                                        Tax Profile
                                                    </div>
                                                    <div className="font-body font-medium text-sm">{`${completePercentage}% Completed`}</div>
                                                </div>
                                                <div className="w-full relative">
                                                    <div className="h-2 bg-gray-200 w-full" />
                                                    <div
                                                        className="absolute left-0 top-0 h-2 bg-primary-500"
                                                        style={{ width: `${completePercentage}%` }}
                                                    />
                                                </div>

                                                <div className="w-full p-4 mt-1">
                                                    <div className="font-body text-sm">{`Last Update: ${moment(
                                                        data?.last_updated_date,
                                                    ).format('DD MMM YYYY, hh:mm a')}`}</div>
                                                    <div className="font-body">
                                                        {meta_data?.section_status}
                                                    </div>
                                                </div>

                                                <div className="w-full  flex">
                                                    <Link
                                                        to={`/evaluate/${irs_account_id}?scroll=${'basic_information'}`}
                                                        className="text-left flex-grow hover:bg-gray-50 text-primary-500 p-4"
                                                    >
                                                        <div className="font-title font-medium ">{`CONTINUE`}</div>
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {
                            <div className="col-span-1">
                                {build.taxprep_module &&
                                    serviceType?.includes('tax_preparation') && (
                                        <>
                                            <div className="flex gap-5">
                                                <div className="font-title ml-3 text-2xl font-semibold text-gray-400">
                                                    Tax Prep
                                                </div>
                                                <div className="font-title text-xl font-medium text-gray-600 ">
                                                    <Button
                                                        onClick={() => setModal(true)}
                                                        className="text-sm text-center w-32"
                                                    >
                                                        {`ADD YEAR`}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div
                                                style={{ maxHeight: '800px', overflowY: 'auto' }}
                                                className="flex flex-col overflow-y-scroll"
                                            >
                                                {data?.taxprep.length
                                                    ? data?.taxprep.map(
                                                          (card: any, key: number) => {
                                                              return (
                                                                  <div
                                                                      key={key}
                                                                      className="ml-3 justify-between rounded-lg shadow-sm bg-white w-full sm:w-96 mt-5 "
                                                                  >
                                                                      <div className="flex w-full items-end p-4">
                                                                          <div className="font-title font-medium flex-grow">
                                                                              Year
                                                                              {card.taxprep_year}
                                                                          </div>
                                                                          <div className="font-body font-medium text-sm">{`${card?.percentage_complete}% Completed`}</div>
                                                                      </div>
                                                                      <div className="w-full relative">
                                                                          <div className="h-2 bg-gray-200 w-full" />
                                                                          <div
                                                                              className="absolute left-0 top-0 h-2 bg-primary-500"
                                                                              style={{
                                                                                  width: `${card?.percentage_complete}%`,
                                                                              }}
                                                                          />
                                                                      </div>

                                                                      <div className="w-full flex justify-between p-4 mt-1">
                                                                          <div>
                                                                              <div className="font-body text-sm">{`Last Updated: ${moment(
                                                                                  card?.last_updated_date,
                                                                              ).format(
                                                                                  'DD MMM YYYY, hh:mm a',
                                                                              )}`}</div>
                                                                              <div className="font-body">
                                                                                  {
                                                                                      meta_data?.section_status
                                                                                  }
                                                                              </div>
                                                                          </div>
                                                                          <div>
                                                                              <span
                                                                                  className="cursor-pointer  material-icons text-gray-400 hover:text-red-400"
                                                                                  onClick={() => {
                                                                                      setdeleteModal(
                                                                                          true,
                                                                                      );
                                                                                      setDeleteYear(
                                                                                          {
                                                                                              year: card.taxprep_year,
                                                                                              tax_id: card.id,
                                                                                          },
                                                                                      );
                                                                                  }}
                                                                              >
                                                                                  delete_forever
                                                                              </span>
                                                                          </div>
                                                                      </div>

                                                                      <div className="w-full flex flex-col sm:flex-row">
                                                                          <div className="text-left flex-grow hover:bg-gray-50 text-primary-500 p-4 cursor-pointer">
                                                                              <div
                                                                                  className="font-title font-medium "
                                                                                  onClick={() => {
                                                                                      navigate(
                                                                                          `/taxprep-evaluate/${irs_account_id}/${card.taxprep_year}`,
                                                                                      );
                                                                                      sessionStorage.setItem(
                                                                                          'tax_id',
                                                                                          card.id,
                                                                                      );
                                                                                  }}
                                                                              >{`CONTINUE`}</div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              );
                                                          },
                                                      )
                                                    : ''}
                                            </div>
                                        </>
                                    )}
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* modals for add, edit, delete  */}
            <Modal close={() => setModal(false)} isOpen={!!modal}>
                <AddYearCard user={irs_account_id} close={() => setModal(false)} />
            </Modal>
            <Modal close={() => setdeleteModal(false)} isOpen={!!deleteModal}>
                <DeleteYearCard
                    deleteYear={deleteYear}
                    user={irs_account_id}
                    close={() => setdeleteModal(false)}
                />
            </Modal>
            <Modal close={() => setEditClient(false)} isOpen={editClient}>
                <EditClient close={() => setEditClient(false)} data={data} user_id={data?.u_id!} />
            </Modal>
        </>
    );
};

export default TPNavigator;
