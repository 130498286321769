import { upsertAdditionalIncome } from '../../api/finance';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const additionalIncomeForm: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'income_employee',
        label: 'For',
        placeholder: 'Who is this for',
        span: 2,
        options: [
            { value: 'taxpayer', label: 'Taxpayer' },
            { value: 'spouse', label: 'Spouse' },
            { value: 'dependent', label: 'Dependent' },
        ],
    },
    // {
    //     type: fieldTypes.DROPDOWN,
    //     id: 'income_type',
    //     label: 'Source',
    //     options: [
    //         { value: 'Stated', label: 'Stated' },
    //         { value: 'Pay Stub', label: 'Pay Stub' },
    //         { value: 'Return', label: 'Return' },
    //         { value: 'W+I', label: 'W+I' },
    //         { value: 'Other', label: 'Other' },
    //     ],
    //     placeholder: 'Stated',
    // },
    {
        type: fieldTypes.DROPDOWN,
        id: 'income_type',
        label: 'Type',
        options: [
            { value: 'alimony', label: 'Alimony' },
            { value: 'child_support', label: 'Child Support' },
            { value: 'net_business', label: 'Net Self Employment' },
            { value: 'net_rental', label: 'Net Rental' },
            { value: 'pension', label: 'Pension' },
            { value: 'interest_dividends', label: 'Interest Dividends' },
            { value: 'social_security', label: 'Social Security' },
            { value: 'other', label: 'Other' },
        ],
        placeholder: 'Retired',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'income_value',
        label: 'Amount/Month',
        placeholder: '$600',
    },
    // {
    //     type: fieldTypes.TEXT_INPUT,
    //     area: true,
    //     id: 'description',
    //     label: 'Description',
    //     placeholder: '$800 pension for working as a railway driver for 12 years',
    // },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'additional_income_data',
        messages: [
            {
                id: 'income_type',
                value: 'net_business',
                message:
                    '*"Net Self Employment" will only be considered if no Income is added in the Business section. Otherwise, the income calculated from the Business section will be used in the forms.',
            },
        ],
        label: 'Do you have any Additional Income Sources?',
        formStructure: additionalIncomeForm,
        displayColumns: [
            {
                name: 'Income Source',
                key: 'income_employee',
            },
            {
                name: 'Income Type',
                key: 'income_type',
            },
            {
                name: 'Amount/Month',
                key: 'income_value',
            },
        ],
        header: 'Additional Income Details',
    },
];

export const page: PageInputInterface = {
    address: 'additonal_income',
    name: 'Additional Income',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertAdditionalIncome,
};
