import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { HeaderGroup, useSortBy, useTable } from 'react-table';
import { build } from '../config';

const PAGE_LIMIT = 20;

export const PageManager: React.FC<{
    total: number;
    offset: number;
    setOffset: (offset: number) => void;
}> = ({ total, offset, setOffset }) => {
    const [pageIndex, setPageIndex] = useState<number>(offset / PAGE_LIMIT + 1);

    const pageCount = Math.ceil(total / PAGE_LIMIT);
    const canPreviousPage = pageIndex > 1;
    const canNextPage = pageIndex < pageCount;

    const gotoPage = (p: number) => setPageIndex(p);
    const previousPage = () => setPageIndex((p) => p - 1);
    const nextPage = () => setPageIndex((p) => p + 1);

    useEffect(() => {
        if (offset !== (pageIndex - 1) * PAGE_LIMIT) {
            setOffset((pageIndex - 1) * PAGE_LIMIT);
        }
    }, [pageIndex]);

    return (
        <div className="h-12 w-full flex justify-center items-center">
            <span
                className={classNames(
                    'mx-1 cursor-pointer hover:bg-gray-100 material-icons border border-gray-400 rounded-full transform rotate-180',
                    { 'opacity-50': !canPreviousPage },
                )}
                onClick={() => canPreviousPage && gotoPage(1)}
            >
                skip_next
            </span>
            <span
                className={classNames(
                    'mx-1 cursor-pointer hover:bg-gray-100 material-icons border border-gray-400 rounded-full transform rotate-180',
                    { 'opacity-50': !canPreviousPage },
                )}
                onClick={() => canPreviousPage && previousPage()}
            >
                navigate_next
            </span>
            <span className="px-3">
                Page
                <strong className="ml-1">
                    {pageIndex} of {pageCount}
                </strong>
            </span>
            <span
                className={classNames(
                    'mx-1 cursor-pointer hover:bg-gray-100 material-icons border border-gray-400 rounded-full',
                    { 'opacity-50': !canNextPage },
                )}
                onClick={() => canNextPage && nextPage()}
            >
                navigate_next
            </span>
            <span
                className={classNames(
                    'mx-1 cursor-pointer hover:bg-gray-100 material-icons border border-gray-400 rounded-full',
                    { 'opacity-50': !canNextPage },
                )}
                onClick={() => canNextPage && gotoPage(pageCount)}
            >
                skip_next
            </span>
        </div>
    );
};

export const Table: React.FC<{
    data: any[];
    columns: any[];
    isSortEnabled?: boolean;
    sortDirection?: string;
    sortByColumn?: (props: HeaderGroup<object>) => void;
    onRowClick?: (data: any) => void;
}> = ({ data, columns, onRowClick, sortByColumn, isSortEnabled }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            // manualSortBy: true,
        },
        useSortBy,
    );
    console.log('rows', rows);
    return (
        <div className="overflow-y-auto h-full px-0.5">
            <table {...getTableProps()} className="w-full">
                <thead className="sticky top-0">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                const col = columns.find(({ Header }) => Header === column.Header);
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        className={classNames(
                                            'bg-gray-700 px-2 py-1 font-title font-normal text-left text-white',
                                            {
                                                'cursor-pointer':
                                                    build.table_sorting && isSortEnabled,
                                            },
                                        )}
                                        onClick={() =>
                                            col.sortable !== false &&
                                            build.table_sorting &&
                                            sortByColumn
                                                ? sortByColumn(column)
                                                : console.error(
                                                      'sortByColumn function is not provided in Table component',
                                                  )
                                        }
                                    >
                                        {column.render('Header')}
                                        {build.table_sorting && (
                                            <span>
                                                {col.sortable !== false &&
                                                    column.Header !== 'Actions' &&
                                                    column.Header !== 'Businesses' &&
                                                    column.Header !== 'Read / Unread' && (
                                                        <span className="cursor-pointer">
                                                            {isSortEnabled ? ' ↑' : ' ↓'}
                                                        </span>
                                                    )}
                                            </span>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rIndex) => {
                        prepareRow(row);
                        return (
                            <tr
                                onClick={() => onRowClick && onRowClick(row.original)}
                                {...row.getRowProps()}
                                className={classNames('hover:bg-gray-50 p-1', {
                                    'cursor-pointer': onRowClick,
                                })}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="px-2 py-1 border border-gray-300"
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
