import { upsertVehicles } from '../../api/finance';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const vehiclesForm: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'owner',
        label: 'Owner',
        placeholder: 'John Smith',
        options: [
            { value: 'Taxpayer', label: 'Taxpayer' },
            { value: 'Spouse', label: 'Spouse' },
            { value: 'Both', label: 'Both' },
        ],
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'type',
        label: 'Type',
        placeholder: 'Car',
        options: [
            { value: 'Loan', label: 'Loan' },
            { value: 'Leased', label: 'Leased' },
            { value: 'Owned', label: 'Owned' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'make',
        label: 'Make',
        placeholder: '1976',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'model',
        label: 'Model',
        placeholder: '23 Aug 2021',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'year',
        label: 'Year',
        placeholder: '$33,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'mileage',
        label: 'Mileage',
        placeholder: '$500,000',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.CALENDAR,
        id: 'purchase_lease_date',
        label: 'Purchase Lease Date',
        placeholder: '$250,000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'payment_amount',
        label: 'Payment Amount',
        placeholder: '$300,000',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'pay_off_date',
        label: 'Pay off Date',
        placeholder: '25 June 2022',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_name',
        label: 'Lender / Lessor Name',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_phone',
        label: 'Lender Phone',
        placeholder: 'Florida',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Lender Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'lender_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'license_tag_number',
        label: 'Tag Number',
        placeholder: '123 AB4',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'vin',
        label: 'VIN',
        placeholder: '$200',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_market_value',
        label: 'Market Value',
        placeholder: '$30,000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_loan_balance',
        label: 'Current Loan Balance',
        placeholder: '20',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'vehicle_data',
        label: 'Do you own any vehicles?',
        header: 'Vehicle Details',
        formStructure: vehiclesForm,
        displayColumns: [
            {
                name: 'Vehicle Type',
                key: 'type',
            },
            {
                name: 'Tage Number',
                key: 'license_tag_number',
            },
            {
                name: 'VIN',
                key: 'vin',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'vehicles',
    name: 'Vehicles',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertVehicles,
};
