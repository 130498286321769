import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { resendUser } from '../../api/admin';
import { useAuthState } from '../../context/auth';
import { fieldTypes } from '../../utils/enums';
import Button from './Button';
import InputGroup from '../client/InputGroup';
import { showToast } from './Toaster';

const ResendCredentials: React.FC<{
    close: () => void;
    user: any;
    role: 'Admin' | 'Client' | 'Tax Preparer';
}> = ({ close, user, role }) => {
    const [email, setEmail] = useState<string>(user.email_address || user.u_email);
    const [firstName, setFirstName] = useState<string>(user.first_name || user.u_first_name);
    const [lastName, setlastName] = useState<string>(user.last_business_name || user.u_last_name);
    const [error, setErrors] = useState<{ [key: string]: any }>({});

    const queryClient = useQueryClient();
    const { token } = useAuthState();

    const mutation = useMutation(resendUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('clients-list');
            showToast('Credentials has been Sent !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const resendClient = () => {
        if (!token) return;

        mutation.mutate({
            u_id: user.user_id,
            u_email: email,
            u_first_name: firstName,
            u_last_name: lastName,
            role_name: role,
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Resend Client Credentials`}</div>

            <div className="flex flex-col w-full">
                <InputGroup
                    id="email"
                    label="Email"
                    placeholder=""
                    value={email}
                    setValue={(value) => {
                        setEmail(value);
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    staticError={error.u_email}
                />

                <InputGroup
                    id="firstName"
                    label="First Name"
                    placeholder=""
                    value={firstName}
                    setValue={(value) => {
                        setFirstName(value);
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    staticError={error.u_first_name}
                />

                <InputGroup
                    id="lastName"
                    label="Last Name"
                    placeholder=""
                    value={lastName}
                    setValue={(value) => {
                        setlastName(value);
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    staticError={error.u_last_name}
                />

                <Button
                    isLoading={mutation.isLoading}
                    onClick={resendClient}
                    className="text-center w-full mt-8"
                >
                    {'RESEND CREDENTIALS'}
                </Button>
            </div>
        </div>
    );
};

export default ResendCredentials;
