/**
 * Saperator UI Component for seperation
 **/

import classNames from 'classnames';
import { interface_seperator } from '../../utils/interfaces';
import HeadingGroup from './HeadingGroup';
import ParentGroup from './ParentGroup';

type SeperatorGroupProps = interface_seperator & {
    className?: string;
    disabled?: boolean;
};

const SeperatorGroup: React.FC<SeperatorGroupProps> = (props) => {
    const { className, title, heading, disabled } = props;

    return (
        <>
            <div className={classNames('w-full mt-10 col-span-2', className)}>
                {title && <HeadingGroup label={title} />}
            </div>
            {heading && (
                <>
                    <ParentGroup className={classNames('col-span-2 px-3')}>
                        <div className="font-title text-sm font-semibold">{heading}</div>
                    </ParentGroup>
                </>
            )}
        </>
    );
};

export default SeperatorGroup;
