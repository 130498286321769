/**
 * UI for Crossroad decider
 */
import classNames from 'classnames';
import ParentGroup from './ParentGroup';
import {
    interface_option,
    interface_field_crossroad,
    FormStateInterface,
} from '../../utils/interfaces';
import { useEffect, useState } from 'react';
import { enumSections, fieldTypes } from '../../utils/enums';
import SeperatorGroup from './SeperatorGroup';
import { useFormDispatch, useFormState } from '../../context/form';
import FormConstructor from '../basic/FormConstructor';
import { useLightHouse } from '../../utils/helpers';
import moment from 'moment';
import { build } from '../../config';
import Modal from '../basic/Modal';
import Button from '../basic/Button';

type CrossroadProps = interface_field_crossroad & {
    className?: string;
    value: string | number;
    formState: FormStateInterface;
    disabled?: boolean;
    setFormState: ({ id, value }: any) => void;
};

type FormDeterminerProps = interface_field_crossroad & {
    id: string;
    pathValue: string;
    header?: string;
    identifier?: string;
    formStructure: any;
    displayColumns: Array<{ name: string; key: string; span?: number }>;
    disabled?: boolean;
    formState: FormStateInterface;
    setFormState: ({ id, value }: any) => void;
};

const defaultOptions: Array<interface_option> = [
    { label: 'No', value: 'no' },
    { label: 'Yes, Only one', value: 'one' },
    { label: 'Yes, Multiple', value: 'multiple' },
];

const SelectOneModal: React.FC<{
    value: any[];
    displayColumns: any[];
    resetAndClose: () => void;
    saveAndClose: (saveRow: any) => void;
}> = ({ value, displayColumns, resetAndClose, saveAndClose }) => {
    const [saveRow, setSaveRow] = useState();

    return (
        <div
            className="bg-white w-full max-w-4xl flex flex-col items-center "
            style={{ maxHeight: '95%', minHeight: '40%' }}
        >
            <div className="flex font-title text-lg font-medium px-5 py-3 w-full text-left bg-gray-100">
                <div className="flex-grow">{'Multiple Rows Found'}</div>
            </div>

            <div className="flex font-body px-5 pt-4 pb-1 w-full text-left">
                <div className="flex-grow">{`You have ${value.length} rows added in multiple. Only one row value can be saved when shifting from multiple to one`}</div>
            </div>

            <div className="flex font-body px-5 py-1 w-full text-left">
                <div className="flex-grow">{`Select the data row you wish to save`}</div>
            </div>
            <div className="px-5 py-2 mt-2 w-full flex-grow">
                <div className="w-full grid grid-cols-3 bg-gray-600">
                    {/**
                     *Title row
                     */}
                    {displayColumns.map(({ name }) => {
                        return (
                            <div className={classNames('font-semibold text-white px-1 text-sm')}>
                                {name}
                            </div>
                        );
                    })}
                    <div></div>
                </div>

                {value.map((item) => {
                    const isMatch = saveRow?.['id'] === item?.id;
                    return (
                        <div
                            onClick={() => setSaveRow(isMatch ? undefined : item)}
                            className={classNames(
                                'w-full grid grid-cols-3 border border-gray-100 rounded-sm py-1.5 cursor-pointer ',
                                {
                                    'bg-primary-500 hover:bg-primary-700': isMatch,
                                    'hover:bg-gray-100': !isMatch,
                                },
                            )}
                        >
                            {/**
                             *Title row
                             */}
                            {displayColumns.map(({ key }) => {
                                return (
                                    <div
                                        className={classNames('px-1 text-sm', {
                                            'text-white font-semibold': isMatch,
                                        })}
                                    >
                                        {item[key]}
                                    </div>
                                );
                            })}
                            <div></div>
                        </div>
                    );
                })}
            </div>

            {!saveRow ? (
                <div className="bg-gray-50 w-full px-5 mt-5 pt-3">
                    <div className="flex w-full items-center justify-end  pt-2 pb-3">
                        <Button
                            onClick={() => resetAndClose()}
                            secondary
                            className="w-1/4 mr-1 bg-white"
                        >{`CANCEL`}</Button>
                    </div>
                </div>
            ) : (
                <div className="bg-gray-300 w-full px-5 mt-5 pt-3">
                    <div className="flex w-full items-center justify-center  pt-2 pb-3">
                        <div className="flex-grow">
                            <div className="font-semibold">{`Confirm data row?`}</div>
                            <div className="text-sm">{`All the remaining rows will be removed`}</div>
                        </div>
                        <Button
                            onClick={() => resetAndClose()}
                            secondary
                            className="w-1/4 mr-1 bg-white"
                        >{`CANCEL`}</Button>
                        <Button onClick={() => saveAndClose(saveRow)} className="w-1/4 ml-1">
                            {'SAVE'}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const FormDeterminer: React.FC<FormDeterminerProps> = ({
    id,
    pathValue,
    header,
    identifier = 'id',
    formStructure: rowStructure,
    setFormState,
    formState,
    displayColumns,
    dynamicSave,
    preForm,
    disabled,
}) => {
    const formValue =
        formState[id]?.map((item: any) => ({
            ...item,
            _errors: formState._errors?.[id]?.[item.id],
        })) || {};

    const setOne = ({ id: fieldId, value }: any) => {
        let put_value = [{ [identifier]: `new_${moment.now()}`, [fieldId]: value }];

        if (Array.isArray(formValue) && formValue?.length) {
            put_value = formValue.map((item: any, index: number) => {
                if (index === 0) return { ...item, [fieldId]: value };
                else return { action: 'delete', ...item };
            });
        }

        setFormState({ id, value: put_value });
    };

    const setMany = ({ value }: any) => {
        setFormState({ id, value: value });
    };

    switch (pathValue) {
        case 'no':
            return (
                <FormConstructor
                    disabled={disabled}
                    structure={[{ type: fieldTypes.TERMINAL, noAction: true }]}
                    formState={{}}
                    setFormState={() => {}}
                />
            );

        case 'one':
            return (
                <>
                    <FormConstructor
                        disabled={disabled}
                        structure={[
                            { type: fieldTypes.SEPERATOR, title: header },
                            ...(preForm || []),
                            { type: fieldTypes.SEPERATOR },
                        ]}
                        formState={formState}
                        setFormState={setFormState}
                    />
                    <FormConstructor
                        disabled={disabled}
                        structure={[...rowStructure, { type: fieldTypes.TERMINAL }]}
                        formState={formValue ? formValue[0] : {}}
                        setFormState={setOne}
                    />
                </>
            );

        case 'multiple':
            return (
                <>
                    <FormConstructor
                        disabled={disabled}
                        structure={[
                            { type: fieldTypes.SEPERATOR, title: header },
                            ...(preForm || []),
                            { type: fieldTypes.SEPERATOR },
                        ]}
                        formState={formState}
                        setFormState={setFormState}
                    />
                    <FormConstructor
                        disabled={disabled}
                        structure={[
                            {
                                type: fieldTypes.TABLE,
                                id,
                                displayColumns,
                                rowStructure,
                                identifier,
                                dynamicSave,
                                header,
                            },
                            { type: fieldTypes.TERMINAL },
                        ]}
                        formState={formState}
                        setFormState={setMany}
                    />
                </>
            );

        default:
            return (
                <>
                    <SeperatorGroup type={fieldTypes.SEPERATOR} />
                    <div className="flex flex-col col-span-2 w-full items-center">
                        <div className="w-full h-0.5 bg-gray-200"></div>
                        <div className="text-sm text-gray-300 font-semibold mt-5">{`Answer to continue`}</div>
                    </div>
                </>
            );
    }
};

const Crossroad: React.FC<CrossroadProps> = (props) => {
    const {
        id,
        displayColumns,
        formStructure,
        label,
        className,
        options: providedOptions,
        setFormState,
        formState,
        preForm,
        disabled,
    } = props;

    const options = providedOptions || defaultOptions;

    const formDispatch = useFormDispatch();
    const universalformState = useFormState();
    const { businessId } = useLightHouse();

    const pathValue = businessId
        ? universalformState.meta_data?.form_status[businessId]
            ? universalformState.meta_data?.form_status[businessId][id + '_count']
            : undefined
        : universalformState.meta_data?.form_status[id + '_count'];

    const currentOption = options.find((o) => o.value === pathValue) as interface_option;

    const [lastSelectedOption, setLastSelctedOption] = useState<interface_option | undefined>(
        currentOption,
    );

    useEffect(() => {
        if (currentOption !== undefined) {
            setLastSelctedOption(currentOption);
        }
    }, [currentOption]);

    const setOptionValue = ({ value, saveRow }: { value: string | undefined; saveRow?: any }) => {
        if (businessId) {
            formDispatch({
                type: 'UPDATE_CROSSROAD_BUSINESS',
                payload: { businessId, data: { [id + '_count']: value } },
            });
        } else {
            formDispatch({
                type: 'UPDATE_CROSSROAD',
                payload: { data: { [id + '_count']: value } },
            });
        }

        let updatedData: any;
        switch (value) {
            case 'no':
            case undefined:
                updatedData = formState[id]?.map((item: any) => {
                    return { action: 'delete', ...item };
                });
                break;

            case 'one':
                updatedData = formState[id]?.map((item: any, index: number) => {
                    if (saveRow?.['id'] == item.id) {
                        const { action, ...original } = item;
                        return original;
                    } else return { action: 'delete', ...item };
                });
                break;

            case 'multiple':
                updatedData = formState[id]?.map((item: any) => {
                    const { action, ...original } = item;
                    return original;
                });
                break;

            default:
                updatedData = formState[id];
        }

        if (id === 'business_data') {
            formDispatch({
                type: 'UPDATE_FORM',
                payload: { data: { business_data: updatedData } },
            });
        } else {
            setFormState({ id, value: updatedData });
        }
    };

    const [selectOneModal, setSelectOneModal] = useState<boolean>(false);

    const formValue =
        formState[id]?.map((item: any) => ({
            ...item,
            _errors: formState._errors?.[id]?.[item.id],
        })) || [];

    const resolveOptionValue = (option: interface_option) => {
        const { value: optionValue } = option;

        if (lastSelectedOption?.value === 'multiple' && optionValue === 'one') {
            if (formValue?.length > 1) {
                setSelectOneModal(true);
                return;
            }
        }

        if (pathValue === optionValue) {
            setOptionValue({ value: undefined });
        } else {
            setOptionValue({ value: optionValue });
        }
    };

    return (
        <ParentGroup className={classNames('mt-16 col-span-2', className)}>
            <div className="w-full grid grid-cols-5">
                <div className="col-span-2 text-right pr-4">{label}</div>
                <div className="col-span-3">
                    {!pathValue ? (
                        options.map((option: interface_option) => {
                            const { label: optionLabel, value: optionValue } = option;

                            return (
                                <div
                                    onClick={() => !disabled && resolveOptionValue(option)}
                                    className={classNames(
                                        'border flex h-10 items-center border-gray-100 focus:outline-none px-3 py-1 mb-2  w-full cursor-pointer',
                                        {
                                            'bg-gray-100 hover:bg-gray-200': !disabled,
                                            'bg-gray-400': disabled,
                                        },
                                    )}
                                >
                                    <div className="flex items-center">
                                        <div
                                            className="h-4 w-4 bg-white rounded-3xl mr-2 border border-gray-300 p"
                                            style={{ padding: 1 }}
                                        />

                                        <div>{optionLabel}</div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className=" flex flex-col items-end w-full ">
                            <div className="border h-10 flex focus:outline-none items-center text-white font-medium px-3 w-full py-1 mb-2 cursor-pointer bg-primary-500 hover:bg-primary-600 border-primary-500">
                                <img
                                    src={build.checkMarkLogo}
                                    className={classNames('h-4 w-4 bg-white rounded-3xl mr-2  ')}
                                    style={{ padding: 1 }}
                                />

                                <div>{currentOption?.label}</div>
                            </div>

                            <div
                                onClick={() => !disabled && setOptionValue({ value: undefined })}
                                className={classNames('cursor-pointer text-xs font-semibold', {
                                    'text-primary-500': !disabled,
                                    'text-gray-500': disabled,
                                })}
                            >
                                CHANGE RESPONSE
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {formStructure && displayColumns && (
                <FormDeterminer {...{ ...props, disabled }} pathValue={pathValue} />
            )}

            <Modal isOpen={selectOneModal} close={() => setSelectOneModal(false)}>
                <SelectOneModal
                    value={formValue}
                    displayColumns={displayColumns}
                    resetAndClose={() => setSelectOneModal(false)}
                    saveAndClose={(saveRow) => {
                        setOptionValue({ value: 'one', saveRow });
                        setSelectOneModal(false);
                    }}
                />
            </Modal>
        </ParentGroup>
    );
};

export default Crossroad;
