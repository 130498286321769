import { upsertOutstandingAmount } from '../../api/business';
import { upsertSafeDepositBox } from '../../api/other';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const partyForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'related_party_name',
        label: 'Name',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Related Party Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'related_party_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'related_party_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'related_party_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'related_party_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.CALENDAR,
        id: 'loan_date',
        label: 'Date of Loan',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_loan_balance',
        label: 'Current Balance As of',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'payment_date',
        label: 'Payment Date',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'payment_amount',
        label: 'Payment Amount',
        placeholder: 'johndoe@hotmail.com',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        label: 'Do any related parties (e.g., officers, partners, employees) have outstanding amounts owed to the business?',
        id: 'business_outstanding_amount_owed_data',
        header: 'Outstanding Amount Owed',
        formStructure: partyForm,
        displayColumns: [
            {
                name: 'Party Name',
                key: 'related_party_name',
            },
            {
                name: 'Date of Loan',
                key: 'loan_date',
            },
            {
                name: 'Payment Amount',
                key: 'payment_amount',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'outstanding_amounts_owed',
    name: 'Business Outstanding Amount Owed',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertOutstandingAmount,
};
