import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from 'react-query';

/* eslint-disable import/no-anonymous-default-export */

import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { transcriptApi } from '../../../../api/transcripts';
import useTranscript from '../../../../api/hooks/useTranscript';
import { Card1, Card2, Card3, Card4, Income } from './TaxometricCards';
import { useState } from 'react';

export const UserTaxometrics: React.FC<{
    setTranscriptTab: (tab: number) => void;
    transcriptTab: number;
}> = ({ setTranscriptTab, transcriptTab }) => {
    let { irs_account_id, type } = useParams();
    const [errMessage, setErrMessage] = useState<string | null>(null);
    const fetchTaxometricData = async (irs_account_id: string | undefined) => {
        try {
            const response = await transcriptApi.get(
                `/getIrsAccountInfoData?irs_account_id=${irs_account_id}&taxpayer_type=${type}`,
            );
            console.log(response, 'response');
            return response.data;
        } catch (err: any) {
            await handleError(err);
            throw err;
        }
    };

    const { data: taxometricData, isLoading: taxometricLoading } = useQuery(
        ['taxometrics', irs_account_id, type],
        () => fetchTaxometricData(irs_account_id),
        {
            enabled: !!irs_account_id,
        },
    );
    async function handleError(err: any) {
        if (err.response.status == 404) {
            setErrMessage(err.response.data.error);
            console.log('Error fetching taxometric data:', err.response);
        }
    }
    console.log('taxometrics', errMessage);
    const { t } = useTranslation();

    // if (errMessage) {
    //     return <div className="p-6 border-0 border-[#CCCCCC] pb-10  w-full ">{errMessage}</div>;
    // } else if (taxometricLoading) {
    //     return <div className="p-6 border-0 border-[#CCCCCC] pb-10  w-full ">Loading...</div>;
    // }
    return (
        <div className="p-6 border-0 border-[#CCCCCC] pb-10 w-full">
            {errMessage ? (
                <div>{errMessage}</div>
            ) : taxometricLoading ? (
                <div>Loading...</div>
            ) : taxometricData ? (
                <>
                    <div className="grid grid-cols-4 gap-7 w-full">
                        <Card3
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Tax Return Status'}
                            data={taxometricData?.filingStatus ? taxometricData?.filingStatus : []}
                        />
                        <Income
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            data={taxometricData?.Income}
                        />
                        <Card2
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Levies'}
                            data={taxometricData?.levyYear ? taxometricData?.levyYear : []}
                        />
                        <Card2
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Liens'}
                            data={taxometricData?.lienYear ? taxometricData?.lienYear : []}
                        />
                        <Card3
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'IRS Debt Actions'}
                            data={
                                taxometricData?.irs_debt_actions
                                    ? taxometricData?.irs_debt_actions
                                    : []
                            }
                        />

                        <Card2
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Audits'}
                            data={taxometricData?.audits ? taxometricData?.audits : []}
                        />

                        <Card2
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Passport Revocation'}
                            data={
                                taxometricData?.PassportRevocation
                                    ? taxometricData?.PassportRevocation
                                    : []
                            }
                        />
                        <Card2
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Private Collections'}
                            data={
                                taxometricData?.PrivateCollection
                                    ? taxometricData?.PrivateCollection
                                    : []
                            }
                        />
                    </div>

                    <div className="w-full flex justify-center py-6">
                        <span className="w-80 border-b-2 border-dashed border-primary-200"></span>
                    </div>

                    <div className="grid grid-cols-4 gap-7 w-full">
                        <Card1
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Total Balance Due'}
                            titles={[
                                taxometricData?.TotalBalanceDue?.total_balance_due?.label || '',
                                taxometricData?.TotalBalanceDueYears?.years_with_balances?.label ||
                                    '',
                            ]}
                            total={taxometricData?.TotalBalanceDue?.total_balance_due?.value}
                            years={
                                taxometricData?.TotalBalanceDueYears?.years_with_balances?.value
                                    ? taxometricData?.TotalBalanceDueYears?.years_with_balances
                                          ?.value
                                    : []
                            }
                            missingInfo={taxometricData?.TotalBalanceDue?.missing_information}
                            color={taxometricData?.TotalBalanceDue?.color}
                        />
                        <Card1
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Total Penalties'}
                            titles={[
                                taxometricData?.TotalPenalties?.total_penalties?.label || '',
                                taxometricData?.TotalPenaltiesYears?.years_with_penalties?.label ||
                                    '',
                            ]}
                            total={taxometricData?.TotalPenalties?.total_penalties?.value}
                            years={
                                taxometricData?.TotalPenaltiesYears?.years_with_penalties?.value
                                    ? taxometricData?.TotalPenaltiesYears?.years_with_penalties
                                          ?.value
                                    : []
                            }
                            missingInfo={taxometricData?.TotalPenalties?.missing_information}
                            color={taxometricData?.TotalPenalties?.color}
                        />
                        <Card1
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Total Interest'}
                            titles={[
                                taxometricData?.TotalInterestDue?.total_interest?.label || '',
                                taxometricData?.TotalInterestDueYears?.years_with_interest?.label ||
                                    '',
                            ]}
                            total={taxometricData?.TotalInterestDue?.total_interest?.value}
                            years={
                                taxometricData?.TotalInterestDueYears?.years_with_interest?.value
                                    ? taxometricData?.TotalInterestDueYears?.years_with_interest
                                          ?.value
                                    : []
                            }
                            missingInfo={taxometricData?.TotalInterestDue?.missing_information}
                            color={taxometricData?.TotalInterestDue?.color}
                        />
                        <Card1
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            mainTitle={'Civil Penalties Balance Due'}
                            titles={[
                                taxometricData?.CivilPenaltiesBalanceDue?.total_balance_due
                                    ?.label || '',
                                taxometricData?.CivilPenaltiesBalanceDueYear?.years_with_balances
                                    ?.label || '',
                            ]}
                            total={
                                taxometricData?.CivilPenaltiesBalanceDue?.total_balance_due?.value
                            }
                            years={
                                taxometricData?.CivilPenaltiesBalanceDueYear?.years_with_balances
                                    ?.value
                                    ? taxometricData?.CivilPenaltiesBalanceDueYear
                                          ?.years_with_balances?.value
                                    : []
                            }
                            missingInfo={
                                taxometricData?.CivilPenaltiesBalanceDue?.missing_information
                            }
                            color={taxometricData?.CivilPenaltiesBalanceDue?.color}
                        />
                        <Card4
                            setTranscriptTab={setTranscriptTab}
                            data={taxometricData?.CurrentYearTax}
                            transcriptTab={transcriptTab}
                            mainTitle={'Estimated Payments - Current Year'}
                        />
                        <Card4
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            data={taxometricData?.PriorYearTax}
                            mainTitle={'Estimated Payments - Prior Year'}
                        />
                        <Card4
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            data={taxometricData?.CurrentYearInstallments}
                            mainTitle={'Installment Agreements - Current Year'}
                        />
                        <Card4
                            setTranscriptTab={setTranscriptTab}
                            transcriptTab={transcriptTab}
                            data={taxometricData?.PriorYearInstallments}
                            mainTitle={'Installment Agreements - Prior Year'}
                        />
                    </div>
                </>
            ) : (
                <div>Data Not Found</div>
            )}
        </div>
    );
};
