import { upsertTransferredAssets } from '../../api/other';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const transferredAssetForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'asset_name',
        label: 'List Assets',
        placeholder: 'Antique Jwellery, World war 2 cannons',
        area: true,
        span: 2,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'value_at_time_of_transfer',
        label: 'Value at Time of Transfer',
        placeholder: '$5,000',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'date_transferred',
        label: 'Date Transferred',
        placeholder: '24 June 2017',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'to_whom_transferred',
        label: 'To Whom or Where was it Transferred',
        placeholder: 'John',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'transfer_assets_data',
        label: 'In the past 10 years, have you transferred any assets for less than their full value?',
        header: 'Transferred Assets Information',
        formStructure: transferredAssetForm,
        displayColumns: [
            {
                name: 'List Assets',
                key: 'asset_name',
                span: 2,
            },
            {
                name: 'value',
                key: 'value_at_time_of_transfer',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'transferred_assets',
    name: 'Transferred Assets',

    sectionId: enumSections.OTHER_INFORMATION,
    formStructure: form,

    save: upsertTransferredAssets,
};
