import { useEffect, useState } from 'react';
import { fieldTypes } from '../../utils/enums';
import Button from '../basic/Button';
import { useMutation } from 'react-query';
import { setUpOrganization } from '../../api/login';
import { useAuthDispatch, useAuthState } from '../../context/auth';
import Dropdown from '../client/Dropdown';
import { phone } from '../../utils/format';
import InputGroup from '../client/InputGroup';
import { showToast } from '../basic/Toaster';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { build } from '../../config';
import { useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';

const OrganizationSignUp: React.FC<{}> = ({}) => {
    //state variables
    const [fullName, setFullName] = useState<string>('');
    const [organizationName, setOrganizationName] = useState<string>('');
    const [organizationPhone, setOrganizationPhone] = useState<string>('');
    const [organizationType, setOrganizationType] = useState<any>({ label: 'Free', value: 'Free' });
    const [highlight, setHighlight] = useState<boolean>(false);
    const authState = useSelector((state: RootState) => state.auth);
    //contains error message fetched from the server
    const [error, setErrors] = useState<{ [key: string]: any }>();
    console.log('auth state org: ', authState);
    //getting ID from the AUTH context to pass in the API
    const {
        success,
        role,
        org_data: { id },
    } = useAuthState();
    const navigate = useNavigate();
    const authDispatch = useAuthDispatch();
    // Mutation for setting up organization
    const mutation = useMutation(setUpOrganization, {
        onSuccess: (data) => {
            setHighlight(true);
            // Delay navigation and show a success message and showing highlighting for the message ( currently not used )
            const time = authState?.authProcess !== 'sso' ? 3000 : 0;
            setTimeout(() => {
                if (authState?.authProcess === 'sso') {
                    authDispatch('LOGIN', data.data);
                } else {
                    navigate('/login');
                    showToast('Credentials have been sent!', 'success', 4000);
                }
            }, time); // Delay of 0 seconds ( currently not used )
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    // Function to register organization
    const registerOrg = () => {
        const data: any = {
            individual_name: fullName,
            id: id,
            organization_name: organizationName,
            organization_phone: organizationPhone,
            organization_type: organizationType?.value ? organizationType?.value : organizationType,
        };
        if (authState?.authProcess == 'sso') {
            data.process_type = 'sso';
        }
        // Call the API mutation to set up the organization
        mutation.mutate(data);
    };

    // Options for organization type dropdown
    const organization_type = [
        { label: 'Free', value: 'Free' },
        { label: 'Starter', value: 'Starter' },
        { label: 'Basic', value: 'Basic' },
        { label: 'Professional', value: 'Professional' },
        { label: 'Enterprise', value: 'Enterprise' },
    ];
    // Options for user type dropdown
    const user_type = [
        { label: 'Individual', value: 'Individual' },
        { label: 'Organization', value: 'Organization' },
    ];

    useEffect(() => {
        if (success) {
            switch (role) {
                case 'Client':
                    navigate('/home');
                    return;

                case 'Admin':
                    navigate('/client');
                    return;

                case 'Tax Preparer':
                    navigate('/client');
                    return;

                case 'Super Admin':
                    navigate('/superAdmin');
                    return;

                default:
                    return;
            }
        }
    });

    return (
        <div className="w-full h-screen flex">
            <div
                className="flex-grow bg-primary-200 max-w-xs opacity-10"
                style={{
                    backgroundImage: 'url(/menu_pattern.png)',
                    backgroundSize: '200px 200px',
                }}
            />

            <div className="flex-grow flex justify-center items-center" style={{ flexGrow: 2 }}>
                <div className="max-w-md w-full flex flex-col items-center p-3">
                    <div className="font-title flex bg-white justify-center items-center z-20">
                        <img
                            src={build.logo_dark}
                            className={classNames('h-16 z-20 bg-white  mb-8 ')}
                            style={{ padding: 1 }}
                        />
                    </div>
                    {
                        <>
                            <Dropdown
                                id="organization_type"
                                label="Plan Type"
                                placeholder="Select..."
                                options={organization_type}
                                value={organizationType}
                                setValue={(value) => {
                                    setOrganizationType(value);
                                }}
                                disabled
                                type={fieldTypes.DROPDOWN}
                                className="w-full mt-1"
                            />{' '}
                            <InputGroup
                                id="individual_name"
                                label="Full Name*"
                                placeholder=""
                                inputType="text"
                                value={fullName}
                                setValue={(value) => setFullName(value)}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full"
                                staticError={error?.individual_name}
                            />
                            <InputGroup
                                id="organization_name"
                                label="Organization Name"
                                placeholder=""
                                inputType="text"
                                value={organizationName}
                                setValue={(value) => setOrganizationName(value)}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full"
                                staticError={error?.organization_name}
                            />
                            <InputGroup
                                id="organization_phone"
                                label="Phone*"
                                placeholder=""
                                formatFunction={phone}
                                value={organizationPhone}
                                setValue={(value) => setOrganizationPhone(value)}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full"
                                staticError={error?.organization_phone}
                            />
                            {authState?.authProcess === 'manual' && highlight && (
                                <div
                                    className={classNames('my-5 text-xs text-gray-600', {
                                        'bg-primary-100 rounded-full p-2': highlight,
                                    })}
                                >
                                    *Your login credentials have be sent to your email.
                                </div>
                            )}
                            <Button
                                isLoading={mutation.isLoading}
                                onClick={registerOrg}
                                className="text-center w-full mt-2"
                            >
                                {'COMPLETE'}
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default OrganizationSignUp;
