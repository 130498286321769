import { upsertIncomeExpense } from '../../api/finance';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const form: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Food, Clothing, Misc' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'personal_expenses',
        label: 'Food, Clothing, Misc',

        displayColumns: [
            { name: '', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],
        defaultRows: [
            [
                'Food',
                {
                    id: 'expense_food',
                    overrideBooleanId: 'expense_food_override',
                    // overrideValueId: 'expense_food_last_entered',
                },
            ],
            [
                'Housekeeping Supplies',
                {
                    id: 'expense_housekeeping',
                    overrideBooleanId: 'expense_housekeeping_override',
                    // overrideValueId: 'expense_housekeeping_last_entered',
                },
            ],
            [
                'Clothing and Clothing Services',
                {
                    id: 'expense_clothing',
                    overrideBooleanId: 'expense_clothing_override',
                    // overrideValueId: 'expense_clothing_last_entered',
                },
            ],
            [
                'Personal Care Products and Services',
                {
                    id: 'expense_personal_care',
                    overrideBooleanId: 'expense_personal_care_override',
                    // overrideValueId: 'expense_personal_care_last_entered',
                },
            ],
            [
                'Misc.',
                {
                    id: 'expense_misc',
                    overrideBooleanId: 'expense_misc_override',
                    // overrideValueId: 'expense_misc_last_entered',
                },
            ],
        ],
        totalRow: [
            {
                id: 'expense_food_clothing_misc_total',
                label: 'Total',
                lesserGreaterId: 'food_clothing_misc_lesser_greater',
                // sharable: 'tp_fcm_shared',
                // allowable: 'tp_fcm_allowable',
            },
        ],
    },

    { type: fieldTypes.SEPERATOR },
    { type: fieldTypes.SEPERATOR, title: 'Transportation' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'transportation',
        label: 'Transportation',

        displayColumns: [
            { name: '', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],
        defaultRows: [
            [
                'Vehicle Ownership / Auto Payment',
                {
                    id: 'expense_vehicle_ownership_total',
                    overrideBooleanId: 'vehicle_ownership_lesser_greater',
                },
            ],
            [
                'Vehicle Operating',
                {
                    id: 'expense_vehicle_operating_total',
                    overrideBooleanId: 'vehicle_operating_lesser_greater',
                },
            ],
            [
                'Public Transportation',
                {
                    id: 'public_transportation_expenses',
                    overrideBooleanId: 'public_transportation_expenses_lesser_greater',
                },
            ],
        ],
        totalRow: [
            {
                id: 'tp_transport_total',
                label: 'Total',
                lesserGreaterId: 'transportation_total_lesser_greater',
                // sharable: 'tp_transport_shared',
                // allowable: 'tp_transport_allowable',
            },
        ],
    },

    { type: fieldTypes.SEPERATOR },
    { type: fieldTypes.SEPERATOR, title: 'Housing & Utilities' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'housing_utilities',
        label: 'Housing & Utilities',

        displayColumns: [
            { name: '', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],
        defaultRows: [
            ['Rent /Mortgage', { id: 'expense_rent' }],
            ['Electric', { id: 'expense_electric' }],
            ['Oil/Gas', { id: 'expense_gas' }],
            ['Water/Server', { id: 'expense_water_sewer' }],
            ['Trash', { id: 'expense_trash' }],
            ['Phone/Cable/Internet', { id: 'expense_telephone' }],
            ['Real Estate Taxes & Insurance', { id: 'expense_taxes_insurance' }],
            ['Repairs & Maintainance', { id: 'expense_maintenance_repairs' }],
            ['Homeowners Associations (HOA)', { id: 'hoa' }],
        ],
        totalRow: [
            {
                id: 'expense_housing_total',
                label: 'Total',
                lesserGreaterId: 'housing_utilities_lesser_greater',
                // sharable: 'tp_hu_shared',
                // allowable: 'tp_hu_allowable',
            },
        ],
    },

    { type: fieldTypes.SEPERATOR },
    { type: fieldTypes.SEPERATOR, title: 'Medical Expenses' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'medical_expenses',
        label: 'Medical Expenses',

        displayColumns: [
            { name: 'Insurance', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],
        addLimit: 3,
        addColumns: [
            {
                name: 'Expenses Name',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'expense_insur_other_',
                id_suffix: '_prompt',
            },
            {
                name: 'Expenses Amount',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'expense_insur_other_',
                id_suffix: '',
            },
        ],
        addButtonText: 'Add new Insurance...',

        defaultRows: [
            ['Health Insurance', { id: 'expense_medical_insur' }],
            ['Dental Insurance', { id: 'expense_dental_insur' }],
            ['Vision Insurance', { id: 'expense_vision_insur' }],
        ],
        totalRow: [
            {
                id: 'expense_health_insur_total',
                label: 'Total',
                // sharable: 'tp_medical_shared',
                // allowable: 'tp_medical_allowable',
            },
        ],
    },

    { type: fieldTypes.SEPERATOR },
    { type: fieldTypes.SEPERATOR, title: 'Out of pocket Medical Expenses' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'out_of_pocket_expenses',
        label: 'Out of pocket Medical Expenses',

        addLimit: 6,
        addColumns: [
            {
                name: 'Expenses Name',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'expense_health_oop_',
                id_suffix: '_prompt',
            },
            {
                name: 'Expenses Amount',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'expense_health_oop_',
                id_suffix: '',
            },
        ],
        addButtonText: 'Add new out of pocket expense...',

        displayColumns: [
            { name: 'Out of Pocket Healthcare Cost', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],
        defaultRows: [['Out of Pocket Healthcare Costs', { id: 'tp_out_of_pkt_health_cost' }]],
        totalRow: [
            {
                id: 'expense_health_oop_total',
                label: 'Total',
                lesserGreaterId: 'out_of_pocket_health_care_lesser_greater',
            },
        ],
    },

    { type: fieldTypes.SEPERATOR },
    { type: fieldTypes.SEPERATOR, title: 'Other Expenses' },
    {
        type: fieldTypes.EDITABLE_TABLE,
        id: 'additional_expense_data',
        label: 'Other Expenses',

        displayColumns: [
            { name: 'Expenses', type: fieldTypes.TEXT_DISPLAY },
            { name: 'Stated', type: fieldTypes.TEXT_INPUT },
        ],

        addLimit: 20,
        addColumns: [
            {
                name: 'Expense Name',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'additional_expense_data_',
                id_suffix: '_prompt',
            },
            {
                name: 'Expense Amount',
                type: fieldTypes.TEXT_INPUT,
                id_prefix: 'additional_expense_data_',
                id_suffix: '',
            },
        ],
        addButtonText: 'Add Other Expenses...',

        defaultRows: [
            ['Child/Dependent Care', { id: 'expense_child_care' }],
            ['Estimated tax payments (Taxpayer)', { id: 'expense_taxes_taxpayer' }],
            ['Estimated tax payments (Spouse)', { id: 'expense_taxes_spouse' }],
            [
                'Estimated tax payments to the state (Taxpayer)',
                { id: 'expense_est_tax_payment_taxpayer' },
            ],
            [
                'Estimated tax payments to the state (Spouse)',
                { id: 'expense_est_tax_payment_spouse' },
            ],
            ['Term Life (Taxpayer)', { id: 'expense_medicare_taxpayer' }],
            ['Term Life (Spouse)', { id: 'expense_medicare_spouse' }],
            [
                'Required Retirement (Taxpayer)',
                { id: 'expense_retirement_employer_required_taxpayer' },
            ],
            ['Required Retirement (Spouse)', { id: 'expense_retirement_employer_required_spouse' }],
            ['Voluntary Retirement (Taxpayer)', { id: 'expense_retirement_voluntary_taxpayer' }],
            ['Voluntary Retirement (Spouse)', { id: 'expense_retirement_voluntary_spouse' }],
            ['Union Dues (Taxpayer)', { id: 'expense_union_dues_taxpayer' }],
            ['Union Dues (Spouse)', { id: 'expense_union_dues_spouse' }],
            ['Delinquent State & Local Taxes', { id: 'delinqu_state_local_tax_debt_amount' }],
            ['Child Support (Taxpayer)', { id: 'expense_noncourt_order_child_support_taxpayer' }],
            ['Child Support (Spouse)', { id: 'expense_noncourt_order_child_support_spouse' }],
            ['Alimony (Taxpayer)', { id: 'expense_court_order_alimony_taxpayer' }],
            ['Alimony (Spouse)', { id: 'expense_court_order_alimony_spouse' }],
            ['Other Court Ordered Payments', { id: 'expense_other_court_order_payment' }],
            ['Life Insurance', { id: 'expense_life_insurance' }],
            ['Tax Payments', { id: 'expense_taxes' }],
            ['Secured debts', { id: 'expense_other_secured_debt' }],
            ['Other', { id: 'expense_other_expenses' }],
        ],

        totalRow: [
            {
                id: 'expense_total',
                label: 'Total Living Expense',
            },
            {
                id: 'net_difference',
                label: 'Net Difference',
            },
            {
                id: 'tp_total_tax_owed_to_state',
                label: 'Total Tax Owed To State',
                isInput: true,
            },
        ],
    },

    { type: fieldTypes.TERMINAL },
];

export const page: PageInputInterface = {
    address: 'expenses',
    name: 'Expenses',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertIncomeExpense,
};
