import { upsertCreditCards } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const businessCreditCards: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'name_on_account',
        label: 'Individual / Business Name On Account',
        placeholder: 'Tony Drug Store',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'type_of_credit_card',
        label: 'Credit Card Type',
        placeholder: 'Platinum',
        options: [
            { label: 'Visa', value: 'Visa' },
            { label: 'MC', value: 'MC' },
            { label: 'Amex', value: 'Amex' },
            { label: 'Discover', value: 'Discover' },
            { label: 'Store Card', value: 'Store Card' },
            { label: 'Other', value: 'Other' },
        ],
    },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Issuing Bank Name',
        placeholder: 'Chase Bank',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_phone',
        label: 'Company Phone',
        placeholder: 'Chase Bank',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Issuing Bank Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        label: 'Addres Line 1',
        span: 2,
        area: true,
        placeholder: `15498 Plantation Oaks Drive`,
    },

    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_city',
        label: 'City',
        placeholder: `Tampa Bay`,
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'company_state',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_zip',
        label: 'Zip',
        placeholder: '32003',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'merchant_account_number',
        label: 'Merchant Account Number',
        placeholder: '12-3456789',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_credit_data',
        label: 'How many credit cards are accepted by your business?',
        formStructure: businessCreditCards,
        header: 'Credit Cards Accepted',
        displayColumns: [
            {
                name: 'Issuing Bank Name',
                key: 'company_name',
            },
            {
                name: 'Card Type',
                key: 'type_of_credit_card',
            },
            {
                name: 'Merchant Account Number',
                key: 'merchant_account_number',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_credit_cards',
    name: 'Business Credit Cards Accepted',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertCreditCards,
};
