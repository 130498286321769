import { useMutation } from 'react-query';
import { signUpUser } from '../../api/login';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthDispatch } from '../../context/auth';
import { useDispatch } from 'react-redux';
import { setAuthError, setAuthLoading, setAuthProcess } from '../../stateManager/authSlice';

const useSignUpMutation = ({ type }: { type: 'manual' | 'sso' }) => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>();
    const dispatch = useDispatch();
    const authDispatch = useAuthDispatch();

    // Define the mutation
    const mutation = useMutation(signUpUser, {
        // Action to perform on successful mutation
        onSuccess: (data) => {
            authDispatch('SIGNUP', data);
            dispatch(setAuthError({ authError: '' }));
            navigate('/sign-up-org');
        },
        // Action to perform on mutation error
        onError: (error: any) => {
            setErrors(error.response.data.message);
            dispatch(setAuthError({ authError: error.response.data.message }));
        },
        // Action to perform when mutation is settled (whether successful or not)
        onSettled: () => {
            dispatch(setAuthLoading({ isLoading: false }));
        },
    });

    // Function to register a user
    const registerUser = (organizationEmail: string) => {
        dispatch(setAuthProcess({ authProcess: type }));
        dispatch(setAuthLoading({ isLoading: true }));
        mutation.mutate({
            organization_email: organizationEmail,
        });
    };

    // Return the registerUser function, isLoading state from the mutation, and any errors
    return { registerUser, isLoading: mutation.isLoading, errors };
};

export default useSignUpMutation;

// @ we are using auth states for the SSO responses to show up on the sign-up and sign-in pages
