import React, { useState } from 'react';
import Button from '../basic/Button';
import useSectionSelection from './useSectionSelection';
interface SectionProps {
    title: string;
    subTitle: string;
    isChecked: boolean;
    icon: string;
    setChecked: () => void;
}

// to render the card
const SectionCard: React.FC<SectionProps> = ({ title, subTitle, isChecked, setChecked, icon }) => (
    <div
        onClick={setChecked}
        className={`p-4 cursor-pointer max-w-sm rounded-md shadow-sm ${
            isChecked ? 'bg-green-100 border border-green-300' : 'bg-white'
        } max-h-36 flex flex-col justify-between transition duration-300 transform hover:scale-105`}
        style={{ minWidth: '14.5rem' }}
    >
        <div className="text-center">
            <span className="p-2 bg-green-100 rounded-full w-auto material-symbols-outlined">
                {icon}
            </span>
        </div>
        <div className="pt-2 text-center text-sm text-gray-500">{subTitle}</div>
        <div
            className={`mt-1 text-md shadow-sm pb-1 mb-1 bg-white rounded-md text-center font-noram-bold`}
        >
            {title}
        </div>
    </div>
);

type Props = {
    edit?: boolean;
    Close: () => void;
    state_id?: string;
};

const SectionSelection: React.FC<Props> = ({ edit, Close }) => {
    const [loading, setLoading] = useState(false);
    const { sections, toggleSection, saveSections } = useSectionSelection({ Close, setLoading });

    //saving the sections changes
    const openSection = async () => {
        setLoading(true);
        saveSections();
    };

    return (
        <div className={`bg-gray-100 ${edit ? 'px-8' : 'min-h-screen'} py-8`}>
            <div className="max-w-5xl mx-5 md:mx-auto">
                <div className="font-bold text-2xl text-gray-400 mb-4">Select Sections</div>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {sections.map((section) => (
                        <SectionCard
                            key={section.name}
                            title={section.dispName}
                            subTitle={section?.subName}
                            isChecked={section.isActive}
                            setChecked={() => toggleSection(section.name)}
                            icon={getIconForSection(section.name)}
                        />
                    ))}
                </div>
                <div className="flex justify-center mt-4">
                    <div className="w-6/12 flex pt-4">
                        <Button
                            isLoading={loading}
                            onClick={openSection}
                            disabled={!sections.some((section: any) => section.isActive === true)}
                            className="text-center flex-grow mr-2"
                        >
                            SAVE
                        </Button>
                        <Button
                            onClick={() => Close()}
                            secondary
                            className="text-center flex-grow ml-2"
                        >
                            {'CANCEL'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionSelection;

// Helper function to get the icon based on the section name
function getIconForSection(sectionName: string) {
    switch (sectionName) {
        case 'dependent_data':
            return 'child_care';
        case 'income_details_data':
            return 'request_page';
        case 'divident_income_data':
            return 'volunteer_activism';
        case 'business_data':
            return 'engineering';
        case 'itemized_deduction_data':
            return 'category';
        case 'foreign_account_data':
            return 'account_balance';
        case 'interest_income_data':
            return 'monetization_on';
        case 'donations_data':
            return 'crowdsource';
        case 'farm_sections':
            return 'agriculture';
        default:
            return '';
    }
}
