/**
 * Heading UI Component for wrapping fields into
 **/

import classNames from 'classnames';

interface HeadingGroupProps {
    className?: string;
    disabled?: boolean;
    label: string;
}

const HeadingGroup: React.FC<HeadingGroupProps> = (props) => {
    const { className, label } = props;

    return (
        <div className={classNames(className)} style={{ width: '100%' }}>
            <div className="grid grid-cols-5 items-center transition-all duration-200 my-4 w-full ">
                <div className="mr-3 col-span-1 text-right h-0.5 bg-primary-500 border-0 border-r" />
                <div className="col-span-4 flex items-center">
                    <div className="pr-3 font-title font-medium text-lg">{label}</div>
                    <div className="flex-grow h-0.5 bg-primary-500" />
                </div>
            </div>
        </div>
    );
};

export default HeadingGroup;
