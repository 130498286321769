import { useEffect, useState } from 'react';
import useFormsList from '../../api/tax-prep/useFormsList';
import Dropdown from '../client/Dropdown';
import { enumSections, fieldTypes } from '../../utils/enums';
import classNames from 'classnames';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { useParams } from 'react-router-dom';
import useAdminCopySectionData from './useAdminCopySectionData';
import useAdminLocalStorage from './useAdminLocalStorage';
import useTaxPrepEvalData from './useTaxPrepEvalData';
import { build } from '../../config';
import Modal from '../basic/Modal';
import Button from '../basic/Button';
import warning_logo from './../../assets/warning-icon.png';

const ConfirmDataLoading: React.FC<{
    close: () => void;
    isOpen: boolean;
    loadData: () => any;
    clearData: () => any;
}> = ({ isOpen, close, loadData, clearData }) => {
    return (
        <Modal isOpen={isOpen} close={close}>
            <div className="rounded-md">
                <div
                    style={{ background: '#f7e5c4' }}
                    className="flex font-bold justify-center align-middle h-9 w-full"
                >
                    <img src={warning_logo} className="h-8" alt="warning-logo" />
                </div>
                <div className="bg-white px-5 py-8 " style={{ minWidth: 500 }}>
                    <p className="text-xl font-semibold text-gray-800 text-center">Are you sure?</p>
                    <p className="text-sm text-center text-gray-500 mb-8">
                        You want to retrieve the unsaved data
                    </p>
                    <div className="flex w-full justify-center">
                        <Button
                            onClick={() => {
                                loadData();
                                close();
                            }}
                            // isLoading={mutation.isLoading}
                            className="text-center w-1/2 mr-2"
                            // style={{ background: '#ffa500' }}
                        >
                            {'Confirm'}
                        </Button>
                        <Button
                            onClick={() => {
                                close();
                                // clearData();
                            }}
                            secondary
                            className="text-center w-1/2 ml-2"
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const YearToggleAdmin = ({ year, setYear }: { year: string; setYear: (year: string) => void }) => {
    const { irs_account_id } = useParams();

    const [copyYear, setCopyYear] = useState<string>();
    const { copyAllSections } = useAdminCopySectionData(copyYear);
    const [copyMessage, setCopyMessage] = useState<boolean>(false);
    const { mutate: executeCopy, isSuccess, isLoading, reset } = copyAllSections;
    const { year: currentYear } = useTaxPrepStructure();
    const [copeMode, setCopyMode] = useState<boolean>(false);
    const [confirmDataLoadidng, setConfirmDataLoadModal] = useState(false);
    let { formState } = useTaxPrepEvalData();
    const { localData, localMetaData, clearLocalStorage, hydrateFormState } = useAdminLocalStorage({
        id: formState.id,
        year,
    });
    const { years } = useFormsList({ irs_account_id: irs_account_id! });
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
        let messageTimer: NodeJS.Timeout;

        if (isSuccess) {
            setCopyMessage(true);
            setCopyMode(false);

            messageTimer = setTimeout(() => {
                setCopyMessage(false);
                setCopyYear(undefined);
                reset();
            }, 3 * 1000);
        }

        return () => {
            if (messageTimer) {
                clearTimeout(messageTimer);
            }
        };
    }, [isSuccess]);

    // useEffect(() => {
    //     if (isSuccess) {
    //         reset();
    //         setCopyMode(false);
    //     }
    // }, [isSuccess]);

    useEffect(() => {
        if (!years) {
            return;
        }

        const newIndex = years.findIndex((y) => y === currentYear);
        if (newIndex !== index) {
            setIndex(newIndex);
        }
    }, [currentYear, years]);

    if (!years) {
        return (
            <div className="flex flex-col w-full border border-gray-300 rounded-md p-1">
                <div>Loading...</div>
            </div>
        );
    }
    console.log('copy message', copyMessage, isSuccess);
    const previousYearIndex = Math.abs((index - 1) % years.length);
    const nextYearIndex = Math.abs((index + 1) % years.length);

    if (copeMode) {
        return (
            <div className="flex flex-col sticky top-12 w-full z-10 border bg-white border-gray-300 rounded-md p-1">
                <Dropdown
                    className="mt-0"
                    type={fieldTypes.DROPDOWN}
                    label="Select a year to copy data from"
                    id="copyYear"
                    placeholder="Year"
                    setValue={(value) => setCopyYear(value)}
                    value={copyYear}
                    options={(years || [])
                        .filter((y: string) => y !== currentYear)
                        .map((y: string) => ({ value: y, label: y }))}
                />

                {isLoading ? (
                    <div className="w-full flex flex-grow justify-end px-3">
                        <span
                            className="material-icons animate-spin w-5 h-5 flex items-center justify-center"
                            style={{ fontSize: 18 }}
                        >
                            refresh
                        </span>
                        <div
                            onClick={() => {
                                setCopyYear(undefined);
                                setCopyMode(false);
                            }}
                            className="m-0.5 cursor-pointer hover:text-gray-700 text-sm text-gray-500 font-semibold"
                        >
                            Copying data...
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex flex-grow justify-end px-3">
                        <div
                            onClick={() => copyYear && executeCopy()}
                            className={classNames(
                                'm-0.5 cursor-pointer mr-2 text-sm font-semibold',
                                {
                                    'text-primary-500 hover:text-primary-700': !!copyYear,
                                    'text-gray-500 hover:text-gray-700': !copyYear,
                                },
                            )}
                        >
                            Confirm
                        </div>
                        <div
                            onClick={() => {
                                setCopyYear(undefined);
                                setCopyMode(false);
                            }}
                            className="m-0.5 cursor-pointer hover:text-gray-700 text-sm text-gray-500 font-semibold"
                        >
                            Cancel
                        </div>
                    </div>
                )}
            </div>
        );
    }
    console.log('localStorage ->', { localData, localMetaData });

    return (
        <div className="sticky top-10 z-10 shadow-lg rounded-md">
            {(localData || localMetaData) && build.tp_local_storage && (
                <div
                    className={classNames(
                        'flex flex-col mt-1 items-center max-w-4xlw-full cursor-pointer rounded-md justify-center',
                    )}
                    style={{ minWidth: 550, minHeight: 48, background: '#f7e5c4' }}
                >
                    <div className="w-full flex px-4 py-1 items-center">
                        <img src={warning_logo} className="h-5 mr-1" alt="warning-logo" />
                        <div className="flex-grow text-sm">{`Want to pick up where you left off? Your previously entered information is still here.`}</div>
                        <div
                            // onClick={loadLocalStorage}
                            style={{ background: '#ffa500' }}
                            onClick={() => setConfirmDataLoadModal(true)}
                            className="px-3 py-2 cursor-pointer text-sm text-gray-800 font-title"
                        >
                            {`Retrieve Unsaved Data`}
                        </div>
                        <div
                            onClick={clearLocalStorage}
                            className="px-3 py-2 ml-2 cursor-pointer underline text-sm text-black font-title"
                        >
                            {`Cancel`}
                        </div>
                    </div>
                    {/* )} */}
                </div>
            )}
            <div className="  bg-white mt-3 rounded-md  ">
                <div className="flex items-center py-2 px-3">
                    <div className="flex w-full p-2">
                        <div className="font-title pt-1 pr-1 text-lg">
                            {/* {first_name + ' ' + last_business_name} */}
                            Tax Prep
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="text-xs">Current Year</div>
                            <div className="flex">
                                {years?.length > 1 && (
                                    <span
                                        className="cursor-pointer hover:text-primary-700 text-primary-500 material-symbols-rounded transform rotate-180"
                                        onClick={() => setYear(years[previousYearIndex])}
                                    >
                                        play_arrow
                                    </span>
                                )}
                                <div className="w-11 text-center">{year}</div>
                                {years?.length > 1 && (
                                    <span
                                        className="cursor-pointer hover:text-primary-700 text-primary-500 material-symbols-rounded"
                                        onClick={() => setYear(years[nextYearIndex])}
                                    >
                                        play_arrow
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex-grow"></div>
                        <div className="flex flex-col items-end">
                            <div className="text-xs text-gray-400">{`Filing for sessions ${years?.map(
                                (y: string) => y,
                            )}`}</div>
                            <div
                                onClick={() => setCopyMode(true)}
                                className="mt-0.5 flex items-center justify-center text-primary-500 hover:text-primary-700 cursor-pointer"
                            >
                                <span
                                    style={{ fontSize: 18 }}
                                    className="text-lg mr-1 material-symbols-rounded"
                                >
                                    file_copy
                                </span>
                                <div className="text-sm">{`Copy entire data from another year`}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {copyMessage && (
                    <div className="flex justify-center rounded-b-sm w-full border-b py-2 bg-primary-500 border-gray-300 p-1 rounded-bl-md rounded-br-md">
                        <div className="text-white text-xs">{`Data successfully copied from ${copyYear}!`}</div>
                    </div>
                )}
                {/* <div
                // onClick={() => setFormModal(true)}
                className="px-3 py-2 bg-primary-500 cursor-pointer hover:bg-primary-700 text-sm text-white font-title"
            >
                GENERATE
            </div> */}
            </div>

            <ConfirmDataLoading
                close={() => setConfirmDataLoadModal(false)}
                isOpen={confirmDataLoadidng}
                loadData={hydrateFormState}
                clearData={clearLocalStorage}
            />
        </div>
    );
};

export default YearToggleAdmin;
