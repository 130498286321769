/**
 * Saperator UI Component for seperation
 **/

import classNames from 'classnames';
import { interface_seperator } from '../../utils/interfaces';
import HeadingGroup from './HeadingGroup';
import ParentGroup from './ParentGroup';

type SeperatorGroupProps = interface_seperator & {
    className?: string;
};
const SeperatorGroup: React.FC<SeperatorGroupProps> = (props) => {
    const { className, title, heading } = props;

    return (
        <>
            {/* <div className={classNames('w-full mt-2 col-span-4', className)}>
                {title && <HeadingGroup label={title} />}
            </div> */}
            {heading && (
                <div className={classNames('w-full mt-2 mx-2 col-span-4', className)}>
                    <div className="font-title text-sm font-semibold">{heading}</div>
                </div>
            )}
        </>
    );
};

export default SeperatorGroup;
