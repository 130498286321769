import { useSelector } from 'react-redux';
import { enumSections, fieldTypes } from '../../utils/enums';
import {
    BrickInterface,
    ConcreteInterface,
    PageInputInterface,
    PageInterface,
    SectionInterface,
} from '../../utils/interfaces';
import { page as personal_information } from './0_0_0_personal_information';
import { page as filing_status } from './0_0_1_filing_information';
import { page as dependents } from './0_0_dependents';
import { page as income_details } from './1_0_income_details';
import { page as deductions } from './1_1_deductions';
import { page as interest_income } from './1_2_interest_income';
import { page as dividents_income } from './1_3_devidents_income';
import { page as foriegn_income } from './1_4_foreign_accounts';
import { page as bussiness } from './2_0_bussiness_section';
import { page as bussiness_details } from './2_1_bussiness_details';
import { page as bussiness_income } from './2_2_bussiness_income';
import { page as bussiness_expenses } from './2_3_bussiness_expenses';
import { page as bussiness_cost_of_goods } from './2_4_bussiness_cost_of_goods';
import { page as bussiness_vehicle_info_ } from './2_5_bussiness_vehicle_details';
import { page as bussiness_partnership } from './2_6_bussiness_partnership_llc';
import { page as bussiness_farm_income } from './2_7_bussiness_farm_income';
import { page as bussiness_farm_expenses } from './2_8_bussiness_expense_cash';
import { page as bussiness_farm_accural } from './2_9_bussiness_farm_income_accural';
import { RootState } from '../../stateManager/rootReducers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useSectionSelection from '../../components/taxprep/useSectionSelection';
import { isBrick } from '../../components/basic/FormConstructor';
import useClientData from '../../api/tax-prep/useClientData';
import { useQueryClient } from 'react-query';

const pagesImport = [
    personal_information,
    filing_status,
    dependents,
    income_details,
    deductions,
    interest_income,
    dividents_income,
    foriegn_income,
    bussiness_farm_income,
    bussiness_farm_expenses,
    bussiness_farm_accural,
    bussiness,
];

const singleMemberLLC: PageInputInterface[] = [
    bussiness_details,
    bussiness_income,
    bussiness_expenses,
    bussiness_cost_of_goods,
    bussiness_vehicle_info_,
];
const partnershipLLC: PageInputInterface[] = [bussiness_details, bussiness_partnership];
const anyOtherLLC: PageInputInterface[] = [bussiness_details];

//creating one instance of bussiness sections
export const bussinessFormsByType: Record<string, PageInputInterface[]> = {
    'Single Member LLC / Sole Prop': singleMemberLLC,
    'Partnership LLC/S-Corp': partnershipLLC,
    'Any Other Corporation': anyOtherLLC,
};

//main keys where sections will lie based on given section ID
export let sectionsDefault: Array<SectionInterface> = [
    { id: enumSections.YOUR_INFORMATION, name: 'Your Information', subSections: [] },
    { id: enumSections.FINANCIAL_INFORMATION, name: 'Financial Information', subSections: [] },
    {
        id: enumSections.BUSINESS_INFORMATION,
        name: 'Business Information',
        tabbed: true,
        subSections: [],
    },
];

const useTaxPrepStructure = (forceYear?: string) => {
    let relevantPages = pagesImport;
    let pages: { [key: string]: PageInterface } = {};
    let sections = sectionsDefault;
    const { sections: selectedSections } = useSectionSelection({});

    let { address, year, irs_account_id } = useParams();

    if (forceYear) {
        year = forceYear;
    }

    let location = useLocation();

    const isAdmin = location.pathname.includes('taxprep-evaluate') ? true : false;
    const navigate = useNavigate();

    if (!address) {
        address = address || '';
    }

    if (!year) {
        year = year || '';
    }

    if (!irs_account_id) {
        irs_account_id = irs_account_id || '';
    }

    let businessId: string | undefined;
    let businessGroupId: string | undefined;

    if (address.includes('.')) {
        businessGroupId = address.split('.')[2];
        businessId = address.split('.')[1];
        address = address.split('.')[0];
    }
    // const { refetch } = useClientData(irs_account_id!, nextYearP!);

    // to navigate through multiple years
    const queryClient = useQueryClient();
    const toggleYearAdmin = (nextYear: string) => {
        queryClient.invalidateQueries(['get-client-data-', nextYear]);
        // setNextYearP(nextYear);
        if (!businessId) {
            navigate('/taxprep-evaluate/' + irs_account_id + '/' + nextYear);
        }
        // console.log('refetch();', refetch());
        // if (nextYear && nextYear !== nextYearP) refetch();
    };
    //same for client-end
    const toggleYearClient = (nextYear: string) => {
        queryClient.invalidateQueries(['get-client-data-', nextYear]);
        if (!businessId) {
            navigate('/test/tax-prep/' + address + '/' + nextYear);
            return;
        }

        navigate(
            '/test/tax-prep/' + `${address}.${businessId}.${businessGroupId}` + '/' + nextYear,
        );
    };

    const state = useSelector((state: RootState) => state.taxPrep.formState);
    const yearBasedState = (state && state[year]) || {};

    const isBussinessIdValid = !!yearBasedState?.['business_data']?.find(
        (b: any) => b?.id === businessId,
    );

    if (businessId && !isBussinessIdValid) {
        const zeroBusiness = yearBasedState?.['business_data'][0];

        const regEx = /^new/;
        if (regEx.test(businessId)) {
            navigate(
                '/test/tax-prep/' +
                    `${address}.${zeroBusiness.id}.${zeroBusiness.business_group_id}` +
                    '/' +
                    year,
            );
        } else {
            navigate('/test/tax-prep/' + `business_data` + '/' + year);
        }
    }

    //creating pagesRaw structure
    let pagesRaw: PageInterface[] = relevantPages.map((page, index) => {
        const section = sectionsDefault.find(({ id }) => id === page.sectionId) as SectionInterface;
        const isLastPage = false;

        let isLastSubSection = false;
        const baseLink = '/test/tax-prep/' + page.address + '/' + year;

        if (index === 0) {
            // If first page iniitallize at zero
            let prev = null;
            let next = null;
            if (index + 1 < relevantPages.length) next = relevantPages[index + 1].address;

            pages[page.address] = {
                ...page,
                prev,
                next,
                section,
                overallPosition: 0,
                isLastPage,
                isLastSubSection,
                baseLink,
            };
        } else {
            // Else calculate previous page and repeat the same process
            const prevPageAddress = relevantPages[index - 1].address;
            const prevPage = pages[prevPageAddress];

            let prev = prevPage.address;
            let next = null;

            if (index + 1 < relevantPages.length) next = relevantPages[index + 1].address;

            pages[page.address] = {
                ...page,
                prev,
                next,
                section,
                overallPosition: index,
                isLastSubSection,
                isLastPage,
                baseLink,
            };
        }

        sections = sections.map((section) => {
            if (section.id === page.sectionId)
                return { ...section, subSections: [...section.subSections, pages[page.address]] };
            else return section;
        });

        return {
            ...page,
            section,
            overallPosition: index,
            isLastSubSection,
            isLastPage,
            baseLink,
        };
    });

    /** Insert business pages */

    console.log('YEAR STATE', yearBasedState, pagesRaw);
    yearBasedState?.['business_data']?.forEach((b: any) => {
        if (!b) {
            return;
        }

        const businessPages = (bussinessFormsByType[b?.business_type] || [bussiness_details]).map(
            (page) => {
                const mainAddress = page.address + '.' + b.id + '.' + b.business_group_id;
                const baseLink = '/test/tax-prep/' + mainAddress + '/' + year;

                const section = sectionsDefault.find(
                    ({ id }) => id === page.sectionId,
                ) as SectionInterface;

                pages[page.address] = {
                    ...page,
                    section,
                    overallPosition: 0,
                    isLastPage: false,
                    isLastSubSection: true,
                    baseLink,
                };

                return {
                    ...page,
                    address: mainAddress,
                    businessId: b.id,
                    businessGroupId: b.business_group_id,
                    businessName: b.business_name,
                    baseLink,
                    section,
                } as PageInterface;
            },
        );

        pagesRaw.push(...businessPages);
    });

    //filter the fields based on the blocked form fields
    const shouldKeepField = (
        field: BrickInterface | ConcreteInterface,
        blockFields: string[],
    ): ShouldKeepField => {
        if (isBrick(field)) {
            if (blockFields.includes(field.id)) {
                return false;
            }

            switch (field.type) {
                case fieldTypes.CROSSROAD: {
                    const formStructure = field.formStructure
                        .map((f) => shouldKeepField(f, blockFields))
                        .filter((f) => f) as Array<BrickInterface | ConcreteInterface>;

                    const preForm = field.preForm
                        ? (field.preForm
                              .map((f) => shouldKeepField(f, blockFields))
                              .filter((f) => f) as Array<BrickInterface | ConcreteInterface>)
                        : field.preForm;

                    field = { ...field, formStructure, preForm };
                }
            }

            if (field.branches) {
                const paths = field.branches.paths.map((item) => {
                    const formStructure = item.form
                        .map((f) => shouldKeepField(f, blockFields))
                        .filter((f) => f) as Array<BrickInterface | ConcreteInterface>;

                    return { ...item, form: formStructure };
                });

                field = { ...field, branches: { ...field.branches, paths } };
            }
        } else {
            if (field.type === fieldTypes.SEPERATOR) {
                if (blockFields.includes(field.heading || '')) {
                    return false;
                }
            }
        }

        return field;
    };

    type ShouldKeepField = BrickInterface | ConcreteInterface | false;

    //function to filter sections ( pagesRaw ) based on the selected sections
    console.log('SELECTED SECTION A', selectedSections);
    selectedSections.forEach((selSec) => {
        if (!selSec.isActive) {
            pagesRaw = pagesRaw
                .filter((page) => {
                    return !selSec.blockedSections?.some((blockedSection: any) =>
                        page.address?.includes(blockedSection),
                    );
                })
                ?.map((page) => {
                    if (selSec.blockedFormFields.length === 0) {
                        return page;
                    }
                    const formStructure = page.formStructure
                        ?.map((field) => shouldKeepField(field, selSec.blockedFormFields))
                        ?.filter((f) => f !== false) as Array<BrickInterface | ConcreteInterface>;

                    return { ...page, formStructure };
                });
        }
    });

    /** Insert Previous and next page */
    pagesRaw = pagesRaw.map((page, index) => {
        let nextPage: PageInterface | undefined, prevPage: PageInterface | undefined;
        const isLastPage = index === pagesRaw.length - 1;
        let isLastSubSection = false;

        if (!isLastPage) {
            const nextPage = pagesRaw[index + 1];
            if (nextPage.sectionId !== page.sectionId) isLastSubSection = true;
        } else {
            isLastSubSection = true;
        }

        if (index < pagesRaw.length - 1) {
            nextPage = pagesRaw[index + 1];

            pages[page.address] = {
                ...pages[page.address],
                nextPage,
                isLastPage,
                isLastSubSection,
            };
        }

        if (index > 0) {
            prevPage = pagesRaw[index - 1];

            pages[page.address] = {
                ...pages[page.address],
                prevPage,
                isLastPage,
                isLastSubSection,
            };
        }

        return { ...page, prevPage, nextPage, isLastPage, isLastSubSection };
    });

    //exporting current page address individually
    const currentAddress = address + (businessId ? '.' + businessId + '.' + businessGroupId : '');

    //exporting current page individually
    const currentPage = pagesRaw.find(({ address: a }) => a === currentAddress);

    return {
        pages,
        sections,
        pagesRaw,
        address,
        year,
        currentPage,
        toggleYear: isAdmin ? toggleYearAdmin : toggleYearClient,
        businessId,
        businessGroupId,
        irs_account_id,
    };
};

export default useTaxPrepStructure;
