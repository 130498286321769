import { upsertAvailableCredit } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const investmentForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Name',
        placeholder: 'Bitcoin',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'credit_limit',
        label: 'Credit Limit',
        placeholder: '1000',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'company_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'amount_owed',
        label: 'Amount Owed',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'min_monthly_payment',
        label: 'Available Credit',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Account Number',
        placeholder: 'johndoe@hotmail.com',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_available_credit_data',
        label: 'Does your business have any Available credit?',
        header: 'Business Available Credit',
        formStructure: investmentForm,
        displayColumns: [
            {
                name: 'Name',
                key: 'company_name',
            },
            {
                name: 'Credit Limit',
                key: 'credit_limit',
            },
        ],
        preForm: [
            {
                type: fieldTypes.CALENDAR,
                id: 'available_credit_amount_owed_as_of_date',
                label: 'Amount Owed as of',
                placeholder: 'Tampa Bay',
            },
            {
                type: fieldTypes.CALENDAR,
                id: 'available_credit_as_of_date',
                label: 'Available Credit as of',
                placeholder: 'Florida',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'available_credit',
    name: 'Business Available Credit',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertAvailableCredit,
};
