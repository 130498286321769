import React, { createContext, useReducer, useContext, Dispatch, useEffect, useState } from 'react';
import { COOKIE, enumActionTypes, routes } from '../utils/enums';
import { api } from '../api';
import { decode, JwtPayload } from 'jsonwebtoken';
import moment from 'moment';
import { getUserCredentials } from '../api/login';
import { useQuery } from 'react-query';
import { build } from '../config';
import { useLocation } from 'react-router-dom';
import { apiTaxPrep } from '../api/tax-prep';
import { transcriptApi } from '../api/transcripts';

interface AuthStateInterface {
    loading: boolean;
    success: boolean;
    token: string | null;
    role: 'Admin' | 'Client' | 'Tax Preparer' | 'Super Admin' | null;
    user_id: string | null;
    u_email: string | null;
    name?: string;
    meta_data?: any;
    last_login?: string;
    business?: Array<string>;
    irs_account_id: string | null;
    feature_data: Array<any>;
    org_data: any;
    plan_type: string | null;
}

const initialState: AuthStateInterface = {
    loading: true,
    success: false,
    token: null,
    role: null,
    org_data: null,
    user_id: null,
    u_email: null,
    irs_account_id: null,
    feature_data: build.feature_data,
    plan_type: null,
};

interface LOGIN {
    type: 'LOGIN';
    payload: {
        success: boolean;
        token: string;
        role: 'Admin' | 'Client' | 'Tax Preparer' | 'Super Admin';
        user_id: string;
        irs_account_id?: string;
        feature_data: Array<any>;
    };
}

interface LOGOUT {
    type: 'LOGOUT';
    payload: null;
}
interface SIGNUP {
    type: 'SIGNUP';
    payload: any;
}

type AuthActionInterface = LOGIN | LOGOUT | SIGNUP;
type AuthActionTypes = LOGIN['type'] | LOGOUT['type'] | SIGNUP['type'];

const authReducer = (state: AuthStateInterface, action: AuthActionInterface) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage?.setItem(COOKIE, action.payload.token);

            return { ...state, ...action.payload, success: true, loading: false };

        case 'SIGNUP':
            localStorage?.setItem(COOKIE, action.payload);

            return { ...state, ...action.payload, success: true, loading: false };

        case 'LOGOUT':
            localStorage?.removeItem(COOKIE);

            delete api.defaults.headers.common['Authorization'];
            delete apiTaxPrep.defaults.headers.common['Authorization'];

            return {
                loading: false,
                success: false,
                token: null,
                role: null,
                user_id: null,
                u_email: null,
                irs_account_id: null,
                feature_data: [],
            };

        default:
            return state;
    }
};

type AuthDispatchContextType = (type: AuthActionTypes, payload: any) => void;
const AuthStateContext = createContext<AuthStateInterface>({} as AuthStateInterface);
const AuthDispatchContext = createContext<AuthDispatchContextType>({} as AuthDispatchContextType);

const isJWTPayload = (decoded: JwtPayload | string | null): decoded is JwtPayload => {
    return (decoded as JwtPayload).exp !== undefined;
};

export const isTokenValid = (token: string) => {
    const decodedToken = decode(token);

    if (isJWTPayload(decodedToken)) {
        const expiryDate = moment((decodedToken.exp as number) * 1000);
        const valid = expiryDate.isAfter(moment.now());

        return valid;
    } else {
        return false;
    }
};

export const AuthProvider: React.FC = ({ children }) => {
    const [state, defaultDispatch] = useReducer(authReducer, initialState);
    const dispatch = (type: AuthActionTypes, payload: any) => defaultDispatch({ type, payload });

    const isIframe = window.location.href.includes('client-autologin');

    const autoLogin = async () => {
        const storedToken = localStorage?.getItem(COOKIE);

        if (storedToken) {
            const isValid = isTokenValid(storedToken);
            if (isValid) return getUserCredentials(storedToken);
        }

        throw 'Login Failed';
    };

    useQuery('credentials', autoLogin, {
        staleTime: 60 * 60 * 1000 * 18,
        enabled: !state.success && !isIframe,
        onSuccess: (data) => {
            dispatch('LOGIN', data);
        },
        onError: () => {
            dispatch('LOGOUT', null);
        },
    });

    useEffect(() => {
        api.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
        apiTaxPrep.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
        transcriptApi.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
    }, [state.token]);

    return (
        <AuthDispatchContext.Provider value={dispatch}>
            <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
        </AuthDispatchContext.Provider>
    );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
