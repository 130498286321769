import moment from 'moment';
import React, { createContext, useReducer, useContext, Dispatch } from 'react';
import { calculatePercentage } from '../utils/helpers';
import { FormStateInterface, FormActionInterface } from '../utils/interfaces';
import { build } from '../config';

const initialState: FormStateInterface = {
    meta_data: { form_status: {}, page_json: {} },
    your_information: {},
    template: {},
    business_data: [],
    dependent_data: [],
    employment_data: [],
    additional_income_data: [],
    bank_account_data: [],
    investment_data: [],
    virtual_currency_data: [],
    insurance_data: [],
    real_estate_data: [],
    vehicle_data: [],
    credit_card_data: [],
    other_assets_data: [],
    lawsuit_info_data: [],
    bankruptcy_data: [],
    abroad_residence_data: [],
    beneficiary_data: [],
    litigation_data: [],
    real_property_outside_us_data: [],
    fund_held_by_third_party_data: [],
    individual_expense_data: [],
    additional_individual_expense_data: [],
    trust_info_data: [],
    safe_deposit_data: [],
    transfer_assets_data: [],
    _errors: {},
    _error_sections: {},
};

const FormStateContext = createContext<FormStateInterface>({} as FormStateInterface);
const FormDispatchContext = createContext<Dispatch<FormActionInterface>>(
    {} as Dispatch<FormActionInterface>,
);

const reducer = (state: FormStateInterface, action: FormActionInterface) => {
    const { data } = action.payload;
    let businessId: string;
    let page_json: any;
    let percentage_complete: number;

    switch (action.type) {
        case 'UPDATE_FORM':
            return { ...state, ...data };

        case 'UPDATE_FORM_BUSINESS':
            const updateBusinessId = action.payload.businessId;
            const businessData = action.payload.data;

            if (updateBusinessId) {
                const updatedBusinessData = state.business_data.map((item: any) => {
                    if (item.id === updateBusinessId) {
                        return { ...item, ...businessData };
                    } else return item;
                });
                return { ...state, business_data: updatedBusinessData };
            } else {
                if (businessData?.business_data[0]?.name) {
                    return {
                        ...state,
                        business_data: [
                            {
                                ...state.business_data[0],
                                id: state.business_data[0]?.id || `new_${moment.now()}`,
                                name: businessData?.business_data[0]?.name,
                            },
                        ],
                    };
                } else return state;
            }

        case 'UPDATE_COMMENTS':
            return {
                ...state,
                meta_data: {
                    ...state.meta_data,
                    form_status: { ...state.meta_data.form_status, ...data },
                },
            };

        case 'UPDATE_COMMENTS_BUSINESS':
            businessId = action.payload.businessId;

            return {
                ...state,
                meta_data: {
                    ...state.meta_data,
                    form_status: {
                        ...state.meta_data.form_status,
                        [businessId]: {
                            ...(state.meta_data.form_status[businessId] || {}),
                            ...data,
                        },
                    },
                },
            };

        case 'UPDATE_PAGE_STATUS':
            page_json = { ...state.meta_data.page_json, ...data };
            percentage_complete = calculatePercentage({
                page_json,
                business_data: state.business_data,
            });

            return {
                ...state,
                meta_data: {
                    ...state.meta_data,
                    page_json,
                    percentage_complete,
                },
            };

        case 'UPDATE_PAGE_STATUS_BUSINESS':
            businessId = action.payload.businessId;
            page_json = {
                ...state.meta_data.page_json,
                [businessId]: { ...(state.meta_data.page_json[businessId] || {}), ...data },
            };

            percentage_complete = calculatePercentage({
                page_json,
                business_data: state.business_data,
            });

            return {
                ...state,
                meta_data: {
                    ...state.meta_data,
                    page_json,
                    percentage_complete,
                },
            };

        case 'UPDATE_CROSSROAD':
            return {
                ...state,
                meta_data: {
                    ...state.meta_data,
                    form_status: { ...state.meta_data.form_status, ...data },
                },
            };

        case 'UPDATE_CROSSROAD_BUSINESS':
            businessId = action.payload.businessId;
            return {
                ...state,
                meta_data: {
                    ...state.meta_data,
                    form_status: {
                        ...state.meta_data.form_status,
                        [businessId]: {
                            ...(state.meta_data.form_status[businessId] || {}),
                            ...data,
                        },
                    },
                },
            };

        case 'SET_ERRORS': {
            const { data, sections } = action.payload;

            return {
                ...state,
                _errors: { ...state._errors, ...data },
                _error_sections: { ...state._error_sections, ...sections },
            };
        }

        case 'SET_BUSINESS_ERRORS':
            return state;

        case 'CLEAR_ERRORS':
            return {
                ...state,
                _errors: {},
                _error_sections: {},
            };

        case 'CLEAR_FORM':
            return initialState;

        default:
            return state;
    }
};

const formReducer = (state: FormStateInterface, action: FormActionInterface) => {
    // This is done for when data is set by API calls
    if (action.type === 'SET_DATA') {
        const { data } = action.payload;
        return { ...state, ...data };
    }

    const newState = reducer(state, action);

    if (build.local_storage && action.type !== 'CLEAR_FORM') {
        localStorage.setItem('form-data-' + state.irs_account_id, JSON.stringify(newState));
    }
    return newState;
};

export const FormProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(formReducer, initialState);

    return (
        <FormDispatchContext.Provider value={dispatch}>
            <FormStateContext.Provider value={state}>{children}</FormStateContext.Provider>
        </FormDispatchContext.Provider>
    );
};

export const useFormState = () => useContext(FormStateContext);
export const useFormDispatch = () => useContext(FormDispatchContext);
