import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '..';
import { useAuthState } from '../../context/auth';
import { build } from '../../config';

const formatData = (list: any[]): { [key: string]: boolean } => {
    let lockDetails = {};

    if (!Array.isArray(list)) {
        return {};
    }

    list.forEach((item) => {
        lockDetails = { ...lockDetails, [item.section_name]: item.section_lock === 1 };
    });

    return lockDetails;
};

const useSectionLock = (irs_account_id: string | undefined | null) => {
    const getLockDetails = async ({ queryKey: [_, id] }: any) => {
        const { data } = await api.get('/getSectionLock/' + id);
        return data.data;
    };

    const { data: lockDetails } = useQuery({
        queryKey: ['get-section-lock', irs_account_id],
        queryFn: getLockDetails,
        enabled: !!irs_account_id && build.section_locking,
    });

    const setLock = async ({ key, value }: { key: string; value: boolean }) => {
        if (!build.section_locking) {
            throw 'Section Lock Feature is not enabled';
        }

        const existing = lockDetails.find((item: any) => item.section_name === key);

        const postData = {
            irs_account_id,
            data: [
                {
                    ...(existing || {}),
                    irs_account_id,
                    action: existing ? 'update' : 'create',
                    section_name: key,
                    section_lock: value ? 1 : 0,
                },
            ],
        };

        await api.post('/sectionLockByAction', postData);
        return true;
    };

    const queryClient = useQueryClient();
    const { mutate: setSectionLock, isLoading } = useMutation({
        mutationKey: ['set-section-lock', irs_account_id],
        mutationFn: setLock,
        onSuccess: () => queryClient.invalidateQueries(['get-section-lock', irs_account_id]),
    });

    return {
        isLoading,
        lockDetails: formatData(lockDetails),
        setSectionLock,
    };
};

export default useSectionLock;
