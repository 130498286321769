import classNames from 'classnames';
import { fieldTypes } from '../../utils/enums';
import YearToggle from './YearToggle';
import useTaxPrepClientData from './useTaxPrepClientData';
import { FormInterface } from '../../utils/interfaces';
import useDependents from '../../api/tax-prep/useDependents';
import FormConstructor from '../taxprep-client-form-components/FormContructor';
import Button from '../basic/Button';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { useState } from 'react';
import Modal from '../basic/Modal';
import { useAuthState } from '../../context/auth';

// export const TAXPREP_TEST_IRS_ACCOUNT_ID = 'e7713e76-1196-11ee-be56-0242ac120002';
// export const TAXPREP_TEST_IRS_ACCOUNT_ID = '1458696d-5c36-42ab-8250-37796e6a9f9f';

const ClientFormSection = () => {
    let {
        year,
        getOptionValue,
        setOptionValue,
        getBusinessOptionValue,
        setBusinessOptionValue,
        formState,
        setFormState,
        businessFormState,
        setBusinessFormState,
    } = useTaxPrepClientData();

    const { currentPage: page, businessId, toggleYear } = useTaxPrepStructure();

    let formData = formState;
    let setFormData = setFormState;

    if (businessId) {
        formData = businessFormState;
        setFormData = (params) => {
            setBusinessFormState(businessId, params);
        };
        getOptionValue = (path: string) => {
            return getBusinessOptionValue(businessId, path);
        };
        setOptionValue = (params) => {
            setBusinessOptionValue(businessId, params);
        };
    }

    if (!page) {
        return <div className="m-60">Unknown page</div>;
    }

    const setYear = (nextYear: string) => {
        toggleYear(nextYear);
    };

    return (
        <div className="flex-grow flex justify-center mt-10">
            <div className="flex flex-col w-full max-w-2xl px-1 py-3">
                <YearToggle year={year!} setYear={setYear} />

                <FormConstructor
                    className={classNames('flex flex-grow')}
                    structure={page.formStructure}
                    formState={formData}
                    setFormState={setFormData}
                    getOptionValue={getOptionValue}
                    setOptionValue={setOptionValue}
                />
                {/* <Button isLoading={updateClient.isLoading} onClick={() => updateClient.mutate()}>
                    SAVE
                </Button> */}
            </div>
        </div>
    );
};

export default ClientFormSection;
