import { upsertBusinessInvestment } from '../../api/business';
import { Investment_Type, usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const investmentForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Name',
        placeholder: 'Bitcoin',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'type_of_investment',
        label: 'Investment Type',
        options: Investment_Type,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Account Number',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_phone',
        label: 'Phone',
        placeholder: '1000',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Company Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'company_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        id: 'used_as_collateral_ind',
        label: 'Used as collateral on loan',
        span: 2,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Current Value',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'loan_balance',
        label: 'Loan Balance',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_investment_data',
        label: 'Does your business have any Investments?',
        header: 'Business Investment Information',
        formStructure: investmentForm,
        displayColumns: [
            {
                name: 'Name',
                key: 'company_name',
            },
            {
                name: 'Current Value',
                key: 'current_value',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'investments',
    name: 'Business Investments',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertBusinessInvestment,
};
