import { api } from '.';

export const fetchClient = async (irs_account_id: string) => {
    const { data } = await api.get(`/client/${irs_account_id}`);
    return data.data;
};

export const updateClient = async (updateData: any) => {

    const { data } = await api.put(`/updateClient/${updateData.irs_account_id}`, updateData);
    return data.data;
};

export const fetchClientWithDependent = async (irs_account_id: string) => {
    const { data } = await api.get(`/ClientWithDependent/${irs_account_id}`);
    return { ...data.data[0], meta_data: data.meta_data };
};

export const upsertDependents = async ({ dependent_data, meta_data }: any) => {
    const regEx = /^new/;
    const data = dependent_data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: meta_data.irs_account_id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });


    return api
        .post(`/dependentByAction`, {
            irs_account_id: meta_data.irs_account_id,
            meta_data,
            data,
        })
        .then((response) => {
            return response.data;
        });
};
