import { upsertNotesRecievable } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const notesReceivable: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_note_name',
        label: 'Name',
        placeholder: '',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'amount_due',
        label: 'Amount Owed',
        placeholder: '$12,000',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'status',
        label: 'Status',
        placeholder: '',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'date_due',
        label: 'Due date',
        placeholder: '$12,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'invoice_grant_contract_number',
        label: 'Invoice/Grant No.',
        placeholder: '',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_note_receivable_data',
        label: 'How many Notes receivable does your business has?',
        header: 'Notes Receivable Information',
        formStructure: notesReceivable,
        displayColumns: [
            {
                name: 'Note Name',
                key: 'account_note_name',
            },
            {
                name: 'Amount Owed',
                key: 'amount_due',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_notes_receivable',
    name: 'Business Notes Receivable',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertNotesRecievable,
};
