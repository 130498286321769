import { useLocation, useParams } from 'react-router-dom';
import { enumSections } from '../utils/enums';
import { PageInterface, SectionInterface } from '../utils/interfaces';
import NavBar from '../components/basic/NavBar';
import {
    compareItemsforChange,
    pageRefiner,
    useFeatureEnabled,
    useIsMobile,
} from '../utils/helpers';
import { useFormDispatch, useFormState } from '../context/form';
import classNames from 'classnames';
import EvalSection from '../components/basic/EvalSection';
import { getfetchFunction } from '../api';
import { useQuery } from 'react-query';
import Menu from '../components/basic/EvalSectionMenu';
import FloatingSaveButton from '../components/basic/FloatingSaveButton';
import { useEffect, useMemo, useState } from 'react';
import Modal from '../components/basic/Modal';
import FormConstructor from '../components/basic/AdminFormConstructor';
import { scroller } from 'react-scroll';
import { useSaveDispatch } from '../context/save';
import GenerateFormModal from '../components/basic/GenerateForm';
import Button from '../components/basic/Button';
import { useAuthState } from '../context/auth';
import useSectionLock from '../api/hooks/useSectionLocks';
import { build } from '../config';
import warning_logo from './../assets/warning-icon.png';

const ConfirmDataLoading: React.FC<{
    close: () => void;
    isOpen: boolean;
    loadData: () => any;
    clearData?: () => any;
}> = ({ isOpen, close, loadData, clearData }) => {
    return (
        <Modal isOpen={isOpen} close={close}>
            <div className="rounded-md">
                <div
                    style={{ background: '#f7e5c4' }}
                    className="flex font-bold justify-center align-middle h-9 w-full"
                >
                    <img src={warning_logo} className="h-8" alt="warning-logo" />
                </div>
                <div className="bg-white px-5 py-8 " style={{ minWidth: 500 }}>
                    <p className="text-xl font-semibold text-gray-800 text-center mb-2">
                        Are you sure?
                    </p>
                    <p className="text-sm text-center text-gray-500">
                        You want to retrieve the unsaved data from previous session?
                    </p>
                    <p className="text-sm text-center text-gray-500 mb-8">
                        This action will override the unsaved data of current session
                    </p>
                    <div className="flex w-full justify-center">
                        <Button
                            onClick={() => {
                                loadData();
                                close();
                            }}
                            // isLoading={mutation.isLoading}
                            className="text-center w-1/2 mr-2"
                            // style={{ background: '#ffa500' }}
                        >
                            {'Confirm'}
                        </Button>
                        <Button
                            onClick={() => {
                                close();
                                // clearData();
                            }}
                            secondary
                            className="text-center w-1/2 ml-2"
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const BusinessHome: React.FC<{ page: PageInterface | undefined }> = ({ page }) => {
    const { irs_account_id } = useParams();

    const formState = useFormState();
    const dispatch = useFormDispatch();
    const saveDispatch = useSaveDispatch();

    const { success } = useAuthState();

    const setFormState = ({ id, value }: { id: string; value: any }) => {
        dispatch({
            type: 'UPDATE_FORM',
            payload: { data: { [id]: value } },
        });
    };

    const fetchFunction = getfetchFunction(page?.sectionId!);
    const { data: serverData } = useQuery(
        [`${page?.sectionId}`, irs_account_id],
        () => fetchFunction(irs_account_id as string),
        { enabled: !!irs_account_id && success },
    );

    useEffect(() => {
        if (page?.address !== 'business_home') return;
        if (!serverData) return;

        const isChanged = compareItemsforChange(formState.business_data, serverData.business_data);
        if (isChanged) {
            saveDispatch('ADD_ADDRESS', { address: 'business_home' });
        } else {
            saveDispatch('REMOVE_ADDRESS', { address: 'business_home' });
        }
    }, [formState]);

    if (page?.address !== 'business_home') return <></>;

    const { formStructure, address, section } = page;
    return (
        <div
            id="business_home"
            key="business_home"
            className="flex flex-col w-full bg-white rounded-md relative"
        >
            <div className="border-b-2 border-primary-500">
                <div className="font-title p-2">BUSINESS INFORMATION</div>
            </div>

            <div className="flex-grow flex justify-center p-2">
                <FormConstructor
                    className={classNames('flex flex-grow')}
                    structure={formStructure}
                    formState={formState}
                    setFormState={setFormState}
                />
            </div>
        </div>
    );
};

const Section: React.FC<{ key: number; section: SectionInterface }> = ({ key, section }) => {
    const { subSections } = section;
    const { irs_account_id } = useParams();

    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);

    const { business_data } = useFormState();
    const formDispatch = useFormDispatch();

    const fetchFunction = getfetchFunction(section.id);

    const { success } = useAuthState();
    const { data, isSuccess, isLoading } = useQuery(
        [`${section.id}`, irs_account_id],
        () => fetchFunction(irs_account_id as string),
        {
            enabled: !!irs_account_id && success,
            onSuccess: (data) => {
                formDispatch({ type: 'SET_DATA', payload: { data: data } });
            },
            onError: () => {
                setIsError(true);
            },
        },
    );

    useEffect(() => {
        if (data && isSuccess) {
            formDispatch({ type: 'SET_DATA', payload: { data: data } });
        }
    }, [irs_account_id]);

    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const scrollId = query.get('scroll');

    useEffect(() => {
        if (scrollId && !isScrolled) {
            scroller.scrollTo(scrollId, { smooth: true });
            setIsScrolled(true);
        }
    }, [scrollId, data]);

    if (isError) {
        return (
            <Modal isOpen={isError} close={() => {}}>
                <div className="bg-white rounded-md" style={{ minWidth: 500 }}>
                    <div className="px-5 font-title py-2 font-black text-white bg-red-500">{`Oops!`}</div>
                    <div className="px-5 pt-2 pb-8  ">
                        <div className="font-body py-2">{`Something went wrong fetching data...`}</div>
                        <div className="font-body py-2">{`Click the button to fix the issue`}</div>

                        <div className="flex w-full">
                            <Button
                                onClick={() => window.location.reload()}
                                className="text-center flex-grow mr-2"
                            >
                                {'RELOAD PAGE'}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return section.id !== enumSections.BUSINESS_INFORMATION ? (
        <div key={key} className="w-full ">
            {subSections.map((subSection, index) => {
                return <EvalSection key={index} currentPage={subSection} isLoading={isLoading} />;
            })}
        </div>
    ) : (
        <>
            <BusinessHome page={subSections.find(({ address }) => address === 'business_home')} />
            {business_data.map((business: any, index: number) => {
                return (
                    <div key={index + irs_account_id!} className="w-full">
                        {subSections.map((subSection, index) => {
                            return (
                                <EvalSection
                                    isLoading={isLoading}
                                    key={index}
                                    currentPage={subSection}
                                    business={business}
                                />
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
};

const BasicInformation: React.FC = () => {
    const [formModal, setFormModal] = useState<boolean>(false);
    const { first_name, last_business_name } = useFormState();
    const [foundLocal, setFoundLocal] = useState<boolean>(false);
    const [confirmDataLoadidng, setConfirmDataLoadModal] = useState(false);
    const formDispatch = useFormDispatch();
    const saveDispatch = useSaveDispatch();
    const { irs_account_id } = useParams();
    const retrieveLocalInfo = async () => {
        const saveState = localStorage.getItem('save-state-' + irs_account_id);
        const formData = localStorage.getItem('form-data-' + irs_account_id);

        if (formData) {
            setFoundLocal(build.local_storage && true);
        }
    };

    useEffect(() => {
        retrieveLocalInfo();
    }, []);
    const { success: isFormEnabled } = useFeatureEnabled('Form');
    const loadLocalStorage = () => {
        const saveState = localStorage.getItem('save-state-' + irs_account_id);
        const formData = localStorage.getItem('form-data-' + irs_account_id);
        if (formData) {
            formDispatch({ type: 'SET_DATA', payload: { data: JSON.parse(formData) } });
        }

        if (saveState) {
            saveDispatch('SET_DATA', JSON.parse(saveState));
        }

        setFoundLocal(build.local_storage && false);
    };

    const clearLocalStorage = () => {
        localStorage.removeItem('save-state-' + irs_account_id);
        localStorage.removeItem('form-data-' + irs_account_id);

        setFoundLocal(build.local_storage && false);
    };

    return (
        <div className="sticky top-10 z-10 shadow-lg">
            {foundLocal && (
                <div
                    className={classNames(
                        'flex flex-col items-center max-w-4xlw-full cursor-pointer rounded-md justify-center',
                    )}
                    style={{ minWidth: 550, minHeight: 48, background: '#f7e5c4' }}
                >
                    <div className="w-full flex px-4 py-1 items-center">
                        <img src={warning_logo} className="h-5 mr-1" alt="warning-logo" />
                        <div className="flex-grow text-sm">{`Want to pick up where you left off? Your previously entered information is still here.`}</div>
                        <div
                            // onClick={loadLocalStorage}
                            style={{ background: '#ffa500' }}
                            onClick={() => setConfirmDataLoadModal(true)}
                            className="px-3 py-2 cursor-pointer text-sm text-gray-800 font-title"
                        >
                            {`Retrieve Unsaved Data`}
                        </div>
                        <div
                            onClick={clearLocalStorage}
                            className="px-3 py-2 ml-2 cursor-pointer underline text-sm text-black font-title"
                        >
                            {`Cancel`}
                        </div>
                    </div>
                    {/* )} */}
                </div>
            )}
            <div
                className="flex w-full mt-3 py-2 px-3 bg-white rounded-md items-center"
                style={{ minWidth: 550 }}
            >
                {first_name ? (
                    <div className="font-title text-lg flex-grow">
                        {first_name + ' ' + last_business_name}
                    </div>
                ) : (
                    <span className="flex font-title text-lg flex-grow items-center ">
                        <span
                            className="material-icons animate-spin h-5 w-5 flex items-center justify-center"
                            style={{ fontSize: 22 }}
                        >
                            refresh
                        </span>
                        <div className="ml-3">Loading</div>
                    </span>
                )}

                {isFormEnabled && (
                    <div
                        onClick={() => setFormModal(true)}
                        className="px-3 py-2 bg-primary-500 cursor-pointer hover:bg-primary-700 text-sm text-white font-title"
                    >
                        GENERATE FORM
                    </div>
                )}
                <ConfirmDataLoading
                    close={() => setConfirmDataLoadModal(false)}
                    isOpen={confirmDataLoadidng}
                    loadData={loadLocalStorage}
                    clearData={clearLocalStorage}
                />
                <Modal close={() => setFormModal(false)} isOpen={formModal}>
                    <GenerateFormModal close={() => setFormModal(false)} />
                </Modal>
            </div>
        </div>
    );
};

const EvalScreen: React.FC<{ iframe?: boolean }> = ({ iframe }) => {
    const { pages, sections } = pageRefiner();
    const isMobile = useIsMobile();

    const [menu, setMenu] = useState<boolean>(false);
    const formState = useFormState();

    return (
        <>
            <div className="flex flex-col w-full h-full bg-gray-200">
                <NavBar iframe={iframe} toggleMenu={() => setMenu(!menu)} />
                {isMobile && (
                    <div
                        onClick={() => setMenu(!menu)}
                        className="flex mr-3 items-center justify-center"
                    >
                        <span className="material-symbols-outlined text-white">menu</span>
                    </div>
                )}
                <div className={classNames('w-full h-screen items-center', { 'mt-10': iframe })}>
                    <div className="flex w-full mt-3">
                        {!isMobile ? (
                            <Menu iframe={iframe} closed={iframe} />
                        ) : (
                            menu && (
                                <div className="z-50 fixed top-10 h-screen">
                                    <Menu closed close={() => setMenu(false)} />
                                </div>
                            )
                        )}
                        <div className="w-full flex justify-center bg-gray-100 px-2">
                            <div className="relative grid grid-cols-1 max-w-4xl w-full pt-10">
                                <BasicInformation />

                                {sections.map((section, index) => {
                                    return <Section key={index} section={section} />;
                                })}

                                <FloatingSaveButton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EvalScreen;
