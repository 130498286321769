import { upsertTransferredAssets } from '../../api/business';
import { upsertSafeDepositBox } from '../../api/other';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const assetForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        span: 2,
        id: 'asset_name',
        label: 'List Assets',
        placeholder: '',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'value_at_time_of_transfer',
        label: 'Value at Time of Transfer',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'date_transferred',
        label: 'Date Transferred',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'to_whom_transferred',
        label: 'To Whom or Where Transferred',
        placeholder: 'johndoe@hotmail.com',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        label: 'Have any assets been transferred, in the last 10 years, from this business for less than full value?',
        id: 'business_transfer_assets_data',
        header: 'Transferred Assets',
        formStructure: assetForm,
        displayColumns: [
            {
                name: 'List Assets',
                key: 'asset_name',
            },
            {
                name: 'Value',
                key: 'value_at_time_of_transfer',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_transfer_assets',
    name: 'Business Transferred Assets',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertTransferredAssets,
};
