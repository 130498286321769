import { themes } from './index';

export interface ITheme {
    [key: string]: string;
}

export interface IThemes {
    [key: string]: ITheme;
}

export interface IMappedTheme {
    [key: string]: string | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
    return {
        '--color-primary-100': variables['primary-100'] || '',
        '--color-primary-200': variables['primary-200'] || '',
        '--color-primary-300': variables['primary-300'] || '',
        '--color-primary-400': variables['primary-400'] || '',
        '--color-primary-500': variables['primary-500'] || '',
        '--color-primary-600': variables['primary-600'] || '',
        '--color-primary-700': variables['primary-700'] || '',
        '--color-primary-800': variables['primary-800'] || '',
        '--color-primary-900': variables['primary-900'] || '',
    };
};

export const applyTheme = (theme: string): void => {
    const themeObject: IMappedTheme = mapTheme(themes[theme]);
    if (!themeObject) return;

    const root = document.documentElement;

    Object.keys(themeObject).forEach((property) => {
        if (property === 'name') {
            return;
        }

        root.style.setProperty(property, themeObject[property]);
    });
};

export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
    return { ...extending, ...newTheme };
};
