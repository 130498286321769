import { useState } from 'react';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { api } from '..';
import { build } from '../../config';
import { useAuthState } from '../../context/auth';
import { useFeatureEnabled } from '../../utils/helpers';

type DocumentUploadArgs = {
    document: File;
    section: string;
};

type DocumentDeleteArgs = {
    id: string;
};

type CreateRequestArgs = {
    description: string;
    section: string;
};

type FulfillRequestArgs = {
    document: File;
    id: string;
};

type DeleteRequestArgs = {
    id: string;
    irs_account_id?: string;
};

const useDocuments = () => {
    const { token, user_id, irs_account_id: auth_irs_id, role } = useAuthState();
    const { irs_account_id: client_irs } = useParams();

    const { success: isDocumentEnabled } = useFeatureEnabled('Document');

    const irs_account_id = role === 'Client' ? auth_irs_id : client_irs;
    const [errors, setErrors] = useState<any>({});

    const getDocuments = async (): Promise<any> => {
        const { data } = await api.get(`/getDocList/` + irs_account_id);
        return data.data;
    };

    const { data: allDocuments, isLoading } = useQuery({
        queryKey: ['document-list', irs_account_id],
        queryFn: getDocuments,
        enabled: !!token && !!irs_account_id && !!isDocumentEnabled,
        onError: (error: any) => setErrors(error.response.data),
    });

    const uploadDocument = async (createData: DocumentUploadArgs): Promise<{}> => {
        let formData = new FormData();
        formData.append('document', createData.document);
        formData.append('section', createData.section);
        formData.append('irs_account_id', irs_account_id!);
        console.log('file doc formdata', formData);
        const headers = {
            'Content-Type': 'multipart/form-data',
            // Encrypt: 'multipart/form-data',
        };
        console.log('creating document upload', { createData, formData });

        await api.post('/postDocument', formData, { headers });
        return true;
    };

    const deleteServerDocument = async (createData: DocumentDeleteArgs): Promise<{}> => {
        await api.delete('/deleteDocument/' + createData.id);
        return true;
    };

    const createDocumentRequest = async (createData: any): Promise<{}> => {
        const payload = {
            user_id,
            action: 'create',
            irs_account_id: irs_account_id!,
            id: createData?.id!,
            ...createData,
        };

        await api.post('/requestedDocument', payload);
        return true;
    };

    const fulfillDocumentRequest = async (createData: FulfillRequestArgs): Promise<{}> => {
        var formData = new FormData();
        formData.append('action', 'update');
        formData.append('id', createData.id);
        formData.append('irs_account_id', irs_account_id!);
        formData.append('document', createData.document);

        const headers = {
            'Content-Type': 'multipart/form-data',
            // Encrypt: 'multipart/form-data',
        };

        await api.post('/requestedDocument', formData, { headers });
        return true;
    };

    const deleteDocumentRequest = async (createData: any): Promise<{}> => {
        console.log('createData', createData)
        const payload = {
            action: 'delete',
            ...createData,
        };

        await api.post('/requestedDocument', payload);
        return true;
    };

    const queryClient = useQueryClient();
    const upload = useMutation(uploadDocument, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries('document-list');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const deleteDocument = useMutation(deleteServerDocument, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries('document-list');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const createRequest = useMutation(createDocumentRequest, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries('document-list');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const fulfillRequest = useMutation(fulfillDocumentRequest, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries('document-list');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const deleteRequest = useMutation(deleteDocumentRequest, {
        onMutate: () => setErrors({}),
        onSuccess: () => {
            queryClient.invalidateQueries('document-list');
        },
        onError: (error: any) => setErrors(error.response.data),
    });

    const documents = allDocuments?.filter((doc: any) => doc.request_fullfill === null);
    const requests = allDocuments?.filter((doc: any) => doc.request_fullfill !== null);

    return {
        allDocuments,
        documents,
        requests,
        upload,
        deleteDocument,
        isLoading,
        errors,
        setErrors,
        createRequest,
        fulfillRequest,
        deleteRequest,
    };
};

export default useDocuments;
