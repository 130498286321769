import { useState } from 'react';
import classNames from 'classnames';

interface ModalProps {
    isOpen: boolean;
    close: () => void;
    title?: string;
    light?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, close, title, children, light = false }) => {
    const handleOverlayClick = (event: any) => {
        if (event.target === event.currentTarget) close();
    };

    return isOpen ? (
        <div
            onClick={handleOverlayClick}
            className={classNames(
                'fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full p-6 overflow-auto ',
                { 'bg-black bg-opacity-60': !light, 'bg-opacity-100': light },
            )}
        >
            {children}
        </div>
    ) : (
        <></>
    );
};

export default Modal;
