import React, { useState, useEffect } from 'react';
import DateTimePicker from './components/client/Calendar';
import Dropdown from './components/client/Dropdown';
import HeadingGroup from './components/client/HeadingGroup';
import InputGroup from './components/client/InputGroup';
import RadioGroup from './components/client/Radio';
import FormConstructor from './components/basic/FormConstructor';
import { fieldTypes } from './utils/enums';
import { template } from './utils/forms';
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate,
    useLocation,
    Outlet,
    useSearchParams,
    useParams,
} from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactTooltip from 'react-tooltip';

import FormSection from './pages/FormSection';
import LoginScreen from './pages/LoginScreen';
import HomeScreen from './pages/HomeScreen';
import EvalScreen from './pages/EvalScreen';
import ClientManager from './pages/ClientManager';
import TaxProManager from './pages/TaxProManager';
import { useAuthState } from './context/auth';
import DeploymentManager from './pages/DeploymentManager';
import ForgotPassword from './pages/ForgotPassword';
import ClientSettings from './components/client/Settings';
import AdminSettings from './components/admin/Settings';
import IFrameForm from './pages/IFrameForm';

import { applyTheme } from './config/themes/utils';
import './App.css';
import { build } from './config';
import IFrameFormV2 from './pages/IFrameFormV2';
import { MicrosoftCallback } from './components/basic/MicrosoftCallback';
import AutoLoginClient from './pages/AutoLoginClient';
import SuperClient from './pages/SuperClient';
import TaxPrepClientForm from './components/taxprep/TaxPrepClientForm';

import AdminList from './pages/AdminList';
import { ToastContainer } from 'react-toastify';
import SuperClientsFeatures from './pages/SuperClientsFeatures';
import SectionSelection from './components/taxprep/SectionSelection';
import CopyBusiness from './components/taxprep/CopyBusiness';
import TaxPrepEvalScreen from './components/taxprep/TaxPrepEvalScreen';
import TPNavigator from './pages/TPNavigator';
import SignUpScreen from './pages/SignUpScreen';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { components } from 'react-select';
import OrganizationSignUp from './components/basic/OrganizationSignUp';
import NotificationsFullView from './pages/NotificationsFullView';
import UserTranscripts from './components/basic/user-dashboard/userTranscripts';
import UserBilling from './components/basic/user-dashboard/userBilling/UserBilling';
import FormDownload from './components/basic/user-dashboard/FormDownload';
import Layout from './components/basic/user-dashboard/layout/UserDashLayout';
import UserForms from './components/basic/user-dashboard/userForms';
import UserProfile from './components/basic/user-dashboard/UserProfile';
import Documents from './components/basic/user-dashboard/Documents';
interface formInterface {
    firstName: string;
    flavour: string;
    business?: any;
    dob?: string;
    dependents: any[];
    expenses: any[];
}

const FormComponents = () => {
    const [form, setForm] = useState<formInterface>({
        firstName: '',
        flavour: '',
        dependents: [],
        expenses: [],
    });

    const setKey = (id: any, value: any) => {
        setForm((state) => ({ ...state, [id]: value }));
    };

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
                width: '100%',
            }}
        >
            <div style={{ gridColumn: `span 1 / span 1` }}>
                <HeadingGroup label="Individual Components" />

                <InputGroup
                    id="firstName"
                    label="First Name"
                    placeholder="John"
                    value={form.firstName}
                    setValue={(value) => setKey('firstName', value)}
                    type={fieldTypes.TEXT_INPUT}
                />

                <Dropdown
                    label="Flavour"
                    placeholder={'Chocolate'}
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' },
                    ]}
                    id="flavour"
                    value={form.flavour}
                    setValue={(value) => setKey('flavour', value)}
                    type={fieldTypes.DROPDOWN}
                />

                <RadioGroup
                    id="business"
                    label="Have any businesses?"
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ]}
                    value={form.business}
                    setValue={(value: boolean) => setKey('business', value)}
                    type={fieldTypes.RADIO}
                />

                <DateTimePicker
                    id="dob"
                    label="Date of Birth"
                    placeholder="Click to Select date"
                    value={form.dob}
                    setValue={(value) => setKey('dob', value)}
                    type={fieldTypes.CALENDAR}
                />
            </div>
            <div style={{ gridColumn: `span 1 / span 1` }}>
                <HeadingGroup label="Script Generated Form" />
                <FormConstructor
                    structure={template}
                    formState={form}
                    setFormState={({ id, value }) => setKey(id, value)}
                />
            </div>
        </div>
    );
};

const UI = () => {
    return (
        <div className="h-screen w-full flex bg-red-300">
            <div className="max-w-sm flex-grow bg-yellow-300"></div>
            <div className="flex-grow flex flex-col bg-green-300">
                <div className="w-full h-16 bg-red-300">A</div>
                <div className="w-full flex-grow  bg-blue-300 overflow-y-auto">
                    {[...Array(124)].map(() => (
                        <div className="flex item justify-center">
                            <div className="max-w-xs w-full bg-primary-600">VB</div>
                        </div>
                    ))}
                </div>
                <div className="w-full h-16 bg-pink-300">C</div>
            </div>
        </div>
    );
};

const RequireAuth: React.FC = ({ children }) => {
    const { success, loading } = useAuthState();
    const authS = useAuthState();
    let location = useLocation();

    if (loading) return <></>;

    if (!success) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <>{children}</>;
};
const RequiredAuth: React.FC = ({ children }) => {
    const { success, loading } = useAuthState();

    let location = useLocation();

    if (loading) return <></>;

    if (!success) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="sa/login" state={{ from: location }} replace />;
    }

    return <>{children}</>;
};

const IframePOC = () => {
    const [searchParams] = useSearchParams();
    const padding = searchParams.get('p');

    return (
        <iframe
            src="http://localhost:3000/iframe-evaluate/8f36a145-0d4c-46b0-8b22-7366540df5b4?tax_pro_id=31d7463b-5810-48f7-9e92-3af398797319&source=salesforce"
            className={`w-full h-screen bg-red-500 p-${padding}`}
        />
    );
};

const AutoLoginClientTest = () => {
    const { access_token } = useParams();
    return (
        <iframe
            style={{ border: '5px solid #ddd' }}
            src={build.URL + '/client-autologin/' + access_token}
        />
    );
};

const App = () => {
    /**
     * Run the applyTheme function every time the theme state changes
     */
    useEffect(() => {
        applyTheme(build.theme || 'base');
    }, []);

    return (
        <div className="App font-body flex flex-col items-center h-screen">
            <ToastContainer />
            <div className="w-full">
                <BrowserRouter>
                    <Routes>
                        <Route path="/callback" element={<MicrosoftCallback />} />
                        <Route path="/linkedin" element={<LinkedInCallback />} />
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="/login" element={<LoginScreen />} />
                        <Route path="/sign-up" element={<SignUpScreen />} />
                        <Route path="/sign-up-org" element={<OrganizationSignUp />} />
                        <Route path="/sa/login" element={<LoginScreen />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/a/settings" element={<AdminSettings />} />
                        <Route path="/c/settings" element={<ClientSettings />} />

                        <Route path="/iframe-poc" element={<IframePOC />} />
                        <Route path="/iframe-evaluate/:irs_account_id" element={<IFrameForm />} />

                        <Route
                            path="/client-autologin/:access_token"
                            element={<AutoLoginClient />}
                        />
                        <Route
                            path="/client-autologin-test/:access_token"
                            element={<AutoLoginClientTest />}
                        />
                        <Route
                            path="/iframe-evaluate-v2/:Encoded_Encrypted_Text/:Encoded_Timestamp"
                            element={<IFrameFormV2 />}
                        >
                            /
                        </Route>

                        <Route
                            path="/test/section-select/:year"
                            element={<SectionSelection Close={() => {}} />}
                        />
                        <Route path="/test" element={<FormComponents />} />
                        <Route path="/test2" element={<UI />} />
                        <Route
                            element={
                                <RequireAuth>
                                    <Outlet />
                                </RequireAuth>
                            }
                        >
                            <Route
                                path="/test/tax-prep/:address/:year/"
                                element={<TaxPrepClientForm />}
                            />

                            <Route path="/form/:address/" element={<FormSection />} />
                            <Route path="/tp-navigator/:irs_account_id" element={<TPNavigator />} />
                            <Route path="/notifications" element={<NotificationsFullView />} />
                            <Route
                                path="/client-dashboard/:irs_account_id/form-download/:form"
                                element={<FormDownload />}
                            />
                            <Route path="/form/:address/:businessId" element={<FormSection />} />
                            <Route path="/section/:sectionId/" element={<FormSection />} />
                            <Route path="/section/:sectionId/" element={<FormSection />} />

                            <Route path="/iframe-form/:address/" element={<FormSection />} />
                            <Route
                                path="/iframe-form/:address/:businessId"
                                element={<FormSection />}
                            />
                            <Route path="/iframe-section/:sectionId/" element={<FormSection />} />

                            <Route path="/home" element={<HomeScreen />} />
                            <Route path="/iframe-home" element={<HomeScreen />} />

                            <Route path="/summary" element={<EvalScreen />} />
                            <Route path="/client" element={<ClientManager />} />
                            <Route path="/taxprep" element={<TaxProManager />} />
                            {build.admin_list && <Route path="/admin" element={<AdminList />} />}
                            <Route path="/evaluate/:irs_account_id" element={<EvalScreen />} />
                            <Route
                                path="/taxprep-evaluate/:irs_account_id/:year"
                                element={<TaxPrepEvalScreen />}
                            />
                            <Route path="/superAdmin" element={<SuperClient />} />
                            <Route
                                path="/superAdmin/clientsFeatures/:id"
                                element={<SuperClientsFeatures />}
                            />
                            <Route path="/deploy" element={<DeploymentManager />} />
                            {/* routes and layout for the transcripts UI */}
                            <Route
                                element={
                                    <Layout>
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                <Route
                                    path="/client/:irs_account_id/profile/"
                                    element={<UserProfile />}
                                />
                                <Route
                                    path="/client/:irs_account_id/transcripts/:type"
                                    element={<UserTranscripts />}
                                />
                                <Route
                                    path="/client/:irs_account_id/documents/"
                                    element={<Documents />}
                                />
                                <Route
                                    path="/client/:irs_account_id/forms/"
                                    element={<UserForms />}
                                />
                                <Route
                                    path="/client/:irs_account_id/billings/"
                                    element={<UserBilling />}
                                />
                                <Route
                                    path="/client/:irs_account_id/tasks/"
                                    element={<>coming soon</>}
                                />
                            </Route>
                            {/* <Route path="/client-dashboard" element={<Layout />}></Route> */}
                            {/* <Route path="/client-dashboard/+" element={<Index />} /> */}
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div>

            <ReactQueryDevtools initialIsOpen={true} />
            <ReactTooltip />
        </div>
    );
};

export default App;
