export default {
    'primary-100': '#cde5e5',
    'primary-200': '#9bccca',
    'primary-300': '#6ab2b0',
    'primary-400': '#389995',
    'primary-500': '#067f7b',
    'primary-600': '#056662',
    'primary-700': '#374151',
    'primary-800': '#023331',
    'primary-900': '#011919',
};
