import { api } from '.';
import { apiTaxPrep } from './tax-prep';

export const getClients = async (): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.get(`/getAllClient`);
    return data;
};
export const getSuperClient = async (): Promise<{ sucess: boolean;[key: string]: any }> => {
    const { data } = await api.get(`/featureClientList`);
    return data;
};

export const getTaxPro = async (): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.get(`/getAllTaxPreparer`);
    return data;
};

export const addClient = async (clientData: {
    u_email: string;
    u_first_name: string;
    u_last_name: string;
    token: string;
    tax_preparer_id?: string;
    u_service_type?: string[];
    u_taxprep_years?: number[];
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/register`, { ...clientData, u_role: 'Client' });
    return data;
};

export const editClient = async ({
    formData,
    user_id,
}: any): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await apiTaxPrep.put(`/updateUserClient/${user_id}`, formData);
    return data;
};

export const deleteUser = async (userData: {
    user_id: string;
    client_type: 'taxprep' | 'client' | 'Admin';
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/deleteClient`, userData);
    return data;
};

export const changeStatus = async (userData: {
    u_id: string;
    u_status: 'Inactive' | 'Active';
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/userInactive`, userData);
    return data;
};

export const deleteSuperClient = async (userData: {
    action: string;
    id: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/clientCreateMycase`, userData);
    return data;
};

export const resendUser = async (resendData: {
    u_id: string;
    u_email: string;
    u_first_name: string;
    u_last_name: string;
    role_name: 'Admin' | 'Client' | 'Tax Preparer';
}): Promise<{}> => {
    const { data } = await api.post(`/resendPassword`, resendData);
    return data;
};

export const assignTaxPro = async (
    assignData: Array<{
        user_id: string;
        irs_account_id: string;
        tax_preparer_id: string;
    }>,
): Promise<{}> => {
    const { data } = await api.post(`/massAssignTaxprep`, { data: assignData });
    return data;
};

export const addTaxPro = async (taxProData: {
    u_email: string;
    u_first_name: string;
    u_last_name: string;

    u_phone_no: string;
    u_fax: string;
    u_caf_no: string;
    u_ptin: string;

    u_designation: string;
    u_jurisdiction: string;
    u_bar: string;

    token: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/register`, { ...taxProData, u_role: 'TaxPreparer' });
    return data;
};

export const editTaxPro = async (taxProData: {
    user_id: string;

    u_id: string;
    u_user_name: string;
    u_email: string;
    u_first_name: string;
    u_last_name: string;

    u_phone_no: string;
    u_fax: string;
    u_caf_no: string;
    u_ptin: string;

    u_designation: string;
    u_jurisdiction: string;
    u_bar: string;

    token: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/updateTaxpreparer`, { ...taxProData });
    return data;
};

export const assignClient = async (taxProData: {
    u_email: string;
    u_first_name: string;
    u_last_name: string;
    token: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/clientsAssign`, { ...taxProData, u_role: 'TaxPreparer' });
    return data;
};

export const generateForm = async (generateFormData: {
    irs_account_id: string;
    form_name: string;
    extraData?: any;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { irs_account_id, form_name, extraData } = generateFormData;

    const { data } = await api.post(`/getForms`, {
        irs_account_id,
        form_name,
        ...(extraData || {}),
    });

    return data;
};

export const addAdmin = async (adminData: {
    u_email: string;
    u_first_name: string;
    u_last_name: string;
    u_phone_no: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/register`, { ...adminData, u_role: 'Admin' });
    return data;
};

export const editAdmin = async (adminData: {
    user_id: string;
    u_email: string;
    u_first_name: string;
    u_last_name: string;
    u_phone_no: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/updateTaxpreparer`, { ...adminData });
    return data;
};

//password confirmation before exporting data
export const ExportPasswordModal = async (expData: {
    password: string;
    request_type: 'Admin' | 'Client' | 'Tax Preparer';
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/exportAllData`, { ...expData });
    return data;
};

export const updateCFMutation = async (updateEnableFeatures: {
    id?: string;
    document: boolean | 1 | 0;
    form: boolean | 1 | 0;
    form_list: string[];
    export: boolean | 1 | 0;
    sso_login: boolean | 1 | 0;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/updateEnableFeatures`, { ...updateEnableFeatures });
    return data;
};

export const addSuperClient = async (addData: {
    client_name: string;
    client_url: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/clientCreateMycase`, { ...addData, action: 'create' });
    return data;
};

export const editSuperClient = async (editData: {
    client_name: string;
    client_url: string;
    id: string;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await api.post(`/clientCreateMycase`, { ...editData, action: 'update' });
    return data;
};

export const addNewTaxPrepYear = async (userData: {
    irs_account_id: string;
    years: Array<number>;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await apiTaxPrep.post(`/addTaxPrepYearRecord`, userData);
    return data;
};

export const deleteTaxPrepYear = async ({
    tax_id,
}: {
    tax_id: any;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await apiTaxPrep.post(`/removeTaxPrepYearRecord?id=${tax_id}`);
    return data;
};

export const editSections = async ({
    irs_account_id,
    section_selection,
    action,
    tax_id,
    taxprep_year,
}: {
    irs_account_id: any;
    section_selection: any;
    action: string;
    tax_id: any;
    taxprep_year: any;
}): Promise<{ success: boolean;[key: string]: any }> => {
    const { data } = await apiTaxPrep.put(`/updateTaxPrepClient/${tax_id}`, {
        irs_account_id,
        section_selection,
        action,
        taxprep_year,
    });
    return data;
};

export const clearNotifications = async ({
    irs_account_id,
    clear_all,
    read,
}: {
    irs_account_id?: any;
    clear_all?: boolean;
    read?: boolean;
}): Promise<{ success: boolean;[key: string]: any }> => {
    let url = '/clearNotification';
    if (clear_all) {
        url += '?clear_all=true';
    } else {
        url += `?irs_account_id=${irs_account_id}&read=${read}&clear_all=false`
    }
    const { data } = await api.post(url);
    return data;
};
