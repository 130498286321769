import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertBusiness_p_p3 = (
    year: string,
    { id, business_farm_income_accural_details, meta_data }: any,
) => {
    const regEx = /^new/;
    const data = business_farm_income_accural_details
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: meta_data.irs_account_id,
                    taxprep_account_id: id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/businessFarmIncomeAccuralByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data,
        taxprep_year: year,
    });
};

const b_p_p3_Form: FormInterface = [
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'products_sales',
        label: 'Sales of livestock, produce, grains, and other products',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'cooperative_distributions',
        label: 'Cooperative distributions ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'taxable_amount',
        label: 'Taxable amount',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'agricultural_program_payments',
        label: 'Agricultural program payments',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'agricultural_taxable_amount',
        label: 'Agricultural Taxable amount',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'commodity_credit_corporation',
        label: 'Commodity Credit Corporation (CCC) loans',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'ccc_loans_reported',
        label: 'CCC loans reported ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ccc_loans_forfeited',
        label: 'CCC loans forfeited',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'ccc_taxable_amount',
        label: 'CCC Taxable amount',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'crop_insurance_proceeds',
        label: 'Crop insurance proceeds',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'custom_hire_income',
        label: 'Custom hire (machine work) income',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'inventory_livestock_beginning_year',
        label: 'Inventory of livestock, produce, grains, and other products at beginning of the year 2022',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'cost_inventory_livestock_year',
        label: 'Cost of livestock, produce, grains, and other products purchased during the year 2022',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'inventory_livestock_end_year',
        label: 'Inventory of livestock, produce, grains, and other products at end of year 2022',
        placeholder: 'john',
        span: 2,
    },
];

const b_p_p3SecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_farm_income_accural_details',
        label: 'Do you have Farm Income—Accrual Method?',
        header: 'Farm Income—Accrual Method',
        displayColumns: [
            {
                name: 'Product sales',
                key: 'products_sales',
            },
            {
                name: 'Cooperative distributions',
                key: 'cooperative_distributions',
            },
            {
                name: 'Taxable amount',
                key: 'taxable_amount',
            },
        ],
        formStructure: b_p_p3_Form,
    },
];

export const page: PageInputInterface = {
    address: 'business_farm_income_accural_details',
    name: 'Farm Income—Accrual Method',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: b_p_p3SecForm,
    save: upsertBusiness_p_p3,
};
