import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fieldTypes } from '../../../../utils/enums';
import NavBar from '../../NavBar';
import { useAuthState } from '../../../../context/auth';
import { useCompletedPercentage, useFeatureEnabled, useIsMobile } from '../../../../utils/helpers';
import { useFormState } from '../../../../context/form';
import moment from 'moment';
import useFormsList from '../../../../api/tax-prep/useFormsList';
import Modal from '../../Modal';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../Button';
import InputGroup from './InputGroup';
import { build } from '../../../../config';
import { HeaderGroup } from 'react-table';
import wallet from '../../../../assets/Wallet_duotone_line.svg';
import money from '../../../../assets/Money_duotone_line.svg';
/* eslint-disable import/no-anonymous-default-export */
import useTaxPrep from '../../../../api/hooks/useTaxPrep';

import { PageManager, Table } from '../Tables/Table';

import dayjs from 'dayjs';
import { paymentColumns, serviceRecurringColumns, serviceStandardColumns } from '../ColumnHeader';
import { useQuery } from 'react-query';
import { transcriptApi } from '../../../../api/transcripts';
import { billingApi } from '../../../../api/billing';
import Dropdown from './Dropdown';

type accessOptns = {
    label: string;
    value: string;
};

const accessOpts: accessOptns[] = [
    { label: 'Tax Resolution', value: 'tax_resolution' },
    { label: 'Tax Prepration', value: 'tax_preparation' },
];

//getting previous twenty years from current year for the adding years and edit years dropdown
const currentYear = new Date().getFullYear();
const getYears = (currentYear: number) => {
    let temp = currentYear - 20;
    let year = [];
    for (let i = currentYear; i > temp; i--) {
        year.push(i);
    }
    return year;
};

const taxPreparerLoadingData = [
    {
        user_id: 'Loading...',
        u_first_name: 'Loading...',
        u_last_name: 'Loading...',
        u_user_name: 'Loading...',
        u_email: 'Loading...',
    },
];

export function formatDate(dateString: string, format: string) {
    const date = dayjs(dateString);
    return date.format(format);
}
const ActionsCell = ({
    original,
    setConfirmDelete,
    setConfirmResend,
    setEditTaxPro,
    setStatusData,
}: any) => {
    return (
        <div className="flex mr-5 items-center">
            <span
                onClick={(e) => {
                    e.stopPropagation();

                    setEditTaxPro(original);
                }}
                className="cursor-pointer pr-1  font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
            >
                edit
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();

                    setConfirmResend(original['user_id']);
                }}
                className="cursor-pointer pr-1 font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
            >
                restart_alt
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDelete(original['user_id']);
                }}
                style={{ fontSize: '25px' }}
                className="cursor-pointer pr-1  material-icons text-gray-400 hover:text-red-400"
            >
                delete_forever
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    setStatusData(original['user_id']);
                }}
                style={{ fontSize: '29px' }}
                className={`cursor-pointer material-icons hover:text-primary-500' , ${
                    original['u_status'] === 'Inactive' ? 'text-gray-400' : 'text-primary-300'
                }`}
            >
                {original?.['u_status'] === 'Inactive' ? 'toggle_off' : 'toggle_on'}
            </span>
        </div>
    );
};

const AddService: React.FC<{ close: () => void }> = ({ close }) => {
    const init = {
        service_group: '',
        service_category: '',
        service_name: '',
        list_price: '',
        quantity: '',
        discount_amount: '',
        promo_code: '',
        calculated_amount: '',
    };
    const [serviceValue, setServiceValue] = useState(init);
    const [errors, setErrors] = useState({});

    function saveCancel() {
        console.log('saveCancel');
        setServiceValue(init);
        close();
    }
    return (
        <div style={{ width: '56rem' }} className=" bg-white  overflow-hidden">
            <div className="pl-7 pr-6 py-4 flex items-center justify-between bg-gray-100 overflow-hidden">
                <span className="font-semibold text-2xl">Add Service</span>
                <span onClick={() => close()} className="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
            <div className="grid grid-cols-4 gap-4 px-6 pb-7 pt-2">
                <Dropdown
                    id="ServiceGroup"
                    label="Service Group"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.service_group}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, service_group: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <Dropdown
                    id="ServiceCatgory"
                    label="Service Category"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.service_category}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, service_category: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <Dropdown
                    id="ServiceName"
                    label="Service Name"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.service_name}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, service_name: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />

                <InputGroup
                    id="ListPrice"
                    label="List Price"
                    placeholder=""
                    value={serviceValue.list_price}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, list_price: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="Quantity"
                    label="Quantity"
                    placeholder=""
                    value={serviceValue.quantity}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, quantity: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="DiscountAmount"
                    label="Discount Amount"
                    placeholder=""
                    value={serviceValue.discount_amount}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, discount_amount: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="PromoCode"
                    label="Promo Code"
                    placeholder=""
                    value={serviceValue.promo_code}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, promo_code: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="CalculatedAmount"
                    label="Calculated Amount"
                    placeholder=""
                    value={serviceValue.calculated_amount}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, calculated_amount: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
            </div>
            <div className="w-full flex items-center justify-end mt-8 px-5 py-7">
                <div className="flex gap-4 items-center">
                    <span
                        // style={{ padding: '4.5px 22.5px 4.5px 21.5px;' }}
                        className="px-6 py-1 bg-primary-500 text-white text-md font-normal"
                        onClick={saveCancel}
                    >
                        Save
                    </span>
                    <span
                        // style={{ padding: '4.5px 16.5px 4.5px 15.5px' }}
                        className="px-6 py-1 text-primary-500 bg-white text-md font-normal border border-primary-100"
                        onClick={saveCancel}
                    >
                        Cancel
                    </span>
                </div>
            </div>
        </div>
    );
};

const OneTimePayment: React.FC<{ close: () => void }> = ({ close }) => {
    const init = {
        payment_method: '',
        amount: '',
        date: '',
        description: '',
    };
    const [serviceValue, setServiceValue] = useState(init);
    const [errors, setErrors] = useState({});

    function saveCancel() {
        console.log('saveCancel');
        setServiceValue(init);
        close();
    }
    return (
        <div style={{ width: '50rem' }} className=" bg-white  overflow-hidden">
            <div className="pl-7 pr-6 py-4 flex items-center justify-between bg-gray-100 overflow-hidden">
                <span className="font-semibold text-2xl">One Time Payment</span>
                <span onClick={() => close()} className="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
            <div className="grid  grid-cols-3 gap-4 p-6 mr-4">
                <Dropdown
                    id="PaymentMethod"
                    label="Payment Method"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 1', label: 'service 2' },
                        { value: 'service 1', label: 'service 3' },
                    ]}
                    value={serviceValue.payment_method}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, payment_method: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <InputGroup
                    id="Amount"
                    label="Amount"
                    placeholder=""
                    value={serviceValue.amount}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, amount: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="Date"
                    label="Date (mm-dd-yyyy)"
                    placeholder=""
                    value={serviceValue.date}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, date: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="Description"
                    label="Description"
                    placeholder=""
                    value={serviceValue.description}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, description: value });
                        setErrors({});
                    }}
                    span={3}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
            </div>
            <div className="w-full flex items-center justify-end  px-5 py-7">
                <div className="flex gap-4">
                    {/* <Button onClick={saveCancel}>Save</Button>
                    <Button onClick={saveCancel} secondary className="bg-white">
                        Cancel
                    </Button> */}
                    <div className="w-full flex items-center justify-end mt-5 px-5 ">
                        <div className="flex gap-4 items-center">
                            <span
                                // style={{ padding: '4.5px 22.5px 4.5px 21.5px;' }}
                                className="px-6 py-1 bg-primary-500 text-white text-md font-normal"
                                onClick={saveCancel}
                            >
                                Save
                            </span>
                            <span
                                // style={{ padding: '4.5px 16.5px 4.5px 15.5px' }}
                                className="px-6 py-1 cursor-pointer text-primary-500 bg-white text-md font-normal border border-primary-100"
                                onClick={saveCancel}
                            >
                                Cancel
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ScheduledPayment: React.FC<{ close: () => void }> = ({ close }) => {
    const init = {
        payment_method: '',
        amount: '',
        date: '',
        quatity_to_pay: '',
        description: '',
    };
    const [serviceValue, setServiceValue] = useState(init);
    const [errors, setErrors] = useState({});

    function saveCancel() {
        console.log('saveCancel');
        setServiceValue(init);
        close();
    }
    return (
        <div style={{ width: '55rem' }} className=" bg-white  overflow-hidden">
            <div className="pl-7 pr-6 py-4 flex items-center justify-between bg-gray-100 overflow-hidden">
                <span className="font-semibold text-2xl">Add Schedule</span>
                <span onClick={() => close()} className="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
            <div className="grid  grid-cols-4 gap-4 p-6 mr-4">
                <Dropdown
                    id="PaymentMethod"
                    label="Payment Method"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 1', label: 'service 2' },
                        { value: 'service 1', label: 'service 3' },
                    ]}
                    value={serviceValue.payment_method}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, payment_method: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <Dropdown
                    id="QuantityToPay"
                    label="Quantity To Pay"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 1', label: 'service 2' },
                        { value: 'service 1', label: 'service 3' },
                    ]}
                    value={serviceValue.quatity_to_pay}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, quatity_to_pay: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />

                <InputGroup
                    id="Amount"
                    label="Amount"
                    placeholder=""
                    value={serviceValue.amount}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, amount: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />

                <InputGroup
                    id="Date"
                    label="Date (mm-dd-yyyy)"
                    placeholder=""
                    value={serviceValue.date}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, date: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="Description"
                    label="Description"
                    placeholder=""
                    value={serviceValue.description}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, description: value });
                        setErrors({});
                    }}
                    span={4}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
            </div>
            <div className="w-full flex items-center justify-end  px-5 py-7">
                <div className="flex gap-4">
                    {/* <Button onClick={saveCancel}>Save</Button>
                    <Button onClick={saveCancel} secondary className="bg-white">
                        Cancel
                    </Button> */}
                    <div className="w-full flex items-center justify-end px-5 ">
                        <div className="flex gap-4 items-center">
                            <span
                                // style={{ padding: '4.5px 22.5px 4.5px 21.5px;' }}
                                className="px-6 py-1 bg-primary-500 text-white text-md font-normal"
                                onClick={saveCancel}
                            >
                                Save
                            </span>
                            <span
                                // style={{ padding: '4.5px 16.5px 4.5px 15.5px' }}
                                className="px-6 py-1 cursor-pointer text-primary-500 bg-white text-md font-normal border border-primary-100"
                                onClick={saveCancel}
                            >
                                Cancel
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const RecurringPayment: React.FC<{ close: () => void }> = ({ close }) => {
    const init = {
        payment_method: '',
        frequency: '',
        amount: '',
        events: '',
        date: '',
        description: '',
    };
    const [serviceValue, setServiceValue] = useState(init);
    const [errors, setErrors] = useState({});

    function saveCancel() {
        console.log('saveCancel');
        setServiceValue(init);
        close();
    }
    return (
        <div style={{ width: '50rem' }} className=" bg-white  overflow-hidden">
            <div className="pl-7 pr-6 py-4 flex items-center justify-between bg-gray-100 overflow-hidden">
                <span className="font-semibold text-2xl">Add Recurring Payment</span>
                <span onClick={() => close()} className="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
            <div className="grid  grid-cols-3 gap-4 p-6 mr-4">
                <Dropdown
                    id="PaymentMethod"
                    label="Payment Method"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.payment_method}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, payment_method: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <InputGroup
                    id="Amount"
                    label="Amount"
                    placeholder=""
                    value={serviceValue.amount}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, amount: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <Dropdown
                    id="Frequency"
                    label="Frequency"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.frequency}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, frequency: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />

                <InputGroup
                    id="NoOfEvents"
                    label="No: of Events"
                    placeholder=""
                    value={serviceValue.events}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, events: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="Date"
                    label="Date (mm-dd-yyyy)"
                    placeholder=""
                    value={serviceValue.date}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, date: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="Description"
                    label="Description"
                    placeholder=""
                    value={serviceValue.description}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, description: value });
                        setErrors({});
                    }}
                    span={3}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
            </div>
            <div className="w-full flex items-center justify-end  px-5 py-7">
                <div className="flex gap-4">
                    {/* <Button onClick={saveCancel}>Save</Button>
                    <Button onClick={saveCancel} secondary className="bg-white">
                        Cancel
                    </Button> */}
                    <div className="w-full flex items-center justify-end mt-5 px-5 ">
                        <div className="flex gap-4 items-center">
                            <span
                                // style={{ padding: '4.5px 22.5px 4.5px 21.5px;' }}
                                className="px-6 py-1 bg-primary-500 text-white text-md font-normal"
                                onClick={saveCancel}
                            >
                                Save
                            </span>
                            <span
                                // style={{ padding: '4.5px 16.5px 4.5px 15.5px' }}
                                className="px-6 py-1 cursor-pointer text-primary-500 bg-white text-md font-normal border border-primary-100"
                                onClick={saveCancel}
                            >
                                Cancel
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AddPayService: React.FC<{ close: () => void }> = ({ close }) => {
    const init = {
        service_group: '',
        service_category: '',
        service_name: '',
        list_price: '',
        quantity: '',
        discount_amount: '',
        start_date: '',
        end_date: '',
        recurring_type: '',
        promo_code: '',
        calculated_amount: '',
    };
    const [serviceValue, setServiceValue] = useState(init);
    const [errors, setErrors] = useState({});

    function saveCancel() {
        console.log('saveCancel');
        setServiceValue(init);
        close();
    }
    return (
        <div style={{ width: '50rem' }} className=" bg-white  overflow-hidden">
            <div className="pl-7 pr-6 py-4 flex items-center justify-between bg-gray-100 overflow-hidden">
                <span className="font-semibold text-2xl">Add Service</span>
                <span onClick={() => close()} className="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
            <div className="grid grid-cols-4 gap-4 px-6 py-7">
                <Dropdown
                    id="ServiceGroup"
                    label="Service Group"
                    placeholder=""
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' },
                    ]}
                    value={serviceValue.service_group}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, service_group: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <Dropdown
                    id="ServiceCatgory"
                    label="Service Category"
                    placeholder=""
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' },
                    ]}
                    value={serviceValue.service_category}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, service_category: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <Dropdown
                    id="ServiceName"
                    label="Service Name"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.service_name}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, service_name: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />

                <InputGroup
                    id="ListPrice"
                    label="List Price"
                    placeholder=""
                    value={serviceValue.list_price}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, list_price: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="Quantity"
                    label="Quantity"
                    placeholder=""
                    value={serviceValue.quantity}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, quantity: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="StartDate"
                    label="Start Date"
                    placeholder=""
                    value={serviceValue.start_date}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, start_date: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <Dropdown
                    id="StartDate"
                    label="Start Date"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.start_date}
                    setValue={(value: any) => {
                        setServiceValue({ ...serviceValue, start_date: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <Dropdown
                    id="EndDate"
                    label="End Date"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.end_date}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, end_date: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />
                <Dropdown
                    id="RecurringType"
                    label="Recurring Type"
                    placeholder=""
                    options={[
                        { value: 'service 1', label: 'service 1' },
                        { value: 'service 2', label: 'service 2' },
                        { value: 'service 3', label: 'service 3' },
                    ]}
                    value={serviceValue.recurring_type}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, recurring_type: value });
                        setErrors({});
                    }}
                    type={fieldTypes.DROPDOWN}
                />

                <InputGroup
                    id="DiscountAmount"
                    label="Discount Amount"
                    placeholder=""
                    value={serviceValue.discount_amount}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, discount_amount: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="PromoCode"
                    label="Promo Code"
                    placeholder=""
                    value={serviceValue.promo_code}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, promo_code: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
                <InputGroup
                    id="CalculatedAmount"
                    label="Calculated Amount"
                    placeholder=""
                    value={serviceValue.calculated_amount}
                    setValue={(value) => {
                        setServiceValue({ ...serviceValue, calculated_amount: value });
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full mt-1"
                    // staticError={error.u_first_name}
                />
            </div>
            <div className="w-full flex items-center justify-end px-5 py-7">
                <div className="flex gap-4 items-center">
                    <span
                        // style={{ padding: '4.5px 22.5px 4.5px 21.5px;' }}
                        className="px-6 py-1 bg-primary-500 text-white text-md font-normal"
                        onClick={saveCancel}
                    >
                        Save
                    </span>
                    <span
                        // style={{ padding: '4.5px 16.5px 4.5px 15.5px' }}
                        className="px-6 py-1 cursor-pointer text-primary-500 bg-white text-md font-normal border border-primary-100"
                        onClick={saveCancel}
                    >
                        Cancel
                    </span>
                </div>
            </div>
        </div>
    );
};
const PaymentMethods: React.FC<{ close: () => void }> = ({ close }) => {
    const init = {
        payment_method: '',
        frequency: '',
        amount: '',
        events: '',
        date: '',
        description: '',
    };
    const [serviceValue, setServiceValue] = useState(init);
    const [errors, setErrors] = useState({});

    function saveCancel() {
        console.log('saveCancel');
        setServiceValue(init);
        close();
    }
    return (
        <div className=" bg-white  overflow-hidden">
            <div className="px-6 py-4 flex items-center justify-between bg-gray-100 overflow-hidden">
                <span className="font-semibold text-lg"> Payment Methods </span>
                <span onClick={() => close()} className="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
            <div className="flex flex-col gap-4 px-5 py-4 mb-16 ">
                <div className=" border-primary-500 w-full border-2 text-center py-2.5 text-primary-500 font-semibold">
                    Add New
                </div>
                <div className="border border-black">
                    <div className=" flex flex-col gap-3 border-l-4 border-primary-500   p-2">
                        <div className="flex justify-between gap-3">
                            <div className="flex gap-2">
                                <span className="material-symbols-outlined">credit_card</span>
                                <span className="font-semibold ">Credit Card</span>
                            </div>

                            <div className="flex gap-2">
                                <span
                                    style={{ fontSize: '18px' }}
                                    className=" text-xs material-symbols-outlined"
                                >
                                    edit
                                </span>
                                <span
                                    style={{ fontSize: '18px' }}
                                    className=" text-xs material-symbols-outlined"
                                >
                                    delete
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between gap-3">
                            <div className="flex gap-2">
                                <span className="text-xs font-semibold">Card No.</span>
                                <span className="text-xs font-semibold ">***************1452</span>
                                <span
                                    style={{ fontSize: '16px' }}
                                    className=" font-semibold material-symbols-outlined"
                                >
                                    visibility
                                </span>
                            </div>

                            <div className="flex gap-3">
                                <span className="text-xs font-semibold">Exp Date: 10/2024</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border border-black">
                    <div className=" flex flex-col gap-3    p-2">
                        <div className="flex justify-between gap-3">
                            <div className="flex gap-2">
                                <span className="material-symbols-outlined">credit_card</span>
                                <span className="font-semibold ">Cash</span>
                            </div>

                            <div className="flex gap-2">
                                <span
                                    style={{ fontSize: '18px' }}
                                    className=" text-xs material-symbols-outlined"
                                >
                                    edit
                                </span>
                                <span
                                    style={{ fontSize: '18px' }}
                                    className=" text-xs material-symbols-outlined"
                                >
                                    delete
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between gap-3">
                            <div className="flex gap-2">
                                <span className="text-xs font-semibold">Card No.</span>
                                <span className="text-xs font-semibold ">***************1452</span>
                                <span
                                    style={{ fontSize: '16px' }}
                                    className=" font-semibold material-symbols-outlined"
                                >
                                    visibility
                                </span>
                            </div>

                            <div className="flex gap-3">
                                <span className="text-xs font-semibold">Exp Date: 10/2024</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const PaymentDropdown: React.FC<{ setPaymentModal: any }> = ({ setPaymentModal }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    console.log();
    return (
        <div className="relative" ref={dropdownRef}>
            <div
                onClick={toggleDropdown}
                className=" pay-dropdown px-5 py-1.5 flex justify-center items-center gap-1 border-2 cursor-pointer  border-primary-500  "
            >
                <span className="pay-dropdown-span text-primary-500 font-semibold text-sm">
                    Payments
                </span>
                <span className="pay-dropdown-span material-symbols-outlined  border-none  text-primary-500  text-xl">
                    expand_more
                </span>
            </div>
            {isOpen && (
                <ul className="z-50 w-full absolute text-gray-700  shadow-md border">
                    <li className=" ">
                        <button
                            className=" text-left text-xs font-semibold w-full  bg-white hover:bg-gray-200 py-2 px-3 block border-b border-gray-300 whitespace-no-wrap"
                            onClick={() => {
                                // setTranscript(6);
                                setPaymentModal((prev: any) => ({ ...prev, oneTime: true }));

                                setIsOpen(false);
                            }}
                        >
                            One Time{' '}
                        </button>
                    </li>
                    <li className=" ">
                        <button
                            className=" text-left text-xs font-semibold w-full  bg-white hover:bg-gray-200 border-b border-gray-300 py-2 px-3 block whitespace-no-wrap"
                            onClick={() => {
                                // setTranscript(6);
                                setPaymentModal((prev: any) => ({ ...prev, scheduled: true }));

                                setIsOpen(false);
                            }}
                        >
                            Schedule
                        </button>
                    </li>
                    <li className="">
                        <button
                            className=" text-left text-xs font-semibold w-full bg-white hover:bg-gray-200 border-b border-gray-300 py-2 px-3 block whitespace-no-wrap"
                            onClick={() => {
                                // setTranscript(7);
                                setPaymentModal((prev: any) => ({ ...prev, recurring: true }));

                                setIsOpen(false);
                            }}
                        >
                            Recurring
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};

const PaymentsToggle = () => {
    const [isAutoPay, setIsAutoPay] = useState(true);

    const handleToggle = () => {
        setIsAutoPay(!isAutoPay);
    };

    return (
        <button
            className="flex items-center border border-gray-300 transition-colors duration-300 ease-in-out focus:outline-none"
            onClick={handleToggle}
        >
            <span
                className={`p-1 text-xs ${
                    !isAutoPay ? 'text-white bg-primary-500' : 'text-black bg-white'
                }`}
            >
                OFF
            </span>
            <span
                className={` p-1 text-xs ${
                    isAutoPay ? 'text-white bg-primary-500' : 'text-black bg-white'
                }`}
            >
                AutoPay
            </span>
        </button>
    );
};

export const UserBilling: React.FC = () => {
    const [editClient, setEditTaxPro] = useState(undefined);
    const [confirmExport, setConfirmExport] = useState(false);
    const [billing, setBilling] = useState(1);
    const { role } = useAuthState();
    const { success: isExportEnabled } = useFeatureEnabled('Export');
    const [addService, setAddService] = useState(false);
    const [recurrService, setRecurrService] = useState(false);
    const [onePay, setOnePay] = useState(false);

    const [paymentModal, setPaymentModal] = useState({
        oneTime: false,
        scheduled: false,
        recurring: false,
    });
    const [payMethod, setPayMethod] = useState(false);
    console.log(onePay, 'setOnePay');

    const { data: serviceTableData, isLoading: isServiceLoading } = useQuery(
        'services',
        async () => {
            const response = await billingApi.get('/getAllServicesRecords');
            return response?.data?.data;
        },
    );

    const { data: paymentTableData, isLoading: isPaymentLoading } = useQuery(
        'payments',
        async () => {
            const response = await fetch(
                'https://taxprofilens.nablasol.net/apiAR/getAllPaymentDetailsRecords',
            );

            const data = await response.json();
            return data.data;
        },
    );

    const billings = [
        { id: 1, billing: 'Standard' },
        { id: 2, billing: 'Recurring' },
    ];

    const serviceHeaders = [serviceStandardColumns, serviceRecurringColumns];
    return (
        <>
            <div className=" flex flex-col  border-0 border-[#CCCCCC] pb-10 row-span-3 col-span-2 ">
                <div className=" flex flex-col gap-2 items-start">
                    <div className="flex justify-start gap-5 ">
                        {billings.map((item, index) => (
                            <div
                                key={item.id}
                                onClick={() => setBilling(item.id)}
                                style={{ padding: '10.422px 40.311px 8.578px 25.689px' }}
                                className={`text-center py-2.5 px-9 cursor-pointer ease-in-out duration-200 ${
                                    billing == item.id ? 'border-b-2 border-primary-500' : ''
                                }`}
                            >
                                {item.billing}
                            </div>
                        ))}
                        <div className="flex justify-center items-center px-5">
                            <span
                                style={{
                                    fontSize: '20px',
                                    background: '#222222',
                                    borderColor: '#222222',
                                }}
                                className="material-symbols-outlined    text-white   "
                            >
                                add
                            </span>
                        </div>
                    </div>

                    <div className="px-6 flex flex-col gap-4 w-full">
                        {serviceTableData && (
                            <div className="flex flex-col gap-3">
                                <div className="flex justify-between items-center">
                                    <span className="font-semibold py-2 text-xl leading-normal">
                                        {billing == 1 ? 'Standard Services' : 'Recurring Services'}
                                    </span>
                                    <div
                                        onClick={() => {
                                            billing == 1
                                                ? setAddService(true)
                                                : setRecurrService(true);
                                        }}
                                        className="bill-serive px-4 py-1.5 flex justify-center items-center gap-3 border-2 cursor-pointer  border-primary-500  "
                                    >
                                        <span
                                            style={{
                                                fontSize: '20px',
                                            }}
                                            className="bill-serive-add material-symbols-outlined   text-white bg-primary-500   border-primary-500  "
                                        >
                                            add
                                        </span>
                                        <span className=" bill-serive-span text-primary-500 font-semibold text-sm">
                                            Service
                                        </span>
                                    </div>
                                </div>
                                <Table
                                    tableMaxHeight={'325px'}
                                    data={serviceTableData}
                                    columns={serviceHeaders[billing - 1]}
                                />
                            </div>
                        )}

                        {paymentTableData && (
                            <div className="flex flex-col gap-3">
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center">
                                        <div className="font-semibold text-xl mr-3">Payments</div>
                                        <PaymentsToggle />
                                    </div>
                                    <div className="flex gap-4">
                                        <PaymentDropdown setPaymentModal={setPaymentModal} />
                                        <div
                                            onClick={() => setPayMethod(true)}
                                            className=" paymethod px-3 py-1.5 flex justify-center items-center cursor-pointer gap-3 border-2  border-primary-500  "
                                        >
                                            <span className="paymethod-span text-primary-500 font-semibold text-sm  ">
                                                Payment Methods
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Table
                                    tableMaxHeight={'325px'}
                                    data={paymentTableData}
                                    columns={paymentColumns}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Modal close={() => setAddService(false)} isOpen={!!addService}>
                <AddService close={() => setAddService(false)} />
            </Modal>
            <Modal close={() => setRecurrService(false)} isOpen={!!recurrService}>
                <AddPayService close={() => setRecurrService(false)} />
            </Modal>
            <Modal
                close={() => setPaymentModal((prev: any) => ({ ...prev, oneTime: false }))}
                isOpen={!!paymentModal.oneTime}
            >
                <OneTimePayment
                    close={() => setPaymentModal((prev: any) => ({ ...prev, oneTime: false }))}
                />
            </Modal>

            <Modal
                close={() => setPaymentModal((prev: any) => ({ ...prev, scheduled: false }))}
                isOpen={!!paymentModal.scheduled}
            >
                <ScheduledPayment
                    close={() => setPaymentModal((prev: any) => ({ ...prev, scheduled: false }))}
                />
            </Modal>
            <Modal
                close={() => setPaymentModal((prev: any) => ({ ...prev, recurring: false }))}
                isOpen={!!paymentModal.recurring}
            >
                <RecurringPayment
                    close={() => setPaymentModal((prev: any) => ({ ...prev, recurring: false }))}
                />
            </Modal>

            <Modal close={() => setPayMethod(false)} isOpen={!!payMethod}>
                <PaymentMethods close={() => setPayMethod(false)} />
            </Modal>
        </>
    );
};

const Billing: React.FC<{}> = ({}) => {
    const { irs_account_id } = useParams();

    const { isLoading, data } = useFormsList({
        irs_account_id: irs_account_id,
    });

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    //to get the complete percentage for tax resolution

    if (isLoading) {
        return (
            <div>
                {!iframe && <NavBar />}
                <div
                    style={{ alignItems: 'center' }}
                    className="h-screen flex justify-center text-justify align-middle"
                >
                    <div>Loading...</div>
                </div>
            </div>
        );
    }

    //to get the service type text to show on screen
    const serviceType = data && JSON.parse(data?.u_service_type);
    const getServiceType = () => {
        const text1 = serviceType?.includes('tax_resolution') ? 'Tax Resolution' : '';
        const text2 = serviceType?.includes('tax_preparation') ? 'Tax Preparation' : '';

        return text1 && text2 ? `${text1} , ${text2}` : text1 || text2;
    };
    console.log(data);
    return (
        <>
            <div className="flex flex-col w-full h-screen overflow-y-scroll bg-gray-100">
                {!iframe && <NavBar />}
                <div className="w-full h-full" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                    <div
                        className={`grid mt-7 ${
                            data?.Taxpro.length &&
                            serviceType?.includes('tax_preparation') &&
                            build.taxprep_module
                                ? 'grid-cols-1 xl:grid-cols-3 lg:grid-cols-3'
                                : 'grid-cols-3'
                        } `}
                    >
                        <div className="col-span-3">
                            {' '}
                            {/* new code */}
                            <div className="grid grid-cols-4 gap-7">
                                <div className=" ml-3 bg-white shadow-sm   relative row-span-3 col-span-3">
                                    <UserBilling />
                                </div>
                                {/* Total Amount */}
                                <div className="col-span-1 row-span-1 flex flex-col gap-8 ">
                                    <div className=" flex flex-col gap-3">
                                        <div
                                            style={{
                                                borderImage:
                                                    'linear-gradient(90deg, rgba(177,232,213,1) 0%, rgba(6,127,123,1) 100%)',
                                                borderImageSlice: 1,
                                                borderBottomWidth: '5px',
                                            }}
                                            className="bg-white flex items-center justify-between pl-10 pr-8 py-3 border-gray-600 border-b-"
                                        >
                                            <div className="flex flex-col">
                                                <span className="font-bold text-2xl leading-normal">
                                                    $ 1,420
                                                </span>
                                                <span
                                                    style={{ fontSize: '10.66px' }}
                                                    className=" font-bold text-gray-500"
                                                >
                                                    Total Amount
                                                </span>
                                            </div>
                                            <div
                                                style={{ background: '#B1E8D5' }}
                                                className="rounded-full w-12 h-12 bg-gray-400  flex items-center justify-center"
                                            >
                                                <img src={wallet} alt="" />
                                                {/* <span className="material-symbols-outlined">
                                                    account_balance_wallet
                                                </span> */}
                                            </div>
                                        </div>
                                        {/* Total Amount */}

                                        {/* Paid Amount */}
                                        <div
                                            style={{
                                                borderImage:
                                                    'linear-gradient(90deg, rgba(180,231,227,1) 0%, rgba(95,209,198,1) 100%)',
                                                borderImageSlice: 1,
                                                borderBottomWidth: '5px',
                                            }}
                                            className="bg-white flex items-center justify-between pl-10 pr-8 py-3 border-gray-400 border-b-4"
                                        >
                                            <div className="flex flex-col">
                                                <span className="font-bold text-2xl leading-normal">
                                                    $ 320
                                                </span>
                                                <span
                                                    style={{ fontSize: '10.66px' }}
                                                    className=" font-bold text-gray-500"
                                                >
                                                    Paid Amount
                                                </span>
                                            </div>
                                            <div
                                                style={{ background: '#DFF6F4' }}
                                                className="rounded-full w-12 h-12  flex items-center justify-center"
                                            >
                                                <span className="material-symbols-outlined">
                                                    check_circle
                                                </span>
                                            </div>
                                        </div>
                                        {/* Paid Amount */}

                                        {/* Remaining Amount */}
                                        <div
                                            style={{
                                                borderImage:
                                                    'linear-gradient(90deg, rgba(228,223,255,1) 0%, rgba(74,59,151,1) 100%)',
                                                borderImageSlice: 1,
                                                borderBottomWidth: '5px',
                                            }}
                                            className="bg-white flex items-center justify-between pl-10 pr-8 py-3 border-blue-800 border-b-4"
                                        >
                                            <div className="flex flex-col">
                                                <span className="font-bold text-2xl leading-normal">
                                                    $ 1,100
                                                </span>
                                                <span
                                                    style={{ fontSize: '10.66px' }}
                                                    className=" font-bold text-gray-500"
                                                >
                                                    Remaining Amount
                                                </span>
                                            </div>
                                            <div className="rounded-full w-12 h-12 bg-blue-200 flex items-center justify-center">
                                                <img src={money} alt="" />
                                            </div>
                                        </div>
                                        {/* Remaining Amount */}

                                        {/* Scheduled Date */}
                                        <div
                                            style={{
                                                borderImage:
                                                    'linear-gradient(90deg, rgba(255, 255, 224, 0.79) 0%, rgba(244, 236, 43, 0.79) 100%)',
                                                borderImageSlice: 1,
                                                borderBottomWidth: '5px',
                                            }}
                                            className="bg-white flex items-center justify-between pl-10 pr-8 py-3 border-yellow-400 border-b-"
                                        >
                                            <div className="flex flex-col">
                                                <span className="font-bold text-2xl leading-normal">
                                                    $ 200
                                                </span>
                                                <span
                                                    style={{ fontSize: '10.66px' }}
                                                    className=" font-bold text-gray-500"
                                                >
                                                    Next Scheduled | 24/03/2024
                                                </span>
                                            </div>
                                            <div
                                                style={{ background: '#FFFFE0' }}
                                                className="rounded-full w-12 h-12 bg-yellow-200 flex items-center justify-center"
                                            >
                                                <span className="material-symbols-outlined">
                                                    schedule
                                                </span>
                                            </div>
                                        </div>
                                        {/* Scheduled Date */}
                                    </div>
                                    <div className="border-2 p-4 flex flex-col gap-4 bg-white">
                                        <span className="font-semibold text-lg">Change Log</span>
                                        <div className="px-2">
                                            <input
                                                type="text"
                                                className="border outline-none px-2 py-1 w-full placeholder"
                                                placeholder="Search"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            {[1, 2, 3].map((item, index) => (
                                                <div
                                                    style={{
                                                        boxShadow:
                                                            'rgba(17, 17, 26, 0.1) 0px 1px 0px',
                                                    }}
                                                    className="p-2   rounded-md"
                                                >
                                                    <span className="font-semibold text-sm">
                                                        Closed Business Representation Service Added
                                                    </span>
                                                    <div className="flex font-semibold items-center text-gray-400 gap-2 text-sm">
                                                        <span>Alex Costa</span>
                                                        <span className="rounded-full w-2 h-2 bg-gray-500"></span>
                                                        <span>14 hours ago</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* modals for add, edit, delete  */}
        </>
    );
};

export default Billing;
