import React, { useState } from 'react';
import NavBar from '../basic/NavBar';
import { useIsMobile } from '../../utils/helpers';
import classNames from 'classnames';
import Menu from './TaxPrepEvalSectionMenu';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import Button from './../basic/Button';
import Modal from '../basic/Modal';
import { useParams } from 'react-router';
import EvalSection from './TaxPrepEvalSection';
import YearToggleAdmin from './YearToggleAdmin';
import FloatingSaveButton from '../tax-prep-admin-form-components/FloatingSaveButton';
import useFormsList from '../../api/tax-prep/useFormsList';
import useTaxPrepEvalData from './useTaxPrepEvalData';
import useAllBusinesses from './useAllBusinesses';

export const CopyBusinessModal = (props: {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}) => {
    //getting the needed vars
    const { irs_account_id, year: currentYear } = useParams();
    const { years } = useFormsList({ irs_account_id: irs_account_id! });

    const [selectedBusinesses, setSelectedBusinesses] = useState<any[]>([]);
    const { copyBusinessData, refreshData } = useTaxPrepEvalData();

    //toggle selected business
    const toggleBusiness = (b: any) => {
        if (selectedBusinesses.find((exB) => exB.id === b.id)) {
            setSelectedBusinesses(selectedBusinesses.filter((exB) => exB.id !== b.id));
        } else {
            setSelectedBusinesses([...selectedBusinesses, b]);
        }
    };

    const [isExecuting, setIsExecuting] = useState(false);

    const { data: businesses } = useAllBusinesses(irs_account_id);
    console.log('Businesses', businesses);

    //main business copying function
    const executeCopy = async () => {
        setIsExecuting(true);

        await copyBusinessData.mutateAsync({ copyYear: currentYear!, selectedBusinesses });
        refreshData();

        setIsExecuting(false);
        props.setIsOpen(false);
    };

    const YearComponent = (props: { year: string }) => {
        const relevantBusinesses =
            businesses?.filter(({ taxprep_year }: any) => taxprep_year === props.year) || [];

        if (relevantBusinesses.length === 0 || props.year === currentYear) {
            return <></>;
        }

        return (
            <div className="flex flex-col font-body px-5 py-1 w-full text-left">
                <div className="flex bg-gray-600 text-white text-base pl-2">{props.year}</div>

                <div
                    className="flex text-sm flex-col overflow-y-scroll"
                    style={{ minHeight: '40%' }}
                >
                    {relevantBusinesses.map((b: any) => {
                        const isBusinessSelected = selectedBusinesses?.find(
                            (exB) => exB.id === b.id,
                        );
                        return (
                            <div
                                className={classNames(
                                    'cursor-pointer border-gray-100 border rounded-sm p-1',
                                    {
                                        'text-white bg-primary-500 hover:bg-primary-700 font-bold':
                                            isBusinessSelected,
                                        'text-black hover:bg-gray-200': !isBusinessSelected,
                                    },
                                )}
                                onClick={() => toggleBusiness(b)}
                            >
                                {b.business_name}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <Modal isOpen={props.isOpen} close={() => props.setIsOpen(false)}>
            <div
                className="bg-white w-full max-w-4xl flex flex-col items-center overflow-y-scroll "
                style={{ maxHeight: '95%', minHeight: '40%' }}
            >
                <div className="flex items-center justify-center font-title text-lg  text-primary-500 font-medium px-5 py-3 w-full text-left bg-gray-100">
                    <div className="flex-grow">
                        {' '}
                        <span
                            style={{ fontSize: 20 }}
                            className="text-lg mr-1 material-symbols-rounded"
                        >
                            file_copy
                        </span>
                        {'Copy Business into ' + currentYear}
                    </div>
                </div>

                <div className="flex font-body px-5 pt-4 pb-1 w-full text-left">
                    <div className="flex-grow">{`Select one or more businesses to be copied into this year`}</div>
                </div>
                {/* listing existing years */}
                {years?.map((y) => (
                    <YearComponent year={y} />
                ))}
                <div className="bg-gray-300 w-full px-5 mt-5 pt-3">
                    <div className="flex w-full items-center justify-center h-auto  pt-2 pb-3">
                        <div className="flex-grow">
                            <div className="font-semibold">Confirm copying data?</div>
                            <div className="text-sm">
                                All the selected bussinesses will be copied
                            </div>
                        </div>
                        <Button
                            onClick={() => props.setIsOpen(false)}
                            secondary
                            className="w-1/4  mr-3 bg-white"
                        >
                            {`Cancel`}
                        </Button>
                        <Button isLoading={isExecuting} onClick={executeCopy}>
                            {`Copy Select Businesses`}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const TaxPrepEvalScreen: React.FC<{ iframe?: boolean }> = ({ iframe }) => {
    const [menu, setMenu] = useState<boolean>(false);
    const [copyBusiness, setCopyBusiness] = useState<boolean>(false);
    const { pagesRaw, toggleYear, year } = useTaxPrepStructure();

    const isMobile = useIsMobile();

    //setting the next or previous year for navition from toggle bar
    const setYear = (nextYear: string) => {
        toggleYear(nextYear);
    };
    return (
        <div className="flex flex-col w-full h-full bg-gray-200">
            <NavBar iframe={iframe} toggleMenu={() => setMenu(!menu)} />
            {isMobile && (
                <div
                    onClick={() => setMenu(!menu)}
                    className="flex mr-3 items-center justify-center"
                >
                    <span className="material-symbols-outlined text-white">menu</span>
                </div>
            )}
            <div className={classNames('w-full h-screen items-center', { 'mt-10': iframe })}>
                <div className="flex w-full mt-10">
                    {!isMobile ? (
                        <Menu iframe={iframe} closed={iframe} />
                    ) : (
                        menu && (
                            <div className="z-50 fixed top-10 h-screen">
                                <Menu closed close={() => setMenu(false)} />
                            </div>
                        )
                    )}
                    <div className="w-full flex justify-center bg-gray-100 px-2">
                        <div className="relative grid grid-cols-1 max-w-4xl w-full pt-4">
                            {/* <BasicInformation /> */}
                            <YearToggleAdmin year={year!} setYear={setYear} />

                            {pagesRaw.map((page, index) => {
                                return (
                                    <EvalSection
                                        key={index}
                                        currentPage={page}
                                        isLoading={false}
                                        openCopyBusiness={() => setCopyBusiness(true)}
                                    />
                                );
                            })}

                            <FloatingSaveButton />
                        </div>
                    </div>
                </div>
            </div>
            {/* for copying the bussiness data */}
            <CopyBusinessModal
                isOpen={copyBusiness}
                setIsOpen={(value) => setCopyBusiness(value)}
            />
        </div>
    );
};

export default TaxPrepEvalScreen;
