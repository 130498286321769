import base from './base';
import anthem from './anthem';
import { IThemes } from './utils';

/**
 * The default theme to load
 */
export const DEFAULT_THEME: string = 'base';

export const themes: IThemes = {
    base,
    anthem,
};
