import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';
import {
    formStateStore,
    metaStateStore,
    errorStateStore,
} from '../../stateManager/taxPreparerSlice';
import dayjs from 'dayjs';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { upsertBusinesses } from '../../resource/form_structures_taxprep/2_0_bussiness_section';

const useTaxPrepClientData = (forceYear?: string) => {
    const {
        year: currentYear,
        address,
        pages,
        pagesRaw,
        currentPage: page,
        businessId,
    } = useTaxPrepStructure();

    const year = forceYear || currentYear;
    const pageState = useSelector((state: RootState) => state.taxPrep.pageState);
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.taxPrep.formState);
    const meta = useSelector((state: RootState) => state.taxPrep.metaState);
    const error = useSelector((state: RootState) => state.taxPrep.errorState);
    const serverMetaState = useSelector((state: RootState) => state.taxPrep.serverMetaState);
    const yearBasedState = (state && state[year]) || {};
    const yearBasedMetaState = (meta && meta[year]) || {};
    console.log('meta year year state', { yearBasedMetaState, meta })
    const yearBasedErrorState = (error && error[year]) || {};
    const yearBasedServerMetaState = (serverMetaState && serverMetaState[year]) || {};

    function setStateForYear(year: string, update: any) {
        const data = {
            ...state,
            [year]: {
                ...(state[year] || {}),
                ...update,
            },
        };

        dispatch(formStateStore({ data }));
    }
    function setErrorForYear(year: string, error: any) {
        const data = {
            [year]: { ...error },
        };
        dispatch(errorStateStore({ state: data }));
    }

    function setStateForBusinessYear(businessId: string, year: string, update: any) {
        let business_data = state[year]?.['business_data'].map((business_id_data: any) => {
            if (!business_id_data) {
                return business_id_data;
            }

            if (business_id_data.id === businessId) {
                return {
                    ...business_id_data,
                    ...update,
                };
            }
        });

        const data = {
            ...state,
            [year]: {
                ...(state[year] || {}),
                business_data: business_data,
            },
        };

        dispatch(formStateStore({ data }));
    }

    function setMeta(updateState: any) {
        const data = {
            ...meta,
            [year]: {
                ...(meta[year] || {}),
                ...updateState,
            },
        };
        dispatch(metaStateStore({ state: data }));
    }

    const businessFormState = state[year]?.['business_data']?.find(
        (business_id_data: any) => business_id_data?.id === businessId,
    );

    const updateMutation = async () => {
        if (!businessId) {
            const { data } = await page?.save(year, { ...state[year], meta_data: yearBasedMetaState });
            return data;
        }

        const { data } = await page?.save(year, { ...(businessFormState || {}), meta_data: yearBasedMetaState });
        return data;
    };

    const queryClient = useQueryClient();

    const updateClient = useMutation({
        mutationFn: updateMutation,
        onMutate: () => {
            dispatch(errorStateStore({ state: [] }));
        },
        mutationKey: ['save-mutation'],
        onSuccess: (data) => {
            queryClient.invalidateQueries(['get-client-data-', year]);
        },
        onError: (error: any) => {
            setErrorForYear(year, error.response.data.message);
        },
    });

    const setFormState = (params: { id: string; value: any }) => {
        setStateForYear(year, { [params.id]: params.value });
    };

    const setBusinessFormState = (business_id: string, params: { id: string; value: any }) => {
        setStateForBusinessYear(business_id, year, { [params.id]: params.value });
    };

    const setOptionValue = (params: {
        path: string;
        value: 'one' | 'no' | 'multiple' | undefined | null;
    }) => {
        const initialFormStatus = typeof yearBasedMetaState?.form_status === 'string' ? {} : yearBasedMetaState?.form_status;

        setMeta({
            ...yearBasedMetaState,
            form_status: {
                ...initialFormStatus,
                [params.path]: params.value,
            },
        });
    };

    // to get calculated percentage
    function getCompletionPercentage(pageState: any, currentYear?: String) {
        //pass currentYear along with page_json to use this function globally
        let n_pageState = currentYear ? pageState : pageState;

        //to get selected pages
        const page = pagesRaw?.map((page: any) => {
            return page.address;
        });

        const page_json = Object.keys(n_pageState || {});

        const trueKeys = page_json.filter(
            (key) => n_pageState[key] && key !== 'percentage_complete',
        );

        return Math.floor((trueKeys.length / page.length) * 100);
    }

    const setPageStateValue = (
        params: {
            path: string;
            value: any;
        }
    ) => {
        // Check if meta.page_json is not null or undefined
        const initialPageJson = yearBasedMetaState?.page_json || {};
        const initialServerPageJson = yearBasedServerMetaState?.page_json || {};

        const serverValue = initialServerPageJson?.[params.path];

        /** Override undefined if the server was initially in the undefined state */
        if (serverValue === undefined && params.value === false) {
            params.value = undefined;
        }

        const updatedPageState = {
            ...initialPageJson,
            [params.path]: params.value,
        };

        //setting page_json in meta.page_json
        const updatedMeta = {
            ...yearBasedMetaState,
            page_json: {
                ...initialPageJson,
                ...updatedPageState,
                percentage_complete: getCompletionPercentage(updatedPageState),
            },
        };
        setMeta(updatedMeta);
    };

    const setBusinessOptionValue = (
        businessId: string,
        params: {
            path: string;
            value: 'one' | 'no' | 'multiple' | undefined | null;
        },
    ) => {
        const initialFormStatus = typeof yearBasedMetaState?.form_status === 'string' ? {} : yearBasedMetaState?.form_status;

        setMeta({
            ...yearBasedMetaState,
            form_status: {
                ...initialFormStatus,
                business_data: {
                    ...(initialFormStatus?.['business_data'] || {}),
                    [businessId]: {
                        ...(initialFormStatus?.['business_data']?.[
                            businessId
                        ] || {}),
                        [params.path]: params.value,
                    },
                },
            },
        });
    };

    const getBusinessOptionValue = (businessId: string, path: string) => {
        return yearBasedMetaState.form_status?.['business_data']?.[businessId]?.[
            path + '_count'
        ];
    };

    const getOptionValue = (path: string) => {
        console.log('option value', yearBasedMetaState)
        return yearBasedMetaState.form_status?.[path + '_count'];
    };


    const businessGroup = Object.keys(state).reduce(
        (group, yearKey) => {
            if (!businessFormState) {
                return group;
            }

            const relatedBusiness = state[yearKey]?.['business_data']?.find((busData: any) => {
                if (!busData) {
                    return false;
                }

                const { business_group_id } = busData;
                return business_group_id === businessFormState.business_group_id;
            });

            if (!relatedBusiness) {
                return group;
            }

            return {
                ...group,
                [yearKey]: relatedBusiness,
            };
        },
        { [year]: businessFormState },
    );

    /** address and the target year can be found from the context */
    const copyDataFunction = async (fromYear: string) => {
        const fromData = state[fromYear][address];

        const fromOptionValue =
            fromData?.meta_data?.form_status?.[address + '_count'];
        const initialFormStatus = typeof yearBasedMetaState?.form_status === 'string' ? {} : yearBasedMetaState?.form_status;

        const { data } = await page?.save(year, {
            ...state[year],
            [address]: fromData.map(({ id, ...item }: any, index: number) => ({
                ...item,
                id: 'new_' + dayjs().millisecond() + index,
                taxprep_year: undefined,
            })),

            meta_data: {
                ...yearBasedMetaState,
                form_status: {
                    ...initialFormStatus,
                    [address + '_count']: fromOptionValue,
                },
            },
        });

        return data;
    };

    const copyData = useMutation({
        mutationKey: ['copy-tax-prep-data'],
        mutationFn: copyDataFunction,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['get-client-data-', year]);
        },

        // () => {
        //
        // },
        onError: (data) => { },
    });

    const copyBusinessData = useMutation({
        mutationKey: ['upsert-business'],
        mutationFn: async (params: { copyYear: string; businessData: any }) => {
            const { id, ...createData } = params.businessData;

            const { data } = await upsertBusinesses(params.copyYear, {
                meta_data: yearBasedMetaState,
                business_data: [{ ...createData, id: 'new_000', action: 'create' }],
            });

            return data?.data?.[0];
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['get-client-data-', year]);
        },

        // () => {
        //
        // },
        onError: (data) => { },
    });

    return {
        state,
        meta: yearBasedMetaState,
        isNew: yearBasedState?.[address]?.length === 0,
        copyData,
        copyBusinessData,
        address,
        year,
        setOptionValue,
        setPageStateValue,
        getOptionValue,
        getBusinessOptionValue,
        setBusinessOptionValue,
        formState: { ...yearBasedState, _errors: yearBasedErrorState },
        setFormState,
        businessFormState,
        setBusinessFormState,
        updateClient,
        businessId,
        businessGroup,
        setError: setErrorForYear,
    };
};

export default useTaxPrepClientData;
