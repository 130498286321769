import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.css';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

import { FormProvider } from './context/form';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './context/auth';
import { Helmet } from 'react-helmet';
import { SaveProvider } from './context/save';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './stateManager/store';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './i18n';
import { useTranslation } from 'react-i18next';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            retry: 2,
            retryDelay: 500,
            cacheTime: 10 * 60 * 1000,
        },
    },
});

const msalInstance = new PublicClientApplication(msalConfig);

const ContextProvider: React.FC = ({ children }) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <SaveProvider>
                            <FormProvider>{children}</FormProvider>
                        </SaveProvider>
                    </AuthProvider>
                </QueryClientProvider>{' '}
            </PersistGate>
        </Provider>
    );
};
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
ReactDOM.render(
    <ContextProvider>
        <Helmet>
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <link
                rel="stylesheet"
                href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
            />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
            />

            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
            />
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,0,0"
            />
            {/* new code */}
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
            />

            {/* new code */}

            <script>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-P44P9W2');`}
            </script>
        </Helmet>
        <MsalProvider instance={msalInstance}>
            <GoogleOAuthProvider clientId={googleClientId!}>
                <App />
            </GoogleOAuthProvider>
        </MsalProvider>
    </ContextProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
