export const taxometrics_dashboard = {
    'Tax Return Status': 'Tax Return Status',
    Income: 'Income',
    Levies: 'Levies',
    Liens: 'Liens',
    'IRS Debt Actions': 'IRS Debt Actions',
    Audits: 'Audits',
    'Passport Revocation': 'Passport Revocation',
    'Private Collections:': 'Private Collections',
    'Total Balance Due': 'Total Balance Due',
    'Total Balance Due:': 'Total Balance Due:',
    'Total Penalities': 'Total Penalities',
    'Total Penalities:': 'Total Penalities:',
    'Total Interest': 'Total Interest',
    'Total Interest:': 'Total Interest:',
    'Civil Penalties Balance Due': 'Civil Penalties Balance Due',
    'Civil Penalties Balance Due:': 'Civil Penalties Balance Due:',
    'In Compliance:': 'In Compliance:',
    'No Return Filed:': 'No Return Filed:',
    'Substitute for Return': 'Substitute for Return',
    'Intent to Levy Notice:': 'Intent to Levy Notice',
    'Lien Notice Issued': 'Lien Notice Issued',
    'IRS Collections Active': 'IRS Collections Active',
    'Installment Agreement Pending': 'Installment Agreement Pending',
    'In Installment Agreement': 'In Installment Agreement',
    'Currently Not Collectible': 'Currently Not Collectible',
    'No Active Audit/Examination': 'No Active Audit/Examination',
    'Certified for Revocation': 'Certified for Revocation',
    'Private Collection Agency Assigned:': 'Private Collection Agency Assigned:',
    'Total Balances:': 'Total Balances:',
    'Missing Information!': 'Missing Information!',
    'No Intent to Levy Notice Issued': 'No Intent to Levy Notice Issued',
    'No Lien Notice Issued': 'No Lien Notice Issued',
    'No Active Collections': 'No Active Collections',
    'No Active Audits/Examinations': 'No Active Audits/Examinations',
    'Passport Not Certified for Revocation': 'Passport Not Certified for Revocation',
    'Not Assigned to Private Collection Agency': 'Not Assigned to Private Collection Agency',
    'No Balance Due': 'No Balance Due',
    'No Penalties:': 'No Penalties:',
    'No Interests:': 'No Interests:',
    'No Civil Penalties Balance Due': 'No Civil Penalties Balance Due',
    'No Tax Filing Data': 'No Tax Filing Data',
    'All Dates': 'All Dates',
    'Bankruptcy/Other legal action:': 'Bankruptcy/Other legal action:',
    'Account Collectible actions:': 'Account Collectible actions:',
    'Installment Agreement Actions:': 'Installment Agreement Actions:',
    'Offer In Compromise Actions': 'Offer In Compromise Actions:',
    'Audit/Eamination Active': 'Audit/Eamination Active',
    'Years with Interest': 'Years with Interest',
    'Years with Interest:': 'Years with Interest:',
    'Years with Penalties': 'Years with Penalties',
    'Total Penalties': 'Total Penalties',
    'Total Penalties:': 'Total Penalties:',
    'Years with Balances:': 'Years with Balances:',
    'No Data': 'No Data',
    'View Details': 'View Details',
    'No Data Available': 'No Data Available',
    'Client Authorizations': 'Client Authorizations',
    'No authorizations yet!': 'No authorizations yet!',
    'authorizations data missing please add authorization':
        'authorizations data missing please add authorization',
    'using the button below.': 'using the button below.',
    'New Authorization': 'New Authorization',
    'Marriage Date': 'Marriage Date',
};
