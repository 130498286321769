import classNames from 'classnames';
import moment, { invalid } from 'moment';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTable, useResizeColumns, useRowSelect } from 'react-table';
import { useFormDispatch, useFormState } from '../../context/form';
import { enumSections, fieldTypes } from '../../utils/enums';
import { template } from '../../utils/forms';
import cloud_logo from '../../assets/cloud.png';

import {
    BrickInterface,
    ConcreteInterface,
    FormInterface,
    FormStateInterface,
    interface_table,
} from '../../utils/interfaces';
import FormConstructor from '../basic/FormConstructor';
import Button from '../basic/Button';
import Modal from '../basic/Modal';
import ParentGroup from './ParentGroup';

/**
 * Complex UI Component for Table Components
 **/

type TableGroupProps = interface_table & {
    className?: string;
    inputType?: string;
    value: any[];
    error?: { [key: string]: any };
    setValue: (str: any) => void;
};

type EditRowInterface = interface_table & {
    id: string | null;
    close: (v: void) => void;
    title?: string;
    update: (state: FormStateInterface | undefined) => void;
    deleteRow: (row: FormStateInterface) => void;
    data?: { [key: string]: any };
    error?: any;
};

const EditRow: React.FC<EditRowInterface> = ({
    id,
    data = {},
    error,
    identifier = 'id',
    rowStructure,
    title,
    update,
    deleteRow,
    dynamicSave,
    dynamicSaveMessage,
}) => {
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [localFormState, setLocalFormState] = useState(data);
    const [dynamicallyAdded, setDynamicallyAdded] = useState<boolean>(false);
    const formDispatch = useFormDispatch();
    const { meta_data } = useFormState();

    /** If the item is new, give default new values */
    useEffect(() => {
        if (id === 'new') setLocalFormState({ [identifier || '']: `new` });
    }, []);

    const queryClient = useQueryClient();

    const mutation = useMutation(dynamicSave ? dynamicSave.saveFunction : async () => {}, {
        onSuccess: () => {
            if (dynamicSave?.sectionId) {
                queryClient.invalidateQueries(`${dynamicSave.sectionId}`);
                setDynamicallyAdded(true);
            }
        },
        onError: (err: any) => {
            let errorObj = { business_data: { new: { name: ['Error'] } } };
            if (err.response.data?.message?.['new']) {
                errorObj.business_data = err.response.data?.message;
            } else if (err.response.data?.message?.[0]) {
                errorObj.business_data.new = err.response.data?.message?.[0];
            }

            if (dynamicSave?.sectionId) {
                formDispatch({
                    type: 'SET_ERRORS',
                    payload: {
                        data: errorObj,
                        // sections: { ['business_home']: true },
                    },
                });
            }
        },
    });

    useEffect(() => {
        const saveTimeout = setTimeout(() => {
            if (dynamicallyAdded) {
                setDynamicallyAdded(false);
                update(undefined);
            }
        }, 4000);

        return () => clearTimeout(saveTimeout);
    }, [dynamicallyAdded]);

    const saveAndClose = () => {
        if (dynamicSave?.saveFunction) {
            mutation.mutate({ business: localFormState, meta_data });
        } else {
            update(localFormState);
        }
    };

    const resetAndClose = () => {
        update(data);
    };

    const setValue = ({ id, value }: any) => {
        setLocalFormState((state) => ({ ...state, [id]: value }));
    };

    return (
        <Modal isOpen={id !== ''} close={resetAndClose}>
            {dynamicallyAdded ? (
                <>
                    <div
                        className="bg-white w-full max-w-3xl flex flex-col items-center justify-center rounded"
                        style={{ maxHeight: '95%', minHeight: '40%' }}
                    >
                        <div className="p-2 border-2 border-primary-500 h-16 w-16 rounded-full">
                            <img src={cloud_logo} className="h-full w-full" />
                        </div>
                        <div className="pt-5 pb-1">{dynamicSaveMessage}</div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        id={'modal_' + id}
                        className="bg-white w-full max-w-4xl flex flex-col items-center "
                        style={{ maxHeight: '95%', minHeight: '40%' }}
                    >
                        {!confirmDelete ? (
                            <div className="flex font-title text-lg font-medium px-5 py-3 w-full text-left bg-gray-100">
                                <div className="flex-grow">{title || 'Row Data'}</div>
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setConfirmDelete(true);
                                    }}
                                    className="material-icons cursor-pointer mr-2 ml-1 text-gray-400 hover:text-red-400"
                                >
                                    delete_forever
                                </span>
                            </div>
                        ) : (
                            <div
                                className="flex w-full border items-center border-yellow-200  py-1 border-t-0 cursor-pointer hover:bg-yellow-100 bg-yellow-50"
                                style={{ minHeight: 50 }}
                            >
                                <span className="material-icons cursor-pointer ml-2 text-red-400">
                                    warning
                                </span>
                                <div className="flex-grow pl-3 font-medium">{`Are you sure you want to delete this row?`}</div>
                                <div className="flex">
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            deleteRow(data);

                                            if (dynamicSave?.saveFunction) {
                                                mutation.mutate({
                                                    business: {
                                                        action: 'delete',
                                                        ...localFormState,
                                                    },
                                                    meta_data,
                                                });
                                            }
                                        }}
                                        className="material-icons cursor-pointer ml-1 text-primary-500"
                                    >
                                        check_circle
                                    </span>
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setConfirmDelete(false);
                                        }}
                                        className="material-icons mr-2 ml-1 text-red-400"
                                    >
                                        cancel
                                    </span>
                                </div>
                            </div>
                        )}
                        {/* <div className="w-full h-px bg-gray-300" /> */}

                        {/* Content */}
                        <div className="flex-grow w-full overflow-auto flex flex-col items-center overflow-y-scroll p-3">
                            <FormConstructor
                                structure={rowStructure}
                                formState={{
                                    ...localFormState,
                                    _errors: error,
                                }}
                                setFormState={setValue}
                                className="mt-3 mb-8 justify-center"
                            />

                            <div className="flex w-full items-center justify-center bg-gray-50 pt-3 pb-8">
                                <div className="flex flex-grow justify-end max-w-2xl">
                                    <div className="flex w-80">
                                        <Button
                                            onClick={resetAndClose}
                                            secondary
                                            className="w-1/2 mr-1 bg-white"
                                        >{`CANCEL`}</Button>
                                        <Button
                                            isLoading={mutation.isLoading}
                                            onClick={saveAndClose}
                                            className="w-1/2 ml-1"
                                        >
                                            {'CONFIRM'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};

// const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
//     const defaultRef = useRef();
//     const resolvedRef = ref || defaultRef;

//     useEffect(() => {
//         resolvedRef.current.indeterminate = indeterminate;
//     }, [resolvedRef, indeterminate]);

//     return (
//         <>
//             <input type="checkbox" ref={resolvedRef} {...rest} />
//         </>
//     );
// });

export const Table: React.FC<TableGroupProps> = ({
    id,
    type,
    value,
    identifier = 'id',
    setValue,
    displayColumns,
    dynamicSave,
    addButtonText,
    rowStructure,
    header,
    error,
    dynamicSaveMessage,
}) => {
    const [editRow, setEditRow] = useState<string | null>(null);

    const updateRow = (row: FormStateInterface | undefined) => {
        if (row === undefined) {
            setEditRow(null);
            return;
        }

        if (row[identifier] === 'new') {
            setValue([...(value || []), { ...row, [identifier]: `new_${moment.now()}` }]);
        } else {
            const newValue = value.map((item) => {
                if (item[identifier] === row[identifier]) return row;
                else return item;
            });

            setValue(newValue);
        }

        setEditRow(null);
    };

    const deleteRow = (row: FormStateInterface) => {
        const newValue = value.map((item) => {
            if (item[identifier] === row[identifier]) {
                return { ...item, action: 'delete' };
            }

            return item;
        });
        setValue(newValue);
        setEditRow(null);
    };

    const data: { [key: string]: any }[] = value || [];

    function isBrick(field: BrickInterface | ConcreteInterface): field is BrickInterface {
        return (field as BrickInterface).id !== undefined;
    }

    const columns: Array<any> = useMemo(
        () =>
            rowStructure
                .map((field) => {
                    if (isBrick(field)) {
                        const brick = field as BrickInterface;

                        switch (brick.type) {
                            case fieldTypes.TEXT_INPUT:
                                return {
                                    valid: true,
                                    Header: brick.label || brick.id,
                                    accessor: brick.id, // accessor is the "key" in the data
                                };

                            case fieldTypes.AMOUNT_INPUT:
                                return {
                                    valid: true,
                                    Header: brick.label || brick.id,
                                    accessor: brick.id, // accessor is the "key" in the data
                                    Cell: ({ value }: any) =>
                                        !value && value !== 0
                                            ? ''
                                            : '$' + Intl.NumberFormat('en-US').format(value),
                                };

                            case fieldTypes.TEXT_DISPLAY:
                                return {
                                    valid: true,
                                    Header: brick.label || brick.id,
                                    accessor: brick.id, // accessor is the "key" in the data
                                };

                            case fieldTypes.DROPDOWN:
                                return {
                                    valid: true,
                                    Header: brick.label || brick.id,
                                    accessor: brick.id, // accessor is the "key" in the data
                                    Cell: ({ value }: any) =>
                                        brick.options.find((o) => o.value === value)?.label || '',
                                };

                            case fieldTypes.RADIO:
                                return {
                                    valid: true,
                                    Header: brick.label || brick.id,
                                    accessor: brick.id, // accessor is the "key" in the data
                                    Cell: ({ value }: any) =>
                                        brick.options.find((o) => o.value === value)?.label || '',
                                };

                            case fieldTypes.CALENDAR:
                                return {
                                    valid: true,
                                    Header: brick.label || brick.id,
                                    accessor: brick.id, // accessor is the "key" in the data
                                    Cell: ({ value }: any) => {
                                        if (value)
                                            return moment(value, 'MM-DD-YYYY').format('MM-DD-YYYY');
                                        return '';
                                    },
                                };
                        }
                    } else return { valid: false };
                })
                .filter(({ valid }: any) => valid),
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 80, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 250, // maxWidth is only used as a limit for resizing
        }),
        [],
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        defaultColumn,
    });

    return (
        <ParentGroup className={`col-span-4 w-full`}>
            <div className="overflow-y-auto h-full px-0.5">
                <table {...getTableProps()} className="w-full">
                    <thead className="sticky top-0">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="bg-gray-700 px-2 py-1 font-title font-normal text-left text-white"
                                        >
                                            {column.render('Header')}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, rIndex) => {
                            prepareRow(row);

                            // If the row has been deleted, do not render it
                            if (row?.original.action === 'delete') return <></>;

                            const row_error = error?.[row.original.id];
                            const error_length = Object.keys(row_error || {}).length;

                            return (
                                <tr
                                    onClick={() => setEditRow(row.original[identifier])}
                                    {...row.getRowProps()}
                                    className={classNames('p-1 cursor-pointer', {
                                        'bg-red-100 hover:bg-red-200': error_length > 0,
                                        'hover:bg-gray-50': error_length === 0,
                                    })}
                                >
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-2 py-1 border border-gray-300"
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* ADD BUTTON */}
                <div
                    onClick={() => setEditRow('new')}
                    style={{ minHeight: 33 }}
                    className={classNames(
                        'w-full border flex justify-center items-center border-gray-200 px-2 cursor-pointer hover:bg-gray-100',
                        {
                            'border-dashed bg-gray-50': Array.isArray(value) && value?.length === 0,
                            'border-t-0': Array.isArray(value) && value?.length !== 0,
                        },
                    )}
                >
                    {(Array.isArray(value) && value?.length === 0) || !value ? (
                        <div className="py-8 ">
                            <div className="text-gray-500 text-sm font-body font-medium">
                                No records exist. Click to add first
                            </div>
                            <div className=" text-primary-500 text-center font-title font-bold mt-1">
                                ADD ROW
                            </div>
                        </div>
                    ) : (
                        <div className="text-primary-500 text-center font-title text-sm font-bold mt-1">
                            {addButtonText || 'ADD NEW ROW'}
                        </div>
                    )}
                </div>
            </div>

            <EditRow
                key={editRow}
                id={editRow || ''}
                identifier={identifier}
                close={() => setEditRow(null)}
                rowStructure={rowStructure}
                update={updateRow}
                deleteRow={deleteRow}
                data={value?.find((item) => item[identifier] === editRow)}
                error={error?.[editRow || '']}
                dynamicSave={dynamicSave}
                dynamicSaveMessage={dynamicSaveMessage}
                displayColumns={displayColumns}
                type={type}
                title={`Row Data | ${header}`}
            />
        </ParentGroup>
    );
};
