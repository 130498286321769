import { upsertOtherFinancial } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const payrollServiceForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'payroll_service_name',
        label: 'Name',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'payroll_service_effective_date',
        label: 'Effective Date',
        placeholder: 'Tampa Bay',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Payroll Service Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'payroll_service_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'payroll_service_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'payroll_service_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'payroll_service_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
];

const lawsuitForm: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'lawsuit_plaintiff_defendant',
        label: 'Plaintiff/Defendant',
        placeholder: 'Tampa Bay',
        options: [
            { value: 'def', label: 'Defendant' },
            { value: 'pla', label: 'Plaintiff' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_location_of_filing',
        label: 'Location of Filing',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_represented_by',
        label: 'Represented by',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_docket_case_num',
        label: 'Docket/Case No',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_amount_of_suit',
        label: 'Amount of Suit',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'lawsuit_possible_completion_date',
        label: 'Possible Completion Date',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lawsuit_subject_of_suit',
        label: 'Subject of Suit',
        area: true,
        span: 2,
        placeholder: 'johndoe@hotmail.com',
    },
];

const bankruptcyForm: FormInterface = [
    {
        type: fieldTypes.CALENDAR,
        id: 'bankruptcy_filed_date',
        label: 'Date Filed',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'bankruptcy_dismissed_date',
        label: 'Date Dismissed',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'bankruptcy_discharged_date',
        label: 'Date Discharged',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'bankruptcy_petition_num',
        label: 'Petition No.',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'bankruptcy_location',
        label: 'District of Filing',
        placeholder: 'johndoe@hotmail.com',
    },
];

const incomeForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        span: 2,
        id: 'change_in_income_explain',
        label: 'Explain',
        placeholder: '',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'change_in_income_amount',
        label: 'How much will it increase/decrease',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'change_in_income_when',
        label: 'When will it increase/decrease',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
];

const governmentForm: FormInterface = [
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'cash_on_hand',
        span: 2,
        label: 'Cash on hand',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
];

const safeForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        span: 2,
        id: 'safe_contents',
        label: 'Contents',
        placeholder: '',
    },
    { type: fieldTypes.SEPERATOR },
];

const form: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Other Financial Information' },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Does the business use a Payroll Service Provider or Reporting Agent',
        id: 'payroll_service_ind',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: payrollServiceForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Is the business a party to a lawsuit',
        id: 'lawsuit_party',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: lawsuitForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Has the business ever filed bankruptcy',
        id: 'bankruptcy_filed',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: bankruptcyForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Any increase or decrease in income?',
        id: 'change_in_income',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: incomeForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Is the business a Federal Government Contractor?',
        id: 'federal_contractor_ind',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: governmentForm }],
        },
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        label: 'Is there a safe on the business premises?',
        id: 'safe_on_premises_ind',
        span: 2,
        branches: {
            inline: true,
            paths: [{ value: 'yes', form: safeForm }],
        },
    },

    { type: fieldTypes.TERMINAL },
];

export const page: PageInputInterface = {
    address: 'business_other_financials',
    name: 'Business Other Financial Information',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertOtherFinancial,
};
