import YearToggle from './YearToggle';
import useTaxPrepClientData from './useTaxPrepClientData';
import Button from '../basic/Button';
import Modal from '../basic/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from '../../context/auth';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { useEffect, useState } from 'react';

const CopyBusinessClient: React.FC<{ toYear: string; isOpen: boolean; close: () => void }> = ({
    isOpen,
    close,
    toYear,
}) => {
    const { address, year } = useTaxPrepStructure();
    const [promptCopy, setPromptCopy] = useState(false);

    let { copyBusinessData, businessFormState: fromBusiness } = useTaxPrepClientData();
    let { formState: toState } = useTaxPrepClientData(toYear);

    const navigate = useNavigate();

    const toBusiness = toState?.['business_data']?.find(
        ({ business_group_id }: any) => fromBusiness.business_group_id === business_group_id,
    );

    useEffect(() => {
        if (!toBusiness) {
            setPromptCopy(true);
            return;
        } else {
            setPromptCopy(false);

            navigate(
                '/test/tax-prep/' +
                    `${address}.${toBusiness.id}.${toBusiness.business_group_id}` +
                    '/' +
                    toYear,
            );
            close();
        }
    }, [toState]);

    const copyBusiness = async () => {
        const data = await copyBusinessData.mutateAsync({
            businessData: fromBusiness,
            copyYear: toYear,
        });
        navigate(
            '/test/tax-prep/' + `${address}.${data.id}.${data.business_group_id}` + '/' + toYear,
        );
    };

    return (
        // <div className="flex-grow flex justify-center mt-10">
        //     <div className="flex flex-col w-full max-w-2xl px-1 py-3">
        //         <YearToggle year={year!} setYear={() => {}} />

        <Modal isOpen={isOpen} close={close}>
            <div
                className="bg-white w-full max-w-4xl flex flex-col items-center justify-center rounded p-6"
                style={{ maxHeight: '95%' }}
            >
                {promptCopy ? (
                    <>
                        <div className="flex-grow">
                            <div className="font-semibold text-lg">{`Copy Business Data?`}</div>
                            <div className="">{`The Business ${fromBusiness.business_name} does not exist in the year ${toYear}. Do you want to create a copy of this business`}</div>
                        </div>
                        <div className="w-full mt-5 pt-3">
                            <div className="flex w-full items-center justify-center">
                                <Button
                                    isLoading={copyBusinessData.isLoading}
                                    onClick={copyBusiness}
                                    className="w-1/4 mr-1 bg-white"
                                >{`COPY BUSINESS`}</Button>
                                <Button onClick={close} secondary className="w-1/4 ml-1">
                                    {'NOT YET'}
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>Redirecting...</div>
                )}
            </div>
        </Modal>
        //         {/* <Button isLoading={updateClient.isLoading} onClick={() => updateClient.mutate()}>
        //             SAVE
        //         </Button> */}
        //     </div>
        // </div>
    );
};

export default CopyBusinessClient;
