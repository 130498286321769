import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { usePageSelector } from './UploadModal';
import { date } from '../../utils/format';
import { useAuthState } from '../../context/auth';
import FullfillRequestModal from './FullfillRequestModal';
import DeleteRequestModal from './DeleteRequestModal';
import { build } from '../../config';

export const ActionsCell: React.FC<{
    original: any;
    small?: boolean;
}> = ({ original }) => {
    const [deleteItem, setDeleteItem] = useState<boolean>(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);

    const { role } = useAuthState();

    return (
        <>
            <div className="flex items-right justify-end mr-1">
                {original.request_fullfill === 'yes' && (
                    <a
                        data-tip="Download File"
                        target="_blank"
                        download={original.document_name}
                        href={`${build.baseURL}/downloadFile/${original.id}`}
                    >
                        <span
                            className="cursor-pointer material-symbols-outlined text-gray-400 hover:text-primary-500"
                            style={{ fontSize: 21 }}
                        >
                            file_download
                        </span>
                    </a>
                )}

                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        setUploadModal(true);
                    }}
                    className="cursor-pointer font-bold material-symbols-outlined text-gray-400 hover:text-primary-500"
                    style={{ fontSize: 21 }}
                >
                    cloud_upload
                </span>

                {role !== 'Client' && (
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            setDeleteItem(true);
                        }}
                        className="cursor-pointer material-icons text-gray-400 hover:text-red-400"
                        style={{ fontSize: 20 }}
                    >
                        delete_forever
                    </span>
                )}
            </div>

            <DeleteRequestModal
                isOpen={deleteItem}
                close={() => setDeleteItem(false)}
                document={original}
            />

            <FullfillRequestModal
                isOpen={uploadModal}
                close={() => setUploadModal(false)}
                document_id={original.id}
                description={original.description}
            />
        </>
    );
};

export const MobileRequestsTable: React.FC<{ data: Array<any> }> = ({ data = [] }) => {
    const { pageOptions } = usePageSelector();

    return (
        <div className="w-full h-full">
            {data.map((item) => {
                return (
                    <div className="w-full px-2 py-3 border-b">
                        <div className="flex">
                            <div className="text-sm flex-grow text-gray-600">
                                {pageOptions.find((o) => o.value === item.section)?.label}
                            </div>
                            <div className="flex items-center ">
                                <div
                                    className={classNames('h-3 w-3 rounded-full', {
                                        'bg-yellow-500': item.request_fullfill === 'no',
                                        'bg-primary-500': item.request_fullfill === 'yes',
                                    })}
                                />
                                <div className="ml-2 text-xs">
                                    {item.request_fullfill === 'no'
                                        ? 'Pending'
                                        : date(item.last_updated_date) || ''}
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-grow">{item.description}</div>
                            <ActionsCell original={item} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const RequestsTable: React.FC<{ data: any[] }> = ({ data = [] }) => {
    const { pageOptions } = usePageSelector();

    const columns = useMemo(
        () => [
            {
                Header: 'Uploaded',
                accessor: 'last_updated_date', // accessor is the "key" in the data
                Cell: ({ value, row: { original } }: any) => {
                    return (
                        <div className="flex items-center">
                            <div
                                className={classNames('h-3 w-3 rounded-full', {
                                    'bg-yellow-500': original.request_fullfill === 'no',
                                    'bg-primary-500': original.request_fullfill === 'yes',
                                })}
                            />
                            <div className="ml-2">
                                {original.request_fullfill === 'no' ? 'Pending' : date(value) || ''}
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Description',
                accessor: 'description', // accessor is the "key" in the data
                Cell: ({ row: { original } }: any) => (
                    <a
                        href={original.document_url}
                        target="_blank"
                        className="text-primary-500 hover:underline"
                    >
                        {original.description}
                    </a>
                ),
            },
            {
                Header: 'Section',
                accessor: 'section', // accessor is the "key" in the data
                Cell: ({ row: { original } }: any) => (
                    <div>{pageOptions.find((o) => o.value === original.section)?.label}</div>
                ),
            },

            {
                Header: 'Actions',
                Cell: ({ row: { original } }: any) => <ActionsCell original={original} />,
                sortable: false,
            },
        ],
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 80, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 250, // maxWidth is only used as a limit for resizing
        }),
        [],
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        defaultColumn,
    });

    return (
        <div className="h-full px-0.5">
            <table {...getTableProps()} className="w-full">
                <thead className="sticky top-0">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className={classNames(
                                        'px-1 py-1 border-b border-gray-210 text-left text-xs font-semibold',
                                        {
                                            'text-center': column.render('Header') === 'Actions',
                                        },
                                    )}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rIndex) => {
                        prepareRow(row);

                        // If the row has been deleted, do not render it
                        if (row?.original.action === 'delete') return <></>;

                        return (
                            <tr
                                {...row.getRowProps()}
                                className={classNames('p-1 cursor-pointer', {
                                    // 'bg-red-100 hover:bg-red-210': error_length > 0,
                                    // 'hover:bg-gray-50': error_length === 0,
                                })}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td {...cell.getCellProps()} className="px-1 py-1 ">
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
