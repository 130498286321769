import classNames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTable, useResizeColumns, useRowSelect } from 'react-table';
import DeleteModal from './DeleteModal';
import { usePageSelector } from './UploadModal';
import axios from 'axios';
import { useAuthState } from '../../context/auth';
import { date } from '../../utils/format';
import { build } from '../../config';
import { useMutation, useQuery } from 'react-query';

export const ActionsCell: React.FC<{
    original: any;
    small?: boolean;
}> = ({ original }) => {
    const [deleteItem, setDeleteItem] = useState<boolean>(false);

    return (
        <>
            <div className="flex items-center justify-center">
                <a
                    data-tip="Download File"
                    target="_blank"
                    download={original.document_name}
                    href={`${build.baseURL}/downloadFile/${original.id}`}
                >
                    <span
                        className="cursor-pointer font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
                        style={{ fontSize: 20 }}
                    >
                        file_download
                    </span>
                </a>

                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        setDeleteItem(true);
                    }}
                    className="cursor-pointer material-icons text-gray-400 hover:text-red-400"
                    style={{ fontSize: 20 }}
                >
                    delete_forever
                </span>
            </div>
            <DeleteModal
                isOpen={deleteItem}
                close={() => setDeleteItem(false)}
                document={original}
            />
        </>
    );
};

export const MobileUploadsTable: React.FC<{ data: any[]; onDocumentClick: (doc: any) => void }> = ({
    data = [],
    onDocumentClick,
}) => {
    const { pageOptions } = usePageSelector();

    return (
        <div className="w-full h-full">
            {data.map((item) => {
                return (
                    <div
                        onClick={() => onDocumentClick(item)}
                        className="w-full px-2 py-3 border-b"
                    >
                        <div className="flex">
                            <div className="text-sm flex-grow text-gray-600">
                                {pageOptions.find((o) => o.value === item.section)?.label}
                            </div>
                            <div className="flex items-center ">
                                <div
                                    className={classNames('h-3 w-3 rounded-full', {
                                        'bg-yellow-500': item.request_fullfill === 'no',
                                        'bg-primary-500': item.request_fullfill === 'yes',
                                    })}
                                />
                                <div className="ml-2 text-xs">
                                    {item.request_fullfill === 'no'
                                        ? 'Pending'
                                        : date(item.last_updated_date) || ''}
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-grow">{item.document_name}</div>
                            <ActionsCell original={item} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const UploadsTable: React.FC<{ data: any[]; onDocumentClick: (doc: any) => void }> = ({
    data = [],
    onDocumentClick,
}) => {
    const { pageOptions } = usePageSelector();

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'document_name', // accessor is the "key" in the data
                Cell: ({ row: { original } }: any) => (
                    <div
                        onClick={() => onDocumentClick(original)}
                        className="text-primary-500 hover:underline"
                    >
                        {original.document_name}
                    </div>
                ),
            },
            {
                Header: 'Section',
                accessor: 'section', // accessor is the "key" in the data
                Cell: ({ row: { original } }: any) => (
                    <div>{pageOptions.find((o) => o.value === original.section)?.label}</div>
                ),
            },
            {
                Header: 'Upload Date',
                accessor: 'last_updated_date', // accessor is the "key" in the data
                Cell: ({ value }: any) => {
                    if (value) return date(value);
                    return '';
                },
            },
            {
                Header: 'Actions',
                Cell: ({ row: { original } }: any) => <ActionsCell original={original} />,
                sortable: false,
            },
        ],
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 80, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 250, // maxWidth is only used as a limit for resizing
        }),
        [],
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        defaultColumn,
    });

    return (
        <div className="h-full px-0.5">
            <table {...getTableProps()} className="w-full">
                <thead className="sticky top-0">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className={classNames(
                                        'px-1 py-1 border-b border-gray-200 text-left text-xs font-semibold',
                                        {
                                            'text-center': column.render('Header') === 'Actions',
                                        },
                                    )}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rIndex) => {
                        prepareRow(row);

                        // If the row has been deleted, do not render it
                        if (row?.original.action === 'delete') return <></>;

                        return (
                            <tr
                                {...row.getRowProps()}
                                className={classNames('p-1 cursor-pointer', {
                                    // 'bg-red-100 hover:bg-red-200': error_length > 0,
                                    // 'hover:bg-gray-50': error_length === 0,
                                })}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td {...cell.getCellProps()} className="px-1 py-1 ">
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
