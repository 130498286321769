import { upsertPaymentProcessor } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const virtualCurrencyForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Name',
        placeholder: 'Bitcoin',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Payment Processor Account Number',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Company Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'company_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_payment_processor_data',
        label: 'Does your business have any payment processors?',
        header: 'Payment Processor Information',
        formStructure: virtualCurrencyForm,
        displayColumns: [
            {
                name: 'Name',
                key: 'company_name',
            },
            {
                name: 'Account Number',
                key: 'account_number',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'payment_processor',
    name: 'Business Payment Processor Information',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertPaymentProcessor,
};
