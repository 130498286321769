import classNames from 'classnames';
import { enumSections, fieldTypes } from '../../utils/enums';
import { compareItemsforChange, useFeatureEnabled } from '../../utils/helpers';
import {
    BrickInterface,
    ConcreteInterface,
    FormInterface,
    FormStateInterface,
    PageInterface,
} from '../../utils/interfaces';
import CheckMarkLogo from '../assets/checkmark.svg';
import { useEffect, useState } from 'react';
import InputGroup from '../admin/InputGroup';
import { useAuthState } from '../../context/auth';
import { useFormDispatch, useFormState } from '../../context/form';
import { useParams } from 'react-router-dom';
import { ActionsCell as DocumentActionsCell } from '../document/UploadsTable';
import { ActionsCell as RequestActionsCell } from '../document/RequestsTable';
import UploadModal from '../document/UploadModal';
import { date } from '../../utils/format';
import { build } from '../../config';
import CreateRequest from '../document/CreateRequestModal';
import { FilePreview } from '../document/Manager';
import useSectionLock from '../../api/hooks/useSectionLocks';
import Modal from '../basic/Modal';
import useTaxPrepEvalData from './useTaxPrepEvalData';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import FormConstructor from '../tax-prep-admin-form-components/FormContructor';
import { isBrick } from '../basic/FormConstructor';
import Dropdown from '../client/Dropdown';
import useFormsList from '../../api/tax-prep/useFormsList';
import useTaxPrepClientData from './useTaxPrepClientData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';
import { useClientFillupPercentage } from './ClientFillupPercentage';
import { pageStateStore } from '../../stateManager/taxPreparerSlice';
import useAdminCopySectionData from './useAdminCopySectionData';

export const useTaxPrepBusiness = (businessId?: string, year?: string) => {
    let { formState, state } = useTaxPrepEvalData();

    return (year ? state[year] : formState)?.['business_data']?.find(
        (bus: any) => bus?.id === businessId,
    );
};

export const getTaxPrepBusiness = (state: any, businessId: string) => {
    return state?.['business_data']?.find((bus: any) => bus?.id === businessId);
};

const MainSection: React.FC<{ currentPage: PageInterface }> = ({ currentPage }) => {
    const { address: page, section, formStructure } = currentPage;

    let {
        getOptionValue,
        setOptionValue,
        getBusinessOptionValue,
        setBusinessOptionValue,
        formState,
        setFormState,
        setBusinessFormState,
    } = useTaxPrepEvalData();

    const businessId = currentPage.businessId;
    const { toggleYear } = useTaxPrepStructure();

    let formData = formState;
    let setFormData = setFormState;

    const businessData = useTaxPrepBusiness(businessId);

    const relevantData = currentPage.formStructure.reduce((acc, item) => {
        if (isBrick(item)) {
            acc = { ...acc, [item.id]: formData[item.id] };
        }
        return acc;
    }, {});

    if (section.id === enumSections.BUSINESS_INFORMATION && businessId && businessData) {
        formData = businessData;
        setFormData = (params) => {
            setBusinessFormState(businessId, params);
        };
        getOptionValue = (path: string) => {
            return getBusinessOptionValue(businessId, path);
        };
        setOptionValue = (params) => {
            setBusinessOptionValue(businessId, params);
        };
    }

    if (!page) {
        return <div className="m-60">Unknown page</div>;
    }

    const setYear = (nextYear: string) => {
        toggleYear(nextYear);
    };

    return (
        <div className="flex-grow flex justify-center p-2">
            <FormConstructor
                className={classNames('flex flex-grow')}
                structure={formStructure}
                formState={formData}
                setFormState={setFormData}
                getOptionValue={getOptionValue}
                setOptionValue={setOptionValue}
            />
        </div>
    );
};

export type CommentType = {
    id: string;
    email_address: string;
    name: string;
    text: string;
    createdAt: string;
};

const Comments: React.FC<{ currentPage: PageInterface; business?: any }> = ({
    business,
    currentPage,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [comment, setComment] = useState('');
    const { user_id, name } = useAuthState();

    const formData = useFormState();
    const formDispatch = useFormDispatch();

    const saved_comments = business
        ? formData.meta_data?.form_status[business.id]
            ? formData.meta_data?.form_status[business.id][currentPage.address + '_comments']
            : undefined
        : formData.meta_data?.form_status
        ? formData.meta_data?.form_status[currentPage.address + '_comments']
        : undefined;

    const upsertComments = (comments: Array<CommentType>) => {
        if (business) {
            formDispatch({
                type: 'UPDATE_COMMENTS_BUSINESS',
                payload: {
                    businessId: business.id,
                    data: { [currentPage.address + '_comments']: comments },
                },
            });
        } else {
            formDispatch({
                type: 'UPDATE_COMMENTS',
                payload: { data: { [currentPage.address + '_comments']: comments } },
            });
        }
    };

    const addComment = () => {
        if ([undefined, null, ''].includes(comment)) {
            return;
        }

        const newComment: CommentType = {
            id: user_id!,
            email_address: formData.email_address,
            name: name!,
            text: comment,
            createdAt: new Date().toISOString(),
        };

        if (saved_comments) {
            upsertComments([...saved_comments, newComment]);
        } else {
            upsertComments([newComment]);
        }
        setComment('');
    };

    return (
        <>
            {saved_comments?.length > 0 ? (
                <>
                    <div className="bg-primary-500 text-xs font-bold text-white rounded-full h-4 w-4 flex mr-1 justify-center">
                        {saved_comments.length}
                    </div>

                    <span
                        onClick={() => setIsOpen(true)}
                        className="material-icons cursor-pointer text-primary-500 hover:text-primary-800"
                    >
                        comment
                    </span>
                </>
            ) : (
                <span
                    onClick={() => setIsOpen(true)}
                    className="material-icons cursor-pointer text-gray-500 hover:text-gray-800 "
                >
                    comment
                </span>
            )}

            {isOpen && (
                <div
                    className="absolute rounded-md flex flex-col h-full w-64 right-0 top-0 bg-white z-20 shadow-md"
                    style={{ minHeight: 300 }}
                >
                    <div className="w-full rounded-md flex items-center bg-gray-700 px-2">
                        <div className="font-title text-sm p-0.5 flex-grow text-white">
                            {'Comments'}
                        </div>
                        <span
                            onClick={() => setIsOpen(false)}
                            className="material-icons cursor-pointer text-white hover:text-primary-400 "
                        >
                            close
                        </span>
                    </div>

                    <div className="flex-grow overflow-y-auto">
                        {/* <CommentThread comments={saved_comments} /> */}
                    </div>

                    <div className="flex bg-gray-50">
                        <InputGroup
                            id="comment"
                            label=""
                            placeholder=""
                            setValue={(v) => setComment(v)}
                            type={fieldTypes.TEXT_INPUT}
                            value={comment}
                            area={true}
                        />
                        <div
                            onClick={addComment}
                            className="px-3 py-2 bg-primary-500 cursor-pointer hover:bg-primary-700 text-sm text-white font-title"
                        >
                            ADD
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const AttachedDocument: React.FC<any> = (document) => {
    const [isOpen, setIsOpen] = useState(false);
    const [viewDocument, setViewDocument] = useState<any>(null);

    return (
        <>
            <div
                className={
                    'w-full flex items-center justify-center border-t cursor-pointer px-2 py-2 bg-gray-100'
                }
            >
                {document.description && <div className="mr-2 text-sm">{document.description}</div>}
                <div
                    onClick={() => setViewDocument(document)}
                    className="flex-grow text-sm hover:underline text-primary-500"
                >
                    <div>{document.document_name}</div>
                </div>

                <div className="mr-2 text-sm text-gray-600">{date(document.last_updated_date)}</div>

                {document.request_fullfill ? (
                    <RequestActionsCell small original={document} />
                ) : (
                    <DocumentActionsCell small original={document} />
                )}
            </div>

            <Modal isOpen={!!viewDocument} close={() => setViewDocument(null)}>
                <FilePreview
                    type={viewDocument?.document_type}
                    url={viewDocument?.document_url}
                    closePreview={() => setViewDocument(null)}
                />
            </Modal>
        </>
    );
};

const EvalSection: React.FC<{
    currentPage: PageInterface;
    isLoading: boolean;
    openCopyBusiness: () => void;
}> = ({ currentPage, isLoading, openCopyBusiness }) => {
    const { irs_account_id } = useParams();
    const [completeFlag, setCompleteFlag] = useState<boolean>(false);
    const [seeAll, setSeeAll] = useState<boolean>(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [requestModal, setRequestModal] = useState<boolean>(false);
    const [copyMode, setCopyMode] = useState<boolean>(false);
    const [copyMessage, setCopyMessage] = useState<boolean>(false);
    const { years } = useFormsList({ irs_account_id: irs_account_id! });
    const { setPageStateValue, meta } = useTaxPrepEvalData();
    // const { mutate: copy, isLoading: copyLoading, isSuccess, reset } = copyData;
    const { year: currentYear } = useTaxPrepStructure();
    const [copyYear, setCopyYear] = useState<string>();

    const {
        confirm: copySection,
        isSuccess,
        reset,
        isLoading: copyLoading,
    } = useAdminCopySectionData(copyYear);

    useEffect(() => {
        let messageTimer: NodeJS.Timeout;

        if (isSuccess) {
            setCopyMessage(true);
            setCopyMode(false);

            messageTimer = setTimeout(() => {
                setCopyMessage(false);
                setCopyYear(undefined);
                reset();
            }, 3 * 1000);
        }

        return () => {
            if (messageTimer) {
                clearTimeout(messageTimer);
            }
        };
    }, [isSuccess]);

    async function upsertCompleteStatus() {
        setCompleteFlag(!completeFlag);

        if (currentPage) {
            setPageStateValue({ path: currentPage.address, value: !completeFlag });
        }
    }
    const currentPageAddress = currentPage?.address;

    if (currentPage.address === 'business_home') return <></>;
    console.log('currentPage', currentPage);
    return (
        <div
            id={currentPage.address}
            key={currentPage.address + currentYear + irs_account_id}
            className={classNames('flex flex-col w-full mt-12 bg-white rounded-md relative', {
                'mb-10': currentPage.isLastPage,
            })}
            style={{ minWidth: 550 }}
        >
            <div className="w-full flex items-center border-b-2 border-primary-500 px-2">
                <div className="cursor-pointer flex items-center" onClick={upsertCompleteStatus}>
                    {meta?.page_json?.[currentPageAddress!] === true ? (
                        <span
                            className="material-icons bg-primary-500 rounded-full text-white"
                            style={{ fontSize: 20 }}
                        >
                            check
                        </span>
                    ) : (
                        <div
                            className={
                                'h-5 w-5 bg-gray-50 rounded-3xl hover:bg-gray-100  border border-gray-300 p-px'
                            }
                        />
                    )}
                </div>
                <div className="font-title  p-2 flex-grow">
                    {currentPage.businessId
                        ? `${currentPage.name} | ${currentPage.businessName}`
                        : currentPage.name}
                </div>
                {/* {build.section_locking && (
                    <span
                        data-tip={isSectionLocked ? 'Unlock Section' : 'Lock Section'}
                        onClick={lockSection}
                        className={classNames('cursor-pointer material-symbols-outlined mr-2', {
                            'animate-spin': isSectionLoading,
                            'text-gray-500 hover:text-gray-800': !isSectionLocked,
                            'text-primary-500 hover:text-primary-800': isSectionLocked,
                        })}
                    >
                        {isSectionLoading ? 'refresh' : isSectionLocked ? 'lock' : `lock_open`}
                    </span>
                )} */}

                {/* <span
                            data-tip="Create Upload Request"
                            onClick={() => setRequestModal(true)}
                            className="cursor-pointer material-symbols-outlined text-gray-500 hover:text-gray-800 mr-2"
                        >
                            add_circle
                        </span>
                        <span
                            data-tip="Upload File"
                            onClick={() => setUploadModal(true)}
                            className="cursor-pointer material-symbols-outlined text-gray-500 hover:text-gray-800 mr-2"
                        >
                            upload_file
                        </span> */}
                {(currentPage.sectionId !== enumSections.BUSINESS_INFORMATION ||
                    currentPage.address === 'business_data') && (
                    <span
                        data-tip="Copy data"
                        onClick={() =>
                            currentPage.address === 'business_data'
                                ? openCopyBusiness()
                                : setCopyMode(!copyMode)
                        }
                        className="cursor-pointer material-symbols-outlined text-gray-500 hover:text-gray-800 mr-2"
                    >
                        file_copy
                    </span>
                )}
                {/* <div
                            onClick={() => setSeeAll(!seeAll)}
                            className="cursor-pointer  flex justify-center relative"
                        >
                            <span
                                data-tip="View Attached File"
                                className={classNames(
                                    'material-symbols-outlined text-gray-500 hover:text-gray-800 mr-2',
                                    {
                                        'text-primary-500 hover:text-primary-800': seeAll,
                                        'text-gray-500 hover:text-gray-800': !seeAll,
                                    },
                                )}
                            >
                                attach_file
                            </span> 
                        {!!attachedDocuments?.length && (
                                <div
                                    className={classNames(
                                        'absolute w-4 text-center font-bold text-white bg-primary-500 rounded-full text-xs font-body top-3 left-2',
                                    )}
                                >
                                    {attachedDocuments.length}
                                </div>
                            )} 
                         </div> */}

                {/* <Comments currentPage={currentPage} business={business} /> */}
            </div>

            {copyMessage && (
                <div className="flex justify-center w-full border-b py-2 bg-primary-500 border-gray-300 p-1">
                    <div className="text-white text-xs">{`Data successfully copied from ${copyYear}!`}</div>
                </div>
            )}

            {copyMode && (
                <div className="flex flex-col w-full border-b py-2 bg-gray-300 border-gray-300  p-1">
                    <Dropdown
                        className="mt-0"
                        type={fieldTypes.DROPDOWN}
                        label="Select a year to copy data from"
                        id="copyYear"
                        placeholder="Year"
                        setValue={(value) => setCopyYear(value)}
                        value={copyYear}
                        options={(years || [])
                            .filter((y: string) => y !== currentYear)
                            .map((y: string) => ({ value: y, label: y }))}
                    />

                    {copyLoading ? (
                        <div className="w-full flex flex-grow justify-end px-3">
                            <span
                                className="material-icons animate-spin w-5 h-5 flex items-center justify-center"
                                style={{ fontSize: 18 }}
                            >
                                refresh
                            </span>
                            <div
                                onClick={() => {
                                    setCopyYear(undefined);
                                    setCopyMode(false);
                                }}
                                className="m-0.5 cursor-pointer hover:text-gray-700 text-sm text-gray-500 font-semibold"
                            >
                                Copying data...
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex flex-grow justify-end px-3">
                            <div
                                onClick={() => {
                                    console.log(
                                        'CPY CMD',
                                        { copyYear },
                                        { sectionAddress: currentPage.address },
                                    );
                                    copyYear &&
                                        copySection({ sectionAddress: currentPage.address });
                                }}
                                className={classNames(
                                    'm-0.5 cursor-pointer mr-2 text-sm font-semibold',
                                    {
                                        'text-primary-500 hover:text-primary-700': !!copyYear,
                                        'text-gray-500 hover:text-gray-400': !copyYear,
                                    },
                                )}
                            >
                                Confirm
                            </div>
                            <div
                                onClick={() => {
                                    setCopyYear(undefined);
                                    setCopyMode(false);
                                }}
                                className="m-0.5 cursor-pointer hover:text-gray-700 text-sm text-gray-500 font-semibold"
                            >
                                Cancel
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* {isDocumentEnabled &&
                seeAll &&
                attachedDocuments
                    ?.filter((_: any, index: number) => {
                        return seeAll ? true : index < 2;
                    })
                    .map((item: any) => <AttachedDocument {...item} />)} */}

            {isLoading ? (
                <span className="flex font-title flex-grow items-center justify-center py-6 ">
                    <span
                        className="material-icons animate-spin h-5 w-5 flex items-center justify-center"
                        style={{ fontSize: 20 }}
                    >
                        refresh
                    </span>
                    <div className="ml-3">Loading</div>
                </span>
            ) : (
                <MainSection currentPage={currentPage} />
            )}

            <div className="p-2" />
        </div>
    );
};

export default EvalSection;
