import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Define the type for each tab
type Tab = {
    name: string;
    path: string;
    hasDropdown?: boolean; // Add hasDropdown flag
    icon: string;
};

// ClientDashboardMenu component
interface ClientDashboardMenuProps {
    expand: boolean;
    setExpand: React.Dispatch<React.SetStateAction<boolean>>;
    data: any;
}

const ClientDashboardMenu: React.FC<ClientDashboardMenuProps> = ({ expand, setExpand, data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [type, setType] = useState<any>('');
    const [b_id, setB_id] = useState<any>();
    const { irs_account_id } = useParams();
    console.log('data tax', data);
    const Tabs: Tab[] = [
        {
            name: t('Profile'),
            icon: 'person',
            path: `/client/${irs_account_id}/profile/`,
        },
        {
            name: t('Transcripts'),
            icon: 'widgets',
            path: `/client/${irs_account_id}/transcripts/${type}`,
            hasDropdown: true,
        },
        {
            name: t('Documents'),
            icon: 'description',
            path: `/client/${irs_account_id}/documents/`,
        },
        {
            name: t('Forms'),
            icon: 'assignment',
            path: `/client/${irs_account_id}/forms/`,
        },
        {
            name: t('Billing'),
            icon: 'receipt_long',
            path: `/client/${irs_account_id}/billings/`,
        },
        {
            name: t('Tasks'),
            icon: 'task',
            path: `/client/${irs_account_id}/tasks/`,
        },
    ];
    const handleTab = (tab: Tab, type?: string, b_id?: string) => {
        // If a specific type is provided, navigate to the transcripts with that type,
        // otherwise, navigate to the default path of the tab.
        navigate(type ? `/client/${irs_account_id}/transcripts/${type}` : tab.path);
    };

    const location = useLocation();
    const getActiveTab = (tab: Tab) => {
        return location.pathname.includes(tab.path);
    };

    return (
        <div className="flex relative bg-white">
            <div
                onMouseEnter={() => setExpand(true)}
                className={classNames('flex flex-col py-3 border-r transition-all', {
                    'w-14': !expand,
                    'w-52': expand,
                })}
            >
                {Tabs.map((tab: Tab, index: number) => {
                    const isSelected = getActiveTab(tab);
                    return (
                        <div
                            key={index}
                            className={classNames(
                                'flex pl-4 pr-3 py-3 w-14 cursor-pointer transition-all',
                                {
                                    'bg-gray-200': isSelected,
                                    'hover:bg-gray-200': !isSelected && expand,
                                },
                            )}
                            onClick={() => handleTab(tab)}
                        >
                            <span
                                className={classNames('material-symbols-outlined  mr-3', {
                                    'text-primary-500': isSelected,
                                    'text-gray-500': !isSelected,
                                })}
                            >
                                {tab.icon}
                            </span>
                        </div>
                    );
                })}
            </div>
            {expand && (
                <div
                    onMouseLeave={() => setExpand(false)}
                    className={classNames(
                        'absolute flex flex-col py-3 border-r bg-white w-52 z-10 h-full transition-all',
                        { 'w-0': !expand },
                    )}
                >
                    {Tabs.map((tab: Tab, index: number) => {
                        const isSelected = getActiveTab(tab);

                        return tab.hasDropdown ? (
                            <div
                                key={index}
                                className={classNames(
                                    'flex flex-col group transition-all cursor-pointer',
                                )}
                            >
                                <div
                                    onClick={() => {
                                        // Handle form selection
                                    }}
                                    className={classNames(
                                        'w-full flex justify-between items-center  pl-4 pr-3 py-3 group-hover:bg-gray-200  hover:bg-gray-100  px-3',
                                        {
                                            'bg-gray-300': isSelected,
                                            'hover:bg-gray-200': !isSelected,
                                        },
                                    )}
                                >
                                    <span className="flex items-center text-gray-600">
                                        <span
                                            className={classNames(
                                                'material-symbols-outlined  mr-3',
                                                {
                                                    'text-primary-500': isSelected,
                                                    'text-gray-500': !isSelected,
                                                },
                                            )}
                                        >
                                            {tab.icon}
                                        </span>
                                        {tab.name}
                                    </span>
                                    <span
                                        style={{ fontSize: '22px' }}
                                        className="material-symbols-outlined border-none text-md text-gray-500 transform group-hover:rotate-180"
                                    >
                                        expand_more
                                    </span>
                                </div>

                                <li className="hidden group-hover:block bg-gray-50">
                                    <ul>
                                        <li
                                            onClick={() => handleTab(tab, 'Individual')}
                                            className="text-left text-gray-600 pl-8 text-xs font-semibold w-full  hover:bg-gray-200 py-2 block whitespace-no-wrap"
                                        >
                                            {data?.primaryClient}
                                        </li>
                                        <li
                                            onClick={() => handleTab(tab, 'Spouse')}
                                            className="text-left text-gray-600 pl-8 text-xs font-semibold w-full  hover:bg-gray-200 py-2 block whitespace-no-wrap"
                                        >
                                            {data?.spouse}
                                        </li>
                                        {data?.business.length > 0 && data?.business && (
                                            <li
                                                className="relative  mx-3"
                                                style={{ marginTop: '-11px' }}
                                            >
                                                <div className="inline-flex items-center w-full">
                                                    <hr className="flex-grow h-px bg-gray-200 border-0 dark:bg-gray-700" />
                                                    <span
                                                        style={{ fontSize: '11px' }}
                                                        className="absolute left-1/2 transform -translate-x-1/2 px-2 bg-gray-50  font-medium text-gray-400 dark:text-white dark:bg-gray-900"
                                                    >
                                                        businesses
                                                    </span>
                                                </div>
                                            </li>
                                        )}
                                        {data?.business?.map((business: any, index: any) => (
                                            <li
                                                onClick={() => handleTab(tab, 'Business', 'id')}
                                                key={index}
                                                className="text-left text-gray-600 pl-8 text-xs font-semibold w-full  hover:bg-gray-200 py-2 block whitespace-no-wrap"
                                            >
                                                {business?.name}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </div>
                        ) : (
                            <div
                                key={index}
                                className={classNames(
                                    'flex pl-4 pr-3 py-3 cursor-pointer transition-all',
                                    {
                                        'bg-gray-200': isSelected,
                                        'hover:bg-gray-200': !isSelected,
                                    },
                                )}
                                onClick={() => handleTab(tab)}
                            >
                                <span
                                    className={classNames('material-symbols-outlined  mr-3', {
                                        'text-primary-500': isSelected,
                                        'text-gray-500': !isSelected,
                                    })}
                                >
                                    {tab.icon}
                                </span>
                                <div className="text-gray-600">{tab.name}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ClientDashboardMenu;
