import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface TaxData {}
interface TitleItem {
    subtitle: string;
    value?: any;
    color?: string;
}

export const Card1: React.FC<{
    mainTitle: string;
    titles: string[];
    total: string;
    years: string[];
    missingInfo?: string;
    color: string;
    setTranscriptTab: (tab: number) => void;
    transcriptTab: number;
}> = ({ mainTitle, titles, total, years, missingInfo, color, setTranscriptTab, transcriptTab }) => {
    const { t } = useTranslation();
    const formatYears = (years: any) => {
        if (!years || years.length === 0) {
            return '';
        }
        if (years.length === 1) {
            return years[0].toString();
        }
        return years.slice(0, -1).join(', ') + ', ' + years.slice(-1);
    };
    const formattedYears = formatYears(years);
    return (
        <div style={{ minHeight: '150px' }} className="border rounded-md overflow-hidden">
            <div
                style={{
                    borderImage:
                        'linear-gradient(180deg, rgba(6,127,123,1) 0%, rgba(11,229,222,1) 100%)',
                    borderImageSlice: 1,
                }}
                className="border-gray-500 h-full rounded-md border-l-4 p-2 pr-4 flex flex-col  justify-between"
            >
                <div className="flex items-center justify-between">
                    <span style={{ fontSize: '15px' }} className="font-semibold text-sm ">
                        {t(mainTitle)}
                    </span>
                    <span
                        style={{ fontSize: '13px' }}
                        className=" underline text-cursor-pointer text-custom-green-300 font-medium"
                        onClick={() => setTranscriptTab(6)}
                    >
                        {t('View Details')}
                    </span>
                </div>
                {missingInfo ? (
                    <span
                        style={{ color }}
                        className="text-sm font-medium text-center w-full block"
                    >
                        {t(missingInfo)}
                    </span>
                ) : (
                    <>
                        <div className="flex flex-col gap-2">
                            <div className="flex justify-between ">
                                <span
                                    style={{ fontSize: '13px' }}
                                    className="text-xs font-medium text-custom-green-300"
                                >
                                    {' '}
                                    {t(titles[0])}
                                </span>
                                <span
                                    style={{ fontSize: '13px', color: color }}
                                    className="text-xs  font-medium"
                                >
                                    {total ? `$${total}` : ''}
                                </span>
                            </div>
                            <div className="w-full flex justify-center">
                                <span className="h-2 w-2/3 border-dashed border-b-2 "></span>
                            </div>
                            <div className="flex justify-between ">
                                <span
                                    style={{ fontSize: '13px' }}
                                    className="text-xs font-medium text-custom-green-300"
                                >
                                    {' '}
                                    {t(titles[1])}
                                </span>
                                <span
                                    style={{ fontSize: '13px' }}
                                    className="text-xs font-medium flex gap-1"
                                >
                                    <span
                                        // key={index}
                                        className="flex items-center justify-center  rounded-full text-custom-green-500 "
                                    >
                                        {formattedYears}
                                        {/* 2017,2018 */}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export const Card2: React.FC<{
    mainTitle: string;
    titles?: TitleItem[];
    data?: any;
    setTranscriptTab: (tab: number) => void;
    transcriptTab: number;
}> = ({ setTranscriptTab, transcriptTab, mainTitle, titles, data }) => {
    // console.log('titles[0].value', titles[0].value);
    const { t } = useTranslation();

    const formatDates = (dates: string[], color: string | undefined) => {
        try {
            if (dates.length <= 2) {
                return <span style={{ color }}>{dates.join(', ')}</span>;
            } else {
                const [firstDate, secondDate, ...rest] = dates;
                return (
                    <>
                        <span style={{ color }}>
                            {firstDate}, {secondDate},{' '}
                        </span>
                        <span className="text-custom-green-300 font-medium">+{rest.length}</span>
                    </>
                );
            }
        } catch (error) {
            console.error('Error in formatDates:', error);
            return <span>Error formatting dates</span>;
        }
    };
    return (
        <div style={{ minHeight: '150px' }} className="border rounded-md overflow-hidden">
            <div
                style={{
                    borderImage:
                        'linear-gradient(180deg, rgba(6,127,123,1) 0%, rgba(11,229,222,1) 100%)',

                    borderImageSlice: 1,
                }}
                className="border-gray-500 h-full rounded-md border-l-4 p-2 pr-4 flex flex-col  justify-between"
            >
                <div className="flex items-center justify-between">
                    <span style={{ fontSize: '15px' }} className="font-semibold text-sm ">
                        {t(mainTitle)}
                    </span>
                    <span
                        style={{ fontSize: '13px' }}
                        className="text-xs underline cursor-pointer font-medium text-custom-green-300"
                        onClick={() => setTranscriptTab(6)}
                    >
                        {t('View Details')}
                    </span>
                </div>
                <div className="">
                    {data ? (
                        data.missing_information ? (
                            <span
                                style={{ color: data?.color }}
                                className="text-sm text-custom-green-300 font-medium text-center w-full block"
                            >
                                {t(data.missing_information)}
                            </span>
                        ) : (
                            Object.entries(data).map(([key, item]: [string, any]) => (
                                <div className="flex justify-between items-end px-2" key={key}>
                                    <span
                                        style={{ maxWidth: '165px' }}
                                        className="text-sm font-medium text-custom-green-300"
                                    >
                                        {t(item.label)}
                                    </span>
                                    <span className="text-sm text-custom-red-600 font-medium">
                                        {Array.isArray(item.value)
                                            ? formatDates(item.value, item.color)
                                            : item.value}
                                    </span>
                                </div>
                            ))
                        )
                    ) : (
                        <div className="text-sm font-medium text-center w-full text-custom-green-300">
                            {t('Missing Information!')}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export const Card3: React.FC<{
    setTranscriptTab: (tab: number) => void;
    transcriptTab: number;
    mainTitle: string;
    data?: any;
    titles?: TitleItem[];
}> = ({ mainTitle, titles, data, setTranscriptTab, transcriptTab }) => {
    const { t } = useTranslation();
    let dateArr: any = [];

    function groupYears(years: any) {
        const result = [];
        let start = null;
        let end = null;
        let yearArr = years.map(Number);
        yearArr.sort((a: any, b: any) => a - b); // Sort the array in ascending order

        for (let i = 0; i < years.length; i++) {
            if (start === null) {
                start = yearArr[i];
                end = yearArr[i];
            } else if (yearArr[i] === end + 1) {
                end = yearArr[i];
            } else {
                result.push(formatRange(start, end));
                start = yearArr[i];
                end = yearArr[i];
            }
        }

        // Handle the last range
        result.push(formatRange(start, end));

        return result;
    }

    function formatRange(start: any, end: any) {
        if (start === end) {
            return start.toString();
        } else {
            return `${start}-${end}`;
        }
    }

    const formatDates = (dates: string[], color: string | undefined) => {
        dateArr = groupYears(dates);
        console.log(dateArr, 'dateArr');

        try {
            if (dateArr.length <= 1) {
                return <span style={{ color }}>{dateArr.join(', ')}</span>;
            } else {
                const [firstDate, ...rest] = dateArr;
                return (
                    <>
                        <span style={{ color }}>{firstDate}, </span>
                        <span className="text-custom-green-300 font-medium">+{rest.length}</span>
                    </>
                );
            }
        } catch (error) {
            console.error('Error in formatDates:', error);
            return <span>Error formatting dates</span>;
        }
    };
    return (
        <div style={{ minHeight: '150px' }} className="border flex rounded-md  text">
            <div
                style={{
                    background:
                        'linear-gradient(180deg, rgba(6,127,123,1) 0%, rgba(11,229,222,1) 100%)',
                }}
                className="h-full w-1 rounded-l-md"
            ></div>
            <div
                // style={{
                //     borderImage:
                //         'linear-gradient(180deg, rgba(6,127,123,1) 0%, rgba(11,229,222,1) 100%)',

                //     borderImageSlice: 1,
                // }}
                className=" h-full w-full   p-2 pr-4 flex flex-col justify-between "
            >
                <div className="flex items-center justify-between">
                    <span style={{ fontSize: '15px' }} className="font-semibold text-sm ">
                        {t(mainTitle)}
                    </span>
                    <span
                        style={{ fontSize: '13px' }}
                        className="text-xs underline cursor-pointer font-medium text-custom-green-300"
                        onClick={() => setTranscriptTab(6)}
                    >
                        {t('View Details')}
                    </span>
                </div>
                <div className="flex flex-col gap-1">
                    {data ? (
                        data.missing_information ? (
                            <span
                                style={{ color: data?.color }}
                                className="text-sm text-custom-green-300 font-medium text-center w-full block"
                            >
                                {t(data.missing_information)}
                            </span>
                        ) : (
                            Object.entries(data).map(([key, item]: [string, any]) => (
                                <div className="flex justify-between" key={key}>
                                    <span
                                        style={{ fontSize: '13px' }}
                                        className="text-xs font-medium text-custom-green-300"
                                    >
                                        {t(item.label)}
                                    </span>
                                    <div className="group relative flex">
                                        <span
                                            style={{ fontSize: '13px' }}
                                            className="text-xs font-medium cursor-pointer"
                                        >
                                            {item?.value?.length > 0 && Array.isArray(item.value)
                                                ? formatDates(item.value, item.color)
                                                : item.value}
                                        </span>
                                        <div
                                            style={{
                                                boxShadow: '0px 0px 30px 1px rgba(0, 0, 0, 0.10)',
                                            }}
                                            className="absolute flex flex-col gap-2.5 w-36 top-5 transform scale-0 z-50 transition-all rounded bg-white border-custom-gray-100 pb-1 pr-8 pl-3.5 pt-2 text-xs group-hover:scale-100"
                                        >
                                            <span className="text-custom-green-500 text-sm font-bold pb-2">
                                                {t('All Dates')}
                                            </span>
                                            {dateArr?.map((value: string, index: number) => (
                                                <span
                                                    className="flex text-sm font-medium pb-1"
                                                    key={index}
                                                >
                                                    {value}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                    ) : (
                        <div className="text-sm font-medium text-center w-full text-custom-green-300">
                            {t('Missing Information!')}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const Card4: React.FC<{
    data: any;
    mainTitle: string;
    setTranscriptTab: (tab: number) => void;
    transcriptTab: number;
}> = ({ mainTitle, setTranscriptTab, transcriptTab, data }) => {
    const { t } = useTranslation();

    return (
        <div style={{ minHeight: '150px' }} className="border rounded-md overflow-hidden">
            <div
                style={{
                    borderImage:
                        'linear-gradient(180deg, rgba(6,127,123,1) 0%, rgba(11,229,222,1) 100%)',

                    borderImageSlice: 1,
                }}
                className="border-gray-500 h-full rounded-md border-l-4 p-2 pr-4 flex flex-col gap-4 justify-between"
            >
                <div className="flex items-start justify-between">
                    <span
                        style={{ fontSize: '15px' }}
                        className="font-semibold text-sm flex flex-wrap w-48"
                    >
                        {mainTitle}
                    </span>
                    <span
                        style={{ fontSize: '13px' }}
                        className="text-xs underline cursor-pointer font-medium text-custom-green-300"
                        onClick={() => setTranscriptTab(6)}
                    >
                        {t('View Details')}
                    </span>
                </div>
                {!data?.missing_information ? (
                    // <div className="flex gap-9 ">
                    //     <div className="flex flex-col gap-2 pl-6">
                    //         <div className="flex flex-col  ">
                    //             <span
                    //                 style={{ fontSize: '13px' }}
                    //                 className="text-xs text-custom-green-300 font-medium"
                    //             >
                    //                 08 July 2024
                    //             </span>
                    //             <span style={{ fontSize: '13px' }} className="text-xs font-medium">
                    //                 $21,000
                    //             </span>
                    //         </div>
                    //         <div className="flex flex-col  ">
                    //             <span
                    //                 style={{ fontSize: '13px' }}
                    //                 className="text-xs text-custom-green-300 font-medium"
                    //             >
                    //                 08 July 2024
                    //             </span>
                    //             <span style={{ fontSize: '13px' }} className="text-xs font-medium">
                    //                 $21,000
                    //             </span>
                    //         </div>
                    //     </div>

                    //     <div className="flex flex-col gap-2 border-dashed border-l-2 pl-6">
                    //         <div className="flex flex-col  ">
                    //             <span
                    //                 style={{ fontSize: '13px' }}
                    //                 className="text-xs text-custom-green-300 font-medium"
                    //             >
                    //                 08 July 2024
                    //             </span>
                    //             <span style={{ fontSize: '13px' }} className="text-xs font-medium">
                    //                 $21,000
                    //             </span>
                    //         </div>
                    //         <div className="flex flex-col  ">
                    //             <span
                    //                 style={{ fontSize: '13px' }}
                    //                 className="text-xs text-custom-green-300 font-medium"
                    //             >
                    //                 08 July 2024
                    //             </span>
                    //             <span style={{ fontSize: '13px' }} className="text-xs font-medium">
                    //                 $21,000
                    //             </span>
                    //         </div>
                    //     </div>
                    // </div>
                    <span></span>
                ) : (
                    <div
                        style={{ color: data?.color }}
                        className="text-sm font-medium text-center w-full text-custom-green-300"
                    >
                        {t(data?.missing_information)}
                    </div>
                )}
            </div>
        </div>
    );
};

export const Income: React.FC<{
    data: any;
    setTranscriptTab: (tab: number) => void;
    transcriptTab: number;
}> = ({ data, setTranscriptTab, transcriptTab }) => {
    const { t } = useTranslation();

    return (
        <div style={{ minHeight: '150px' }} className="border rounded-md overflow-hidden">
            <div
                style={{
                    borderImage:
                        'linear-gradient(180deg, rgba(6,127,123,1) 0%, rgba(11,229,222,1) 100%)',
                    borderImageSlice: 1,
                }}
                className="border-gray-500 h-full dsf rounded-md border-l-4 p-2 pr-4 flex flex-col justify-between"
            >
                <div className="flex items-center justify-between">
                    <span style={{ fontSize: '15px' }} className="font-semibold text-sm ">
                        Income
                    </span>
                    <span
                        style={{ fontSize: '13px' }}
                        className="text-xs underline cursor-pointer font-medium text-custom-green-300"
                        onClick={() => setTranscriptTab(6)}
                    >
                        {t('View Details')}
                    </span>
                </div>
                {data ? (
                    <div className="flex justify-between px-2 ">
                        {data.map((item: any, index: any) => (
                            <div className=" flex flex-col " key={index}>
                                <span className="text-sm font-medium text-custom-green-300">
                                    {item.label}
                                </span>
                                <span style={{ fontSize: '13px' }} className="font-medium ">
                                    ${item.value}
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    // <div className="text-sm font-medium text-center w-full text-custom-green-300">
                    //     {t('No Data')}
                    // </div>
                    <></>
                )}
            </div>
        </div>
    );
};
