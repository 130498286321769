import { useState } from 'react';
import { fieldTypes } from '../../utils/enums';
import { useMutation } from 'react-query';
import { ExportPasswordModal } from '../../api/admin';
import { useAuthState } from './../../context/auth';
import InputGroup from '../client/InputGroup';
import Button from './Button';
import saveAs from 'file-saver';
import * as xlsx from 'xlsx-js-style';
interface ModifiedData {
    newObj: { [key: string]: any };
    headerKeys: { [key: string]: string };
}

const getModifiedHeaders = (data: any[], request_type: string): ModifiedData[] => {
    return data.map((obj) => {
        let newObj: { [key: string]: any } = { ...obj };
        let headerKeys: { [key: string]: string } = {};
        //in case want to add more table then increase cases and add headers which have to be modified and removable will be put in the delete newObj['key]
        switch (request_type) {
            case 'Tax Preparer': {
                delete newObj['u_user_name'];
                delete newObj['u_caf_no'];
                delete newObj['u_ptin'];

                headerKeys = {
                    u_first_name: 'First Name',
                    u_last_name: 'Last Name',
                    u_email: 'Email ID',
                    u_phone_no: 'Phone Number',
                    u_fax: 'Fax Number',
                    u_designation: 'Designation',
                    u_jurisdiction: 'Jurisdiction',
                    u_bar: 'Bar',
                    user_id: 'User ID',
                };
                return { newObj, headerKeys };
            }
            case 'Client': {
                delete newObj['customer_code'];
                delete newObj['birth_dt'];
                delete newObj['tax_preparer_id'];

                headerKeys = {
                    first_name: 'First Name',
                    home_phone: 'Phone Number',
                    email_address: 'Email Address',
                    business: 'Business Name',
                    role_name: 'Role Name',
                    taxpro_user: 'Tax Pro Name',
                    address_line_1: 'Address',
                    u_last_logged_in: 'Last Logged In',
                    account_type: 'Account Type',
                    user_id: 'User ID',
                    irs_account_id: 'IRS Account ID',
                };
                return { newObj, headerKeys };
            }
            default: {
                console.error('Invalid parameters!');
                return { newObj: {}, headerKeys: {} };
            }
        }
    });
};

//modifying the data
const modifyData = (data: any[], request_type: string): any[] => {
    //get the header based on the request type
    const modifiedHeaders = getModifiedHeaders(data, request_type);

    //in case want to add more then add another case according to the request typE  and add logic
    return modifiedHeaders.map(({ newObj, headerKeys }) => {
        for (const [key, value] of Object.entries(headerKeys)) {
            switch (request_type) {
                case 'Tax Preparer':
                    if (newObj.hasOwnProperty(key)) {
                        newObj[value] = newObj[key];
                        delete newObj[key];
                    }
                    break;
                case 'Client':
                    if (key === 'first_name' && newObj.hasOwnProperty('last_business_name')) {
                        newObj['Client Name'] =
                            newObj['first_name'] + ' ' + newObj['last_business_name'];
                        delete newObj['first_name'];
                        delete newObj['last_business_name'];
                    } else if (newObj.hasOwnProperty(key)) {
                        newObj[value] = newObj[key];
                        delete newObj[key];
                    }
                    break;
                default:
                    console.error('Unknown Case!');
                    return { newObj: [] };
            }
        }

        return newObj;
    });
};

//excel conversion function
export const handleExcelDownload = (data: any[], filename: string) => {
    const wb = xlsx.utils.book_new();
    const sheet = xlsx.utils.json_to_sheet(data, { header: [] });

    const headers = Object.keys(data[0]); // Get the headers from the first object in the data array

    headers.forEach((header, columnIndex) => {
        sheet[xlsx.utils.encode_cell({ r: 0, c: columnIndex })] = {
            v: header,
            s: {
                font: { bold: true, sz: 13, color: { rgb: '374151' } }, // Increase the font size (sz) to 13 or your desired value currently data rows are set to 11 and headers 13
            },
        };
    });

    xlsx.utils.book_append_sheet(wb, sheet);
    const excelBuffer = xlsx.write(wb, {
        bookType: 'xlsx',
        type: 'array',
    });
    const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(blob, `${filename}.xlsx`);
};

//excel download modal
const ConfirmExport: React.FC<{
    close: () => void;
    request_type: 'Admin' | 'Client' | 'Tax Preparer';
}> = ({ close, request_type }) => {
    const [password, setPassword] = useState<string>('');
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const { token } = useAuthState();

    const { mutate: addMutation, isLoading } = useMutation(ExportPasswordModal, {
        onSuccess: (data: any) => {
            //in case more table added for modified headers then add more || condition
            if (request_type === 'Client' || request_type === 'Tax Preparer') {
                const modifData = modifyData(data.data, request_type);
                modifData
                    ? handleExcelDownload(modifData, `${request_type}-List`)
                    : console.error('Something went wrong while modifying the excel!');
            } else {
                handleExcelDownload(data.data, `${request_type}-List`);
            }
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const handleSubmit = () => {
        if (!token) return;
        addMutation({ password, request_type });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">Confirm Password</div>
            <div className="flex pt-6 pb-8">
                <InputGroup
                    id="password"
                    label="Password"
                    placeholder=""
                    inputType="password"
                    value={password}
                    setValue={(value) => {
                        setPassword(value);
                        setErrors({});
                    }}
                    type={fieldTypes.TEXT_INPUT}
                    className="w-full"
                    staticError={error.password}
                />
            </div>
            <div className="flex w-full">
                <Button
                    isLoading={isLoading}
                    onClick={() => handleSubmit()}
                    className="text-center flex-grow mr-2"
                >
                    {'EXPORT'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmExport;
