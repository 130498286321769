import { upsertVirtualCurrency } from '../../api/finance';
import { build } from '../../config';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const virtualCurrencyFormNew: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'name_of_virtual_currency',
        label: 'Digital Asset Type',
        placeholder: 'Bitcoin',
        options: [
            { label: 'Bitcoin', value: 'Bitcoin' },
            { label: 'Ethereum', value: 'Ethereum' },
            { label: 'Litecoin', value: 'Litecoin' },
            { label: 'Ripple', value: 'Ripple' },
            { label: 'Other', value: 'Other' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'description',
        label: 'Description',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'currency_wallet_exc',
        label: 'Digital Asset Name',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'number_of_units',
        label: 'Number of Units',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_of_virtual_currency',
        label: 'Digital Asset Location',
        placeholder: 'Metamask',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Account Number',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'email_address_of_virtual_currency',
        label: 'Digital Asset Address/Email',
        placeholder: 'johndoe@hotmail.com',
        span: 2,
    },
    { type: fieldTypes.SEPERATOR },
    // {
    //     type: fieldTypes.AMOUNT_INPUT,
    //     id: 'current_value',
    //     label: 'Digital Asset Amount',
    //     placeholder: '0.4',
    // },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'virtual_currency_in_usd',
        label: 'Digital Asset in USD',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '$33,000',
        formatFunction: amount,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'who_has_access_to_private_key',
        label: 'Name of individuals who have access to digital wallets',
        placeholder: 'Names...',
        span: 2,
    },
];
const virtualCurrencyFormOld: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'name_of_virtual_currency',
        label: 'Digital Asset Type',
        placeholder: 'Bitcoin',
        options: [
            { label: 'Bitcoin', value: 'Bitcoin' },
            { label: 'Ethereum', value: 'Ethereum' },
            { label: 'Litecoin', value: 'Litecoin' },
            { label: 'Ripple', value: 'Ripple' },
            { label: 'Other', value: 'Other' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'currency_wallet_exc',
        label: 'Digital Asset Name',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'email_address_of_virtual_currency',
        label: 'Email',
        placeholder: 'johndoe@hotmail.com',
        span: 2,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_of_virtual_currency',
        label: 'Digital Asset Location',
        placeholder: 'Metamask',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Digital Asset Amount',
        placeholder: '0.4',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'virtual_currency_in_usd',
        label: 'Digital Asset in USD',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '$33,000',
        formatFunction: amount,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'who_has_access_to_private_key',
        label: 'Name of individuals who have access to digital wallets',
        placeholder: 'Names...',
        span: 2,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'virtual_currency_data',
        label: 'Do you have any digital assets?',
        formStructure: build.virtual_currency_change
            ? virtualCurrencyFormNew
            : virtualCurrencyFormOld,
        displayColumns: [
            {
                name: 'Currency Name',
                key: 'name_of_virtual_currency',
            },
            {
                name: 'Currency Amount',
                key: 'current_value',
            },
            {
                name: 'Value in USD',
                key: 'virtual_currency_in_usd',
            },
        ],
        header: 'Virtual Currency Details',
    },
];

export const page: PageInputInterface = {
    address: 'virtual_currency',
    name: 'Virtual Currency',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertVirtualCurrency,
};
