import { formatDate } from './userBilling/UserBilling';

//-------------------- userTaxometrics --------------------------------
export const TaxometricColumns: Array<any> = [
    {
        Header: 'Tax Year',
        accessor: 'taxYear',
        Cell: ({ value }: any) => {
            return (
                <div className="" style={{ minWidth: '90px' }}>
                    No Data
                </div>
            );
        },
    },
    {
        Header: 'Return Filed',
        accessor: 'returnFiled',
    },
    {
        Header: 'Tax Assessed',
        accessor: 'taxAssessed',
    },
    {
        Header: 'Penalties Assessed',
        accessor: 'penaltiesAssessed',
    },
    {
        Header: 'Interest Assessed',
        accessor: 'interestAssessed',
    },
    {
        Header: 'Other Assessed Amounts',
        accessor: 'otherAssessedAmounts',
    },
    {
        Header: 'Payments',
        accessor: 'payments',
    },
    {
        Header: 'Refunds',
        accessor: 'refunds',
    },
    {
        Header: 'Accured Interest',
        accessor: 'accuredInterest',
    },
    {
        Header: 'Total IRS Balance',
        accessor: 'totalIRSBalance',
    },
    {
        Header: 'Balance From Transcripts',
        accessor: 'balanceFromTranscripts',
    },
];
//-------------------- userTaxometrics --------------------------------

// ---------------------userCompliance--------------------------------------------
export const ComplianceColumns: Array<any> = [
    {
        Header: () => {
            return <div style={{ minWidth: '87px' }}> Tax Year</div>;
        },
        accessor: 'year',
        Cell: ({ value }: any) => {
            return (
                <div className="flex items-center gap-2 py-1">
                    <input type="checkbox" />
                    {value}
                </div>
            );
        },
    },
    {
        Header: 'Return Filed',
        accessor: 'returnFiled',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '100px' }}>Filing Status</div>;
        },
        accessor: 'filing_status',
    },
    {
        Header: () => {
            return <div className="pr-11">Lien Active</div>;
        },
        accessor: 'intent_to_levy_date',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '118px' }}>Final Notice of Intent to Levy</div>;
        },
        accessor: 'interestAssessed',
    },
    {
        Header: 'Collections Active',
        accessor: 'otherAssessedAmounts',
    },
    {
        Header: 'Examination/Audit Active',
        accessor: 'audit_exam_start_date',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '140px' }}>Passport Certified for Revocation</div>;
        },
        accessor: 'passport_revocation',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '138px' }}>Assigned to Private Collection Agency</div>;
        },
        accessor: 'private_collection',
    },
    {
        Header: 'Assessed Balance',
        accessor: 'assessed_balance.value',
        Cell: ({ value, row }: any) => {
            return <div style={{ color: row.original.assessed_balance.color }}>${value}</div>;
        },
    },
    {
        Header: 'Accrued Balance',
        accessor: 'accrual_balance.value',
        Cell: ({ value, row }: any) => {
            return <div style={{ color: row.original.accrual_balance.color }}>${value}</div>;
        },
    },
    {
        Header: () => {
            return <div className="pr-8">Transcript Date</div>;
        },
        accessor: 'transcript_date',
    },
];
export const LiabilityColumns: Array<any> = [
    {
        Header: () => {
            return <div style={{ minWidth: '87px' }}> Tax Year</div>;
        },
        accessor: 'year',
        Cell: ({ value }: any) => {
            return (
                <div className="flex items-center gap-2">
                    <input type="checkbox" />
                    {value}
                </div>
            );
        },
    },
    {
        Header: 'Return Filed',
        accessor: 'returnFiled',
    },
    {
        Header: 'Tax Accessed',
        accessor: 'filing_status',
    },
    {
        Header: 'Penalities Assessed',
        accessor: 'PenalitiesAssessed',
    },
    {
        Header: 'Interest Assessed',
        accessor: 'InterestAssessed',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '100px' }}>Other Assessed Amounts</div>;
        },
        accessor: 'OtherAssessedAmounts',
    },
    {
        Header: 'Payments',
        accessor: 'Payments',
        Cell: ({ value }: any) => {
            return <div className="text-red-600">{value}</div>;
        },
    },
    {
        Header: 'Refunds',
        accessor: 'audit_exam_start_date',
    },
    {
        Header: 'Accrued Penalities',
        accessor: 'accrual_penalities',
    },

    {
        Header: 'Accrued Interest',
        accessor: 'accrual_interest',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '100px' }}>Total IRS Balance Date</div>;
        },
        accessor: 'TotalIRSBalanceDate',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '100px' }}> Balance From Transcripts</div>;
        },
        accessor: 'BalanceFromTranscripts',
    },
];

// ---------------------userCompliance--------------------------------------------

//-------------------- UserTransaction --------------------------------

export const PaymentMetricsColumns: Array<any> = [
    {
        Header: () => {
            return <div className="pl-4">Tax Year</div>;
        },
        accessor: 'year',
        Cell: ({ value }: any) => {
            return (
                <div className="" style={{ minWidth: '90px' }}>
                    No Data
                </div>
            );
        },
    },
    {
        Header: () => {
            return <div className="pl-12">Payment Code</div>;
        },
        accessor: 'paymentcode',
    },
    {
        Header: () => {
            return (
                <div style={{ width: '159px' }} className="pl-9 ">
                    Type Of Payment or Event
                </div>
            );
        },
        accessor: 'taxAssessed',
    },
    {
        Header: () => {
            return <div className="pl-20">Payment Date</div>;
        },
        accessor: 'penaltiesAssessed',
    },
    {
        Header: () => {
            return <div className="pl-12">Payment Amount</div>;
        },
        accessor: 'interestAssessed',
    },
];
export const TransactionMetricsColumns: Array<any> = [
    {
        Header: () => {
            return <div className=" py-2.5">Period</div>;
        },
        accessor: 'period',
        Cell: ({ value }: any) => {
            return (
                <div className="flex items-center gap-2 py-1" style={{ minWidth: '90px' }}>
                    {value}
                </div>
            );
        },
    },
    {
        Header: 'Code',
        accessor: 'transaction_code',
    },
    {
        Header: 'Description',
        accessor: 'transaction_description',
    },
    {
        Header: 'Date',
        accessor: 'transaction_date',
    },
    {
        Header: 'Amount',
        accessor: 'transaction_amount',
        Cell: ({ value }: any) => {
            return (
                <div style={{ color: value.transaction_color }} className="flex items-center gap-2">
                    ${value.transaction_value}
                </div>
            );
        },
    },
];
//-------------------- UserTransaction --------------------------------

//-------------------- UserIncome --------------------------------

export const IncomeColumns: Array<any> = [
    {
        Header: () => {
            return <div className=" py-2.5">Income Type/Items Paid</div>;
        },
        accessor: 'taxYear',
        Cell: ({ value }: any) => {
            return <div className="pl-4">No Data</div>;
        },
    },
    {
        Header: '2012',
        accessor: 'returnFiled',
    },
    {
        Header: '2013',
        accessor: 'returnFiled2013',
    },
    {
        Header: '2014',
        accessor: 'returnFiled2014',
    },
    {
        Header: '2015',
        accessor: 'returnFiled2015',
    },
    {
        Header: '2016',
        accessor: 'returnFiled2016',
    },
    {
        Header: '2017',
        accessor: 'returnFiled2017',
    },
    {
        Header: '2018',
        accessor: 'returnFiled2018',
    },
    {
        Header: 'Total',
        accessor: 'balanceFromTranscripts',
    },
];
//-------------------- UserIncome --------------------------------

//-------------------- UserAllTranscripts --------------------------------

export const allTranscriptColumns: Array<any> = [
    {
        Header: () => {
            return <div className=" py-2.5">Tax Year</div>;
        },
        accessor: 'taxYear',
        Cell: ({ value }: any) => {
            return (
                <div className="" style={{ minWidth: '90px' }}>
                    No Data
                </div>
            );
        },
    },
    {
        Header: 'Return Filed',
        accessor: 'returnFiled',
    },
    {
        Header: 'Tax Assessed',
        accessor: 'taxAssessed',
    },
    {
        Header: 'Penalties Assessed',
        accessor: 'penaltiesAssessed',
    },
    {
        Header: 'Interest Assessed',
        accessor: 'interestAssessed',
    },
    {
        Header: 'Other Assessed Amounts',
        accessor: 'otherAssessedAmounts',
    },
    {
        Header: 'Payments',
        accessor: 'payments',
    },
    {
        Header: 'Refunds',
        accessor: 'refunds',
    },
    {
        Header: 'Accured Interest',
        accessor: 'accuredInterest',
    },
    {
        Header: 'Total IRS Balance',
        accessor: 'totalIRSBalance',
    },
    {
        Header: 'Balance From Transcripts',
        accessor: 'balanceFromTranscripts',
    },
];
//-------------------- UserAllTranscripts --------------------------------

//-------------------- UserBillings --------------------------------

export const serviceStandardColumns: Array<any> = [
    {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ value }: any) => {
            return (
                <div className="flex gap-2" style={{ minWidth: '90px' }}>
                    <span className="material-symbols-outlined">delete</span>
                    <span className="material-symbols-outlined">stylus</span>
                </div>
            );
        },
    },
    {
        Header: 'Service',
        accessor: 'service_type',
        Cell: ({ value }: any) => {
            const words = value.split(' ');

            const firstTwoWords = words.slice(0, 2).join(' ');
            const remainingWords = words.slice(2).join(' ');

            return (
                <div>
                    <div>{firstTwoWords}</div>
                    <div>{remainingWords}</div>
                </div>
            );
        },
    },
    {
        Header: () => {
            return (
                <div>
                    <div>List Price &</div>
                    <div>Quantity</div>
                </div>
            );
        },
        accessor: 'list_price',
        Cell: ({ value, row }: any) => {
            if (value) {
                return (
                    <div
                        className="flex items-center"
                        style={{ minWidth: '90px', minHeight: '42.5px' }}
                    >
                        ${value} x{row.original.quantity}
                    </div>
                );
            } else {
                return <div></div>;
            }
        },
    },
    {
        Header: 'Total Amt.',
        accessor: 'total_amoun',
    },
    {
        Header: 'Service Date',
        accessor: 'date_entered',
        Cell: ({ value }: any) => {
            return <div className="">{value && formatDate(value, 'MMM D, YYYY')}</div>;
        },
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: any) => {
            return (
                <>
                    {value && (
                        <div className="py-1 shadow-sm flex gap-3">
                            <span className="shadow-md px-4">{value}</span>
                            <span className="material-symbols-outlined"> edit </span>
                        </div>
                    )}
                    {value}
                </>
            );
        },
    },
    {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }: any) => {
            return <div className="">{value && formatDate(value, 'MMM D, YYYY')}</div>;
        },
    },
];

export const serviceRecurringColumns: Array<any> = [
    {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ value }: any) => {
            return (
                <div className="flex gap-2" style={{ minWidth: '90px' }}>
                    <span className="material-symbols-outlined">delete</span>
                    <span className="material-symbols-outlined">stylus</span>
                </div>
            );
        },
    },
    {
        Header: 'Service',
        accessor: 'service_type',
        Cell: ({ value }: any) => {
            const words = value.split(' ');

            const firstTwoWords = words.slice(0, 2).join(' ');
            const remainingWords = words.slice(2).join(' ');

            return (
                <div>
                    <div>{firstTwoWords}</div>
                    <div>{remainingWords}</div>
                </div>
            );
        },
    },
    {
        Header: () => {
            return (
                <div>
                    <div>List Price &</div>
                    <div>Quantity</div>
                </div>
            );
        },
        accessor: 'list_price',
        Cell: ({ value, row }: any) => {
            if (value) {
                return (
                    <div
                        className="flex items-center"
                        style={{ minWidth: '90px', minHeight: '42.5px' }}
                    >
                        ${value} x{row.original.quantity}
                    </div>
                );
            } else {
                return <div></div>;
            }
        },
    },
    {
        Header: 'Total Amt.',
        accessor: 'total_amoun',
    },
    {
        Header: 'Recurring Type',
        accessor: 'recurring',
    },
    {
        Header: () => {
            return (
                <div>
                    <div>Start Date</div>
                    <div>End Date</div>
                </div>
            );
        },
        accessor: 'date_entered',
        Cell: ({ value }: any) => {
            return (
                <div className="">
                    <div>{value && formatDate(value, 'MMM D, YYYY')}</div>
                    <div>{value && formatDate(value, 'MMM D, YYYY')}</div>
                </div>
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: any) => {
            return (
                <>
                    {value && (
                        <div className="py-1 shadow-sm flex gap-3">
                            <span className="shadow-md px-4">{value}</span>
                            <span className="material-symbols-outlined"> edit </span>
                        </div>
                    )}
                    {value}
                </>
            );
        },
    },
    {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }: any) => {
            return <div className="">{value && formatDate(value, 'MMM D, YYYY')}</div>;
        },
    },
];

export const paymentColumns: Array<any> = [
    {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ value }: any) => {
            return (
                <div className="flex gap-2" style={{ minWidth: '90px' }}>
                    <span className="material-symbols-outlined">delete</span>
                    <span className="material-symbols-outlined">stylus</span>
                    <span className="material-symbols-outlined">send</span>
                    <span className="material-symbols-outlined">restart_alt</span>
                </div>
            );
        },
    },
    {
        Header: () => {
            return <div>Service</div>;
        },
        accessor: 'service',
    },
    {
        Header: () => {
            return <div style={{ minWidth: '140px' }}>Schedule Date</div>;
        },
        accessor: 'schedule_date',
        Cell: ({ value }: any) => {
            return (
                <div className="" style={{ minWidth: '90px' }}>
                    {value && formatDate(value, 'MMM D, YYYY')}
                </div>
            );
        },
    },
    {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }: any) => {
            if (value) {
                return <span>${value}</span>;
            }
            return '';
        },
    },
    {
        Header: () => {
            return (
                <div style={{ minWidth: '140px' }} className="py-2">
                    Payment Method
                </div>
            );
        },
        accessor: 'payment_method',
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }: any) => {
            return (
                <>
                    {value && (
                        <div className="py-1 justify-center my-1 w-28 shadow-md  flex">
                            <span className="pr-2">{value}</span>
                            <span
                                style={{ fontSize: '16px' }}
                                className="material-symbols-outlined pt-1"
                            >
                                edit
                            </span>
                        </div>
                    )}
                </>
            );
        },
    },
    {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }: any) => {
            return (
                <div className="" style={{ minWidth: '100px' }}>
                    {value}
                </div>
            );
        },
    },
];
//-------------------- UserBillings --------------------------------
