import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageManager, Table } from '../Tables/Table';
import { ComplianceColumns, LiabilityColumns } from '../ColumnHeader';
import useTranscript from '../../../../api/hooks/useTranscript';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { transcriptApi } from '../../../../api/transcripts';

export const UserTaxLiabilty: React.FC = () => {
    let { irs_account_id, type } = useParams();
    const [errMessage, setErrMessage] = useState<string | null>(null);
    // const { data: complianceData, isLoading: complianceLoading } = useTranscript(
    //     '/getComplianceData',
    //     { irs_account_id: irs_account_id, taxpayer_type: type },
    //     {
    //         enabled: !!irs_account_id,
    //         //refetchOnWindowFocus: false
    //     },
    //     (errorMessage: string) => {
    //         setErrMessage(errorMessage);
    //     },
    // );

    const fetchTaxometricData = async (irs_account_id: string | undefined) => {
        try {
            const response = await transcriptApi.get(
                `/getComplianceData?irs_account_id=${irs_account_id}&taxpayer_type=${type}`,
            );
            console.log(response, 'response');
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.data.error) {
                setErrMessage(err.response.data.error);
            }
            console.log('Error fetching taxometric data:', err.response.data.error);
            throw err;
        }
    };

    const { data: complianceData, isLoading: complianceLoading } = useQuery(
        ['compliance', irs_account_id, type],
        () => fetchTaxometricData(irs_account_id),
        {
            enabled: !!irs_account_id,
        },
    );

    return (
        <div className="p-6 flex flex-col gap-4 border-0 border-[#CCCCCC] pb-10 ">
            <span className="font-semibold text-xl">Tax Liability Metrics</span>

            {complianceLoading ? (
                <div className="w-full">Loading...</div>
            ) : complianceData ? (
                <Table data={complianceData} columns={LiabilityColumns} />
            ) : (
                errMessage && <div>{errMessage}</div>
                // errMessage && <div>Error: Failed to fetch Tax Liability data</div>
            )}
        </div>
    );
};
export const UserCompliance: React.FC = () => {
    let { irs_account_id, type } = useParams();
    const [errMessage, setErrMessage] = useState<string | null>(null);

    // const { data: complianceData, isLoading: complianceLoading } = useTranscript(
    //     '/getComplianceData',
    //     { irs_account_id: irs_account_id, taxpayer_type: type },
    //     {
    //         enabled: !!irs_account_id,
    //         //refetchOnWindowFocus: false
    //     },
    // );

    const fetchTaxometricData = async (irs_account_id: string | undefined) => {
        try {
            const response = await transcriptApi.get(
                `/getComplianceData?irs_account_id=${irs_account_id}&taxpayer_type=${type}`,
            );
            console.log(response, 'response');
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.data.error) {
                setErrMessage(err.response.data.error);
            }
            console.log('Error fetching taxometric data:', err.response.data.error);
            throw err;
        }
    };

    const {
        data: complianceData,
        isLoading: complianceLoading,
        error: transcriptsError,
    } = useQuery(['compliance', irs_account_id, type], () => fetchTaxometricData(irs_account_id), {
        enabled: !!irs_account_id,
    });

    return (
        <div className="p-6 flex flex-col gap-4 border-0 border-[#CCCCCC] pb-10 ">
            <span className="font-semibold text-xl">Compliance</span>
            <>
                {complianceLoading ? (
                    <div className="w-full">Loading...</div>
                ) : complianceData ? (
                    <Table data={complianceData} columns={ComplianceColumns} />
                ) : (
                    errMessage && <div>{errMessage}</div>
                    // errMessage && <div>Error: Failed to fetch compliance data</div>
                )}
            </>
        </div>
    );
};
