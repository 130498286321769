import moment from 'moment';
import { CommentType } from './EvalSection';

const CommentThread: React.FC<{ comments: CommentType[] }> = ({ comments = [] }) => {
    return <></>;

    return (
        <>
            {comments?.map(({ text, name, createdAt }: CommentType) => {
                return (
                    <div className="flex items-center pr-2 py-3 relative hover:bg-gray-100">
                        <div className="absolute h-full w-0.5 left-3 bg-gray-400" />
                        <div className="absolute h-3 w-3 rounded-full left-2 bg-primary-500" />

                        <div className="flex-grow pl-6">
                            {/* <div className="flex w-full"> */}
                            <div className="text-xs flex-grow">{name}</div>
                            <div className="text-xs text-gray-600">
                                {moment(createdAt).format('MM-DD-YY, hh:mm a')}
                            </div>
                            {/* </div> */}
                            <div className="text-sm mt-2">{text}</div>
                        </div>
                        {/* <span
                            onClick={(e) => {}}
                            className="cursor-pointer material-icons text-gray-400 hover:text-red-400"
                        >
                            delete_forever
                        </span> */}
                    </div>
                );
            })}
        </>
    );
};

export default CommentThread;
