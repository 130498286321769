import { useEffect, useState } from 'react';
import ButtonOutlined from './ButtonOutlined';
import InputGroup from '../../../admin/InputGroup';
import { fieldTypes } from '../../../../utils/enums';
import DateTimePicker from '../../../../components/admin/Calendar';
import Dropdown from '../../../client/Dropdown';
import { ssn, phone as phoneFormat } from '../../../../utils/format';
import { usa_counties, usa_states } from '../../../../utils/constants';
import CountryDrop from '../../../taxprep-client-form-components/Dropdown';
import { editClient, getTaxPro } from '../../../../api/admin';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { showToast } from '../../Toaster';
import { useAuthState } from '../../../../context/auth';
import { build } from '../../../../config';
import { useTranslation } from 'react-i18next';

type Props = {
    data: any;
    isLoading: boolean;
    setMaritalStatus: any;
    maritalStatus: any;
    taxPrepYears: any;
};

type accessOptns = {
    label: string;
    value: string;
};

const accessOpts: accessOptns[] = [
    { label: 'Tax Resolution', value: 'tax_resolution' },
    { label: 'Tax Prepration', value: 'tax_preparation' },
];
const maritalStatusOptions: accessOptns[] = [
    { label: 'Single', value: 'single' },
    { label: 'Married, Filing Jointly', value: 'jointly' },
    { label: 'Married, Filing Separately', value: 'seperately' },
    { label: 'Head of Household', value: 'hoh' },
    { label: 'Qualifying Widower', value: 'qw' },
];

const PrimaryUserProfile: React.FC<Props> = ({
    data,
    isLoading,
    setMaritalStatus,
    maritalStatus,
    taxPrepYears,
}) => {
    const [years, setYears] = useState<Array<number>>([]);
    const [isEditable, setIsEditable] = useState(false);
    const [firstName, setFirstName] = useState(data?.u_first_name);
    const [lastName, setLastName] = useState(data?.u_last_name);
    const [email, setEmail] = useState(data?.u_email);

    const [dob, setDob] = useState<string | undefined>(data?.u_birth_dt);
    const [formAccess, setFormAccess] = useState<any>(
        data?.u_service_type ? JSON?.parse(data?.u_service_type) : [{}],
    );
    const [phone, setPhone] = useState(data?.u_phone_no);
    const [fax, setFax] = useState(data?.u_fax);
    const [caseId, setCaseId] = useState('');
    const [status, setStatus] = useState(data?.u_status);
    const [streetName, setStreetName] = useState(data?.u_street_name);
    const [apartmentNo, setApartmentNo] = useState(data?.u_Appartment_unit);
    const [city, setCity] = useState(data?.u_City);
    const [state, setState] = useState(data?.u_state);
    const [zipCode, setZipCode] = useState(data?.u_zip_code);
    const [country, setCountry] = useState(data?.u_county);
    const [SSN, setSsn] = useState(data?.u_ssn);
    const [driverLicense, setDriverLicense] = useState(data?.u_Driver_license);
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const { t } = useTranslation();

    const [taxPro, setTaxPro] = useState<any>({
        label: data?.taxpro,
        value: data?.tax_preparer_id,
    });

    const [hide, setHide] = useState(true);

    const [taxPros, setTaxPros] = useState([
        {
            user_id: 'Loading...',
            u_first_name: 'Loading...',
            u_last_name: 'Loading...',
            u_user_name: 'Loading...',
            u_email: 'Loading...',
        },
    ]);
    //formatting data
    const taxProOptions = taxPros.map((t) => ({
        label: t.u_first_name + ' ' + t.u_last_name,
        value: t.user_id,
    }));

    const { data: taxProData } = useQuery('get-taxpros', getTaxPro);

    //seting tax pro data
    useEffect(() => {
        if (!taxProData) return;

        if (taxProData.success) {
            if (Array.isArray(taxProData.data)) setTaxPros(taxProData.data);
        }
    }, [taxProData]);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
    };

    const queryClient = useQueryClient();
    //mutation to edit client
    const mutation = useMutation(editClient, {
        onSuccess: async () => {
            await queryClient.invalidateQueries('TaxprepList');
            showToast('Client has been updated successfully!', 'success');
            toggleEdit();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const handleSubmit = () => {
        const formData: any = {
            u_first_name: firstName,
            u_last_name: lastName,
            u_email: email,
            u_phone_no: phone,
            u_fax: fax,
            u_status: status,
            taxpro: taxPro,
            u_street_name: streetName,
            u_Appartment_unit: apartmentNo,
            u_City: city,
            u_birth_dt: dob,
            u_state: state,
            u_zip_code: zipCode,
            u_county: country,
            u_ssn: SSN,
            u_taxprep_years: taxPrepYears,
            marital_status: maritalStatus,
            u_Driver_license: driverLicense,
            tax_preparer_id:
                data?.tax_preparer_id === taxPro?.value ? data?.tax_preparer_id : taxPro,
        };
        if (build.taxprep_module) {
            formData.u_service_type = formAccess;
        }
        mutation.mutate({ formData, user_id: data?.u_id });
    };
    //to get the service type text to show on screen
    const serviceType = data && JSON.parse(data?.u_service_type);
    const getServiceType = () => {
        const text1 = serviceType?.includes('tax_resolution') ? 'Tax Resolution' : '';
        const text2 = serviceType?.includes('tax_preparation') ? 'Tax Preparation' : '';

        return text1 && text2 ? `${text1} , ${text2}` : text1 || text2;
    };
    console.log('data', data);
    return (
        <>
            <div className="flex justify-between items-center">
                <p className="text-xl text-black font-semibold">{t('Taxpayer Information')}</p>
                <div className="flex items-center gap-4">
                    {isEditable && (
                        <span
                            className="material-symbols-outlined cursor-pointer text-red-400"
                            onClick={toggleEdit}
                        >
                            {t('cancel')}
                        </span>
                    )}
                    <ButtonOutlined
                        onClick={isEditable ? handleSubmit : toggleEdit}
                        customClass={`${
                            isEditable
                                ? 'bg-primary-500 text-white flex items-center'
                                : 'text-primary-500'
                        }`}
                    >
                        {!isEditable && (
                            <span
                                style={{ fontSize: '20px' }}
                                className="material-symbols-outlined text-primary-500"
                            >
                                edit
                            </span>
                        )}
                        {mutation.isLoading
                            ? 'Loading..'
                            : isEditable
                            ? t('Save Updates')
                            : t('Edit')}
                    </ButtonOutlined>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="firstName"
                            label="First Name"
                            placeholder=""
                            value={firstName}
                            setValue={(value) => {
                                setFirstName(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_first_name}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('First Name')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_first_name}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="firstName"
                            label="Last Name"
                            placeholder=""
                            value={lastName}
                            setValue={(value) => {
                                setLastName(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_last_name}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Last Name')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_last_name}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="email"
                            label="Email"
                            placeholder=""
                            value={email}
                            setValue={(value) => {
                                setEmail(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_email}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Email')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_email}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <DateTimePicker
                            id="dob"
                            label="Date of Birth"
                            className="h-full"
                            placeholder="Click to Select date"
                            value={dob}
                            setValue={(value: any) => setDob(value)}
                            type={fieldTypes.CALENDAR}
                            error={error?.u_birth_dt}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Date of Birth')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_birth_dt}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <Dropdown
                            id="maritalStatus"
                            label="Marital Status"
                            placeholder="Select Status"
                            options={maritalStatusOptions}
                            value={maritalStatus}
                            setValue={(value) => {
                                setMaritalStatus(value);
                                setErrors({});
                            }}
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1"
                            staticError={error?.marital_status}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Marital Status')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.marital_status}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="phone"
                            label="Phone"
                            placeholder=""
                            value={phone}
                            setValue={(value) => {
                                setPhone(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            formatFunction={phoneFormat}
                            error={error?.u_phone_no}
                            // staticError={error?.phone}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Phone')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_phone_no}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="Fax"
                            label="Fax"
                            placeholder=""
                            value={fax}
                            setValue={(value) => {
                                setFax(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_fax}
                            // staticError={error?.phone}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Fax')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_fax}
                            </label>
                        </>
                    )}
                </div>
                {/* <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="Fax"
                            label="Case Id"
                            placeholder=""
                            value={fax}
                            setValue={(value) => {
                                setCaseId(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            // staticError={error?.setCaseId}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {'Case Id'}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {caseId}
                            </label>
                        </>
                    )}
                </div> */}
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="Status"
                            label="Status"
                            placeholder=""
                            value={status}
                            setValue={(value) => {
                                setStatus(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_status}
                            // staticError={error?.status}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Status')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_status}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <Dropdown
                            id="tax_pro"
                            label="Assigned To Professional"
                            placeholder="Select Tax Preparer..."
                            options={taxProOptions}
                            value={taxPro}
                            setValue={(value) => {
                                setTaxPro(value);
                                setErrors({});
                            }}
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1"
                            staticError={error?.taxpro}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Assigned to')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.taxpro}
                            </label>
                        </>
                    )}
                </div>

                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="Street Name"
                            label="Street Name"
                            placeholder=""
                            value={status}
                            setValue={(value) => {
                                setStreetName(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_street_name}
                            // staticError={error?.status}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Street Name')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_street_name}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="Apartment/Unit No."
                            label="Apartment/Unit No."
                            placeholder=""
                            value={apartmentNo}
                            setValue={(value) => {
                                setApartmentNo(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_Appartment_unit}
                            // staticError={error?.status}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Apartment/Unit No.')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_Appartment_unit}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="City"
                            label="City"
                            placeholder=""
                            value={city}
                            setValue={(value) => {
                                setCity(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_City}
                            // staticError={error?.status}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('City')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_City}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <Dropdown
                            id="state_cd "
                            label="State"
                            placeholder="Select "
                            options={usa_states}
                            value={state}
                            setValue={(value) => {
                                setState(value);
                                setErrors({});
                            }}
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1 bg-gray-100"
                            staticError={error?.u_state}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('State')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_state}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="Zip Code"
                            label="Zip Code"
                            placeholder=""
                            value={zipCode}
                            setValue={(value) => {
                                setZipCode(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_zip_code}
                            // staticError={error?.status}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Zip Code')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_zip_code}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <CountryDrop
                            id="county"
                            label="County"
                            placeholder="Select"
                            options={usa_counties}
                            value={country}
                            formData={{ state_cd: state }}
                            setValue={(value) => {
                                setCountry(value);
                                setErrors({});
                            }}
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1 bg-gray-100"
                            staticError={error?.u_county}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Country')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_county}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="SSN"
                            label="SSN"
                            placeholder=""
                            value={SSN}
                            setValue={(value) => {
                                setSsn(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            // staticError={error?.status}
                            error={error?.u_ssn}
                            formatFunction={ssn}
                            hidden={true}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {'SSN'}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className={`text-black pb-1 flex font-semibold text-md w-full justify-between`}
                            >
                                {hide ? '•'.repeat(data?.u_ssn?.length) : data?.u_ssn}

                                <span
                                    onClick={() => setHide(!hide)}
                                    className="h-1 cursor-pointer mr-5"
                                >
                                    {SSN?.length > 1 ? (
                                        hide ? (
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        ) : (
                                            <span className="material-symbols-outlined">
                                                visibility_off
                                            </span>
                                        )
                                    ) : null}
                                </span>
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col my-2 py-2">
                    {isEditable ? (
                        <InputGroup
                            id="Driver License"
                            label="Driver License"
                            placeholder=""
                            value={driverLicense}
                            setValue={(value) => {
                                setDriverLicense(value);
                                setErrors({});
                            }}
                            type={fieldTypes.TEXT_INPUT}
                            className="w-full mt-1 h-full"
                            error={error?.u_Driver_license}
                            // staticError={error?.status}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Driver License')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {data?.u_Driver_license}
                            </label>
                        </>
                    )}
                </div>
                <div className="flex flex-col col-span-2 my-2 py-2">
                    {isEditable ? (
                        <Dropdown
                            isMulti
                            id="service_type "
                            label="Service Type"
                            placeholder="Select "
                            options={accessOpts}
                            value={formAccess}
                            setValue={(value) => {
                                setFormAccess(value);
                                setErrors({});
                            }}
                            type={fieldTypes.DROPDOWN}
                            error={error?.u_service_type}
                            className="w-full mt-1 bg-gray-100"
                            // staticError={error?.taxPro}
                        />
                    ) : (
                        <>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-gray-400 pb-1 font-semibold text-sm"
                            >
                                {t('Service type')}
                            </label>
                            <label
                                // htmlFor={label.toLowerCase()}
                                className="text-black pb-1 font-semibold text-md"
                            >
                                {getServiceType()}
                            </label>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default PrimaryUserProfile;
