import { api } from '.';
import { build } from '../config';

const getIncomeType = (data: any[]) => {
    let type = ``;
    data.forEach((item, index) => {
        if (index !== 0 && index !== data.length - 1) {
            type += item;

            if (index !== data.length - 2) {
                type += '_';
            }
        }
    });

    return type;
};

export const fetchClientWithFinance = async (irs_account_id: string) => {
    const response = await api.get(`/ClientWithFinance/${irs_account_id}`);

    let clientData = response.data;
    let exracted_keys = {};

    [...Array(100)].map((_, index) => {
        exracted_keys = {
            ...exracted_keys,
            [`additional_expense_data_${index + 1}_id`]: undefined,
            [`additional_expense_data_${index + 1}_action`]: undefined,
            [`additional_expense_data_${index + 1}_prompt`]: undefined,
            [`additional_expense_data_${index + 1}`]: undefined,
        };
    });

    clientData.data[0].additional_expense_data.map(
        ({ id, description, monthly_payment }: any, index: number) => {
            exracted_keys = {
                ...exracted_keys,
                [`additional_expense_data_${index + 1}_id`]: id,
                [`additional_expense_data_${index + 1}_action`]: 'update',
                [`additional_expense_data_${index + 1}_prompt`]: description,
                [`additional_expense_data_${index + 1}`]: monthly_payment,
            };
        },
    );

    return {
        ...clientData.data[0],
        ...exracted_keys,
        // additional_income_data,
        meta_data: clientData.meta_data,
    };
};
const createPayload = (data: any[], meta_data: any) => {
    if (!Array.isArray(data)) {
        return { irs_account_id: meta_data.irs_account_id, data };
    }

    const regEx = /^new/;
    const injectedData = data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: meta_data.irs_account_id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    const payload = {
        irs_account_id: meta_data.irs_account_id,
        meta_data: { ...meta_data, irs_account_id: meta_data.irs_account_id },
        data: injectedData,
    };

    return payload;
};

export const upsertEmploymentData = async ({ employment_data, meta_data }: any) => {
    const payload = createPayload(employment_data, meta_data);

    const response = await api.post(`/employmentByAction`, payload);
    return response.data;
};

export const upsertAdditionalIncome = async ({ additional_income_data, meta_data }: any) => {
    const payload = createPayload(additional_income_data, meta_data);
    const response = await api.post(`/addIncomeByAction`, payload);
    return response.data;
};

export const upsertBankAccounts = async ({ bank_account_data, meta_data, cash_on_hand }: any) => {
    const payload = createPayload(bank_account_data, meta_data);

    const response = await api.post(`/BankAccountByAction`, { cash_on_hand, ...payload });
    return response.data;
};

export const upsertInvestments = async ({ investment_data, meta_data }: any) => {
    const payload = createPayload(investment_data, meta_data);

    const response = await api.post(`/investmentByAction`, payload);
    return response.data;
};

export const upsertVirtualCurrency = async ({
    virtual_currency_data,
    meta_data,
    individuals_with_access_to_vc,
}: any) => {
    const payload = createPayload(virtual_currency_data, meta_data);

    const response = await api.post(`/createUpVirtualCurrency`, {
        individuals_with_access_to_vc,
        ...payload,
    });
    return response.data;
};

export const upsertLifeInsurance = async ({ insurance_data, meta_data }: any) => {
    const payload = createPayload(insurance_data, meta_data);

    const response = await api.post(`/lifeInsuranceByAction`, payload);
    return response.data;
};

export const upsertRealProperty = async ({
    real_estate_data,
    meta_data,
    real_property_fund_offer,
    real_property_fund_offer_amount,
}: any) => {
    const payload = createPayload(real_estate_data, meta_data);

    const response = await api.post(`/realEstateByAction`, {
        real_property_fund_offer,
        real_property_fund_offer_amount,
        ...payload,
    });
    return response.data;
};

export const upsertVehicles = async ({ vehicle_data, meta_data }: any) => {
    const payload = createPayload(vehicle_data, meta_data);

    const response = await api.post(`/vehicleByAction`, payload);
    return response.data;
};

export const upsertCreditCards = async ({
    credit_card_data,
    meta_data,
    available_credit_amount_owed_as_of_date,
    available_credit_as_of_date,
}: any) => {
    const payload = createPayload(credit_card_data, meta_data);

    const response = await api.post(`/creditCardByAction`, {
        available_credit_amount_owed_as_of_date,
        available_credit_as_of_date,
        ...payload,
    });
    return response.data;
};

export const upsertOtherPersonalAssets = async ({ other_assets_data, meta_data }: any) => {
    const payload = createPayload(other_assets_data, meta_data);

    const response = await api.post(`/otherAssetsByAction`, payload);
    return response.data;
};

export const upsertIncomeExpense = async ({ meta_data, ...data }: any) => {
    let expenseData: any = [];

    Object.keys(data).map((key) => {
        const regExPrefix = /^additional_expense_data_/;
        const regExSuffix = /_prompt$/;

        if (regExPrefix.test(key) && regExSuffix.test(key)) {
            const indexArray = key.match(/\d+/g);
            const index = indexArray ? indexArray[0] : undefined;

            if (data[`additional_expense_data_${index}_id`]) {
                let item = {
                    irs_account_id: meta_data.irs_account_id,
                    id: data[`additional_expense_data_${index}_id`],
                    action: data[`additional_expense_data_${index}_action`],
                    description: data[`additional_expense_data_${index}_prompt`],
                    monthly_payment: data[`additional_expense_data_${index}`],
                };

                if (!item.monthly_payment && !item.description) {
                    item.action = 'delete';
                }
                expenseData.push(item);
            } else {
                if (data[`additional_expense_data_${index}_action`] !== 'delete') {
                    if (
                        data[`additional_expense_data_${index}_prompt`] &&
                        data[`additional_expense_data_${index}`]
                    ) {
                        const item = {
                            action: 'create',
                            irs_account_id: meta_data.irs_account_id,
                            description: data[`additional_expense_data_${index}_prompt`],
                            monthly_payment: data[`additional_expense_data_${index}`],
                        };
                        expenseData.push(item);
                    }
                }
            }
        }
    });

    const payload = {
        ...data,
        irs_account_id: meta_data.irs_account_id,
        meta_data: { ...meta_data, irs_account_id: meta_data.irs_account_id },
        additional_expense_data: expenseData,
    };
    const response = await api.post(`/FinancialExpenseByAction`, payload);
    return response.data;
};
