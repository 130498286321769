import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertBussinessIncomeData = (year: string, tempData: any) => {
    const { taxprep_account_id, irs_account_id, income_data, meta_data } = tempData;
    const regEx = /^new/;
    const data = income_data
        ?.map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: irs_account_id,
                    taxprep_account_id: taxprep_account_id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/businessIncomeDetailsByActionTP`, {
        irs_account_id: irs_account_id,
        meta_data: {
            ...meta_data,
            taxprep_account_id: taxprep_account_id,
            irs_account_id: irs_account_id,
        },
        data,
        taxprep_year: year,
    });
};

const b_incomeForm: FormInterface = [
    {
        type: fieldTypes.RADIO,
        id: 'statutory_employee',
        label: 'Are you a Statutory employee?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'income',
                            label: 'Mention the Income',
                            placeholder: 'john',
                        },
                    ],
                },
            ],
        },
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'return_allowances',
        label: 'Returns and allowances',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'good_sold_cost',
        label: 'Cost of goods sold ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'other_income',
        label: 'Other income (including federal and state gasoline or fuel tax credit or refund)',
        placeholder: 'john',
        span: 2,
    },
];

const b_incomeSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'income_data',
        label: 'Do you have Business Income?',
        header: 'Income',
        displayColumns: [
            {
                name: 'Statutory employee?',
                key: 'financial_interest',
            },
            {
                name: 'Returns and allowance',
                key: 'return_allowances',
            },
        ],
        formStructure: b_incomeForm,
    },
];

export const page: PageInputInterface = {
    address: 'income_data',
    name: 'Income',
    sectionId: enumSections.BUSINESS_INFORMATION,

    formStructure: b_incomeSecForm,
    save: upsertBussinessIncomeData,
};
