import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertBusinesses = (
    year: string,
    { business_data, meta_data, id, irs_account_id }: any,
) => {
    const regEx = /^new/;

    const data = business_data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: irs_account_id, taxprep_account_id: id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/taxprepBusinessByAction`, {
        irs_account_id: irs_account_id,
        meta_data: { ...meta_data, taxprep_account_id: id, irs_account_id: irs_account_id },
        data,
        taxprep_year: year,
    });
};

const bussinessForm: FormInterface = [
    { type: fieldTypes.SEPERATOR, heading: 'Bussiness Section' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'business_name',
        label: 'Business Name',
        placeholder: 'john',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'business_type',
        placeholder: 'Select the type of bussiness that you own',
        label: 'Selecting Accounting method',
        options: [
            { value: 'Single Member LLC / Sole Prop', label: 'Single Member LLC / Sole Prop' },
            { value: 'Partnership LLC/S-Corp', label: 'Partnership LLC/S-Corp' },
            { value: 'Any Other Corporation', label: 'Any Other Corporation' },
        ],
    },
];

const bussinessSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_data',
        label: 'Do you have Business?',
        header: 'Bussiness',
        displayColumns: [
            {
                name: 'Business Name',
                key: 'business_name',
            },
            {
                name: 'Business Type',
                key: 'business_type',
            },
        ],
        formStructure: bussinessForm,
    },
];

export const page: PageInputInterface = {
    address: 'business_data',
    name: 'Business details',
    sectionId: enumSections.BUSINESS_INFORMATION,

    formStructure: bussinessSecForm,
    save: upsertBusinesses,
};
