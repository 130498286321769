import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteSuperClient, addSuperClient, editSuperClient } from '../api/admin';
import { useAuthState } from '../context/auth';
import { fieldTypes } from '../utils/enums';
import { PageManager, Table } from './Table';
import { useFormDispatch } from '../context/form';
import { useIsMobile } from '../utils/helpers';
import { showToast } from '../components/basic/Toaster';
import AdminMenu from '../components/basic/AdminMenu';
import DropdownAdmin from '../components/admin/Dropdown';
import InputGroup from '../components/client/InputGroup';
import InputGroupAdmin from '../components/admin/InputGroup';
import Button from '../components/basic/Button';
import Modal from '../components/basic/Modal';
import NavBar from '../components/basic/NavBar';
import classNames from 'classnames';
import ResendCredentials from '../components/basic/ResendCredentials';
import useSuperClients from '../api/hooks/useSuperClients';

const clientLoadingData = [
    {
        id: 'Loading...',
        date_entered: 'Loading',
        IsDeleted: 'Loading...',
        client_name: 'Loading...',
        feature_data: 'Loading...',
        client_url: 'Loading',
    },
];

const DeleteAdmin: React.FC<{ close: () => void; user: any }> = ({ close, user }) => {
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const queryClient = useQueryClient();
    const { token } = useAuthState();

    const { mutate: deleteMutation, isLoading } = useMutation(deleteSuperClient, {
        onSuccess: () => {
            queryClient.invalidateQueries('super-clients-list');
            showToast('Client has been Deleted !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const deleteClient = () => {
        if (!token) return;

        deleteMutation({
            id: user.id,
            action: 'delete',
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Delete Client`}</div>

            <div className="font-body py-2">{`Are you sure you want to delete ${user.client_name} from your clients permanently?`}</div>

            <div className="flex w-full">
                <Button
                    onClick={deleteClient}
                    isLoading={isLoading}
                    className="text-center flex-grow mr-2"
                >
                    {'DELETE CLIENT'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const AddNewClient: React.FC<{ close: () => void; edit?: any }> = ({ close, edit }) => {
    const [clientName, setClientName] = useState<string>(edit?.client_name);
    const [clientUrl, setClientUrl] = useState<string>(edit?.client_url);
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const queryClient = useQueryClient();

    const { mutate: addMutation, isLoading: addLoading } = useMutation(addSuperClient, {
        onSuccess: () => {
            queryClient.invalidateQueries('super-clients-list');
            showToast('Client has been Added !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const { mutate: editMutation, isLoading: editLoading } = useMutation(editSuperClient, {
        onSuccess: () => {
            queryClient.invalidateQueries('super-clients-list');
            showToast('Client has been Updated !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const { token } = useAuthState();

    const addClient = () => {
        if (!token) return;
        addMutation({
            client_name: clientName,
            client_url: clientUrl,
        });
    };

    const editClient = () => {
        if (!token) return;

        editMutation({
            client_name: clientName,
            client_url: clientUrl,
            id: edit.id,
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`${edit ? 'Edit' : 'Add New'}  Client`}</div>

            <InputGroup
                id="clientName"
                label="Client Name"
                placeholder=""
                value={clientName}
                setValue={(value) => {
                    setClientName(value);
                    setErrors({});
                }}
                type={fieldTypes.TEXT_INPUT}
                className="w-full mt-1"
                staticError={error.client_name}
            />

            <InputGroup
                id="clientUrl"
                label="Client URL"
                placeholder=""
                value={clientUrl}
                setValue={(value) => {
                    setClientUrl(value);
                    setErrors({});
                }}
                type={fieldTypes.TEXT_INPUT}
                className="w-full mt-1"
                staticError={error.client_url}
            />

            {edit ? (
                <Button
                    isLoading={editLoading}
                    onClick={editClient}
                    className="text-center w-full mt-8"
                >
                    {'EDIT CLIENT'}
                </Button>
            ) : (
                <Button
                    isLoading={addLoading}
                    onClick={addClient}
                    className="text-center w-full mt-8"
                >
                    {'ADD CLIENT'}
                </Button>
            )}
        </div>
    );
};

const ActionsCell = ({ original, setConfirmDelete, setEditClient }: any) => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-evenly items-center">
            <span
                className="cursor-pointer font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
                onClick={(e) => {
                    e.stopPropagation();
                    setEditClient(original);
                }}
            >
                edit
            </span>
            <span
                className="material-symbols-outlined text-gray-400 hover:text-primary-400 cursor-pointer font-bold"
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/superAdmin/clientsFeatures/${original.id}`);
                    // setEditClient(original);
                }}
            >
                settings
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDelete(original['id']);
                }}
                className="cursor-pointer material-icons text-gray-400 hover:text-red-400"
            >
                delete_forever
            </span>
        </div>
    );
};

const NameComponent = ({ original }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className="flex items-center cursor-pointer hover:bg-gray-100">
                <div className="text-primary-500 hover:underline" onClick={() => setIsOpen(true)}>
                    {original.client_name}
                </div>
            </div>
            <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
                <div
                    className="w-full flex flex-col m-2 bg-white rounded-md"
                    style={{ maxWidth: 600, maxHeight: '90vh' }}
                >
                    <div>Hello</div>
                </div>
            </Modal>
        </>
    );
};

export default function SuperClient() {
    const [addClient, setAddClient] = useState(false);
    const isMobile = useIsMobile();
    const [menu, setMenu] = useState<boolean>(false);
    const { superClients, total, setFilterParams, filterParams, isLoading } = useSuperClients();
    const [editClient, setEditClient] = useState(undefined);
    const [cofirmDeleteId, setConfirmDelete] = useState<string | undefined>();
    const [cofirmResendId, setConfirmResend] = useState<string | undefined>();

    const data: Array<{
        id: string;
        date_entered: string;
        IsDeleted: boolean;
        cilent_name: string;
        client_url: string;
        feature_data: Array<{
            id: string;
            mycase_client_id: string;
            date_entered: string;
            last_updated_date: string;
            IsDeleted: boolean;
            created_by: string;
            modified_by: string;
            feature_enable: string;
            feature_name: string;
            form_list: null;
            third_party: string;
        }>;
    }> = isLoading ? clientLoadingData : superClients;

    const columns: Array<any> = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'client_name', // accessor is the "key" in the data
                Cell: ({ row: { original } }: any) => <NameComponent original={original} />,
            },
            {
                Header: 'Client URL',
                accessor: 'client_url',
            },
            {
                Header: 'Tax Preparer',
                accessor: 'id',
                Cell: ({ row: { original } }: any) => {
                    return <div>{original.id}</div>;
                },
            },
            {
                Header: 'Actions',
                Cell: ({ row: { original } }: any) => (
                    <ActionsCell
                        original={original}
                        setConfirmDelete={setConfirmDelete}
                        setEditClient={setEditClient}
                    />
                ),
                sortable: false,
            },
        ],
        [],
    );

    const formDispatch = useFormDispatch();
    useEffect(() => {
        formDispatch({ type: 'CLEAR_FORM', payload: { data: undefined } });
    }, []);

    return (
        <>
            <div className={classNames('w-full h-screen flex', { 'flex-col': isMobile })}>
                {isMobile && <NavBar toggleMenu={() => setMenu(!menu)} />}
                {!isMobile ? (
                    <AdminMenu selected="superAdmin" />
                ) : (
                    menu && (
                        <div className="z-50 absolute h-screen">
                            <AdminMenu selected="superAdmin" close={() => setMenu(false)} />
                        </div>
                    )
                )}
                <div className="w-full flex h-screen flex-col items-center p-6 flex-grow">
                    <div className="w-full flex flex-col max-w-6xl max-h-full">
                        <div className="flex w-full items-center py-2">
                            <div className="font-title font-medium flex-grow">
                                {'CLIENT MANAGER'}
                            </div>
                            <Button onClick={() => setAddClient(true)} className="text-sm">
                                {`ADD CLIENT`}
                            </Button>
                        </div>

                        <div className="flex w-full">
                            <InputGroupAdmin
                                id="search"
                                label="Search Text"
                                placeholder="Search Text"
                                value={filterParams?.search}
                                setValue={(value) =>
                                    setFilterParams((p) => ({ ...p, search: value }))
                                }
                                onEnter={(value) =>
                                    setFilterParams((p) => ({ ...p, search: value }))
                                }
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full mt-1"
                            />

                            <DropdownAdmin
                                isClearable
                                formData={{}}
                                id="account_type"
                                label="Account Type"
                                placeholder="Select Tax Preparer..."
                                options={[
                                    { label: 'Personal', value: 'Personal' },
                                    { label: 'Business', value: 'Business' },
                                ]}
                                value={filterParams?.account_type}
                                setValue={(value) =>
                                    setFilterParams((p) => ({ ...p, account_type: value }))
                                }
                                type={fieldTypes.DROPDOWN}
                                className="w-full mt-1"
                            />
                        </div>

                        <Table
                            data={data}
                            columns={columns}
                            isSortEnabled={false}
                            // onRowClick={({ irs_account_id }) => redirectToEval(irs_account_id)}
                        />

                        <PageManager
                            total={total}
                            offset={filterParams?.offset}
                            setOffset={(offset) => setFilterParams((p) => ({ ...p, offset }))}
                        />
                    </div>
                </div>
            </div>

            <Modal close={() => setAddClient(false)} isOpen={addClient}>
                <AddNewClient close={() => setAddClient(false)} />
            </Modal>

            <Modal close={() => setEditClient(undefined)} isOpen={!!editClient}>
                <AddNewClient edit={editClient} close={() => setEditClient(undefined)} />
            </Modal>

            <Modal close={() => setConfirmDelete(undefined)} isOpen={!!cofirmDeleteId}>
                <DeleteAdmin
                    user={data.find(({ id }) => id === cofirmDeleteId)}
                    close={() => setConfirmDelete(undefined)}
                />
            </Modal>

            <Modal close={() => setConfirmResend(undefined)} isOpen={!!cofirmResendId}>
                <ResendCredentials
                    role="Client"
                    user={data.find(({ id }) => id === cofirmResendId)}
                    close={() => setConfirmResend(undefined)}
                />
            </Modal>
        </>
    );
}
