import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDocuments from '../../api/hooks/useDocument';
import { useAuthState } from '../../context/auth';
import { useFormState } from '../../context/form';
import { enumSections, fieldTypes } from '../../utils/enums';
import { pageRefiner } from '../../utils/helpers';
import { PageInterface, SectionInterface } from '../../utils/interfaces';

import Button from '../basic/Button';
import ButtonSmall from '../basic/ButtonSmall';
import Modal from '../basic/Modal';
import Dropdown from '../client/Dropdown';

import cloud_logo from '../../assets/cloud.png';

type HTMLInputType = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> | null;

export const usePageSelector = () => {
    const { pages } = pageRefiner();
    const { busniness_data } = useFormState();

    let allpageOptions = Object.keys(pages).map((key: string) => {
        return {
            value: pages[key].address,
            label: pages[key].name,
            sectionId: pages[key].sectionId,
        };
    });

    let basicBusniessOptions = allpageOptions.filter(
        (item) => item.sectionId === enumSections.BUSINESS_INFORMATION,
    );

    let businessOptions: any[] = [];
    busniness_data?.forEach((b: any) => {
        businessOptions = [
            ...businessOptions,
            ...basicBusniessOptions.map((i) => {
                return {
                    ...i,
                    businessId: b.id,
                    label: b.name + ' | ' + i.label,
                };
            }),
        ];
    });

    const otherpageOptions = allpageOptions.filter(
        (item) => item.sectionId === enumSections.OTHER_INFORMATION,
    );
    const firstpageOptions = allpageOptions.filter((item) =>
        [enumSections.YOUR_INFORMATION, enumSections.FINANCIAL_INFORMATION].includes(
            item.sectionId,
        ),
    );
    const pageOptions = [...firstpageOptions, ...businessOptions, ...otherpageOptions];

    return { pageOptions };
};

const Success: React.FC<{ close: () => void }> = ({ close }) => {
    const [timeLeft, setTimeLeft] = useState(2);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => {
            close();
            clearInterval(intervalId);
        };
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    return (
        <>
            <div className="w-full flex justify-center my-4">
                <div className="p-2 border-2 border-primary-500 h-16 w-16 rounded-full">
                    <img src={cloud_logo} className="h-full w-full" />
                </div>
                <div className="p-5">{`File Uploaded succeffully`}</div>
            </div>
        </>
    );
};

const FilePreview = ({ file }: { file: File | undefined }) => {
    if (!file)
        return (
            <div className="bg-gray-100 mx-2 p-2 text-center py-4 mt-2 mb-4 border-dashed border-gray-200 border-2">{`Upload file to see a preview`}</div>
        );

    let regEx = /^image/;
    if (regEx.test(file.type)) {
        return (
            <div
                className="w-auto flex justify-center bg-gray-100"
                style={{ maxHeight: '50vh', height: '100vh' }}
            >
                <img src={URL.createObjectURL(file)} className="h-full" />
            </div>
        );
    }

    if (['.pdf', 'application/pdf'].includes(file.type)) {
        return (
            <div className="flex-grow bg-gray-200" style={{ maxHeight: '50vh', height: '100vh' }}>
                <iframe height="100%" width="100%" src={URL.createObjectURL(file)} className="" />
            </div>
        );
    }

    return (
        <div className="bg-gray-100">{`Unknown document type. Please select a PDF or image.`}</div>
    );
};

const UploadModal = ({
    isOpen,
    close,
    description,
    page: defaultPage,
    pageLock,
}: {
    isOpen: boolean;
    close: () => void;
    description?: string;
    page?: string;
    pageLock?: boolean;
}): JSX.Element => {
    const [file, setFile] = useState<File>();

    const { pages } = pageRefiner();
    const [page, setPage] = useState<string | undefined>(defaultPage);

    const { upload } = useDocuments();
    const { mutate, isLoading, isSuccess, reset } = upload;

    const uploadDocument = () => {
        mutate({ document: file!, section: page! });
    };

    const { pageOptions } = usePageSelector();

    const closeModal = () => {
        reset();
        setFile(undefined);
        close();
    };

    return (
        <Modal isOpen={isOpen} close={closeModal} title={'Upload Document'}>
            <div
                className="w-full flex flex-col m-2 bg-white rounded-md"
                style={{ maxWidth: 600, maxHeight: '90vh' }}
            >
                <div className="w-full flex bg-primary-500 mb-3 items-center px-2 py-1">
                    <div className="text-sm flex-grow font-title   text-white  font-medium">
                        {`Upload Document | ${pages[page || '']?.name}`}
                    </div>
                    <span
                        onClick={() => close()}
                        className="material-symbols-outlined cursor-pointer text-gray-100 hover:text-red-200"
                        style={{ fontSize: 20 }}
                    >
                        cancel
                    </span>
                </div>
                {/* <div className="mx-2 mt-1">{description}</div> */}

                {isSuccess ? (
                    <Success close={closeModal} />
                ) : (
                    <>
                        {!pageLock && (
                            <Dropdown
                                type={fieldTypes.DROPDOWN}
                                id="page"
                                label="Define a section for uploading the document"
                                value={page}
                                setValue={(o) => setPage(o)}
                                options={pageOptions}
                                placeholder="Please select a Section"
                            />
                        )}

                        <div className="relative flex w-full">
                            <input
                                id="uploadfilecomponent"
                                type={'file'}
                                accept=".pdf, image/*"
                                onChange={(e) => setFile(e.target.files?.[0])}
                                className="cursor-pointer flex-grow bg-gray-200 hover:bg-gray-300 text-sm px-2 py-3 rounded-md my-3 mx-2"
                            />
                            {file && (
                                <div className="absolute right-4 flex items-center h-full">
                                    <span
                                        onClick={() => {
                                            setFile(undefined);
                                            const comp = document.getElementById(
                                                'uploadfilecomponent',
                                            ) as HTMLInputType;

                                            if (comp) {
                                                comp.value = '';
                                            }
                                        }}
                                        className="material-icons cursor-pointer text-white bg-gray-400 p-0.5 text-xs rounded-full"
                                    >
                                        close
                                    </span>
                                </div>
                            )}
                        </div>
                        <FilePreview file={file} />

                        <Button
                            disabled={!file}
                            onClick={uploadDocument}
                            isLoading={isLoading}
                            className="my-3 mx-2 mt-4"
                        >
                            <div>Confirm</div>
                        </Button>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UploadModal;
