/* eslint-disable import/no-anonymous-default-export */
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { addClient, deleteUser, getTaxPro } from '../api/admin';
import useClients from '../api/hooks/useClients';
import AdminMenu from '../components/basic/AdminMenu';
import Dropdown from '../components/client/Dropdown';
import DropdownAdmin from '../components/admin/Dropdown';
import InputGroup from '../components/client/InputGroup';
import InputGroupAdmin from '../components/admin/InputGroup';
import Button from '../components/basic/Button';
import Modal from '../components/basic/Modal';
import NavBar from '../components/basic/NavBar';
import { useAuthState } from '../context/auth';
import { fieldTypes } from '../utils/enums';
import { PageManager, Table } from './Table';
import { useFormDispatch } from '../context/form';
import { useFeatureEnabled, useIsMobile } from '../utils/helpers';
import classNames from 'classnames';
import ResendCredentials from '../components/basic/ResendCredentials';
import AssignTaxPro from '../components/basic/AssignTaxPro';
import { build } from '../config';
import cloud_logo from '../assets/cloud.png';
import { showToast } from './../components/basic/Toaster';
import ConfirmExport from '../components/basic/ExportConfirmationModal';
import { HeaderGroup } from 'react-table';

const clientLoadingData = [
    {
        irs_account_id: 'Loading...',
        first_name: 'Loading...',
        last_business_name: 'Loading...',
        birth_dt: 'Loading...',
        home_phone: 'Loading...',
        email_address: 'Loading...',
        address_line_1: 'Loading...',
    },
];

const BusinessCell: React.FC<{ business: string; redirect: (id: string) => void }> = ({
    business,
    redirect,
}) => {
    const businessArray = business?.split(',').map((item) => {
        return { id: item.split(':')[0], name: item.split(':')[1] };
    });

    return (
        <div className="flex flex-col">
            {businessArray?.map(({ id, name }) => (
                <div onClick={() => redirect(id)} className="flex items-center">
                    <div className="w-2 h-2 mr-1 rounded-md bg-primary-300" />
                    <div>{name}</div>
                </div>
            ))}
        </div>
    );
};

const DeleteClient: React.FC<{ close: () => void; user: any }> = ({ close, user }) => {
    const [error, setErrors] = useState<{ [key: string]: any }>({});

    const queryClient = useQueryClient();
    const { token, role } = useAuthState();

    const mutation = useMutation(deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('clients-list');
            showToast('Client has been Deleted !', 'success');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const deleteClient = () => {
        if (!token) return;

        mutation.mutate({
            user_id: user.user_id,
            client_type: 'client',
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Delete Client`}</div>

            <div className="font-body py-2">{`Are you sure you want to delete ${user.name} from your clients permanently?`}</div>

            <div className="flex w-full">
                <Button
                    isLoading={mutation.isLoading}
                    onClick={deleteClient}
                    className="text-center flex-grow mr-2"
                >
                    {'DELETE CLIENT'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

type accessOptns = {
    label: string;
    value: string;
};

const accessOpts: accessOptns[] = [
    { label: 'Tax Resolution', value: 'tax_resolution' },
    { label: 'Tax Prepration', value: 'tax_preparation' },
];

const AddNewClient: React.FC<{ close: () => void; plan_type: string; length: boolean }> = ({
    close,
    plan_type,
    length,
}) => {
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setlastName] = useState<string>('');
    const [taxPro, setTaxPro] = useState<string>('');
    const [formAccess, setFormAccess] = useState<string[]>([]);
    const { success: isTaxPrepEnabled } = useFeatureEnabled('Tax Prep');
    const [years, setYears] = useState<number[]>([]);
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const [isClientAdded, setClientAdded] = useState(false);

    useEffect(() => {
        const saveTimeout = setTimeout(() => {
            if (isClientAdded) {
                setClientAdded(false);
                close();
            }
        }, 3000);

        return () => clearTimeout(saveTimeout);
    }, [isClientAdded]);

    const queryClient = useQueryClient();
    const mutation = useMutation(addClient, {
        onSuccess: () => {
            queryClient.invalidateQueries('clients-list');
            setClientAdded(true);
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    const [taxPros, setTaxPros] = useState([
        {
            user_id: 'Loading...',
            u_first_name: 'Loading...',
            u_last_name: 'Loading...',
            u_user_name: 'Loading...',
            u_email: 'Loading...',
        },
    ]);

    //geetting previous twenty years from current year
    const currentYear = new Date().getFullYear();
    const getYears = (currentYear: number) => {
        let temp = currentYear - 20;
        let year = [];
        for (let i = currentYear; i > temp; i--) {
            year.push(i);
        }
        return year;
    };

    const taxProOptions = taxPros.map((t) => ({
        label: t.u_first_name + ' ' + t.u_last_name,
        value: t.user_id,
    }));

    const { data: taxProData } = useQuery('get-taxpros', getTaxPro);

    useEffect(() => {
        if (!taxProData) return;

        if (taxProData.success) {
            if (Array.isArray(taxProData.data)) setTaxPros(taxProData.data);
        }
    }, [taxProData]);

    const { token, role } = useAuthState();

    const registerClient = () => {
        if (!token) return;

        const formData: {
            u_email: string;
            u_first_name: string;
            u_last_name: string;
            tax_preparer_id: string;
            token: string;
            u_service_type?: string[];
            u_taxprep_years?: number[];
        } = {
            u_email: email,
            u_first_name: firstName,
            u_last_name: lastName,
            tax_preparer_id: taxPro,
            token: token,
        };

        if (isTaxPrepEnabled) {
            formData.u_service_type = formAccess;
            if (formAccess.includes('tax_preparation')) {
                formData.u_taxprep_years = years;
            }
        }
        mutation.mutate(formData);
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ maxWidth: 500, width: '100%' }}>
            {isClientAdded ? (
                <>
                    <div
                        className="bg-white w-full max-w-3xl flex flex-col items-center justify-center rounded"
                        style={{ maxHeight: '95%', minHeight: '40%' }}
                    >
                        <div className="p-2 border-2 border-primary-500 h-16 w-16 rounded-full">
                            <img src={cloud_logo} className="h-full w-full" />
                        </div>
                        <div className="pt-5 pb-1">{`Client Added Successfully`}</div>
                    </div>
                </>
            ) : (
                <>
                    <div className="font-title py-4">{`Add New Client`}</div>
                    {plan_type === 'Free' && length ? (
                        <div className="bg-red-50 mb-5">
                            <div className="text-center text-sm font-semibold p-2">
                                Please Upgrade Your Plan!
                                <div className="text-gray-600 text-xs py-1">
                                    Your current plan is{' '}
                                    <span className="text-primary-400">{plan_type}</span>
                                </div>
                            </div>
                            <Button className="text-center w-auto">
                                <a
                                    href="https://mycase.tax/pricing/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {'Upgrade'}
                                </a>
                            </Button>
                        </div>
                    ) : (
                        <>
                            <InputGroup
                                id="email"
                                label="Email"
                                placeholder=""
                                value={email}
                                setValue={(value) => {
                                    setEmail(value);
                                    setErrors({});
                                }}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full mt-1"
                                staticError={error.u_email}
                            />

                            <InputGroup
                                id="firstName"
                                label="First Name"
                                placeholder=""
                                value={firstName}
                                setValue={(value) => {
                                    setFirstName(value);
                                    setErrors({});
                                }}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full mt-1"
                                staticError={error.u_first_name}
                            />

                            <InputGroup
                                id="lastName"
                                label="Last Name"
                                placeholder=""
                                value={lastName}
                                setValue={(value) => {
                                    setlastName(value);
                                    setErrors({});
                                }}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full mt-1"
                                staticError={error.u_last_name}
                            />

                            {role === 'Admin' && (
                                <>
                                    <Dropdown
                                        id="tax_pro"
                                        label="Assigned To Professional"
                                        placeholder="Select Tax Preparer..."
                                        options={taxProOptions}
                                        value={taxPro}
                                        setValue={(value) => {
                                            setTaxPro(value);
                                            setErrors({});
                                        }}
                                        type={fieldTypes.DROPDOWN}
                                        className="w-full mt-1"
                                        staticError={error.taxPro}
                                    />
                                    {build.taxprep_module && (
                                        <>
                                            <Dropdown
                                                isMulti
                                                id="service_type "
                                                label="Service Type"
                                                placeholder="Select "
                                                options={accessOpts}
                                                value={formAccess}
                                                setValue={(value) => {
                                                    setFormAccess(value);
                                                    setErrors({});
                                                }}
                                                type={fieldTypes.DROPDOWN}
                                                className="w-full mt-1"
                                                staticError={error.taxPro}
                                            />
                                            {formAccess.includes('tax_preparation') && (
                                                <>
                                                    <Dropdown
                                                        isMulti
                                                        id="tp_initial_years "
                                                        label="Tax Prep Years"
                                                        placeholder="Select "
                                                        options={getYears(currentYear).map(
                                                            (year: number) => {
                                                                return {
                                                                    label: `${year}`,
                                                                    value: `${year}`,
                                                                };
                                                            },
                                                        )}
                                                        value={years}
                                                        setValue={(value) => {
                                                            setYears(value);
                                                            setErrors({});
                                                        }}
                                                        type={fieldTypes.DROPDOWN}
                                                        className="w-full mt-1"
                                                        staticError={error.taxPro}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            <Button
                                isLoading={mutation.isLoading}
                                onClick={registerClient}
                                className="text-center w-full mt-8"
                            >
                                {'ADD CLIENT'}
                            </Button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const ActionsCell = ({ original, setConfirmDelete, setConfirmResend, setAssignTaxPro }: any) => {
    return (
        <div className="flex justify-center items-center">
            <span
                onClick={(e) => {
                    e.stopPropagation();

                    original['user_id']
                        ? setAssignTaxPro(original['user_id'])
                        : setAssignTaxPro(true);
                }}
                className="cursor-pointer font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
            >
                edit
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();

                    setConfirmResend(original['user_id']);
                }}
                className="cursor-pointer font-bold material-symbols-outlined text-gray-400 hover:text-primary-400"
            >
                restart_alt
            </span>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDelete(original['user_id']);
                }}
                className="cursor-pointer material-icons text-gray-400 hover:text-red-400"
            >
                delete_forever
            </span>
        </div>
    );
};

export default function () {
    const [addClient, setAddClient] = useState(false);
    const [confirmExport, setConfirmExport] = useState(false);
    const { role, plan_type } = useAuthState();
    const { success: isExportEnabled } = useFeatureEnabled('Export');
    const [isSortEnabled, setisSortEnabled] = useState(false);
    const { clients, total, setFilterParams, setFilterString, filterParams, isLoading } =
        useClients();
    const [cofirmDeleteId, setConfirmDelete] = useState<string | undefined>();
    const [cofirmResendId, setConfirmResend] = useState<string | undefined>();
    const [assignTaxPro, setAssignTaxPro] = useState<string | undefined | boolean>();

    const queryClient = useQueryClient();
    useEffect(() => {
        queryClient.removeQueries('get-client-data');
        queryClient.removeQueries('1');
        queryClient.removeQueries('2');
        queryClient.removeQueries('3');
        queryClient.removeQueries('4');
    }, []);

    const data: Array<{
        user_id: string;
        irs_account_id: string;
        first_name: string;
        last_business_name: string;
        birth_dt: string;
        home_phone: string;
        email_address: string;
        address_line_1: string | null;
    }> = isLoading
        ? clientLoadingData
        : clients?.map((user: any) => {
              return { ...user, name: user.first_name + ' ' + user.last_business_name };
          }) || clientLoadingData;

    const navigate = useNavigate();

    //for the Tax prep navigation
    const navigateOnTaxPrep = (year: string, irs_account_id: string) => {
        if (year !== 'none') {
            navigate(`/taxprep-evaluate/${irs_account_id}/${year}`);
        }
    };

    const columns: Array<any> = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
                Cell: ({ row: { original } }: any) => (
                    <div className="flex items-center cursor-pointer hover:bg-gray-100">
                        <div
                            className="text-primary-500 hover:underline"
                            onClick={() => {
                                if (build.taxprep_module) {
                                    if (build.new_user_dashboard) {
                                        navigate(`/client/${original.irs_account_id}/profile/`);
                                    } else {
                                        navigate(`/tp-navigator/${original.irs_account_id}`);
                                    }
                                } else {
                                    redirectToEval(original.irs_account_id, 'personal_information');
                                }
                            }}
                        >
                            {original.name}
                        </div>
                        {build.arrow_icon && (
                            <span
                                style={{ fontSize: 18 }}
                                className="material-symbols-outlined text-primary-500 ml-1"
                            >
                                open_in_new
                            </span>
                        )}
                    </div>
                ),
            },
            {
                Header: 'Case ID',
                accessor: 'customer_code',
            },
            // {
            //     Header: 'Businesses',
            //     Cell: ({ row: { original } }: any) => (
            //         <BusinessCell
            //             redirect={(id) => redirectToEval(original.irs_account_id, id)}
            //             business={original.business}
            //         />
            //     ),
            //     sortable: false,
            // },
            {
                Header: build.tax_pro_table_label ? 'Tax Pro' : 'Tax Preparer',
                accessor: 'taxpro_user',
            },
            {
                Header: 'Last Login',
                accessor: 'u_last_logged_in',
                Cell: ({ value }: any) => {
                    if (value) return moment(value).format('MMM DD YYYY, hh:mm');
                    return '';
                },
            },
            {
                Header: 'Actions',
                Cell: ({ row: { original } }: any) => (
                    <ActionsCell
                        original={original}
                        setConfirmDelete={setConfirmDelete}
                        setConfirmResend={setConfirmResend}
                        setAssignTaxPro={setAssignTaxPro}
                    />
                ),
                sortable: false,
            },
        ],
        [],
    );

    const TaxPrepYearDropdown = ({ state }: { state: any }) => {
        // Split the string into an array
        const yearsArray = state.taxprep_years?.split(',');
        return (
            <div className="text-primary-500  flex flex-col">
                <div className="flex items-center cursor-pointer hover:bg-gray-100">
                    <div
                        className="text-primary-500 pl-2 hover:underline"
                        onClick={() => {
                            redirectToEval(state.irs_account_id, 'personal_information');
                        }}
                    >
                        Res
                    </div>
                    <span
                        style={{ fontSize: 18 }}
                        className="material-symbols-outlined text-primary-500 ml-1"
                    >
                        open_in_new
                    </span>
                </div>

                {yearsArray && (
                    <>
                        <div className="flex mt-1">
                            <select
                                onChange={(e: any) =>
                                    navigateOnTaxPrep(e.target.value, state?.irs_account_id)
                                }
                                className="border border-primary-500 rounded-full hover:underline outline-none bg-transparent text-sm pl-1"
                                name="cars"
                                id="cars"
                            >
                                <option value="none" selected disabled hidden>
                                    Prep
                                </option>
                                {yearsArray?.map((year: string) => {
                                    return (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                )}
            </div>
        );
    };

    if (build.taxprep_module) {
        columns.splice(4, 0, {
            Header: 'Service Type',
            accessor: '',
            Cell: ({ row: { original } }: any) => <TaxPrepYearDropdown state={original} />,
        });
    }

    const [redirect, setRedirect] = useState<string | null>();
    const redirectToEval = (irs_account_id: string, id: string) => {
        setRedirect(`${irs_account_id}?scroll=${'basic_information' + id}`);
    };

    const formDispatch = useFormDispatch();
    useEffect(() => {
        formDispatch({ type: 'CLEAR_FORM', payload: { data: undefined } });
    }, []);

    const isMobile = useIsMobile();
    const [menu, setMenu] = useState<boolean>(false);

    if (redirect) return <Navigate to={`/evaluate/${redirect}`} />;

    //to sort table columns wise
    const getSortString = (key: string) => {
        return `sort_by=${key}&order_by=${isSortEnabled ? 'ASC' : 'DESC'}`;
    };

    const sortByColumn = (props: HeaderGroup<object>) => {
        setisSortEnabled(!isSortEnabled);

        console.log('Header', props.Header);
        switch (props.Header) {
            case 'Name':
                setFilterString(getSortString('first_name'));
                break;
            case 'Case ID':
                setFilterString(getSortString('customer_code'));
                break;
            case 'Tax Pro':
            case 'Tax Preparer':
                setFilterString(getSortString('taxpro_user'));
                break;
            case 'Last Login':
                setFilterString(getSortString('u_last_logged_in'));
                break;
            default:
                console.error('Unknown header type: ' + props.Header);
        }
    };
    return (
        <>
            <div className={classNames('w-full h-screen flex', { 'flex-col': isMobile })}>
                {isMobile && <NavBar toggleMenu={() => setMenu(!menu)} />}
                {!isMobile ? (
                    <AdminMenu selected="client" />
                ) : (
                    menu && (
                        <div className="z-50 absolute h-screen">
                            <AdminMenu selected="client" close={() => setMenu(false)} />
                        </div>
                    )
                )}
                <div className="w-full flex h-screen flex-col items-center p-6 flex-grow">
                    <div className="w-full flex flex-col max-w-6xl max-h-full">
                        <div
                            className={classNames('flex w-full items-center py-2', {
                                'mt-5 flex-col': isMobile,
                            })}
                        >
                            <div className="font-title font-medium flex-grow">
                                {'CLIENT MANAGER'}
                            </div>
                            <div
                                className={classNames('flex w-auto items-center py-2', {
                                    'mt-5 flex-row': isMobile,
                                })}
                            >
                                {build.data_export && isExportEnabled && role === 'Admin' && (
                                    <Button
                                        onClick={() => setConfirmExport(true)}
                                        className="text-sm mr-2"
                                    >
                                        {'Download XLSX'}
                                    </Button>
                                )}
                                <Button onClick={() => setAddClient(true)} className="text-sm">
                                    {`ADD CLIENT`}
                                </Button>
                            </div>
                        </div>

                        <div className="flex w-full">
                            <InputGroupAdmin
                                id="search"
                                label="Search Text"
                                placeholder="Search Text"
                                value={filterParams?.search}
                                setValue={(value) =>
                                    setFilterParams((p) => ({ ...p, search: value }))
                                }
                                onEnter={(value) =>
                                    setFilterParams((p) => ({ ...p, search: value }))
                                }
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full mt-1"
                            />

                            <DropdownAdmin
                                isClearable
                                formData={{}}
                                id="account_type"
                                label="Account Type"
                                placeholder="Select Tax Preparer..."
                                options={[
                                    { label: 'Personal', value: 'Personal' },
                                    { label: 'Business', value: 'Business' },
                                ]}
                                value={filterParams?.account_type}
                                setValue={(value) =>
                                    setFilterParams((p) => ({ ...p, account_type: value }))
                                }
                                type={fieldTypes.DROPDOWN}
                                className="w-full mt-1"
                            />
                        </div>

                        <Table
                            data={data}
                            columns={columns}
                            sortByColumn={sortByColumn}
                            isSortEnabled={isSortEnabled}
                            // onRowClick={({ irs_account_id }) => redirectToEval(irs_account_id)}
                        />

                        <PageManager
                            total={total}
                            offset={filterParams?.offset}
                            setOffset={(offset) => setFilterParams((p) => ({ ...p, offset }))}
                        />
                    </div>
                </div>
            </div>

            <Modal close={() => setAddClient(false)} isOpen={addClient}>
                <AddNewClient
                    close={() => setAddClient(false)}
                    plan_type={plan_type!}
                    length={data.length >= 4 ? true : false}
                />
            </Modal>

            <Modal close={() => setConfirmDelete(undefined)} isOpen={!!cofirmDeleteId}>
                <DeleteClient
                    user={data.find(({ user_id }) => user_id === cofirmDeleteId)}
                    close={() => setConfirmDelete(undefined)}
                />
            </Modal>

            <Modal close={() => setConfirmResend(undefined)} isOpen={!!cofirmResendId}>
                <ResendCredentials
                    role="Client"
                    user={data.find(({ user_id }) => user_id === cofirmResendId)}
                    close={() => setConfirmResend(undefined)}
                />
            </Modal>

            <Modal close={() => setAssignTaxPro(undefined)} isOpen={!!assignTaxPro}>
                <AssignTaxPro
                    user={data.find(({ user_id }) => user_id === assignTaxPro || '')}
                    close={() => setAssignTaxPro(undefined)}
                />
            </Modal>

            <Modal close={() => setConfirmExport(false)} isOpen={!!confirmExport}>
                <ConfirmExport request_type="Client" close={() => setConfirmExport(false)} />
            </Modal>
        </>
    );
}
