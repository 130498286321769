import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertForiegnAcc = (
    year: string,
    { foreign_account_data, meta_data, irs_account_id, id }: any,
) => {
    const regEx = /^new/;
    const data = foreign_account_data
        ?.map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: irs_account_id, taxprep_account_id: id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/foreignAccountByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data: data ? data : [],
        taxprep_year: year,
    });
};

const f_accountsForm: FormInterface = [
    { type: fieldTypes.SEPERATOR, heading: 'Foreign Accounts' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'foreign_county_name',
        label: "List the name's of the foriegn countries where the financial accounts located?",
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.RADIO,
        id: 'grantor_transferor',
        label: 'During 2022, did you recieved distribution from, or where you the granter of, or transfer to, a foriegn trust?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
];

const f_accountsSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'foreign_account_data',
        label: 'Do you Foreign Accounts?',
        header: 'Foreign Accounts Data',
        displayColumns: [
            {
                name: 'Foriegn country',
                key: 'foreign_county_name',
            },
            {
                name: 'Grantor Transferor',
                key: 'grantor_transferor',
            },
        ],
        formStructure: f_accountsForm,
    },
];

export const page: PageInputInterface = {
    address: 'foreign_account_data',
    name: 'Foreign Accounts Information',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: f_accountsSecForm,
    save: upsertForiegnAcc,
};
