import { useEffect } from 'react';
import { getAccessToken, helloWorld } from '../api/deploy';

const DeploymentManager: React.FC = () => {
    useEffect(() => {
        helloWorld().then((data) => {});

        getAccessToken().then((data) => {});
    });
    return <div>Deployment Manager</div>;
};

export default DeploymentManager;
