import { upsertBankAccounts } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const businessBankAccounts: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'type_of_account',
        label: 'Type',
        placeholder: 'Savings',
        options: [
            { value: 'Cash', label: 'Cash' },
            { value: 'Checking', label: 'Checking' },
            { value: 'Savings', label: 'Savings' },
            { value: 'Moneymarket/CD', label: 'Moneymarket/CD' },
            { value: 'Online', label: 'Online' },
            { value: 'Stored Value', label: 'Stored Value' },
            { value: 'Card', label: 'Card' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Bank Name',
        placeholder: 'Chase Bank',
    },

    { type: fieldTypes.SEPERATOR, heading: 'Company Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        area: true,
        span: 2,
        label: 'Bank Address Street',
        placeholder: `15498 Plantation Oaks Drive`,
    },

    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_city',
        label: 'City',
        placeholder: `Tampa Bay`,
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'company_state',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_zip',
        label: 'Zip',
        placeholder: '32003',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Account Number',
        placeholder: '12-3456789',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'account_balance',
        label: 'Account Balance',
        placeholder: '12-3456789',
    },
];

const form: FormInterface = [
    // {
    //     type: fieldTypes.RADIO,
    //     crossroadVariant: true,
    //     id: 'cash_on_hand',
    //     label: 'Do you have Business cash on Hand?',
    //     options: [
    //         { value: false, label: 'No' },
    //         { value: true, label: 'Yes' },
    //     ],
    //     span: 2,
    //     branches: {
    //         paths: [
    //             { value: 'No', form: [] },
    //             {
    //                 value: 'Yes',
    //                 form: [
    //                     {
    //                         type: fieldTypes.TEXT_INPUT,
    //                         id: 'cash_on_hand_value',
    //                         label: 'Enter Cash Balance',
    //                         placeholder: '$12,000',
    //                     },
    //                 ],
    //             },
    //         ],
    //     },
    // },
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_account_data',
        label: 'How many bank accounts does your business has?',
        header: 'Bank Account Details',
        formStructure: businessBankAccounts,
        displayColumns: [
            {
                name: 'Bank Name',
                key: 'company_name',
            },
            {
                name: 'Type',
                key: 'type_of_account',
            },
            {
                name: 'Account Number',
                key: 'account_number',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_bank_accounts',
    name: 'Business Bank Accounts',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertBankAccounts,
};
