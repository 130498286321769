import { upsertPersonalContact } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { phone, ssn } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const virtualCurrencyForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'full_name',
        label: 'Full Name',
        placeholder: 'Bitcoin',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'title',
        label: 'Title',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Home Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'home_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'home_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'home_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'home_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        id: 'deposits_payroll_tax_ind',
        label: 'Responsible for Depositing Payroll Taxes',
        options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ssn',
        label: 'Taxpayer Identification Number',
        placeholder: 'johndoe@hotmail.com',
        formatFunction: ssn,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'home_phone',
        label: 'Home Telephone',
        placeholder: 'johndoe@hotmail.com',
        formatFunction: phone,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'work_cell_phone',
        label: 'Work/Cell Phone',
        placeholder: 'johndoe@hotmail.com',
        formatFunction: phone,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ownership_shares_interest',
        label: 'Ownership Percentage & Shares or Interest',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'annual_salary_draw',
        label: 'Annual Salary/Draw',
        placeholder: 'johndoe@hotmail.com',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_personal_contact_data',
        label: 'Does your business have any Personnel and contacts?',
        header: 'Personnel Contact Information',
        formStructure: virtualCurrencyForm,
        displayColumns: [
            {
                name: 'Full Name',
                key: 'full_name',
            },
            {
                name: 'Title',
                key: 'title',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'personnel_and_contacts',
    name: 'Business Personnel and Contacts',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertPersonalContact,
};
