import { decodeJwtToken } from '../../utils/helpers';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import useSignUpMutation from '../../api/hooks/useSignUpMutation ';
import useSsoSignInMutation from '../../api/hooks/useSsoSignInMutation';
import axios from 'axios';
import g_logo from '../../assets/google-icon.png';
const GoogleSignIn = ({
    isMulti,
    method,
}: {
    isMulti?: boolean;
    method: 'sign-in' | 'sign-up';
}) => {
    const { registerUser: sign_up } = useSignUpMutation({ type: 'sso' });
    const { signInUserWithSso } = useSsoSignInMutation();

    const login = useGoogleLogin({
        onSuccess: async (respose) => {
            try {
                const { data } = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${respose.access_token}`,
                    },
                });

                if (data?.email) {
                    if (method === 'sign-in') {
                        signInUserWithSso(data.email);
                    } else {
                        sign_up(data.email);
                    }
                    console.log('Google fetch error', { data });
                }

                console.log(data);
            } catch (err) {
                console.log(err);
            }
        },
    });

    const handleLoginClick = () => {
        login(); // Call the login function here
    };

    return (
        <>
            <button
                style={{ borderRadius: '4px' }}
                className="ml-auto flex justify-center items-center w-full py-2 px-4 font-medium text-gray-700 border-gray-300 border pointer hover:bg-gray-100"
                onClick={handleLoginClick}
            >
                <img src={g_logo} className="w-5 h-5 mr-2" alt="Google Logo" />
                <div className="pr-2">
                    {method === 'sign-in' ? `Sign in with Google` : `Sign up with Google`}
                </div>
            </button>
        </>
    );

    // return { isLoading, userData, error, GoogleSignInComponent };
};

export default GoogleSignIn;
