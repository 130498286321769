import classNames from 'classnames';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useAuthDispatch, useAuthState } from '../../context/auth';
import { useQueryClient } from 'react-query';
import { build } from '../../config';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
    selected,
    close,
    blockRoutes,
}: {
    selected: string;
    close?: () => void;
    blockRoutes?: boolean;
}) {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { success, role } = useAuthState();
    const authDispatch = useAuthDispatch();
    const queryClient = useQueryClient();

    const {} = useParams();
    if (!blockRoutes && !success) return <Navigate to="/login" />;

    return (
        <div
            className={classNames(
                'bg-gray-800 h-full w-56 sticky top-0  text-white flex flex-col items-center pb-10',
                { 'pt-10': !close },
            )}
        >
            <div className="z-30 h-10 w-full flex justify-start items-center">
                {close && (
                    <span onClick={close} className="material-symbols-outlined text-white ml-3">
                        menu
                    </span>
                )}
            </div>

            {!close && (
                <div className="px-3">
                    <img
                        src={build.logo_white}
                        className={classNames('h-12 z-20')}
                        style={{ padding: 1 }}
                        alt=""
                    />
                </div>
            )}

            <div className="flex-grow flex flex-col w-full mt-8">
                {!blockRoutes && (
                    <>
                        {role !== 'Super Admin' && (
                            <Link to="/client">
                                <div
                                    className={classNames('p-3 cursor-pointer', {
                                        'bg-gray-700': selected === 'client',
                                    })}
                                >
                                    CLIENTS
                                </div>
                            </Link>
                        )}

                        {role === 'Admin' && (
                            <Link to="/taxprep">
                                <div
                                    className={classNames('p-3 cursor-pointer', {
                                        'bg-gray-700': selected === 'taxprep',
                                    })}
                                >
                                    TAX PRO
                                </div>
                            </Link>
                        )}
                        {role === 'Super Admin' && (
                            <>
                                <Link to="/superAdmin">
                                    <div
                                        className={classNames('p-3 cursor-pointer', {
                                            'bg-gray-700': selected === 'superAdmin',
                                        })}
                                    >
                                        CLIENTS
                                    </div>
                                </Link>
                            </>
                        )}
                        {build.admin_list && role === 'Admin' && (
                            <>
                                <Link to="/admin">
                                    <div
                                        className={classNames('p-3 cursor-pointer', {
                                            'bg-gray-700': selected === 'admin',
                                        })}
                                    >
                                        ADMIN
                                    </div>
                                </Link>
                            </>
                        )}

                        {/* <Link to="/test/tax-prep/dependent_data/2021">
                    <div
                    className={classNames('p-3 cursor-pointer', {
                        'bg-gray-700': selected === 'client',
                    })}
                    >
                    TAX PREP
                    </div>
                </Link> */}

                        <div className="flex-grow"></div>
                        {build.notifications && (
                            <Link to="/notifications">
                                <div
                                    className={classNames('p-3 cursor-pointer', {
                                        'bg-gray-700': selected === 'notifications',
                                    })}
                                >
                                    NOTIFICATIONS
                                </div>
                            </Link>
                        )}
                        <Link to="/a/settings">
                            <div
                                className={classNames('p-3 cursor-pointer', {
                                    'bg-gray-700': selected === 'settings',
                                })}
                            >
                                SETTINGS
                            </div>
                        </Link>

                        <div
                            onClick={() => {
                                if (isAuthenticated) {
                                    instance
                                        .logoutPopup()
                                        .catch((e) => {
                                            console.error(e);
                                        })
                                        .then(() => {
                                            queryClient.invalidateQueries();
                                            authDispatch('LOGOUT', null);
                                        });
                                } else {
                                    queryClient.invalidateQueries();
                                    authDispatch('LOGOUT', null);
                                }
                            }}
                            className="p-3 cursor-pointer"
                        >
                            LOGOUT
                        </div>
                    </>
                )}
                {/* blocked routes end */}
            </div>
        </div>
    );
}
