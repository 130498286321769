/* eslint-disable import/no-anonymous-default-export */
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { transcriptApi } from '../../../../api/transcripts';
import { useQuery, useQueryClient } from 'react-query';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const UserAllTranscripts: React.FC<{ transcriptTab: number }> = ({ transcriptTab }) => {
    let { irs_account_id, type } = useParams();
    const [htmlUrl, setHtmlUrl] = useState<string>('');
    const [creatingPdf, setCreatingPdf] = useState<boolean>(false);
    const [errMessage, setErrMessage] = useState<string | null>(null);
    const [htmlErrMessage, setHtmlErrMessage] = useState<string | null>(null);
    const [versionId, setVersionId] = useState<string | undefined>('');
    const [transcriptType, setTranscriptType] = useState('');
    const [headingData, setHeadingData] = useState<any>({ period: '', date: '' });
    const [transcriptVersions, setTranscriptVersions] = useState<string[]>([]);
    const queryClient = useQueryClient();
    const [decodedHtml, setDecodedHtml] = useState('');
    const [base64Html, setBase64Html] = useState('');
    const formatDate = (dateString: string) => {
        return dayjs(dateString).format('MM-DD-YYYY');
    };

    const { data: transcriptsData, isLoading: transcriptsLoading } = useQuery(
        ['allTranscripts', irs_account_id, type],
        () => fetchAllTranscriptsData(irs_account_id),
        {
            enabled: !!irs_account_id,
        },
    );
    const {
        data: scriptsHtml,
        isLoading: scriptsHtmlLoading,
        refetch: refetchHtml,
    } = useQuery(['allTranscriptsHtml', versionId], () => fetchHtml(versionId), {
        onSuccess: (data: any) => {
            console.log(data, 'onSuccess Html');
            setHeadingData({ period: data.period, date: formatDate(data.download_date) });
            setTranscriptType(data.transcript_type);
            setBase64Html(data.file_content);
        },
        enabled: !!versionId,
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        if (transcriptsData) {
            const arr = transcriptsData
                .flatMap((item: any) => item.value)
                .map((item: any) => item.version_id);
            setVersionId(arr[0]);
            queryClient.invalidateQueries(['allTranscriptsHtml', arr[0]]); // same issue as given on code 141
            setTranscriptVersions(arr);
        }
    }, [transcriptsData, transcriptTab]); // or else when tab is changned the html section will be empty
    console.log(transcriptTab, 'transcriptTab');
    useEffect(() => {
        const decoded = atob(base64Html);
        setDecodedHtml(decoded);
        const htmlBlob = new Blob([decoded], { type: 'text/html' });
        const url = URL.createObjectURL(htmlBlob);
        setHtmlUrl(url);

        // Clean up the URL object when the component unmounts
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [base64Html]);

    const downloadPdf = async () => {
        setCreatingPdf(true);
        // Create an iframe for off-screen rendering
        const iframe: any = document.createElement('iframe');
        document.body.appendChild(iframe);

        // Write the HTML content to the iframe
        iframe.style.position = 'absolute';
        iframe.style.top = '-9999px';
        iframe.style.left = '-9999px';
        const iframeDoc = iframe.contentDocument || iframe?.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(decodedHtml);
        iframeDoc.close();

        // Wait for the iframe content to be fully loaded and styled
        iframe.onload = async () => {
            const element = iframeDoc.body;

            // Style the element to ensure proper formatting
            element.style.padding = '25px';
            element.style.fontSize = '16px'; // Adjust as needed
            element.style.width = '200mm'; // A4 width minus padding in mm to prevent wrapping issues
            element.style.marginBottom = '100px'; // Margin at the bottom for padding
            element.style.marginTop = '20px';
            // Use a higher scale for better quality, adjust if needed for performance
            const canvas = await html2canvas(element, { scale: 1, useCORS: true });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // Split the image into multiple pages if necessary
            const imgProps = pdf.getImageProperties(imgData);
            const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

            let heightLeft = imgHeight;
            let position = 0;

            // Add the first page
            pdf.addImage(imgData, 'PNG', 10, position + 10, pdfWidth - 20, imgHeight);
            heightLeft -= pdfHeight;

            // Add more pages if the content is longer than one page
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position + 10, pdfWidth - 20, imgHeight);
                heightLeft -= pdfHeight;
            }

            pdf.save('transcripts.pdf');

            // Clean up
            setCreatingPdf(false);
            document.body.removeChild(iframe);
        };
    };

    const fetchAllTranscriptsData = async (irs_account_id: string | undefined) => {
        try {
            const response = await transcriptApi.get(
                `/getAllTranscriptsData?irs_account_id=${irs_account_id}&taxpayer_type=${type}`,
            );
            console.log(response, 'response');
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.data.error) {
                setErrMessage(err.response.data.error);
            }
            console.log('Error fetching taxometric data:', err.response.data.error);
            throw err;
        }
    };

    const fetchHtml = async (version_id: string | undefined) => {
        try {
            const response = await transcriptApi.get(
                `/downloadTranscriptData?version_id=${version_id}&taxpayer_type=${type}`,
            );
            console.log(response, 'html response');
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.data.error) {
                setHtmlErrMessage(err.response.data.error);
            }
            console.log('Error fetching html data:', err.response.data);
            throw err;
        }
    };
    function handleTranscriptVersionChange(action: 'prev' | 'next') {
        const N = transcriptVersions.length;
        const index = transcriptVersions.indexOf(versionId || '');

        let newIndex;

        if (action === 'prev') {
            newIndex = (index - 1 + N) % N; // (0 - 1 + 3) % 3 , so its goes to the last element
        } else {
            newIndex = (index + 1) % N; // (2 + 1)% 3 , so it goes to the first element
        }
        const newVersionId: string = transcriptVersions[newIndex];
        setVersionId(newVersionId);

        queryClient.invalidateQueries(['allTranscriptsHtml', newVersionId]);
    }

    function handleClick(versId: string) {
        setVersionId(versId);
        queryClient.invalidateQueries(['allTranscriptsHtml', versId]);
        // When updating the versionId state and invalidating the query in the same function,
        // make sure to add the new versionId value directly for invalidating the query.
        // Don't use the versionId state directly, as it will still have the old value,
        // leading to incorrect behavior
    }

    return (
        <>
            <div className="p-6 pb-0 flex flex-col gap-5  border-0 border-[#CCCCCC]  w-full">
                <span className="font-semibold text-xl ">All Transcripts</span>
                <>
                    {transcriptsLoading ? (
                        <div>Loading...</div>
                    ) : errMessage ? (
                        <div>{errMessage}</div>
                    ) : transcriptsData ? (
                        <div className="grid grid-cols-11 gap-5 w-full">
                            <div className="col-span-3">
                                <div
                                    style={{ minHeight: '60vh', borderWidth: '1.5px' }}
                                    className="pt-5  flex flex-col gap-5 border h-full border-custom-gray-100  "
                                >
                                    {transcriptsData &&
                                        transcriptsData?.map((item: any, index: any) => (
                                            <div
                                                className="flex gap-4 flex-col group "
                                                tabIndex={0}
                                            >
                                                <div className="flex gap-2 items-center cursor-pointer pl-5 pr-9">
                                                    <span className="font-light text-base">
                                                        {item.transcript_type}
                                                    </span>
                                                    <span
                                                        style={{ fontSize: '22px' }}
                                                        className="material-symbols-outlined border-none text-black text-md font-light transform group-hover:rotate-180"
                                                    >
                                                        expand_more
                                                    </span>
                                                </div>
                                                <div className="hidden group-hover:block">
                                                    <div className=" flex flex-col ">
                                                        <div className="  grid grid-cols-3 px-5 gap-1 mb-2">
                                                            <span>Period</span>
                                                            <span className="col-span-2">
                                                                Download Date
                                                            </span>
                                                        </div>
                                                        {item?.value?.map(
                                                            (value: any, index: any) => (
                                                                <div
                                                                    key={index}
                                                                    className=" group border-b border-custom-gray-100 grid grid-cols-3 cursor-pointer p-1 items-center px-5 gap-1 hover:bg-custom-gray-50"
                                                                >
                                                                    <span>{value.period}</span>
                                                                    <span>
                                                                        {formatDate(
                                                                            value.download_date,
                                                                        )}
                                                                    </span>
                                                                    <div className="flex justify-end items-center">
                                                                        <span
                                                                            onClick={() =>
                                                                                handleClick(
                                                                                    value.version_id,
                                                                                    // value.period,
                                                                                    // value.download_date,
                                                                                    // item.transcript_type,
                                                                                )
                                                                            }
                                                                            style={{
                                                                                fontSize: '19px',
                                                                            }}
                                                                            className="material-symbols-outlined   bg-custom-green-400 rounded-full hover:bg-custom-green-500 z-50 text-white cursor-pointer "
                                                                        >
                                                                            chevron_right
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="col-span-8 min-h">
                                <>
                                    {scriptsHtmlLoading ? (
                                        <div>Loading...</div>
                                    ) : htmlErrMessage ? (
                                        <div>Error: {htmlErrMessage}</div>
                                    ) : scriptsHtml ? (
                                        <>
                                            <div className="flex items-center justify-between w-full px-5 py-3 bg-custom-gray-700">
                                                <span className="text-white text-sm">
                                                    {headingData?.date}
                                                </span>
                                                <span className="text-white font-semibold">
                                                    {`${transcriptType} - ${headingData?.period}`}
                                                </span>
                                                <div className="flex gap-5 items-center">
                                                    {decodedHtml && (
                                                        <span
                                                            onClick={() => downloadPdf()}
                                                            className={`material-symbols-outlined text-white cursor-pointer ${
                                                                creatingPdf ? 'rotate' : ''
                                                            }`}
                                                        >
                                                            {creatingPdf ? 'refresh' : 'download'}
                                                        </span>
                                                    )}
                                                    <span
                                                        onClick={() =>
                                                            handleTranscriptVersionChange('prev')
                                                        }
                                                        className="material-symbols-outlined cursor-pointer text-white transform rotate-90"
                                                    >
                                                        expand_circle_down
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            handleTranscriptVersionChange('next')
                                                        }
                                                        className="material-symbols-outlined cursor-pointer text-white transform  -rotate-90"
                                                    >
                                                        expand_circle_down
                                                    </span>
                                                </div>
                                            </div>
                                            <iframe
                                                src={htmlUrl}
                                                className="border border-custom-gray-100 w-full h-full"
                                                title="description"
                                            ></iframe>
                                        </>
                                    ) : (
                                        <div className="w-full h-full flex justify-center items-center">
                                            Select transcript to be viewed or to download!
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                    ) : errMessage ? (
                        <div>{errMessage}</div>
                    ) : (
                        <div>Error: Failed to fetch transcripts data</div>
                    )}
                </>
            </div>
        </>
    );
};
