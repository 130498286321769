import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertData = (
    year: string,
    { taxprep_account_id, cost_of_goods_sold_details, meta_data }: any,
) => {
    const regEx = /^new/;
    const data = cost_of_goods_sold_details
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: meta_data.irs_account_id,
                    taxprep_account_id: taxprep_account_id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/businessCostOfGoodsSoldByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data,
        taxprep_year: year,
    });
};

const b_p4Form: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'closing_inventory_method',
        placeholder: 'Select',
        label: 'Which method do you use to value closing Inventory?',
        options: [
            { value: '1', label: 'Cost' },
            { value: '2', label: 'Lower of Cost or Market' },
            { value: '3', label: 'Other' },
        ],
        span: 2,
        branches: {
            inline: true,
            paths: [
                {
                    value: '3',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'other_method_explanation',
                            label: 'Give Eplaination',
                            placeholder: 'john',
                            span: 2,
                        },
                    ],
                },
            ],
        },
    },
    { type: fieldTypes.SEPERATOR },

    {
        type: fieldTypes.RADIO,
        id: 'change_in_inventory',
        label: 'Was there any change in determining quantities, costs, or valuations between opening and closing inventory?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
        span: 2,
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'inventory_explanation',
                            label: 'Give Eplaination',
                            placeholder: 'john',
                            span: 2,
                        },
                    ],
                },
            ],
        },
    },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        id: 'inventory_year',
        label: 'What was your inventory at the beginning of the year?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'personal_use_cost',
        label: 'Purchases less cost of items withdrawn for personal use ($)',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'labor_cost',
        label: 'Cost of labor. Do not include any amounts paid to yourself  ($)',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'materials_and_supplies',
        label: 'Materials and supplies ($)',
        placeholder: 'john',
    },

    {
        type: fieldTypes.TEXT_INPUT,
        id: 'other_cost',
        label: 'Other costs ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'inventory_end_year',
        label: 'Inventory at end of year ($)',
        placeholder: 'john',
    },
];

const b_p4SecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'cost_of_goods_sold_details',
        label: 'Do you have Cost of Goods Sold?',
        header: 'Cost of Goods Sold',
        displayColumns: [
            {
                name: 'Closing inventory method',
                key: 'closing_inventory_method',
            },
            {
                name: 'Personal use cost',
                key: 'personal_use_cost',
            },
        ],
        formStructure: b_p4Form,
    },
];

export const page: PageInputInterface = {
    address: 'cost_of_goods_sold_details',
    name: 'Cost of Goods Sold',
    sectionId: enumSections.BUSINESS_INFORMATION,

    formStructure: b_p4SecForm,
    save: upsertData,
};
