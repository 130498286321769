import { upsertVirtualCurrency } from '../../api/business';
import { build } from '../../config';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const virtualCurrencyFormNew: FormInterface = [
    // {
    //     type: fieldTypes.DROPDOWN,
    //     id: 'type_of_virtual_currency',
    //     label: 'Currency Name',
    //     placeholder: 'Bitcoin',
    //     options: [
    //         { label: 'Bitcoin', value: 'Bitcoin' },
    //         { label: 'Ethereum', value: 'Ethereum' },
    //         { label: 'Litecoin', value: 'Litecoin' },
    //         { label: 'Ripple', value: 'Ripple' },
    //         { label: 'Other', value: 'Other' },
    //     ],
    // },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'description',
        label: 'Description',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'number_of_units',
        label: 'Number of Units',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_of_virtual_currency',
        label: 'Digital Asset Location',
        placeholder: 'Metamask',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Account Number',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'name_of_virtual_currency',
        label: 'Currency Wallet',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'email_address_of_virtual_currency',
        label: 'Digital Asset Address/Email',
        span: 2,
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_of_virtual_currency',
        label: 'Virtual Curency Location',
        placeholder: 'Metamask',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Virtual Currency Amount',
        placeholder: '0.4',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'federal_and_state_tax_rate_pct',
        label: 'Value in USD',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'who_has_access_to_private_key',
        label: 'Name of individuals who have access to digital wallets',
        placeholder: 'Names...',
        span: 2,
    },
];
const virtualCurrencyFormOld: FormInterface = [
    {
        type: fieldTypes.DROPDOWN,
        id: 'type_of_virtual_currency',
        label: 'Currency Name',
        placeholder: 'Bitcoin',
        options: [
            { label: 'Bitcoin', value: 'Bitcoin' },
            { label: 'Ethereum', value: 'Ethereum' },
            { label: 'Litecoin', value: 'Litecoin' },
            { label: 'Ripple', value: 'Ripple' },
            { label: 'Other', value: 'Other' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'name_of_virtual_currency',
        label: 'Currency Wallet',
        placeholder: '0xf253fc2ca37c078436d07fb75e5a76a649892172',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'email_address_of_virtual_currency',
        label: 'Email',
        span: 2,
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_of_virtual_currency',
        label: 'Virtual Curency Location',
        placeholder: 'Metamask',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Virtual Currency Amount',
        placeholder: '0.4',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'federal_and_state_tax_rate_pct',
        label: 'Value in USD',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'who_has_access_to_private_key',
        label: 'Name of individuals who have access to digital wallets',
        placeholder: 'Names...',
        span: 2,
    },
];
const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_virtual_currency_data',
        label: 'Does your business own any virtual currency?',
        header: 'Virtual Currency Information',
        formStructure: build.virtual_currency_change
            ? virtualCurrencyFormNew
            : virtualCurrencyFormOld,
        displayColumns: [
            {
                name: 'Name',
                key: 'type_of_virtual_currency',
            },
            {
                name: 'Amount',
                key: 'current_value',
            },
            {
                name: 'Value in USD',
                key: 'federal_and_state_tax_rate_pct',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_virtual_currency',
    name: 'Business Virtual Currency Information',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertVirtualCurrency,
};
