import { upsertBusinessData } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { ein, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const businessBasicform: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Basic Business Information' },
    {
        type: fieldTypes.RADIO,
        id: 'is_sole_proprietorship',
        label: 'Is the business sole proprietorship?',
        options: [
            { value: 'no', label: 'No' },
            { value: 'yes', label: 'Yes' },
        ],
        span: 2,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'name',
        label: 'Name of Business',
        placeholder: 'Tony Drug Store',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'business_dba_name',
        label: 'Trade Name',
        placeholder: 'Tony Drug Business LLC.',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'business_type',
        label: 'Type of Business',
        placeholder: 'Pharmaceutical',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'beneficiary_ein',
        label: 'EIN',
        placeholder: '12-3456789',
        formatFunction: ein,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Business Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'address',
        label: 'Address Line 1',
        span: 2,
        area: true,
        placeholder: `15498 Plantation Oaks Drive`,
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_website',
        label: 'Business Website',
        placeholder: 'tonydrugs.us',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'work_phone',
        label: 'Business Phone Number',
        placeholder: '(415)-555-0127',
        formatFunction: phone,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'number_of_employees',
        label: 'Number of Employees',
        placeholder: '35',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'frequency_of_tax_deposits',
        label: 'Freq. of Tax Deposits',
        placeholder: 'Quaterly',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'monthly_gross_payroll',
        label: 'Avg. Gross Monthly Payroll',
        placeholder: '$80,000',
    },
    { type: fieldTypes.SEPERATOR },
    // {
    //     type: fieldTypes.RADIO,
    //     id: 'federal_contractor_ind',
    //     label: 'Is this business a Federal Contractor?',
    //     options: [
    //         { value: 'No', label: 'No' },
    //         { value: 'Yes', label: 'Yes' },
    //     ],
    // },
    {
        type: fieldTypes.CALENDAR,
        id: 'date_incorporated_established',
        label: 'Date Incorporated / Established',
        placeholder: '',
    },

    { type: fieldTypes.TERMINAL },
];

export const page: PageInputInterface = {
    address: 'basic_information',
    name: 'Basic Business Information',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: businessBasicform,

    save: upsertBusinessData,
};
