import { useState, useEffect } from 'react';
import { fieldTypes } from '../utils/enums';
import { useLocation, useNavigate } from 'react-router-dom';
import InputGroup from '../components/client/InputGroup';
import Button from '../components/basic/Button';
import classNames from 'classnames';
import { build } from '../config';
import { useIsAuthenticated } from '@azure/msal-react';
import useSignUpMutation from '../api/hooks/useSignUpMutation ';
import GoogleSignIn from '../components/basic/GoogleSignIn';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stateManager/rootReducers';
import { SignInButton } from '../components/basic/MicrosoftSignIn';
import { useFeatureEnabled } from '../utils/helpers';
import { setAuthPage } from '../stateManager/authSlice';

const SignUpScreen: React.FC = () => {
    const [organizationEmail, setOrganizationEmail] = useState<string>('');
    const ms_login_feature = useFeatureEnabled('Microsoft Login');
    const authState = useSelector((state: RootState) => state.auth);
    const { registerUser: sign_up, isLoading } = useSignUpMutation({ type: 'manual' });
    // Function to register a user
    const registerUser = () => {
        sign_up(organizationEmail);
    };
    const dispatch = useDispatch();

    //set the auth page that the process is running from login page for futher usage based on this data
    useEffect(() => {
        dispatch(setAuthPage({ authPage: 'sign-up' }));
    }, []);

    return (
        <div className="w-full h-screen flex">
            <div
                className="flex-grow bg-primary-200 max-w-xs opacity-10"
                style={{
                    backgroundImage: 'url(/menu_pattern.png)',
                    backgroundSize: '200px 200px',
                }}
            />

            <div className="flex-grow flex justify-center items-center" style={{ flexGrow: 2 }}>
                <div className="max-w-md w-full flex flex-col items-center p-3">
                    <div className="font-title flex bg-white justify-center items-center z-20">
                        <img
                            src={build.logo_dark}
                            className={classNames('h-16 z-20 bg-white  mb-8 ')}
                            style={{ padding: 1 }}
                        />
                    </div>
                    {/* {errors from sso} */}
                    {authState?.authError && (
                        <div className="w-full bg-red-100 text-red-600 font-bold text-xs mb-2 px-3">
                            {authState?.authError}
                        </div>
                    )}
                    {/* Render SSO components */}
                    {build.sso_login && (
                        <>
                            <div className="flex flex-col space-y-2 w-full ">
                                {ms_login_feature.success && <SignInButton method="sign-up" />}
                                <GoogleSignIn method="sign-up" />
                            </div>
                            <div className="relative w-full py-3 flex justify-center">
                                <div
                                    style={{ top: '50%' }}
                                    className="absolute left-0 right-0 h-px bg-gray-300 w-full"
                                />
                                <div className="bg-white z-10 p-3 text-gray-700">OR</div>
                            </div>
                        </>
                    )}
                    {/* Render email input and register button */}
                    {
                        <>
                            <InputGroup
                                id="organization_email"
                                label="Email"
                                placeholder=""
                                value={organizationEmail}
                                setValue={(value) => setOrganizationEmail(value)}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full"
                            />

                            <Button
                                isLoading={authState?.isLoading || isLoading}
                                onClick={registerUser}
                                className="text-center w-full mt-4"
                            >
                                {'NEXT'}
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default SignUpScreen;
