import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertBussinessExpense = (
    year: string,
    { taxprep_account_id, expense_data, meta_data }: any,
) => {
    const regEx = /^new/;
    const data = expense_data
        ?.map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: meta_data.irs_account_id,
                    taxprep_account_id: taxprep_account_id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/businessExpensesDetailsByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data,
        taxprep_year: year,
    });
};

const b_expensesForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'advertising',
        label: 'Advertising ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.RADIO,
        id: 'financial_interest',
        label: 'Do you have a car or truck Expenses?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.AMOUNT_INPUT,
                            id: 'amount',
                            label: 'Enter Amount ($)',
                            placeholder: 'john',
                        },
                        {
                            type: fieldTypes.AMOUNT_INPUT,
                            id: 'commissions_fees',
                            label: 'Commisions and fees ($)',
                            placeholder: 'john',
                        },
                        {
                            type: fieldTypes.AMOUNT_INPUT,
                            id: 'contract_labor',
                            label: 'Contract labor ($)',
                            placeholder: 'john',
                        },
                        {
                            type: fieldTypes.AMOUNT_INPUT,
                            id: 'office_expense',
                            label: 'Office expenses ($)',
                            placeholder: 'john',
                        },
                        {
                            type: fieldTypes.AMOUNT_INPUT,
                            id: 'pension_profit_sharing',
                            label: 'Pension and profit-sharing plans ($)',
                            placeholder: 'john',
                        },
                    ],
                },
            ],
        },
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'repairs_maintenance',
        placeholder: 'Select',
        label: 'Repairs and maintenance',
        options: [
            { value: '1', label: 'Vehichles, machines and equipment' },
            { value: '1', label: 'Other business property' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.AMOUNT_INPUT,
                            id: 'repair_amount',
                            label: 'Enter Amount  ($)',
                            placeholder: 'john',
                        },
                    ],
                },
            ],
        },
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'supplies',
        label: 'Supplies ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'taxes_licenses',
        label: 'Taxes and licenses  ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'utilites',
        label: 'Utilities   ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'wages',
        label: 'Wages   ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'other_expenses',
        label: 'Other expenses  ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'insurance',
        label: 'Insurance (other than health)  ($)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'travel_meals',
        placeholder: 'Select',
        label: 'Travel and meals',
        options: [
            { value: '1', label: 'Travel' },
            { value: '2', label: 'Deductible' },
        ],
        branches: {
            inline: true,
            paths: [
                {
                    value: '1',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'travel _meals_amount',
                            label: 'Enter Amount  ($)',
                            placeholder: 'john',
                        },
                    ],
                },
                {
                    value: '2',
                    form: [
                        {
                            type: fieldTypes.TEXT_INPUT,
                            id: 'travel _meals_amount',
                            label: 'Enter Amount  ($)',
                            placeholder: 'john',
                        },
                    ],
                },
            ],
        },
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'interest',
        placeholder: 'Select',
        label: 'Interest',
        options: [
            { value: '1', label: 'Mortgage (paid to banks, etc' },
            { value: '0', label: 'Other' },
        ],
    },
];

const b_expensesSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'expense_data',
        label: 'Do you have Expenses?',
        header: 'Expenses',
        displayColumns: [
            {
                name: 'Advertising ($)',
                key: 'advertising',
            },
            {
                name: 'car or truck Expenses?',
                key: 'financial_interest',
            },
        ],
        formStructure: b_expensesForm,
    },
];

export const page: PageInputInterface = {
    address: 'expense_data',
    name: 'Expenses',
    sectionId: enumSections.BUSINESS_INFORMATION,

    formStructure: b_expensesSecForm,
    save: upsertBussinessExpense,
};
