import { upsertLiabilities } from '../../api/business';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const liabilitiesForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'description',
        label: 'Description',
        placeholder: '',
        area: true,
        span: 2,
    },
    {
        type: fieldTypes.RADIO,
        id: 'secured_ind',
        label: 'Secured/ Unsecured',
        options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
        ],
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'date_pledged',
        label: 'Date Pledged',
        placeholder: '1000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'balance_owed',
        label: 'Balance Owed',
        placeholder: '1000',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'final_payment_date',
        label: 'Date of Final Payment',
        placeholder: '1000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'monthly_payment',
        label: 'Payment Amount',
        placeholder: '1000',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Name',
        placeholder: 'Bitcoin',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_phone',
        label: 'Phone',
        placeholder: '9982663773',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Company Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'company_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_liability_data',
        label: 'Does your business have any liabilities?',
        header: 'Business Liabilities',
        formStructure: liabilitiesForm,
        displayColumns: [
            {
                name: 'Description',
                key: 'description',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_liabilities',
    name: 'Business Liabilities',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertLiabilities,
};
