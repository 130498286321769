import classNames from 'classnames';
import { useState } from 'react';
import useDocuments from '../../api/hooks/useDocument';
import { build } from '../../config';
import { useAuthState } from '../../context/auth';
import { date } from '../../utils/format';
import { useFeatureEnabled, useLightHouse } from '../../utils/helpers';
import FullfillRequestModal from './FullfillRequestModal';
import Manager from './Manager';
import { ActionsCell } from './UploadsTable';
import UploadModal from './UploadModal';
import useSectionLock from '../../api/hooks/useSectionLocks';

const RequiredDocument: React.FC<any> = ({ description, id, request_fullfill, document_url }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className={'w-full flex items-center justify-center border-t p-2'}>
                <div
                    className={classNames('h-4 w-4 rounded-full mr-2', {
                        'bg-yellow-500': request_fullfill === 'no',
                        'bg-primary-500': request_fullfill === 'yes',
                    })}
                />
                <a href={document_url} target="_blank" className="text-primary-500 flex-grow">
                    <div>{description}</div>
                </a>
                <div onClick={() => setIsOpen(true)} className="text-xs  hover:cursor-pointer ">
                    Upload Document
                </div>
            </div>

            <FullfillRequestModal
                document_id={id}
                description={description}
                isOpen={isOpen}
                close={() => setIsOpen(false)}
            />
        </>
    );
};

const AttachedDocument: React.FC<any> = (document) => {
    const [isOpen, setIsOpen] = useState(false);
    const { currentPage } = useLightHouse();

    return (
        <>
            <div className={'w-full flex items-center justify-center border-t cursor-pointer p-1'}>
                <div className="text-sm mr-3 text-gray-500">{date(document.last_updated_date)}</div>
                <a
                    href={document.document_url}
                    target="_blank"
                    className="flex-grow text-sm hover:underline text-primary-500"
                >
                    <div>{document.document_name}</div>
                </a>

                <ActionsCell small original={document} />
            </div>
        </>
    );
};

export default (): JSX.Element => {
    const [seeAll, setSeeAll] = useState<boolean>(false);
    const [manageModal, setManageModal] = useState<boolean>(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);

    const { success: isDocumentEnabled } = useFeatureEnabled('Document');

    const { documents, requests } = useDocuments();
    const { currentPage, businessId } = useLightHouse();

    const { irs_account_id } = useAuthState();
    const { lockDetails } = useSectionLock(irs_account_id);

    const sectionKey = businessId ? currentPage.address + ':' + businessId : currentPage.address;
    const isSectionLocked = lockDetails[sectionKey];

    const attachedDocuments = documents?.filter((item: any) => {
        return item.section === currentPage.address;
    });

    const requiredDocs = requests?.filter((item: any) => {
        return item.section === currentPage.address;
    });

    if (!isDocumentEnabled) return <></>;

    // return isSectionLocked ? (
    //     <></>
    // ) : (
    return (
        <>
            {!!requiredDocs?.length && (
                <div
                    className={
                        'w-full flex flex-col justify-center mt-8 border  border-primary-100'
                    }
                >
                    <div className="py-0.5 px-2 bg-primary-500 text-white text-xs">
                        Required Upload
                    </div>
                    {requiredDocs.map((item: any) => (
                        <RequiredDocument {...item} />
                    ))}
                </div>
            )}

            {!!attachedDocuments?.length && (
                <div
                    className={
                        'w-full flex flex-col justify-center mt-4 border  border-primary-100'
                    }
                >
                    <>
                        <div className="flex py-0.5 px-2 bg-primary-500 text-white text-xs">
                            <div className="flex-grow">{`Attached Documents (${attachedDocuments.length})`}</div>
                            <div
                                onClick={() => setManageModal(true)}
                                className="font-semibold hover:underline cursor-pointer"
                            >
                                {`Manage Documents`}
                            </div>
                        </div>
                        {attachedDocuments
                            .filter((_: any, index: number) => {
                                return seeAll ? true : index < 2;
                            })
                            .map((item: any) => (
                                <AttachedDocument {...item} />
                            ))}

                        {attachedDocuments?.length > 2 && (
                            <div
                                onClick={() => setSeeAll(!seeAll)}
                                className={
                                    'w-full flex items-center justify-center border-t cursor-pointer px-1 hover:bg-gray-100'
                                }
                            >
                                <div className="flex-grow text-xs text-primary-500 py-1">
                                    {seeAll ? `Collapse All` : `See All`}
                                </div>
                            </div>
                        )}
                    </>
                </div>
            )}

            <div
                onClick={() => setUploadModal(true)}
                className={
                    'w-full text-sm flex flex-col bg-gray-50 justify-center items-center border border-dashed border-primary-500 hover:bg-gray-100 cursor-pointer p-2 mt-4'
                }
            >
                <div>Upload New Document</div>
            </div>

            <Manager isOpen={manageModal} close={() => setManageModal(false)} />

            <UploadModal
                pageLock
                page={currentPage.address}
                isOpen={uploadModal}
                close={() => setUploadModal(false)}
            />
        </>
    );
    // );
};
