import { Dispatch, useReducer } from 'react';
import Calendar from '../admin/Calendar';
import Dropdown from '../admin/Dropdown';
import InputGroup from '../admin/InputGroup';
import RadioGroup from '../admin/Radio';
import SeperatorGroup from '../admin/SeperatorGroup';
import { useFormDispatch, useFormState } from '../../context/form';
import { fieldTypes } from '../../utils/enums';
import {
    BrickInterface,
    ConcreteInterface,
    FormStateInterface,
    FormActionInterface,
    FormInterface,
    SectionInterface,
} from '../../utils/interfaces';
import { Table } from '../admin/Table';
import classNames from 'classnames';
import Crossroad from '../admin/Crossroad';
import TerminalGroup from './TerminalGroup';
import EditableTable from '../admin/EditableTable';
import EditableTableOld from '../admin/EditableTableOld';
import InputDisplay from '../admin/InputDisplay';
import AmountInputGroup from '../admin/AmountInput';
import { build } from '../../config';

type WallInterface = {
    className?: string;
    structure: FormInterface;
    formState: FormStateInterface;
    business?: any;
    setFormState: (update: { id: string; value: any }) => void;
};

function isBrick(field: BrickInterface | ConcreteInterface): field is BrickInterface {
    return (field as BrickInterface).id !== undefined;
}

type FieldResolverProps = {
    field: BrickInterface | ConcreteInterface;
    formState: FormStateInterface;
    setFormState: (update: { id: string; value: any }) => void;
    business?: any;
};

const FieldResolver: React.FC<FieldResolverProps> = ({
    field,
    formState = {},
    setFormState,
    business,
}) => {
    if (isBrick(field)) {
        /** Component is a brick, hence needs to utilize formStates */
        const brick = field as BrickInterface;

        const value = formState[brick.id];
        const error = formState._errors?.[brick.id];

        const setValue = (value: any) => {
            setFormState({ id: brick.id, value });
        };

        switch (brick.type) {
            case fieldTypes.TEXT_INPUT:
                return <InputGroup {...{ ...brick, error, value, setValue, business }} />;

            case fieldTypes.AMOUNT_INPUT:
                return <AmountInputGroup {...{ ...brick, error, value, setValue }} />;

            case fieldTypes.TEXT_DISPLAY:
                return <InputDisplay {...{ ...brick, value, setValue, business }} />;

            case fieldTypes.DROPDOWN:
                return (
                    <Dropdown
                        formData={formState}
                        {...{ ...brick, error, value, setValue, business }}
                    />
                );

            case fieldTypes.RADIO:
                return <RadioGroup {...{ ...brick, error, value, setValue, business }} />;

            case fieldTypes.CALENDAR:
                return <Calendar {...{ ...brick, error, value, setValue, business }} />;

            case fieldTypes.TABLE:
                return (
                    <Table
                        {...{ ...brick, error, value, setValue, business, formState, setFormState }}
                    />
                );

            case fieldTypes.EDITABLE_TABLE:
                return build.checkboxes_in_expenses ? (
                    <EditableTable
                        {...{
                            ...brick,
                            error: formState._errors,
                            value,
                            setValue,
                            business,
                            formState,
                            setFormState,
                        }}
                    />
                ) : (
                    <EditableTableOld
                        {...{
                            ...brick,
                            error: formState._errors,
                            value,
                            setValue,
                            business,
                            formState,
                            setFormState,
                        }}
                    />
                );

            case fieldTypes.CROSSROAD:
                return (
                    <Crossroad
                        {...{
                            ...brick,
                            value,
                            setValue,
                            business,
                            formState,
                            setFormState,
                        }}
                    />
                );

            default:
                return <div>FAILED BRICK</div>;
        }
    } else {
        /** Component is a concrete, can't alter form State */
        const concrete = field as ConcreteInterface;

        switch (concrete.type) {
            case fieldTypes.SEPERATOR:
                return <SeperatorGroup {...concrete} />;

            // case fieldTypes.TERMINAL:
            //     return <TerminalGroup {...concrete} />;

            default:
                return <></>;
        }
    }
};

const Branches: React.FC<FieldResolverProps> = ({ field, formState, setFormState, business }) => {
    if (!formState) return <></>;

    if (isBrick(field)) {
        /**
         * Component is a brick, hence can form branches
         * */
        if (field.type === fieldTypes.CROSSROAD) return <></>;

        if (field.branches) {
            const paths = field.branches.paths;

            if (paths) {
                const path = paths.find((b) => b.value === formState[field.id]);

                if (path)
                    return (
                        <FormConstructor
                            structure={path.form}
                            {...{ formState, setFormState, business }}
                        />
                    );
            }
        }

        /** Component is a concrete, can't extend branches */
    }

    return <></>;
};

const FormConstructor: React.FC<WallInterface> = (wall) => {
    const { structure, formState, setFormState, business } = wall;
    return (
        <div className={classNames('grid grid-cols-4 col-span-4 w-full', wall.className)}>
            {structure.map((component, index) => {
                return (
                    <>
                        <FieldResolver
                            key={index}
                            field={component}
                            {...{ formState, setFormState, business }}
                        />
                        <Branches
                            key={structure.length + index}
                            field={component}
                            {...{ formState, setFormState, business }}
                        />
                    </>
                );
            })}
        </div>
    );
};

export default FormConstructor;
