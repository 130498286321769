export const colors = {
    primary: '#067F7B',
};

export const Investment_Type = [
    { label: 'Investment Bonds', value: 'Investment Bonds' },
    { label: 'Investment Stocks', value: 'Investment Stocks' },
    { label: 'Investment Other', value: 'Investment Other' },
]
export const Investment_Vehicle = [
    { label: 'Lease', value: 'Lease' },
    { label: 'Loan', value: 'Loan' },
]
export const Investment_Property = [
    { label: 'Investment', value: 'Investment' },
    { label: 'Lease', value: 'Lease' },
    { label: 'Primary', value: 'Primary' },
    { label: 'Rental/Mortgage', value: 'Rental/Mortgage' },
    { label: 'Secondary', value: 'Secondary' },
]

export const usa_states = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    // { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District Of Columbia', value: 'DC' },
    // { label: 'Federated States Of Micronesia', value: 'FM' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    // { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Palau', value: 'PW' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];

export const usa_counties = [
    { state: 'AK', label: 'Aleutians East Borough', value: 'Aleutians East Borough' },
    { state: 'AK', label: 'Aleutians West Census Area', value: 'Aleutians West Census Area' },
    { state: 'AK', label: 'Anchorage Municipality', value: 'Anchorage Municipality' },
    { state: 'AK', label: 'Bethel Census Area', value: 'Bethel Census Area' },
    { state: 'AK', label: 'Bristol Bay Borough', value: 'Bristol Bay Borough' },
    { state: 'AK', label: 'Denali Borough', value: 'Denali Borough' },
    { state: 'AK', label: 'Dillingham Census Area', value: 'Dillingham Census Area' },
    { state: 'AK', label: 'Fairbanks North Star Borough', value: 'Fairbanks North Star Borough' },
    { state: 'AK', label: 'Haines Borough', value: 'Haines Borough' },
    { state: 'AK', label: 'Hoonah-Angoon Census Area', value: 'Hoonah-Angoon Census Area' },
    { state: 'AK', label: 'Juneau City and Borough', value: 'Juneau City and Borough' },
    { state: 'AK', label: 'Kenai Peninsula Borough', value: 'Kenai Peninsula Borough' },
    { state: 'AK', label: 'Ketchikan Gateway Borough', value: 'Ketchikan Gateway Borough' },
    { state: 'AK', label: 'Kodiak Island Borough', value: 'Kodiak Island Borough' },
    { state: 'AK', label: 'Kusilvak Census Area', value: 'Kusilvak Census Area' },
    { state: 'AK', label: 'Lake and Peninsula Borough', value: 'Lake and Peninsula Borough' },
    { state: 'AK', label: 'Matanuska-Susitna Borough', value: 'Matanuska-Susitna Borough' },
    { state: 'AK', label: 'Nome Census Area', value: 'Nome Census Area' },
    { state: 'AK', label: 'North Slope Borough', value: 'North Slope Borough' },
    { state: 'AK', label: 'Northwest Arctic Borough', value: 'Northwest Arctic Borough' },
    { state: 'AK', label: 'Petersburg Census Area', value: 'Petersburg Census Area' },
    {
        state: 'AK',
        label: 'Prince of Wales-Hyder Census Area',
        value: 'Prince of Wales-Hyder Census Area',
    },
    { state: 'AK', label: 'Sitka City and Borough', value: 'Sitka City and Borough' },
    { state: 'AK', label: 'Skagway Municipality', value: 'Skagway Municipality' },
    {
        state: 'AK',
        label: 'Southeast Fairbanks Census Area',
        value: 'Southeast Fairbanks Census Area',
    },
    // { state: 'AK', label: 'Valdez-Cordova Census Area', value: 'Valdez-Cordova Census Area' },
    { state: 'AK', label: 'Wrangell City and Borough', value: 'Wrangell City and Borough' },
    { state: 'AK', label: 'Yakutat City and Borough', value: 'Yakutat City and Borough' },
    { state: 'AK', label: 'Yukon-Koyukuk Census Area', value: 'Yukon-Koyukuk Census Area' },
    { state: 'AL', label: 'Autauga County', value: 'Autauga County' },
    { state: 'AL', label: 'Baldwin County', value: 'Baldwin County' },
    { state: 'AL', label: 'Barbour County', value: 'Barbour County' },
    { state: 'AL', label: 'Bibb County', value: 'Bibb County' },
    { state: 'AL', label: 'Blount County', value: 'Blount County' },
    { state: 'AL', label: 'Bullock County', value: 'Bullock County' },
    { state: 'AL', label: 'Butler County', value: 'Butler County' },
    { state: 'AL', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'AL', label: 'Chambers County', value: 'Chambers County' },
    { state: 'AL', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'AL', label: 'Chilton County', value: 'Chilton County' },
    { state: 'AL', label: 'Choctaw County', value: 'Choctaw County' },
    { state: 'AL', label: 'Clarke County', value: 'Clarke County' },
    { state: 'AL', label: 'Clay County', value: 'Clay County' },
    { state: 'AL', label: 'Cleburne County', value: 'Cleburne County' },
    { state: 'AL', label: 'Coffee County', value: 'Coffee County' },
    { state: 'AL', label: 'Colbert County', value: 'Colbert County' },
    { state: 'AL', label: 'Conecuh County', value: 'Conecuh County' },
    { state: 'AL', label: 'Coosa County', value: 'Coosa County' },
    { state: 'AL', label: 'Covington County', value: 'Covington County' },
    { state: 'AL', label: 'Crenshaw County', value: 'Crenshaw County' },
    { state: 'AL', label: 'Cullman County', value: 'Cullman County' },
    { state: 'AL', label: 'Dale County', value: 'Dale County' },
    { state: 'AL', label: 'Dallas County', value: 'Dallas County' },
    { state: 'AL', label: 'DeKalb County', value: 'DeKalb County' },
    { state: 'AL', label: 'Elmore County', value: 'Elmore County' },
    { state: 'AL', label: 'Escambia County', value: 'Escambia County' },
    { state: 'AL', label: 'Etowah County', value: 'Etowah County' },
    { state: 'AL', label: 'Fayette County', value: 'Fayette County' },
    { state: 'AL', label: 'Franklin County', value: 'Franklin County' },
    { state: 'AL', label: 'Geneva County', value: 'Geneva County' },
    { state: 'AL', label: 'Greene County', value: 'Greene County' },
    { state: 'AL', label: 'Hale County', value: 'Hale County' },
    { state: 'AL', label: 'Henry County', value: 'Henry County' },
    { state: 'AL', label: 'Houston County', value: 'Houston County' },
    { state: 'AL', label: 'Jackson County', value: 'Jackson County' },
    { state: 'AL', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'AL', label: 'Lamar County', value: 'Lamar County' },
    { state: 'AL', label: 'Lauderdale County', value: 'Lauderdale County' },
    { state: 'AL', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'AL', label: 'Lee County', value: 'Lee County' },
    { state: 'AL', label: 'Limestone County', value: 'Limestone County' },
    { state: 'AL', label: 'Lowndes County', value: 'Lowndes County' },
    { state: 'AL', label: 'Macon County', value: 'Macon County' },
    { state: 'AL', label: 'Madison County', value: 'Madison County' },
    { state: 'AL', label: 'Marengo County', value: 'Marengo County' },
    { state: 'AL', label: 'Marion County', value: 'Marion County' },
    { state: 'AL', label: 'Marshall County', value: 'Marshall County' },
    { state: 'AL', label: 'Mobile County', value: 'Mobile County' },
    { state: 'AL', label: 'Monroe County', value: 'Monroe County' },
    { state: 'AL', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'AL', label: 'Morgan County', value: 'Morgan County' },
    { state: 'AL', label: 'Perry County', value: 'Perry County' },
    { state: 'AL', label: 'Pickens County', value: 'Pickens County' },
    { state: 'AL', label: 'Pike County', value: 'Pike County' },
    { state: 'AL', label: 'Randolph County', value: 'Randolph County' },
    { state: 'AL', label: 'Russell County', value: 'Russell County' },
    { state: 'AL', label: 'Shelby County', value: 'Shelby County' },
    { state: 'AL', label: 'St. Clair County', value: 'St. Clair County' },
    { state: 'AL', label: 'Sumter County', value: 'Sumter County' },
    { state: 'AL', label: 'Talladega County', value: 'Talladega County' },
    { state: 'AL', label: 'Tallapoosa County', value: 'Tallapoosa County' },
    { state: 'AL', label: 'Tuscaloosa County', value: 'Tuscaloosa County' },
    { state: 'AL', label: 'Walker County', value: 'Walker County' },
    { state: 'AL', label: 'Washington County', value: 'Washington County' },
    { state: 'AL', label: 'Wilcox County', value: 'Wilcox County' },
    { state: 'AL', label: 'Winston County', value: 'Winston County' },
    { state: 'AR', label: 'Arkansas County', value: 'Arkansas County' },
    { state: 'AR', label: 'Ashley County', value: 'Ashley County' },
    { state: 'AR', label: 'Baxter County', value: 'Baxter County' },
    { state: 'AR', label: 'Benton County', value: 'Benton County' },
    { state: 'AR', label: 'Boone County', value: 'Boone County' },
    { state: 'AR', label: 'Bradley County', value: 'Bradley County' },
    { state: 'AR', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'AR', label: 'Carroll County', value: 'Carroll County' },
    { state: 'AR', label: 'Chicot County', value: 'Chicot County' },
    { state: 'AR', label: 'Clark County', value: 'Clark County' },
    { state: 'AR', label: 'Clay County', value: 'Clay County' },
    { state: 'AR', label: 'Cleburne County', value: 'Cleburne County' },
    { state: 'AR', label: 'Cleveland County', value: 'Cleveland County' },
    { state: 'AR', label: 'Columbia County', value: 'Columbia County' },
    { state: 'AR', label: 'Conway County', value: 'Conway County' },
    { state: 'AR', label: 'Craighead County', value: 'Craighead County' },
    { state: 'AR', label: 'Crawford County', value: 'Crawford County' },
    { state: 'AR', label: 'Crittenden County', value: 'Crittenden County' },
    { state: 'AR', label: 'Cross County', value: 'Cross County' },
    { state: 'AR', label: 'Dallas County', value: 'Dallas County' },
    { state: 'AR', label: 'Desha County', value: 'Desha County' },
    { state: 'AR', label: 'Drew County', value: 'Drew County' },
    { state: 'AR', label: 'Faulkner County', value: 'Faulkner County' },
    { state: 'AR', label: 'Franklin County', value: 'Franklin County' },
    { state: 'AR', label: 'Fulton County', value: 'Fulton County' },
    { state: 'AR', label: 'Garland County', value: 'Garland County' },
    { state: 'AR', label: 'Grant County', value: 'Grant County' },
    { state: 'AR', label: 'Greene County', value: 'Greene County' },
    { state: 'AR', label: 'Hempstead County', value: 'Hempstead County' },
    { state: 'AR', label: 'Hot Spring County', value: 'Hot Spring County' },
    { state: 'AR', label: 'Howard County', value: 'Howard County' },
    { state: 'AR', label: 'Independence County', value: 'Independence County' },
    { state: 'AR', label: 'Izard County', value: 'Izard County' },
    { state: 'AR', label: 'Jackson County', value: 'Jackson County' },
    { state: 'AR', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'AR', label: 'Johnson County', value: 'Johnson County' },
    { state: 'AR', label: 'Lafayette County', value: 'Lafayette County' },
    { state: 'AR', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'AR', label: 'Lee County', value: 'Lee County' },
    { state: 'AR', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'AR', label: 'Little River County', value: 'Little River County' },
    { state: 'AR', label: 'Logan County', value: 'Logan County' },
    { state: 'AR', label: 'Lonoke County', value: 'Lonoke County' },
    { state: 'AR', label: 'Madison County', value: 'Madison County' },
    { state: 'AR', label: 'Marion County', value: 'Marion County' },
    { state: 'AR', label: 'Miller County', value: 'Miller County' },
    { state: 'AR', label: 'Mississippi County', value: 'Mississippi County' },
    { state: 'AR', label: 'Monroe County', value: 'Monroe County' },
    { state: 'AR', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'AR', label: 'Nevada County', value: 'Nevada County' },
    { state: 'AR', label: 'Newton County', value: 'Newton County' },
    { state: 'AR', label: 'Ouachita County', value: 'Ouachita County' },
    { state: 'AR', label: 'Perry County', value: 'Perry County' },
    { state: 'AR', label: 'Phillips County', value: 'Phillips County' },
    { state: 'AR', label: 'Pike County', value: 'Pike County' },
    { state: 'AR', label: 'Poinsett County', value: 'Poinsett County' },
    { state: 'AR', label: 'Polk County', value: 'Polk County' },
    { state: 'AR', label: 'Pope County', value: 'Pope County' },
    { state: 'AR', label: 'Prairie County', value: 'Prairie County' },
    { state: 'AR', label: 'Pulaski County', value: 'Pulaski County' },
    { state: 'AR', label: 'Randolph County', value: 'Randolph County' },
    { state: 'AR', label: 'Saline County', value: 'Saline County' },
    { state: 'AR', label: 'Scott County', value: 'Scott County' },
    { state: 'AR', label: 'Searcy County', value: 'Searcy County' },
    { state: 'AR', label: 'Sebastian County', value: 'Sebastian County' },
    { state: 'AR', label: 'Sevier County', value: 'Sevier County' },
    { state: 'AR', label: 'Sharp County', value: 'Sharp County' },
    { state: 'AR', label: 'St. Francis County', value: 'St. Francis County' },
    { state: 'AR', label: 'Stone County', value: 'Stone County' },
    { state: 'AR', label: 'Union County', value: 'Union County' },
    { state: 'AR', label: 'Van Buren County', value: 'Van Buren County' },
    { state: 'AR', label: 'Washington County', value: 'Washington County' },
    { state: 'AR', label: 'White County', value: 'White County' },
    { state: 'AR', label: 'Woodruff County', value: 'Woodruff County' },
    { state: 'AR', label: 'Yell County', value: 'Yell County' },
    { state: 'AZ', label: 'Apache County', value: 'Apache County' },
    { state: 'AZ', label: 'Cochise County', value: 'Cochise County' },
    { state: 'AZ', label: 'Coconino County', value: 'Coconino County' },
    { state: 'AZ', label: 'Gila County', value: 'Gila County' },
    { state: 'AZ', label: 'Graham County', value: 'Graham County' },
    { state: 'AZ', label: 'Greenlee County', value: 'Greenlee County' },
    { state: 'AZ', label: 'La Paz County', value: 'La Paz County' },
    { state: 'AZ', label: 'Maricopa County', value: 'Maricopa County' },
    { state: 'AZ', label: 'Mohave County', value: 'Mohave County' },
    { state: 'AZ', label: 'Navajo County', value: 'Navajo County' },
    { state: 'AZ', label: 'Pima County', value: 'Pima County' },
    { state: 'AZ', label: 'Pinal County', value: 'Pinal County' },
    { state: 'AZ', label: 'Santa Cruz County', value: 'Santa Cruz County' },
    { state: 'AZ', label: 'Yavapai County', value: 'Yavapai County' },
    { state: 'AZ', label: 'Yuma County', value: 'Yuma County' },
    { state: 'CA', label: 'Alameda County', value: 'Alameda County' },
    { state: 'CA', label: 'Alpine County', value: 'Alpine County' },
    { state: 'CA', label: 'Amador County', value: 'Amador County' },
    { state: 'CA', label: 'Butte County', value: 'Butte County' },
    { state: 'CA', label: 'Calaveras County', value: 'Calaveras County' },
    { state: 'CA', label: 'Colusa County', value: 'Colusa County' },
    { state: 'CA', label: 'Contra Costa County', value: 'Contra Costa County' },
    { state: 'CA', label: 'Del Norte County', value: 'Del Norte County' },
    { state: 'CA', label: 'El Dorado County', value: 'El Dorado County' },
    { state: 'CA', label: 'Fresno County', value: 'Fresno County' },
    { state: 'CA', label: 'Glenn County', value: 'Glenn County' },
    { state: 'CA', label: 'Humboldt County', value: 'Humboldt County' },
    { state: 'CA', label: 'Imperial County', value: 'Imperial County' },
    { state: 'CA', label: 'Inyo County', value: 'Inyo County' },
    { state: 'CA', label: 'Kern County', value: 'Kern County' },
    { state: 'CA', label: 'Kings County', value: 'Kings County' },
    { state: 'CA', label: 'Lake County', value: 'Lake County' },
    { state: 'CA', label: 'Lassen County', value: 'Lassen County' },
    { state: 'CA', label: 'Los Angeles County', value: 'Los Angeles County' },
    { state: 'CA', label: 'Madera County', value: 'Madera County' },
    { state: 'CA', label: 'Marin County', value: 'Marin County' },
    { state: 'CA', label: 'Mariposa County', value: 'Mariposa County' },
    { state: 'CA', label: 'Mendocino County', value: 'Mendocino County' },
    { state: 'CA', label: 'Merced County', value: 'Merced County' },
    { state: 'CA', label: 'Modoc County', value: 'Modoc County' },
    { state: 'CA', label: 'Mono County', value: 'Mono County' },
    { state: 'CA', label: 'Monterey County', value: 'Monterey County' },
    { state: 'CA', label: 'Napa County', value: 'Napa County' },
    { state: 'CA', label: 'Nevada County', value: 'Nevada County' },
    { state: 'CA', label: 'Orange County', value: 'Orange County' },
    { state: 'CA', label: 'Placer County', value: 'Placer County' },
    { state: 'CA', label: 'Plumas County', value: 'Plumas County' },
    { state: 'CA', label: 'Riverside County', value: 'Riverside County' },
    { state: 'CA', label: 'Sacramento County', value: 'Sacramento County' },
    { state: 'CA', label: 'San Benito County', value: 'San Benito County' },
    { state: 'CA', label: 'San Bernardino County', value: 'San Bernardino County' },
    { state: 'CA', label: 'San Diego County', value: 'San Diego County' },
    { state: 'CA', label: 'San Francisco County', value: 'San Francisco County' },
    { state: 'CA', label: 'San Joaquin County', value: 'San Joaquin County' },
    { state: 'CA', label: 'San Luis Obispo County', value: 'San Luis Obispo County' },
    { state: 'CA', label: 'San Mateo County', value: 'San Mateo County' },
    { state: 'CA', label: 'Santa Barbara County', value: 'Santa Barbara County' },
    { state: 'CA', label: 'Santa Clara County', value: 'Santa Clara County' },
    { state: 'CA', label: 'Santa Cruz County', value: 'Santa Cruz County' },
    { state: 'CA', label: 'Shasta County', value: 'Shasta County' },
    { state: 'CA', label: 'Sierra County', value: 'Sierra County' },
    { state: 'CA', label: 'Siskiyou County', value: 'Siskiyou County' },
    { state: 'CA', label: 'Solano County', value: 'Solano County' },
    { state: 'CA', label: 'Sonoma County', value: 'Sonoma County' },
    { state: 'CA', label: 'Stanislaus County', value: 'Stanislaus County' },
    { state: 'CA', label: 'Sutter County', value: 'Sutter County' },
    { state: 'CA', label: 'Tehama County', value: 'Tehama County' },
    { state: 'CA', label: 'Trinity County', value: 'Trinity County' },
    { state: 'CA', label: 'Tulare County', value: 'Tulare County' },
    { state: 'CA', label: 'Tuolumne County', value: 'Tuolumne County' },
    { state: 'CA', label: 'Ventura County', value: 'Ventura County' },
    { state: 'CA', label: 'Yolo County', value: 'Yolo County' },
    { state: 'CA', label: 'Yuba County', value: 'Yuba County' },
    { state: 'CO', label: 'Adams County', value: 'Adams County' },
    { state: 'CO', label: 'Alamosa County', value: 'Alamosa County' },
    { state: 'CO', label: 'Arapahoe County', value: 'Arapahoe County' },
    { state: 'CO', label: 'Archuleta County', value: 'Archuleta County' },
    { state: 'CO', label: 'Baca County', value: 'Baca County' },
    { state: 'CO', label: 'Bent County', value: 'Bent County' },
    { state: 'CO', label: 'Boulder County', value: 'Boulder County' },
    { state: 'CO', label: 'Broomfield County', value: 'Broomfield County' },
    { state: 'CO', label: 'Chaffee County', value: 'Chaffee County' },
    { state: 'CO', label: 'Cheyenne County', value: 'Cheyenne County' },
    { state: 'CO', label: 'Clear Creek County', value: 'Clear Creek County' },
    { state: 'CO', label: 'Conejos County', value: 'Conejos County' },
    { state: 'CO', label: 'Costilla County', value: 'Costilla County' },
    { state: 'CO', label: 'Crowley County', value: 'Crowley County' },
    { state: 'CO', label: 'Custer County', value: 'Custer County' },
    { state: 'CO', label: 'Delta County', value: 'Delta County' },
    { state: 'CO', label: 'Denver County', value: 'Denver County' },
    { state: 'CO', label: 'Dolores County', value: 'Dolores County' },
    { state: 'CO', label: 'Douglas County', value: 'Douglas County' },
    { state: 'CO', label: 'Eagle County', value: 'Eagle County' },
    { state: 'CO', label: 'El Paso County', value: 'El Paso County' },
    { state: 'CO', label: 'Elbert County', value: 'Elbert County' },
    { state: 'CO', label: 'Fremont County', value: 'Fremont County' },
    { state: 'CO', label: 'Garfield County', value: 'Garfield County' },
    { state: 'CO', label: 'Gilpin County', value: 'Gilpin County' },
    { state: 'CO', label: 'Grand County', value: 'Grand County' },
    { state: 'CO', label: 'Gunnison County', value: 'Gunnison County' },
    { state: 'CO', label: 'Hinsdale County', value: 'Hinsdale County' },
    { state: 'CO', label: 'Huerfano County', value: 'Huerfano County' },
    { state: 'CO', label: 'Jackson County', value: 'Jackson County' },
    { state: 'CO', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'CO', label: 'Kiowa County', value: 'Kiowa County' },
    { state: 'CO', label: 'Kit Carson County', value: 'Kit Carson County' },
    { state: 'CO', label: 'La Plata County', value: 'La Plata County' },
    { state: 'CO', label: 'Lake County', value: 'Lake County' },
    { state: 'CO', label: 'Larimer County', value: 'Larimer County' },
    { state: 'CO', label: 'Las Animas County', value: 'Las Animas County' },
    { state: 'CO', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'CO', label: 'Logan County', value: 'Logan County' },
    { state: 'CO', label: 'Mesa County', value: 'Mesa County' },
    { state: 'CO', label: 'Mineral County', value: 'Mineral County' },
    { state: 'CO', label: 'Moffat County', value: 'Moffat County' },
    { state: 'CO', label: 'Montezuma County', value: 'Montezuma County' },
    { state: 'CO', label: 'Montrose County', value: 'Montrose County' },
    { state: 'CO', label: 'Morgan County', value: 'Morgan County' },
    { state: 'CO', label: 'Otero County', value: 'Otero County' },
    { state: 'CO', label: 'Ouray County', value: 'Ouray County' },
    { state: 'CO', label: 'Park County', value: 'Park County' },
    { state: 'CO', label: 'Phillips County', value: 'Phillips County' },
    { state: 'CO', label: 'Pitkin County', value: 'Pitkin County' },
    { state: 'CO', label: 'Prowers County', value: 'Prowers County' },
    { state: 'CO', label: 'Pueblo County', value: 'Pueblo County' },
    { state: 'CO', label: 'Rio Blanco County', value: 'Rio Blanco County' },
    { state: 'CO', label: 'Rio Grande County', value: 'Rio Grande County' },
    { state: 'CO', label: 'Routt County', value: 'Routt County' },
    { state: 'CO', label: 'Saguache County', value: 'Saguache County' },
    { state: 'CO', label: 'San Juan County', value: 'San Juan County' },
    { state: 'CO', label: 'San Miguel County', value: 'San Miguel County' },
    { state: 'CO', label: 'Sedgwick County', value: 'Sedgwick County' },
    { state: 'CO', label: 'Summit County', value: 'Summit County' },
    { state: 'CO', label: 'Teller County', value: 'Teller County' },
    { state: 'CO', label: 'Washington County', value: 'Washington County' },
    { state: 'CO', label: 'Weld County', value: 'Weld County' },
    { state: 'CO', label: 'Yuma County', value: 'Yuma County' },
    // { state: 'CT', label: 'Fairfield County', value: 'Fairfield County' },
    // { state: 'CT', label: 'Hartford County', value: 'Hartford County' },
    // { state: 'CT', label: 'Litchfield County', value: 'Litchfield County' },
    // { state: 'CT', label: 'Middlesex County', value: 'Middlesex County' },
    // { state: 'CT', label: 'New Haven County', value: 'New Haven County' },
    // { state: 'CT', label: 'New London County', value: 'New London County' },
    // { state: 'CT', label: 'Tolland County', value: 'Tolland County' },
    // { state: 'CT', label: 'Windham County', value: 'Windham County' },
    { state: 'CT', label: 'Capitol Planning Region', value: 'Capitol Planning Region' },
    { state: 'CT', label: 'Greater Bridgeport Planning Region', value: 'Greater Bridgeport Planning Region' },
    { state: 'CT', label: 'Lower Connecticut River Valley Planning Region', value: 'Lower Connecticut River Valley Planning Region' },
    { state: 'CT', label: 'Naugatuck Valley Planning Region', value: 'Naugatuck Valley Planning Region' },
    { state: 'CT', label: 'Northeastern Connecticut Planning Region', value: 'Northeastern Connecticut Planning Region' },
    { state: 'CT', label: 'Northwest Hills Planning Region', value: 'Northwest Hills Planning Region' },
    { state: 'CT', label: 'South Central Connecticut Planning Region', value: 'South Central Connecticut Planning Region' },
    { state: 'CT', label: 'Southeastern Connecticut Planning Region', value: 'Southeastern Connecticut Planning Region' },
    { state: 'CT', label: 'Western Connecticut Planning Region', value: 'Western Connecticut Planning Region' },
    { state: 'DC', label: 'District of Columbia', value: 'District of Columbia' },
    { state: 'DE', label: 'Kent County', value: 'Kent County' },
    { state: 'DE', label: 'New Castle County', value: 'New Castle County' },
    { state: 'DE', label: 'Sussex County', value: 'Sussex County' },
    { state: 'FL', label: 'Alachua County', value: 'Alachua County' },
    { state: 'FL', label: 'Baker County', value: 'Baker County' },
    { state: 'FL', label: 'Bay County', value: 'Bay County' },
    { state: 'FL', label: 'Bradford County', value: 'Bradford County' },
    { state: 'FL', label: 'Brevard County', value: 'Brevard County' },
    { state: 'FL', label: 'Broward County', value: 'Broward County' },
    { state: 'FL', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'FL', label: 'Charlotte County', value: 'Charlotte County' },
    { state: 'FL', label: 'Citrus County', value: 'Citrus County' },
    { state: 'FL', label: 'Clay County', value: 'Clay County' },
    { state: 'FL', label: 'Collier County', value: 'Collier County' },
    { state: 'FL', label: 'Columbia County', value: 'Columbia County' },
    { state: 'FL', label: 'DeSoto County', value: 'DeSoto County' },
    { state: 'FL', label: 'Dixie County', value: 'Dixie County' },
    { state: 'FL', label: 'Duval County', value: 'Duval County' },
    { state: 'FL', label: 'Escambia County', value: 'Escambia County' },
    { state: 'FL', label: 'Flagler County', value: 'Flagler County' },
    { state: 'FL', label: 'Franklin County', value: 'Franklin County' },
    { state: 'FL', label: 'Gadsden County', value: 'Gadsden County' },
    { state: 'FL', label: 'Gilchrist County', value: 'Gilchrist County' },
    { state: 'FL', label: 'Glades County', value: 'Glades County' },
    { state: 'FL', label: 'Gulf County', value: 'Gulf County' },
    { state: 'FL', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'FL', label: 'Hardee County', value: 'Hardee County' },
    { state: 'FL', label: 'Hendry County', value: 'Hendry County' },
    { state: 'FL', label: 'Hernando County', value: 'Hernando County' },
    { state: 'FL', label: 'Highlands County', value: 'Highlands County' },
    { state: 'FL', label: 'Hillsborough County', value: 'Hillsborough County' },
    { state: 'FL', label: 'Holmes County', value: 'Holmes County' },
    { state: 'FL', label: 'Indian River County', value: 'Indian River County' },
    { state: 'FL', label: 'Jackson County', value: 'Jackson County' },
    { state: 'FL', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'FL', label: 'Lafayette County', value: 'Lafayette County' },
    { state: 'FL', label: 'Lake County', value: 'Lake County' },
    { state: 'FL', label: 'Lee County', value: 'Lee County' },
    { state: 'FL', label: 'Leon County', value: 'Leon County' },
    { state: 'FL', label: 'Levy County', value: 'Levy County' },
    { state: 'FL', label: 'Liberty County', value: 'Liberty County' },
    { state: 'FL', label: 'Madison County', value: 'Madison County' },
    { state: 'FL', label: 'Manatee County', value: 'Manatee County' },
    { state: 'FL', label: 'Marion County', value: 'Marion County' },
    { state: 'FL', label: 'Martin County', value: 'Martin County' },
    { state: 'FL', label: 'Miami-Dade County', value: 'Miami-Dade County' },
    { state: 'FL', label: 'Monroe County', value: 'Monroe County' },
    { state: 'FL', label: 'Nassau County', value: 'Nassau County' },
    { state: 'FL', label: 'Okaloosa County', value: 'Okaloosa County' },
    { state: 'FL', label: 'Okeechobee County', value: 'Okeechobee County' },
    { state: 'FL', label: 'Orange County', value: 'Orange County' },
    { state: 'FL', label: 'Osceola County', value: 'Osceola County' },
    { state: 'FL', label: 'Palm Beach County', value: 'Palm Beach County' },
    { state: 'FL', label: 'Pasco County', value: 'Pasco County' },
    { state: 'FL', label: 'Pinellas County', value: 'Pinellas County' },
    { state: 'FL', label: 'Polk County', value: 'Polk County' },
    { state: 'FL', label: 'Putnam County', value: 'Putnam County' },
    { state: 'FL', label: 'Santa Rosa County', value: 'Santa Rosa County' },
    { state: 'FL', label: 'Sarasota County', value: 'Sarasota County' },
    { state: 'FL', label: 'Seminole County', value: 'Seminole County' },
    { state: 'FL', label: 'St. Johns County', value: 'St. Johns County' },
    { state: 'FL', label: 'St. Lucie County', value: 'St. Lucie County' },
    { state: 'FL', label: 'Sumter County', value: 'Sumter County' },
    { state: 'FL', label: 'Suwannee County', value: 'Suwannee County' },
    { state: 'FL', label: 'Taylor County', value: 'Taylor County' },
    { state: 'FL', label: 'Union County', value: 'Union County' },
    { state: 'FL', label: 'Volusia County', value: 'Volusia County' },
    { state: 'FL', label: 'Wakulla County', value: 'Wakulla County' },
    { state: 'FL', label: 'Walton County', value: 'Walton County' },
    { state: 'FL', label: 'Washington County', value: 'Washington County' },
    { state: 'GA', label: 'Appling County', value: 'Appling County' },
    { state: 'GA', label: 'Atkinson County', value: 'Atkinson County' },
    { state: 'GA', label: 'Bacon County', value: 'Bacon County' },
    { state: 'GA', label: 'Baker County', value: 'Baker County' },
    { state: 'GA', label: 'Baldwin County', value: 'Baldwin County' },
    { state: 'GA', label: 'Banks County', value: 'Banks County' },
    { state: 'GA', label: 'Barrow County', value: 'Barrow County' },
    { state: 'GA', label: 'Bartow County', value: 'Bartow County' },
    { state: 'GA', label: 'Ben Hill County', value: 'Ben Hill County' },
    { state: 'GA', label: 'Berrien County', value: 'Berrien County' },
    { state: 'GA', label: 'Bibb County', value: 'Bibb County' },
    { state: 'GA', label: 'Bleckley County', value: 'Bleckley County' },
    { state: 'GA', label: 'Brantley County', value: 'Brantley County' },
    { state: 'GA', label: 'Brooks County', value: 'Brooks County' },
    { state: 'GA', label: 'Bryan County', value: 'Bryan County' },
    { state: 'GA', label: 'Bulloch County', value: 'Bulloch County' },
    { state: 'GA', label: 'Burke County', value: 'Burke County' },
    { state: 'GA', label: 'Butts County', value: 'Butts County' },
    { state: 'GA', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'GA', label: 'Camden County', value: 'Camden County' },
    { state: 'GA', label: 'Candler County', value: 'Candler County' },
    { state: 'GA', label: 'Carroll County', value: 'Carroll County' },
    { state: 'GA', label: 'Catoosa County', value: 'Catoosa County' },
    { state: 'GA', label: 'Charlton County', value: 'Charlton County' },
    { state: 'GA', label: 'Chatham County', value: 'Chatham County' },
    { state: 'GA', label: 'Chattahoochee County', value: 'Chattahoochee County' },
    { state: 'GA', label: 'Chattooga County', value: 'Chattooga County' },
    { state: 'GA', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'GA', label: 'Clarke County', value: 'Clarke County' },
    { state: 'GA', label: 'Clay County', value: 'Clay County' },
    { state: 'GA', label: 'Clayton County', value: 'Clayton County' },
    { state: 'GA', label: 'Clinch County', value: 'Clinch County' },
    { state: 'GA', label: 'Cobb County', value: 'Cobb County' },
    { state: 'GA', label: 'Coffee County', value: 'Coffee County' },
    { state: 'GA', label: 'Colquitt County', value: 'Colquitt County' },
    { state: 'GA', label: 'Columbia County', value: 'Columbia County' },
    { state: 'GA', label: 'Cook County', value: 'Cook County' },
    { state: 'GA', label: 'Coweta County', value: 'Coweta County' },
    { state: 'GA', label: 'Crawford County', value: 'Crawford County' },
    { state: 'GA', label: 'Crisp County', value: 'Crisp County' },
    { state: 'GA', label: 'Dade County', value: 'Dade County' },
    { state: 'GA', label: 'Dawson County', value: 'Dawson County' },
    { state: 'GA', label: 'Decatur County', value: 'Decatur County' },
    { state: 'GA', label: 'DeKalb County', value: 'DeKalb County' },
    { state: 'GA', label: 'Dodge County', value: 'Dodge County' },
    { state: 'GA', label: 'Dooly County', value: 'Dooly County' },
    { state: 'GA', label: 'Dougherty County', value: 'Dougherty County' },
    { state: 'GA', label: 'Douglas County', value: 'Douglas County' },
    { state: 'GA', label: 'Early County', value: 'Early County' },
    { state: 'GA', label: 'Echols County', value: 'Echols County' },
    { state: 'GA', label: 'Effingham County', value: 'Effingham County' },
    { state: 'GA', label: 'Elbert County', value: 'Elbert County' },
    { state: 'GA', label: 'Emanuel County', value: 'Emanuel County' },
    { state: 'GA', label: 'Evans County', value: 'Evans County' },
    { state: 'GA', label: 'Fannin County', value: 'Fannin County' },
    { state: 'GA', label: 'Fayette County', value: 'Fayette County' },
    { state: 'GA', label: 'Floyd County', value: 'Floyd County' },
    { state: 'GA', label: 'Forsyth County', value: 'Forsyth County' },
    { state: 'GA', label: 'Franklin County', value: 'Franklin County' },
    { state: 'GA', label: 'Fulton County', value: 'Fulton County' },
    { state: 'GA', label: 'Gilmer County', value: 'Gilmer County' },
    { state: 'GA', label: 'Glascock County', value: 'Glascock County' },
    { state: 'GA', label: 'Glynn County', value: 'Glynn County' },
    { state: 'GA', label: 'Gordon County', value: 'Gordon County' },
    { state: 'GA', label: 'Grady County', value: 'Grady County' },
    { state: 'GA', label: 'Greene County', value: 'Greene County' },
    { state: 'GA', label: 'Gwinnett County', value: 'Gwinnett County' },
    { state: 'GA', label: 'Habersham County', value: 'Habersham County' },
    { state: 'GA', label: 'Hall County', value: 'Hall County' },
    { state: 'GA', label: 'Hancock County', value: 'Hancock County' },
    { state: 'GA', label: 'Haralson County', value: 'Haralson County' },
    { state: 'GA', label: 'Harris County', value: 'Harris County' },
    { state: 'GA', label: 'Hart County', value: 'Hart County' },
    { state: 'GA', label: 'Heard County', value: 'Heard County' },
    { state: 'GA', label: 'Henry County', value: 'Henry County' },
    { state: 'GA', label: 'Houston County', value: 'Houston County' },
    { state: 'GA', label: 'Irwin County', value: 'Irwin County' },
    { state: 'GA', label: 'Jackson County', value: 'Jackson County' },
    { state: 'GA', label: 'Jasper County', value: 'Jasper County' },
    { state: 'GA', label: 'Jeff Davis County', value: 'Jeff Davis County' },
    { state: 'GA', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'GA', label: 'Jenkins County', value: 'Jenkins County' },
    { state: 'GA', label: 'Johnson County', value: 'Johnson County' },
    { state: 'GA', label: 'Jones County', value: 'Jones County' },
    { state: 'GA', label: 'Lamar County', value: 'Lamar County' },
    { state: 'GA', label: 'Lanier County', value: 'Lanier County' },
    { state: 'GA', label: 'Laurens County', value: 'Laurens County' },
    { state: 'GA', label: 'Lee County', value: 'Lee County' },
    { state: 'GA', label: 'Liberty County', value: 'Liberty County' },
    { state: 'GA', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'GA', label: 'Long County', value: 'Long County' },
    { state: 'GA', label: 'Lowndes County', value: 'Lowndes County' },
    { state: 'GA', label: 'Lumpkin County', value: 'Lumpkin County' },
    { state: 'GA', label: 'Macon County', value: 'Macon County' },
    { state: 'GA', label: 'Madison County', value: 'Madison County' },
    { state: 'GA', label: 'Marion County', value: 'Marion County' },
    { state: 'GA', label: 'McDuffie County', value: 'McDuffie County' },
    { state: 'GA', label: 'McIntosh County', value: 'McIntosh County' },
    { state: 'GA', label: 'Meriwether County', value: 'Meriwether County' },
    { state: 'GA', label: 'Miller County', value: 'Miller County' },
    { state: 'GA', label: 'Mitchell County', value: 'Mitchell County' },
    { state: 'GA', label: 'Monroe County', value: 'Monroe County' },
    { state: 'GA', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'GA', label: 'Morgan County', value: 'Morgan County' },
    { state: 'GA', label: 'Murray County', value: 'Murray County' },
    { state: 'GA', label: 'Muscogee County', value: 'Muscogee County' },
    { state: 'GA', label: 'Newton County', value: 'Newton County' },
    { state: 'GA', label: 'Oconee County', value: 'Oconee County' },
    { state: 'GA', label: 'Oglethorpe County', value: 'Oglethorpe County' },
    { state: 'GA', label: 'Paulding County', value: 'Paulding County' },
    { state: 'GA', label: 'Peach County', value: 'Peach County' },
    { state: 'GA', label: 'Pickens County', value: 'Pickens County' },
    { state: 'GA', label: 'Pierce County', value: 'Pierce County' },
    { state: 'GA', label: 'Pike County', value: 'Pike County' },
    { state: 'GA', label: 'Polk County', value: 'Polk County' },
    { state: 'GA', label: 'Pulaski County', value: 'Pulaski County' },
    { state: 'GA', label: 'Putnam County', value: 'Putnam County' },
    { state: 'GA', label: 'Quitman County', value: 'Quitman County' },
    { state: 'GA', label: 'Rabun County', value: 'Rabun County' },
    { state: 'GA', label: 'Randolph County', value: 'Randolph County' },
    { state: 'GA', label: 'Richmond County', value: 'Richmond County' },
    { state: 'GA', label: 'Rockdale County', value: 'Rockdale County' },
    { state: 'GA', label: 'Schley County', value: 'Schley County' },
    { state: 'GA', label: 'Screven County', value: 'Screven County' },
    { state: 'GA', label: 'Seminole County', value: 'Seminole County' },
    { state: 'GA', label: 'Spalding County', value: 'Spalding County' },
    { state: 'GA', label: 'Stephens County', value: 'Stephens County' },
    { state: 'GA', label: 'Stewart County', value: 'Stewart County' },
    { state: 'GA', label: 'Sumter County', value: 'Sumter County' },
    { state: 'GA', label: 'Talbot County', value: 'Talbot County' },
    { state: 'GA', label: 'Taliaferro County', value: 'Taliaferro County' },
    { state: 'GA', label: 'Tattnall County', value: 'Tattnall County' },
    { state: 'GA', label: 'Taylor County', value: 'Taylor County' },
    { state: 'GA', label: 'Telfair County', value: 'Telfair County' },
    { state: 'GA', label: 'Terrell County', value: 'Terrell County' },
    { state: 'GA', label: 'Thomas County', value: 'Thomas County' },
    { state: 'GA', label: 'Tift County', value: 'Tift County' },
    { state: 'GA', label: 'Toombs County', value: 'Toombs County' },
    { state: 'GA', label: 'Towns County', value: 'Towns County' },
    { state: 'GA', label: 'Treutlen County', value: 'Treutlen County' },
    { state: 'GA', label: 'Troup County', value: 'Troup County' },
    { state: 'GA', label: 'Turner County', value: 'Turner County' },
    { state: 'GA', label: 'Twiggs County', value: 'Twiggs County' },
    { state: 'GA', label: 'Union County', value: 'Union County' },
    { state: 'GA', label: 'Upson County', value: 'Upson County' },
    { state: 'GA', label: 'Walker County', value: 'Walker County' },
    { state: 'GA', label: 'Walton County', value: 'Walton County' },
    { state: 'GA', label: 'Ware County', value: 'Ware County' },
    { state: 'GA', label: 'Warren County', value: 'Warren County' },
    { state: 'GA', label: 'Washington County', value: 'Washington County' },
    { state: 'GA', label: 'Wayne County', value: 'Wayne County' },
    { state: 'GA', label: 'Webster County', value: 'Webster County' },
    { state: 'GA', label: 'Wheeler County', value: 'Wheeler County' },
    { state: 'GA', label: 'White County', value: 'White County' },
    { state: 'GA', label: 'Whitfield County', value: 'Whitfield County' },
    { state: 'GA', label: 'Wilcox County', value: 'Wilcox County' },
    { state: 'GA', label: 'Wilkes County', value: 'Wilkes County' },
    { state: 'GA', label: 'Wilkinson County', value: 'Wilkinson County' },
    { state: 'GA', label: 'Worth County', value: 'Worth County' },
    { state: 'HI', label: 'Hawaii County', value: 'Hawaii County' },
    { state: 'HI', label: 'Honolulu County', value: 'Honolulu County' },
    { state: 'HI', label: 'Kalawao County', value: 'Kalawao County' },
    { state: 'HI', label: 'Kauai County', value: 'Kauai County' },
    { state: 'HI', label: 'Maui County', value: 'Maui County' },
    { state: 'IA', label: 'Adair County', value: 'Adair County' },
    { state: 'IA', label: 'Adams County', value: 'Adams County' },
    { state: 'IA', label: 'Allamakee County', value: 'Allamakee County' },
    { state: 'IA', label: 'Appanoose County', value: 'Appanoose County' },
    { state: 'IA', label: 'Audubon County', value: 'Audubon County' },
    { state: 'IA', label: 'Benton County', value: 'Benton County' },
    { state: 'IA', label: 'Black Hawk County', value: 'Black Hawk County' },
    { state: 'IA', label: 'Boone County', value: 'Boone County' },
    { state: 'IA', label: 'Bremer County', value: 'Bremer County' },
    { state: 'IA', label: 'Buchanan County', value: 'Buchanan County' },
    { state: 'IA', label: 'Buena Vista County', value: 'Buena Vista County' },
    { state: 'IA', label: 'Butler County', value: 'Butler County' },
    { state: 'IA', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'IA', label: 'Carroll County', value: 'Carroll County' },
    { state: 'IA', label: 'Cass County', value: 'Cass County' },
    { state: 'IA', label: 'Cedar County', value: 'Cedar County' },
    { state: 'IA', label: 'Cerro Gordo County', value: 'Cerro Gordo County' },
    { state: 'IA', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'IA', label: 'Chickasaw County', value: 'Chickasaw County' },
    { state: 'IA', label: 'Clarke County', value: 'Clarke County' },
    { state: 'IA', label: 'Clay County', value: 'Clay County' },
    { state: 'IA', label: 'Clayton County', value: 'Clayton County' },
    { state: 'IA', label: 'Clinton County', value: 'Clinton County' },
    { state: 'IA', label: 'Crawford County', value: 'Crawford County' },
    { state: 'IA', label: 'Dallas County', value: 'Dallas County' },
    { state: 'IA', label: 'Davis County', value: 'Davis County' },
    { state: 'IA', label: 'Decatur County', value: 'Decatur County' },
    { state: 'IA', label: 'Delaware County', value: 'Delaware County' },
    { state: 'IA', label: 'Des Moines County', value: 'Des Moines County' },
    { state: 'IA', label: 'Dickinson County', value: 'Dickinson County' },
    { state: 'IA', label: 'Dubuque County', value: 'Dubuque County' },
    { state: 'IA', label: 'Emmet County', value: 'Emmet County' },
    { state: 'IA', label: 'Fayette County', value: 'Fayette County' },
    { state: 'IA', label: 'Floyd County', value: 'Floyd County' },
    { state: 'IA', label: 'Franklin County', value: 'Franklin County' },
    { state: 'IA', label: 'Fremont County', value: 'Fremont County' },
    { state: 'IA', label: 'Greene County', value: 'Greene County' },
    { state: 'IA', label: 'Grundy County', value: 'Grundy County' },
    { state: 'IA', label: 'Guthrie County', value: 'Guthrie County' },
    { state: 'IA', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'IA', label: 'Hancock County', value: 'Hancock County' },
    { state: 'IA', label: 'Hardin County', value: 'Hardin County' },
    { state: 'IA', label: 'Harrison County', value: 'Harrison County' },
    { state: 'IA', label: 'Henry County', value: 'Henry County' },
    { state: 'IA', label: 'Howard County', value: 'Howard County' },
    { state: 'IA', label: 'Humboldt County', value: 'Humboldt County' },
    { state: 'IA', label: 'Ida County', value: 'Ida County' },
    { state: 'IA', label: 'Iowa County', value: 'Iowa County' },
    { state: 'IA', label: 'Jackson County', value: 'Jackson County' },
    { state: 'IA', label: 'Jasper County', value: 'Jasper County' },
    { state: 'IA', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'IA', label: 'Johnson County', value: 'Johnson County' },
    { state: 'IA', label: 'Jones County', value: 'Jones County' },
    { state: 'IA', label: 'Keokuk County', value: 'Keokuk County' },
    { state: 'IA', label: 'Kossuth County', value: 'Kossuth County' },
    { state: 'IA', label: 'Lee County', value: 'Lee County' },
    { state: 'IA', label: 'Linn County', value: 'Linn County' },
    { state: 'IA', label: 'Louisa County', value: 'Louisa County' },
    { state: 'IA', label: 'Lucas County', value: 'Lucas County' },
    { state: 'IA', label: 'Lyon County', value: 'Lyon County' },
    { state: 'IA', label: 'Madison County', value: 'Madison County' },
    { state: 'IA', label: 'Mahaska County', value: 'Mahaska County' },
    { state: 'IA', label: 'Marion County', value: 'Marion County' },
    { state: 'IA', label: 'Marshall County', value: 'Marshall County' },
    { state: 'IA', label: 'Mills County', value: 'Mills County' },
    { state: 'IA', label: 'Mitchell County', value: 'Mitchell County' },
    { state: 'IA', label: 'Monona County', value: 'Monona County' },
    { state: 'IA', label: 'Monroe County', value: 'Monroe County' },
    { state: 'IA', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'IA', label: 'Muscatine County', value: 'Muscatine County' },
    { state: 'IA', label: "O'Brien County", value: "O'Brien County" },
    { state: 'IA', label: 'Osceola County', value: 'Osceola County' },
    { state: 'IA', label: 'Page County', value: 'Page County' },
    { state: 'IA', label: 'Palo Alto County', value: 'Palo Alto County' },
    { state: 'IA', label: 'Plymouth County', value: 'Plymouth County' },
    { state: 'IA', label: 'Pocahontas County', value: 'Pocahontas County' },
    { state: 'IA', label: 'Polk County', value: 'Polk County' },
    { state: 'IA', label: 'Pottawattamie County', value: 'Pottawattamie County' },
    { state: 'IA', label: 'Poweshiek County', value: 'Poweshiek County' },
    { state: 'IA', label: 'Ringgold County', value: 'Ringgold County' },
    { state: 'IA', label: 'Sac County', value: 'Sac County' },
    { state: 'IA', label: 'Scott County', value: 'Scott County' },
    { state: 'IA', label: 'Shelby County', value: 'Shelby County' },
    { state: 'IA', label: 'Sioux County', value: 'Sioux County' },
    { state: 'IA', label: 'Story County', value: 'Story County' },
    { state: 'IA', label: 'Tama County', value: 'Tama County' },
    { state: 'IA', label: 'Taylor County', value: 'Taylor County' },
    { state: 'IA', label: 'Union County', value: 'Union County' },
    { state: 'IA', label: 'Van Buren County', value: 'Van Buren County' },
    { state: 'IA', label: 'Wapello County', value: 'Wapello County' },
    { state: 'IA', label: 'Warren County', value: 'Warren County' },
    { state: 'IA', label: 'Washington County', value: 'Washington County' },
    { state: 'IA', label: 'Wayne County', value: 'Wayne County' },
    { state: 'IA', label: 'Webster County', value: 'Webster County' },
    { state: 'IA', label: 'Winnebago County', value: 'Winnebago County' },
    { state: 'IA', label: 'Winneshiek County', value: 'Winneshiek County' },
    { state: 'IA', label: 'Woodbury County', value: 'Woodbury County' },
    { state: 'IA', label: 'Worth County', value: 'Worth County' },
    { state: 'IA', label: 'Wright County', value: 'Wright County' },
    { state: 'ID', label: 'Ada County', value: 'Ada County' },
    { state: 'ID', label: 'Adams County', value: 'Adams County' },
    { state: 'ID', label: 'Bannock County', value: 'Bannock County' },
    { state: 'ID', label: 'Bear Lake County', value: 'Bear Lake County' },
    { state: 'ID', label: 'Benewah County', value: 'Benewah County' },
    { state: 'ID', label: 'Bingham County', value: 'Bingham County' },
    { state: 'ID', label: 'Blaine County', value: 'Blaine County' },
    { state: 'ID', label: 'Boise County', value: 'Boise County' },
    { state: 'ID', label: 'Bonner County', value: 'Bonner County' },
    { state: 'ID', label: 'Bonneville County', value: 'Bonneville County' },
    { state: 'ID', label: 'Boundary County', value: 'Boundary County' },
    { state: 'ID', label: 'Butte County', value: 'Butte County' },
    { state: 'ID', label: 'Camas County', value: 'Camas County' },
    { state: 'ID', label: 'Canyon County', value: 'Canyon County' },
    { state: 'ID', label: 'Caribou County', value: 'Caribou County' },
    { state: 'ID', label: 'Cassia County', value: 'Cassia County' },
    { state: 'ID', label: 'Clark County', value: 'Clark County' },
    { state: 'ID', label: 'Clearwater County', value: 'Clearwater County' },
    { state: 'ID', label: 'Custer County', value: 'Custer County' },
    { state: 'ID', label: 'Elmore County', value: 'Elmore County' },
    { state: 'ID', label: 'Franklin County', value: 'Franklin County' },
    { state: 'ID', label: 'Fremont County', value: 'Fremont County' },
    { state: 'ID', label: 'Gem County', value: 'Gem County' },
    { state: 'ID', label: 'Gooding County', value: 'Gooding County' },
    { state: 'ID', label: 'Idaho County', value: 'Idaho County' },
    { state: 'ID', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'ID', label: 'Jerome County', value: 'Jerome County' },
    { state: 'ID', label: 'Kootenai County', value: 'Kootenai County' },
    { state: 'ID', label: 'Latah County', value: 'Latah County' },
    { state: 'ID', label: 'Lemhi County', value: 'Lemhi County' },
    { state: 'ID', label: 'Lewis County', value: 'Lewis County' },
    { state: 'ID', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'ID', label: 'Madison County', value: 'Madison County' },
    { state: 'ID', label: 'Minidoka County', value: 'Minidoka County' },
    { state: 'ID', label: 'Nez Perce County', value: 'Nez Perce County' },
    { state: 'ID', label: 'Oneida County', value: 'Oneida County' },
    { state: 'ID', label: 'Owyhee County', value: 'Owyhee County' },
    { state: 'ID', label: 'Payette County', value: 'Payette County' },
    { state: 'ID', label: 'Power County', value: 'Power County' },
    { state: 'ID', label: 'Shoshone County', value: 'Shoshone County' },
    { state: 'ID', label: 'Teton County', value: 'Teton County' },
    { state: 'ID', label: 'Twin Falls County', value: 'Twin Falls County' },
    { state: 'ID', label: 'Valley County', value: 'Valley County' },
    { state: 'ID', label: 'Washington County', value: 'Washington County' },
    { state: 'IL', label: 'Adams County', value: 'Adams County' },
    { state: 'IL', label: 'Alexander County', value: 'Alexander County' },
    { state: 'IL', label: 'Bond County', value: 'Bond County' },
    { state: 'IL', label: 'Boone County', value: 'Boone County' },
    { state: 'IL', label: 'Brown County', value: 'Brown County' },
    { state: 'IL', label: 'Bureau County', value: 'Bureau County' },
    { state: 'IL', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'IL', label: 'Carroll County', value: 'Carroll County' },
    { state: 'IL', label: 'Cass County', value: 'Cass County' },
    { state: 'IL', label: 'Champaign County', value: 'Champaign County' },
    { state: 'IL', label: 'Christian County', value: 'Christian County' },
    { state: 'IL', label: 'Clark County', value: 'Clark County' },
    { state: 'IL', label: 'Clay County', value: 'Clay County' },
    { state: 'IL', label: 'Clinton County', value: 'Clinton County' },
    { state: 'IL', label: 'Coles County', value: 'Coles County' },
    { state: 'IL', label: 'Cook County', value: 'Cook County' },
    { state: 'IL', label: 'Crawford County', value: 'Crawford County' },
    { state: 'IL', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'IL', label: 'De Witt County', value: 'De Witt County' },
    { state: 'IL', label: 'DeKalb County', value: 'DeKalb County' },
    { state: 'IL', label: 'Douglas County', value: 'Douglas County' },
    { state: 'IL', label: 'DuPage County', value: 'DuPage County' },
    { state: 'IL', label: 'Edgar County', value: 'Edgar County' },
    { state: 'IL', label: 'Edwards County', value: 'Edwards County' },
    { state: 'IL', label: 'Effingham County', value: 'Effingham County' },
    { state: 'IL', label: 'Fayette County', value: 'Fayette County' },
    { state: 'IL', label: 'Ford County', value: 'Ford County' },
    { state: 'IL', label: 'Franklin County', value: 'Franklin County' },
    { state: 'IL', label: 'Fulton County', value: 'Fulton County' },
    { state: 'IL', label: 'Gallatin County', value: 'Gallatin County' },
    { state: 'IL', label: 'Greene County', value: 'Greene County' },
    { state: 'IL', label: 'Grundy County', value: 'Grundy County' },
    { state: 'IL', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'IL', label: 'Hancock County', value: 'Hancock County' },
    { state: 'IL', label: 'Hardin County', value: 'Hardin County' },
    { state: 'IL', label: 'Henderson County', value: 'Henderson County' },
    { state: 'IL', label: 'Henry County', value: 'Henry County' },
    { state: 'IL', label: 'Iroquois County', value: 'Iroquois County' },
    { state: 'IL', label: 'Jackson County', value: 'Jackson County' },
    { state: 'IL', label: 'Jasper County', value: 'Jasper County' },
    { state: 'IL', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'IL', label: 'Jersey County', value: 'Jersey County' },
    { state: 'IL', label: 'Jo Daviess County', value: 'Jo Daviess County' },
    { state: 'IL', label: 'Johnson County', value: 'Johnson County' },
    { state: 'IL', label: 'Kane County', value: 'Kane County' },
    { state: 'IL', label: 'Kankakee County', value: 'Kankakee County' },
    { state: 'IL', label: 'Kendall County', value: 'Kendall County' },
    { state: 'IL', label: 'Knox County', value: 'Knox County' },
    { state: 'IL', label: 'La Salle County', value: 'La Salle County' },
    { state: 'IL', label: 'Lake County', value: 'Lake County' },
    { state: 'IL', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'IL', label: 'Lee County', value: 'Lee County' },
    { state: 'IL', label: 'Livingston County', value: 'Livingston County' },
    { state: 'IL', label: 'Logan County', value: 'Logan County' },
    { state: 'IL', label: 'Macon County', value: 'Macon County' },
    { state: 'IL', label: 'Macoupin County', value: 'Macoupin County' },
    { state: 'IL', label: 'Madison County', value: 'Madison County' },
    { state: 'IL', label: 'Marion County', value: 'Marion County' },
    { state: 'IL', label: 'Marshall County', value: 'Marshall County' },
    { state: 'IL', label: 'Mason County', value: 'Mason County' },
    { state: 'IL', label: 'Massac County', value: 'Massac County' },
    { state: 'IL', label: 'McDonough County', value: 'McDonough County' },
    { state: 'IL', label: 'McHenry County', value: 'McHenry County' },
    { state: 'IL', label: 'McLean County', value: 'McLean County' },
    { state: 'IL', label: 'Menard County', value: 'Menard County' },
    { state: 'IL', label: 'Mercer County', value: 'Mercer County' },
    { state: 'IL', label: 'Monroe County', value: 'Monroe County' },
    { state: 'IL', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'IL', label: 'Morgan County', value: 'Morgan County' },
    { state: 'IL', label: 'Moultrie County', value: 'Moultrie County' },
    { state: 'IL', label: 'Ogle County', value: 'Ogle County' },
    { state: 'IL', label: 'Peoria County', value: 'Peoria County' },
    { state: 'IL', label: 'Perry County', value: 'Perry County' },
    { state: 'IL', label: 'Piatt County', value: 'Piatt County' },
    { state: 'IL', label: 'Pike County', value: 'Pike County' },
    { state: 'IL', label: 'Pope County', value: 'Pope County' },
    { state: 'IL', label: 'Pulaski County', value: 'Pulaski County' },
    { state: 'IL', label: 'Putnam County', value: 'Putnam County' },
    { state: 'IL', label: 'Randolph County', value: 'Randolph County' },
    { state: 'IL', label: 'Richland County', value: 'Richland County' },
    { state: 'IL', label: 'Rock Island County', value: 'Rock Island County' },
    { state: 'IL', label: 'Saline County', value: 'Saline County' },
    { state: 'IL', label: 'Sangamon County', value: 'Sangamon County' },
    { state: 'IL', label: 'Schuyler County', value: 'Schuyler County' },
    { state: 'IL', label: 'Scott County', value: 'Scott County' },
    { state: 'IL', label: 'Shelby County', value: 'Shelby County' },
    { state: 'IL', label: 'St. Clair County', value: 'St. Clair County' },
    { state: 'IL', label: 'Stark County', value: 'Stark County' },
    { state: 'IL', label: 'Stephenson County', value: 'Stephenson County' },
    { state: 'IL', label: 'Tazewell County', value: 'Tazewell County' },
    { state: 'IL', label: 'Union County', value: 'Union County' },
    { state: 'IL', label: 'Vermilion County', value: 'Vermilion County' },
    { state: 'IL', label: 'Wabash County', value: 'Wabash County' },
    { state: 'IL', label: 'Warren County', value: 'Warren County' },
    { state: 'IL', label: 'Washington County', value: 'Washington County' },
    { state: 'IL', label: 'Wayne County', value: 'Wayne County' },
    { state: 'IL', label: 'White County', value: 'White County' },
    { state: 'IL', label: 'Whiteside County', value: 'Whiteside County' },
    { state: 'IL', label: 'Will County', value: 'Will County' },
    { state: 'IL', label: 'Williamson County', value: 'Williamson County' },
    { state: 'IL', label: 'Winnebago County', value: 'Winnebago County' },
    { state: 'IL', label: 'Woodford County', value: 'Woodford County' },
    { state: 'IN', label: 'Adams County', value: 'Adams County' },
    { state: 'IN', label: 'Allen County', value: 'Allen County' },
    { state: 'IN', label: 'Bartholomew County', value: 'Bartholomew County' },
    { state: 'IN', label: 'Benton County', value: 'Benton County' },
    { state: 'IN', label: 'Blackford County', value: 'Blackford County' },
    { state: 'IN', label: 'Boone County', value: 'Boone County' },
    { state: 'IN', label: 'Brown County', value: 'Brown County' },
    { state: 'IN', label: 'Carroll County', value: 'Carroll County' },
    { state: 'IN', label: 'Cass County', value: 'Cass County' },
    { state: 'IN', label: 'Clark County', value: 'Clark County' },
    { state: 'IN', label: 'Clay County', value: 'Clay County' },
    { state: 'IN', label: 'Clinton County', value: 'Clinton County' },
    { state: 'IN', label: 'Crawford County', value: 'Crawford County' },
    { state: 'IN', label: 'Daviess County', value: 'Daviess County' },
    { state: 'IN', label: 'Dearborn County', value: 'Dearborn County' },
    { state: 'IN', label: 'Decatur County', value: 'Decatur County' },
    { state: 'IN', label: 'DeKalb County', value: 'DeKalb County' },
    { state: 'IN', label: 'Delaware County', value: 'Delaware County' },
    { state: 'IN', label: 'Dubois County', value: 'Dubois County' },
    { state: 'IN', label: 'Elkhart County', value: 'Elkhart County' },
    { state: 'IN', label: 'Fayette County', value: 'Fayette County' },
    { state: 'IN', label: 'Floyd County', value: 'Floyd County' },
    { state: 'IN', label: 'Fountain County', value: 'Fountain County' },
    { state: 'IN', label: 'Franklin County', value: 'Franklin County' },
    { state: 'IN', label: 'Fulton County', value: 'Fulton County' },
    { state: 'IN', label: 'Gibson County', value: 'Gibson County' },
    { state: 'IN', label: 'Grant County', value: 'Grant County' },
    { state: 'IN', label: 'Greene County', value: 'Greene County' },
    { state: 'IN', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'IN', label: 'Hancock County', value: 'Hancock County' },
    { state: 'IN', label: 'Harrison County', value: 'Harrison County' },
    { state: 'IN', label: 'Hendricks County', value: 'Hendricks County' },
    { state: 'IN', label: 'Henry County', value: 'Henry County' },
    { state: 'IN', label: 'Howard County', value: 'Howard County' },
    { state: 'IN', label: 'Huntington County', value: 'Huntington County' },
    { state: 'IN', label: 'Jackson County', value: 'Jackson County' },
    { state: 'IN', label: 'Jasper County', value: 'Jasper County' },
    { state: 'IN', label: 'Jay County', value: 'Jay County' },
    { state: 'IN', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'IN', label: 'Jennings County', value: 'Jennings County' },
    { state: 'IN', label: 'Johnson County', value: 'Johnson County' },
    { state: 'IN', label: 'Knox County', value: 'Knox County' },
    { state: 'IN', label: 'Kosciusko County', value: 'Kosciusko County' },
    { state: 'IN', label: 'LaGrange County', value: 'LaGrange County' },
    { state: 'IN', label: 'Lake County', value: 'Lake County' },
    { state: 'IN', label: 'LaPorte County', value: 'LaPorte County' },
    { state: 'IN', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'IN', label: 'Madison County', value: 'Madison County' },
    { state: 'IN', label: 'Marion County', value: 'Marion County' },
    { state: 'IN', label: 'Marshall County', value: 'Marshall County' },
    { state: 'IN', label: 'Martin County', value: 'Martin County' },
    { state: 'IN', label: 'Miami County', value: 'Miami County' },
    { state: 'IN', label: 'Monroe County', value: 'Monroe County' },
    { state: 'IN', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'IN', label: 'Morgan County', value: 'Morgan County' },
    { state: 'IN', label: 'Newton County', value: 'Newton County' },
    { state: 'IN', label: 'Noble County', value: 'Noble County' },
    { state: 'IN', label: 'Ohio County', value: 'Ohio County' },
    { state: 'IN', label: 'Orange County', value: 'Orange County' },
    { state: 'IN', label: 'Owen County', value: 'Owen County' },
    { state: 'IN', label: 'Parke County', value: 'Parke County' },
    { state: 'IN', label: 'Perry County', value: 'Perry County' },
    { state: 'IN', label: 'Pike County', value: 'Pike County' },
    { state: 'IN', label: 'Porter County', value: 'Porter County' },
    { state: 'IN', label: 'Posey County', value: 'Posey County' },
    { state: 'IN', label: 'Pulaski County', value: 'Pulaski County' },
    { state: 'IN', label: 'Putnam County', value: 'Putnam County' },
    { state: 'IN', label: 'Randolph County', value: 'Randolph County' },
    { state: 'IN', label: 'Ripley County', value: 'Ripley County' },
    { state: 'IN', label: 'Rush County', value: 'Rush County' },
    { state: 'IN', label: 'Scott County', value: 'Scott County' },
    { state: 'IN', label: 'Shelby County', value: 'Shelby County' },
    { state: 'IN', label: 'Spencer County', value: 'Spencer County' },
    { state: 'IN', label: 'St. Joseph County', value: 'St. Joseph County' },
    { state: 'IN', label: 'Starke County', value: 'Starke County' },
    { state: 'IN', label: 'Steuben County', value: 'Steuben County' },
    { state: 'IN', label: 'Sullivan County', value: 'Sullivan County' },
    { state: 'IN', label: 'Switzerland County', value: 'Switzerland County' },
    { state: 'IN', label: 'Tippecanoe County', value: 'Tippecanoe County' },
    { state: 'IN', label: 'Tipton County', value: 'Tipton County' },
    { state: 'IN', label: 'Union County', value: 'Union County' },
    { state: 'IN', label: 'Vanderburgh County', value: 'Vanderburgh County' },
    { state: 'IN', label: 'Vermillion County', value: 'Vermillion County' },
    { state: 'IN', label: 'Vigo County', value: 'Vigo County' },
    { state: 'IN', label: 'Wabash County', value: 'Wabash County' },
    { state: 'IN', label: 'Warren County', value: 'Warren County' },
    { state: 'IN', label: 'Warrick County', value: 'Warrick County' },
    { state: 'IN', label: 'Washington County', value: 'Washington County' },
    { state: 'IN', label: 'Wayne County', value: 'Wayne County' },
    { state: 'IN', label: 'Wells County', value: 'Wells County' },
    { state: 'IN', label: 'White County', value: 'White County' },
    { state: 'IN', label: 'Whitley County', value: 'Whitley County' },
    { state: 'KS', label: 'Allen County', value: 'Allen County' },
    { state: 'KS', label: 'Anderson County', value: 'Anderson County' },
    { state: 'KS', label: 'Atchison County', value: 'Atchison County' },
    { state: 'KS', label: 'Barber County', value: 'Barber County' },
    { state: 'KS', label: 'Barton County', value: 'Barton County' },
    { state: 'KS', label: 'Bourbon County', value: 'Bourbon County' },
    { state: 'KS', label: 'Brown County', value: 'Brown County' },
    { state: 'KS', label: 'Butler County', value: 'Butler County' },
    { state: 'KS', label: 'Chase County', value: 'Chase County' },
    { state: 'KS', label: 'Chautauqua County', value: 'Chautauqua County' },
    { state: 'KS', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'KS', label: 'Cheyenne County', value: 'Cheyenne County' },
    { state: 'KS', label: 'Clark County', value: 'Clark County' },
    { state: 'KS', label: 'Clay County', value: 'Clay County' },
    { state: 'KS', label: 'Cloud County', value: 'Cloud County' },
    { state: 'KS', label: 'Coffey County', value: 'Coffey County' },
    { state: 'KS', label: 'Comanche County', value: 'Comanche County' },
    { state: 'KS', label: 'Cowley County', value: 'Cowley County' },
    { state: 'KS', label: 'Crawford County', value: 'Crawford County' },
    { state: 'KS', label: 'Decatur County', value: 'Decatur County' },
    { state: 'KS', label: 'Dickinson County', value: 'Dickinson County' },
    { state: 'KS', label: 'Doniphan County', value: 'Doniphan County' },
    { state: 'KS', label: 'Douglas County', value: 'Douglas County' },
    { state: 'KS', label: 'Edwards County', value: 'Edwards County' },
    { state: 'KS', label: 'Elk County', value: 'Elk County' },
    { state: 'KS', label: 'Ellis County', value: 'Ellis County' },
    { state: 'KS', label: 'Ellsworth County', value: 'Ellsworth County' },
    { state: 'KS', label: 'Finney County', value: 'Finney County' },
    { state: 'KS', label: 'Ford County', value: 'Ford County' },
    { state: 'KS', label: 'Franklin County', value: 'Franklin County' },
    { state: 'KS', label: 'Geary County', value: 'Geary County' },
    { state: 'KS', label: 'Gove County', value: 'Gove County' },
    { state: 'KS', label: 'Graham County', value: 'Graham County' },
    { state: 'KS', label: 'Grant County', value: 'Grant County' },
    { state: 'KS', label: 'Gray County', value: 'Gray County' },
    { state: 'KS', label: 'Greeley County', value: 'Greeley County' },
    { state: 'KS', label: 'Greenwood County', value: 'Greenwood County' },
    { state: 'KS', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'KS', label: 'Harper County', value: 'Harper County' },
    { state: 'KS', label: 'Harvey County', value: 'Harvey County' },
    { state: 'KS', label: 'Haskell County', value: 'Haskell County' },
    { state: 'KS', label: 'Hodgeman County', value: 'Hodgeman County' },
    { state: 'KS', label: 'Jackson County', value: 'Jackson County' },
    { state: 'KS', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'KS', label: 'Jewell County', value: 'Jewell County' },
    { state: 'KS', label: 'Johnson County', value: 'Johnson County' },
    { state: 'KS', label: 'Kearny County', value: 'Kearny County' },
    { state: 'KS', label: 'Kingman County', value: 'Kingman County' },
    { state: 'KS', label: 'Kiowa County', value: 'Kiowa County' },
    { state: 'KS', label: 'Labette County', value: 'Labette County' },
    { state: 'KS', label: 'Lane County', value: 'Lane County' },
    { state: 'KS', label: 'Leavenworth County', value: 'Leavenworth County' },
    { state: 'KS', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'KS', label: 'Linn County', value: 'Linn County' },
    { state: 'KS', label: 'Logan County', value: 'Logan County' },
    { state: 'KS', label: 'Lyon County', value: 'Lyon County' },
    { state: 'KS', label: 'Marion County', value: 'Marion County' },
    { state: 'KS', label: 'Marshall County', value: 'Marshall County' },
    { state: 'KS', label: 'McPherson County', value: 'McPherson County' },
    { state: 'KS', label: 'Meade County', value: 'Meade County' },
    { state: 'KS', label: 'Miami County', value: 'Miami County' },
    { state: 'KS', label: 'Mitchell County', value: 'Mitchell County' },
    { state: 'KS', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'KS', label: 'Morris County', value: 'Morris County' },
    { state: 'KS', label: 'Morton County', value: 'Morton County' },
    { state: 'KS', label: 'Nemaha County', value: 'Nemaha County' },
    { state: 'KS', label: 'Neosho County', value: 'Neosho County' },
    { state: 'KS', label: 'Ness County', value: 'Ness County' },
    { state: 'KS', label: 'Norton County', value: 'Norton County' },
    { state: 'KS', label: 'Osage County', value: 'Osage County' },
    { state: 'KS', label: 'Osborne County', value: 'Osborne County' },
    { state: 'KS', label: 'Ottawa County', value: 'Ottawa County' },
    { state: 'KS', label: 'Pawnee County', value: 'Pawnee County' },
    { state: 'KS', label: 'Phillips County', value: 'Phillips County' },
    { state: 'KS', label: 'Pottawatomie County', value: 'Pottawatomie County' },
    { state: 'KS', label: 'Pratt County', value: 'Pratt County' },
    { state: 'KS', label: 'Rawlins County', value: 'Rawlins County' },
    { state: 'KS', label: 'Reno County', value: 'Reno County' },
    { state: 'KS', label: 'Republic County', value: 'Republic County' },
    { state: 'KS', label: 'Rice County', value: 'Rice County' },
    { state: 'KS', label: 'Riley County', value: 'Riley County' },
    { state: 'KS', label: 'Rooks County', value: 'Rooks County' },
    { state: 'KS', label: 'Rush County', value: 'Rush County' },
    { state: 'KS', label: 'Russell County', value: 'Russell County' },
    { state: 'KS', label: 'Saline County', value: 'Saline County' },
    { state: 'KS', label: 'Scott County', value: 'Scott County' },
    { state: 'KS', label: 'Sedgwick County', value: 'Sedgwick County' },
    { state: 'KS', label: 'Seward County', value: 'Seward County' },
    { state: 'KS', label: 'Shawnee County', value: 'Shawnee County' },
    { state: 'KS', label: 'Sheridan County', value: 'Sheridan County' },
    { state: 'KS', label: 'Sherman County', value: 'Sherman County' },
    { state: 'KS', label: 'Smith County', value: 'Smith County' },
    { state: 'KS', label: 'Stafford County', value: 'Stafford County' },
    { state: 'KS', label: 'Stanton County', value: 'Stanton County' },
    { state: 'KS', label: 'Stevens County', value: 'Stevens County' },
    { state: 'KS', label: 'Sumner County', value: 'Sumner County' },
    { state: 'KS', label: 'Thomas County', value: 'Thomas County' },
    { state: 'KS', label: 'Trego County', value: 'Trego County' },
    { state: 'KS', label: 'Wabaunsee County', value: 'Wabaunsee County' },
    { state: 'KS', label: 'Wallace County', value: 'Wallace County' },
    { state: 'KS', label: 'Washington County', value: 'Washington County' },
    { state: 'KS', label: 'Wichita County', value: 'Wichita County' },
    { state: 'KS', label: 'Wilson County', value: 'Wilson County' },
    { state: 'KS', label: 'Woodson County', value: 'Woodson County' },
    { state: 'KS', label: 'Wyandotte County', value: 'Wyandotte County' },
    { state: 'KY', label: 'Adair County', value: 'Adair County' },
    { state: 'KY', label: 'Allen County', value: 'Allen County' },
    { state: 'KY', label: 'Anderson County', value: 'Anderson County' },
    { state: 'KY', label: 'Ballard County', value: 'Ballard County' },
    { state: 'KY', label: 'Barren County', value: 'Barren County' },
    { state: 'KY', label: 'Bath County', value: 'Bath County' },
    { state: 'KY', label: 'Bell County', value: 'Bell County' },
    { state: 'KY', label: 'Boone County', value: 'Boone County' },
    { state: 'KY', label: 'Bourbon County', value: 'Bourbon County' },
    { state: 'KY', label: 'Boyd County', value: 'Boyd County' },
    { state: 'KY', label: 'Boyle County', value: 'Boyle County' },
    { state: 'KY', label: 'Bracken County', value: 'Bracken County' },
    { state: 'KY', label: 'Breathitt County', value: 'Breathitt County' },
    { state: 'KY', label: 'Breckinridge County', value: 'Breckinridge County' },
    { state: 'KY', label: 'Bullitt County', value: 'Bullitt County' },
    { state: 'KY', label: 'Butler County', value: 'Butler County' },
    { state: 'KY', label: 'Caldwell County', value: 'Caldwell County' },
    { state: 'KY', label: 'Calloway County', value: 'Calloway County' },
    { state: 'KY', label: 'Campbell County', value: 'Campbell County' },
    { state: 'KY', label: 'Carlisle County', value: 'Carlisle County' },
    { state: 'KY', label: 'Carroll County', value: 'Carroll County' },
    { state: 'KY', label: 'Carter County', value: 'Carter County' },
    { state: 'KY', label: 'Casey County', value: 'Casey County' },
    { state: 'KY', label: 'Christian County', value: 'Christian County' },
    { state: 'KY', label: 'Clark County', value: 'Clark County' },
    { state: 'KY', label: 'Clay County', value: 'Clay County' },
    { state: 'KY', label: 'Clinton County', value: 'Clinton County' },
    { state: 'KY', label: 'Crittenden County', value: 'Crittenden County' },
    { state: 'KY', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'KY', label: 'Daviess County', value: 'Daviess County' },
    { state: 'KY', label: 'Edmonson County', value: 'Edmonson County' },
    { state: 'KY', label: 'Elliott County', value: 'Elliott County' },
    { state: 'KY', label: 'Estill County', value: 'Estill County' },
    { state: 'KY', label: 'Fayette County', value: 'Fayette County' },
    { state: 'KY', label: 'Fleming County', value: 'Fleming County' },
    { state: 'KY', label: 'Floyd County', value: 'Floyd County' },
    { state: 'KY', label: 'Franklin County', value: 'Franklin County' },
    { state: 'KY', label: 'Fulton County', value: 'Fulton County' },
    { state: 'KY', label: 'Gallatin County', value: 'Gallatin County' },
    { state: 'KY', label: 'Garrard County', value: 'Garrard County' },
    { state: 'KY', label: 'Grant County', value: 'Grant County' },
    { state: 'KY', label: 'Graves County', value: 'Graves County' },
    { state: 'KY', label: 'Grayson County', value: 'Grayson County' },
    { state: 'KY', label: 'Green County', value: 'Green County' },
    { state: 'KY', label: 'Greenup County', value: 'Greenup County' },
    { state: 'KY', label: 'Hancock County', value: 'Hancock County' },
    { state: 'KY', label: 'Hardin County', value: 'Hardin County' },
    { state: 'KY', label: 'Harlan County', value: 'Harlan County' },
    { state: 'KY', label: 'Harrison County', value: 'Harrison County' },
    { state: 'KY', label: 'Hart County', value: 'Hart County' },
    { state: 'KY', label: 'Henderson County', value: 'Henderson County' },
    { state: 'KY', label: 'Henry County', value: 'Henry County' },
    { state: 'KY', label: 'Hickman County', value: 'Hickman County' },
    { state: 'KY', label: 'Hopkins County', value: 'Hopkins County' },
    { state: 'KY', label: 'Jackson County', value: 'Jackson County' },
    { state: 'KY', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'KY', label: 'Jessamine County', value: 'Jessamine County' },
    { state: 'KY', label: 'Johnson County', value: 'Johnson County' },
    { state: 'KY', label: 'Kenton County', value: 'Kenton County' },
    { state: 'KY', label: 'Knott County', value: 'Knott County' },
    { state: 'KY', label: 'Knox County', value: 'Knox County' },
    { state: 'KY', label: 'Larue County', value: 'Larue County' },
    { state: 'KY', label: 'Laurel County', value: 'Laurel County' },
    { state: 'KY', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'KY', label: 'Lee County', value: 'Lee County' },
    { state: 'KY', label: 'Leslie County', value: 'Leslie County' },
    { state: 'KY', label: 'Letcher County', value: 'Letcher County' },
    { state: 'KY', label: 'Lewis County', value: 'Lewis County' },
    { state: 'KY', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'KY', label: 'Livingston County', value: 'Livingston County' },
    { state: 'KY', label: 'Logan County', value: 'Logan County' },
    { state: 'KY', label: 'Lyon County', value: 'Lyon County' },
    { state: 'KY', label: 'Madison County', value: 'Madison County' },
    { state: 'KY', label: 'Magoffin County', value: 'Magoffin County' },
    { state: 'KY', label: 'Marion County', value: 'Marion County' },
    { state: 'KY', label: 'Marshall County', value: 'Marshall County' },
    { state: 'KY', label: 'Martin County', value: 'Martin County' },
    { state: 'KY', label: 'Mason County', value: 'Mason County' },
    { state: 'KY', label: 'McCracken County', value: 'McCracken County' },
    { state: 'KY', label: 'McCreary County', value: 'McCreary County' },
    { state: 'KY', label: 'McLean County', value: 'McLean County' },
    { state: 'KY', label: 'Meade County', value: 'Meade County' },
    { state: 'KY', label: 'Menifee County', value: 'Menifee County' },
    { state: 'KY', label: 'Mercer County', value: 'Mercer County' },
    { state: 'KY', label: 'Metcalfe County', value: 'Metcalfe County' },
    { state: 'KY', label: 'Monroe County', value: 'Monroe County' },
    { state: 'KY', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'KY', label: 'Morgan County', value: 'Morgan County' },
    { state: 'KY', label: 'Muhlenberg County', value: 'Muhlenberg County' },
    { state: 'KY', label: 'Nelson County', value: 'Nelson County' },
    { state: 'KY', label: 'Nicholas County', value: 'Nicholas County' },
    { state: 'KY', label: 'Ohio County', value: 'Ohio County' },
    { state: 'KY', label: 'Oldham County', value: 'Oldham County' },
    { state: 'KY', label: 'Owen County', value: 'Owen County' },
    { state: 'KY', label: 'Owsley County', value: 'Owsley County' },
    { state: 'KY', label: 'Pendleton County', value: 'Pendleton County' },
    { state: 'KY', label: 'Perry County', value: 'Perry County' },
    { state: 'KY', label: 'Pike County', value: 'Pike County' },
    { state: 'KY', label: 'Powell County', value: 'Powell County' },
    { state: 'KY', label: 'Pulaski County', value: 'Pulaski County' },
    { state: 'KY', label: 'Robertson County', value: 'Robertson County' },
    { state: 'KY', label: 'Rockcastle County', value: 'Rockcastle County' },
    { state: 'KY', label: 'Rowan County', value: 'Rowan County' },
    { state: 'KY', label: 'Russell County', value: 'Russell County' },
    { state: 'KY', label: 'Scott County', value: 'Scott County' },
    { state: 'KY', label: 'Shelby County', value: 'Shelby County' },
    { state: 'KY', label: 'Simpson County', value: 'Simpson County' },
    { state: 'KY', label: 'Spencer County', value: 'Spencer County' },
    { state: 'KY', label: 'Taylor County', value: 'Taylor County' },
    { state: 'KY', label: 'Todd County', value: 'Todd County' },
    { state: 'KY', label: 'Trigg County', value: 'Trigg County' },
    { state: 'KY', label: 'Trimble County', value: 'Trimble County' },
    { state: 'KY', label: 'Union County', value: 'Union County' },
    { state: 'KY', label: 'Warren County', value: 'Warren County' },
    { state: 'KY', label: 'Washington County', value: 'Washington County' },
    { state: 'KY', label: 'Wayne County', value: 'Wayne County' },
    { state: 'KY', label: 'Webster County', value: 'Webster County' },
    { state: 'KY', label: 'Whitley County', value: 'Whitley County' },
    { state: 'KY', label: 'Wolfe County', value: 'Wolfe County' },
    { state: 'KY', label: 'Woodford County', value: 'Woodford County' },
    { state: 'LA', label: 'Acadia Parish', value: 'Acadia Parish' },
    { state: 'LA', label: 'Allen Parish', value: 'Allen Parish' },
    { state: 'LA', label: 'Ascension Parish', value: 'Ascension Parish' },
    { state: 'LA', label: 'Assumption Parish', value: 'Assumption Parish' },
    { state: 'LA', label: 'Avoyelles Parish', value: 'Avoyelles Parish' },
    { state: 'LA', label: 'Beauregard Parish', value: 'Beauregard Parish' },
    { state: 'LA', label: 'Bienville Parish', value: 'Bienville Parish' },
    { state: 'LA', label: 'Bossier Parish', value: 'Bossier Parish' },
    { state: 'LA', label: 'Caddo Parish', value: 'Caddo Parish' },
    { state: 'LA', label: 'Calcasieu Parish', value: 'Calcasieu Parish' },
    { state: 'LA', label: 'Caldwell Parish', value: 'Caldwell Parish' },
    { state: 'LA', label: 'Cameron Parish', value: 'Cameron Parish' },
    { state: 'LA', label: 'Catahoula Parish', value: 'Catahoula Parish' },
    { state: 'LA', label: 'Claiborne Parish', value: 'Claiborne Parish' },
    { state: 'LA', label: 'Concordia Parish', value: 'Concordia Parish' },
    { state: 'LA', label: 'De Soto Parish', value: 'De Soto Parish' },
    { state: 'LA', label: 'East Baton Rouge Parish', value: 'East Baton Rouge Parish' },
    { state: 'LA', label: 'East Carroll Parish', value: 'East Carroll Parish' },
    { state: 'LA', label: 'East Feliciana Parish', value: 'East Feliciana Parish' },
    { state: 'LA', label: 'Evangeline Parish', value: 'Evangeline Parish' },
    { state: 'LA', label: 'Franklin Parish', value: 'Franklin Parish' },
    { state: 'LA', label: 'Grant Parish', value: 'Grant Parish' },
    { state: 'LA', label: 'Iberia Parish', value: 'Iberia Parish' },
    { state: 'LA', label: 'Iberville Parish', value: 'Iberville Parish' },
    { state: 'LA', label: 'Jackson Parish', value: 'Jackson Parish' },
    { state: 'LA', label: 'Jefferson Davis Parish', value: 'Jefferson Davis Parish' },
    { state: 'LA', label: 'Jefferson Parish', value: 'Jefferson Parish' },
    { state: 'LA', label: 'La Salle Parish', value: 'La Salle Parish' },
    { state: 'LA', label: 'Lafayette Parish', value: 'Lafayette Parish' },
    { state: 'LA', label: 'Lafourche Parish', value: 'Lafourche Parish' },
    { state: 'LA', label: 'Lincoln Parish', value: 'Lincoln Parish' },
    { state: 'LA', label: 'Livingston Parish', value: 'Livingston Parish' },
    { state: 'LA', label: 'Madison Parish', value: 'Madison Parish' },
    { state: 'LA', label: 'Morehouse Parish', value: 'Morehouse Parish' },
    { state: 'LA', label: 'Natchitoches Parish', value: 'Natchitoches Parish' },
    { state: 'LA', label: 'Orleans Parish', value: 'Orleans Parish' },
    { state: 'LA', label: 'Ouachita Parish', value: 'Ouachita Parish' },
    { state: 'LA', label: 'Plaquemines Parish', value: 'Plaquemines Parish' },
    { state: 'LA', label: 'Pointe Coupee Parish', value: 'Pointe Coupee Parish' },
    { state: 'LA', label: 'Rapides Parish', value: 'Rapides Parish' },
    { state: 'LA', label: 'Red River Parish', value: 'Red River Parish' },
    { state: 'LA', label: 'Richland Parish', value: 'Richland Parish' },
    { state: 'LA', label: 'Sabine Parish', value: 'Sabine Parish' },
    { state: 'LA', label: 'St. Bernard Parish', value: 'St. Bernard Parish' },
    { state: 'LA', label: 'St. Charles Parish', value: 'St. Charles Parish' },
    { state: 'LA', label: 'St. Helena Parish', value: 'St. Helena Parish' },
    { state: 'LA', label: 'St. James Parish', value: 'St. James Parish' },
    { state: 'LA', label: 'St. John the Baptist Parish', value: 'St. John the Baptist Parish' },
    { state: 'LA', label: 'St. Landry Parish', value: 'St. Landry Parish' },
    { state: 'LA', label: 'St. Martin Parish', value: 'St. Martin Parish' },
    { state: 'LA', label: 'St. Mary Parish', value: 'St. Mary Parish' },
    { state: 'LA', label: 'St. Tammany Parish', value: 'St. Tammany Parish' },
    { state: 'LA', label: 'Tangipahoa Parish', value: 'Tangipahoa Parish' },
    { state: 'LA', label: 'Tensas Parish', value: 'Tensas Parish' },
    { state: 'LA', label: 'Terrebonne Parish', value: 'Terrebonne Parish' },
    { state: 'LA', label: 'Union Parish', value: 'Union Parish' },
    { state: 'LA', label: 'Vermilion Parish', value: 'Vermilion Parish' },
    { state: 'LA', label: 'Vernon Parish', value: 'Vernon Parish' },
    { state: 'LA', label: 'Washington Parish', value: 'Washington Parish' },
    { state: 'LA', label: 'Webster Parish', value: 'Webster Parish' },
    { state: 'LA', label: 'West Baton Rouge Parish', value: 'West Baton Rouge Parish' },
    { state: 'LA', label: 'West Carroll Parish', value: 'West Carroll Parish' },
    { state: 'LA', label: 'West Feliciana Parish', value: 'West Feliciana Parish' },
    { state: 'LA', label: 'Winn Parish', value: 'Winn Parish' },
    { state: 'MA', label: 'Barnstable County', value: 'Barnstable County' },
    { state: 'MA', label: 'Berkshire County', value: 'Berkshire County' },
    { state: 'MA', label: 'Bristol County', value: 'Bristol County' },
    { state: 'MA', label: 'Dukes County', value: 'Dukes County' },
    { state: 'MA', label: 'Essex County', value: 'Essex County' },
    { state: 'MA', label: 'Franklin County', value: 'Franklin County' },
    { state: 'MA', label: 'Hampden County', value: 'Hampden County' },
    { state: 'MA', label: 'Hampshire County', value: 'Hampshire County' },
    { state: 'MA', label: 'Middlesex County', value: 'Middlesex County' },
    { state: 'MA', label: 'Nantucket County', value: 'Nantucket County' },
    { state: 'MA', label: 'Norfolk County', value: 'Norfolk County' },
    { state: 'MA', label: 'Plymouth County', value: 'Plymouth County' },
    { state: 'MA', label: 'Suffolk County', value: 'Suffolk County' },
    { state: 'MA', label: 'Worcester County', value: 'Worcester County' },
    { state: 'MD', label: 'Allegany County', value: 'Allegany County' },
    { state: 'MD', label: 'Anne Arundel County', value: 'Anne Arundel County' },
    { state: 'MD', label: 'Baltimore city', value: 'Baltimore city' },
    { state: 'MD', label: 'Baltimore County', value: 'Baltimore County' },
    { state: 'MD', label: 'Calvert County', value: 'Calvert County' },
    { state: 'MD', label: 'Caroline County', value: 'Caroline County' },
    { state: 'MD', label: 'Carroll County', value: 'Carroll County' },
    { state: 'MD', label: 'Cecil County', value: 'Cecil County' },
    { state: 'MD', label: 'Charles County', value: 'Charles County' },
    { state: 'MD', label: 'Dorchester County', value: 'Dorchester County' },
    { state: 'MD', label: 'Frederick County', value: 'Frederick County' },
    { state: 'MD', label: 'Garrett County', value: 'Garrett County' },
    { state: 'MD', label: 'Harford County', value: 'Harford County' },
    { state: 'MD', label: 'Howard County', value: 'Howard County' },
    { state: 'MD', label: 'Kent County', value: 'Kent County' },
    { state: 'MD', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'MD', label: "Prince George's County", value: "Prince George's County" },
    { state: 'MD', label: "Queen Anne's County", value: "Queen Anne's County" },
    { state: 'MD', label: 'Somerset County', value: 'Somerset County' },
    { state: 'MD', label: "St. Mary's County", value: "St. Mary's County" },
    { state: 'MD', label: 'Talbot County', value: 'Talbot County' },
    { state: 'MD', label: 'Washington County', value: 'Washington County' },
    { state: 'MD', label: 'Wicomico County', value: 'Wicomico County' },
    { state: 'MD', label: 'Worcester County', value: 'Worcester County' },
    { state: 'ME', label: 'Androscoggin County', value: 'Androscoggin County' },
    { state: 'ME', label: 'Aroostook County', value: 'Aroostook County' },
    { state: 'ME', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'ME', label: 'Franklin County', value: 'Franklin County' },
    { state: 'ME', label: 'Hancock County', value: 'Hancock County' },
    { state: 'ME', label: 'Kennebec County', value: 'Kennebec County' },
    { state: 'ME', label: 'Knox County', value: 'Knox County' },
    { state: 'ME', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'ME', label: 'Oxford County', value: 'Oxford County' },
    { state: 'ME', label: 'Penobscot County', value: 'Penobscot County' },
    { state: 'ME', label: 'Piscataquis County', value: 'Piscataquis County' },
    { state: 'ME', label: 'Sagadahoc County', value: 'Sagadahoc County' },
    { state: 'ME', label: 'Somerset County', value: 'Somerset County' },
    { state: 'ME', label: 'Waldo County', value: 'Waldo County' },
    { state: 'ME', label: 'Washington County', value: 'Washington County' },
    { state: 'ME', label: 'York County', value: 'York County' },
    { state: 'MI', label: 'Alcona County', value: 'Alcona County' },
    { state: 'MI', label: 'Alger County', value: 'Alger County' },
    { state: 'MI', label: 'Allegan County', value: 'Allegan County' },
    { state: 'MI', label: 'Alpena County', value: 'Alpena County' },
    { state: 'MI', label: 'Antrim County', value: 'Antrim County' },
    { state: 'MI', label: 'Arenac County', value: 'Arenac County' },
    { state: 'MI', label: 'Baraga County', value: 'Baraga County' },
    { state: 'MI', label: 'Barry County', value: 'Barry County' },
    { state: 'MI', label: 'Bay County', value: 'Bay County' },
    { state: 'MI', label: 'Benzie County', value: 'Benzie County' },
    { state: 'MI', label: 'Berrien County', value: 'Berrien County' },
    { state: 'MI', label: 'Branch County', value: 'Branch County' },
    { state: 'MI', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'MI', label: 'Cass County', value: 'Cass County' },
    { state: 'MI', label: 'Charlevoix County', value: 'Charlevoix County' },
    { state: 'MI', label: 'Cheboygan County', value: 'Cheboygan County' },
    { state: 'MI', label: 'Chippewa County', value: 'Chippewa County' },
    { state: 'MI', label: 'Clare County', value: 'Clare County' },
    { state: 'MI', label: 'Clinton County', value: 'Clinton County' },
    { state: 'MI', label: 'Crawford County', value: 'Crawford County' },
    { state: 'MI', label: 'Delta County', value: 'Delta County' },
    { state: 'MI', label: 'Dickinson County', value: 'Dickinson County' },
    { state: 'MI', label: 'Eaton County', value: 'Eaton County' },
    { state: 'MI', label: 'Emmet County', value: 'Emmet County' },
    { state: 'MI', label: 'Genesee County', value: 'Genesee County' },
    { state: 'MI', label: 'Gladwin County', value: 'Gladwin County' },
    { state: 'MI', label: 'Gogebic County', value: 'Gogebic County' },
    { state: 'MI', label: 'Grand Traverse County', value: 'Grand Traverse County' },
    { state: 'MI', label: 'Gratiot County', value: 'Gratiot County' },
    { state: 'MI', label: 'Hillsdale County', value: 'Hillsdale County' },
    { state: 'MI', label: 'Houghton County', value: 'Houghton County' },
    { state: 'MI', label: 'Huron County', value: 'Huron County' },
    { state: 'MI', label: 'Ingham County', value: 'Ingham County' },
    { state: 'MI', label: 'Ionia County', value: 'Ionia County' },
    { state: 'MI', label: 'Iosco County', value: 'Iosco County' },
    { state: 'MI', label: 'Iron County', value: 'Iron County' },
    { state: 'MI', label: 'Isabella County', value: 'Isabella County' },
    { state: 'MI', label: 'Jackson County', value: 'Jackson County' },
    { state: 'MI', label: 'Kalamazoo County', value: 'Kalamazoo County' },
    { state: 'MI', label: 'Kalkaska County', value: 'Kalkaska County' },
    { state: 'MI', label: 'Kent County', value: 'Kent County' },
    { state: 'MI', label: 'Keweenaw County', value: 'Keweenaw County' },
    { state: 'MI', label: 'Lake County', value: 'Lake County' },
    { state: 'MI', label: 'Lapeer County', value: 'Lapeer County' },
    { state: 'MI', label: 'Leelanau County', value: 'Leelanau County' },
    { state: 'MI', label: 'Lenawee County', value: 'Lenawee County' },
    { state: 'MI', label: 'Livingston County', value: 'Livingston County' },
    { state: 'MI', label: 'Luce County', value: 'Luce County' },
    { state: 'MI', label: 'Mackinac County', value: 'Mackinac County' },
    { state: 'MI', label: 'Macomb County', value: 'Macomb County' },
    { state: 'MI', label: 'Manistee County', value: 'Manistee County' },
    { state: 'MI', label: 'Marquette County', value: 'Marquette County' },
    { state: 'MI', label: 'Mason County', value: 'Mason County' },
    { state: 'MI', label: 'Mecosta County', value: 'Mecosta County' },
    { state: 'MI', label: 'Menominee County', value: 'Menominee County' },
    { state: 'MI', label: 'Midland County', value: 'Midland County' },
    { state: 'MI', label: 'Missaukee County', value: 'Missaukee County' },
    { state: 'MI', label: 'Monroe County', value: 'Monroe County' },
    { state: 'MI', label: 'Montcalm County', value: 'Montcalm County' },
    { state: 'MI', label: 'Montmorency County', value: 'Montmorency County' },
    { state: 'MI', label: 'Muskegon County', value: 'Muskegon County' },
    { state: 'MI', label: 'Newaygo County', value: 'Newaygo County' },
    { state: 'MI', label: 'Oakland County', value: 'Oakland County' },
    { state: 'MI', label: 'Oceana County', value: 'Oceana County' },
    { state: 'MI', label: 'Ogemaw County', value: 'Ogemaw County' },
    { state: 'MI', label: 'Ontonagon County', value: 'Ontonagon County' },
    { state: 'MI', label: 'Osceola County', value: 'Osceola County' },
    { state: 'MI', label: 'Oscoda County', value: 'Oscoda County' },
    { state: 'MI', label: 'Otsego County', value: 'Otsego County' },
    { state: 'MI', label: 'Ottawa County', value: 'Ottawa County' },
    { state: 'MI', label: 'Presque Isle County', value: 'Presque Isle County' },
    { state: 'MI', label: 'Roscommon County', value: 'Roscommon County' },
    { state: 'MI', label: 'Saginaw County', value: 'Saginaw County' },
    { state: 'MI', label: 'Sanilac County', value: 'Sanilac County' },
    { state: 'MI', label: 'Schoolcraft County', value: 'Schoolcraft County' },
    { state: 'MI', label: 'Shiawassee County', value: 'Shiawassee County' },
    { state: 'MI', label: 'St. Clair County', value: 'St. Clair County' },
    { state: 'MI', label: 'St. Joseph County', value: 'St. Joseph County' },
    { state: 'MI', label: 'Tuscola County', value: 'Tuscola County' },
    { state: 'MI', label: 'Van Buren County', value: 'Van Buren County' },
    { state: 'MI', label: 'Washtenaw County', value: 'Washtenaw County' },
    { state: 'MI', label: 'Wayne County', value: 'Wayne County' },
    { state: 'MI', label: 'Wexford County', value: 'Wexford County' },
    { state: 'MN', label: 'Aitkin County', value: 'Aitkin County' },
    { state: 'MN', label: 'Anoka County', value: 'Anoka County' },
    { state: 'MN', label: 'Becker County', value: 'Becker County' },
    { state: 'MN', label: 'Beltrami County', value: 'Beltrami County' },
    { state: 'MN', label: 'Benton County', value: 'Benton County' },
    { state: 'MN', label: 'Big Stone County', value: 'Big Stone County' },
    { state: 'MN', label: 'Blue Earth County', value: 'Blue Earth County' },
    { state: 'MN', label: 'Brown County', value: 'Brown County' },
    { state: 'MN', label: 'Carlton County', value: 'Carlton County' },
    { state: 'MN', label: 'Carver County', value: 'Carver County' },
    { state: 'MN', label: 'Cass County', value: 'Cass County' },
    { state: 'MN', label: 'Chippewa County', value: 'Chippewa County' },
    { state: 'MN', label: 'Chisago County', value: 'Chisago County' },
    { state: 'MN', label: 'Clay County', value: 'Clay County' },
    { state: 'MN', label: 'Clearwater County', value: 'Clearwater County' },
    { state: 'MN', label: 'Cook County', value: 'Cook County' },
    { state: 'MN', label: 'Cottonwood County', value: 'Cottonwood County' },
    { state: 'MN', label: 'Crow Wing County', value: 'Crow Wing County' },
    { state: 'MN', label: 'Dakota County', value: 'Dakota County' },
    { state: 'MN', label: 'Dodge County', value: 'Dodge County' },
    { state: 'MN', label: 'Douglas County', value: 'Douglas County' },
    { state: 'MN', label: 'Faribault County', value: 'Faribault County' },
    { state: 'MN', label: 'Fillmore County', value: 'Fillmore County' },
    { state: 'MN', label: 'Freeborn County', value: 'Freeborn County' },
    { state: 'MN', label: 'Goodhue County', value: 'Goodhue County' },
    { state: 'MN', label: 'Grant County', value: 'Grant County' },
    { state: 'MN', label: 'Hennepin County', value: 'Hennepin County' },
    { state: 'MN', label: 'Houston County', value: 'Houston County' },
    { state: 'MN', label: 'Hubbard County', value: 'Hubbard County' },
    { state: 'MN', label: 'Isanti County', value: 'Isanti County' },
    { state: 'MN', label: 'Itasca County', value: 'Itasca County' },
    { state: 'MN', label: 'Jackson County', value: 'Jackson County' },
    { state: 'MN', label: 'Kanabec County', value: 'Kanabec County' },
    { state: 'MN', label: 'Kandiyohi County', value: 'Kandiyohi County' },
    { state: 'MN', label: 'Kittson County', value: 'Kittson County' },
    { state: 'MN', label: 'Koochiching County', value: 'Koochiching County' },
    { state: 'MN', label: 'Lac qui Parle County', value: 'Lac qui Parle County' },
    { state: 'MN', label: 'Lake County', value: 'Lake County' },
    { state: 'MN', label: 'Lake of the Woods County', value: 'Lake of the Woods County' },
    { state: 'MN', label: 'Le Sueur County', value: 'Le Sueur County' },
    { state: 'MN', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'MN', label: 'Lyon County', value: 'Lyon County' },
    { state: 'MN', label: 'Mahnomen County', value: 'Mahnomen County' },
    { state: 'MN', label: 'Marshall County', value: 'Marshall County' },
    { state: 'MN', label: 'Martin County', value: 'Martin County' },
    { state: 'MN', label: 'McLeod County', value: 'McLeod County' },
    { state: 'MN', label: 'Meeker County', value: 'Meeker County' },
    { state: 'MN', label: 'Mille Lacs County', value: 'Mille Lacs County' },
    { state: 'MN', label: 'Morrison County', value: 'Morrison County' },
    { state: 'MN', label: 'Mower County', value: 'Mower County' },
    { state: 'MN', label: 'Murray County', value: 'Murray County' },
    { state: 'MN', label: 'Nicollet County', value: 'Nicollet County' },
    { state: 'MN', label: 'Nobles County', value: 'Nobles County' },
    { state: 'MN', label: 'Norman County', value: 'Norman County' },
    { state: 'MN', label: 'Olmsted County', value: 'Olmsted County' },
    { state: 'MN', label: 'Otter Tail County', value: 'Otter Tail County' },
    { state: 'MN', label: 'Pennington County', value: 'Pennington County' },
    { state: 'MN', label: 'Pine County', value: 'Pine County' },
    { state: 'MN', label: 'Pipestone County', value: 'Pipestone County' },
    { state: 'MN', label: 'Polk County', value: 'Polk County' },
    { state: 'MN', label: 'Pope County', value: 'Pope County' },
    { state: 'MN', label: 'Ramsey County', value: 'Ramsey County' },
    { state: 'MN', label: 'Red Lake County', value: 'Red Lake County' },
    { state: 'MN', label: 'Redwood County', value: 'Redwood County' },
    { state: 'MN', label: 'Renville County', value: 'Renville County' },
    { state: 'MN', label: 'Rice County', value: 'Rice County' },
    { state: 'MN', label: 'Rock County', value: 'Rock County' },
    { state: 'MN', label: 'Roseau County', value: 'Roseau County' },
    { state: 'MN', label: 'Scott County', value: 'Scott County' },
    { state: 'MN', label: 'Sherburne County', value: 'Sherburne County' },
    { state: 'MN', label: 'Sibley County', value: 'Sibley County' },
    { state: 'MN', label: 'St. Louis County', value: 'St. Louis County' },
    { state: 'MN', label: 'Stearns County', value: 'Stearns County' },
    { state: 'MN', label: 'Steele County', value: 'Steele County' },
    { state: 'MN', label: 'Stevens County', value: 'Stevens County' },
    { state: 'MN', label: 'Swift County', value: 'Swift County' },
    { state: 'MN', label: 'Todd County', value: 'Todd County' },
    { state: 'MN', label: 'Traverse County', value: 'Traverse County' },
    { state: 'MN', label: 'Wabasha County', value: 'Wabasha County' },
    { state: 'MN', label: 'Wadena County', value: 'Wadena County' },
    { state: 'MN', label: 'Waseca County', value: 'Waseca County' },
    { state: 'MN', label: 'Washington County', value: 'Washington County' },
    { state: 'MN', label: 'Watonwan County', value: 'Watonwan County' },
    { state: 'MN', label: 'Wilkin County', value: 'Wilkin County' },
    { state: 'MN', label: 'Winona County', value: 'Winona County' },
    { state: 'MN', label: 'Wright County', value: 'Wright County' },
    { state: 'MN', label: 'Yellow Medicine County', value: 'Yellow Medicine County' },
    { state: 'MO', label: 'Adair County', value: 'Adair County' },
    { state: 'MO', label: 'Andrew County', value: 'Andrew County' },
    { state: 'MO', label: 'Atchison County', value: 'Atchison County' },
    { state: 'MO', label: 'Audrain County', value: 'Audrain County' },
    { state: 'MO', label: 'Barry County', value: 'Barry County' },
    { state: 'MO', label: 'Barton County', value: 'Barton County' },
    { state: 'MO', label: 'Bates County', value: 'Bates County' },
    { state: 'MO', label: 'Benton County', value: 'Benton County' },
    { state: 'MO', label: 'Bollinger County', value: 'Bollinger County' },
    { state: 'MO', label: 'Boone County', value: 'Boone County' },
    { state: 'MO', label: 'Buchanan County', value: 'Buchanan County' },
    { state: 'MO', label: 'Butler County', value: 'Butler County' },
    { state: 'MO', label: 'Caldwell County', value: 'Caldwell County' },
    { state: 'MO', label: 'Callaway County', value: 'Callaway County' },
    { state: 'MO', label: 'Camden County', value: 'Camden County' },
    { state: 'MO', label: 'Cape Girardeau County', value: 'Cape Girardeau County' },
    { state: 'MO', label: 'Carroll County', value: 'Carroll County' },
    { state: 'MO', label: 'Carter County', value: 'Carter County' },
    { state: 'MO', label: 'Cass County', value: 'Cass County' },
    { state: 'MO', label: 'Cedar County', value: 'Cedar County' },
    { state: 'MO', label: 'Chariton County', value: 'Chariton County' },
    { state: 'MO', label: 'Christian County', value: 'Christian County' },
    { state: 'MO', label: 'Clark County', value: 'Clark County' },
    { state: 'MO', label: 'Clay County', value: 'Clay County' },
    { state: 'MO', label: 'Clinton County', value: 'Clinton County' },
    { state: 'MO', label: 'Cole County', value: 'Cole County' },
    { state: 'MO', label: 'Cooper County', value: 'Cooper County' },
    { state: 'MO', label: 'Crawford County', value: 'Crawford County' },
    { state: 'MO', label: 'Dade County', value: 'Dade County' },
    { state: 'MO', label: 'Dallas County', value: 'Dallas County' },
    { state: 'MO', label: 'Daviess County', value: 'Daviess County' },
    { state: 'MO', label: 'DeKalb County', value: 'DeKalb County' },
    { state: 'MO', label: 'Dent County', value: 'Dent County' },
    { state: 'MO', label: 'Douglas County', value: 'Douglas County' },
    { state: 'MO', label: 'Dunklin County', value: 'Dunklin County' },
    { state: 'MO', label: 'Franklin County', value: 'Franklin County' },
    { state: 'MO', label: 'Gasconade County', value: 'Gasconade County' },
    { state: 'MO', label: 'Gentry County', value: 'Gentry County' },
    { state: 'MO', label: 'Greene County', value: 'Greene County' },
    { state: 'MO', label: 'Grundy County', value: 'Grundy County' },
    { state: 'MO', label: 'Harrison County', value: 'Harrison County' },
    { state: 'MO', label: 'Henry County', value: 'Henry County' },
    { state: 'MO', label: 'Hickory County', value: 'Hickory County' },
    { state: 'MO', label: 'Holt County', value: 'Holt County' },
    { state: 'MO', label: 'Howard County', value: 'Howard County' },
    { state: 'MO', label: 'Howell County', value: 'Howell County' },
    { state: 'MO', label: 'Iron County', value: 'Iron County' },
    { state: 'MO', label: 'Jackson County', value: 'Jackson County' },
    { state: 'MO', label: 'Jasper County', value: 'Jasper County' },
    { state: 'MO', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'MO', label: 'Johnson County', value: 'Johnson County' },
    { state: 'MO', label: 'Knox County', value: 'Knox County' },
    { state: 'MO', label: 'Laclede County', value: 'Laclede County' },
    { state: 'MO', label: 'Lafayette County', value: 'Lafayette County' },
    { state: 'MO', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'MO', label: 'Lewis County', value: 'Lewis County' },
    { state: 'MO', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'MO', label: 'Linn County', value: 'Linn County' },
    { state: 'MO', label: 'Livingston County', value: 'Livingston County' },
    { state: 'MO', label: 'Macon County', value: 'Macon County' },
    { state: 'MO', label: 'Madison County', value: 'Madison County' },
    { state: 'MO', label: 'Maries County', value: 'Maries County' },
    { state: 'MO', label: 'Marion County', value: 'Marion County' },
    { state: 'MO', label: 'McDonald County', value: 'McDonald County' },
    { state: 'MO', label: 'Mercer County', value: 'Mercer County' },
    { state: 'MO', label: 'Miller County', value: 'Miller County' },
    { state: 'MO', label: 'Mississippi County', value: 'Mississippi County' },
    { state: 'MO', label: 'Moniteau County', value: 'Moniteau County' },
    { state: 'MO', label: 'Monroe County', value: 'Monroe County' },
    { state: 'MO', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'MO', label: 'Morgan County', value: 'Morgan County' },
    { state: 'MO', label: 'New Madrid County', value: 'New Madrid County' },
    { state: 'MO', label: 'Newton County', value: 'Newton County' },
    { state: 'MO', label: 'Nodaway County', value: 'Nodaway County' },
    { state: 'MO', label: 'Oregon County', value: 'Oregon County' },
    { state: 'MO', label: 'Osage County', value: 'Osage County' },
    { state: 'MO', label: 'Ozark County', value: 'Ozark County' },
    { state: 'MO', label: 'Pemiscot County', value: 'Pemiscot County' },
    { state: 'MO', label: 'Perry County', value: 'Perry County' },
    { state: 'MO', label: 'Pettis County', value: 'Pettis County' },
    { state: 'MO', label: 'Phelps County', value: 'Phelps County' },
    { state: 'MO', label: 'Pike County', value: 'Pike County' },
    { state: 'MO', label: 'Platte County', value: 'Platte County' },
    { state: 'MO', label: 'Polk County', value: 'Polk County' },
    { state: 'MO', label: 'Pulaski County', value: 'Pulaski County' },
    { state: 'MO', label: 'Putnam County', value: 'Putnam County' },
    { state: 'MO', label: 'Ralls County', value: 'Ralls County' },
    { state: 'MO', label: 'Randolph County', value: 'Randolph County' },
    { state: 'MO', label: 'Ray County', value: 'Ray County' },
    { state: 'MO', label: 'Reynolds County', value: 'Reynolds County' },
    { state: 'MO', label: 'Ripley County', value: 'Ripley County' },
    { state: 'MO', label: 'Saline County', value: 'Saline County' },
    { state: 'MO', label: 'Schuyler County', value: 'Schuyler County' },
    { state: 'MO', label: 'Scotland County', value: 'Scotland County' },
    { state: 'MO', label: 'Scott County', value: 'Scott County' },
    { state: 'MO', label: 'Shannon County', value: 'Shannon County' },
    { state: 'MO', label: 'Shelby County', value: 'Shelby County' },
    { state: 'MO', label: 'St. Charles County', value: 'St. Charles County' },
    { state: 'MO', label: 'St. Clair County', value: 'St. Clair County' },
    { state: 'MO', label: 'St. Francois County', value: 'St. Francois County' },
    { state: 'MO', label: 'St. Louis city', value: 'St. Louis city' },
    { state: 'MO', label: 'St. Louis County', value: 'St. Louis County' },
    { state: 'MO', label: 'Ste. Genevieve County', value: 'Ste. Genevieve County' },
    { state: 'MO', label: 'Stoddard County', value: 'Stoddard County' },
    { state: 'MO', label: 'Stone County', value: 'Stone County' },
    { state: 'MO', label: 'Sullivan County', value: 'Sullivan County' },
    { state: 'MO', label: 'Taney County', value: 'Taney County' },
    { state: 'MO', label: 'Texas County', value: 'Texas County' },
    { state: 'MO', label: 'Vernon County', value: 'Vernon County' },
    { state: 'MO', label: 'Warren County', value: 'Warren County' },
    { state: 'MO', label: 'Washington County', value: 'Washington County' },
    { state: 'MO', label: 'Wayne County', value: 'Wayne County' },
    { state: 'MO', label: 'Webster County', value: 'Webster County' },
    { state: 'MO', label: 'Worth County', value: 'Worth County' },
    { state: 'MO', label: 'Wright County', value: 'Wright County' },
    { state: 'MS', label: 'Adams County', value: 'Adams County' },
    { state: 'MS', label: 'Alcorn County', value: 'Alcorn County' },
    { state: 'MS', label: 'Amite County', value: 'Amite County' },
    { state: 'MS', label: 'Attala County', value: 'Attala County' },
    { state: 'MS', label: 'Benton County', value: 'Benton County' },
    { state: 'MS', label: 'Bolivar County', value: 'Bolivar County' },
    { state: 'MS', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'MS', label: 'Carroll County', value: 'Carroll County' },
    { state: 'MS', label: 'Chickasaw County', value: 'Chickasaw County' },
    { state: 'MS', label: 'Choctaw County', value: 'Choctaw County' },
    { state: 'MS', label: 'Claiborne County', value: 'Claiborne County' },
    { state: 'MS', label: 'Clarke County', value: 'Clarke County' },
    { state: 'MS', label: 'Clay County', value: 'Clay County' },
    { state: 'MS', label: 'Coahoma County', value: 'Coahoma County' },
    { state: 'MS', label: 'Copiah County', value: 'Copiah County' },
    { state: 'MS', label: 'Covington County', value: 'Covington County' },
    { state: 'MS', label: 'DeSoto County', value: 'DeSoto County' },
    { state: 'MS', label: 'Forrest County', value: 'Forrest County' },
    { state: 'MS', label: 'Franklin County', value: 'Franklin County' },
    { state: 'MS', label: 'George County', value: 'George County' },
    { state: 'MS', label: 'Greene County', value: 'Greene County' },
    { state: 'MS', label: 'Grenada County', value: 'Grenada County' },
    { state: 'MS', label: 'Hancock County', value: 'Hancock County' },
    { state: 'MS', label: 'Harrison County', value: 'Harrison County' },
    { state: 'MS', label: 'Hinds County', value: 'Hinds County' },
    { state: 'MS', label: 'Holmes County', value: 'Holmes County' },
    { state: 'MS', label: 'Humphreys County', value: 'Humphreys County' },
    { state: 'MS', label: 'Issaquena County', value: 'Issaquena County' },
    { state: 'MS', label: 'Itawamba County', value: 'Itawamba County' },
    { state: 'MS', label: 'Jackson County', value: 'Jackson County' },
    { state: 'MS', label: 'Jasper County', value: 'Jasper County' },
    { state: 'MS', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'MS', label: 'Jefferson Davis County', value: 'Jefferson Davis County' },
    { state: 'MS', label: 'Jones County', value: 'Jones County' },
    { state: 'MS', label: 'Kemper County', value: 'Kemper County' },
    { state: 'MS', label: 'Lafayette County', value: 'Lafayette County' },
    { state: 'MS', label: 'Lamar County', value: 'Lamar County' },
    { state: 'MS', label: 'Lauderdale County', value: 'Lauderdale County' },
    { state: 'MS', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'MS', label: 'Leake County', value: 'Leake County' },
    { state: 'MS', label: 'Lee County', value: 'Lee County' },
    { state: 'MS', label: 'Leflore County', value: 'Leflore County' },
    { state: 'MS', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'MS', label: 'Lowndes County', value: 'Lowndes County' },
    { state: 'MS', label: 'Madison County', value: 'Madison County' },
    { state: 'MS', label: 'Marion County', value: 'Marion County' },
    { state: 'MS', label: 'Marshall County', value: 'Marshall County' },
    { state: 'MS', label: 'Monroe County', value: 'Monroe County' },
    { state: 'MS', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'MS', label: 'Neshoba County', value: 'Neshoba County' },
    { state: 'MS', label: 'Newton County', value: 'Newton County' },
    { state: 'MS', label: 'Noxubee County', value: 'Noxubee County' },
    { state: 'MS', label: 'Oktibbeha County', value: 'Oktibbeha County' },
    { state: 'MS', label: 'Panola County', value: 'Panola County' },
    { state: 'MS', label: 'Pearl River County', value: 'Pearl River County' },
    { state: 'MS', label: 'Perry County', value: 'Perry County' },
    { state: 'MS', label: 'Pike County', value: 'Pike County' },
    { state: 'MS', label: 'Pontotoc County', value: 'Pontotoc County' },
    { state: 'MS', label: 'Prentiss County', value: 'Prentiss County' },
    { state: 'MS', label: 'Quitman County', value: 'Quitman County' },
    { state: 'MS', label: 'Rankin County', value: 'Rankin County' },
    { state: 'MS', label: 'Scott County', value: 'Scott County' },
    { state: 'MS', label: 'Sharkey County', value: 'Sharkey County' },
    { state: 'MS', label: 'Simpson County', value: 'Simpson County' },
    { state: 'MS', label: 'Smith County', value: 'Smith County' },
    { state: 'MS', label: 'Stone County', value: 'Stone County' },
    { state: 'MS', label: 'Sunflower County', value: 'Sunflower County' },
    { state: 'MS', label: 'Tallahatchie County', value: 'Tallahatchie County' },
    { state: 'MS', label: 'Tate County', value: 'Tate County' },
    { state: 'MS', label: 'Tippah County', value: 'Tippah County' },
    { state: 'MS', label: 'Tishomingo County', value: 'Tishomingo County' },
    { state: 'MS', label: 'Tunica County', value: 'Tunica County' },
    { state: 'MS', label: 'Union County', value: 'Union County' },
    { state: 'MS', label: 'Walthall County', value: 'Walthall County' },
    { state: 'MS', label: 'Warren County', value: 'Warren County' },
    { state: 'MS', label: 'Washington County', value: 'Washington County' },
    { state: 'MS', label: 'Wayne County', value: 'Wayne County' },
    { state: 'MS', label: 'Webster County', value: 'Webster County' },
    { state: 'MS', label: 'Wilkinson County', value: 'Wilkinson County' },
    { state: 'MS', label: 'Winston County', value: 'Winston County' },
    { state: 'MS', label: 'Yalobusha County', value: 'Yalobusha County' },
    { state: 'MS', label: 'Yazoo County', value: 'Yazoo County' },
    { state: 'MT', label: 'Beaverhead County', value: 'Beaverhead County' },
    { state: 'MT', label: 'Big Horn County', value: 'Big Horn County' },
    { state: 'MT', label: 'Blaine County', value: 'Blaine County' },
    { state: 'MT', label: 'Broadwater County', value: 'Broadwater County' },
    { state: 'MT', label: 'Carbon County', value: 'Carbon County' },
    { state: 'MT', label: 'Carter County', value: 'Carter County' },
    { state: 'MT', label: 'Cascade County', value: 'Cascade County' },
    { state: 'MT', label: 'Chouteau County', value: 'Chouteau County' },
    { state: 'MT', label: 'Custer County', value: 'Custer County' },
    { state: 'MT', label: 'Daniels County', value: 'Daniels County' },
    { state: 'MT', label: 'Dawson County', value: 'Dawson County' },
    { state: 'MT', label: 'Deer Lodge County', value: 'Deer Lodge County' },
    { state: 'MT', label: 'Fallon County', value: 'Fallon County' },
    { state: 'MT', label: 'Fergus County', value: 'Fergus County' },
    { state: 'MT', label: 'Flathead County', value: 'Flathead County' },
    { state: 'MT', label: 'Gallatin County', value: 'Gallatin County' },
    { state: 'MT', label: 'Garfield County', value: 'Garfield County' },
    { state: 'MT', label: 'Glacier County', value: 'Glacier County' },
    { state: 'MT', label: 'Golden Valley County', value: 'Golden Valley County' },
    { state: 'MT', label: 'Granite County', value: 'Granite County' },
    { state: 'MT', label: 'Hill County', value: 'Hill County' },
    { state: 'MT', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'MT', label: 'Judith Basin County', value: 'Judith Basin County' },
    { state: 'MT', label: 'Lake County', value: 'Lake County' },
    { state: 'MT', label: 'Lewis and Clark County', value: 'Lewis and Clark County' },
    { state: 'MT', label: 'Liberty County', value: 'Liberty County' },
    { state: 'MT', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'MT', label: 'Madison County', value: 'Madison County' },
    { state: 'MT', label: 'McCone County', value: 'McCone County' },
    { state: 'MT', label: 'Meagher County', value: 'Meagher County' },
    { state: 'MT', label: 'Mineral County', value: 'Mineral County' },
    { state: 'MT', label: 'Missoula County', value: 'Missoula County' },
    { state: 'MT', label: 'Musselshell County', value: 'Musselshell County' },
    { state: 'MT', label: 'Park County', value: 'Park County' },
    { state: 'MT', label: 'Petroleum County', value: 'Petroleum County' },
    { state: 'MT', label: 'Phillips County', value: 'Phillips County' },
    { state: 'MT', label: 'Pondera County', value: 'Pondera County' },
    { state: 'MT', label: 'Powder River County', value: 'Powder River County' },
    { state: 'MT', label: 'Powell County', value: 'Powell County' },
    { state: 'MT', label: 'Prairie County', value: 'Prairie County' },
    { state: 'MT', label: 'Ravalli County', value: 'Ravalli County' },
    { state: 'MT', label: 'Richland County', value: 'Richland County' },
    { state: 'MT', label: 'Roosevelt County', value: 'Roosevelt County' },
    { state: 'MT', label: 'Rosebud County', value: 'Rosebud County' },
    { state: 'MT', label: 'Sanders County', value: 'Sanders County' },
    { state: 'MT', label: 'Sheridan County', value: 'Sheridan County' },
    { state: 'MT', label: 'Silver Bow County', value: 'Silver Bow County' },
    { state: 'MT', label: 'Stillwater County', value: 'Stillwater County' },
    { state: 'MT', label: 'Sweet Grass County', value: 'Sweet Grass County' },
    { state: 'MT', label: 'Teton County', value: 'Teton County' },
    { state: 'MT', label: 'Toole County', value: 'Toole County' },
    { state: 'MT', label: 'Treasure County', value: 'Treasure County' },
    { state: 'MT', label: 'Valley County', value: 'Valley County' },
    { state: 'MT', label: 'Wheatland County', value: 'Wheatland County' },
    { state: 'MT', label: 'Wibaux County', value: 'Wibaux County' },
    { state: 'MT', label: 'Yellowstone County', value: 'Yellowstone County' },
    { state: 'NC', label: 'Alamance County', value: 'Alamance County' },
    { state: 'NC', label: 'Alexander County', value: 'Alexander County' },
    { state: 'NC', label: 'Alleghany County', value: 'Alleghany County' },
    { state: 'NC', label: 'Anson County', value: 'Anson County' },
    { state: 'NC', label: 'Ashe County', value: 'Ashe County' },
    { state: 'NC', label: 'Avery County', value: 'Avery County' },
    { state: 'NC', label: 'Beaufort County', value: 'Beaufort County' },
    { state: 'NC', label: 'Bertie County', value: 'Bertie County' },
    { state: 'NC', label: 'Bladen County', value: 'Bladen County' },
    { state: 'NC', label: 'Brunswick County', value: 'Brunswick County' },
    { state: 'NC', label: 'Buncombe County', value: 'Buncombe County' },
    { state: 'NC', label: 'Burke County', value: 'Burke County' },
    { state: 'NC', label: 'Cabarrus County', value: 'Cabarrus County' },
    { state: 'NC', label: 'Caldwell County', value: 'Caldwell County' },
    { state: 'NC', label: 'Camden County', value: 'Camden County' },
    { state: 'NC', label: 'Carteret County', value: 'Carteret County' },
    { state: 'NC', label: 'Caswell County', value: 'Caswell County' },
    { state: 'NC', label: 'Catawba County', value: 'Catawba County' },
    { state: 'NC', label: 'Chatham County', value: 'Chatham County' },
    { state: 'NC', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'NC', label: 'Chowan County', value: 'Chowan County' },
    { state: 'NC', label: 'Clay County', value: 'Clay County' },
    { state: 'NC', label: 'Cleveland County', value: 'Cleveland County' },
    { state: 'NC', label: 'Columbus County', value: 'Columbus County' },
    { state: 'NC', label: 'Craven County', value: 'Craven County' },
    { state: 'NC', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'NC', label: 'Currituck County', value: 'Currituck County' },
    { state: 'NC', label: 'Dare County', value: 'Dare County' },
    { state: 'NC', label: 'Davidson County', value: 'Davidson County' },
    { state: 'NC', label: 'Davie County', value: 'Davie County' },
    { state: 'NC', label: 'Duplin County', value: 'Duplin County' },
    { state: 'NC', label: 'Durham County', value: 'Durham County' },
    { state: 'NC', label: 'Edgecombe County', value: 'Edgecombe County' },
    { state: 'NC', label: 'Forsyth County', value: 'Forsyth County' },
    { state: 'NC', label: 'Franklin County', value: 'Franklin County' },
    { state: 'NC', label: 'Gaston County', value: 'Gaston County' },
    { state: 'NC', label: 'Gates County', value: 'Gates County' },
    { state: 'NC', label: 'Graham County', value: 'Graham County' },
    { state: 'NC', label: 'Granville County', value: 'Granville County' },
    { state: 'NC', label: 'Greene County', value: 'Greene County' },
    { state: 'NC', label: 'Guilford County', value: 'Guilford County' },
    { state: 'NC', label: 'Halifax County', value: 'Halifax County' },
    { state: 'NC', label: 'Harnett County', value: 'Harnett County' },
    { state: 'NC', label: 'Haywood County', value: 'Haywood County' },
    { state: 'NC', label: 'Henderson County', value: 'Henderson County' },
    { state: 'NC', label: 'Hertford County', value: 'Hertford County' },
    { state: 'NC', label: 'Hoke County', value: 'Hoke County' },
    { state: 'NC', label: 'Hyde County', value: 'Hyde County' },
    { state: 'NC', label: 'Iredell County', value: 'Iredell County' },
    { state: 'NC', label: 'Jackson County', value: 'Jackson County' },
    { state: 'NC', label: 'Johnston County', value: 'Johnston County' },
    { state: 'NC', label: 'Jones County', value: 'Jones County' },
    { state: 'NC', label: 'Lee County', value: 'Lee County' },
    { state: 'NC', label: 'Lenoir County', value: 'Lenoir County' },
    { state: 'NC', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'NC', label: 'Macon County', value: 'Macon County' },
    { state: 'NC', label: 'Madison County', value: 'Madison County' },
    { state: 'NC', label: 'Martin County', value: 'Martin County' },
    { state: 'NC', label: 'McDowell County', value: 'McDowell County' },
    { state: 'NC', label: 'Mecklenburg County', value: 'Mecklenburg County' },
    { state: 'NC', label: 'Mitchell County', value: 'Mitchell County' },
    { state: 'NC', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'NC', label: 'Moore County', value: 'Moore County' },
    { state: 'NC', label: 'Nash County', value: 'Nash County' },
    { state: 'NC', label: 'New Hanover County', value: 'New Hanover County' },
    { state: 'NC', label: 'Northampton County', value: 'Northampton County' },
    { state: 'NC', label: 'Onslow County', value: 'Onslow County' },
    { state: 'NC', label: 'Orange County', value: 'Orange County' },
    { state: 'NC', label: 'Pamlico County', value: 'Pamlico County' },
    { state: 'NC', label: 'Pasquotank County', value: 'Pasquotank County' },
    { state: 'NC', label: 'Pender County', value: 'Pender County' },
    { state: 'NC', label: 'Perquimans County', value: 'Perquimans County' },
    { state: 'NC', label: 'Person County', value: 'Person County' },
    { state: 'NC', label: 'Pitt County', value: 'Pitt County' },
    { state: 'NC', label: 'Polk County', value: 'Polk County' },
    { state: 'NC', label: 'Randolph County', value: 'Randolph County' },
    { state: 'NC', label: 'Richmond County', value: 'Richmond County' },
    { state: 'NC', label: 'Robeson County', value: 'Robeson County' },
    { state: 'NC', label: 'Rockingham County', value: 'Rockingham County' },
    { state: 'NC', label: 'Rowan County', value: 'Rowan County' },
    { state: 'NC', label: 'Rutherford County', value: 'Rutherford County' },
    { state: 'NC', label: 'Sampson County', value: 'Sampson County' },
    { state: 'NC', label: 'Scotland County', value: 'Scotland County' },
    { state: 'NC', label: 'Stanly County', value: 'Stanly County' },
    { state: 'NC', label: 'Stokes County', value: 'Stokes County' },
    { state: 'NC', label: 'Surry County', value: 'Surry County' },
    { state: 'NC', label: 'Swain County', value: 'Swain County' },
    { state: 'NC', label: 'Transylvania County', value: 'Transylvania County' },
    { state: 'NC', label: 'Tyrrell County', value: 'Tyrrell County' },
    { state: 'NC', label: 'Union County', value: 'Union County' },
    { state: 'NC', label: 'Vance County', value: 'Vance County' },
    { state: 'NC', label: 'Wake County', value: 'Wake County' },
    { state: 'NC', label: 'Warren County', value: 'Warren County' },
    { state: 'NC', label: 'Washington County', value: 'Washington County' },
    { state: 'NC', label: 'Watauga County', value: 'Watauga County' },
    { state: 'NC', label: 'Wayne County', value: 'Wayne County' },
    { state: 'NC', label: 'Wilkes County', value: 'Wilkes County' },
    { state: 'NC', label: 'Wilson County', value: 'Wilson County' },
    { state: 'NC', label: 'Yadkin County', value: 'Yadkin County' },
    { state: 'NC', label: 'Yancey County', value: 'Yancey County' },
    { state: 'ND', label: 'Adams County', value: 'Adams County' },
    { state: 'ND', label: 'Barnes County', value: 'Barnes County' },
    { state: 'ND', label: 'Benson County', value: 'Benson County' },
    { state: 'ND', label: 'Billings County', value: 'Billings County' },
    { state: 'ND', label: 'Bottineau County', value: 'Bottineau County' },
    { state: 'ND', label: 'Bowman County', value: 'Bowman County' },
    { state: 'ND', label: 'Burke County', value: 'Burke County' },
    { state: 'ND', label: 'Burleigh County', value: 'Burleigh County' },
    { state: 'ND', label: 'Cass County', value: 'Cass County' },
    { state: 'ND', label: 'Cavalier County', value: 'Cavalier County' },
    { state: 'ND', label: 'Dickey County', value: 'Dickey County' },
    { state: 'ND', label: 'Divide County', value: 'Divide County' },
    { state: 'ND', label: 'Dunn County', value: 'Dunn County' },
    { state: 'ND', label: 'Eddy County', value: 'Eddy County' },
    { state: 'ND', label: 'Emmons County', value: 'Emmons County' },
    { state: 'ND', label: 'Foster County', value: 'Foster County' },
    { state: 'ND', label: 'Golden Valley County', value: 'Golden Valley County' },
    { state: 'ND', label: 'Grand Forks County', value: 'Grand Forks County' },
    { state: 'ND', label: 'Grant County', value: 'Grant County' },
    { state: 'ND', label: 'Griggs County', value: 'Griggs County' },
    { state: 'ND', label: 'Hettinger County', value: 'Hettinger County' },
    { state: 'ND', label: 'Kidder County', value: 'Kidder County' },
    { state: 'ND', label: 'LaMoure County', value: 'LaMoure County' },
    { state: 'ND', label: 'Logan County', value: 'Logan County' },
    { state: 'ND', label: 'McHenry County', value: 'McHenry County' },
    { state: 'ND', label: 'McIntosh County', value: 'McIntosh County' },
    { state: 'ND', label: 'McKenzie County', value: 'McKenzie County' },
    { state: 'ND', label: 'McLean County', value: 'McLean County' },
    { state: 'ND', label: 'Mercer County', value: 'Mercer County' },
    { state: 'ND', label: 'Morton County', value: 'Morton County' },
    { state: 'ND', label: 'Mountrail County', value: 'Mountrail County' },
    { state: 'ND', label: 'Nelson County', value: 'Nelson County' },
    { state: 'ND', label: 'Oliver County', value: 'Oliver County' },
    { state: 'ND', label: 'Pembina County', value: 'Pembina County' },
    { state: 'ND', label: 'Pierce County', value: 'Pierce County' },
    { state: 'ND', label: 'Ramsey County', value: 'Ramsey County' },
    { state: 'ND', label: 'Ransom County', value: 'Ransom County' },
    { state: 'ND', label: 'Renville County', value: 'Renville County' },
    { state: 'ND', label: 'Richland County', value: 'Richland County' },
    { state: 'ND', label: 'Rolette County', value: 'Rolette County' },
    { state: 'ND', label: 'Sargent County', value: 'Sargent County' },
    { state: 'ND', label: 'Sheridan County', value: 'Sheridan County' },
    { state: 'ND', label: 'Sioux County', value: 'Sioux County' },
    { state: 'ND', label: 'Slope County', value: 'Slope County' },
    { state: 'ND', label: 'Stark County', value: 'Stark County' },
    { state: 'ND', label: 'Steele County', value: 'Steele County' },
    { state: 'ND', label: 'Stutsman County', value: 'Stutsman County' },
    { state: 'ND', label: 'Towner County', value: 'Towner County' },
    { state: 'ND', label: 'Traill County', value: 'Traill County' },
    { state: 'ND', label: 'Walsh County', value: 'Walsh County' },
    { state: 'ND', label: 'Ward County', value: 'Ward County' },
    { state: 'ND', label: 'Wells County', value: 'Wells County' },
    { state: 'ND', label: 'Williams County', value: 'Williams County' },
    { state: 'NE', label: 'Adams County', value: 'Adams County' },
    { state: 'NE', label: 'Antelope County', value: 'Antelope County' },
    { state: 'NE', label: 'Arthur County', value: 'Arthur County' },
    { state: 'NE', label: 'Banner County', value: 'Banner County' },
    { state: 'NE', label: 'Blaine County', value: 'Blaine County' },
    { state: 'NE', label: 'Boone County', value: 'Boone County' },
    { state: 'NE', label: 'Box Butte County', value: 'Box Butte County' },
    { state: 'NE', label: 'Boyd County', value: 'Boyd County' },
    { state: 'NE', label: 'Brown County', value: 'Brown County' },
    { state: 'NE', label: 'Buffalo County', value: 'Buffalo County' },
    { state: 'NE', label: 'Burt County', value: 'Burt County' },
    { state: 'NE', label: 'Butler County', value: 'Butler County' },
    { state: 'NE', label: 'Cass County', value: 'Cass County' },
    { state: 'NE', label: 'Cedar County', value: 'Cedar County' },
    { state: 'NE', label: 'Chase County', value: 'Chase County' },
    { state: 'NE', label: 'Cherry County', value: 'Cherry County' },
    { state: 'NE', label: 'Cheyenne County', value: 'Cheyenne County' },
    { state: 'NE', label: 'Clay County', value: 'Clay County' },
    { state: 'NE', label: 'Colfax County', value: 'Colfax County' },
    { state: 'NE', label: 'Cuming County', value: 'Cuming County' },
    { state: 'NE', label: 'Custer County', value: 'Custer County' },
    { state: 'NE', label: 'Dakota County', value: 'Dakota County' },
    { state: 'NE', label: 'Dawes County', value: 'Dawes County' },
    { state: 'NE', label: 'Dawson County', value: 'Dawson County' },
    { state: 'NE', label: 'Deuel County', value: 'Deuel County' },
    { state: 'NE', label: 'Dixon County', value: 'Dixon County' },
    { state: 'NE', label: 'Dodge County', value: 'Dodge County' },
    { state: 'NE', label: 'Douglas County', value: 'Douglas County' },
    { state: 'NE', label: 'Dundy County', value: 'Dundy County' },
    { state: 'NE', label: 'Fillmore County', value: 'Fillmore County' },
    { state: 'NE', label: 'Franklin County', value: 'Franklin County' },
    { state: 'NE', label: 'Frontier County', value: 'Frontier County' },
    { state: 'NE', label: 'Furnas County', value: 'Furnas County' },
    { state: 'NE', label: 'Gage County', value: 'Gage County' },
    { state: 'NE', label: 'Garden County', value: 'Garden County' },
    { state: 'NE', label: 'Garfield County', value: 'Garfield County' },
    { state: 'NE', label: 'Gosper County', value: 'Gosper County' },
    { state: 'NE', label: 'Grant County', value: 'Grant County' },
    { state: 'NE', label: 'Greeley County', value: 'Greeley County' },
    { state: 'NE', label: 'Hall County', value: 'Hall County' },
    { state: 'NE', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'NE', label: 'Harlan County', value: 'Harlan County' },
    { state: 'NE', label: 'Hayes County', value: 'Hayes County' },
    { state: 'NE', label: 'Hitchcock County', value: 'Hitchcock County' },
    { state: 'NE', label: 'Holt County', value: 'Holt County' },
    { state: 'NE', label: 'Hooker County', value: 'Hooker County' },
    { state: 'NE', label: 'Howard County', value: 'Howard County' },
    { state: 'NE', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'NE', label: 'Johnson County', value: 'Johnson County' },
    { state: 'NE', label: 'Kearney County', value: 'Kearney County' },
    { state: 'NE', label: 'Keith County', value: 'Keith County' },
    { state: 'NE', label: 'Keya Paha County', value: 'Keya Paha County' },
    { state: 'NE', label: 'Kimball County', value: 'Kimball County' },
    { state: 'NE', label: 'Knox County', value: 'Knox County' },
    { state: 'NE', label: 'Lancaster County', value: 'Lancaster County' },
    { state: 'NE', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'NE', label: 'Logan County', value: 'Logan County' },
    { state: 'NE', label: 'Loup County', value: 'Loup County' },
    { state: 'NE', label: 'Madison County', value: 'Madison County' },
    { state: 'NE', label: 'McPherson County', value: 'McPherson County' },
    { state: 'NE', label: 'Merrick County', value: 'Merrick County' },
    { state: 'NE', label: 'Morrill County', value: 'Morrill County' },
    { state: 'NE', label: 'Nance County', value: 'Nance County' },
    { state: 'NE', label: 'Nemaha County', value: 'Nemaha County' },
    { state: 'NE', label: 'Nuckolls County', value: 'Nuckolls County' },
    { state: 'NE', label: 'Otoe County', value: 'Otoe County' },
    { state: 'NE', label: 'Pawnee County', value: 'Pawnee County' },
    { state: 'NE', label: 'Perkins County', value: 'Perkins County' },
    { state: 'NE', label: 'Phelps County', value: 'Phelps County' },
    { state: 'NE', label: 'Pierce County', value: 'Pierce County' },
    { state: 'NE', label: 'Platte County', value: 'Platte County' },
    { state: 'NE', label: 'Polk County', value: 'Polk County' },
    { state: 'NE', label: 'Red Willow County', value: 'Red Willow County' },
    { state: 'NE', label: 'Richardson County', value: 'Richardson County' },
    { state: 'NE', label: 'Rock County', value: 'Rock County' },
    { state: 'NE', label: 'Saline County', value: 'Saline County' },
    { state: 'NE', label: 'Sarpy County', value: 'Sarpy County' },
    { state: 'NE', label: 'Saunders County', value: 'Saunders County' },
    { state: 'NE', label: 'Scotts Bluff County', value: 'Scotts Bluff County' },
    { state: 'NE', label: 'Seward County', value: 'Seward County' },
    { state: 'NE', label: 'Sheridan County', value: 'Sheridan County' },
    { state: 'NE', label: 'Sherman County', value: 'Sherman County' },
    { state: 'NE', label: 'Sioux County', value: 'Sioux County' },
    { state: 'NE', label: 'Stanton County', value: 'Stanton County' },
    { state: 'NE', label: 'Thayer County', value: 'Thayer County' },
    { state: 'NE', label: 'Thomas County', value: 'Thomas County' },
    { state: 'NE', label: 'Thurston County', value: 'Thurston County' },
    { state: 'NE', label: 'Valley County', value: 'Valley County' },
    { state: 'NE', label: 'Washington County', value: 'Washington County' },
    { state: 'NE', label: 'Wayne County', value: 'Wayne County' },
    { state: 'NE', label: 'Webster County', value: 'Webster County' },
    { state: 'NE', label: 'Wheeler County', value: 'Wheeler County' },
    { state: 'NE', label: 'York County', value: 'York County' },
    { state: 'NH', label: 'Belknap County', value: 'Belknap County' },
    { state: 'NH', label: 'Carroll County', value: 'Carroll County' },
    { state: 'NH', label: 'Cheshire County', value: 'Cheshire County' },
    { state: 'NH', label: 'Coos County', value: 'Coos County' },
    { state: 'NH', label: 'Grafton County', value: 'Grafton County' },
    { state: 'NH', label: 'Hillsborough County', value: 'Hillsborough County' },
    { state: 'NH', label: 'Merrimack County', value: 'Merrimack County' },
    { state: 'NH', label: 'Rockingham County', value: 'Rockingham County' },
    { state: 'NH', label: 'Strafford County', value: 'Strafford County' },
    { state: 'NH', label: 'Sullivan County', value: 'Sullivan County' },
    { state: 'NJ', label: 'Atlantic County', value: 'Atlantic County' },
    { state: 'NJ', label: 'Bergen County', value: 'Bergen County' },
    { state: 'NJ', label: 'Burlington County', value: 'Burlington County' },
    { state: 'NJ', label: 'Camden County', value: 'Camden County' },
    { state: 'NJ', label: 'Cape May County', value: 'Cape May County' },
    { state: 'NJ', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'NJ', label: 'Essex County', value: 'Essex County' },
    { state: 'NJ', label: 'Gloucester County', value: 'Gloucester County' },
    { state: 'NJ', label: 'Hudson County', value: 'Hudson County' },
    { state: 'NJ', label: 'Hunterdon County', value: 'Hunterdon County' },
    { state: 'NJ', label: 'Mercer County', value: 'Mercer County' },
    { state: 'NJ', label: 'Middlesex County', value: 'Middlesex County' },
    { state: 'NJ', label: 'Monmouth County', value: 'Monmouth County' },
    { state: 'NJ', label: 'Morris County', value: 'Morris County' },
    { state: 'NJ', label: 'Ocean County', value: 'Ocean County' },
    { state: 'NJ', label: 'Passaic County', value: 'Passaic County' },
    { state: 'NJ', label: 'Salem County', value: 'Salem County' },
    { state: 'NJ', label: 'Somerset County', value: 'Somerset County' },
    { state: 'NJ', label: 'Sussex County', value: 'Sussex County' },
    { state: 'NJ', label: 'Union County', value: 'Union County' },
    { state: 'NJ', label: 'Warren County', value: 'Warren County' },
    { state: 'NM', label: 'Bernalillo County', value: 'Bernalillo County' },
    { state: 'NM', label: 'Catron County', value: 'Catron County' },
    { state: 'NM', label: 'Chaves County', value: 'Chaves County' },
    { state: 'NM', label: 'Cibola County', value: 'Cibola County' },
    { state: 'NM', label: 'Colfax County', value: 'Colfax County' },
    { state: 'NM', label: 'Curry County', value: 'Curry County' },
    { state: 'NM', label: 'DeBaca County', value: 'DeBaca County' },
    { state: 'NM', label: 'Dona Ana County', value: 'Dona Ana County' },
    { state: 'NM', label: 'Eddy County', value: 'Eddy County' },
    { state: 'NM', label: 'Grant County', value: 'Grant County' },
    { state: 'NM', label: 'Guadalupe County', value: 'Guadalupe County' },
    { state: 'NM', label: 'Harding County', value: 'Harding County' },
    { state: 'NM', label: 'Hidalgo County', value: 'Hidalgo County' },
    { state: 'NM', label: 'Lea County', value: 'Lea County' },
    { state: 'NM', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'NM', label: 'Los Alamos County', value: 'Los Alamos County' },
    { state: 'NM', label: 'Luna County', value: 'Luna County' },
    { state: 'NM', label: 'McKinley County', value: 'McKinley County' },
    { state: 'NM', label: 'Mora County', value: 'Mora County' },
    { state: 'NM', label: 'Otero County', value: 'Otero County' },
    { state: 'NM', label: 'Quay County', value: 'Quay County' },
    { state: 'NM', label: 'Rio Arriba County', value: 'Rio Arriba County' },
    { state: 'NM', label: 'Roosevelt County', value: 'Roosevelt County' },
    { state: 'NM', label: 'San Juan County', value: 'San Juan County' },
    { state: 'NM', label: 'San Miguel County', value: 'San Miguel County' },
    { state: 'NM', label: 'Sandoval County', value: 'Sandoval County' },
    { state: 'NM', label: 'Santa Fe County', value: 'Santa Fe County' },
    { state: 'NM', label: 'Sierra County', value: 'Sierra County' },
    { state: 'NM', label: 'Socorro County', value: 'Socorro County' },
    { state: 'NM', label: 'Taos County', value: 'Taos County' },
    { state: 'NM', label: 'Torrance County', value: 'Torrance County' },
    { state: 'NM', label: 'Union County', value: 'Union County' },
    { state: 'NM', label: 'Valencia County', value: 'Valencia County' },
    { state: 'NV', label: 'Carson City', value: 'Carson City' },
    { state: 'NV', label: 'Churchill County', value: 'Churchill County' },
    { state: 'NV', label: 'Clark County', value: 'Clark County' },
    { state: 'NV', label: 'Douglas County', value: 'Douglas County' },
    { state: 'NV', label: 'Elko County', value: 'Elko County' },
    { state: 'NV', label: 'Esmeralda County', value: 'Esmeralda County' },
    { state: 'NV', label: 'Eureka County', value: 'Eureka County' },
    { state: 'NV', label: 'Humboldt County', value: 'Humboldt County' },
    { state: 'NV', label: 'Lander County', value: 'Lander County' },
    { state: 'NV', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'NV', label: 'Lyon County', value: 'Lyon County' },
    { state: 'NV', label: 'Mineral County', value: 'Mineral County' },
    { state: 'NV', label: 'Nye County', value: 'Nye County' },
    { state: 'NV', label: 'Pershing County', value: 'Pershing County' },
    { state: 'NV', label: 'Storey County', value: 'Storey County' },
    { state: 'NV', label: 'Washoe County', value: 'Washoe County' },
    { state: 'NV', label: 'White Pine County', value: 'White Pine County' },
    { state: 'NY', label: 'Albany County', value: 'Albany County' },
    { state: 'NY', label: 'Allegany County', value: 'Allegany County' },
    { state: 'NY', label: 'Bronx County', value: 'Bronx County' },
    { state: 'NY', label: 'Broome County', value: 'Broome County' },
    { state: 'NY', label: 'Cattaraugus County', value: 'Cattaraugus County' },
    { state: 'NY', label: 'Cayuga County', value: 'Cayuga County' },
    { state: 'NY', label: 'Chautauqua County', value: 'Chautauqua County' },
    { state: 'NY', label: 'Chemung County', value: 'Chemung County' },
    { state: 'NY', label: 'Chenango County', value: 'Chenango County' },
    { state: 'NY', label: 'Clinton County', value: 'Clinton County' },
    { state: 'NY', label: 'Columbia County', value: 'Columbia County' },
    { state: 'NY', label: 'Cortland County', value: 'Cortland County' },
    { state: 'NY', label: 'Delaware County', value: 'Delaware County' },
    { state: 'NY', label: 'Dutchess County', value: 'Dutchess County' },
    { state: 'NY', label: 'Erie County', value: 'Erie County' },
    { state: 'NY', label: 'Essex County', value: 'Essex County' },
    { state: 'NY', label: 'Franklin County', value: 'Franklin County' },
    { state: 'NY', label: 'Fulton County', value: 'Fulton County' },
    { state: 'NY', label: 'Genesee County', value: 'Genesee County' },
    { state: 'NY', label: 'Greene County', value: 'Greene County' },
    { state: 'NY', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'NY', label: 'Herkimer County', value: 'Herkimer County' },
    { state: 'NY', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'NY', label: 'Kings County', value: 'Kings County' },
    { state: 'NY', label: 'Lewis County', value: 'Lewis County' },
    { state: 'NY', label: 'Livingston County', value: 'Livingston County' },
    { state: 'NY', label: 'Madison County', value: 'Madison County' },
    { state: 'NY', label: 'Monroe County', value: 'Monroe County' },
    { state: 'NY', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'NY', label: 'Nassau County', value: 'Nassau County' },
    { state: 'NY', label: 'New York County', value: 'New York County' },
    { state: 'NY', label: 'Niagara County', value: 'Niagara County' },
    { state: 'NY', label: 'Oneida County', value: 'Oneida County' },
    { state: 'NY', label: 'Onondaga County', value: 'Onondaga County' },
    { state: 'NY', label: 'Ontario County', value: 'Ontario County' },
    { state: 'NY', label: 'Orange County', value: 'Orange County' },
    { state: 'NY', label: 'Orleans County', value: 'Orleans County' },
    { state: 'NY', label: 'Oswego County', value: 'Oswego County' },
    { state: 'NY', label: 'Otsego County', value: 'Otsego County' },
    { state: 'NY', label: 'Putnam County', value: 'Putnam County' },
    { state: 'NY', label: 'Queens County', value: 'Queens County' },
    { state: 'NY', label: 'Rensselaer County', value: 'Rensselaer County' },
    { state: 'NY', label: 'Richmond County', value: 'Richmond County' },
    { state: 'NY', label: 'Rockland County', value: 'Rockland County' },
    { state: 'NY', label: 'Saratoga County', value: 'Saratoga County' },
    { state: 'NY', label: 'Schenectady County', value: 'Schenectady County' },
    { state: 'NY', label: 'Schoharie County', value: 'Schoharie County' },
    { state: 'NY', label: 'Schuyler County', value: 'Schuyler County' },
    { state: 'NY', label: 'Seneca County', value: 'Seneca County' },
    { state: 'NY', label: 'St. Lawrence County', value: 'St. Lawrence County' },
    { state: 'NY', label: 'Steuben County', value: 'Steuben County' },
    { state: 'NY', label: 'Suffolk County', value: 'Suffolk County' },
    { state: 'NY', label: 'Sullivan County', value: 'Sullivan County' },
    { state: 'NY', label: 'Tioga County', value: 'Tioga County' },
    { state: 'NY', label: 'Tompkins County', value: 'Tompkins County' },
    { state: 'NY', label: 'Ulster County', value: 'Ulster County' },
    { state: 'NY', label: 'Warren County', value: 'Warren County' },
    { state: 'NY', label: 'Washington County', value: 'Washington County' },
    { state: 'NY', label: 'Wayne County', value: 'Wayne County' },
    { state: 'NY', label: 'Westchester County', value: 'Westchester County' },
    { state: 'NY', label: 'Wyoming County', value: 'Wyoming County' },
    { state: 'NY', label: 'Yates County', value: 'Yates County' },
    { state: 'OH', label: 'Adams County', value: 'Adams County' },
    { state: 'OH', label: 'Allen County', value: 'Allen County' },
    { state: 'OH', label: 'Ashland County', value: 'Ashland County' },
    { state: 'OH', label: 'Ashtabula County', value: 'Ashtabula County' },
    { state: 'OH', label: 'Athens County', value: 'Athens County' },
    { state: 'OH', label: 'Auglaize County', value: 'Auglaize County' },
    { state: 'OH', label: 'Belmont County', value: 'Belmont County' },
    { state: 'OH', label: 'Brown County', value: 'Brown County' },
    { state: 'OH', label: 'Butler County', value: 'Butler County' },
    { state: 'OH', label: 'Carroll County', value: 'Carroll County' },
    { state: 'OH', label: 'Champaign County', value: 'Champaign County' },
    { state: 'OH', label: 'Clark County', value: 'Clark County' },
    { state: 'OH', label: 'Clermont County', value: 'Clermont County' },
    { state: 'OH', label: 'Clinton County', value: 'Clinton County' },
    { state: 'OH', label: 'Columbiana County', value: 'Columbiana County' },
    { state: 'OH', label: 'Coshocton County', value: 'Coshocton County' },
    { state: 'OH', label: 'Crawford County', value: 'Crawford County' },
    { state: 'OH', label: 'Cuyahoga County', value: 'Cuyahoga County' },
    { state: 'OH', label: 'Darke County', value: 'Darke County' },
    { state: 'OH', label: 'Defiance County', value: 'Defiance County' },
    { state: 'OH', label: 'Delaware County', value: 'Delaware County' },
    { state: 'OH', label: 'Erie County', value: 'Erie County' },
    { state: 'OH', label: 'Fairfield County', value: 'Fairfield County' },
    { state: 'OH', label: 'Fayette County', value: 'Fayette County' },
    { state: 'OH', label: 'Franklin County', value: 'Franklin County' },
    { state: 'OH', label: 'Fulton County', value: 'Fulton County' },
    { state: 'OH', label: 'Gallia County', value: 'Gallia County' },
    { state: 'OH', label: 'Geauga County', value: 'Geauga County' },
    { state: 'OH', label: 'Greene County', value: 'Greene County' },
    { state: 'OH', label: 'Guernsey County', value: 'Guernsey County' },
    { state: 'OH', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'OH', label: 'Hancock County', value: 'Hancock County' },
    { state: 'OH', label: 'Hardin County', value: 'Hardin County' },
    { state: 'OH', label: 'Harrison County', value: 'Harrison County' },
    { state: 'OH', label: 'Henry County', value: 'Henry County' },
    { state: 'OH', label: 'Highland County', value: 'Highland County' },
    { state: 'OH', label: 'Hocking County', value: 'Hocking County' },
    { state: 'OH', label: 'Holmes County', value: 'Holmes County' },
    { state: 'OH', label: 'Huron County', value: 'Huron County' },
    { state: 'OH', label: 'Jackson County', value: 'Jackson County' },
    { state: 'OH', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'OH', label: 'Knox County', value: 'Knox County' },
    { state: 'OH', label: 'Lake County', value: 'Lake County' },
    { state: 'OH', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'OH', label: 'Licking County', value: 'Licking County' },
    { state: 'OH', label: 'Logan County', value: 'Logan County' },
    { state: 'OH', label: 'Lorain County', value: 'Lorain County' },
    { state: 'OH', label: 'Lucas County', value: 'Lucas County' },
    { state: 'OH', label: 'Madison County', value: 'Madison County' },
    { state: 'OH', label: 'Mahoning County', value: 'Mahoning County' },
    { state: 'OH', label: 'Marion County', value: 'Marion County' },
    { state: 'OH', label: 'Medina County', value: 'Medina County' },
    { state: 'OH', label: 'Meigs County', value: 'Meigs County' },
    { state: 'OH', label: 'Mercer County', value: 'Mercer County' },
    { state: 'OH', label: 'Miami County', value: 'Miami County' },
    { state: 'OH', label: 'Monroe County', value: 'Monroe County' },
    { state: 'OH', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'OH', label: 'Morgan County', value: 'Morgan County' },
    { state: 'OH', label: 'Morrow County', value: 'Morrow County' },
    { state: 'OH', label: 'Muskingum County', value: 'Muskingum County' },
    { state: 'OH', label: 'Noble County', value: 'Noble County' },
    { state: 'OH', label: 'Ottawa County', value: 'Ottawa County' },
    { state: 'OH', label: 'Paulding County', value: 'Paulding County' },
    { state: 'OH', label: 'Perry County', value: 'Perry County' },
    { state: 'OH', label: 'Pickaway County', value: 'Pickaway County' },
    { state: 'OH', label: 'Pike County', value: 'Pike County' },
    { state: 'OH', label: 'Portage County', value: 'Portage County' },
    { state: 'OH', label: 'Preble County', value: 'Preble County' },
    { state: 'OH', label: 'Putnam County', value: 'Putnam County' },
    { state: 'OH', label: 'Richland County', value: 'Richland County' },
    { state: 'OH', label: 'Ross County', value: 'Ross County' },
    { state: 'OH', label: 'Sandusky County', value: 'Sandusky County' },
    { state: 'OH', label: 'Scioto County', value: 'Scioto County' },
    { state: 'OH', label: 'Seneca County', value: 'Seneca County' },
    { state: 'OH', label: 'Shelby County', value: 'Shelby County' },
    { state: 'OH', label: 'Stark County', value: 'Stark County' },
    { state: 'OH', label: 'Summit County', value: 'Summit County' },
    { state: 'OH', label: 'Trumbull County', value: 'Trumbull County' },
    { state: 'OH', label: 'Tuscarawas County', value: 'Tuscarawas County' },
    { state: 'OH', label: 'Union County', value: 'Union County' },
    { state: 'OH', label: 'Van Wert County', value: 'Van Wert County' },
    { state: 'OH', label: 'Vinton County', value: 'Vinton County' },
    { state: 'OH', label: 'Warren County', value: 'Warren County' },
    { state: 'OH', label: 'Washington County', value: 'Washington County' },
    { state: 'OH', label: 'Wayne County', value: 'Wayne County' },
    { state: 'OH', label: 'Williams County', value: 'Williams County' },
    { state: 'OH', label: 'Wood County', value: 'Wood County' },
    { state: 'OH', label: 'Wyandot County', value: 'Wyandot County' },
    { state: 'OK', label: 'Adair County', value: 'Adair County' },
    { state: 'OK', label: 'Alfalfa County', value: 'Alfalfa County' },
    { state: 'OK', label: 'Atoka County', value: 'Atoka County' },
    { state: 'OK', label: 'Beaver County', value: 'Beaver County' },
    { state: 'OK', label: 'Beckham County', value: 'Beckham County' },
    { state: 'OK', label: 'Blaine County', value: 'Blaine County' },
    { state: 'OK', label: 'Bryan County', value: 'Bryan County' },
    { state: 'OK', label: 'Caddo County', value: 'Caddo County' },
    { state: 'OK', label: 'Canadian County', value: 'Canadian County' },
    { state: 'OK', label: 'Carter County', value: 'Carter County' },
    { state: 'OK', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'OK', label: 'Choctaw County', value: 'Choctaw County' },
    { state: 'OK', label: 'Cimarron County', value: 'Cimarron County' },
    { state: 'OK', label: 'Cleveland County', value: 'Cleveland County' },
    { state: 'OK', label: 'Coal County', value: 'Coal County' },
    { state: 'OK', label: 'Comanche County', value: 'Comanche County' },
    { state: 'OK', label: 'Cotton County', value: 'Cotton County' },
    { state: 'OK', label: 'Craig County', value: 'Craig County' },
    { state: 'OK', label: 'Creek County', value: 'Creek County' },
    { state: 'OK', label: 'Custer County', value: 'Custer County' },
    { state: 'OK', label: 'Delaware County', value: 'Delaware County' },
    { state: 'OK', label: 'Dewey County', value: 'Dewey County' },
    { state: 'OK', label: 'Ellis County', value: 'Ellis County' },
    { state: 'OK', label: 'Garfield County', value: 'Garfield County' },
    { state: 'OK', label: 'Garvin County', value: 'Garvin County' },
    { state: 'OK', label: 'Grady County', value: 'Grady County' },
    { state: 'OK', label: 'Grant County', value: 'Grant County' },
    { state: 'OK', label: 'Greer County', value: 'Greer County' },
    { state: 'OK', label: 'Harmon County', value: 'Harmon County' },
    { state: 'OK', label: 'Harper County', value: 'Harper County' },
    { state: 'OK', label: 'Haskell County', value: 'Haskell County' },
    { state: 'OK', label: 'Hughes County', value: 'Hughes County' },
    { state: 'OK', label: 'Jackson County', value: 'Jackson County' },
    { state: 'OK', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'OK', label: 'Johnston County', value: 'Johnston County' },
    { state: 'OK', label: 'Kay County', value: 'Kay County' },
    { state: 'OK', label: 'Kingfisher County', value: 'Kingfisher County' },
    { state: 'OK', label: 'Kiowa County', value: 'Kiowa County' },
    { state: 'OK', label: 'Latimer County', value: 'Latimer County' },
    { state: 'OK', label: 'Le Flore County', value: 'Le Flore County' },
    { state: 'OK', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'OK', label: 'Logan County', value: 'Logan County' },
    { state: 'OK', label: 'Love County', value: 'Love County' },
    { state: 'OK', label: 'Major County', value: 'Major County' },
    { state: 'OK', label: 'Marshall County', value: 'Marshall County' },
    { state: 'OK', label: 'Mayes County', value: 'Mayes County' },
    { state: 'OK', label: 'McClain County', value: 'McClain County' },
    { state: 'OK', label: 'McCurtain County', value: 'McCurtain County' },
    { state: 'OK', label: 'McIntosh County', value: 'McIntosh County' },
    { state: 'OK', label: 'Murray County', value: 'Murray County' },
    { state: 'OK', label: 'Muskogee County', value: 'Muskogee County' },
    { state: 'OK', label: 'Noble County', value: 'Noble County' },
    { state: 'OK', label: 'Nowata County', value: 'Nowata County' },
    { state: 'OK', label: 'Okfuskee County', value: 'Okfuskee County' },
    { state: 'OK', label: 'Oklahoma County', value: 'Oklahoma County' },
    { state: 'OK', label: 'Okmulgee County', value: 'Okmulgee County' },
    { state: 'OK', label: 'Osage County', value: 'Osage County' },
    { state: 'OK', label: 'Ottawa County', value: 'Ottawa County' },
    { state: 'OK', label: 'Pawnee County', value: 'Pawnee County' },
    { state: 'OK', label: 'Payne County', value: 'Payne County' },
    { state: 'OK', label: 'Pittsburg County', value: 'Pittsburg County' },
    { state: 'OK', label: 'Pontotoc County', value: 'Pontotoc County' },
    { state: 'OK', label: 'Pottawatomie County', value: 'Pottawatomie County' },
    { state: 'OK', label: 'Pushmataha County', value: 'Pushmataha County' },
    { state: 'OK', label: 'Roger Mills County', value: 'Roger Mills County' },
    { state: 'OK', label: 'Rogers County', value: 'Rogers County' },
    { state: 'OK', label: 'Seminole County', value: 'Seminole County' },
    { state: 'OK', label: 'Sequoyah County', value: 'Sequoyah County' },
    { state: 'OK', label: 'Stephens County', value: 'Stephens County' },
    { state: 'OK', label: 'Texas County', value: 'Texas County' },
    { state: 'OK', label: 'Tillman County', value: 'Tillman County' },
    { state: 'OK', label: 'Tulsa County', value: 'Tulsa County' },
    { state: 'OK', label: 'Wagoner County', value: 'Wagoner County' },
    { state: 'OK', label: 'Washington County', value: 'Washington County' },
    { state: 'OK', label: 'Washita County', value: 'Washita County' },
    { state: 'OK', label: 'Woods County', value: 'Woods County' },
    { state: 'OK', label: 'Woodward County', value: 'Woodward County' },
    { state: 'OR', label: 'Baker County', value: 'Baker County' },
    { state: 'OR', label: 'Benton County', value: 'Benton County' },
    { state: 'OR', label: 'Clackamas County', value: 'Clackamas County' },
    { state: 'OR', label: 'Clatsop County', value: 'Clatsop County' },
    { state: 'OR', label: 'Columbia County', value: 'Columbia County' },
    { state: 'OR', label: 'Coos County', value: 'Coos County' },
    { state: 'OR', label: 'Crook County', value: 'Crook County' },
    { state: 'OR', label: 'Curry County', value: 'Curry County' },
    { state: 'OR', label: 'Deschutes County', value: 'Deschutes County' },
    { state: 'OR', label: 'Douglas County', value: 'Douglas County' },
    { state: 'OR', label: 'Gilliam County', value: 'Gilliam County' },
    { state: 'OR', label: 'Grant County', value: 'Grant County' },
    { state: 'OR', label: 'Harney County', value: 'Harney County' },
    { state: 'OR', label: 'Hood River County', value: 'Hood River County' },
    { state: 'OR', label: 'Jackson County', value: 'Jackson County' },
    { state: 'OR', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'OR', label: 'Josephine County', value: 'Josephine County' },
    { state: 'OR', label: 'Klamath County', value: 'Klamath County' },
    { state: 'OR', label: 'Lake County', value: 'Lake County' },
    { state: 'OR', label: 'Lane County', value: 'Lane County' },
    { state: 'OR', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'OR', label: 'Linn County', value: 'Linn County' },
    { state: 'OR', label: 'Malheur County', value: 'Malheur County' },
    { state: 'OR', label: 'Marion County', value: 'Marion County' },
    { state: 'OR', label: 'Morrow County', value: 'Morrow County' },
    { state: 'OR', label: 'Multnomah County', value: 'Multnomah County' },
    { state: 'OR', label: 'Polk County', value: 'Polk County' },
    { state: 'OR', label: 'Sherman County', value: 'Sherman County' },
    { state: 'OR', label: 'Tillamook County', value: 'Tillamook County' },
    { state: 'OR', label: 'Umatilla County', value: 'Umatilla County' },
    { state: 'OR', label: 'Union County', value: 'Union County' },
    { state: 'OR', label: 'Wallowa County', value: 'Wallowa County' },
    { state: 'OR', label: 'Wasco County', value: 'Wasco County' },
    { state: 'OR', label: 'Washington County', value: 'Washington County' },
    { state: 'OR', label: 'Wheeler County', value: 'Wheeler County' },
    { state: 'OR', label: 'Yamhill County', value: 'Yamhill County' },
    { state: 'PA', label: 'Adams County', value: 'Adams County' },
    { state: 'PA', label: 'Allegheny County', value: 'Allegheny County' },
    { state: 'PA', label: 'Armstrong County', value: 'Armstrong County' },
    { state: 'PA', label: 'Beaver County', value: 'Beaver County' },
    { state: 'PA', label: 'Bedford County', value: 'Bedford County' },
    { state: 'PA', label: 'Berks County', value: 'Berks County' },
    { state: 'PA', label: 'Blair County', value: 'Blair County' },
    { state: 'PA', label: 'Bradford County', value: 'Bradford County' },
    { state: 'PA', label: 'Bucks County', value: 'Bucks County' },
    { state: 'PA', label: 'Butler County', value: 'Butler County' },
    { state: 'PA', label: 'Cambria County', value: 'Cambria County' },
    { state: 'PA', label: 'Cameron County', value: 'Cameron County' },
    { state: 'PA', label: 'Carbon County', value: 'Carbon County' },
    { state: 'PA', label: 'Centre County', value: 'Centre County' },
    { state: 'PA', label: 'Chester County', value: 'Chester County' },
    { state: 'PA', label: 'Clarion County', value: 'Clarion County' },
    { state: 'PA', label: 'Clearfield County', value: 'Clearfield County' },
    { state: 'PA', label: 'Clinton County', value: 'Clinton County' },
    { state: 'PA', label: 'Columbia County', value: 'Columbia County' },
    { state: 'PA', label: 'Crawford County', value: 'Crawford County' },
    { state: 'PA', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'PA', label: 'Dauphin County', value: 'Dauphin County' },
    { state: 'PA', label: 'Delaware County', value: 'Delaware County' },
    { state: 'PA', label: 'Elk County', value: 'Elk County' },
    { state: 'PA', label: 'Erie County', value: 'Erie County' },
    { state: 'PA', label: 'Fayette County', value: 'Fayette County' },
    { state: 'PA', label: 'Forest County', value: 'Forest County' },
    { state: 'PA', label: 'Franklin County', value: 'Franklin County' },
    { state: 'PA', label: 'Fulton County', value: 'Fulton County' },
    { state: 'PA', label: 'Greene County', value: 'Greene County' },
    { state: 'PA', label: 'Huntingdon County', value: 'Huntingdon County' },
    { state: 'PA', label: 'Indiana County', value: 'Indiana County' },
    { state: 'PA', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'PA', label: 'Juniata County', value: 'Juniata County' },
    { state: 'PA', label: 'Lackawanna County', value: 'Lackawanna County' },
    { state: 'PA', label: 'Lancaster County', value: 'Lancaster County' },
    { state: 'PA', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'PA', label: 'Lebanon County', value: 'Lebanon County' },
    { state: 'PA', label: 'Lehigh County', value: 'Lehigh County' },
    { state: 'PA', label: 'Luzerne County', value: 'Luzerne County' },
    { state: 'PA', label: 'Lycoming County', value: 'Lycoming County' },
    { state: 'PA', label: 'McKean County', value: 'McKean County' },
    { state: 'PA', label: 'Mercer County', value: 'Mercer County' },
    { state: 'PA', label: 'Mifflin County', value: 'Mifflin County' },
    { state: 'PA', label: 'Monroe County', value: 'Monroe County' },
    { state: 'PA', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'PA', label: 'Montour County', value: 'Montour County' },
    { state: 'PA', label: 'Northampton County', value: 'Northampton County' },
    { state: 'PA', label: 'Northumberland County', value: 'Northumberland County' },
    { state: 'PA', label: 'Perry County', value: 'Perry County' },
    { state: 'PA', label: 'Philadelphia County', value: 'Philadelphia County' },
    { state: 'PA', label: 'Pike County', value: 'Pike County' },
    { state: 'PA', label: 'Potter County', value: 'Potter County' },
    { state: 'PA', label: 'Schuylkill County', value: 'Schuylkill County' },
    { state: 'PA', label: 'Snyder County', value: 'Snyder County' },
    { state: 'PA', label: 'Somerset County', value: 'Somerset County' },
    { state: 'PA', label: 'Sullivan County', value: 'Sullivan County' },
    { state: 'PA', label: 'Susquehanna County', value: 'Susquehanna County' },
    { state: 'PA', label: 'Tioga County', value: 'Tioga County' },
    { state: 'PA', label: 'Union County', value: 'Union County' },
    { state: 'PA', label: 'Venango County', value: 'Venango County' },
    { state: 'PA', label: 'Warren County', value: 'Warren County' },
    { state: 'PA', label: 'Washington County', value: 'Washington County' },
    { state: 'PA', label: 'Wayne County', value: 'Wayne County' },
    { state: 'PA', label: 'Westmoreland County', value: 'Westmoreland County' },
    { state: 'PA', label: 'Wyoming County', value: 'Wyoming County' },
    { state: 'PA', label: 'York County', value: 'York County' },
    { state: 'PR', label: 'Adjuntas Municipio', value: 'Adjuntas Municipio' },
    { state: 'PR', label: 'Adjuntas Municipio', value: 'Adjuntas Municipio' },
    { state: 'PR', label: 'Aguada Municipio', value: 'Aguada Municipio' },
    { state: 'PR', label: 'Aguadilla Municipio', value: 'Aguadilla Municipio' },
    { state: 'PR', label: 'Aguas Buenas Municipio', value: 'Aguas Buenas Municipio' },
    { state: 'PR', label: 'Aibonito Municipio', value: 'Aibonito Municipio' },
    { state: 'PR', label: 'Arecibo Municipio', value: 'Arecibo Municipio' },
    { state: 'PR', label: 'Arroyo Municipio', value: 'Arroyo Municipio' },
    { state: 'PR', label: 'Barceloneta Municipio', value: 'Barceloneta Municipio' },
    { state: 'PR', label: 'Barranquitas Municipio', value: 'Barranquitas Municipio' },
    { state: 'PR', label: 'Bayamón Municipio', value: 'Bayamón Municipio' },
    { state: 'PR', label: 'Cabo Rojo Municipio', value: 'Cabo Rojo Municipio' },
    { state: 'PR', label: 'Caguas Municipio', value: 'Caguas Municipio' },
    { state: 'PR', label: 'Camuy Municipio', value: 'Camuy Municipio' },
    { state: 'PR', label: 'Canóvanas Municipio', value: 'Canóvanas Municipio' },
    { state: 'PR', label: 'Carolina Municipio', value: 'Carolina Municipio' },
    { state: 'PR', label: 'Cataño Municipio', value: 'Cataño Municipio' },
    { state: 'PR', label: 'Cayey Municipio', value: 'Cayey Municipio' },
    { state: 'PR', label: 'Ceiba Municipio', value: 'Ceiba Municipio' },
    { state: 'PR', label: 'Ciales Municipio', value: 'Ciales Municipio' },
    { state: 'PR', label: 'Cidra Municipio', value: 'Cidra Municipio' },
    { state: 'PR', label: 'Coamo Municipio', value: 'Coamo Municipio' },
    { state: 'PR', label: 'Comerío Municipio', value: 'Comerío Municipio' },
    { state: 'PR', label: 'Corozal Municipio', value: 'Corozal Municipio' },
    { state: 'PR', label: 'Culebra Municipio', value: 'Culebra Municipio' },
    { state: 'PR', label: 'Dorado Municipio', value: 'Dorado Municipio' },
    { state: 'PR', label: 'Fajardo Municipio', value: 'Fajardo Municipio' },
    { state: 'PR', label: 'Florida Municipio', value: 'Florida Municipio' },
    { state: 'PR', label: 'Guánica Municipio', value: 'Guánica Municipio' },
    { state: 'PR', label: 'Guayama Municipio', value: 'Guayama Municipio' },
    { state: 'PR', label: 'Guayanilla Municipio', value: 'Guayanilla Municipio' },
    { state: 'PR', label: 'Guaynabo Municipio', value: 'Guaynabo Municipio' },
    { state: 'PR', label: 'Gurabo Municipio', value: 'Gurabo Municipio' },
    { state: 'PR', label: 'Hatillo Municipio', value: 'Hatillo Municipio' },
    { state: 'PR', label: 'Hormigueros Municipio', value: 'Hormigueros Municipio' },
    { state: 'PR', label: 'Humacao Municipio', value: 'Humacao Municipio' },
    { state: 'PR', label: 'Isabela Municipio', value: 'Isabela Municipio' },
    { state: 'PR', label: 'Jayuya Municipio', value: 'Jayuya Municipio' },
    { state: 'PR', label: 'Juana Díaz Municipio', value: 'Juana Díaz Municipio' },
    { state: 'PR', label: 'Juncos Municipio', value: 'Juncos Municipio' },
    { state: 'PR', label: 'Lajas Municipio', value: 'Lajas Municipio' },
    { state: 'PR', label: 'Lares Municipio', value: 'Lares Municipio' },
    { state: 'PR', label: 'Las Marías Municipio', value: 'Las Marías Municipio' },
    { state: 'PR', label: 'Las Piedras Municipio', value: 'Las Piedras Municipio' },
    { state: 'PR', label: 'Loíza Municipio', value: 'Loíza Municipio' },
    { state: 'PR', label: 'Luquillo Municipio', value: 'Luquillo Municipio' },
    { state: 'PR', label: 'Manatí Municipio', value: 'Manatí Municipio' },
    { state: 'PR', label: 'Maricao Municipio', value: 'Maricao Municipio' },
    { state: 'PR', label: 'Maunabo Municipio', value: 'Maunabo Municipio' },
    { state: 'PR', label: 'Mayagüez Municipio', value: 'Mayagüez Municipio' },
    { state: 'PR', label: 'Moca Municipio', value: 'Moca Municipio' },
    { state: 'PR', label: 'Morovis Municipio', value: 'Morovis Municipio' },
    { state: 'PR', label: 'Naguabo Municipio', value: 'Naguabo Municipio' },
    { state: 'PR', label: 'Naranjito Municipio', value: 'Naranjito Municipio' },
    { state: 'PR', label: 'Orocovis Municipio', value: 'Orocovis Municipio' },
    { state: 'PR', label: 'Patillas Municipio', value: 'Patillas Municipio' },
    { state: 'PR', label: 'Peñuelas Municipio', value: 'Peñuelas Municipio' },
    { state: 'PR', label: 'Ponce Municipio', value: 'Ponce Municipio' },
    { state: 'PR', label: 'Quebradillas Municipio', value: 'Quebradillas Municipio' },
    { state: 'PR', label: 'Río Grande Municipio', value: 'Río Grande Municipio' },
    { state: 'PR', label: 'Rincón Municipio', value: 'Rincón Municipio' },
    { state: 'PR', label: 'Sabana Grande Municipio', value: 'Sabana Grande Municipio' },
    { state: 'PR', label: 'Salinas Municipio', value: 'Salinas Municipio' },
    { state: 'PR', label: 'San Germán Municipio', value: 'San Germán Municipio' },
    { state: 'PR', label: 'San Juan Municipio', value: 'San Juan Municipio' },
    { state: 'PR', label: 'San Lorenzo Municipio', value: 'San Lorenzo Municipio' },
    { state: 'PR', label: 'San Sebastián Municipio', value: 'San Sebastián Municipio' },
    { state: 'PR', label: 'Santa Isabel Municipio', value: 'Santa Isabel Municipio' },
    { state: 'PR', label: 'Toa Alta Municipio', value: 'Toa Alta Municipio' },
    { state: 'PR', label: 'Toa Baja Municipio', value: 'Toa Baja Municipio' },
    { state: 'PR', label: 'Trujillo Alto Municipio', value: 'Trujillo Alto Municipio' },
    { state: 'PR', label: 'Utuado Municipio', value: 'Utuado Municipio' },
    { state: 'PR', label: 'Vega Alta Municipio', value: 'Vega Alta Municipio' },
    { state: 'PR', label: 'Vega Baja Municipio', value: 'Vega Baja Municipio' },
    { state: 'PR', label: 'Vieques Municipio', value: 'Vieques Municipio' },
    { state: 'PR', label: 'Villalba Municipio', value: 'Villalba Municipio' },
    { state: 'PR', label: 'Yabucoa Municipio', value: 'Yabucoa Municipio' },
    { state: 'PR', label: 'Yauco Municipio', value: 'Yauco Municipio' },
    { state: 'RI', label: 'Bristol County', value: 'Bristol County' },
    { state: 'RI', label: 'Kent County', value: 'Kent County' },
    { state: 'RI', label: 'Newport County', value: 'Newport County' },
    { state: 'RI', label: 'Providence County', value: 'Providence County' },
    { state: 'RI', label: 'Washington County', value: 'Washington County' },
    { state: 'SC', label: 'Abbeville County', value: 'Abbeville County' },
    { state: 'SC', label: 'Aiken County', value: 'Aiken County' },
    { state: 'SC', label: 'Allendale County', value: 'Allendale County' },
    { state: 'SC', label: 'Anderson County', value: 'Anderson County' },
    { state: 'SC', label: 'Bamberg County', value: 'Bamberg County' },
    { state: 'SC', label: 'Barnwell County', value: 'Barnwell County' },
    { state: 'SC', label: 'Beaufort County', value: 'Beaufort County' },
    { state: 'SC', label: 'Berkeley County', value: 'Berkeley County' },
    { state: 'SC', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'SC', label: 'Charleston County', value: 'Charleston County' },
    { state: 'SC', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'SC', label: 'Chester County', value: 'Chester County' },
    { state: 'SC', label: 'Chesterfield County', value: 'Chesterfield County' },
    { state: 'SC', label: 'Clarendon County', value: 'Clarendon County' },
    { state: 'SC', label: 'Colleton County', value: 'Colleton County' },
    { state: 'SC', label: 'Darlington County', value: 'Darlington County' },
    { state: 'SC', label: 'Dillon County', value: 'Dillon County' },
    { state: 'SC', label: 'Dorchester County', value: 'Dorchester County' },
    { state: 'SC', label: 'Edgefield County', value: 'Edgefield County' },
    { state: 'SC', label: 'Fairfield County', value: 'Fairfield County' },
    { state: 'SC', label: 'Florence County', value: 'Florence County' },
    { state: 'SC', label: 'Georgetown County', value: 'Georgetown County' },
    { state: 'SC', label: 'Greenville County', value: 'Greenville County' },
    { state: 'SC', label: 'Greenwood County', value: 'Greenwood County' },
    { state: 'SC', label: 'Hampton County', value: 'Hampton County' },
    { state: 'SC', label: 'Horry County', value: 'Horry County' },
    { state: 'SC', label: 'Jasper County', value: 'Jasper County' },
    { state: 'SC', label: 'Kershaw County', value: 'Kershaw County' },
    { state: 'SC', label: 'Lancaster County', value: 'Lancaster County' },
    { state: 'SC', label: 'Laurens County', value: 'Laurens County' },
    { state: 'SC', label: 'Lee County', value: 'Lee County' },
    { state: 'SC', label: 'Lexington County', value: 'Lexington County' },
    { state: 'SC', label: 'Marion County', value: 'Marion County' },
    { state: 'SC', label: 'Marlboro County', value: 'Marlboro County' },
    { state: 'SC', label: 'McCormick County', value: 'McCormick County' },
    { state: 'SC', label: 'Newberry County', value: 'Newberry County' },
    { state: 'SC', label: 'Oconee County', value: 'Oconee County' },
    { state: 'SC', label: 'Orangeburg County', value: 'Orangeburg County' },
    { state: 'SC', label: 'Pickens County', value: 'Pickens County' },
    { state: 'SC', label: 'Richland County', value: 'Richland County' },
    { state: 'SC', label: 'Saluda County', value: 'Saluda County' },
    { state: 'SC', label: 'Spartanburg County', value: 'Spartanburg County' },
    { state: 'SC', label: 'Sumter County', value: 'Sumter County' },
    { state: 'SC', label: 'Union County', value: 'Union County' },
    { state: 'SC', label: 'Williamsburg County', value: 'Williamsburg County' },
    { state: 'SC', label: 'York County', value: 'York County' },
    { state: 'SD', label: 'Aurora County', value: 'Aurora County' },
    { state: 'SD', label: 'Beadle County', value: 'Beadle County' },
    { state: 'SD', label: 'Bennett County', value: 'Bennett County' },
    { state: 'SD', label: 'Bon Homme County', value: 'Bon Homme County' },
    { state: 'SD', label: 'Brookings County', value: 'Brookings County' },
    { state: 'SD', label: 'Brown County', value: 'Brown County' },
    { state: 'SD', label: 'Brule County', value: 'Brule County' },
    { state: 'SD', label: 'Buffalo County', value: 'Buffalo County' },
    { state: 'SD', label: 'Butte County', value: 'Butte County' },
    { state: 'SD', label: 'Campbell County', value: 'Campbell County' },
    { state: 'SD', label: 'Charles Mix County', value: 'Charles Mix County' },
    { state: 'SD', label: 'Clark County', value: 'Clark County' },
    { state: 'SD', label: 'Clay County', value: 'Clay County' },
    { state: 'SD', label: 'Codington County', value: 'Codington County' },
    { state: 'SD', label: 'Corson County', value: 'Corson County' },
    { state: 'SD', label: 'Custer County', value: 'Custer County' },
    { state: 'SD', label: 'Davison County', value: 'Davison County' },
    { state: 'SD', label: 'Day County', value: 'Day County' },
    { state: 'SD', label: 'Deuel County', value: 'Deuel County' },
    { state: 'SD', label: 'Dewey County', value: 'Dewey County' },
    { state: 'SD', label: 'Douglas County', value: 'Douglas County' },
    { state: 'SD', label: 'Edmunds County', value: 'Edmunds County' },
    { state: 'SD', label: 'Fall River County', value: 'Fall River County' },
    { state: 'SD', label: 'Faulk County', value: 'Faulk County' },
    { state: 'SD', label: 'Grant County', value: 'Grant County' },
    { state: 'SD', label: 'Gregory County', value: 'Gregory County' },
    { state: 'SD', label: 'Haakon County', value: 'Haakon County' },
    { state: 'SD', label: 'Hamlin County', value: 'Hamlin County' },
    { state: 'SD', label: 'Hand County', value: 'Hand County' },
    { state: 'SD', label: 'Hanson County', value: 'Hanson County' },
    { state: 'SD', label: 'Harding County', value: 'Harding County' },
    { state: 'SD', label: 'Hughes County', value: 'Hughes County' },
    { state: 'SD', label: 'Hutchinson County', value: 'Hutchinson County' },
    { state: 'SD', label: 'Hyde County', value: 'Hyde County' },
    { state: 'SD', label: 'Jackson County', value: 'Jackson County' },
    { state: 'SD', label: 'Jerauld County', value: 'Jerauld County' },
    { state: 'SD', label: 'Jones County', value: 'Jones County' },
    { state: 'SD', label: 'Kingsbury County', value: 'Kingsbury County' },
    { state: 'SD', label: 'Lake County', value: 'Lake County' },
    { state: 'SD', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'SD', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'SD', label: 'Lyman County', value: 'Lyman County' },
    { state: 'SD', label: 'Marshall County', value: 'Marshall County' },
    { state: 'SD', label: 'McCook County', value: 'McCook County' },
    { state: 'SD', label: 'McPherson County', value: 'McPherson County' },
    { state: 'SD', label: 'Meade County', value: 'Meade County' },
    { state: 'SD', label: 'Mellette County', value: 'Mellette County' },
    { state: 'SD', label: 'Miner County', value: 'Miner County' },
    { state: 'SD', label: 'Minnehaha County', value: 'Minnehaha County' },
    { state: 'SD', label: 'Moody County', value: 'Moody County' },
    { state: 'SD', label: 'Oglala Dakota County', value: 'Oglala Dakota County' },
    { state: 'SD', label: 'Pennington County', value: 'Pennington County' },
    { state: 'SD', label: 'Perkins County', value: 'Perkins County' },
    { state: 'SD', label: 'Potter County', value: 'Potter County' },
    { state: 'SD', label: 'Roberts County', value: 'Roberts County' },
    { state: 'SD', label: 'Sanborn County', value: 'Sanborn County' },
    { state: 'SD', label: 'Spink County', value: 'Spink County' },
    { state: 'SD', label: 'Stanley County', value: 'Stanley County' },
    { state: 'SD', label: 'Sully County', value: 'Sully County' },
    { state: 'SD', label: 'Todd County', value: 'Todd County' },
    { state: 'SD', label: 'Tripp County', value: 'Tripp County' },
    { state: 'SD', label: 'Turner County', value: 'Turner County' },
    { state: 'SD', label: 'Union County', value: 'Union County' },
    { state: 'SD', label: 'Walworth County', value: 'Walworth County' },
    { state: 'SD', label: 'Yankton County', value: 'Yankton County' },
    { state: 'SD', label: 'Ziebach County', value: 'Ziebach County' },
    { state: 'TN', label: 'Anderson County', value: 'Anderson County' },
    { state: 'TN', label: 'Bedford County', value: 'Bedford County' },
    { state: 'TN', label: 'Benton County', value: 'Benton County' },
    { state: 'TN', label: 'Bledsoe County', value: 'Bledsoe County' },
    { state: 'TN', label: 'Blount County', value: 'Blount County' },
    { state: 'TN', label: 'Bradley County', value: 'Bradley County' },
    { state: 'TN', label: 'Campbell County', value: 'Campbell County' },
    { state: 'TN', label: 'Cannon County', value: 'Cannon County' },
    { state: 'TN', label: 'Carroll County', value: 'Carroll County' },
    { state: 'TN', label: 'Carter County', value: 'Carter County' },
    { state: 'TN', label: 'Cheatham County', value: 'Cheatham County' },
    { state: 'TN', label: 'Chester County', value: 'Chester County' },
    { state: 'TN', label: 'Claiborne County', value: 'Claiborne County' },
    { state: 'TN', label: 'Clay County', value: 'Clay County' },
    { state: 'TN', label: 'Cocke County', value: 'Cocke County' },
    { state: 'TN', label: 'Coffee County', value: 'Coffee County' },
    { state: 'TN', label: 'Crockett County', value: 'Crockett County' },
    { state: 'TN', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'TN', label: 'Davidson County', value: 'Davidson County' },
    { state: 'TN', label: 'Decatur County', value: 'Decatur County' },
    { state: 'TN', label: 'DeKalb County', value: 'DeKalb County' },
    { state: 'TN', label: 'Dickson County', value: 'Dickson County' },
    { state: 'TN', label: 'Dyer County', value: 'Dyer County' },
    { state: 'TN', label: 'Fayette County', value: 'Fayette County' },
    { state: 'TN', label: 'Fentress County', value: 'Fentress County' },
    { state: 'TN', label: 'Franklin County', value: 'Franklin County' },
    { state: 'TN', label: 'Gibson County', value: 'Gibson County' },
    { state: 'TN', label: 'Giles County', value: 'Giles County' },
    { state: 'TN', label: 'Grainger County', value: 'Grainger County' },
    { state: 'TN', label: 'Greene County', value: 'Greene County' },
    { state: 'TN', label: 'Grundy County', value: 'Grundy County' },
    { state: 'TN', label: 'Hamblen County', value: 'Hamblen County' },
    { state: 'TN', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'TN', label: 'Hancock County', value: 'Hancock County' },
    { state: 'TN', label: 'Hardeman County', value: 'Hardeman County' },
    { state: 'TN', label: 'Hardin County', value: 'Hardin County' },
    { state: 'TN', label: 'Hawkins County', value: 'Hawkins County' },
    { state: 'TN', label: 'Haywood County', value: 'Haywood County' },
    { state: 'TN', label: 'Henderson County', value: 'Henderson County' },
    { state: 'TN', label: 'Henry County', value: 'Henry County' },
    { state: 'TN', label: 'Hickman County', value: 'Hickman County' },
    { state: 'TN', label: 'Houston County', value: 'Houston County' },
    { state: 'TN', label: 'Humphreys County', value: 'Humphreys County' },
    { state: 'TN', label: 'Jackson County', value: 'Jackson County' },
    { state: 'TN', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'TN', label: 'Johnson County', value: 'Johnson County' },
    { state: 'TN', label: 'Knox County', value: 'Knox County' },
    { state: 'TN', label: 'Lake County', value: 'Lake County' },
    { state: 'TN', label: 'Lauderdale County', value: 'Lauderdale County' },
    { state: 'TN', label: 'Lawrence County', value: 'Lawrence County' },
    { state: 'TN', label: 'Lewis County', value: 'Lewis County' },
    { state: 'TN', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'TN', label: 'Loudon County', value: 'Loudon County' },
    { state: 'TN', label: 'Macon County', value: 'Macon County' },
    { state: 'TN', label: 'Madison County', value: 'Madison County' },
    { state: 'TN', label: 'Marion County', value: 'Marion County' },
    { state: 'TN', label: 'Marshall County', value: 'Marshall County' },
    { state: 'TN', label: 'Maury County', value: 'Maury County' },
    { state: 'TN', label: 'McMinn County', value: 'McMinn County' },
    { state: 'TN', label: 'McNairy County', value: 'McNairy County' },
    { state: 'TN', label: 'Meigs County', value: 'Meigs County' },
    { state: 'TN', label: 'Monroe County', value: 'Monroe County' },
    { state: 'TN', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'TN', label: 'Moore County', value: 'Moore County' },
    { state: 'TN', label: 'Morgan County', value: 'Morgan County' },
    { state: 'TN', label: 'Obion County', value: 'Obion County' },
    { state: 'TN', label: 'Overton County', value: 'Overton County' },
    { state: 'TN', label: 'Perry County', value: 'Perry County' },
    { state: 'TN', label: 'Pickett County', value: 'Pickett County' },
    { state: 'TN', label: 'Polk County', value: 'Polk County' },
    { state: 'TN', label: 'Putnam County', value: 'Putnam County' },
    { state: 'TN', label: 'Rhea County', value: 'Rhea County' },
    { state: 'TN', label: 'Roane County', value: 'Roane County' },
    { state: 'TN', label: 'Robertson County', value: 'Robertson County' },
    { state: 'TN', label: 'Rutherford County', value: 'Rutherford County' },
    { state: 'TN', label: 'Scott County', value: 'Scott County' },
    { state: 'TN', label: 'Sequatchie County', value: 'Sequatchie County' },
    { state: 'TN', label: 'Sevier County', value: 'Sevier County' },
    { state: 'TN', label: 'Shelby County', value: 'Shelby County' },
    { state: 'TN', label: 'Smith County', value: 'Smith County' },
    { state: 'TN', label: 'Stewart County', value: 'Stewart County' },
    { state: 'TN', label: 'Sullivan County', value: 'Sullivan County' },
    { state: 'TN', label: 'Sumner County', value: 'Sumner County' },
    { state: 'TN', label: 'Tipton County', value: 'Tipton County' },
    { state: 'TN', label: 'Trousdale County', value: 'Trousdale County' },
    { state: 'TN', label: 'Unicoi County', value: 'Unicoi County' },
    { state: 'TN', label: 'Union County', value: 'Union County' },
    { state: 'TN', label: 'Van Buren County', value: 'Van Buren County' },
    { state: 'TN', label: 'Warren County', value: 'Warren County' },
    { state: 'TN', label: 'Washington County', value: 'Washington County' },
    { state: 'TN', label: 'Wayne County', value: 'Wayne County' },
    { state: 'TN', label: 'Weakley County', value: 'Weakley County' },
    { state: 'TN', label: 'White County', value: 'White County' },
    { state: 'TN', label: 'Williamson County', value: 'Williamson County' },
    { state: 'TN', label: 'Wilson County', value: 'Wilson County' },
    { state: 'TX', label: 'Anderson County', value: 'Anderson County' },
    { state: 'TX', label: 'Andrews County', value: 'Andrews County' },
    { state: 'TX', label: 'Angelina County', value: 'Angelina County' },
    { state: 'TX', label: 'Aransas County', value: 'Aransas County' },
    { state: 'TX', label: 'Archer County', value: 'Archer County' },
    { state: 'TX', label: 'Armstrong County', value: 'Armstrong County' },
    { state: 'TX', label: 'Atascosa County', value: 'Atascosa County' },
    { state: 'TX', label: 'Austin County', value: 'Austin County' },
    { state: 'TX', label: 'Bailey County', value: 'Bailey County' },
    { state: 'TX', label: 'Bandera County', value: 'Bandera County' },
    { state: 'TX', label: 'Bastrop County', value: 'Bastrop County' },
    { state: 'TX', label: 'Baylor County', value: 'Baylor County' },
    { state: 'TX', label: 'Bee County', value: 'Bee County' },
    { state: 'TX', label: 'Bell County', value: 'Bell County' },
    { state: 'TX', label: 'Bexar County', value: 'Bexar County' },
    { state: 'TX', label: 'Blanco County', value: 'Blanco County' },
    { state: 'TX', label: 'Borden County', value: 'Borden County' },
    { state: 'TX', label: 'Bosque County', value: 'Bosque County' },
    { state: 'TX', label: 'Bowie County', value: 'Bowie County' },
    { state: 'TX', label: 'Brazoria County', value: 'Brazoria County' },
    { state: 'TX', label: 'Brazos County', value: 'Brazos County' },
    { state: 'TX', label: 'Brewster County', value: 'Brewster County' },
    { state: 'TX', label: 'Briscoe County', value: 'Briscoe County' },
    { state: 'TX', label: 'Brooks County', value: 'Brooks County' },
    { state: 'TX', label: 'Brown County', value: 'Brown County' },
    { state: 'TX', label: 'Burleson County', value: 'Burleson County' },
    { state: 'TX', label: 'Burnet County', value: 'Burnet County' },
    { state: 'TX', label: 'Caldwell County', value: 'Caldwell County' },
    { state: 'TX', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'TX', label: 'Callahan County', value: 'Callahan County' },
    { state: 'TX', label: 'Cameron County', value: 'Cameron County' },
    { state: 'TX', label: 'Camp County', value: 'Camp County' },
    { state: 'TX', label: 'Carson County', value: 'Carson County' },
    { state: 'TX', label: 'Cass County', value: 'Cass County' },
    { state: 'TX', label: 'Castro County', value: 'Castro County' },
    { state: 'TX', label: 'Chambers County', value: 'Chambers County' },
    { state: 'TX', label: 'Cherokee County', value: 'Cherokee County' },
    { state: 'TX', label: 'Childress County', value: 'Childress County' },
    { state: 'TX', label: 'Clay County', value: 'Clay County' },
    { state: 'TX', label: 'Cochran County', value: 'Cochran County' },
    { state: 'TX', label: 'Coke County', value: 'Coke County' },
    { state: 'TX', label: 'Coleman County', value: 'Coleman County' },
    { state: 'TX', label: 'Collin County', value: 'Collin County' },
    { state: 'TX', label: 'Collingsworth County', value: 'Collingsworth County' },
    { state: 'TX', label: 'Colorado County', value: 'Colorado County' },
    { state: 'TX', label: 'Comal County', value: 'Comal County' },
    { state: 'TX', label: 'Comanche County', value: 'Comanche County' },
    { state: 'TX', label: 'Concho County', value: 'Concho County' },
    { state: 'TX', label: 'Cooke County', value: 'Cooke County' },
    { state: 'TX', label: 'Coryell County', value: 'Coryell County' },
    { state: 'TX', label: 'Cottle County', value: 'Cottle County' },
    { state: 'TX', label: 'Crane County', value: 'Crane County' },
    { state: 'TX', label: 'Crockett County', value: 'Crockett County' },
    { state: 'TX', label: 'Crosby County', value: 'Crosby County' },
    { state: 'TX', label: 'Culberson County', value: 'Culberson County' },
    { state: 'TX', label: 'Dallam County', value: 'Dallam County' },
    { state: 'TX', label: 'Dallas County', value: 'Dallas County' },
    { state: 'TX', label: 'Dawson County', value: 'Dawson County' },
    { state: 'TX', label: 'Deaf Smith County', value: 'Deaf Smith County' },
    { state: 'TX', label: 'Delta County', value: 'Delta County' },
    { state: 'TX', label: 'Denton County', value: 'Denton County' },
    { state: 'TX', label: 'DeWitt County', value: 'DeWitt County' },
    { state: 'TX', label: 'Dickens County', value: 'Dickens County' },
    { state: 'TX', label: 'Dimmit County', value: 'Dimmit County' },
    { state: 'TX', label: 'Donley County', value: 'Donley County' },
    { state: 'TX', label: 'Duval County', value: 'Duval County' },
    { state: 'TX', label: 'Eastland County', value: 'Eastland County' },
    { state: 'TX', label: 'Ector County', value: 'Ector County' },
    { state: 'TX', label: 'Edwards County', value: 'Edwards County' },
    { state: 'TX', label: 'El Paso County', value: 'El Paso County' },
    { state: 'TX', label: 'Ellis County', value: 'Ellis County' },
    { state: 'TX', label: 'Erath County', value: 'Erath County' },
    { state: 'TX', label: 'Falls County', value: 'Falls County' },
    { state: 'TX', label: 'Fannin County', value: 'Fannin County' },
    { state: 'TX', label: 'Fayette County', value: 'Fayette County' },
    { state: 'TX', label: 'Fisher County', value: 'Fisher County' },
    { state: 'TX', label: 'Floyd County', value: 'Floyd County' },
    { state: 'TX', label: 'Foard County', value: 'Foard County' },
    { state: 'TX', label: 'Fort Bend County', value: 'Fort Bend County' },
    { state: 'TX', label: 'Franklin County', value: 'Franklin County' },
    { state: 'TX', label: 'Freestone County', value: 'Freestone County' },
    { state: 'TX', label: 'Frio County', value: 'Frio County' },
    { state: 'TX', label: 'Gaines County', value: 'Gaines County' },
    { state: 'TX', label: 'Galveston County', value: 'Galveston County' },
    { state: 'TX', label: 'Garza County', value: 'Garza County' },
    { state: 'TX', label: 'Gillespie County', value: 'Gillespie County' },
    { state: 'TX', label: 'Glasscock County', value: 'Glasscock County' },
    { state: 'TX', label: 'Goliad County', value: 'Goliad County' },
    { state: 'TX', label: 'Gonzales County', value: 'Gonzales County' },
    { state: 'TX', label: 'Gray County', value: 'Gray County' },
    { state: 'TX', label: 'Grayson County', value: 'Grayson County' },
    { state: 'TX', label: 'Gregg County', value: 'Gregg County' },
    { state: 'TX', label: 'Grimes County', value: 'Grimes County' },
    { state: 'TX', label: 'Guadalupe County', value: 'Guadalupe County' },
    { state: 'TX', label: 'Hale County', value: 'Hale County' },
    { state: 'TX', label: 'Hall County', value: 'Hall County' },
    { state: 'TX', label: 'Hamilton County', value: 'Hamilton County' },
    { state: 'TX', label: 'Hansford County', value: 'Hansford County' },
    { state: 'TX', label: 'Hardeman County', value: 'Hardeman County' },
    { state: 'TX', label: 'Hardin County', value: 'Hardin County' },
    { state: 'TX', label: 'Harris County', value: 'Harris County' },
    { state: 'TX', label: 'Harrison County', value: 'Harrison County' },
    { state: 'TX', label: 'Hartley County', value: 'Hartley County' },
    { state: 'TX', label: 'Haskell County', value: 'Haskell County' },
    { state: 'TX', label: 'Hays County', value: 'Hays County' },
    { state: 'TX', label: 'Hemphill County', value: 'Hemphill County' },
    { state: 'TX', label: 'Henderson County', value: 'Henderson County' },
    { state: 'TX', label: 'Hidalgo County', value: 'Hidalgo County' },
    { state: 'TX', label: 'Hill County', value: 'Hill County' },
    { state: 'TX', label: 'Hockley County', value: 'Hockley County' },
    { state: 'TX', label: 'Hood County', value: 'Hood County' },
    { state: 'TX', label: 'Hopkins County', value: 'Hopkins County' },
    { state: 'TX', label: 'Houston County', value: 'Houston County' },
    { state: 'TX', label: 'Howard County', value: 'Howard County' },
    { state: 'TX', label: 'Hudspeth County', value: 'Hudspeth County' },
    { state: 'TX', label: 'Hunt County', value: 'Hunt County' },
    { state: 'TX', label: 'Hutchinson County', value: 'Hutchinson County' },
    { state: 'TX', label: 'Irion County', value: 'Irion County' },
    { state: 'TX', label: 'Jack County', value: 'Jack County' },
    { state: 'TX', label: 'Jackson County', value: 'Jackson County' },
    { state: 'TX', label: 'Jasper County', value: 'Jasper County' },
    { state: 'TX', label: 'Jeff Davis County', value: 'Jeff Davis County' },
    { state: 'TX', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'TX', label: 'Jim Hogg County', value: 'Jim Hogg County' },
    { state: 'TX', label: 'Jim Wells County', value: 'Jim Wells County' },
    { state: 'TX', label: 'Johnson County', value: 'Johnson County' },
    { state: 'TX', label: 'Jones County', value: 'Jones County' },
    { state: 'TX', label: 'Karnes County', value: 'Karnes County' },
    { state: 'TX', label: 'Kaufman County', value: 'Kaufman County' },
    { state: 'TX', label: 'Kendall County', value: 'Kendall County' },
    { state: 'TX', label: 'Kenedy County', value: 'Kenedy County' },
    { state: 'TX', label: 'Kent County', value: 'Kent County' },
    { state: 'TX', label: 'Kerr County', value: 'Kerr County' },
    { state: 'TX', label: 'Kimble County', value: 'Kimble County' },
    { state: 'TX', label: 'King County', value: 'King County' },
    { state: 'TX', label: 'Kinney County', value: 'Kinney County' },
    { state: 'TX', label: 'Kleberg County', value: 'Kleberg County' },
    { state: 'TX', label: 'Knox County', value: 'Knox County' },
    { state: 'TX', label: 'La Salle County', value: 'La Salle County' },
    { state: 'TX', label: 'Lamar County', value: 'Lamar County' },
    { state: 'TX', label: 'Lamb County', value: 'Lamb County' },
    { state: 'TX', label: 'Lampasas County', value: 'Lampasas County' },
    { state: 'TX', label: 'Lavaca County', value: 'Lavaca County' },
    { state: 'TX', label: 'Lee County', value: 'Lee County' },
    { state: 'TX', label: 'Leon County', value: 'Leon County' },
    { state: 'TX', label: 'Liberty County', value: 'Liberty County' },
    { state: 'TX', label: 'Limestone County', value: 'Limestone County' },
    { state: 'TX', label: 'Lipscomb County', value: 'Lipscomb County' },
    { state: 'TX', label: 'Live Oak County', value: 'Live Oak County' },
    { state: 'TX', label: 'Llano County', value: 'Llano County' },
    { state: 'TX', label: 'Loving County', value: 'Loving County' },
    { state: 'TX', label: 'Lubbock County', value: 'Lubbock County' },
    { state: 'TX', label: 'Lynn County', value: 'Lynn County' },
    { state: 'TX', label: 'Madison County', value: 'Madison County' },
    { state: 'TX', label: 'Marion County', value: 'Marion County' },
    { state: 'TX', label: 'Martin County', value: 'Martin County' },
    { state: 'TX', label: 'Mason County', value: 'Mason County' },
    { state: 'TX', label: 'Matagorda County', value: 'Matagorda County' },
    { state: 'TX', label: 'Maverick County', value: 'Maverick County' },
    { state: 'TX', label: 'McCulloch County', value: 'McCulloch County' },
    { state: 'TX', label: 'McLennan County', value: 'McLennan County' },
    { state: 'TX', label: 'McMullen County', value: 'McMullen County' },
    { state: 'TX', label: 'Medina County', value: 'Medina County' },
    { state: 'TX', label: 'Menard County', value: 'Menard County' },
    { state: 'TX', label: 'Midland County', value: 'Midland County' },
    { state: 'TX', label: 'Milam County', value: 'Milam County' },
    { state: 'TX', label: 'Mills County', value: 'Mills County' },
    { state: 'TX', label: 'Mitchell County', value: 'Mitchell County' },
    { state: 'TX', label: 'Montague County', value: 'Montague County' },
    { state: 'TX', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'TX', label: 'Moore County', value: 'Moore County' },
    { state: 'TX', label: 'Morris County', value: 'Morris County' },
    { state: 'TX', label: 'Motley County', value: 'Motley County' },
    { state: 'TX', label: 'Nacogdoches County', value: 'Nacogdoches County' },
    { state: 'TX', label: 'Navarro County', value: 'Navarro County' },
    { state: 'TX', label: 'Newton County', value: 'Newton County' },
    { state: 'TX', label: 'Nolan County', value: 'Nolan County' },
    { state: 'TX', label: 'Nueces County', value: 'Nueces County' },
    { state: 'TX', label: 'Ochiltree County', value: 'Ochiltree County' },
    { state: 'TX', label: 'Oldham County', value: 'Oldham County' },
    { state: 'TX', label: 'Orange County', value: 'Orange County' },
    { state: 'TX', label: 'Palo Pinto County', value: 'Palo Pinto County' },
    { state: 'TX', label: 'Panola County', value: 'Panola County' },
    { state: 'TX', label: 'Parker County', value: 'Parker County' },
    { state: 'TX', label: 'Parmer County', value: 'Parmer County' },
    { state: 'TX', label: 'Pecos County', value: 'Pecos County' },
    { state: 'TX', label: 'Polk County', value: 'Polk County' },
    { state: 'TX', label: 'Potter County', value: 'Potter County' },
    { state: 'TX', label: 'Presidio County', value: 'Presidio County' },
    { state: 'TX', label: 'Rains County', value: 'Rains County' },
    { state: 'TX', label: 'Randall County', value: 'Randall County' },
    { state: 'TX', label: 'Reagan County', value: 'Reagan County' },
    { state: 'TX', label: 'Real County', value: 'Real County' },
    { state: 'TX', label: 'Red River County', value: 'Red River County' },
    { state: 'TX', label: 'Reeves County', value: 'Reeves County' },
    { state: 'TX', label: 'Refugio County', value: 'Refugio County' },
    { state: 'TX', label: 'Roberts County', value: 'Roberts County' },
    { state: 'TX', label: 'Robertson County', value: 'Robertson County' },
    { state: 'TX', label: 'Rockwall County', value: 'Rockwall County' },
    { state: 'TX', label: 'Runnels County', value: 'Runnels County' },
    { state: 'TX', label: 'Rusk County', value: 'Rusk County' },
    { state: 'TX', label: 'Sabine County', value: 'Sabine County' },
    { state: 'TX', label: 'San Augustine County', value: 'San Augustine County' },
    { state: 'TX', label: 'San Jacinto County', value: 'San Jacinto County' },
    { state: 'TX', label: 'San Patricio County', value: 'San Patricio County' },
    { state: 'TX', label: 'San Saba County', value: 'San Saba County' },
    { state: 'TX', label: 'Schleicher County', value: 'Schleicher County' },
    { state: 'TX', label: 'Scurry County', value: 'Scurry County' },
    { state: 'TX', label: 'Shackelford County', value: 'Shackelford County' },
    { state: 'TX', label: 'Shelby County', value: 'Shelby County' },
    { state: 'TX', label: 'Sherman County', value: 'Sherman County' },
    { state: 'TX', label: 'Smith County', value: 'Smith County' },
    { state: 'TX', label: 'Somervell County', value: 'Somervell County' },
    { state: 'TX', label: 'Starr County', value: 'Starr County' },
    { state: 'TX', label: 'Stephens County', value: 'Stephens County' },
    { state: 'TX', label: 'Sterling County', value: 'Sterling County' },
    { state: 'TX', label: 'Stonewall County', value: 'Stonewall County' },
    { state: 'TX', label: 'Sutton County', value: 'Sutton County' },
    { state: 'TX', label: 'Swisher County', value: 'Swisher County' },
    { state: 'TX', label: 'Tarrant County', value: 'Tarrant County' },
    { state: 'TX', label: 'Taylor County', value: 'Taylor County' },
    { state: 'TX', label: 'Terrell County', value: 'Terrell County' },
    { state: 'TX', label: 'Terry County', value: 'Terry County' },
    { state: 'TX', label: 'Throckmorton County', value: 'Throckmorton County' },
    { state: 'TX', label: 'Titus County', value: 'Titus County' },
    { state: 'TX', label: 'Tom Green County', value: 'Tom Green County' },
    { state: 'TX', label: 'Travis County', value: 'Travis County' },
    { state: 'TX', label: 'Trinity County', value: 'Trinity County' },
    { state: 'TX', label: 'Tyler County', value: 'Tyler County' },
    { state: 'TX', label: 'Upshur County', value: 'Upshur County' },
    { state: 'TX', label: 'Upton County', value: 'Upton County' },
    { state: 'TX', label: 'Uvalde County', value: 'Uvalde County' },
    { state: 'TX', label: 'Val Verde County', value: 'Val Verde County' },
    { state: 'TX', label: 'Van Zandt County', value: 'Van Zandt County' },
    { state: 'TX', label: 'Victoria County', value: 'Victoria County' },
    { state: 'TX', label: 'Walker County', value: 'Walker County' },
    { state: 'TX', label: 'Waller County', value: 'Waller County' },
    { state: 'TX', label: 'Ward County', value: 'Ward County' },
    { state: 'TX', label: 'Washington County', value: 'Washington County' },
    { state: 'TX', label: 'Webb County', value: 'Webb County' },
    { state: 'TX', label: 'Wharton County', value: 'Wharton County' },
    { state: 'TX', label: 'Wheeler County', value: 'Wheeler County' },
    { state: 'TX', label: 'Wichita County', value: 'Wichita County' },
    { state: 'TX', label: 'Wilbarger County', value: 'Wilbarger County' },
    { state: 'TX', label: 'Willacy County', value: 'Willacy County' },
    { state: 'TX', label: 'Williamson County', value: 'Williamson County' },
    { state: 'TX', label: 'Wilson County', value: 'Wilson County' },
    { state: 'TX', label: 'Winkler County', value: 'Winkler County' },
    { state: 'TX', label: 'Wise County', value: 'Wise County' },
    { state: 'TX', label: 'Wood County', value: 'Wood County' },
    { state: 'TX', label: 'Yoakum County', value: 'Yoakum County' },
    { state: 'TX', label: 'Young County', value: 'Young County' },
    { state: 'TX', label: 'Zapata County', value: 'Zapata County' },
    { state: 'TX', label: 'Zavala County', value: 'Zavala County' },
    { state: 'UT', label: 'Beaver County', value: 'Beaver County' },
    { state: 'UT', label: 'Box Elder County', value: 'Box Elder County' },
    { state: 'UT', label: 'Cache County', value: 'Cache County' },
    { state: 'UT', label: 'Carbon County', value: 'Carbon County' },
    { state: 'UT', label: 'Daggett County', value: 'Daggett County' },
    { state: 'UT', label: 'Davis County', value: 'Davis County' },
    { state: 'UT', label: 'Duchesne County', value: 'Duchesne County' },
    { state: 'UT', label: 'Emery County', value: 'Emery County' },
    { state: 'UT', label: 'Garfield County', value: 'Garfield County' },
    { state: 'UT', label: 'Grand County', value: 'Grand County' },
    { state: 'UT', label: 'Iron County', value: 'Iron County' },
    { state: 'UT', label: 'Juab County', value: 'Juab County' },
    { state: 'UT', label: 'Kane County', value: 'Kane County' },
    { state: 'UT', label: 'Millard County', value: 'Millard County' },
    { state: 'UT', label: 'Morgan County', value: 'Morgan County' },
    { state: 'UT', label: 'Piute County', value: 'Piute County' },
    { state: 'UT', label: 'Rich County', value: 'Rich County' },
    { state: 'UT', label: 'Salt Lake County', value: 'Salt Lake County' },
    { state: 'UT', label: 'San Juan County', value: 'San Juan County' },
    { state: 'UT', label: 'Sanpete County', value: 'Sanpete County' },
    { state: 'UT', label: 'Sevier County', value: 'Sevier County' },
    { state: 'UT', label: 'Summit County', value: 'Summit County' },
    { state: 'UT', label: 'Tooele County', value: 'Tooele County' },
    { state: 'UT', label: 'Uintah County', value: 'Uintah County' },
    { state: 'UT', label: 'Utah County', value: 'Utah County' },
    { state: 'UT', label: 'Wasatch County', value: 'Wasatch County' },
    { state: 'UT', label: 'Washington County', value: 'Washington County' },
    { state: 'UT', label: 'Wayne County', value: 'Wayne County' },
    { state: 'UT', label: 'Weber County', value: 'Weber County' },
    { state: 'VA', label: 'Accomack County', value: 'Accomack County' },
    { state: 'VA', label: 'Albemarle County', value: 'Albemarle County' },
    { state: 'VA', label: 'Alexandria city', value: 'Alexandria city' },
    { state: 'VA', label: 'Alleghany County', value: 'Alleghany County' },
    { state: 'VA', label: 'Amelia County', value: 'Amelia County' },
    { state: 'VA', label: 'Amherst County', value: 'Amherst County' },
    { state: 'VA', label: 'Appomattox County', value: 'Appomattox County' },
    { state: 'VA', label: 'Arlington County', value: 'Arlington County' },
    { state: 'VA', label: 'Augusta County', value: 'Augusta County' },
    { state: 'VA', label: 'Bath County', value: 'Bath County' },
    { state: 'VA', label: 'Bedford city', value: 'Bedford city' },
    { state: 'VA', label: 'Bedford County', value: 'Bedford County' },
    { state: 'VA', label: 'Bland County', value: 'Bland County' },
    { state: 'VA', label: 'Botetourt County', value: 'Botetourt County' },
    { state: 'VA', label: 'Bristol city', value: 'Bristol city' },
    { state: 'VA', label: 'Brunswick County', value: 'Brunswick County' },
    { state: 'VA', label: 'Buchanan County', value: 'Buchanan County' },
    { state: 'VA', label: 'Buckingham County', value: 'Buckingham County' },
    { state: 'VA', label: 'Buena Vista city', value: 'Buena Vista city' },
    { state: 'VA', label: 'Campbell County', value: 'Campbell County' },
    { state: 'VA', label: 'Caroline County', value: 'Caroline County' },
    { state: 'VA', label: 'Carroll County', value: 'Carroll County' },
    { state: 'VA', label: 'Charles City County', value: 'Charles City County' },
    { state: 'VA', label: 'Charlotte County', value: 'Charlotte County' },
    { state: 'VA', label: 'Charlottesville city', value: 'Charlottesville city' },
    { state: 'VA', label: 'Chesapeake city', value: 'Chesapeake city' },
    { state: 'VA', label: 'Chesterfield County', value: 'Chesterfield County' },
    { state: 'VA', label: 'Clarke County', value: 'Clarke County' },
    { state: 'VA', label: 'Colonial Heights city', value: 'Colonial Heights city' },
    { state: 'VA', label: 'Covington city', value: 'Covington city' },
    { state: 'VA', label: 'Craig County', value: 'Craig County' },
    { state: 'VA', label: 'Culpeper County', value: 'Culpeper County' },
    { state: 'VA', label: 'Cumberland County', value: 'Cumberland County' },
    { state: 'VA', label: 'Danville city', value: 'Danville city' },
    { state: 'VA', label: 'Dickenson County', value: 'Dickenson County' },
    { state: 'VA', label: 'Dinwiddie County', value: 'Dinwiddie County' },
    { state: 'VA', label: 'Emporia city', value: 'Emporia city' },
    { state: 'VA', label: 'Essex County', value: 'Essex County' },
    { state: 'VA', label: 'Fairfax city', value: 'Fairfax city' },
    { state: 'VA', label: 'Fairfax County', value: 'Fairfax County' },
    { state: 'VA', label: 'Falls Church city', value: 'Falls Church city' },
    { state: 'VA', label: 'Fauquier County', value: 'Fauquier County' },
    { state: 'VA', label: 'Floyd County', value: 'Floyd County' },
    { state: 'VA', label: 'Fluvanna County', value: 'Fluvanna County' },
    { state: 'VA', label: 'Franklin city', value: 'Franklin city' },
    { state: 'VA', label: 'Franklin County', value: 'Franklin County' },
    { state: 'VA', label: 'Frederick County', value: 'Frederick County' },
    { state: 'VA', label: 'Fredericksburg city', value: 'Fredericksburg city' },
    { state: 'VA', label: 'Galax city', value: 'Galax city' },
    { state: 'VA', label: 'Giles County', value: 'Giles County' },
    { state: 'VA', label: 'Gloucester County', value: 'Gloucester County' },
    { state: 'VA', label: 'Goochland County', value: 'Goochland County' },
    { state: 'VA', label: 'Grayson County', value: 'Grayson County' },
    { state: 'VA', label: 'Greene County', value: 'Greene County' },
    { state: 'VA', label: 'Greensville County', value: 'Greensville County' },
    { state: 'VA', label: 'Halifax County', value: 'Halifax County' },
    { state: 'VA', label: 'Hampton city', value: 'Hampton city' },
    { state: 'VA', label: 'Hanover County', value: 'Hanover County' },
    { state: 'VA', label: 'Harrisonburg city', value: 'Harrisonburg city' },
    { state: 'VA', label: 'Henrico County', value: 'Henrico County' },
    { state: 'VA', label: 'Henry County', value: 'Henry County' },
    { state: 'VA', label: 'Highland County', value: 'Highland County' },
    { state: 'VA', label: 'Hopewell city', value: 'Hopewell city' },
    { state: 'VA', label: 'Isle of Wight County', value: 'Isle of Wight County' },
    { state: 'VA', label: 'James City County', value: 'James City County' },
    { state: 'VA', label: 'King and Queen County', value: 'King and Queen County' },
    { state: 'VA', label: 'King George County', value: 'King George County' },
    { state: 'VA', label: 'King William County', value: 'King William County' },
    { state: 'VA', label: 'Lancaster County', value: 'Lancaster County' },
    { state: 'VA', label: 'Lee County', value: 'Lee County' },
    { state: 'VA', label: 'Lexington city', value: 'Lexington city' },
    { state: 'VA', label: 'Loudoun County', value: 'Loudoun County' },
    { state: 'VA', label: 'Louisa County', value: 'Louisa County' },
    { state: 'VA', label: 'Lunenburg County', value: 'Lunenburg County' },
    { state: 'VA', label: 'Lynchburg city', value: 'Lynchburg city' },
    { state: 'VA', label: 'Madison County', value: 'Madison County' },
    { state: 'VA', label: 'Manassas city', value: 'Manassas city' },
    { state: 'VA', label: 'Manassas Park city', value: 'Manassas Park city' },
    { state: 'VA', label: 'Martinsville city', value: 'Martinsville city' },
    { state: 'VA', label: 'Mathews County', value: 'Mathews County' },
    { state: 'VA', label: 'Mecklenburg County', value: 'Mecklenburg County' },
    { state: 'VA', label: 'Middlesex County', value: 'Middlesex County' },
    { state: 'VA', label: 'Montgomery County', value: 'Montgomery County' },
    { state: 'VA', label: 'Nelson County', value: 'Nelson County' },
    { state: 'VA', label: 'New Kent County', value: 'New Kent County' },
    { state: 'VA', label: 'Newport News city', value: 'Newport News city' },
    { state: 'VA', label: 'Norfolk city', value: 'Norfolk city' },
    { state: 'VA', label: 'Northampton County', value: 'Northampton County' },
    { state: 'VA', label: 'Northumberland County', value: 'Northumberland County' },
    { state: 'VA', label: 'Norton city', value: 'Norton city' },
    { state: 'VA', label: 'Nottoway County', value: 'Nottoway County' },
    { state: 'VA', label: 'Orange County', value: 'Orange County' },
    { state: 'VA', label: 'Page County', value: 'Page County' },
    { state: 'VA', label: 'Patrick County', value: 'Patrick County' },
    { state: 'VA', label: 'Petersburg city', value: 'Petersburg city' },
    { state: 'VA', label: 'Pittsylvania County', value: 'Pittsylvania County' },
    { state: 'VA', label: 'Poquoson city', value: 'Poquoson city' },
    { state: 'VA', label: 'Portsmouth city', value: 'Portsmouth city' },
    { state: 'VA', label: 'Powhatan County', value: 'Powhatan County' },
    { state: 'VA', label: 'Prince Edward County', value: 'Prince Edward County' },
    { state: 'VA', label: 'Prince George County', value: 'Prince George County' },
    { state: 'VA', label: 'Prince William County', value: 'Prince William County' },
    { state: 'VA', label: 'Pulaski County', value: 'Pulaski County' },
    { state: 'VA', label: 'Radford city', value: 'Radford city' },
    { state: 'VA', label: 'Rappahannock County', value: 'Rappahannock County' },
    { state: 'VA', label: 'Richmond city', value: 'Richmond city' },
    { state: 'VA', label: 'Richmond County', value: 'Richmond County' },
    { state: 'VA', label: 'Roanoke city', value: 'Roanoke city' },
    { state: 'VA', label: 'Roanoke County', value: 'Roanoke County' },
    { state: 'VA', label: 'Rockbridge County', value: 'Rockbridge County' },
    { state: 'VA', label: 'Rockingham County', value: 'Rockingham County' },
    { state: 'VA', label: 'Russell County', value: 'Russell County' },
    { state: 'VA', label: 'Salem city', value: 'Salem city' },
    { state: 'VA', label: 'Scott County', value: 'Scott County' },
    { state: 'VA', label: 'Shenandoah County', value: 'Shenandoah County' },
    { state: 'VA', label: 'Smyth County', value: 'Smyth County' },
    { state: 'VA', label: 'Southampton County', value: 'Southampton County' },
    { state: 'VA', label: 'Spotsylvania County', value: 'Spotsylvania County' },
    { state: 'VA', label: 'Stafford County', value: 'Stafford County' },
    { state: 'VA', label: 'Staunton city', value: 'Staunton city' },
    { state: 'VA', label: 'Suffolk city', value: 'Suffolk city' },
    { state: 'VA', label: 'Surry County', value: 'Surry County' },
    { state: 'VA', label: 'Sussex County', value: 'Sussex County' },
    { state: 'VA', label: 'Tazewell County', value: 'Tazewell County' },
    { state: 'VA', label: 'Virginia Beach city', value: 'Virginia Beach city' },
    { state: 'VA', label: 'Warren County', value: 'Warren County' },
    { state: 'VA', label: 'Washington County', value: 'Washington County' },
    { state: 'VA', label: 'Waynesboro city', value: 'Waynesboro city' },
    { state: 'VA', label: 'Westmoreland County', value: 'Westmoreland County' },
    { state: 'VA', label: 'Williamsburg city', value: 'Williamsburg city' },
    { state: 'VA', label: 'Winchester city', value: 'Winchester city' },
    { state: 'VA', label: 'Wise County', value: 'Wise County' },
    { state: 'VA', label: 'Wythe County', value: 'Wythe County' },
    { state: 'VA', label: 'York County', value: 'York County' },
    { state: 'VT', label: 'Addison County', value: 'Addison County' },
    { state: 'VT', label: 'Bennington County', value: 'Bennington County' },
    { state: 'VT', label: 'Caledonia County', value: 'Caledonia County' },
    { state: 'VT', label: 'Chittenden County', value: 'Chittenden County' },
    { state: 'VT', label: 'Essex County', value: 'Essex County' },
    { state: 'VT', label: 'Franklin County', value: 'Franklin County' },
    { state: 'VT', label: 'Grand Isle County', value: 'Grand Isle County' },
    { state: 'VT', label: 'Lamoille County', value: 'Lamoille County' },
    { state: 'VT', label: 'Orange County', value: 'Orange County' },
    { state: 'VT', label: 'Orleans County', value: 'Orleans County' },
    { state: 'VT', label: 'Rutland County', value: 'Rutland County' },
    { state: 'VT', label: 'Washington County', value: 'Washington County' },
    { state: 'VT', label: 'Windham County', value: 'Windham County' },
    { state: 'VT', label: 'Windsor County', value: 'Windsor County' },
    { state: 'WA', label: 'Adams County', value: 'Adams County' },
    { state: 'WA', label: 'Asotin County', value: 'Asotin County' },
    { state: 'WA', label: 'Benton County', value: 'Benton County' },
    { state: 'WA', label: 'Chelan County', value: 'Chelan County' },
    { state: 'WA', label: 'Clallam County', value: 'Clallam County' },
    { state: 'WA', label: 'Clark County', value: 'Clark County' },
    { state: 'WA', label: 'Columbia County', value: 'Columbia County' },
    { state: 'WA', label: 'Cowlitz County', value: 'Cowlitz County' },
    { state: 'WA', label: 'Douglas County', value: 'Douglas County' },
    { state: 'WA', label: 'Ferry County', value: 'Ferry County' },
    { state: 'WA', label: 'Franklin County', value: 'Franklin County' },
    { state: 'WA', label: 'Garfield County', value: 'Garfield County' },
    { state: 'WA', label: 'Grant County', value: 'Grant County' },
    { state: 'WA', label: 'Grays Harbor County', value: 'Grays Harbor County' },
    { state: 'WA', label: 'Island County', value: 'Island County' },
    { state: 'WA', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'WA', label: 'King County', value: 'King County' },
    { state: 'WA', label: 'Kitsap County', value: 'Kitsap County' },
    { state: 'WA', label: 'Kittitas County', value: 'Kittitas County' },
    { state: 'WA', label: 'Klickitat County', value: 'Klickitat County' },
    { state: 'WA', label: 'Lewis County', value: 'Lewis County' },
    { state: 'WA', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'WA', label: 'Mason County', value: 'Mason County' },
    { state: 'WA', label: 'Okanogan County', value: 'Okanogan County' },
    { state: 'WA', label: 'Pacific County', value: 'Pacific County' },
    { state: 'WA', label: 'Pend Oreille County', value: 'Pend Oreille County' },
    { state: 'WA', label: 'Pierce County', value: 'Pierce County' },
    { state: 'WA', label: 'San Juan County', value: 'San Juan County' },
    { state: 'WA', label: 'Skagit County', value: 'Skagit County' },
    { state: 'WA', label: 'Skamania County', value: 'Skamania County' },
    { state: 'WA', label: 'Snohomish County', value: 'Snohomish County' },
    { state: 'WA', label: 'Spokane County', value: 'Spokane County' },
    { state: 'WA', label: 'Stevens County', value: 'Stevens County' },
    { state: 'WA', label: 'Thurston County', value: 'Thurston County' },
    { state: 'WA', label: 'Wahkiakum County', value: 'Wahkiakum County' },
    { state: 'WA', label: 'Walla Walla County', value: 'Walla Walla County' },
    { state: 'WA', label: 'Whatcom County', value: 'Whatcom County' },
    { state: 'WA', label: 'Whitman County', value: 'Whitman County' },
    { state: 'WA', label: 'Yakima County', value: 'Yakima County' },
    { state: 'WI', label: 'Adams County', value: 'Adams County' },
    { state: 'WI', label: 'Ashland County', value: 'Ashland County' },
    { state: 'WI', label: 'Barron County', value: 'Barron County' },
    { state: 'WI', label: 'Bayfield County', value: 'Bayfield County' },
    { state: 'WI', label: 'Brown County', value: 'Brown County' },
    { state: 'WI', label: 'Buffalo County', value: 'Buffalo County' },
    { state: 'WI', label: 'Burnett County', value: 'Burnett County' },
    { state: 'WI', label: 'Calumet County', value: 'Calumet County' },
    { state: 'WI', label: 'Chippewa County', value: 'Chippewa County' },
    { state: 'WI', label: 'Clark County', value: 'Clark County' },
    { state: 'WI', label: 'Columbia County', value: 'Columbia County' },
    { state: 'WI', label: 'Crawford County', value: 'Crawford County' },
    { state: 'WI', label: 'Dane County', value: 'Dane County' },
    { state: 'WI', label: 'Dodge County', value: 'Dodge County' },
    { state: 'WI', label: 'Door County', value: 'Door County' },
    { state: 'WI', label: 'Douglas County', value: 'Douglas County' },
    { state: 'WI', label: 'Dunn County', value: 'Dunn County' },
    { state: 'WI', label: 'Eau Claire County', value: 'Eau Claire County' },
    { state: 'WI', label: 'Florence County', value: 'Florence County' },
    { state: 'WI', label: 'Fond du Lac County', value: 'Fond du Lac County' },
    { state: 'WI', label: 'Forest County', value: 'Forest County' },
    { state: 'WI', label: 'Grant County', value: 'Grant County' },
    { state: 'WI', label: 'Green County', value: 'Green County' },
    { state: 'WI', label: 'Green Lake County', value: 'Green Lake County' },
    { state: 'WI', label: 'Iowa County', value: 'Iowa County' },
    { state: 'WI', label: 'Iron County', value: 'Iron County' },
    { state: 'WI', label: 'Jackson County', value: 'Jackson County' },
    { state: 'WI', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'WI', label: 'Juneau County', value: 'Juneau County' },
    { state: 'WI', label: 'Kenosha County', value: 'Kenosha County' },
    { state: 'WI', label: 'Kewaunee County', value: 'Kewaunee County' },
    { state: 'WI', label: 'La Crosse County', value: 'La Crosse County' },
    { state: 'WI', label: 'Lafayette County', value: 'Lafayette County' },
    { state: 'WI', label: 'Langlade County', value: 'Langlade County' },
    { state: 'WI', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'WI', label: 'Manitowoc County', value: 'Manitowoc County' },
    { state: 'WI', label: 'Marathon County', value: 'Marathon County' },
    { state: 'WI', label: 'Marinette County', value: 'Marinette County' },
    { state: 'WI', label: 'Marquette County', value: 'Marquette County' },
    { state: 'WI', label: 'Menominee County', value: 'Menominee County' },
    { state: 'WI', label: 'Milwaukee County', value: 'Milwaukee County' },
    { state: 'WI', label: 'Monroe County', value: 'Monroe County' },
    { state: 'WI', label: 'Oconto County', value: 'Oconto County' },
    { state: 'WI', label: 'Oneida County', value: 'Oneida County' },
    { state: 'WI', label: 'Outagamie County', value: 'Outagamie County' },
    { state: 'WI', label: 'Ozaukee County', value: 'Ozaukee County' },
    { state: 'WI', label: 'Pepin County', value: 'Pepin County' },
    { state: 'WI', label: 'Pierce County', value: 'Pierce County' },
    { state: 'WI', label: 'Polk County', value: 'Polk County' },
    { state: 'WI', label: 'Portage County', value: 'Portage County' },
    { state: 'WI', label: 'Price County', value: 'Price County' },
    { state: 'WI', label: 'Racine County', value: 'Racine County' },
    { state: 'WI', label: 'Richland County', value: 'Richland County' },
    { state: 'WI', label: 'Rock County', value: 'Rock County' },
    { state: 'WI', label: 'Rusk County', value: 'Rusk County' },
    { state: 'WI', label: 'Sauk County', value: 'Sauk County' },
    { state: 'WI', label: 'Sawyer County', value: 'Sawyer County' },
    { state: 'WI', label: 'Shawano County', value: 'Shawano County' },
    { state: 'WI', label: 'Sheboygan County', value: 'Sheboygan County' },
    { state: 'WI', label: 'St. Croix County', value: 'St. Croix County' },
    { state: 'WI', label: 'Taylor County', value: 'Taylor County' },
    { state: 'WI', label: 'Trempealeau County', value: 'Trempealeau County' },
    { state: 'WI', label: 'Vernon County', value: 'Vernon County' },
    { state: 'WI', label: 'Vilas County', value: 'Vilas County' },
    { state: 'WI', label: 'Walworth County', value: 'Walworth County' },
    { state: 'WI', label: 'Washburn County', value: 'Washburn County' },
    { state: 'WI', label: 'Washington County', value: 'Washington County' },
    { state: 'WI', label: 'Waukesha County', value: 'Waukesha County' },
    { state: 'WI', label: 'Waupaca County', value: 'Waupaca County' },
    { state: 'WI', label: 'Waushara County', value: 'Waushara County' },
    { state: 'WI', label: 'Winnebago County', value: 'Winnebago County' },
    { state: 'WI', label: 'Wood County', value: 'Wood County' },
    { state: 'WV', label: 'Barbour County', value: 'Barbour County' },
    { state: 'WV', label: 'Berkeley County', value: 'Berkeley County' },
    { state: 'WV', label: 'Boone County', value: 'Boone County' },
    { state: 'WV', label: 'Braxton County', value: 'Braxton County' },
    { state: 'WV', label: 'Brooke County', value: 'Brooke County' },
    { state: 'WV', label: 'Cabell County', value: 'Cabell County' },
    { state: 'WV', label: 'Calhoun County', value: 'Calhoun County' },
    { state: 'WV', label: 'Clay County', value: 'Clay County' },
    { state: 'WV', label: 'Doddridge County', value: 'Doddridge County' },
    { state: 'WV', label: 'Fayette County', value: 'Fayette County' },
    { state: 'WV', label: 'Gilmer County', value: 'Gilmer County' },
    { state: 'WV', label: 'Grant County', value: 'Grant County' },
    { state: 'WV', label: 'Greenbrier County', value: 'Greenbrier County' },
    { state: 'WV', label: 'Hampshire County', value: 'Hampshire County' },
    { state: 'WV', label: 'Hancock County', value: 'Hancock County' },
    { state: 'WV', label: 'Hardy County', value: 'Hardy County' },
    { state: 'WV', label: 'Harrison County', value: 'Harrison County' },
    { state: 'WV', label: 'Jackson County', value: 'Jackson County' },
    { state: 'WV', label: 'Jefferson County', value: 'Jefferson County' },
    { state: 'WV', label: 'Kanawha County', value: 'Kanawha County' },
    { state: 'WV', label: 'Lewis County', value: 'Lewis County' },
    { state: 'WV', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'WV', label: 'Logan County', value: 'Logan County' },
    { state: 'WV', label: 'Marion County', value: 'Marion County' },
    { state: 'WV', label: 'Marshall County', value: 'Marshall County' },
    { state: 'WV', label: 'Mason County', value: 'Mason County' },
    { state: 'WV', label: 'McDowell County', value: 'McDowell County' },
    { state: 'WV', label: 'Mercer County', value: 'Mercer County' },
    { state: 'WV', label: 'Mineral County', value: 'Mineral County' },
    { state: 'WV', label: 'Mingo County', value: 'Mingo County' },
    { state: 'WV', label: 'Monongalia County', value: 'Monongalia County' },
    { state: 'WV', label: 'Monroe County', value: 'Monroe County' },
    { state: 'WV', label: 'Morgan County', value: 'Morgan County' },
    { state: 'WV', label: 'Nicholas County', value: 'Nicholas County' },
    { state: 'WV', label: 'Ohio County', value: 'Ohio County' },
    { state: 'WV', label: 'Pendleton County', value: 'Pendleton County' },
    { state: 'WV', label: 'Pleasants County', value: 'Pleasants County' },
    { state: 'WV', label: 'Pocahontas County', value: 'Pocahontas County' },
    { state: 'WV', label: 'Preston County', value: 'Preston County' },
    { state: 'WV', label: 'Putnam County', value: 'Putnam County' },
    { state: 'WV', label: 'Raleigh County', value: 'Raleigh County' },
    { state: 'WV', label: 'Randolph County', value: 'Randolph County' },
    { state: 'WV', label: 'Ritchie County', value: 'Ritchie County' },
    { state: 'WV', label: 'Roane County', value: 'Roane County' },
    { state: 'WV', label: 'Summers County', value: 'Summers County' },
    { state: 'WV', label: 'Taylor County', value: 'Taylor County' },
    { state: 'WV', label: 'Tucker County', value: 'Tucker County' },
    { state: 'WV', label: 'Tyler County', value: 'Tyler County' },
    { state: 'WV', label: 'Upshur County', value: 'Upshur County' },
    { state: 'WV', label: 'Wayne County', value: 'Wayne County' },
    { state: 'WV', label: 'Webster County', value: 'Webster County' },
    { state: 'WV', label: 'Wetzel County', value: 'Wetzel County' },
    { state: 'WV', label: 'Wirt County', value: 'Wirt County' },
    { state: 'WV', label: 'Wood County', value: 'Wood County' },
    { state: 'WV', label: 'Wyoming County', value: 'Wyoming County' },
    { state: 'WY', label: 'Albany County', value: 'Albany County' },
    { state: 'WY', label: 'Big Horn County', value: 'Big Horn County' },
    { state: 'WY', label: 'Campbell County', value: 'Campbell County' },
    { state: 'WY', label: 'Carbon County', value: 'Carbon County' },
    { state: 'WY', label: 'Converse County', value: 'Converse County' },
    { state: 'WY', label: 'Crook County', value: 'Crook County' },
    { state: 'WY', label: 'Fremont County', value: 'Fremont County' },
    { state: 'WY', label: 'Goshen County', value: 'Goshen County' },
    { state: 'WY', label: 'Hot Springs County', value: 'Hot Springs County' },
    { state: 'WY', label: 'Johnson County', value: 'Johnson County' },
    { state: 'WY', label: 'Laramie County', value: 'Laramie County' },
    { state: 'WY', label: 'Lincoln County', value: 'Lincoln County' },
    { state: 'WY', label: 'Natrona County', value: 'Natrona County' },
    { state: 'WY', label: 'Niobrara County', value: 'Niobrara County' },
    { state: 'WY', label: 'Park County', value: 'Park County' },
    { state: 'WY', label: 'Platte County', value: 'Platte County' },
    { state: 'WY', label: 'Sheridan County', value: 'Sheridan County' },
    { state: 'WY', label: 'Sublette County', value: 'Sublette County' },
    { state: 'WY', label: 'Sweetwater County', value: 'Sweetwater County' },
    { state: 'WY', label: 'Teton County', value: 'Teton County' },
    { state: 'WY', label: 'Uinta County', value: 'Uinta County' },
    { state: 'WY', label: 'Washakie County', value: 'Washakie County' },
    { state: 'WY', label: 'Weston County', value: 'Weston County' },
];
