import React from 'react';

type Props = {
    checked: boolean;
    setChecked: (value: boolean) => void;
};

const Checkbox = ({ checked, setChecked }: Props) => {
    return (
        <div className="cursor-pointer pl-3 pr-5 flex items-center">
            {checked ? (
                <span
                    className="material-icons bg-primary-500 rounded-full text-white"
                    style={{ fontSize: 20 }}
                    onClick={() => setChecked(!checked)}
                >
                    check
                </span>
            ) : (
                <div
                    onClick={() => setChecked(!checked)}
                    className={
                        'inline h-5 w-5 bg-gray-50 rounded-3xl hover:bg-gray-100  border border-gray-300 p-px'
                    }
                />
            )}
        </div>
    );
};

export default Checkbox;
