import { enumSections, fieldTypes } from '../../utils/enums';
import Button from '../basic/Button';
import moment from 'moment';
import InputGroup from './InputGroup';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { changePassword } from '../../api/login';
import AdminMenu from '../basic/AdminMenu';
import { useAuthState } from '../../context/auth';
import NavBar from '../basic/NavBar';

const Settings: React.FC<{}> = ({}) => {
    const { u_email } = useAuthState();
    const [password, setPassword] = useState<string>('');
    const [new_password, setNewPassword] = useState<string>('');
    const [isPasswordChanged, setPasswordChanged] = useState<boolean>(false);
    const [error, setErrors] = useState<{ [key: string]: any } | string>();

    const {
        mutate: changeUserPassword,
        isLoading,
        isSuccess,
        reset,
    } = useMutation(changePassword, {
        onSuccess: () => {
            setPasswordChanged(true);
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    useEffect(() => {
        const saveTimeout = setTimeout(() => {
            if (isSuccess) {
                setPasswordChanged(false);
                setPassword('');
                setNewPassword('');
                reset();
            }
        }, 3000);

        return () => clearTimeout(saveTimeout);
    }, [isSuccess]);

    return (
        <div className="w-full h-screen flex">
            <NavBar />
            <div className="w-full flex h-screen flex-col items-center p-6">
                <div className="w-full flex flex-col max-w-6xl max-h-full">
                    <div className="font-title text-2xl font-semibold text-gray-400 mt-16">
                        Settings
                    </div>
                    <div className="relative rounded-md w-96 mt-8 shadow-lg">
                        <div className="font-title text-gray-400 m-2">Change Password</div>

                        <div>
                            <InputGroup
                                id="password"
                                label="Current Password"
                                placeholder=""
                                inputType="password"
                                value={password}
                                setValue={(value) => setPassword(value)}
                                type={fieldTypes.TEXT_INPUT}
                            />
                        </div>
                        {typeof error === 'object' && (
                            <div className="w-full bg-red-100 text-red-600 font-bold text-xs px-3">
                                {error?.u_password?.[0]}
                            </div>
                        )}
                        <div>
                            <InputGroup
                                id="new_password"
                                label="New Password"
                                placeholder=""
                                inputType="password"
                                value={new_password}
                                setValue={(value) => setNewPassword(value)}
                                type={fieldTypes.TEXT_INPUT}
                            />
                        </div>
                        {typeof error === 'object' && (
                            <div className="w-full bg-red-100 text-red-600 font-bold text-xs px-3">
                                {error?.new_password?.[0]}
                            </div>
                        )}
                        <Button
                            isLoading={isLoading}
                            onClick={() =>
                                changeUserPassword({
                                    u_email: u_email!,
                                    u_password: password,
                                    new_password,
                                })
                            }
                            className="text-center w-full mt-2"
                        >
                            {'CHANGE PASSWORD'}
                        </Button>

                        {isPasswordChanged && (
                            <div className="absolute opacity-80 flex items-center justify-center top-0 bottom-0 left-0 right-0 bg-primary-100">
                                <div>Password Changed Successfully</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
