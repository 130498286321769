import { useState } from 'react';
import NavBar from '../basic/NavBar';
import Menu from '../basic/TaxPrepMenu';
import ClientFormSection from './ClientFormSection';
import useClientData from '../../api/tax-prep/useClientData';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { useAuthState } from '../../context/auth';

const TaxPrepClientForm: React.FC<{}> = () => {
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const { irs_account_id } = useAuthState();
    const { year } = useTaxPrepStructure();
    
    useClientData(irs_account_id!, year);

    return (
        <>
            <NavBar toggleMenu={() => setOpenMenu(!openMenu)} />

            <div className="flex flex-col h-screen w-full">
                <div className="flex-grow flex flex-col w-full h-screen ">
                    {/* <TopNavigator /> */}
                    <div className="w-full flex flex-grow relative">
                        <Menu />

                        <ClientFormSection />

                        {openMenu && (
                            <div className="z-40 absolute h-full w-screen right-0 flex flex-col">
                                <div
                                    onClick={() => setOpenMenu(false)}
                                    className="absolute opacity-40 bg-black left-0 right-0 top-0 bottom-0 "
                                ></div>
                                <Menu close={() => setOpenMenu(false)} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaxPrepClientForm;
