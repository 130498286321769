import React, { useEffect, useMemo, useState } from 'react';
import {
    FormStateInterface,
    PageInputInterface,
    interface_field_textInput,
    interface_terminal_message,
} from '../../utils/interfaces';
import Button from '../basic/Button';
import ParentGroup from '../client/ParentGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLightHouse } from '../../utils/helpers';
import { useFormDispatch, useFormState } from '../../context/form';
import { useMutation, useQueryClient } from 'react-query';

import classNames from 'classnames';
import { enumPageTypes, enumSections, fieldTypes } from '../../utils/enums';
import InputGroup from '../client/InputGroup';
import Modal from '../basic/Modal';
import CommentThread from '../basic/CommentThread';
import InlineUI from '../document/InlineUI';
import { build } from '../../config';
import check_img from '../../assets/check2.png';
import useTaxPrepClientData from '../taxprep/useTaxPrepClientData';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { useAuthState } from '../../context/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';
import { pageStateStore } from '../../stateManager/taxPreparerSlice';
import { useClientFillupPercentage } from '../taxprep/ClientFillupPercentage';
import useTaxPrepEvalData from '../taxprep/useTaxPrepEvalData';

/**
 * UI Component for Text Input and Text Area
 **/

type TerminalGroupProps = interface_terminal_message & {
    className?: string;
};

type CommentType = {
    id: string;
    email_address: string;
    name: string;
    text: string;
    createdAt: string;
};

const CompletionModal: React.FC<{ close: () => void }> = ({ close }) => {
    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ maxWidth: 500, width: '100%' }}>
            <div
                className="bg-white w-full max-w-3xl flex flex-col items-center justify-center rounded"
                style={{ maxHeight: '95%', minHeight: '40%' }}
            >
                <div className="p-2 border-2 border-primary-500 h-16 w-16 rounded-full">
                    <img src={check_img} className="h-full w-full" />
                </div>
                <div className="pt-5 pb-1 text-gray-700 font-title">{`Form data has been successfully saved`}</div>

                <Button onClick={close} className="text-center w-full mt-8">
                    {'OKAY'}
                </Button>
            </div>
        </div>
    );
};

const AddComments: React.FC<{ close: () => void }> = ({ close }) => {
    const [comment, setComment] = useState<string>('');
    const dispatch = useFormDispatch();
    const { businessId, currentPage } = useLightHouse();
    const { meta_data, email_address, first_name, last_business_name } = useFormState();

    const saved_comments = businessId
        ? meta_data?.form_status[businessId]
            ? meta_data?.form_status[businessId][currentPage.address + '_comments']
            : undefined
        : meta_data?.form_status
        ? meta_data?.form_status[currentPage.address + '_comments']
        : undefined;

    const upsertComments = (comments: Array<CommentType>) => {
        if (businessId) {
            dispatch({
                type: 'UPDATE_COMMENTS_BUSINESS',
                payload: { businessId, data: { [currentPage.address + '_comments']: comments } },
            });
        } else {
            dispatch({
                type: 'UPDATE_COMMENTS',
                payload: { data: { [currentPage.address + '_comments']: comments } },
            });
        }
    };

    const addComment = () => {
        if ([undefined, null, ''].includes(comment)) {
            return;
        }

        const newComment: CommentType = {
            id: meta_data.id,
            email_address: email_address,
            name: first_name + ' ' + last_business_name,
            text: comment,
            createdAt: new Date().toISOString(),
        };

        if (saved_comments) {
            upsertComments([...saved_comments, newComment]);
        } else upsertComments([newComment]);

        close();
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="flex-grow overflow-y-auto">
                <CommentThread comments={saved_comments} />
            </div>

            <InputGroup
                area={true}
                id="comment"
                label="Comment"
                placeholder=""
                value={comment}
                setValue={(value: any) => setComment(value)}
                type={fieldTypes.TEXT_INPUT}
                className="w-full mt-1"
            />
            <Button onClick={addComment} className="text-center w-full mt-8">
                {'ADD COMMENT'}
            </Button>
        </div>
    );
};

const TerminalGroup: React.FC<TerminalGroupProps> = (props) => {
    const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
    const pageState = useSelector((state: RootState) => state.taxPrep.pageState);
    const [loadingRoute, setLoadingRoute] = useState<string>('');
    const { irs_account_id } = useAuthState();
    const [completeFlag, setCompleteFlag] = useState<boolean>(false);
    const { updateClient, setError, setPageStateValue } = useTaxPrepClientData();
    const { currentPage: page, address, year, currentPage, pages } = useTaxPrepStructure();
    const { meta } = useTaxPrepEvalData();

    const dispatch = useDispatch();

    // const { completionPercentage } = useClientFillupPercentage({
    //     pages,
    //     meta: meta?.page_json,
    //     currentYear: year,
    // });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const isWelcome = false;
    const navigate = useNavigate();

    const { className } = props;
    const univarsalFormState = useFormState();

    const saved_comments: any[] = [];

    const [addComment, setAddComment] = useState(false);

    const proceed = async () => {
        setLoadingRoute('NEXT');
        try {
            await updateClient.mutateAsync();
            if (page?.isLastPage) {
                setShowCompletionModal(true);
            } else {
                navigate(page?.nextPage?.baseLink!);
            }
            setError(year, '');
        } catch (error: any) {
            setError(year, error.response?.data?.message);
        }
    };

    function upsertCompleteStatus() {
        setCompleteFlag(!completeFlag);
        if (currentPage) {
            // setPageStateValue(
            //     { path: currentPage.address, value: !completeFlag },
            //     completionPercentage,
            // );
            setPageStateValue({ path: currentPage.address, value: !completeFlag });
        }
    }
    const currentPageAddress = currentPage?.address;

    return (
        <>
            {props.noAction && (
                <div className=" mt-4 mb-2 bg-gray-100 flex px-4 py-6 text-gray-600 border border-gray-300 col-span-2 rounded items-center justify-center">
                    <span className="material-symbols-outlined mr-2 text-gray-600">info</span>{' '}
                    <div>No More Action is required on this page, kindly move to the next page</div>
                </div>
            )}
            <div className="col-span-2 h-1 bg-gray-100 mt-8"></div>

            {!props.noDocument && (
                <ParentGroup className={classNames('col-span-2 mt-4', className)}>
                    <InlineUI />
                </ParentGroup>
            )}

            <ParentGroup key={currentPage?.address} className={classNames('col-span-2', className)}>
                <div
                    key={address}
                    className={classNames('w-full flex flex-col bg-gray-50 justify-center', {
                        'mt-4 mb-52': !isWelcome,
                        'pt-4': isWelcome,
                    })}
                >
                    {!isWelcome && (
                        <>
                            <div
                                onClick={upsertCompleteStatus}
                                className={classNames(
                                    'flex w-full cursor-pointer items-center py-3 px-3 font-medium',
                                    {
                                        'bg-primary-500 text-white':
                                            meta?.page_json?.[currentPageAddress!] === true,
                                        'bg-gray-200':
                                            meta?.page_json?.[currentPageAddress!] !== true,
                                    },
                                )}
                            >
                                {console.log('page metadata-', {
                                    meta: meta?.page_json,
                                    currentPageAddress,
                                })}
                                {meta?.page_json?.[currentPageAddress!] === true ? (
                                    <img
                                        src={build.checkMarkLogo}
                                        className={'h-5 w-5 bg-white rounded-3xl mr-2 p-px '}
                                    />
                                ) : (
                                    <div
                                        className={
                                            'h-5 w-5 bg-white rounded-3xl mr-2 border border-gray-300 p-px'
                                        }
                                    />
                                )}
                                <div>Mark section as completed</div>
                            </div>
                            <div className="flex-grow overflow-y-auto">
                                <CommentThread comments={saved_comments} />
                            </div>
                        </>
                    )}
                    <div className="flex justify-end flex-grow items-center  p-3">
                        <div
                            onClick={() => setAddComment(true)}
                            className="font-bold text-primary-500 font-title flex-grow cursor-pointer hover:text-primary-700"
                        >
                            {/* {!isWelcome && `ADD COMMENT`} */}
                        </div>
                        <div className="flex w-80">
                            <Button
                                secondary
                                disabled={!page?.prevPage}
                                isLoading={loadingRoute === 'PREVIOUS' && updateClient.isLoading}
                                onClick={async () => {
                                    setLoadingRoute('PREVIOUS');
                                    await updateClient.mutateAsync();
                                    navigate(page?.prevPage?.baseLink!);
                                }}
                                className="w-1/2 mr-1 text-center bg-white"
                            >
                                {'PREVIOUS'}
                            </Button>
                            <Button
                                className="w-1/2 ml-1 text-center"
                                disabled={false}
                                isLoading={loadingRoute === 'NEXT' && updateClient.isLoading}
                                onClick={proceed}
                            >
                                {page?.isLastPage ? 'COMPLETE' : 'NEXT'}
                            </Button>
                        </div>
                    </div>
                    {Object.keys(univarsalFormState._errors).length > 0 && (
                        <div className="w-full text-xs font-bold bg-red-100 text-red-600 px-3 py-1">
                            {`Please fix ${
                                Object.keys(univarsalFormState._errors).length
                            } error(s) to proceed`}
                        </div>
                    )}
                </div>
            </ParentGroup>

            <Modal close={() => setAddComment(false)} isOpen={addComment}>
                <AddComments close={() => setAddComment(false)} />
            </Modal>

            <Modal close={() => setShowCompletionModal(false)} isOpen={showCompletionModal}>
                <CompletionModal close={() => setShowCompletionModal(false)} />
            </Modal>
        </>
    );
};

export default React.memo(TerminalGroup);
