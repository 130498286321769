import classNames from 'classnames';
import { Link, useLocation, useParams } from 'react-router-dom';
import { build } from '../../config';
import { useFormState } from '../../context/form';
import { enumSections } from '../../utils/enums';
import { PageInterface, SectionInterface } from '../../utils/interfaces';
import useTaxPrepStructure, { sectionsDefault } from '../../resource/form_structures_taxprep';
import SectionSelection from '../taxprep/SectionSelection';
import Modal from './Modal';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../context/auth';
import useTaxPrepClientData from '../taxprep/useTaxPrepClientData';
import { useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';

const LineSection: React.FC<{ isSelected: boolean; isCurrent: boolean; index: number | null }> = ({
    isSelected,
    isCurrent,
    index,
}) => {
    return (
        <div className="flex items-center justify-center relative h-full w-12">
            {isSelected ? (
                <img
                    src={build.checkMarkLogo}
                    className={classNames('h-5 w-5 z-20 bg-white rounded-3xl   ')}
                    style={{ padding: 1 }}
                />
            ) : (
                <div
                    className={classNames(
                        'h-5 w-5 rounded-2xl  z-20 flex items-center justify-center',
                        {
                            'bg-primary-500': isSelected && !isCurrent,
                            'bg-gray-700 ': isCurrent,
                            'bg-gray-300': !isSelected,
                        },
                    )}
                >
                    {index !== null && (
                        <div className={'text-sm text-center font-semibold text-white'}>
                            {typeof index === 'number' ? index + 1 : index}
                        </div>
                    )}
                </div>
            )}
            {/* <div
                className={classNames('absolute w-1 top-0 h-1/2 z-10', {
                    'bg-gray-800': present || past,
                    'bg-gray-400': future || start,
                })}
                style={{ left: 22 }}
            />
            <div
                className={classNames('absolute w-1 top-1/2 h-1/2 z-10', {
                    'bg-gray-800': past,
                    'bg-gray-400': present || future || start,
                })}
                style={{ left: 22 }}
            /> */}

            {/* Section to render if current page is selected */}
            {isCurrent && <div className="absolute h-5 w-1/2 right-0 bg-gray-700 z-10" />}
        </div>
    );
};

const PageProducer: React.FC<{
    currentPage: PageInterface;
    index: number;
    link: string;
    isSelected: boolean;
    error?: any;
    isCurrent: boolean;
}> = ({ link, error, currentPage, index, isSelected, isCurrent }) => {
    return (
        <Link to={link} className="w-full">
            <div
                className={classNames('flex items-center ', {
                    'h-9 bg-gray-50': !isCurrent,
                    'h-12 bg-primary-500': isCurrent,
                    'bg-red-200 font-black': error?.keysArray?.includes(currentPage.address),
                    // 'text-gray-400': future || start,
                })}
            >
                <div
                    className={classNames('flex-grow text-right font-body text-sm', {
                        'font-semibold ': !isSelected && !isCurrent,
                        'font-semibold text-gray-300': isSelected && !isCurrent,
                        'text-gray-50': isCurrent,
                    })}
                >
                    {currentPage.name}
                </div>

                <LineSection {...{ index, isSelected, isCurrent }} />
            </div>
        </Link>
    );
};

const BusinessSection: React.FC<{
    key: number;
    business: any;
    pages: any[];
    error: any;
    meta?: any;
}> = ({ business, pages, error, meta }) => {
    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    const { address, businessId, businessGroupId } = useTaxPrepStructure();

    return (
        <>
            <Link to={pages[0]?.baseLink} className="w-full">
                <div
                    className={classNames('flex items-center bg-gray-50 h-9', {
                        'h-12 bg-gray-500 text-gray-50': business.id === businessId,
                    })}
                >
                    <div
                        className={classNames('flex-grow text-right font-body text-sm', {
                            // 'font-semibold': present,
                        })}
                    >
                        {business.name}
                    </div>

                    <LineSection
                        {...{
                            index: null,
                            isSelected: false,
                            isCurrent: businessId === business.id,
                        }}
                    />
                </div>
            </Link>

            {businessId === business.id &&
                pages.map((subSection, index) => {
                    const selectionStatus = meta?.page_json?.[subSection.address] === true;
                    return (
                        <PageProducer
                            error={error}
                            index={index}
                            currentPage={subSection}
                            link={`${subSection.baseLink}`}
                            isCurrent={
                                address + '.' + businessId + '.' + businessGroupId ===
                                subSection.address
                            }
                            isSelected={selectionStatus}
                        />
                    );
                })}
        </>
    );
};

const SectionComponent: React.FC<{
    key: number;
    section: SectionInterface;
    data: any[];
    error: any;
    meta: any;
}> = ({ key, section, error, data, meta }) => {
    const { currentPage: page, address } = useTaxPrepStructure();
    const isCurrent = page?.sectionId === section.id;

    const keysArray = Object.keys(error ? error : '');
    const dataArray = data?.map((obj: any) => obj.address);
    const haveCommonElements = keysArray.some((key) => dataArray.includes(key));
    const sectionLink =
        section?.id === enumSections.BUSINESS_INFORMATION
            ? data[0]?.[1]?.[0]?.baseLink
            : data[0]?.baseLink;

    const Section: React.FC = () => {
        return (
            <Link to={{ pathname: sectionLink }}>
                <div
                    className={classNames('flex items-center h-12 bg-white', {
                        'bg-gray-200 h-20 ': isCurrent,
                    })}
                >
                    <div
                        className={classNames('flex-grow text-right font-title text-sm', {
                            'font-medium': isCurrent,
                            'font-semibold ': isCurrent,
                        })}
                    >
                        {section.name}
                    </div>
                    <LineSection {...{ index: null, isSelected: false, isCurrent }} />
                </div>
            </Link>
        );
    };

    switch (section.id) {
        case enumSections.BUSINESS_INFORMATION: {
            return (
                <div key={key} className="w-full">
                    <Section />
                    {page?.sectionId === section.id &&
                        data.map(([b, pages]: any, index: number) => {
                            if (b.id === 'business_data') {
                                return <></>;
                            }

                            return (
                                <BusinessSection
                                    key={index}
                                    business={b}
                                    pages={pages}
                                    meta={meta}
                                    error={{ haveCommonElements, keysArray }}
                                />
                            );
                        })}
                </div>
            );
        }

        default:
            return (
                <div key={key} className="w-full">
                    <Section />
                    {page?.sectionId === section.id &&
                        data.map((subSection, index) => {
                            const selectionStatus = meta?.page_json?.[subSection.address] === true;
                            return (
                                <PageProducer
                                    error={{ haveCommonElements, keysArray }}
                                    link={`${subSection.baseLink}`}
                                    index={index}
                                    currentPage={subSection}
                                    isCurrent={address === subSection.address}
                                    isSelected={selectionStatus}
                                />
                            );
                        })}
                </div>
            );
    }
};

const Menu: React.FC<{ close?: () => void }> = ({ close }) => {
    const [cofirmEdit, setConfirmEdit] = useState(false);
    const { irs_account_id } = useAuthState();

    const { formState, meta } = useTaxPrepClientData();
    const sectionState: any = useSelector((state: RootState) => state.taxPrep.selectedSections);
    const error = useSelector((state: RootState) => state.taxPrep.errorState);
    useEffect(() => {
        if (sectionState?.some((section: any) => section.isActive === true)) {
            setConfirmEdit(false);
        } else {
            setConfirmEdit(true);
        }
    }, [sectionState]);

    const { pagesRaw, sections } = useTaxPrepStructure();

    const defaultStructure = sectionsDefault.map((section) => {
        return [section, []] as any;
    });

    console.log('defaultStructure meta', meta);

    const menuStructure = pagesRaw.reduce((structure, page) => {
        return structure.map((s) => {
            let [section, data] = s;

            if (section.id === page.sectionId) {
                if (section.id === enumSections.BUSINESS_INFORMATION) {
                    if (page.address === 'business_data') {
                        return [
                            section,
                            [...data, [{ id: `business_data`, name: 'Business Home' }, [page]]],
                        ];
                    }

                    const business = data.find(([b, _]: any) => b.id === page.businessId);

                    if (business) {
                        return [
                            section,
                            data.map(([b, pages]: any) => {
                                if (b.id === page.businessId) {
                                    return [b, [...pages, page]];
                                }

                                return [b, pages];
                            }),
                        ];
                    }

                    return [
                        section,
                        [
                            ...data,
                            [
                                {
                                    id: page.businessId,
                                    name: page.businessName,
                                },
                                [page],
                            ],
                        ],
                    ];
                }

                return [section, [...data, page]];
            }

            return s;
        });
    }, defaultStructure as typeof defaultStructure);

    return (
        <div
            className="relative py- flex flex-col flex-grow"
            style={{
                maxWidth: 300,
                minWidth: 300,
                backgroundImage: 'url(/menu_pattern.png)',
                backgroundSize: '400px 400px',
            }}
        >
            <div className="z-30 h-10 w-full flex justify-start items-center">
                {close && (
                    <span onClick={close} className="material-symbols-outlined text-black ml-9">
                        menu
                    </span>
                )}
            </div>
            {/* Background screen with opacity to manage visibility of the background */}
            <div className="absolute h-full w-full bg-gray-100 top-0 left-0 z-10 opacity-80"></div>

            {/* Menu Section cards */}
            <div className="flex flex-col mt-5 sticky top-20 z-20">
                {menuStructure.map(([section, data], index) => {
                    if (!data.length) {
                        return <></>;
                    }

                    return (
                        <SectionComponent
                            key={index}
                            error={formState?._errors}
                            section={section}
                            data={data}
                            meta={meta}
                        />
                    );
                })}
                {/* <div
                    className="cursor-pointer font-title  text-white mt-4 text-sm ml-5 flex items-center justify-center"
                    onClick={() => setConfirmEdit(true)}
                >
                    <span className=" rounded-full bg-primary-400 px-3 py-1">{`Edit Sections`}</span>
                </div> */}
            </div>
            {/* <Modal close={() => setConfirmEdit(false)} isOpen={!!cofirmEdit}>
                <EditSectionTaxPrep close={() => setConfirmEdit(false)} />
            </Modal> */}
        </div>
    );
};

export default Menu;
