import { apiTaxPrep } from '.';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';
import {
    formStateStore,
    metaStateStore,
    pageStateStore,
    sectionsStore,
    serverMetaStateStore,
    serverStateStore,
} from '../../stateManager/taxPreparerSlice';

// Custom hook for fetching and managing client data
const useClientData = (irs_account_id: string, year: string) => {
    // Redux hooks for dispatching actions and accessing state
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.taxPrep.formState);
    const metaState = useSelector((state: RootState) => state.taxPrep.metaState);
    const serverState = useSelector((state: RootState) => state.taxPrep.serverState);
    const serverMetaState = useSelector((state: RootState) => state.taxPrep.serverMetaState);
    const selectedSections = useSelector((state: RootState) => state.taxPrep.selectedSections);

    // Function to make API requests for client data
    const get = async (): Promise<any> => {
        // Fetch client data
        const { data } = await apiTaxPrep.get('/ClientDataDependent', {
            params: { year, irs_account_id },
        });

        // Fetch business data for the client
        const { data: businessData } = await apiTaxPrep.get('/clientWithBusiness', {
            params: { taxprep_year: year, irs_account_id },
        });

        // Return merged data and metadata
        return {
            data: { ...data.data, business_data: businessData.data },
            meta_data: data.meta_data,
        };
    };

    // Query to manage fetching and updating client data
    const queryReturn = useQuery({
        queryKey: ['get-client-data-', year, irs_account_id],
        queryFn: get,
        staleTime: Infinity,
        enabled: !!(year && irs_account_id),
        onSuccess: (data: any) => {
            // Update Redux state with fetched data
            setStateForYear(year, { ...data.data, meta_data: data.meta_data });
            setMetaStateForYear(year, { ...data.meta_data });
            setMetaServerStateForYear(year, { ...data.meta_data });
            // dispatch(metaStateStore({ state: data.meta_data }));
            const serverData = {
                ...(serverState || {}),
                [year]: {
                    ...((serverState || {})[year] || {}),
                    ...data.data,
                    meta_data: data?.meta_data,
                },
            };
            dispatch(
                pageStateStore({
                    state: data?.meta_data?.page_json ? data.meta_data?.page_json : {},
                }),
            );
            dispatch(serverStateStore({ data: serverData }));
            dispatch(
                sectionsStore({
                    state: data?.data?.section_selection
                        ? JSON.parse(data?.data?.section_selection)
                        : selectedSections,
                }),
            );
        },
    });

    // Function to update state for a specific year
    function setStateForYear(year: string, update: any) {
        const data = {
            ...state,
            [year]: {
                ...(state[year] || {}),
                ...update,
            },
        };

        dispatch(formStateStore({ data }));
    }

    // Function to update meta state for a specific year
    function setMetaStateForYear(year: string, update: any) {
        const data = {
            ...metaState,
            [year]: {
                ...(metaState[year] || {}),
                ...update,
            },
        };
        dispatch(metaStateStore({ state: data }));
    }

    // Function to update meta server state for a specific year
    function setMetaServerStateForYear(year: string, update: any) {
        const data = {
            ...serverMetaState,
            [year]: {
                ...(serverMetaState[year] || {}),
                ...update,
            },
        };
        dispatch(serverMetaStateStore({ data: data }));
    }

    return queryReturn;
};

export default useClientData;
