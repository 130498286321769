import { useEffect, useRef, useState } from 'react';
import useDocuments from '../../api/hooks/useDocument';
import { useAuthState } from '../../context/auth';

import Button from '../basic/Button';
import ButtonSmall from '../basic/ButtonSmall';
import Modal from '../basic/Modal';

import cloud_logo from '../../assets/cloud.png';

const Success: React.FC<{ close: () => void }> = ({ close }) => {
    const [timeLeft, setTimeLeft] = useState(2);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => {
            close();
            clearInterval(intervalId);
        };
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    return (
        <>
            <div className="w-full flex justify-center my-4">
                <div className="p-2 border-2 border-red-500 h-16 w-16 rounded-full">
                    <img src={cloud_logo} className="h-full w-full" />
                </div>
                <div className="p-5">{`File Deleted succeffully`}</div>
            </div>
        </>
    );
};

const FilePreview = ({ file }: { file: any }) => {
    if (!file)
        return (
            <div className="bg-gray-100 mx-2 p-2 text-center py-4 mt-2 mb-4 border-dashed border-gray-200 border-2">{`Upload file to see a preview`}</div>
        );

    let regEx = /^image/;
    if (regEx.test(file.document_type)) {
        return (
            <div
                className="w-auto flex justify-center bg-gray-100"
                style={{ maxHeight: '50vh', height: '100vh' }}
            >
                <img src={file.document_url} className="h-full" />
            </div>
        );
    }

    if (['.pdf', 'application/pdf'].includes(file.document_type)) {
        return (
            <div className="flex-grow bg-gray-200" style={{ maxHeight: 600, height: '100vh' }}>
                <iframe height="100%" width="100%" src={file.document_url} className="" />
            </div>
        );
    }

    return <div className="bg-gray-100">{`Unknown document type: ${file.document_type}`}</div>;
};

const DeleteModal = ({
    isOpen,
    close,
    document,
}: {
    isOpen: boolean;
    close: () => void;
    document: any;
}): JSX.Element => {
    const { deleteDocument } = useDocuments();
    const { mutate, isLoading, isSuccess, reset } = deleteDocument;

    const deleteDocumentFn = () => {
        reset();
        mutate({ id: document.id });
    };

    const closeModal = () => {
        reset();
        close();
    };

    return (
        <Modal isOpen={isOpen} close={close} title={'Delete Document'}>
            <div
                className="w-full flex flex-col m-2 bg-white rounded-md"
                style={{ maxWidth: 600, maxHeight: '90vh' }}
            >
                {isSuccess ? (
                    <Success close={closeModal} />
                ) : (
                    <>
                        <div className="flex w-full bg-red-500 mb-3 px-2 py-1">
                            <div className="text-sm font-title text-white flex-grow rounded-md font-medium">{`Delete Document`}</div>
                            <span
                                onClick={() => close()}
                                className="material-symbols-outlined cursor-pointer text-gray-100 hover:text-gray-300"
                                style={{ fontSize: 20 }}
                            >
                                cancel
                            </span>
                        </div>

                        <div className="mx-2 mt-1">{document.document_name}</div>

                        <FilePreview file={document} />

                        <div className="mx-2 mt-3">{`Are you sure you want to delete this file?`}</div>
                        <div className="flex w-full">
                            <ButtonSmall
                                secondary
                                onClick={close}
                                className="my-3 mx-2 mt-4 flex-grow"
                            >
                                <div>Cancel</div>
                            </ButtonSmall>
                            <ButtonSmall
                                onClick={deleteDocumentFn}
                                isLoading={isLoading}
                                className="my-3 mx-2 mt-4 flex-grow"
                            >
                                <div>Confirm</div>
                            </ButtonSmall>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default DeleteModal;
