import { QueryFunctionContext, useQuery } from 'react-query';
import { useAuthState } from '../../context/auth';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { apiTaxPrep } from '../tax-prep';
import { api } from '..';

// Function to generate a query string from key-value pairs
const paramString = (key: string, values: Array<any>): string => {
    let str: string = ``;

    if (values.length === 0) return str;

    values.forEach((value) => {
        if (value && !str) str = `${key}=${value}`;
        else if (value) str = str + `&${key}=${value}`;
    });

    return str;
};

const useTPAuditList = (id: string, auditType: 'tax-prep' | 'tax-res') => {
    const { token } = useAuthState();
    const [filterParams, setFilterParams] = useState<{ [key: string]: any }>({ offset: 0 });
    const [filterString, setFilterString] = useState<string>();
    console.log('filterParams', filterString)
    const getURL = (auditType: 'tax-prep' | 'tax-res') => {
        switch (auditType) {
            case 'tax-prep': return apiTaxPrep.get(`/getAllAudit?taxprep_account_id=${id}${filterString ? '&search_section=' + filterString : ''}`);
            case 'tax-res': return api.get(`getAudits?irs_account_id=${id}${filterString ? '&search_section=' + filterString : ''}`);
        }
    };

    // Query function to fetch audit data
    const getClientsExportList = async ({
        queryKey: [_, filterString],
    }: QueryFunctionContext): Promise<any> => {
        const { data } = await getURL(auditType);
        return data;
    };

    // Use react-query's useQuery hook to fetch audit data
    const { data: auditData, isFetching } = useQuery({
        queryKey: ['audit-log', auditType, filterString],
        refetchOnMount: true,
        refetchOnReconnect: true,
        staleTime: Infinity,
        queryFn: getClientsExportList,
        enabled: !!token, // Enable the query if the token is available
        onError: (error: any) => toast(error.response.data), // Handle errors and display a toast
    });

    // Effect to update the filter string whenever filter parameters change
    useEffect(() => {
        let arrParams = ``,
            strFilters = {};

        // Loop through filter parameters
        Object.keys(filterParams).map((key) => {
            if (Array.isArray(filterParams[key])) {
                // If it's an array, generate a parameter string
                arrParams = paramString(key, filterParams[key]);
            } else if (filterParams[key]) {
                // If it's a single value, add it to the filter object
                strFilters = { ...strFilters, [key]: filterParams[key] };
            }
            return null;
        });

        // Convert the filter object to a URLSearchParams string
        const strParams = new URLSearchParams({ ...strFilters });
        // Combine the filter string and array parameter string
        const queryParams = strParams + (arrParams !== `` ? '&' + arrParams : ``);

        // Set the filter string in state
        setFilterString(queryParams);
    }, [filterParams]);

    // Return relevant data and functions
    return {
        isLoading: isFetching,
        total: auditData?.total,
        data: auditData?.audit_data,
        filterParams,
        setFilterParams,
        setFilterString,
    };
};

export default useTPAuditList;
