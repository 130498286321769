// AuditLog.tsx
import useTPAuditList from '../api/hooks/useTPAuditList';
import Dropdown from '../components/admin/Dropdown';
import { fieldTypes } from '../utils/enums';
import useInfiniteScroll from '../api/tax-prep/useInfiniteScroll';
import { useQueryClient } from 'react-query';
import { useEffect } from 'react';

type Props = {
    id: string;
    auditType: 'tax-prep' | 'tax-res';
    close: () => void;
};

export const AuditLog: React.FC<Props> = ({ id, auditType, close }) => {
    // Fetch data using useTPAuditList hook
    const { data, total, isLoading, filterParams, setFilterString } = useTPAuditList(id, auditType);

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries('audit-log');
    }, []);

    // Format the data received from the API
    const formatedData =
        data &&
        Object.keys(data)
            ?.map((ele) => {
                return data[ele];
            })
            ?.flat()
            ?.sort((a: any, b: any) => {
                const dateA: any = new Date(a.created_at);
                const dateB: any = new Date(b.created_at);
                return dateB - dateA; // Compare dateB with dateA for descending order
            });

    const filterOptions = data && Object.keys(data);

    const filterOptionsObjects = filterOptions?.map((key: any) => ({ label: key, value: key })) || [
        { label: 'Loading', value: 'Loading' },
    ];

    return (
        <div
            style={{ maxWidth: 1400, width: 1400, height: '90vh' }}
            className="w-full  flex h-auto mt-10 bg-white flex-col items-center "
        >
            {/* Container for the entire component */}
            <div className="px-6 py-3 w-full bg-gray-100 flex items-center justify-between mb-3">
                <div className="font-title text-2xl font-semibold text-gray-500">Change Log</div>
                <span
                    style={{ fontSize: '' }}
                    onClick={close}
                    className="material-symbols-outlined cursor-pointer"
                >
                    close
                </span>
            </div>
            <div className="w-full flex flex-col items-center px-6 py-3">
                {/* Main content wrapper */}
                <div className="w-full flex flex-col max-w-7xl max-h-full">
                    {/* Dropdown for selecting section */}
                    <div className="w-3/5 d-none">
                        <Dropdown
                            isClearable
                            formData={{}}
                            id="account_type"
                            label="Select Section"
                            placeholder="Select Tax Preparer..."
                            options={filterOptionsObjects}
                            value={filterParams?.account_type}
                            setValue={(value) => setFilterString(value)}
                            type={fieldTypes.DROPDOWN}
                            className="w-full mt-1"
                        />
                    </div>
                    {/* Additional content above the table if needed */}
                    <div className="flex flex-col">
                        {/* Display any additional content above the table if needed */}
                    </div>
                    {/* Display the table with visible data */}
                    {formatedData && <InfiniteScrollTable data={formatedData} />}
                </div>
            </div>
        </div>
    );
};

type InfiniteScrollTableProps = {
    data: any;
};

const InfiniteScrollTable: React.FC<InfiniteScrollTableProps> = ({ data }) => {
    //to get the data in chunks based on the scroll
    const { visibleItems } = useInfiniteScroll(data, 20);

    return (
        <div className="h-full">
            {/* Additional content above the table if needed */}
            <div className="flex flex-col">
                {/* Display any additional content above the table if needed */}
            </div>
            {/* Display the table with visible data */}
            <div
                style={{ maxHeight: '70vh' }}
                className="overflow-y-auto lg:max-h-96"
                id="scrollContainer"
            >
                <table className="w-full">
                    <thead className="sticky top-0">
                        <tr>
                            <th className="bg-gray-700 px-2 py-1 font-title font-normal text-left text-white">
                                Section
                            </th>
                            <th className="bg-gray-700 px-2 py-1 font-title font-normal text-left text-white">
                                Field
                            </th>
                            <th className="bg-gray-700 px-2 py-1 font-title font-normal text-left text-white">
                                Old Value
                            </th>
                            <th className="bg-gray-700 px-2 py-1 font-title font-normal text-left text-white">
                                New Value
                            </th>
                            <th className="bg-gray-700 px-2 py-1 font-title font-normal text-left text-white">
                                Created At
                            </th>
                            <th className="bg-gray-700 px-2 py-1 font-title font-normal text-left text-white">
                                Modified By
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.slice(0, visibleItems).map((item: any, index: any) => (
                                <tr key={index} className={'hover:bg-gray-50 p-1 text-base'}>
                                    <td className="px-2 py-1 border border-gray-300">
                                        {item.section}
                                    </td>
                                    <td className="px-2 py-1 border border-gray-300">
                                        {item.field}
                                    </td>
                                    <td className="px-2 py-1 border border-gray-300">
                                        {item.old_value}
                                    </td>
                                    <td className="px-2 py-1 border border-gray-300">
                                        {item.new_value}
                                    </td>
                                    <td className="px-2 py-1 border border-gray-300">
                                        {item.created_at}
                                    </td>
                                    <td className="px-2 py-1 border border-gray-300">
                                        {item.modified_by}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InfiniteScrollTable;
