import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { interface_field_amountInput } from '../../utils/interfaces';
import ParentGroup from './ParentGroup';

/**
 * UI Component for Text Input and Text Area
 **/

type AmountInputGroupProps = interface_field_amountInput & {
    className?: string;
    inputType?: string;
    value: number | undefined;
    error?: string;
    staticError?: string;
    disabled?: boolean;
    setValue: (str: any) => void;
    onEnter?: (str: any) => void;
};

const AmountInputGroup: React.FC<AmountInputGroupProps> = (props) => {
    const {
        id,
        placeholder,
        value,
        error,
        setValue,
        label,
        className,
        inputType: type = 'text',
        area,
        span,
        staticError,
        onEnter,
        disabled,
    } = props;

    const [inputValue, setInputValue] = useState<string | undefined>(undefined);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (value !== inputValue) {
            if (!value) {
                setInputValue(undefined);
                return;
            }

            const newValue = '$ ' + Intl.NumberFormat('en-US').format(value);
            setInputValue(newValue);
        }
    }, [value]);

    useEffect(() => {
        if (!isFocused && value !== inputValue && inputValue !== undefined) {
            const sanitized = inputValue.replace(/[^0-9.]/g, '');

            if (sanitized !== '') {
                if (typeof value === 'number') {
                    setValue(parseFloat(sanitized));
                } else {
                    setValue(sanitized);
                }
            } else {
                setValue(undefined);
            }
        }
    }, [inputValue]);

    const handleChange = (e: any) => {
        const value = e.target.value;
        if (!value) {
            setInputValue(undefined);
            return;
        }

        const sanitized = value.replace(/[^0-9.]/g, '');
        const isLastPeriod = sanitized.split('').reverse()[0] === '.';

        let newValue;
        if (isLastPeriod) {
            newValue = '$ ' + Intl.NumberFormat('en-US').format(sanitized) + '.';
        } else {
            newValue = '$ ' + Intl.NumberFormat('en-US').format(sanitized);
        }

        setInputValue(newValue);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter(inputValue);
        }
    };

    const persistValue = (value: string) => {
        setIsFocused(false);
        const sanitized = value.replace(/[^0-9.]/g, '');

        if (sanitized !== '') {
            if (typeof value === 'number') {
                setValue(parseFloat(sanitized));
            } else {
                setValue(sanitized);
            }
        } else {
            setValue(undefined);
        }
    };

    return (
        <ParentGroup
            id={id}
            className={className}
            label={label}
            value={inputValue || ''}
            error={error}
            style={{ gridColumn: `span ${span}` }}
            staticError={staticError}
        >
            {/* <input
                disabled={disabled}
                id={id + '_input'}
                key={id + '_input'}
                className="focus:outline-none hover:bg-gray-50 focus:bg-gray-100 focus:border-primary-500 w-full h-16 border-b border-gray-200  px-3 pb-1 pt-5"
                type={type}
                value={inputValue || ''}
                onChange={handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => persistValue(e.target.value)}
                onKeyPress={handleKeyPress}
            /> */}

            <input
                disabled={disabled}
                id={id + '_input'}
                key={id + '_input'}
                className={classNames(
                    'focus:outline-none focus:border-primary-500 w-full h-16 border-b border-gray-200 px-3 pb-1 pt-5',
                    {
                        'hover:bg-gray-50 focus:bg-gray-100': !disabled,
                        'bg-gray-200': disabled,
                    },
                )}
                type={type}
                value={inputValue || ''}
                onChange={handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => persistValue(e.target.value)}
                onKeyPress={handleKeyPress}
            />
        </ParentGroup>
    );
};

export default AmountInputGroup;
