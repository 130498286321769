import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertBusiness_p_p1 = (
    year: string,
    { taxprep_account_id, business_farm_income_cash_details, meta_data, id }: any,
) => {
    const regEx = /^new/;
    const data = business_farm_income_cash_details
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: meta_data.irs_account_id,
                    taxprep_account_id: id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/businessFarmIncomeCashByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data,
        taxprep_year: year,
    });
};

const b_p_p1_detailsForm: FormInterface = [
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'sales_purchased_livestock_other',
        label: 'Sales of purchased livestock and other resale items',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'purchased_livestock_cost',
        label: 'Cost or other basis of purchased livestock',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'raised_products_sales',
        label: 'Sales of livestock, produce, grains, and other products you raised',
        placeholder: 'john',
        span: 2,
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'cooperative_distributions',
        label: 'Cooperative distributions ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'taxable_amount',
        label: 'Taxable amount ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'agricultural_program_payments',
        label: 'Agricultural program payments',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'agricultural_taxable_amount',
        label: 'Agricultural Taxable amount ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'commodity_credit_corporation_loans',
        label: 'Commodity Credit Corporation (CCC) loans ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'ccc_loans_farfeited',
        label: 'CCC loans forfeited',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ccc_taxable_amount',
        label: 'CCC Taxable amount ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'crop_insurance_disaster_payments',
        label: 'Crop insurance proceeds and federal crop disaster payments ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'received_taxable_amount',
        label: 'Received Taxable amount',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'election_defer_year',
        label: 'If election to defer to 2023',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'amount_deferred_year',
        label: 'Amount deferred from 2021',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'custom_hire_income',
        label: 'Custom hire (machine work) income',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'other_income',
        label: 'Other income, including federal and state gasoline or fuel tax credit or refund ',
        placeholder: 'john',
        span: 2,
    },
];

const b_detailsSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_farm_income_cash_details',
        label: 'Do you have Farm Income—Cash Method?',
        header: 'Farm Income—Cash Method',
        displayColumns: [
            {
                name: 'Sales of purchased',
                key: 'sales_purchased_livestock_other',
            },
            {
                name: 'Cost or other basis',
                key: 'purchased_livestock_cost',
            },
        ],
        formStructure: b_p_p1_detailsForm,
    },
];

export const page: PageInputInterface = {
    address: 'business_farm_income_cash_details',
    name: 'Farm Income—Cash Method',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: b_detailsSecForm,
    save: upsertBusiness_p_p1,
};
