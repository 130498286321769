import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDocuments from '../../api/hooks/useDocument';
import { useAuthState } from '../../context/auth';
import { useFormState } from '../../context/form';
import { enumSections, fieldTypes } from '../../utils/enums';
import { pageRefiner } from '../../utils/helpers';
import { PageInterface, SectionInterface } from '../../utils/interfaces';

import Button from '../basic/Button';
import ButtonSmall from '../basic/ButtonSmall';
import Modal from '../basic/Modal';
import Dropdown from '../client/Dropdown';

import cloud_logo from '../../assets/cloud.png';
import InputGroup from '../client/InputGroup';

type HTMLInputType = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> | null;

export const usePageSelector = () => {
    const { pages } = pageRefiner();
    const { busniness_data } = useFormState();

    let allpageOptions = Object.keys(pages).map((key: string) => {
        return {
            value: pages[key].address,
            label: pages[key].name,
            sectionId: pages[key].sectionId,
        };
    });

    let basicBusniessOptions = allpageOptions.filter(
        (item) => item.sectionId === enumSections.BUSINESS_INFORMATION,
    );

    let businessOptions: any[] = [];
    busniness_data?.forEach((b: any) => {
        businessOptions = [
            ...businessOptions,
            ...basicBusniessOptions.map((i) => {
                return {
                    ...i,
                    businessId: b.id,
                    label: b.name + ' | ' + i.label,
                };
            }),
        ];
    });

    const otherpageOptions = allpageOptions.filter(
        (item) => item.sectionId === enumSections.OTHER_INFORMATION,
    );
    const firstpageOptions = allpageOptions.filter((item) =>
        [enumSections.YOUR_INFORMATION, enumSections.FINANCIAL_INFORMATION].includes(
            item.sectionId,
        ),
    );
    const pageOptions = [...firstpageOptions, ...businessOptions, ...otherpageOptions];

    return { pageOptions };
};

const Success: React.FC<{ close: () => void }> = ({ close }) => {
    const [timeLeft, setTimeLeft] = useState(2);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => {
            close();
            clearInterval(intervalId);
        };
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    return (
        <>
            <div className="w-full flex justify-center my-4">
                <div className="p-2 border-2 border-primary-500 h-16 w-16 rounded-full">
                    <img src={cloud_logo} className="h-full w-full" />
                </div>
                <div className="p-5">{`Request Created succeffully`}</div>
            </div>
        </>
    );
};

const CreateRequest = ({
    isOpen,
    close,
    page: defaultPage,
    pageLock,
}: {
    isOpen: boolean;
    close: () => void;
    page?: string;
    pageLock?: boolean;
}): JSX.Element => {
    const { pages } = pageRefiner();
    const [page, setPage] = useState<string | undefined>(defaultPage);
    const [description, setDescription] = useState<string | undefined>();
    const [emptyError, setEmptyError] = useState<boolean>(false);

    const { createRequest } = useDocuments();
    const { mutate, isLoading, isSuccess, reset } = createRequest;

    const createDocumentRequest = async () => {
        if (!description) {
            setEmptyError(true);
            return;
        }

        setEmptyError(false);
        let data = await mutate({ description: description!, section: page! });
        console.log('Document', data);
        // if (data) {

        // }
    };

    const { pageOptions } = usePageSelector();

    const closeModal = () => {
        reset();
        close();
        setPage(defaultPage);
        setDescription('');
    };

    return (
        <Modal isOpen={isOpen} close={closeModal} title={'Upload Document'}>
            <div
                className="w-full flex flex-col m-2 bg-white rounded-md"
                style={{ maxWidth: 600, maxHeight: '90vh' }}
            >
                <div className="w-full flex bg-primary-500 mb-3 items-center px-2 py-1">
                    <div className="text-sm flex-grow font-title   text-white  font-medium">
                        {`Create Request | ${pages[page || '']?.name || ''}`}
                    </div>
                    <span
                        onClick={() => close()}
                        className="material-symbols-outlined cursor-pointer text-gray-100 hover:text-red-200"
                        style={{ fontSize: 20 }}
                    >
                        cancel
                    </span>
                </div>
                {/* <div className="mx-2 mt-1">{description}</div> */}

                {isSuccess ? (
                    <Success close={closeModal} />
                ) : (
                    <>
                        {!pageLock && (
                            <Dropdown
                                type={fieldTypes.DROPDOWN}
                                id="page"
                                label="Define a section for uploading the document"
                                value={page}
                                setValue={(o) => setPage(o)}
                                options={pageOptions}
                                placeholder="Please select a Section"
                            />
                        )}

                        <InputGroup
                            type={fieldTypes.TEXT_INPUT}
                            id="description"
                            label="Give a brief document description"
                            placeholder=""
                            value={description}
                            error={emptyError ? 'Required Field' : ''}
                            setValue={(o) => {
                                setEmptyError(false);
                                setDescription(o);
                            }}
                        />

                        <Button
                            onClick={createDocumentRequest}
                            isLoading={isLoading}
                            className="my-3 mx-2 mt-4"
                        >
                            <div>Confirm</div>
                        </Button>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default CreateRequest;
