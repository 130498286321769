import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormState } from '../../context/form';
import { enumSections } from '../../utils/enums';
import { template } from '../../utils/forms';
import { FormInterface, FormStateInterface, interface_table } from '../../utils/interfaces';
import Button from '../basic/Button';
import Modal from '../basic/Modal';
import ParentGroup from './ParentGroup';
import FormConstructor from './FormContructor';

/**
 * Complex UI Component for Table Components
 **/

type TableGroupProps = interface_table & {
    className?: string;
    inputType?: string;
    value: any[];
    error?: { [key: string]: any };
    disabled?: boolean;
    setValue: (str: any) => void;
};

type EditRowInterface = interface_table & {
    id: string | null;
    close: (v: void) => void;
    title?: string;
    disabled?: boolean;
    update: (state: FormStateInterface) => void;
    data?: { [key: string]: any };
    error?: any;
};

const EditRow: React.FC<EditRowInterface> = ({
    id,
    data = {},
    error,
    identifier,
    rowStructure,
    title,
    update,
    disabled,
    dynamicSave = {
        saveFunction: async () => null,
        sectionId: null,
    },
}) => {
    const [formState, setFormState] = useState(data);
    const { meta_data } = useFormState();

    /** If the item is new, give default new values */
    useEffect(() => {
        if (id === 'new') setFormState({ [identifier || '']: `new` });
    }, []);

    const queryClient = useQueryClient();

    const mutation = useMutation(dynamicSave.saveFunction, {
        onSuccess: () => {
            queryClient.invalidateQueries(`${dynamicSave.sectionId}`);
        },
    });

    const saveAndClose = () => {
        update(formState);
        mutation.mutate({ business: formState, meta_data });
    };

    const resetAndClose = () => {
        update(data);
    };

    const setValue = ({ id, value }: any) => {
        setFormState((state) => ({ ...state, [id]: value }));
    };

    return (
        <Modal isOpen={id !== ''} close={resetAndClose}>
            <div
                className="bg-white w-full max-w-4xl flex flex-col items-center "
                style={{ maxHeight: '95%', minHeight: '40%' }}
            >
                <div className="font-title text-lg font-medium px-5 py-3 w-full text-left bg-gray-100">
                    {title || 'Row Data'}
                </div>
                {/* <div className="w-full h-px bg-gray-300" /> */}

                {/* Content */}
                <div className="flex-grow w-full overflow-auto flex flex-col items-center overflow-y-scroll">
                    <FormConstructor
                        disabled={disabled}
                        structure={rowStructure}
                        formState={{ ...formState, _errors: error }}
                        setFormState={setValue}
                        className="mt-3 mb-8 justify-center"
                        getOptionValue={() => 'one'}
                        setOptionValue={() => {}}
                    />

                    <div className="flex w-full items-center justify-center bg-gray-50 pt-3 pb-8">
                        <div className="flex flex-grow justify-end max-w-2xl">
                            <div className="flex w-80">
                                <Button
                                    onClick={resetAndClose}
                                    secondary
                                    className="w-1/2 mr-1 bg-white"
                                >{`CANCEL`}</Button>
                                <Button
                                    disabled={disabled}
                                    onClick={saveAndClose}
                                    className="w-1/2 ml-1"
                                >
                                    {'SAVE'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const Table: React.FC<TableGroupProps> = ({
    type,
    value,
    identifier = 'id',
    setValue,
    displayColumns,
    dynamicSave,
    addButtonText,
    rowStructure,
    header,
    error,
    disabled,
}) => {
    const [editRow, setEditRow] = useState<string | null>(null);
    const [confirmDelete, setConfirmDelete] = useState<string | null>(null);

    const updateRow = (row: FormStateInterface) => {
        if (row[identifier] === 'new') {
            setValue([...(value || []), { ...row, [identifier]: `new_${moment.now()}` }]);
        } else {
            const newValue = value.map((item) => {
                if (item[identifier] === row[identifier]) return row;
                else return item;
            });
            setValue(newValue);
        }

        setEditRow(null);
    };

    const deleteRow = (row: FormStateInterface) => {
        const newValue = value.map((item) => {
            if (item[identifier] === row[identifier]) {
                return { ...item, action: 'delete' };
            }

            return item;
        });
        setValue(newValue);
        setConfirmDelete(null);
    };

    const displayValue = (val: any) => {
        if (val instanceof Date) return moment(val).format('DD MMMM YYYY');

        return val;
    };

    const { meta_data } = useFormState();
    const queryClient = useQueryClient();
    const mutation = useMutation(dynamicSave ? dynamicSave.saveFunction : async () => {}, {
        onSuccess: () => {
            if (dynamicSave?.sectionId) {
                queryClient.invalidateQueries(`${dynamicSave.sectionId}`);
            }
        },
    });

    return (
        <ParentGroup className={`col-span-2 w-full`}>
            {Array.isArray(value) && value?.length !== 0 && (
                <div className="flex w-full border-0 border-b  border-gray-200 py-1">
                    <div className="h-2 w-2 rounded-lg bg-white ml-3 mr-4" />
                    <div className="w-full grid grid-cols-3">
                        {/**
                         *Title row
                         */}
                        {displayColumns.map(({ name }) => {
                            return (
                                <div className={classNames('font-semibold px-1 text-sm')}>
                                    {name}
                                </div>
                            );
                        })}
                        <div></div>
                    </div>
                    <div className="h-2 w-2 rounded-lg bg-white ml-3 mr-4" />
                </div>
            )}
            {Array.isArray(value) &&
                value.map((row) => {
                    // If the row has been deleted, do not render it
                    if (row?.action === 'delete') return <></>;

                    if (!row) return <>{JSON.stringify(row)}</>;

                    // Render row confirmation if the user wants to delete it
                    if (confirmDelete === row[identifier]) {
                        return (
                            <div
                                className="flex w-full border items-center border-yellow-200  py-1 border-t-0 cursor-pointer hover:bg-yellow-100 bg-yellow-50"
                                style={{ minHeight: 50 }}
                            >
                                <span className="material-icons ml-2 text-red-400">warning</span>
                                <div className="flex-grow pl-3 font-medium">{`Are you sure you want to delete this row?`}</div>
                                <div className="flex">
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            deleteRow(row);

                                            if (dynamicSave?.saveFunction) {
                                                mutation.mutate({
                                                    business: { action: 'delete', ...row },
                                                    meta_data,
                                                });
                                            }
                                        }}
                                        className="material-icons ml-1 text-primary-500"
                                    >
                                        check_circle
                                    </span>
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setConfirmDelete(null);
                                        }}
                                        className="material-icons mr-2 ml-1 text-red-400"
                                    >
                                        cancel
                                    </span>
                                </div>
                            </div>
                        );
                    }

                    // Render default row
                    const row_error = error?.[row.id];
                    const error_length = Object.keys(row_error || {}).length;

                    return (
                        <div
                            onClick={() => setEditRow(row[identifier])}
                            style={{ minHeight: 50 }}
                            className={classNames(
                                'flex min-h-10 w-full border items-center  py-1 border-t-0 cursor-pointer',
                                {
                                    'border-gray-200 hover:bg-gray-100': error_length === 0,
                                    'border-red-200 hover:bg-red-200 bg-red-100': error_length > 0,
                                },
                            )}
                        >
                            <div className="h-3 w-3 rounded-md bg-primary-500 ml-3 mr-4" />
                            <div className="w-full grid grid-cols-3 ">
                                {displayColumns.map(({ key }) => {
                                    return <div className="px-1">{displayValue(row[key])}</div>;
                                })}
                            </div>
                            {!disabled && (
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setConfirmDelete(row[identifier]);
                                    }}
                                    className="material-icons mr-2 ml-1 text-gray-400 hover:text-red-400"
                                >
                                    delete_forever
                                </span>
                            )}
                        </div>
                    );
                })}

            {/* ADD BUTTON */}
            <div
                onClick={() => !disabled && setEditRow('new')}
                style={{ minHeight: 50 }}
                className={classNames(
                    'w-full border flex justify-center items-center border-gray-200 px-2 py-1  cursor-pointer hover:bg-gray-100',
                    {
                        'border-dashed bg-gray-50': Array.isArray(value) && value?.length === 0,
                        'border-t-0': Array.isArray(value) && value?.length !== 0,
                        'bg-gray-200 hover:bg-gray-200': disabled,
                    },
                )}
            >
                {(Array.isArray(value) && value?.length === 0) || !value ? (
                    <div className={'py-8'}>
                        <div className="text-gray-500 text-sm font-body font-medium">
                            No records exist. Click to add first
                        </div>
                        <div className="text-primary-500 text-center font-title font-bold mt-1">
                            ADD ROW
                        </div>
                    </div>
                ) : (
                    <div className="text-primary-500 text-center font-title font-bold mt-1">
                        {addButtonText || 'ADD NEW ROW'}
                    </div>
                )}
            </div>

            <EditRow
                disabled={disabled}
                key={editRow}
                id={editRow || ''}
                identifier={identifier}
                close={() => setEditRow(null)}
                rowStructure={rowStructure}
                update={updateRow}
                data={value?.find((item) => item[identifier] === editRow)}
                error={error?.[editRow || '']}
                dynamicSave={dynamicSave}
                displayColumns={displayColumns}
                type={type}
                title={`Row Data | ${header}`}
            />
        </ParentGroup>
    );
};
