import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { generateForm } from '../../api/admin';
import useTaxPrep from '../../api/hooks/useTaxPrep';
import { useAuthState } from '../../context/auth';
import { fieldTypes } from '../../utils/enums';
import { useFeatureEnabled } from '../../utils/helpers';
import Dropdown from '../client/Dropdown';
import InputGroup from '../client/InputGroup';
import RadioGroup from '../client/Radio';
import Button from './Button';
import { useFormState } from '../../context/form';

export const formOptions: Array<{ label: string; value: string }> = [
    { label: 'Form 2848', value: 'f2848' },
    { label: 'Form 433-A', value: 'f433a' },
    { label: 'Form 433-A (OIC)', value: 'f433aoic' },
    { label: 'Form 433-B', value: 'f433b' },
    { label: 'Form 433-B (OIC)', value: 'f433boic' },
    { label: 'Form 433-D', value: 'f433d' },
    { label: 'Form 433-F', value: 'f433f' },
    { label: 'Form 656', value: 'f656' },
    { label: 'Form 8821', value: 'f8821' },
];

const GenerateFormModal: React.FC<{ close: () => void; user?: any }> = ({ close }) => {
    const [form, setForm] = useState<string>();
    const [validateB, setValidateB] = useState<boolean>(false);
    const { irs_account_id } = useParams();
    const [errors, setErrors] = useState<{ [key: string]: string }>();
    const user = useAuthState();
    const formState = useFormState();

    const enabledForms = useFeatureEnabled('Form');

    const [extraData, setExtraData] = useState<any>({
        tax_preparer_id1: '',
    });

    const bussinessOptions = formState.business_data.map((obj: any) => {
        return { label: obj.name, value: obj.id };
    });

    useEffect(() => {
        if (form === 'f433b' || form === 'f433boic') {
            if (bussinessOptions.length === 1) {
                setValidateB(false);
                setExtraData({ bussiness_id: bussinessOptions[0].value });
            }
            if (extraData.bussiness_id) {
                setValidateB(false);
            }
        } else {
            setExtraData({
                tax_preparer_id1: '',
            });
        }
    }, [form]);

    const { taxPreparers } = useTaxPrep();

    const taxPrepOptions = taxPreparers
        ? taxPreparers.map((item: any) => ({
              label: item.u_first_name + ' ' + item.u_last_name,
              value: item.user_id,
          }))
        : [];

    const submitForm = () => {
        let localErrors: { [key: string]: string } = {};
        if (!form) localErrors.form = 'Form Required';
        if (!irs_account_id) localErrors.irs_account_id = 'IRS Account id missing';

        if (Object.keys(localErrors).length > 0) {
            setErrors(localErrors);
            return;
        }

        let tED = {};
        Object.keys(extraData).map((key) => {
            switch (key) {
                case 'f656_1040_years':
                case 'f656_business_years':
                    if (extraData[key]) {
                        tED = { ...tED, [key]: extraData[key].join() };
                    }
                    break;

                case 'f656_low_income_qualify':
                    if (extraData[key]) {
                        extraData[key].forEach((element: string) => {
                            tED = { ...tED, [element]: 'Yes' };
                        });
                    }
                    break;

                default:
                    tED = { ...tED, [key]: extraData[key] };
            }
        });

        if ((form === 'f433b' || form === 'f433boic') && !extraData.bussiness_id) {
            setValidateB(true);
            return;
        }

        mutate({
            form_name: form as string,
            irs_account_id: irs_account_id as string,
            extraData: tED,
        });
    };

    const { mutate, isLoading } = useMutation(generateForm, {
        onSuccess: (data) => {
            window.open(data.data[0].url, '_blank');
            close();
        },
        onError: (error: any) => {
            setErrors(error.response.data);
        },
    });

    const years_options = [...Array(30)].map((_, index) => {
        return { label: `${2020 - index}`, value: `${2020 - index}` };
    });

    const removeSelectedTaxPros = (item: any) => {
        for (let i = 1; i <= 4; i++) {
            if (item.value === extraData[`tax_preparer_id${i}`]) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`Generate Form`}</div>
            <RadioGroup
                column
                id="form-selector"
                label=""
                value={form}
                options={formOptions.filter(({ value }) => enabledForms?.feature?.[value])}
                setValue={(value) => {
                    setForm(value);
                    setExtraData({});
                }}
                type={fieldTypes.RADIO}
                className="w-full mt-1"
            />

            {form === 'f433d' && (
                <>
                    <InputGroup
                        id="f433d_routing_number"
                        label="Routing Number"
                        placeholder=""
                        value={extraData.f433d_routing_number}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['f433d_routing_number']: value })
                        }
                        type={fieldTypes.TEXT_INPUT}
                        className="w-full mt-1"
                    />
                    <InputGroup
                        id="f433d_account_number"
                        label="Account Number"
                        placeholder=""
                        value={extraData.f433d_account_number}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['f433d_account_number']: value })
                        }
                        type={fieldTypes.TEXT_INPUT}
                        className="w-full mt-1"
                    />
                </>
            )}

            {(form === 'f433b' || form === 'f433boic') && bussinessOptions.length > 1 && (
                <>
                    <Dropdown
                        key={form + extraData.bussiness_id}
                        id="f656_1040_years"
                        label="Bussiness Options"
                        placeholder=""
                        value={extraData.bussiness_id}
                        options={bussinessOptions}
                        setValue={(value: any) => setExtraData({ bussiness_id: value })}
                        type={fieldTypes.DROPDOWN}
                        className="w-full mt-1"
                    />
                    <div className="text-red-600 px-3 text-xs font-bold bg-red-100 mt-2">
                        {validateB === true && 'Select atleast one'}
                    </div>
                </>
            )}

            {form === 'f656' && (
                <>
                    <Dropdown
                        isMulti
                        id="f656_1040_years"
                        label="1040 Income Tax-Year(s)"
                        placeholder=""
                        value={extraData.f656_1040_years}
                        options={years_options}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['f656_1040_years']: value })
                        }
                        type={fieldTypes.DROPDOWN}
                        className="w-full mt-1"
                    />

                    <Dropdown
                        isMulti
                        id="f656_business_years"
                        label="Business 1120 Income Tax-Year(s)"
                        placeholder=""
                        value={extraData.f656_business_years}
                        options={years_options}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['f656_business_years']: value })
                        }
                        type={fieldTypes.DROPDOWN}
                        className="w-full mt-1"
                    />
                    <div className="px-2 mt-5">Do you qualify for Low-Income Certification?</div>
                    <RadioGroup
                        column
                        isMulti
                        type={fieldTypes.RADIO}
                        id="f656_low_income_qualify"
                        label=""
                        options={[
                            {
                                label: 'Because adjusted gross income <= the total amount',
                                value: 'f656_low_income_qualify1',
                            },
                            {
                                label: `Because household's size and gross monthly income x 12 <=  the total amount`,
                                value: 'f656_low_income_qualify2',
                            },
                        ]}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['f656_low_income_qualify']: value })
                        }
                        value={extraData.f656_low_income_qualify}
                    />

                    <InputGroup
                        id="f656_reason_for_offer"
                        label="Reason for offer"
                        placeholder=""
                        value={extraData.f656_reason_for_offer}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['f656_reason_for_offer']: value })
                        }
                        type={fieldTypes.TEXT_INPUT}
                        className="w-full mt-1"
                    />
                </>
            )}

            {(form === 'f2848' || form === 'f8821') && (
                <>
                    <Dropdown
                        id="tax_preparer_id1"
                        label="Tax Preparer 1"
                        placeholder=""
                        value={extraData.tax_preparer_id1}
                        options={taxPrepOptions.filter(removeSelectedTaxPros)}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['tax_preparer_id1']: value })
                        }
                        type={fieldTypes.DROPDOWN}
                        className="w-full mt-1"
                    />

                    <Dropdown
                        id="tax_preparer_id2"
                        label="Tax Preparer 2"
                        placeholder=""
                        value={extraData.tax_preparer_id2}
                        options={taxPrepOptions.filter(removeSelectedTaxPros)}
                        setValue={(value: any) =>
                            setExtraData({ ...extraData, ['tax_preparer_id2']: value })
                        }
                        type={fieldTypes.DROPDOWN}
                        className="w-full mt-1"
                    />

                    {form === 'f2848' && (
                        <>
                            <Dropdown
                                id="tax_preparer_id3"
                                label="Tax Preparer 3"
                                placeholder=""
                                value={extraData.tax_preparer_id3}
                                options={taxPrepOptions.filter(removeSelectedTaxPros)}
                                setValue={(value: any) =>
                                    setExtraData({ ...extraData, ['tax_preparer_id3']: value })
                                }
                                type={fieldTypes.DROPDOWN}
                                className="w-full mt-1"
                            />

                            <Dropdown
                                id="tax_preparer_id4"
                                label="Tax Preparer 4"
                                placeholder=""
                                value={extraData.tax_preparer_id4}
                                options={taxPrepOptions.filter(removeSelectedTaxPros)}
                                setValue={(value: any) =>
                                    setExtraData({ ...extraData, ['tax_preparer_id4']: value })
                                }
                                type={fieldTypes.DROPDOWN}
                                className="w-full mt-1"
                            />
                        </>
                    )}
                </>
            )}

            {errors && (
                <div className="text-red-600 px-3 text-xs font-bold bg-red-100 mt-8">
                    {errors.message}
                </div>
            )}
            {enabledForms.success && (
                <Button
                    isLoading={isLoading}
                    onClick={submitForm}
                    className="text-center w-full mt-2"
                >
                    {'GENERATE FORM'}
                </Button>
            )}
        </div>
    );
};

export default GenerateFormModal;
