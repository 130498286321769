/**
 * UI for Crossroad decider
 */
import classNames from 'classnames';
import ParentGroup from './ParentGroup';
import CheckMarkLogo from '../../assets/checkmark.svg';
import {
    interface_option,
    interface_field_crossroad,
    FormStateInterface,
    PageInterface,
    BrickInterface,
    ConcreteInterface,
    SectionInterface,
} from '../../utils/interfaces';
import { useEffect, useState } from 'react';
import { fieldTypes } from '../../utils/enums';
import SeperatorGroup from './SeperatorGroup';
import { useFormDispatch, useFormState } from '../../context/form';
import FormConstructor from '../basic/AdminFormConstructor';
import moment from 'moment';
import Modal from '../basic/Modal';
import { Table } from './Table';
import Button from '../basic/Button';

type CrossroadProps = interface_field_crossroad & {
    className?: string;
    value: string | number;
    formState: FormStateInterface;
    setFormState: ({ id, value }: any) => void;
    business?: any;
};

type FormDeterminerProps = interface_field_crossroad & {
    id: string;
    pathValue: string;
    header?: string;
    identifier?: string;
    formStructure: any;
    displayColumns: Array<{ name: string; key: string; span?: number }>;
    formState: FormStateInterface;
    setFormState: ({ id, value }: any) => void;
};

const defaultOptions: Array<interface_option> = [
    { label: 'No', value: 'no' },
    { label: 'Yes, Only one', value: 'one' },
    { label: 'Yes, Multiple', value: 'multiple' },
];

const SelectOneModal: React.FC<{
    value: any[];
    displayColumns: any[];
    resetAndClose: () => void;
    saveAndClose: (saveRow: any) => void;
}> = ({ value, displayColumns, resetAndClose, saveAndClose }) => {
    const [saveRow, setSaveRow] = useState();

    return (
        <div
            className="bg-white w-full max-w-4xl flex flex-col items-center "
            style={{ maxHeight: '95%', minHeight: '40%' }}
        >
            <div className="flex font-title text-lg font-medium px-5 py-3 w-full text-left bg-gray-100">
                <div className="flex-grow">{'Multiple Rows Found'}</div>
            </div>

            <div className="flex font-body px-5 pt-4 pb-1 w-full text-left">
                <div className="flex-grow">{`You have ${value.length} rows added in multiple. Only one row value can be saved when shifting from multiple to one`}</div>
            </div>

            <div className="flex font-body px-5 py-1 w-full text-left">
                <div className="flex-grow">{`Select the data row you wish to save`}</div>
            </div>
            <div className="px-5 py-2 mt-2 w-full flex-grow">
                <div className="w-full grid grid-cols-3 bg-gray-600">
                    {/**
                     *Title row
                     */}
                    {displayColumns.map(({ name }) => {
                        return (
                            <div className={classNames('font-semibold text-white px-1 text-sm')}>
                                {name}
                            </div>
                        );
                    })}
                    <div></div>
                </div>

                {value.map((item) => {
                    const isMatch = saveRow?.['id'] === item?.id;
                    return (
                        <div
                            onClick={() => setSaveRow(isMatch ? undefined : item)}
                            className={classNames(
                                'w-full grid grid-cols-3 border border-gray-100 rounded-sm py-1.5 cursor-pointer ',
                                {
                                    'bg-primary-500 hover:bg-primary-700': isMatch,
                                    'hover:bg-gray-100': !isMatch,
                                },
                            )}
                        >
                            {/**
                             *Title row
                             */}
                            {displayColumns.map(({ key }) => {
                                return (
                                    <div
                                        className={classNames('px-1 text-sm', {
                                            'text-white font-semibold': isMatch,
                                        })}
                                    >
                                        {item[key] === 'y'
                                            ? 'Yes'
                                            : item[key] === 'n'
                                            ? 'No'
                                            : item[key]}
                                    </div>
                                );
                            })}
                            <div></div>
                        </div>
                    );
                })}
            </div>

            {!saveRow ? (
                <div className="bg-gray-50 w-full px-5 mt-5 pt-3">
                    <div className="flex w-full items-center justify-end  pt-2 pb-3">
                        <Button
                            onClick={() => resetAndClose()}
                            secondary
                            className="w-1/4 mr-1 bg-white"
                        >{`CANCEL`}</Button>
                    </div>
                </div>
            ) : (
                <div className="bg-gray-300 w-full px-5 mt-5 pt-3">
                    <div className="flex w-full items-center justify-center  pt-2 pb-3">
                        <div className="flex-grow">
                            <div className="font-semibold">{`Confirm data row?`}</div>
                            <div className="text-sm">{`All the remaining rows will be removed`}</div>
                        </div>
                        <Button
                            onClick={() => resetAndClose()}
                            secondary
                            className="w-1/4 mr-1 bg-white"
                        >{`CANCEL`}</Button>
                        <Button onClick={() => saveAndClose(saveRow)} className="w-1/4 ml-1">
                            {'SAVE'}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const FormDeterminer: React.FC<FormDeterminerProps> = ({
    id,
    pathValue,
    header,
    identifier = 'id',
    formStructure: rowStructure,
    setFormState,
    formState,
    displayColumns,
    dynamicSave,
    dynamicSaveMessage,
    preForm,
}) => {
    const formValue =
        formState[id]?.map((item: any) => ({
            ...item,
            _errors: formState._errors?.[id]?.[item.id],
        })) || [];

    if (id === 'employment_data') {
    }

    const setOne = ({ id: fieldId, value }: any) => {
        let put_value = [{ [identifier]: `new_${moment.now()}`, [fieldId]: value }];

        if (Array.isArray(formValue) && formValue?.length) {
            put_value = formValue.map((item: any, index: number) => {
                if (index === 0) return { ...item, [fieldId]: value };
                else return item;
            });
        }

        setFormState({ id, value: put_value });
    };

    const setMany = ({ value }: any) => {
        setFormState({ id, value: value });
    };

    switch (pathValue) {
        case 'no':
            return (
                <FormConstructor
                    structure={[{ type: fieldTypes.TERMINAL }]}
                    formState={{}}
                    setFormState={() => {}}
                />
            );

        case 'one':
            return (
                <>
                    <FormConstructor
                        structure={[
                            { type: fieldTypes.SEPERATOR, title: header },
                            ...(preForm || []),
                            { type: fieldTypes.SEPERATOR, title: header },
                        ]}
                        formState={formState}
                        setFormState={setFormState}
                    />
                    <FormConstructor
                        structure={[...rowStructure, { type: fieldTypes.TERMINAL }]}
                        formState={
                            formValue
                                ? formValue.find((item: any) => item.action !== 'delete') ||
                                  formValue[0]
                                : {}
                        }
                        setFormState={setOne}
                    />
                </>
            );

        case 'multiple':
            return (
                <>
                    <FormConstructor
                        structure={[
                            { type: fieldTypes.SEPERATOR, title: header },
                            ...(preForm || []),
                            { type: fieldTypes.SEPERATOR },
                        ]}
                        formState={formState}
                        setFormState={setFormState}
                    />
                    <FormConstructor
                        structure={[
                            {
                                type: fieldTypes.TABLE,
                                id,
                                displayColumns,
                                rowStructure,
                                identifier,
                                dynamicSave,
                                dynamicSaveMessage,
                                header,
                            },
                            { type: fieldTypes.TERMINAL },
                        ]}
                        formState={formState}
                        setFormState={setMany}
                    />
                </>
            );

        default:
            return (
                <>
                    <SeperatorGroup type={fieldTypes.SEPERATOR} />
                    <div className="flex flex-col col-span-2 w-full items-center">
                        <div className="w-full h-0.5 bg-gray-200"></div>
                        <div className="text-sm text-gray-300 font-semibold mt-5">{`Answer to continue`}</div>
                    </div>
                </>
            );
    }
};

const Crossroad: React.FC<CrossroadProps> = (props) => {
    const {
        id,
        displayColumns,
        formStructure,
        label,
        className,
        messages,
        options: providedOptions,
        business,
        formState,
        setFormState,
    } = props;

    const globalFormState = useFormState();
    const formDispatch = useFormDispatch();

    const options = providedOptions || defaultOptions;

    if (id === 'business_credit_data') {
    }

    const pathValue = business
        ? globalFormState.meta_data?.form_status[business.id]
            ? globalFormState.meta_data?.form_status[business.id][id + '_count']
            : undefined
        : globalFormState.meta_data?.form_status[id + '_count'];

    const currentOption = options.find((o) => o.value === pathValue) || ({} as interface_option);

    const setOptionValue = ({ value, saveRow }: { value: string | undefined; saveRow?: any }) => {
        if (business) {
            formDispatch({
                type: 'UPDATE_CROSSROAD_BUSINESS',
                payload: { businessId: business.id, data: { [id + '_count']: value } },
            });
        } else {
            formDispatch({
                type: 'UPDATE_CROSSROAD',
                payload: { data: { [id + '_count']: value } },
            });
        }

        let updatedData: any;
        switch (value) {
            case 'no':
            case undefined:
                updatedData = formState[id]?.map((item: any) => {
                    return { action: 'delete', ...item };
                });
                break;

            case 'one':
                updatedData = formState[id]?.map((item: any, index: number) => {
                    if (saveRow?.['id'] == item.id) {
                        const { action, ...original } = item;
                        return original;
                    } else return { action: 'delete', ...item };
                });
                break;

            case 'multiple':
                updatedData = formState[id]?.map((item: any) => {
                    const { action, ...original } = item;
                    return original;
                });
                break;

            default:
                updatedData = formState[id];
        }

        if (id === 'business_data') {
            formDispatch({
                type: 'UPDATE_FORM',
                payload: { data: { business_data: updatedData } },
            });
        } else {
            setFormState({ id, value: updatedData });
        }
    };

    const [selectOneModal, setSelectOneModal] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const formValue =
        formState[id]?.map((item: any) => ({
            ...item,
            _errors: formState._errors?.[id]?.[item.id],
        })) || [];

    const resolveOptionValue = (option: interface_option) => {
        const { value: optionValue } = option;

        if (currentOption.value === 'multiple' && optionValue === 'one') {
            if (formValue?.length > 1) {
                setSelectOneModal(true);
                return;
            } else {
                setOptionValue({ value: 'one', saveRow: formValue[0] });
                return;
            }
        }

        if (currentOption.value === 'no' && optionValue === 'one') {
            if (formValue?.length > 1) {
                setSelectOneModal(true);
                return;
            } else {
                setOptionValue({ value: 'one', saveRow: formValue[0] });
                return;
            }
        }

        if (pathValue === optionValue) {
            setOptionValue({ value: undefined });
        } else {
            setOptionValue({ value: optionValue });
        }
    };

    // const setFormState = ({ id, value }: { id: string; value: any }) => {
    //     if (business) {
    //         formDispatch({
    //             type: 'UPDATE_FORM_BUSINESS',
    //             payload: { businessId: business.id, data: { [id]: value } },
    //         });
    //     } else {
    //         formDispatch({
    //             type: 'UPDATE_FORM',
    //             payload: { data: { [id]: value } },
    //         });
    //     }
    // };

    // const data = formState['additional_income_data']?.map((value: any) => {
    //     return value?.income_type === 'net_business';
    // });
    // function getMessage() {
    //     const isTrue =
    //         formState[id] === 'additional_income_data' &&
    //         formState['additional_income_data']?.map((value: any) => {
    //             if (value.income_type === messages?.id) {
    //                 return true;
    //             } else return false;
    //         });
    //     setMessage(isTrue);
    // }
    // useEffect(() => getMessage());

    // const hasTrueValue = data === true || data?.includes('true');

    // Set message to true if hasTrueValue is true, otherwise false
    // setMessage(hasTrueValue);

    useEffect(() => {
        let flag = false;
        formState[id]?.forEach((value: any) => {
            if (value.action === 'delete') {
                return;
            }

            Object.keys(value).forEach((key) => {
                const m = messages?.find((msg) => msg.id === key);
                if (!m) return;
                if (m.value === value[key]) {
                    flag = true;
                    setMessage(m.message);
                }
            });
        });

        if (!flag && message !== '') {
            setMessage('');
        }
    }, [formState]);

    return (
        <ParentGroup className={classNames('col-span-4', className)}>
            <div className="w-full">
                <div className={classNames('pointer-events-none text-sm')}>{label}</div>
                <div className="flex items-center  w-full">
                    {options.map((option: interface_option) => {
                        const { label: optionLabel, value: optionValue } = option;

                        return (
                            <>
                                <label
                                    onChange={() => resolveOptionValue(option)}
                                    className="flex flex-grow items-center hover:bg-gray-50 py-2"
                                >
                                    <div
                                        className={classNames('h-4 w-4 rounded-full border-2', {
                                            'border-primary-500':
                                                currentOption?.value === optionValue,
                                            'border-gray-200': currentOption?.value !== optionValue,
                                        })}
                                    >
                                        {currentOption?.value === optionValue && (
                                            <div className="h-2 w-2 m-0.5 rounded-full bg-primary-500" />
                                        )}
                                    </div>

                                    <input
                                        name={id}
                                        id={optionValue}
                                        type="radio"
                                        checked={currentOption?.value === optionValue}
                                        className={classNames(
                                            'focus:outline-primary border-4 border-gray-200 hidden rounded-md p-2 pt-5 h-4 w-4 mx-1',
                                            {
                                                'bg-primary-500':
                                                    currentOption?.value === optionValue,
                                            },
                                        )}
                                    />

                                    <div
                                        className={classNames('flex-grow ml-1', {
                                            'text-gray-500': !currentOption?.value === optionValue,
                                        })}
                                    >
                                        {optionLabel}
                                    </div>
                                </label>
                            </>
                        );
                    })}
                </div>
            </div>

            {formStructure && displayColumns && (
                <FormDeterminer
                    {...props}
                    setFormState={setFormState}
                    formState={formState}
                    pathValue={pathValue}
                />
            )}
            <div className="m-1 text-red-600">{message}</div>

            <Modal isOpen={selectOneModal} close={() => setSelectOneModal(false)}>
                <SelectOneModal
                    value={formValue}
                    displayColumns={displayColumns}
                    resetAndClose={() => setSelectOneModal(false)}
                    saveAndClose={(saveRow) => {
                        setOptionValue({ value: 'one', saveRow });
                        setSelectOneModal(false);
                    }}
                />
            </Modal>
        </ParentGroup>
    );
};

export default Crossroad;
