import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertBusiness_p_p2 = (
    year: string,
    { id, business_expense_cash_accural_details, meta_data }: any,
) => {
    const regEx = /^new/;
    const data = business_expense_cash_accural_details
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = {
                    ...temp,
                    action,
                    irs_account_id: meta_data.irs_account_id,
                    taxprep_account_id: id,
                };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/businessFarmExpensesCashAccuralByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data,
        taxprep_year: year,
    });
};

const b_p_p2_Form: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'car_truck_expenses',
        label: 'Car and truck expenses',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'chemicals',
        label: 'Chemicals',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'conservation_expenses',
        label: 'Conservation expenses',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'custom_hire',
        label: 'Custom hire ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'depreciation',
        label: 'Depreciation',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'employee_benefit_programs',
        label: 'Employee benefit programs ',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'feed',
        label: 'Feed',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'fertilizers_lime',
        label: 'Fertilizers and lime',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'freight_trucking',
        label: 'Freight and trucking',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'gasoline_fuel_oil',
        label: 'Gasoline, fuel, and oil',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'insurance',
        label: 'Insurance (other than health)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'interest',
        label: 'Interest',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'mortage',
        label: 'Mortgage (paid to banks, etc.)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'other',
        label: 'Other',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'labor_hired',
        label: 'Labor hired (less employment credits)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'pension_profit_sharing',
        label: 'Pension and profit-sharing plans',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'rent_lease',
        label: 'Rent or lease',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'vehicles_machinery_equipment',
        label: 'Vehicles, machinery, equipment',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'other_land_animals',
        label: 'Other (land, animals, etc.)',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'repairs_maintenance',
        label: 'Repairs and maintenance',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'seeds_plants',
        label: 'Seeds and plants',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'storage_warehousing',
        label: 'Storage and warehousing',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'supplies',
        label: 'Supplies',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'taxes',
        label: 'Taxes',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'utilites',
        label: 'Utilities',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'veterinary_breeding_medicine',
        label: 'Veterinary, breeding, and medicine',
        placeholder: 'john',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'other_expenses',
        label: 'Other expenses',
        placeholder: 'john',
    },
    {
        type: fieldTypes.RADIO,
        id: 'your_investment_type',
        label: 'Your Investment type',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
];

const b_p_p2SecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'business_expense_cash_accural_details',
        label: 'Do you have Farm Expenses—Cash and Accrual Method?',
        header: 'Farm Expenses—Cash and Accrual Method',
        displayColumns: [
            {
                name: 'Car and truck expenses',
                key: 'car_truck_expenses',
            },
            {
                name: 'Chemicals',
                key: 'chemicals',
            },
            {
                name: 'Conservation expenses',
                key: 'conservation_expenses',
            },
        ],
        formStructure: b_p_p2_Form,
    },
];

export const page: PageInputInterface = {
    address: 'business_expense_cash_accural_details',
    name: 'Farm Expenses—Cash and Accrual Method',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: b_p_p2SecForm,
    save: upsertBusiness_p_p2,
};
