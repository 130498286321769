import { useState, useEffect } from 'react';
import { fieldTypes } from '../utils/enums';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import InputGroup from '../components/client/InputGroup';
import Button from '../components/basic/Button';
import { useAuthDispatch, useAuthState } from '../context/auth';
import { defaultLogin, defaultSuperAdmin } from '../api/login';
import { useMutation } from 'react-query';
import classNames from 'classnames';
import { build } from '../config';
import { SignInButton } from '../components/basic/MicrosoftSignIn';
import { useIsAuthenticated } from '@azure/msal-react';
import { useFeatureEnabled } from '../utils/helpers';
import GoogleSignIn from '../components/basic/GoogleSignIn';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stateManager/rootReducers';
import { setAuthError, setAuthPage } from '../stateManager/authSlice';

const LoginScreen: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const location = useLocation();
    const [salogin, setsaLogin] = useState<boolean>(location.pathname == '/sa/login');
    const [password, setPassword] = useState<string>('');
    const [error, setErrors] = useState<{ [key: string]: any } | string>();
    const authState = useSelector((state: RootState) => state.auth);
    const ms_login_feature = useFeatureEnabled('Microsoft Login');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authDispatch = useAuthDispatch();
    const { success, role } = useAuthState();

    //if authstate contains any error to show but when we switch page then it should get erased
    useEffect(() => {
        // This function will be called whenever the URL changes
        dispatch(setAuthError({ authError: '' }));
        // Trigger your event here
    }, [location]);

    const [searchParams] = useSearchParams();
    const isIFrame = searchParams.get('iframe-evaluate');

    //mutate function
    const { mutate: loginUser, isLoading } = useMutation(defaultLogin, {
        onSuccess: (data) => {
            authDispatch('LOGIN', data.data);
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    //mutate caller function
    const { mutate: superLogin, isLoading: superLoading } = useMutation(defaultSuperAdmin, {
        onSuccess: (data) => {
            authDispatch('LOGIN', data.data);
        },
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    //set the auth page that the process is running from login page for futher usage based on this data
    useEffect(() => {
        dispatch(setAuthPage({ authPage: 'sign-in' }));
    }, []);

    //incase authState contains role then redirect based on the role
    useEffect(() => {
        if (success) {
            const client_id = searchParams.get('iframe-evaluate');
            const tax_pro_id = searchParams.get('tax_pro_id');

            if (client_id) {
                navigate(
                    '/iframe-evaluate/' +
                        client_id +
                        '?tax_pro_id=' +
                        tax_pro_id +
                        '&source=salesforce',
                );
                return;
            }

            switch (role) {
                case 'Client':
                    navigate('/home');
                    return;

                case 'Admin':
                    navigate('/taxprep');
                    return;

                case 'Tax Preparer':
                    navigate('/client');
                    return;

                case 'Super Admin':
                    navigate('/superAdmin');
                    return;

                default:
                    return;
            }
        }
    }, [success]);

    return (
        <div className="w-full h-screen flex">
            <div
                className="flex-grow bg-primary-200 max-w-xs opacity-10"
                style={{
                    backgroundImage: 'url(/menu_pattern.png)',
                    backgroundSize: '200px 200px',
                }}
            />

            <div className="flex-grow flex justify-center items-center" style={{ flexGrow: 2 }}>
                <div className="max-w-md w-full flex flex-col items-center p-3">
                    <div className="font-title flex bg-white justify-center items-center z-20">
                        <img
                            src={build.logo_dark}
                            className={classNames('h-16 z-20 bg-white  mb-8 ')}
                            style={{ padding: 1 }}
                        />
                    </div>

                    {typeof error === 'string' && (
                        <div className="w-full bg-red-100 text-red-600 font-bold text-xs px-3">
                            {error}
                        </div>
                    )}
                    {authState?.authError && (
                        <div className="w-full bg-red-100 text-red-600 font-bold text-xs mb-2 px-3">
                            {authState?.authError}
                        </div>
                    )}

                    {build.sso_login && (
                        <>
                            <div className="flex flex-col space-y-2 w-full ">
                                {ms_login_feature.success && <SignInButton method="sign-in" />}
                                <GoogleSignIn method="sign-in" />
                            </div>

                            <div className="relative w-full py-3 flex justify-center">
                                <div
                                    style={{ top: '50%' }}
                                    className="absolute left-0 right-0 h-px bg-gray-300 w-full"
                                />
                                <div className="bg-white z-10 p-3 text-gray-700">OR</div>
                            </div>
                        </>
                    )}
                    {!isIFrame && (
                        <>
                            <InputGroup
                                id="email"
                                label="Email"
                                placeholder=""
                                value={email}
                                setValue={(value) => setEmail(value)}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full"
                                onEnter={(u_email) => loginUser({ u_email, u_password: password })}
                            />

                            {typeof error === 'object' && (
                                <div className="w-full bg-red-100 text-red-600 font-bold text-xs px-3">
                                    {error?.u_email?.[0]}
                                </div>
                            )}

                            <InputGroup
                                id="password"
                                label="Password"
                                placeholder=""
                                inputType="password"
                                value={password}
                                setValue={(value) => setPassword(value)}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full"
                                onEnter={(u_password) => loginUser({ u_email: email, u_password })}
                            />

                            {typeof error === 'object' && (
                                <div className="w-full bg-red-100 text-red-600 font-bold text-xs px-3">
                                    {error?.u_password?.[0]}
                                </div>
                            )}

                            <Button
                                isLoading={isLoading || superLoading || authState?.isLoading}
                                onClick={() =>
                                    salogin
                                        ? superLogin({ u_email: email, u_password: password })
                                        : loginUser({ u_email: email, u_password: password })
                                }
                                className="text-center w-full mt-2"
                            >
                                {'LOGIN'}
                            </Button>
                            {build.sign_up_page && (
                                <>
                                    <div className="text-left mt-3 text-base">
                                        {"Don't have an account already? "}
                                        <span
                                            onClick={() => {
                                                navigate('/sign-up');
                                                dispatch(setAuthError({ authError: '' }));
                                            }}
                                            className="hover:underline text-primary-400 cursor-pointer hover:text-primary-700"
                                        >
                                            Sign Up
                                        </span>
                                    </div>
                                </>
                            )}

                            <div
                                onClick={() => {
                                    navigate('/forgotPassword');
                                }}
                                className="text-primary-500 text-sm mt-4 cursor-pointer hover:text-primary-700 hover:underline"
                            >
                                Forgot Pasword?
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
