import { upsertSafeDepositBox } from '../../api/other';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const safetyDepositForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_address',
        label: 'Address',
        placeholder: 'Florida',
        span: 2,
        area: true,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'contents',
        label: 'Contents',
        placeholder: '455678933333',
        area: true,
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Value',
        placeholder: '$5,600',
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'safe_deposit_data',
        label: 'Do you have a safe deposit box (business or personal)?',
        header: 'Safety Deposit Box Information',
        formStructure: safetyDepositForm,
        displayColumns: [
            {
                name: 'Contents',
                key: 'contents',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'safety_deposit',
    name: 'Safe Deposit Box',

    sectionId: enumSections.OTHER_INFORMATION,
    formStructure: form,

    save: upsertSafeDepositBox,
};
