import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from 'react-scroll';
import { useFormDispatch, useFormState } from '../../context/form';
import { useSaveDispatch, useSaveState } from '../../context/save';
import { page } from '../../resource/form_structures/0_0_personal_information';
import { compareItemsforChange, pageRefiner } from '../../utils/helpers';
import pages from '../../resource';
import { enumSections, fieldTypes } from '../../utils/enums';
import { build } from '../../config';
import { isBrick } from '../taxprep-client-form-components/FormContructor';
import useTaxPrepEvalData from '../taxprep/useTaxPrepEvalData';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { FormInterface, FormStateInterface } from '../../utils/interfaces';
import { getTaxPrepBusiness, useTaxPrepBusiness } from '../taxprep/TaxPrepEvalSection';
import { useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';
import useAdminLocalStorage from '../taxprep/useAdminLocalStorage';

const analyzeFormStructure = (
    formStructure: FormInterface,
    form: FormStateInterface,
    second: any,
): boolean => {
    let isChanged = false;

    if (!form || !second) {
        return false;
    }

    formStructure?.map((field) => {
        if (isBrick(field)) {
            switch (field.type) {
                case fieldTypes.EDITABLE_TABLE:
                    // DefaultRowKeys
                    let keys = [];
                    field.defaultRows.map((item: any) => {
                        keys.push(item[1].id);
                        keys.push(item[1].id + '_PS');
                        keys.push(item[1].id + '_IF');
                        keys.push(item[1].overrideBooleanId);
                        keys.push(item[1].overrideValueId);
                    });

                    if (field.addColumns) {
                        for (let i = 0; i <= 20; i++) {
                            const id_name =
                                field.addColumns[0].id_prefix +
                                `${i + 1}` +
                                field.addColumns[0].id_suffix;

                            const id_value =
                                field.addColumns[1].id_prefix +
                                `${i + 1}` +
                                field.addColumns[1].id_suffix;

                            keys.push(id_name);
                            keys.push(id_value);
                        }
                    }

                    field.totalRow?.map((item: any) => {
                        item.isInput && keys.push(item.id);
                        item.sharable && keys.push(item.sharable);
                        item.allowable && keys.push(item.allowable);
                        item.lesserGreaterId && keys.push(item.lesserGreaterId);
                    });

                    keys.map((key) => {
                        const isLocalChanged = compareItemsforChange(form[key], second[key]);
                        if (isLocalChanged) {
                            isChanged = true;
                        }
                    });

                    break;

                case fieldTypes.CROSSROAD:
                    const isCrossroadLocalChanged = compareItemsforChange(
                        form[field.id],
                        second[field.id],
                    );

                    if (isCrossroadLocalChanged) {
                        isChanged = true;
                    }

                    field.preForm?.map((field) => {
                        if (isBrick(field)) {
                            const isLocalChanged = compareItemsforChange(
                                form[field.id],
                                second[field.id],
                            );

                            if (isLocalChanged) {
                                isChanged = true;
                            }

                            if (isChanged === false) {
                                field.branches?.paths.forEach(({ form: BranchStructure }) => {
                                    if (isChanged) {
                                        return;
                                    }
                                    isChanged = analyzeFormStructure(BranchStructure, form, second);
                                });
                            }
                        }
                    });

                    break;

                default:
                    const isLocalChanged = compareItemsforChange(form[field.id], second[field.id]);
                    if (isLocalChanged) {
                        isChanged = true;
                    }
            }

            if (isChanged === false) {
                field.branches?.paths.forEach(({ form: BranchStructure }) => {
                    if (isChanged) {
                        return;
                    }
                    isChanged = analyzeFormStructure(BranchStructure, form, second);
                });
            }
        }
    });

    return isChanged;
};

const countErrors = (data: any) => {
    let count = 0;

    if (!Array.isArray(data) && typeof data === 'object') {
        Object.keys(data).map((key) => {
            if (!Array.isArray(data[key])) {
                count += countErrors(data[key]);
            } else {
                if (key !== 'id') {
                    count++;
                }
            }
        });
    }

    return count;
};

function arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const serverMetaState = useSelector((state: RootState) => state.taxPrep.serverMetaState);
    const [savePromises, setSavePromises] = useState<
        Array<{ address: string; save: () => Promise<any> }>
    >([]);

    const queryClient = useQueryClient();

    // const { pages, sections } = pageRefiner();

    // const formDispatch = useFormDispatch();

    let {
        year,
        meta,
        formState,
        state,
        serverState,
        setError,
        refreshData,
        getBusinessOptionValue,
        getOptionValue,
        getPageJsonValue,
    } = useTaxPrepEvalData();

    // TODO GOUTAM: remove once serverState is got from above
    // serverState = serverState || {};

    const { pages, pagesRaw } = useTaxPrepStructure();
    const { irs_account_id } = useParams();

    useEffect(() => {
        const saveTimeout = setTimeout(() => {
            if (isSuccess) {
                setIsSuccess(false);
            }
        }, 5000);

        return () => clearTimeout(saveTimeout);
    }, [isSuccess]);

    const getSavePromises = () => {
        let promises: Array<{ address: string; save: () => Promise<any> }> = [];

        if (!state || !serverState) {
            return [];
        }

        /*********************************
         **** A. Finding required save calls
         * 1. Fetch the form Structure
         * 2. Do a deep compare on the JSON and find the pages for all keys which have a change in value.
         * 3. Add the required save functions in the promise array
         *
         **** B. Updating the server
         * 1. Execute the promise array with Promise.allSettled() logic
         * 2. Loop through executions and If any reject is found, extract errors and keep it in temp
         * 3. Keep adding all the errors found for all rejcted eecutions and then set the final result in errors
         * 4. If success, CLears errors and remove the Floating save Component
         *
         *********************************/
        let isMetaChanged = false;

        pagesRaw.forEach(({ address, formStructure, save, businessId }) => {
            if (businessId) {
                const serverBusiness = getTaxPrepBusiness(serverState[year], businessId);
                const businessFormState = getTaxPrepBusiness(state[year], businessId);
                if (!serverBusiness) return;

                const isChanged = analyzeFormStructure(
                    formStructure,
                    businessFormState,
                    serverBusiness,
                );

                if (isChanged) {
                    promises.push({
                        address,
                        save: async () => {
                            const { data } = await save(year, {
                                ...(businessFormState || {}),
                                meta_data: meta,
                            });
                            return data;
                        },
                    });
                } else {
                    const optionValue = getBusinessOptionValue(businessId, address);
                    const serverOptionValue = getBusinessOptionValue(businessId, address, true);
                    const serverPageValue = getPageJsonValue(address, true);
                    const pageValue = getPageJsonValue(address);

                    if (optionValue !== serverOptionValue || serverPageValue !== pageValue) {
                        console.log('Meta Promise', {
                            address,
                            optionValue,
                            serverOptionValue,
                            pageValue,
                            serverPageValue,
                        });
                        isMetaChanged = true;
                    }
                }
            } else {
                const isChanged = analyzeFormStructure(
                    formStructure,
                    state[year],
                    serverState[year],
                );

                if (isChanged) {
                    promises.push({
                        address,
                        save: async () => {
                            const { data } = await save(year, {
                                ...state[year],
                                meta_data: meta,
                            });
                            return data;
                        },
                    });
                } else {
                    const optionValue = getOptionValue(address);
                    const serverOptionValue = getOptionValue(address, true);
                    const serverPageValue = getPageJsonValue(address, true);
                    const pageValue = getPageJsonValue(address);

                    if (optionValue !== serverOptionValue || serverPageValue !== pageValue) {
                        console.log('Meta Promise', {
                            address,
                            optionValue,
                            serverOptionValue,
                            pageValue,
                            serverPageValue,
                        });
                        isMetaChanged = true;
                    }
                }
            }
        });

        if (promises.length === 0 && isMetaChanged) {
            const { address, save } = pagesRaw[0];
            promises.push({
                address,
                save: async () => {
                    const { data } = await save(year, {
                        ...state[year],
                        meta_data: meta,
                    });
                    return data;
                },
            });
        }

        return promises;
    };

    useEffect(() => {
        const promises = getSavePromises();

        if (
            !arraysEqual(
                promises.map(({ address }) => address),
                savePromises.map(({ address }) => address),
            )
        ) {
            setSavePromises(promises);
        }
    }, [formState]);

    const saveData = async () => {
        setError(year, {});
        // formDispatch({ type: 'CLEAR_ERRORS', payload: { data: undefined } });
        setLoading(true);

        const promises = getSavePromises();
        const results = await Promise.allSettled(promises.map(({ save }) => save()));

        //old code

        let errors = {};
        let errorSections: Array<string> = [];

        results.forEach((res, index) => {
            if (res.status === 'rejected') {
                const promise = promises[index];

                errorSections.push(promise.address);
                errors = {
                    ...errors,
                    ...res.reason.response.data.message,
                };
            }
        });

        if (errorSections.length) {
            // Save the errors and errorSections in the formState
            setError(year, { ...errors, _sections: errorSections });
        } else {
            refreshData();
            setIsSuccess(true);
            setSavePromises([]);
        }

        setLoading(false);
        localStorage.removeItem(`tax-prep.${year}.${formState?.id}`);
        localStorage.removeItem(`tax-prep-meta.${year}.${formState?.id}`);
    };

    console.log('Promises', savePromises);

    const show = !!savePromises.length;
    const errorCount = countErrors(formState._errors);

    return show ? (
        <div
            className={classNames(
                'flex flex-col items-center max-w-4xl bg-white w-full cursor-pointer rounded-md fixed bottom-2  border-b-2 border-t-2 border-gray-300 justify-center',
            )}
            style={{ boxShadow: '2px -5px 10px #ddd', minWidth: 550, minHeight: 48 }}
        >
            {isSuccess ? (
                <div>{`Changes saved successfully`}</div>
            ) : (
                <div className="w-full flex px-4 py-1 items-center">
                    <div className="flex-grow">{`You have unsaved changes, save once, after making all relevant changes`}</div>
                    <div
                        onClick={() => !loading && saveData()}
                        className="px-3 py-2 bg-primary-500 cursor-pointer hover:bg-primary-700 text-sm text-white font-title"
                    >
                        {loading ? `SAVING...` : `SAVE`}
                    </div>
                </div>
            )}
            {!!Object.keys(formState._errors).length && (
                <div className="w-full text-xs font-bold bg-red-100 text-red-600 px-3 py-1">
                    {formState._errors['crash']
                        ? 'Something went wrong'
                        : `Please fix ${errorCount} error(s) to proceed`}
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};
