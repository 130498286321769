import { apiTaxPrep } from '../../api/tax-prep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

export const upsertDividentsIncome = (
    year: string,
    { divident_income_data, meta_data, irs_account_id, id }: any,
) => {
    const regEx = /^new/;
    const data = divident_income_data
        ?.map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id: irs_account_id, taxprep_account_id: id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    return apiTaxPrep.post(`/dividentIncomeByActionTP`, {
        irs_account_id: meta_data.irs_account_id,
        meta_data,
        data: data ? data : [],
        taxprep_year: year,
    });
};

const dividentsForm: FormInterface = [
    { type: fieldTypes.SEPERATOR, heading: 'Dividents Income' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'payer_name',
        label: 'Name of Payer',
        placeholder: 'john',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'amount',
        label: 'Enter Amount',
        placeholder: 'john',
    },
    // {
    //     type: fieldTypes.RADIO,
    //     id: 'financial_interest',
    //     label: 'At anytime during 2022, did you have a financial interest in or signature authority over a financial account  ( such as a bank account, security account, or brokerage account) located in a forien country?',
    //     options: [
    //         { value: '1', label: 'Yes' },
    //         { value: '0', label: 'No' },
    //     ],
    // },
];

const dividentsSecForm: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'divident_income_data',
        label: 'Do you have Ordinary Dividents?',
        header: 'Dividents Data',
        displayColumns: [
            {
                name: 'Name of Payer',
                key: 'payer_name',
            },
            {
                name: 'Amount',
                key: 'amount',
            },
        ],
        formStructure: dividentsForm,
    },
];

export const page: PageInputInterface = {
    address: 'divident_income_data',
    name: 'Dividents Income Information',
    sectionId: enumSections.FINANCIAL_INFORMATION,

    formStructure: dividentsSecForm,
    save: upsertDividentsIncome,
};
