import React, { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import ms_logo from '../../assets/ms_logo.png';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = ({ method }: { method?: string }) => {
    const handleLogin = async (instance: any) => {
        const data = await instance.loginPopup(loginRequest);
        return data;
    };
    const { instance } = useMsal();

    const navigate = useNavigate();

    //after authentication from ms sso redirect to callback for ms sso login
    const login = useMutation(handleLogin, {
        onSuccess: (data) => {
            navigate('/callback');
        },
    });

    return (
        <>
            <button
                style={{ borderRadius: '4px' }}
                className="ml-auto flex justify-center items-center w-full py-2 px-4 font-medium text-gray-700 border-gray-300 border pointer hover:bg-gray-100"
                onClick={() => login.mutate(instance)}
            >
                <img src={ms_logo} className="w-5 h-5 mr-2" alt="Microsoft Logo" />
                <div>
                    {method === 'sign-in' ? `Sign in with Microsoft` : `Sign up with Microsoft`}
                </div>
            </button>
        </>
    );
};
