import { useState } from 'react';
import { fieldTypes } from '../utils/enums';
import { Navigate, useNavigate } from 'react-router-dom';
import InputGroup from '../components/client/InputGroup';
import Button from '../components/basic/Button';
import { useAuthDispatch, useAuthState } from '../context/auth';
import { forgotPassword } from '../api/login';
import { useMutation } from 'react-query';
import classNames from 'classnames';
import { build } from '../config';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setErrors] = useState<{ [key: string]: any } | string>();

    const navigate = useNavigate();
    const authDispatch = useAuthDispatch();
    const { success, role } = useAuthState();

    const {
        mutate: forgotUserPassword,
        isLoading,
        isSuccess,
    } = useMutation(forgotPassword, {
        onError: (error: any) => {
            setErrors(error.response.data.message);
        },
    });

    if (success) {
        switch (role) {
            case 'Client':
                return <Navigate to="/home" />;

            case 'Admin':
                return <Navigate to="/taxprep" />;

            case 'Tax Preparer':
                return <Navigate to="/client" />;

            default:
                return <div>{`Loggedin with role ${role}`}</div>;
        }
    }

    return (
        <div className="w-full h-screen flex">
            <div
                className="flex-grow bg-primary-200 max-w-xs"
                style={{
                    backgroundImage: 'url(/menu_pattern.png)',
                    backgroundSize: '400px 400px',
                }}
            />
            <div className="flex-grow flex justify-center items-center" style={{ flexGrow: 2 }}>
                <div className="max-w-md w-full flex flex-col items-center">
                    <div className="font-title flex bg-white justify-center items-center z-20">
                        {/* <div className="h-6 w-6 rounded-md bg-gray-300 mr-3"></div>
                        <div>COMPANY</div> */}
                        <img
                            src={build.logo_dark}
                            className={classNames('h-16 z-20 bg-white  mb-8 ')}
                            style={{ padding: 1 }}
                        />
                    </div>

                    {isSuccess ? (
                        <div className="w-full px-3 py-2 my-3 bg-primary-100 text-sm">
                            {`New Credentials are sent to your email address, click on login below to login using new credentials`}
                        </div>
                    ) : (
                        <>
                            <div className="w-full px-3 py-2 my-3 bg-primary-100 text-sm">
                                {`Enter your email down below, if exists, new credentials will be generated and sent to your email`}
                            </div>

                            {typeof error === 'string' && (
                                <div className="w-full bg-red-100 text-red-600 font-bold text-xs px-3">
                                    {error}
                                </div>
                            )}
                            <InputGroup
                                id="email"
                                label="Email"
                                placeholder=""
                                value={email}
                                setValue={(value) => setEmail(value)}
                                type={fieldTypes.TEXT_INPUT}
                                className="w-full"
                                onEnter={(u_email) => forgotUserPassword({ u_email })}
                            />
                            {typeof error === 'object' && (
                                <div className="w-full bg-red-100 text-red-600 font-bold text-xs px-3">
                                    {error?.u_email?.[0]}
                                </div>
                            )}

                            <Button
                                isLoading={isLoading}
                                onClick={() => forgotUserPassword({ u_email: email })}
                                className="text-center w-full mt-2"
                            >
                                {'RECIEVE NEW CREDENTIALS'}
                            </Button>
                        </>
                    )}
                    {/* <div className="text-2xl font-title font-medium mb-16 mt-2">TAX PROFILE</div> */}

                    <div
                        onClick={() => {
                            navigate('/login');
                        }}
                        className="text-primary-500 text-sm mt-6 cursor-pointer hover:text-primary-700 hover:underline"
                    >
                        Login
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
