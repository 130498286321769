import { useQuery } from 'react-query';
import { apiTaxPrep } from '../../api/tax-prep';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';

const useAllBusinesses = (irs_account_id: string | undefined) => {
    const { year } = useTaxPrepStructure();

    const get = async (): Promise<any> => {
        // Fetch client data

        // Fetch business data for the client
        const { data: businessData } = await apiTaxPrep.get('/clientWithAllBusiness', {
            params: { irs_account_id },
        });

        // Return merged data and metadata
        return businessData.data;
    };

    // Query to manage fetching and updating client data
    const queryReturn = useQuery({
        queryKey: ['get-client-data-', year, 'business'],
        queryFn: get,
        staleTime: Infinity,
        enabled: !!irs_account_id,
    });

    return queryReturn;
};

export default useAllBusinesses;
