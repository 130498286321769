import React, { useState, useEffect, useRef } from 'react';

const FiltersDropdown: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="relative inline-block">
            <div
                className={`flex w-40 text-center cursor-pointer py-1 justify-center border-2 rounded border-primary-500 gap-1 ${
                    isOpen ? 'bg-primary-500 text-white' : ''
                }`}
                onClick={toggleDropdown}
            >
                <span
                    className={`material-symbols-outlined  text-primary-500 ${
                        isOpen ? 'text-white' : 'text-primary-500'
                    }`}
                >
                    filter_alt
                </span>
                <span
                    className={`font-semibold text-primary-500 ${
                        isOpen ? 'text-white' : 'text-primary-500'
                    }`}
                >
                    {'Filters'}
                </span>
            </div>
            {isOpen && (
                <div className="absolute z-10 bg-white mt-2 rounded shadow-md">
                    <div className="flex items-center w-full px-4 py-2 border-b border-gray-300">
                        <input type="checkbox" id="powerOfAttorney" className="mr-2" />
                        <label className="text-xs" htmlFor="powerOfAttorney">
                            Power of Attorney
                        </label>
                    </div>
                    <div className="flex items-center w-full px-4 py-2 border-b border-gray-300">
                        <input type="checkbox" id="resolutionForms" className="mr-2" />
                        <label className="text-xs" htmlFor="resolutionForms">
                            Resolution Forms
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FiltersDropdown;
