import { upsertRealProperty } from '../../api/finance';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const realPropertyForm: FormInterface = [
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.DROPDOWN,
        id: 'owner',
        label: 'Owner',
        placeholder: 'John Smith',
        options: [
            { value: 'Taxpayer', label: 'Taxpayer' },
            { value: 'Spouse', label: 'Spouse' },
            { value: 'Both', label: 'Both' },
        ],
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'property_type',
        label: 'Type',
        placeholder: 'Car',
        options: [
            { value: 'Primary', label: 'Primary' },
            { value: 'Leased', label: 'Leased' },
            { value: 'Investment', label: 'Investment' },
            { value: 'Rental', label: 'Rental/Mortgage' },
            { value: 'Land', label: 'Land' },
            { value: 'Commercial', label: 'Commercial' },
        ],
    },
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        span: 2,
        id: 'property_description',
        label: 'Description',
        placeholder: 'Villa',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.CALENDAR,
        id: 'purchase_lease_date',
        label: 'Purchase Date',
        placeholder: '23 Aug 2021',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'purchase_price',
        label: 'Purchase Price',
        placeholder: '$33,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'year_refinanced',
        label: 'Year Refinanced',
        placeholder: '2012',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'refinance_amount',
        label: 'Refinance Amount',
        placeholder: '2012',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Fair Market Value',
        placeholder: '',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_loan_balance',
        label: 'Loan Balance',
        placeholder: '$100,000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'monthly_payment',
        label: 'Monthly Payment Amount',
        placeholder: '$100,000',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'final_payment_date',
        label: 'Date of Final Payment',
        placeholder: '23 Aug 2021',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'how_title_is_held',
        label: 'How is title held?',
        placeholder: 'Voting',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Property Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: '$300,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_city',
        label: 'City',
        placeholder: '$300,000',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'property_state',
        label: 'State',
        placeholder: '$300,000',
        options: usa_states,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_zip',
        label: 'ZIP',
        placeholder: '$300,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_county',
        label: 'County',
        placeholder: '$300,000',
    },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_name',
        label: 'Lender / Lessor / Landlord Name',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_phone',
        label: 'Lender Phone',
        placeholder: 'Florida',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Lender Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'lender_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'real_estate_data',
        label: 'Do you have any Real Estate?',
        header: 'Real Estate Data',
        formStructure: realPropertyForm,
        displayColumns: [
            {
                name: 'Owner',
                key: 'owner',
            },
            {
                name: 'Property Type',
                key: 'property_type',
            },
            {
                name: 'Property Description',
                key: 'property_description',
            },
        ],
        preForm: [
            {
                type: fieldTypes.RADIO,
                id: 'real_property_fund_offer',
                label: 'Is your real property currently for sale or do you anticipate selling your real property to fund the offer amount? (OIC Only)',
                options: [
                    { label: 'Yes', value: 'y' },
                    { label: 'No', value: 'n' },
                ],
                span: 2,
            },
            {
                type: fieldTypes.AMOUNT_INPUT,
                id: 'real_property_fund_offer_amount',
                placeholder: 'Listing Price',
                label: 'Listing Price',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'real_property',
    name: 'Real Property',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertRealProperty,
};
