import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { enumSections, fieldTypes } from '../utils/enums';
import NavBar from '../components/basic/NavBar';
import { useAuthState } from '../context/auth';
import { calculatePercentage, useCompletedPercentage } from '../utils/helpers';
import { useFormState } from '../context/form';
import moment from 'moment';
import useFormsList from '../api/tax-prep/useFormsList';
import Modal from '../components/basic/Modal';
import { useEffect, useState } from 'react';
import Button from '../components/basic/Button';
import InputGroup from '../components/client/InputGroup';
import { useMutation, useQueryClient } from 'react-query';
import { showToast } from '../components/basic/Toaster';
import { addNewTaxPrepYear, deleteTaxPrepYear } from '../api/admin';
import { build } from '../config';

const AddYearCard: React.FC<{ close: () => void; user: any }> = ({ close, user }) => {
    const [year, setYear] = useState<number>();
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const queryClient = useQueryClient();
    const { token } = useAuthState();

    const mutation = useMutation(addNewTaxPrepYear, {
        onSuccess: () => {
            queryClient.invalidateQueries('TaxprepList');
            showToast('Year has been Added !', 'success');
            close();
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.message, 'error');
            setErrors(error.response.data.message);
        },
    });

    const handleSubmit = () => {
        if (!token) return;
        if (!year) return;

        mutation.mutate({
            irs_account_id: user,
            years: [year],
        });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`ADD YEAR`}</div>
            <InputGroup
                id="year"
                label="Add Year"
                placeholder=""
                value={year}
                setValue={(value) => {
                    setYear(value);
                    setErrors({});
                }}
                type={fieldTypes.TEXT_INPUT}
                className="w-full mt-1 mb-6"
                staticError={error.u_ptin}
            />
            <div className="flex w-full">
                <Button
                    onClick={handleSubmit}
                    isLoading={mutation.isLoading}
                    className="text-center flex-grow mr-2"
                >
                    {'ADD YEAR'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const DeleteYearCard: React.FC<{ close: () => void; user: any; deleteYear: any }> = ({
    close,
    user,
    deleteYear,
}) => {
    const [year, setYear] = useState<number>();
    const [error, setErrors] = useState<{ [key: string]: any }>({});
    const queryClient = useQueryClient();
    const { token } = useAuthState();

    const mutation = useMutation(deleteTaxPrepYear, {
        onSuccess: () => {
            queryClient.invalidateQueries('TaxprepList');
            showToast('Year has been Deleted!', 'success');
            close();
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.message, 'error');
            setErrors(error.response.data.message);
        },
    });

    const handleSubmit = () => {
        if (!token) return;
        mutation.mutate({ tax_id: deleteYear?.tax_id });
    };

    return (
        <div className="bg-white px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="font-title py-4">{`DELETE YEAR`}</div>
            <div className="mb-2">
                Are you sure you want to delete Year
                <span className="font-semibold">{deleteYear?.year}</span>
            </div>
            <div className="flex w-full">
                <Button
                    onClick={handleSubmit}
                    isLoading={mutation.isLoading}
                    className="text-center flex-grow mr-2"
                >
                    {'DELETE YEAR'}
                </Button>
                <Button onClick={close} secondary className="text-center flex-grow ml-2">
                    {'CANCEL'}
                </Button>
            </div>
        </div>
    );
};

const HomeScreen: React.FC<{}> = ({}) => {
    const { name, last_login, irs_account_id } = useAuthState();
    const navigate = useNavigate();
    const { meta_data } = useFormState();
    const [modal, setModal] = useState(false);
    const [deleteModal, setdeleteModal] = useState(false);
    const [deleteYear, setDeleteYear] = useState({ year: '', tax_id: '' });
    const { isSuccess, isLoading, data } = useFormsList({
        irs_account_id: irs_account_id,
    });

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');
    const queryClient = useQueryClient();

    //refetching query everytime component loads for fresh data
    useEffect(() => {
        queryClient.invalidateQueries('TaxprepList');
    }, []);

    const completePercentage = useCompletedPercentage(irs_account_id);

    if (isLoading) {
        return (
            <div>
                {!iframe && <NavBar />}
                <div
                    style={{ alignItems: 'center' }}
                    className="h-screen flex justify-center text-justify align-middle"
                >
                    <div>Loading...</div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col w-full h-screen bg-gray-100">
                {!iframe && <NavBar />}
                <div className="w-full h-full" style={{ paddingLeft: '4%', paddingRight: '4%' }}>
                    <div className="font-title ml-3 text-2xl font-semibold text-gray-400 mt-16">
                        Hello, {name}
                    </div>
                    {build.taxprep_module && data?.Taxpro.length ? (
                        <>
                            <div>
                                <div className="font-title ml-3 text-xl font-semibold text-gray-400 mt-5">
                                    Tax Resolution
                                </div>
                                {data?.Taxpro.map((data: any) => {
                                    return (
                                        <div className="ml-3 bg-white rounded-lg shadow-sm w-96 mt-5 ">
                                            <div className="flex w-full items-end p-4">
                                                <div className="font-title font-medium flex-grow">
                                                    Tax Profile
                                                </div>
                                                <div className="font-body font-medium text-sm">{`${completePercentage}% Completed`}</div>
                                            </div>
                                            <div className="w-full relative">
                                                <div className="h-2 bg-gray-200 w-full" />
                                                <div
                                                    className="absolute left-0 top-0 h-2 bg-primary-500"
                                                    style={{ width: `${completePercentage}%` }}
                                                />
                                            </div>

                                            <div className="w-full p-4 mt-1">
                                                <div className="font-body text-sm">{`Last Update: ${moment(
                                                    data?.last_updated_date,
                                                ).format('DD MMM YYYY, hh:mm a')}`}</div>
                                                <div className="font-body">
                                                    {meta_data?.section_status}
                                                </div>
                                            </div>

                                            <div className="w-full  flex">
                                                <Link
                                                    to={
                                                        iframe
                                                            ? '/iframe-form/personal_information'
                                                            : '/form/personal_information'
                                                    }
                                                    className="text-left flex-grow hover:bg-gray-50 text-primary-500 p-4"
                                                >
                                                    <div className="font-title font-medium ">{`CONTINUE`}</div>
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="font-title ml-3 text-xl font-semibold text-gray-400 mt-5">
                                Tax Resolution
                            </div>
                            <div className="rounded-md ml-3 bg-white w-96 mt-5 shadow-lg">
                                <div className="flex w-full items-end p-4">
                                    <div className="font-title font-medium flex-grow">
                                        Tax Profile
                                    </div>
                                    <div className="font-body font-medium text-sm">{`${completePercentage}% Completed`}</div>
                                </div>
                                <div className="w-full relative">
                                    <div className="h-2 bg-gray-200 w-full" />
                                    <div
                                        className="absolute left-0 top-0 h-2 bg-primary-500"
                                        style={{ width: `${completePercentage}%` }}
                                    />
                                </div>

                                <div className="w-full p-4 mt-1">
                                    <div className="font-body text-sm">{`Last Update: ${moment(
                                        last_login,
                                    ).format('DD MMM YYYY, hh:mm a')}`}</div>
                                    <div className="font-body">{meta_data?.section_status}</div>
                                </div>

                                <div className="w-full  flex">
                                    <Link
                                        to={
                                            iframe
                                                ? '/iframe-form/personal_information'
                                                : '/form/personal_information'
                                        }
                                        className="text-left flex-grow hover:bg-gray-50 text-primary-500 p-4"
                                    >
                                        <div className="font-title font-medium ">{`CONTINUE`}</div>
                                    </Link>
                                    {/* <Link
                                to="/summary"
                                className="text-right flex-grow hover:bg-gray-50 text-primary-500 p-4"
                            >
                                <div className="font-title font-medium ">{`SUMMARY`}</div>
                            </Link> */}
                                </div>
                            </div>
                        </>
                    )}
                    {build.taxprep_module && data?.taxprep && (
                        <>
                            <div className="flex gap-5">
                                <div className="font-title ml-3 text-xl font-semibold text-gray-400 mt-5">
                                    Tax Prep
                                </div>
                                <div className="font-title mt-4 text-xl font-medium text-gray-800 ">
                                    {/* <Button
                                        onClick={() => setModal(true)}
                                        className="text-sm text-center w-32"
                                    >
                                        {`ADD YEAR`}
                                    </Button> */}
                                </div>
                            </div>
                            <div className="flex justify-start flex-wrap">
                                {isSuccess &&
                                    !isLoading &&
                                    data &&
                                    data?.taxprep.map((card: any, key: number) => {
                                        return (
                                            <div
                                                key={key}
                                                className="rounded-md ml-3 justify-between bg-white w-full sm:w-96 mt-5 shadow-lg"
                                            >
                                                <div className="flex w-full items-end p-4">
                                                    <div className="font-title font-medium flex-grow">
                                                        Year {card.taxprep_year}
                                                    </div>
                                                    <div className="font-body font-medium text-sm">{`${card?.percentage_complete}% Completed`}</div>
                                                </div>
                                                <div className="w-full relative">
                                                    <div className="h-2 bg-gray-200 w-full" />
                                                    <div
                                                        className="absolute left-0 top-0 h-2 bg-primary-500"
                                                        style={{
                                                            width: `${card?.percentage_complete}%`,
                                                        }}
                                                    />
                                                </div>

                                                <div className="w-full flex justify-between p-4 mt-1">
                                                    <div>
                                                        <div className="font-body text-sm">{`Last Updated: ${moment(
                                                            card?.last_updated_date,
                                                        ).format('DD MMM YYYY, hh:mm a')}`}</div>
                                                        <div className="font-body">
                                                            {meta_data?.section_status}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {/* <span
                                                            className="cursor-pointer  material-icons text-gray-400 hover:text-red-400"
                                                            onClick={() => {
                                                                setdeleteModal(true);
                                                                setDeleteYear({
                                                                    year: card.taxprep_year,
                                                                    tax_id: card.id,
                                                                });
                                                            }}
                                                        >
                                                            delete_forever
                                                        </span> */}
                                                    </div>
                                                </div>

                                                <div className="w-full flex flex-col sm:flex-row">
                                                    <div className="text-left flex-grow hover:bg-gray-50 text-primary-500 p-4 cursor-pointer">
                                                        <div
                                                            className="font-title font-medium "
                                                            onClick={() => {
                                                                navigate(
                                                                    `/test/tax-prep/personal_information/${card.taxprep_year}`,
                                                                );
                                                                sessionStorage.setItem(
                                                                    'tax_id',
                                                                    card.id,
                                                                );
                                                            }}
                                                        >{`CONTINUE`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Modal close={() => setModal(false)} isOpen={!!modal}>
                <AddYearCard user={irs_account_id} close={() => setModal(false)} />
            </Modal>
            <Modal close={() => setdeleteModal(false)} isOpen={!!deleteModal}>
                <DeleteYearCard
                    deleteYear={deleteYear}
                    user={irs_account_id}
                    close={() => setdeleteModal(false)}
                />
            </Modal>
        </>
    );
};

export default HomeScreen;
