import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { formStateStore, metaStateStore } from '../../stateManager/taxPreparerSlice';

export const useAdminLocalStorage = ({ id, year }: { year: string; id: string }) => {
    const dispatch = useDispatch();
    const itemId = `tax-prep.${year}.${id}`;
    const itemMetaId = `tax-prep-meta.${year}.${id}`;
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [localData, setLocalDataValue] = useState(() => {
        try {
            // Get from local storage by key
            const storage = localStorage.getItem(itemId);
            // Parse stored json or if none return initialValue
            return storage ? JSON.parse(storage) : undefined;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return undefined;
        }
    });

    const [localMetaData, setLocalMetaDataValue] = useState(() => {
        try {
            // Get metadata from local storage by key
            const storage = localStorage.getItem(itemMetaId);
            // Parse stored json or if none return undefined
            return storage ? JSON.parse(storage) : undefined;
        } catch (error) {
            // If error also return undefined
            console.log(error);
            return undefined;
        }
    });

    const setLocalData = (value: any) => {
        console.log('setting value local : ', { value, itemId: id });
        try {
            localStorage.setItem(itemId, JSON.stringify(value));
            setLocalDataValue(value);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    const setLocalMetaData = (value: any) => {
        console.log('setting value local : ', { value, itemId: id });
        try {
            localStorage.setItem(itemMetaId, JSON.stringify(value));
            setLocalMetaDataValue(value);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    const clearLocalStorage = () => {
        try {
            localStorage.removeItem(itemId);
            localStorage.removeItem(itemMetaId);
            setLocalDataValue(undefined);
            setLocalMetaDataValue(undefined);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };


    const hydrateFormState = () => {
        try {
            localData && dispatch(formStateStore({ data: localData }));
            localMetaData && dispatch(metaStateStore({ state: localMetaData }));
            setLocalDataValue(undefined);
            setLocalMetaDataValue(undefined);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return { localData, localMetaData, setLocalData, setLocalMetaData, clearLocalStorage, hydrateFormState } as const;
};

export default useAdminLocalStorage;
