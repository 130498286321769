import { upsertRealProperty } from '../../api/business';
import { build } from '../../config';
import { Investment_Property, usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const propertyForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_description',
        label: 'Property Description',
        area: true,
        span: 2,
        placeholder: 'Bitcoin',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.CALENDAR,
        id: 'purchase_lease_date',
        label: 'Purchase Lease Date',
        placeholder: '1000',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_value',
        label: 'Current Fair Market Value (FMV)',
        placeholder: '1000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_loan_balance',
        label: 'Current Loan Balance',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'monthly_payment',
        label: 'Amount of Monthly Payment',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'final_payment_date',
        label: 'Date of Final Payment',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Property Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'property_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'property_county',
        label: 'Property County',
        placeholder: 'johndoe@hotmail.com',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_name',
        label: 'Lender / Lessor / Landlord Name',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_phone',
        label: 'Lender Phone',
        placeholder: 'Florida',
        formatFunction: phone,
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'property_type',
        label: 'Property Type',
        options: Investment_Property,
        placeholder: 'Florida',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Lender Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_address',
        label: 'Address',
        span: 2,
        area: true,
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_city',
        label: 'City',
        placeholder: 'Tampa Bay',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'lender_state',
        label: 'State',
        options: usa_states,
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_zip',
        label: 'ZIP',
        placeholder: 'johndoe@hotmail.com',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        preForm: [
                  {
                      type: fieldTypes.RADIO,
                      id: 'real_property_fund_offer',
                      label: 'Is this real property currentlty for sale or do they anticipate selling the real property to fund the offer amount?(OIC Only)',
                      options: [
                          { label: 'Yes', value: 'y' },
                          { label: 'No', value: 'n' },
                      ],
                      span: 2,

                      branches: {
                          paths: [
                              {
                                  value: 'y',
                                  form: [
                                      {
                                          type: fieldTypes.AMOUNT_INPUT,
                                          id: 'real_property_fund_offer_amount',
                                          label: 'Listing Price',
                                          placeholder: '',
                                      },
                                  ],
                              },
                          ],
                          inline: true,
                      },
                  },
              ],
        id: 'business_real_property_data',
        label: 'Does your business have any Real Property?',
        header: 'Business Real Property',
        formStructure: propertyForm,
        displayColumns: [
            {
                name: 'Property Description',
                key: 'property_description',
            },
            {
                name: 'Current Fair Market Value',
                key: 'current_value',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'business_real_property',
    name: 'Business Real Property',

    sectionId: enumSections.BUSINESS_INFORMATION,
    formStructure: form,

    save: upsertRealProperty,
};
