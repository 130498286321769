import React from 'react';

interface PropTypes {
    id: string;
    value: number | boolean;
    setValue(value: any): void;
    label: string;
}

const GreenSwitchToggle: React.FC<PropTypes> = ({ value, setValue, label, id }) => {
    const handleToggle = () => {
        const newValue = value === 1 ? 0 : 1;
        setValue(newValue);
    };

    return (
        <div className="py-2">
            <label className="flex items-center py-3 justify-between cursor-pointer">
                <div className=" text-gray-700 font-medium">{label}</div>
                <div className="relative mr-3">
                    <input
                        type="checkbox"
                        id={id}
                        className="sr-only"
                        checked={value === 1}
                        onChange={handleToggle}
                    />
                    <div
                        className={`block shadow-inner bg-gray-300 w-14 h-8 rounded-full transition ${
                            value === 1 ? 'bg-green-400' : 'bg-gray-100'
                        }`}
                    ></div>
                    <div
                        className={`dot absolute left-1 top-1 shadow bg-white w-6 h-6 rounded-full transition ${
                            value === 1 ? 'transform translate-x-full bg-gray-200' : 'bg-white'
                        }`}
                    ></div>
                </div>
            </label>
        </div>
    );
};

export default GreenSwitchToggle;
