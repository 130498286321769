import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserCredentials } from '../api/login';
import { useAuthDispatch, useAuthState } from '../context/auth';
import HomeScreen from './HomeScreen';

const AutoLoginClient = () => {
    const { success, role, loading } = useAuthState();
    const { access_token } = useParams();
    const dispatch = useAuthDispatch();

    const autoLogin = async () => {
        if (!access_token) {
            throw 'Auto Login failed';
        }

        return await getUserCredentials(access_token);
    };

    useQuery('auto-login', autoLogin, {
        staleTime: 60 * 60 * 1000 * 18,
        enabled: !!access_token,
        onSuccess: (data) => {
            dispatch('LOGIN', data);
        },
        onError: () => {
            dispatch('LOGOUT', null);
        },
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (success) {
            switch (role) {
                case 'Client':
                    navigate('/iframe-form/personal_information');
                    return;

                // case 'Admin':
                //     navigate('/taxprep');
                //     return;

                // case 'Tax Preparer':
                //     navigate('/client');
                //     return;

                default:
                    return;
            }
        }
    }, [success]);

    return loading ? (
        <div className="w-full h-full text-center">Loading...</div>
    ) : success ? (
        <div className="w-full h-full text-center">Finishing up...</div>
    ) : (
        <div className="w-full h-full text-center">Error Encountered. Please reload the page</div>
    );
};

export default AutoLoginClient;
