import { upsertDependents } from '../../api/yourInfo';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface, PageInterface } from '../../utils/interfaces';

const dependentsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'first_name',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'last_name',
        label: 'Last Name',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'birth_dt',
        label: 'Date of Birth',
        placeholder: '',
        restrict: 'Future',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'age',
        label: 'Age',
        placeholder: '',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'relationship',
        label: 'Relationship',
        placeholder: 'Son',
        options: [
            { label: 'Child', value: 'Child' },
            { label: 'Son', value: 'Son' },
            { label: 'Daughter', value: 'Daughter' },
            { label: 'Parent', value: 'Parent' },
            { label: 'Grandparent', value: 'Grandparent' },
            { label: 'Stepson', value: 'Stepson' },
            { label: 'StepDaughter', value: 'StepDaughter' },
            { label: 'Niece', value: 'Niece' },
            { label: 'Nephew', value: 'Nephew' },
            { label: 'Other', value: 'Other' },
        ],
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.RADIO,
        id: 'claimed_as_dependent',
        label: 'Claimed on Current Year Tax Return?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
    {
        type: fieldTypes.RADIO,
        id: 'contributes_to_household_income',
        label: 'Contributes to household income?',
        options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
        ],
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'dependent_data',
        label: 'Do you have any dependents?',
        header: 'Dependent Details',
        displayColumns: [
            {
                name: 'First Name',
                key: 'first_name',
            },
            {
                name: 'Last Name',
                key: 'last_name',
            },
            {
                name: 'Relationship',
                key: 'relationship',
            },
        ],
        formStructure: dependentsForm,
    },
];

export const page: PageInputInterface = {
    address: 'dependents',
    name: 'Dependents',

    sectionId: enumSections.YOUR_INFORMATION,
    formStructure: form,
    save: upsertDependents,
};
