import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

import NavBar from '../NavBar';
import useFormsList from '../../../api/tax-prep/useFormsList';
import { Table } from './Tables/Table';
import classNames from 'classnames';
import FiltersDropdown from './components/FiltersDropdown';
import FormDownload from './FormDownload';
import Layout from './layout/UserDashLayout';

export const UserIrsForm: React.FC<{
    setActiveForm: React.Dispatch<React.SetStateAction<{ tab: number; selectedForm: string }>>;
    activeForm: { tab: number; selectedForm: string };
}> = ({ setActiveForm, activeForm }) => {
    const data = [
        {
            id: 1,
            form: 'Form 28246',
            category: 'Resolution Forms',
            description: 'Power of Attorney and Declaration of Representative',
        },
        {
            id: 2,
            form: 'Form 433-A',
            category: 'Resolution Forms',
            description:
                'Collection Information Statement for Wage Earners and Self-Employed Individuals',
        },
        {
            id: 3,
            form: 'Form 433-A(OIC)',
            category: 'Power Of Attorney',
            description:
                'Collection Information Statement for Wage Earners and Self-Employed Individuals',
        },
        {
            id: 4,
            form: 'Form 8821',
            category: 'Resolution Forms',
            description: 'Tax Information Authorization',
        },
        {
            id: 5,
            form: 'Form 433-B',
            category: 'Resolution Forms',
            description: 'Collection Information Statement for Businesses',
        },
        {
            id: 6,
            form: 'Form 433-D',
            category: 'Resolution Forms',
            description: 'Installment Agreement',
        },
        {
            id: 7,
            form: 'Form 433-F',
            category: 'Resolution Forms',
            description: 'Collection Information Statement',
        },
        {
            id: 8,
            form: 'Form 656',
            category: 'Power Of Attorney',
            description: 'Offer in Compromise',
        },
        {
            id: 9,
            form: 'Form 8821',
            category: 'Power Of Attorney',
            description: 'Tax Information Authorization',
        },
    ];

    const irsFormsColumns: Array<any> = [
        {
            Header: () => (
                <div
                    style={{
                        fontStyle: 'normal',
                        lineHeight: ' normal',
                    }}
                    className="text-left pl-4 py-2 text-base font-semibold font-body"
                >
                    Form
                </div>
            ),

            accessor: 'form',
            Cell: ({ value }: any) => {
                return (
                    <div
                        onClick={() => {
                            setActiveForm({ tab: 2, selectedForm: value });
                        }}
                        className="text-left pl-4 "
                        style={{ minWidth: '150px' }}
                    >
                        <Link to="" className="underline text-blue-700 font-normal">
                            {value}
                        </Link>
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div
                    style={{
                        fontStyle: 'normal',
                        lineHeight: ' normal',
                    }}
                    className="text-left text-base font-semibold font-body"
                >
                    Category
                </div>
            ),

            accessor: 'category',
            Cell: ({ value, row }: any) => {
                return (
                    <div
                        onClick={() => {
                            setActiveForm({ tab: 2, selectedForm: row.original.form });
                        }}
                        className="text-left m-1"
                        style={{ minWidth: '150px' }}
                    >
                        <div
                            className={classNames(
                                'py-1 px-3 rounded-lg w-40 text-center text-sm font-medium',
                                {
                                    'text-red-500 bg-red-100':
                                        value.toLowerCase() === 'power of attorney',
                                    'text-blue-800 bg-blue-100':
                                        value.toLowerCase() === 'resolution forms',
                                },
                            )}
                        >
                            {value}
                        </div>
                    </div>
                );
            },
        },

        {
            Header: () => (
                <div
                    style={{
                        fontStyle: 'normal',
                        lineHeight: ' normal',
                    }}
                    className="text-left text-base font-semibold font-body"
                >
                    Description
                </div>
            ),

            accessor: 'description',
            Cell: ({ value, row }: any) => {
                console.log(row, 'row.original.form');
                return (
                    <div
                        onClick={() => {
                            setActiveForm({ tab: 2, selectedForm: row.original.form });
                        }}
                        className="w-full text-left text-base font-light "
                    >
                        {value}
                    </div>
                );
            },
        },
    ];
    console.log('activeForm', activeForm);
    // const FormContent: React.FC = () => {
    switch (activeForm.tab) {
        case 1:
            return (
                <div className="px-4 pb-10">
                    <Table data={data} columns={irsFormsColumns} />
                </div>
            );
        case 2:
            return <FormDownload form={activeForm.selectedForm} />;
        default:
            return null;
    }
};

const UserForms: React.FC<{}> = ({}) => {
    const { irs_account_id } = useParams();
    const [transcript, setTranscript] = useState(1);
    const [activeForm, setActiveForm] = useState({ tab: 1, selectedForm: '' });

    const { isLoading } = useFormsList({
        irs_account_id: irs_account_id,
    });

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    if (isLoading) {
        return (
            <div>
                {!iframe && <NavBar />}
                <div
                    style={{ alignItems: 'center' }}
                    className="h-screen flex justify-center text-justify align-middle"
                >
                    <div>Loading...</div>
                </div>
            </div>
        );
    }

    const forms = [
        { id: 1, form: 'IRS Forms' },
        { id: 2, form: 'State Forms' },
    ];

    const RenderComponent: React.FC = () => {
        switch (transcript) {
            case 1:
                return <UserIrsForm activeForm={activeForm} setActiveForm={setActiveForm} />;
            case 2:
                return <UserIrsForm activeForm={activeForm} setActiveForm={setActiveForm} />;
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col w-full h-screen overflow-y-scroll bg-gray-100">
            {!iframe && <NavBar />}
            <div className="w-full h-full" style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                <div>
                    <div className="col-span-3">
                        {' '}
                        <div className=" ml-3 bg-white  shadow-sm mt-5  relative">
                            {/* new code */}

                            <div className=" flex flex-col  border-0 border-[#CCCCCC] pb-10 ">
                                <div className=" flex flex-col  items-start">
                                    <div className="flex justify-start gap-3 ">
                                        {forms.map((item, index) => (
                                            <div
                                                className={`text-center py-2.5 px-9 cursor-pointer ease-in-out duration-200 ${
                                                    transcript === item.id
                                                        ? 'border-b-2 border-primary-500'
                                                        : ''
                                                }`}
                                                onClick={() => setTranscript(item.id)}
                                            >
                                                {item.form}
                                            </div>
                                        ))}
                                    </div>

                                    <div className=" flex flex-col p-6 pb-0 gap-4 border-0 border-[#CCCCCC]  w-full ">
                                        <div className="flex justify-between">
                                            {activeForm.tab === 1 ? (
                                                <>
                                                    <span className="font-semibold flex items-end text-xl ">
                                                        IRS Forms
                                                    </span>

                                                    <div className="mr-4 flex space-x-5">
                                                        <div className="flex pl-3 items-center border-b-2 w-96 border-primary-500 text-primary-500">
                                                            <span className="material-symbols-outlined text-primary-500 pr-2">
                                                                search
                                                            </span>
                                                            <input
                                                                type="text"
                                                                placeholder="Search..."
                                                                className="border-none outline-none w-full text-primary-500 placeholder-primary-500"
                                                            />
                                                        </div>
                                                        <FiltersDropdown />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="flex items-center">
                                                    {/* <span
                                                            onClick={() =>
                                                                setActiveForm((prev) => ({
                                                                    ...prev,
                                                                    tab: 1,
                                                                }))
                                                            }
                                                            className="cursor-pointer py-2 px-7 text-white font-semibold text-base bg-gray-600 rounded-md"
                                                        >
                                                            Back
                                                        </span> */}
                                                    <span
                                                        onClick={() =>
                                                            setActiveForm((prev) => ({
                                                                ...prev,
                                                                tab: 1,
                                                            }))
                                                        }
                                                        className="material-symbols-outlined cursor-pointer"
                                                    >
                                                        arrow_back
                                                    </span>
                                                    <span className="font-semibold ml-4 text-xl ">
                                                        {activeForm.selectedForm}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <RenderComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserForms;
