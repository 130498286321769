import dayjs from 'dayjs';
import useTaxPrepStructure from '../../resource/form_structures_taxprep';
import { enumSections, fieldTypes } from '../../utils/enums';
import { isBrick } from '../basic/FormConstructor';
import useTaxPrepEvalData from './useTaxPrepEvalData';
import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { BrickInterface, FormInterface } from '../../utils/interfaces';

const generateCopyData = (fromState: any, currentYear: string, field: BrickInterface) => {
    let formData: any = {};

    switch (field.type) {
        case fieldTypes.MULTIFORM:
        case fieldTypes.CROSSROAD: {
            formData = {
                ...formData,
                [field.id]: fromState[field.id]?.map(({ id, ...item }: any, index: number) => ({
                    ...item,
                    id: 'new_' + dayjs().millisecond() + index,
                    taxprep_year: currentYear,
                })),
            };
            break;
        }

        case fieldTypes.EDITABLE_TABLE: {
            let keys = [];
            field.defaultRows.map((item: any) => {
                keys.push(item[1].id);
                keys.push(item[1].id + '_PS');
                keys.push(item[1].id + '_IF');
                keys.push(item[1].overrideBooleanId);
                keys.push(item[1].overrideValueId);
            });

            if (field.addColumns) {
                for (let i = 0; i <= 20; i++) {
                    const id_name =
                        field.addColumns[0].id_prefix + `${i + 1}` + field.addColumns[0].id_suffix;

                    const id_value =
                        field.addColumns[1].id_prefix + `${i + 1}` + field.addColumns[1].id_suffix;

                    keys.push(id_name);
                    keys.push(id_value);
                }
            }

            field.totalRow?.map((item: any) => {
                item.isInput && keys.push(item.id);
                item.sharable && keys.push(item.sharable);
                item.allowable && keys.push(item.allowable);
                item.lesserGreaterId && keys.push(item.lesserGreaterId);
            });

            keys.forEach((key) => {
                formData = { ...formData, [key]: fromState[key] };
            });
            break;
        }

        default: {
            formData = { ...formData, [field.id]: fromState[field.id] };
        }
    }

    return formData;
};

const useAdminCopySectionData = (fromYearValue?: string, forceCurrentYear?: string) => {
    const { year: fromYear, formState: fromState } = useTaxPrepEvalData(fromYearValue);
    const { year: currentYear, formState: currentState } = useTaxPrepEvalData(forceCurrentYear);

    const { pages, pagesRaw } = useTaxPrepStructure();

    const copyDataFunction = async (params: { sectionAddress: string; isMulti?: boolean }) => {
        const page = pages[params.sectionAddress];

        let formData: any = {};

        page.formStructure.forEach((field) => {
            if (!isBrick(field)) {
                return;
            }

            formData = { ...formData, ...generateCopyData(fromState, currentYear, field) };

            field.branches?.paths.forEach(({ form: branchStructure }) => {
                branchStructure.forEach((field) => {
                    if (!isBrick(field)) {
                        return;
                    }

                    formData = { ...formData, ...generateCopyData(fromState, currentYear, field) };
                });
            });
        });

        let meta_data = currentState.meta_data;

        const initialFormStatus =
            (typeof currentState.meta_data?.form_status === 'string'
                ? {}
                : currentState.meta_data?.form_status) || {};

        if (params.isMulti) {
            const fromFormStatus = fromState.meta_data.form_status;

            console.log('Copy Year', 'INFO', { ...currentState, ...formData });

            meta_data = {
                ...meta_data,
                form_status: {
                    ...initialFormStatus,
                    ...fromFormStatus,
                },
                page_json: [],
            };
        } else {
            const fromFormValue = fromState.meta_data.form_status?.[page.address + '_count'];

            console.log('Copy Year', 'INFO', { ...currentState, ...formData });

            meta_data = {
                ...meta_data,
                form_status: {
                    ...initialFormStatus,

                    [page.address + '_count']: fromFormValue,
                },
            };
        }

        const { data } = await page?.save(currentYear, {
            ...currentState,
            ...formData,
            meta_data,
        });

        console.log('Copy Year', 'PAGE DATA', data);

        return data;
    };

    const queryClient = useQueryClient();
    const {
        mutate: copyData,
        isLoading,
        isSuccess,
        reset,
    } = useMutation({
        mutationKey: ['copy-tax-prep-data'],
        mutationFn: copyDataFunction,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['get-client-data-', currentYear]);
        },
        onError: (error) => {
            console.log('Copy Year', 'ERROR', error);
        },
    });

    const confirm = (params: { sectionAddress: string; isMulti?: boolean }) => {
        console.log('Copy Year', 'START');
        const page = pages[params.sectionAddress];

        if (
            page.sectionId === enumSections.BUSINESS_INFORMATION &&
            page.address !== 'business_data'
        ) {
            console.error('Cannot copy business section by this method');
            return;
        }

        copyData(params);
    };

    const confirmCopyAllSections = async () => {
        for (const { address, section } of pagesRaw) {
            if (section.id === enumSections.BUSINESS_INFORMATION && address !== 'business_data') {
                continue;
            }

            console.log('Executing copy: ', address, ' from ', fromYearValue);
            await confirm({ sectionAddress: address, isMulti: true });
        }
    };

    const copyAllSections = useMutation({
        mutationKey: ['copy-tax-prep-data-all-sections'],
        mutationFn: confirmCopyAllSections,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['get-client-data-', currentYear]);
        },
        onError: (error) => {
            console.log('Copy Year', 'ERROR', error);
        },
    });

    return {
        confirm,
        isLoading,
        reset,
        isSuccess,
        copyAllSections,
    };
};

export default useAdminCopySectionData;
