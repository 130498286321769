import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import useNotifications from '../../api/hooks/useNotifications';
import bell_icon from '../../assets/big-bell.svg';
interface ModalProps {
    isOpen: boolean; // Flag to determine if the modal is open
    close: () => void; // Function to close the modal
    title?: string; // Optional title for the modal
    light?: boolean; // Optional flag to use a light theme
}

const Notifications: FC<ModalProps> = ({ isOpen, close, title, light = false }) => {
    // Handle click on overlay to close modal
    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) close();
    };

    // State to track the index of the notification being cleared
    const [clearLoadingIndex, setClearLoadingIndex] = useState<number>();

    // Custom hook to fetch notifications
    const { data, isLoading, clearNotifs, clearNotifLoading } = useNotifications('Read');

    // Query client instance
    const queryClient = useQueryClient();

    // Navigation function
    const navigate = useNavigate();

    // Invalidate cache on component mount to ensure fresh data
    useEffect(() => {
        queryClient.invalidateQueries('notifs');
    }, []);

    // Get the appropriate icon based on the action
    const getActionIcon = (action: string) => {
        switch (action) {
            case 'update':
                return (
                    <div style={{ fontSize: 16 }} className=" pr-1 material-symbols-outlined">
                        update
                    </div>
                );
            case 'delete':
                return (
                    <div style={{ fontSize: 16 }} className=" pr-1 material-symbols-outlined">
                        delete
                    </div>
                );
            case 'edit':
                return (
                    <div style={{ fontSize: 16 }} className=" pr-1 material-symbols-outlined">
                        edit_document
                    </div>
                );
            default:
                return null;
        }
    };

    // Clear a single notification
    const clearSingleNotif = (notif: any, index: number) => {
        setClearLoadingIndex(index);
        clearNotifs({
            irs_account_id: notif?.irs_account_id,
            read: notif?.read,
        });
    };

    return isOpen ? (
        <div
            onClick={handleOverlayClick}
            className={
                'fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center p-6  overflow-auto bg-opacity-100'
            }
        >
            <div className="fixed top-6 right-20  z-50 p-6 pr-12" style={{ minWidth: '650px' }}>
                <div className="bg-white rounded bg-gray-50  shadow-lg p-5">
                    {title ||
                        (true && (
                            <h1 className="text-lg font-bold text-gray-400 font-title">
                                {title} Notifications
                            </h1>
                        ))}
                    <div className="flex justify-between w-full border-b border-gray-300">
                        <button
                            onClick={() => navigate('/notifications')}
                            className="py-2 mr text-sm hover:underline text-gray-500"
                        >
                            View All
                        </button>
                        <button
                            onClick={() => {
                                clearNotifs({ clear_all: true });
                            }}
                            className="py-2 text-sm text-primary-500"
                        >
                            {'Read All'}
                        </button>
                    </div>
                    <div
                        style={{ maxHeight: '650px', overflowY: 'auto' }}
                        className="overflow-y-scroll pr-1 "
                    >
                        {data?.length < 1 && (
                            <div className="flex flex-col w-full mt-7 mb-3 px-4 py-1 text-center justify-center align-middle ">
                                <img src={bell_icon} alt="" className="h-12" />
                                <h4 className="font-semibold">No Notifications!</h4>
                            </div>
                        )}
                        {!isLoading &&
                            data?.map((notif: any, index: number) => {
                                let clearLoading =
                                    clearNotifLoading && index === clearLoadingIndex ? true : false;
                                return (
                                    <div
                                        key={index}
                                        className="flex flex-col w-full my-3 px-4 py-1 bg-white border-l-4 border-primary-500 shadow-sm"
                                    >
                                        <div className="flex justify-between">
                                            <div className=" flex items-center justify-center text-base ">
                                                <span
                                                    onClick={() => {
                                                        navigate(
                                                            `/evaluate/${notif?.irs_account_id}?scroll=basic_information`,
                                                        );
                                                        clearNotifs({
                                                            irs_account_id: notif?.irs_account_id,
                                                            read: notif?.read,
                                                        });
                                                    }}
                                                    className="text-primary-500 cursor-pointer font-semibold hover:underline"
                                                >
                                                    {notif?.client_name}
                                                </span>{' '}
                                                <span className="text-sm pl-1 font-normal text-gray-600">
                                                    has updated the Tax Resolution Worksheet.
                                                </span>
                                            </div>
                                            <p className="text-sm text-gray-700 ">
                                                {clearLoading ? (
                                                    <span
                                                        style={{ fontSize: '14px' }}
                                                        className="material-symbols-outlined refresh-icon animate-spin"
                                                    >
                                                        refresh
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ fontSize: '14px' }}
                                                        className="material-symbols-outlined cursor-pointer"
                                                        onClick={() => {
                                                            clearSingleNotif(notif, index);
                                                        }}
                                                    >
                                                        close
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        <p className="pb-1 flex flex-row items-center text-xs text-gray-600">
                                            {getActionIcon(notif?.Action)}
                                            {notif?.description}
                                        </p>
                                        <div className="flex justify-between text-gray-400 text-xs">
                                            <p>{moment(notif?.last_updated_date).fromNow()}</p>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Notifications;
