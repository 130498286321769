import { page as personal_information } from './form_structures/0_0_personal_information';
import { page as filing_status } from './form_structures/0_1_filing_status';
import { page as dependents } from './form_structures/0_2_dependents';

import { page as employment } from './form_structures/1_0_employment';
import { page as additional_income } from './form_structures/1_2_additional_income';
import { page as bank_accounts } from './form_structures/1_3_bank_accounts';
import { page as investment_accounts } from './form_structures/1_4_investment_accounts';
import { page as virtual_currency } from './form_structures/1_5_virtual_currency';
import { page as life_insurance } from './form_structures/1_6_life_insurance';
import { page as real_property } from './form_structures/1_7_real_property';
import { page as vehicles } from './form_structures/1_8_vehicles';
import { page as credit_card } from './form_structures/1_9_credit_card';
import { page as other_personal_assets } from './form_structures/1_10_other_personal_assets';
import { page as expenses } from './form_structures/1_11_expenses';

import { page as business_home } from './form_structures/2__busniess_home';
import { page as basic_information } from './form_structures/2_0_basic_information';
import { page as ecommerce } from './form_structures/2_1_ecommerce';
import { page as business_interests } from './form_structures/2_3_business_interests';
import { page as business_credit_cards } from './form_structures/2_4_business_credit_cards';
import { page as business_bank_accounts } from './form_structures/2_5_business_bank_accounts';
import { page as business_accounts_receivable } from './form_structures/2_6_business_accounts_receivable';
import { page as notes_receivable } from './form_structures/2_7_notes_receivable';
import { page as business_assets } from './form_structures/2_8_business_assets';
import { page as business_virtual_currency } from './form_structures/2_9_business_virtual_currency';
import { page as income_expense } from './form_structures/2_10_income_expense';
import { page as payment_processor } from './form_structures/2_11_payment_processor';
import { page as personnel_and_contact } from './form_structures/2_12_personnel_and_contacts';
import { page as investements } from './form_structures/2_13_investements';
import { page as available_credit } from './form_structures/2_14_available_credit';
import { page as business_real_property } from './form_structures/2_15_business_real_property';
import { page as business_vehicles } from './form_structures/2_17_business_vehicles';
import { page as business_liabilities } from './form_structures/2_16_business_liabilities';
import { page as business_other_financials } from './form_structures/2_18_business_other_financials';
import { page as business_affiliations } from './form_structures/2_19_business_affiliations';
import { page as outstanding_amount_owed } from './form_structures/2_20_outstanding_amount_owed';
import { page as business_transferred_assets } from './form_structures/2_21_transferred_assets';

import { page as other_financial_information } from './form_structures/3_0_other_financial_information';
import { page as safety_deposit } from './form_structures/3_1_safety_deposit';
import { page as transferred_assets } from './form_structures/3_2_transferred_assets';
import { page as abroad_properties } from './form_structures/3_3_abroad_properties';

const pages = [
    /** Section 1 */
    personal_information,
    filing_status,
    dependents,

    /** Section 2 */
    employment,
    additional_income,
    bank_accounts,
    investment_accounts,
    virtual_currency,
    life_insurance,
    real_property,
    vehicles,
    credit_card,
    other_personal_assets,
    expenses,

    /** Section 3 */
    business_home,
    basic_information,
    // ecommerce,
    // business_interests,
    business_credit_cards,
    business_bank_accounts,
    business_accounts_receivable,
    notes_receivable,
    business_assets,
    business_virtual_currency,
    income_expense,
    payment_processor,
    personnel_and_contact,
    investements,
    available_credit,
    business_real_property,
    business_vehicles,
    business_liabilities,
    business_other_financials,
    outstanding_amount_owed,
    business_transferred_assets,
    business_affiliations,

    /** Section 4 */
    other_financial_information,
    // lawsuits,
    // bankruptcy,
    // abroad_residence,
    // beneficiary,
    // trust,
    safety_deposit,
    transferred_assets,
    // litigation,
    abroad_properties,
    // third_party_fund,
];

export default pages;
