import React, { useState } from 'react';
import ParentGroup from './ParentGroup';
import dayjs from 'dayjs';

import DatePicker, { CalendarContainer } from 'react-datepicker';
import { interface_field_calendar } from '../../utils/interfaces';
import moment, { Moment } from 'moment';
import classNames from 'classnames';

const Container: typeof CalendarContainer = ({ className, children }) => {
    return (
        <div
            className={className}
            style={{
                width: '100%',
                borderRadius: 0,
                borderColor: 'rgba(229, 231, 235, 1)',
                fontFamily: 'Inter',
            }}
        >
            {children}
        </div>
    );
};

type CalendarGroupProps = interface_field_calendar & {
    className?: string;
    value: string | undefined;
    error?: string;
    disabled?: boolean;
    setValue: (value: string | null) => void;
};

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const Header = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}: {
    [key: string]: any;
}) => {
    return (
        <div className="flex w-full justify-center">
            <button
                className="px-2 font-bold font-title"
                onClick={() => changeYear(date.getFullYear() - 1)}
                disabled={prevMonthButtonDisabled}
            >
                {'<<'}
            </button>
            <button
                className="px-2 font-bold font-title"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
            >
                {'<'}
            </button>

            <div className="flex-grow font-title font-medium">
                {`${months[date.getMonth()]} ${date.getFullYear()}`}
            </div>

            <button
                className="px-2 font-bold font-title"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
            >
                {'>'}
            </button>
            <button
                className="px-2 font-bold font-title"
                onClick={() => changeYear(date.getFullYear() + 1)}
                disabled={nextMonthButtonDisabled}
            >
                {'>>'}
            </button>
        </div>
    );
};

const Calendar: React.FC<CalendarGroupProps> = ({
    id,
    value,
    error,
    setValue,
    className,
    label,
    placeholder,
    span,
    disabled,
    restrict,
}) => {
    const validFormatValue = value ? moment(value, 'MM-DD-YYYY').toDate() : undefined;

    const formatAndSetValue = (value: any) => {
        if (value === undefined || value === null || value === '') setValue(null);
        else setValue(dayjs(value).format('MM-DD-YYYY'));
    };

    const getIsDateDisabled = (date: Date | undefined) => {
        const today = new Date();

        const isFuture = date! > today;
        const isPast = date! < today;

        let showFuture = restrict !== 'Future';
        let showPast = restrict !== 'Past';

        return (!showFuture && isFuture) || (!showPast && isPast);
    };

    const DayContent = (day: number, date: Date | undefined) => {
        const isSelected = date?.getTime() === validFormatValue?.getTime();

        const today = new Date();
        const isToday =
            date?.getDate() === today.getDate() &&
            date?.getMonth() === today?.getMonth() &&
            date?.getFullYear() === today.getFullYear();

        const disabled = getIsDateDisabled(date);

        return (
            <div
                className={
                    disabled
                        ? 'bg-white text-gray-200 rounded-none'
                        : isSelected
                        ? 'bg-primary-500 hover:bg-primary-700 text-white rounded-none'
                        : isToday
                        ? 'bg-gray-200 hover:bg-primary-200 text-black font-bold rounded-none'
                        : 'bg-white hover:bg-gray-100 text-black rounded-none'
                }
            >
                {day}
            </div>
        );
    };

    const minDate = restrict === 'Past' ? new Date() : undefined;
    const maxDate = restrict === 'Future' ? new Date() : undefined;

    return (
        <ParentGroup
            id={id}
            className={className}
            label={validFormatValue ? label : label + ' (mm-dd-yyyy)'}
            value={value}
            error={error}
            style={{ position: 'relative', gridColumn: `span ${span}` }}
        >
            <DatePicker
                disabled={disabled}
                selected={validFormatValue}
                onChange={(value) => !disabled && formatAndSetValue(value)}
                calendarContainer={Container}
                className={classNames(
                    'focus:outline-none focus:border-primary-500 w-full h-16 border-b border-gray-200  px-3 pb-1 pt-5',
                    {
                        'hover:bg-gray-50 focus:bg-gray-50': !disabled,
                        'bg-gray-200': disabled,
                    },
                )}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat="MM-dd-yyyy"
                renderDayContents={DayContent}
                renderCustomHeader={(props) => <Header {...props} />}
            />

            {validFormatValue && !disabled && (
                <span
                    style={{ top: '50%', fontSize: 20 }}
                    onClick={() => formatAndSetValue(null)}
                    className="material-icons h-4 w-4 bg-white text-gray-300 hover:text-red-300 cursor-pointer rounded-full absolute right-1"
                >
                    cancel
                </span>
            )}
        </ParentGroup>
    );
};

export default Calendar;
