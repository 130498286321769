import { actions } from 'react-table';
import { api } from '.';

const refactorData = (serverData: any) => {
    if (!serverData) return {};

    const injectedBusinessData = serverData.data.map((business: any) => {
        let exracted_keys = {};

        business.add_expense?.map(
            ({ id, description, total_amount, secure_unsecure }: any, index: number) => {
                exracted_keys = {
                    ...exracted_keys,
                    [`add_expense_${index + 1}_id`]: id,
                    [`add_expense_${index + 1}_action`]: 'update',
                    [`add_expense_${index + 1}_prompt`]: description,
                    [`add_expense_${index + 1}`]: total_amount,
                    [`add_expense_${index + 1}_secure`]: secure_unsecure,
                };
            },
        );

        return { ...business, ...exracted_keys };
    });

    return {
        meta_data: serverData.meta_data,
        business_data: injectedBusinessData,
    };
};

export const fetchClientWithBusiness = async (irs_account_id: string): Promise<any> => {
    const response = await api.get(`/ClientWithBusiness/${irs_account_id}`);

    let businessData = refactorData(response.data);
    return businessData;
};

const createPayload = (
    id: string | undefined,
    meta_data: any,
    irs_account_id: string | undefined,
    data: any[],
) => {
    if (!Array.isArray(data)) {
        return { irs_account_id: meta_data.irs_account_id, data: [], meta_data, id };
    }

    const regEx = /^new/;
    const injectedData = data
        .map((item: any) => {
            let temp = item;

            if (regEx.test(item.id) || !item.id) {
                let action = item.action || 'create';
                temp = { ...temp, action, irs_account_id };
            } else if (item.action !== 'delete') {
                temp = { ...temp, action: 'update' };
            }

            return temp;
        })
        .filter((item: any) => {
            if ((regEx.test(item.id) || !item.id) && item.action === 'delete') {
                return false;
            }
            return true;
        });

    const payload = { irs_account_id: meta_data.irs_account_id, meta_data, id, data: injectedData };
    return payload;
};

export const upsertBusinessData = async ({ business, meta_data }: any) => {
    let payload: any;
    if (business) {
        if (Array.isArray(business)) {
            payload = createPayload(undefined, meta_data, undefined, business);
        } else {
            payload = createPayload(undefined, meta_data, undefined, [business]);
        }
    } else {
        payload = { irs_account_id: meta_data.irs_account_id, meta_data, data: [] };
    }

    const response = await api.post(`/businessByAction`, payload);
    return response.data;
};

export const upsertCreditCards = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_credit_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_credit_data);

    const response = await api.post(`/businessCreditCardAcceptedByAction`, payload);
    return response.data;
};

export const upsertBankAccounts = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_account_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_account_data);

    const response = await api.post(`/businessAccountByAction`, payload);
    return response.data;
};

export const upsertAccountsRecievable = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_account_receivable_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_account_receivable_data);

    const response = await api.post(`/businessAccountNoteByAction`, payload);
    return response.data;
};

export const upsertNotesRecievable = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_note_receivable_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_note_receivable_data);

    const response = await api.post(`/businessNoteRecByAction`, payload);
    return response.data;
};

export const upsertAssets = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_assets_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_assets_data);

    const response = await api.post(`/businessAssetsByAction`, payload);
    return response.data;
};

export const upsertVirtualCurrency = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_virtual_currency_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_virtual_currency_data);

    const response = await api.post(`/businessVirtualCurrencyByAction`, payload);
    return response.data;
};

export const upsertIncomeExpense = async ({ business, meta_data }: any) => {

    let expenseData: any = [];

    Object.keys(business).map((key) => {
        const regExPrefix = /^add_expense_/;
        const regExSuffix = /_prompt$/;

        if (regExPrefix.test(key) && regExSuffix.test(key)) {
            const indexArray = key.match(/\d+/g);
            const index = indexArray ? indexArray[0] : undefined;

            if (business[`add_expense_${index}_id`]) {
                let item = {
                    irs_account_id: business.irs_account_id,
                    id: business[`add_expense_${index}_id`],
                    action: business[`add_expense_${index}_action`],
                    description: business[`add_expense_${index}_prompt`],
                    total_amount: business[`add_expense_${index}`],
                    secure_unsecure: business[`add_expense_${index}_secure`],
                };

                if (!item.description) {
                    item.action = 'delete';
                }

                expenseData.push(item);
            } else {
                if (business[`add_expense_${index}_action`] !== 'delete') {
                    expenseData.push({
                        action: 'create',
                        irs_account_id: business.irs_account_id,
                        description: business[`add_expense_${index}_prompt`],
                        total_amount: business[`add_expense_${index}`],
                        secure_unsecure: business[`add_expense_${index}_secure`],
                    });
                }
            }
        }
    });



    const response = await api.post(`/businessOtherIncomeByAction`, {
        ...business,
        irs_account_id: meta_data.irs_account_id,
        add_expense: expenseData,
        meta_data: { ...meta_data, irs_account_id: meta_data.irs_account_id },
    });
    return response.data;
};

export const upsertPaymentProcessor = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_payment_processor_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_payment_processor_data);

    const response = await api.post(`/businessPaymentProcessorByAction`, payload);
    return response.data;
};

export const upsertPersonalContact = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_personal_contact_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_personal_contact_data);

    const response = await api.post(`/businessPersonalContactByAction`, payload);
    return response.data;
};

export const upsertBusinessInvestment = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_investment_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_investment_data);

    const response = await api.post(`/businessInvestmentByAction`, payload);
    return response.data;
};

export const upsertAvailableCredit = async ({ business, meta_data }: any) => {
    const {
        id,
        irs_account_id,
        business_available_credit_data,
        available_credit_amount_owed_as_of_date,
        available_credit_as_of_date,
    } = business;

    const payload = createPayload(id, meta_data, irs_account_id, business_available_credit_data);

    const response = await api.post(`/businessCreditByAction`, {
        available_credit_amount_owed_as_of_date,
        available_credit_as_of_date,
        ...payload,
    });
    return response.data;
};

export const upsertRealProperty = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_real_property_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_real_property_data);

    const post = {
        ...payload,
        real_property_fund_offer: business.real_property_fund_offer,
        real_property_fund_offer_amount: business.real_property_fund_offer_amount,
    };

    const response = await api.post(`/businessPropertyByAction`, post);
    return response.data;
};

export const upsertLiabilities = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_liability_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_liability_data);

    const response = await api.post(`/businessLiabilityByAction`, payload);
    return response.data;
};

export const upsertVehicles = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_vehicle_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_vehicle_data);

    const response = await api.post(`/businessVehicleByAction`, payload);
    return response.data;
};

export const upsertOtherFinancial = async ({ business, meta_data }: any) => {
    const payload = {
        meta_data,
        irs_account_id: meta_data.irs_account_id,
        data: [business],
    };
    const response = await api.post(`/businessOtherFinancialInfoByAction`, payload);
    return response.data;
};

export const upsertOtherAffilications = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_other_affilliation_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_other_affilliation_data);

    const response = await api.post(`/otherBusinessAffiliationByAction`, payload);
    return response.data;
};

export const upsertTransferredAssets = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_transfer_assets_data } = business;
    const payload = createPayload(id, meta_data, irs_account_id, business_transfer_assets_data);

    const response = await api.post(`/businessTransferAssetByAction`, payload);
    return response.data;
};

export const upsertOutstandingAmount = async ({ business, meta_data }: any) => {
    const { id, irs_account_id, business_outstanding_amount_owed_data } = business;
    const payload = createPayload(
        id,
        meta_data,
        irs_account_id,
        business_outstanding_amount_owed_data,
    );

    const response = await api.post(`/businessOutstandingAmountByAction`, payload);
    return response.data;
};
