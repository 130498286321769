import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { showToast } from '../components/basic/Toaster';
import { updateCFMutation } from '../api/admin';
import { useAuthState } from '../context/auth';
import { fieldTypes } from '../utils/enums';
import { useIsMobile } from '../utils/helpers';
import { useParams } from 'react-router-dom';
import Button from '../components/basic/Button';
import NavBar from '../components/basic/NavBar';
import AdminMenu from '../components/basic/AdminMenu';
import classNames from 'classnames';
import Dropdown from '../components/client/Dropdown';
import useSuperClients from './../api/hooks/useSuperClients';
import SwitchToggleInput from '../components/client/SwitchToggleInput';
import { formOptions } from '../components/basic/GenerateForm';

interface FeatureData {
    id: string;
    mycase_client_id: string;
    date_entered: string;
    last_updated_date: string;
    IsDeleted: boolean;
    created_by: string;
    modified_by: string;
    feature_enable: boolean | 0 | 1;
    feature_name: string;
    form_list: null;
    third_party: string;
}

const SuperClientsFeatures: React.FC = () => {
    const { token } = useAuthState();
    const { id } = useParams();
    const isMobile = useIsMobile();
    const queryClient = useQueryClient();
    const { superClients, isLoading } = useSuperClients();
    const [formData, setFormData] = useState<FeatureData[] | undefined>(undefined);
    const [documents_feature, setDocuments_feature] = useState<boolean | 1 | 0>(0);
    const [form_feature, setForm_feature] = useState<boolean | 1 | 0>(0);
    const [sso_login, setSso_login] = useState<boolean | 1 | 0>(0);
    const [export_feature, setExport_feature] = useState<boolean | 1 | 0>(0);
    const [form_drop, setForm_drop] = useState<string[]>([]);
    const [menu, setMenu] = useState<boolean>(false);
    const [error, setErrors] = useState<{ [key: string]: any }>({});

    const { mutate: updateClientFMutation, isLoading: updateLoading } = useMutation(
        updateCFMutation,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('super-clients-list');
                showToast('Feature updated Successfully !', 'success');
            },
            onError: (error: any) => {
                setErrors(error.response.data.message);
            },
        },
    );

    //filtering data by client id getting through useParams
    const filteredData =
        !isLoading &&
        superClients?.find((client: any) => {
            return client.id === id;
        });

    //when filtering affected then update the Formdata
    useEffect(() => {
        setFormData(filteredData.feature_data);
    }, [filteredData]);

    //when formData updated then update the field states accordingly
    useEffect(() => {
        if (formData) {
            formData.forEach((data) => {
                if (data.feature_name === 'Document') {
                    setDocuments_feature(data.feature_enable);
                } else if (data.feature_name === 'Form') {
                    setForm_feature(data.feature_enable);
                    const formList = data?.form_list as string | null;
                    if (formList) {
                        // Split the form_list string by comma into an array
                        const data = formList.split(',');
                        // Update the form_drop state with the data array
                        setForm_drop(data);
                    }
                } else if (data.feature_name === 'Export') {
                    setExport_feature(data.feature_enable);
                } else if (data.feature_name === 'SSO Login') {
                    setSso_login(data.feature_enable);
                }
                // Add more conditions for other feature names if needed
            });
        }
    }, [formData]);

    const handleSubmit = () => {
        if (!token) return;
        updateClientFMutation({
            id: id,
            document: documents_feature,
            form: form_feature,
            form_list: form_feature ? form_drop : [],
            export: export_feature,
            sso_login: sso_login,
        });
    };

    return (
        <div className={classNames('w-full bg-gray-100 h-screen flex', { 'flex-col': isMobile })}>
            {isMobile && <NavBar toggleMenu={() => setMenu(!menu)} />}
            {!isMobile ? (
                <AdminMenu selected="superAdmin" />
            ) : (
                menu && (
                    <div className="z-50 absolute h-screen">
                        <AdminMenu selected="superAdmin" />
                    </div>
                )
            )}
            <div className="w-full flex h-screen flex-col items-center p-6 pt-8 flex-grow">
                <div className="w-full flex flex-col max-w-6xl max-h-full">
                    <div className="w-full pl-8 mt-12 sm:mt-24 bg-white flex flex-col p-6">
                        <div className="font-title pt-2 pl-4 text-lg pb-3 border-b border-black font-medium flex-grow">
                            {'Client Permissions'}
                        </div>
                        <div className="flex pt-6 flex-col md:flex-row">
                            <div className="w-full md:w-1/2 border-b md:border-r md:border-b-0 border-gray-400   p-4">
                                <div className="">
                                    <SwitchToggleInput
                                        id="documents"
                                        label="Document Feature"
                                        value={documents_feature}
                                        setValue={setDocuments_feature}
                                    />
                                    <SwitchToggleInput
                                        id="export"
                                        label="Export Feature"
                                        value={export_feature}
                                        setValue={setExport_feature}
                                    />
                                    <SwitchToggleInput
                                        id="sso_login"
                                        label="SSO Login Feature"
                                        value={sso_login}
                                        setValue={setSso_login}
                                    />
                                </div>
                            </div>

                            <div className="w-full md:w-1/2 p-4">
                                <div className="">
                                    <SwitchToggleInput
                                        id="forms"
                                        label="Form Feature"
                                        value={form_feature}
                                        setValue={setForm_feature}
                                    />
                                    <div className="w-full">
                                        {form_feature ? (
                                            <Dropdown
                                                label="Form List"
                                                placeholder="Select"
                                                options={formOptions}
                                                isMulti={true}
                                                id="form-list"
                                                value={form_drop}
                                                setValue={(value) => setForm_drop(value)}
                                                type={fieldTypes.DROPDOWN}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex pb-10 justify-start">
                            <Button
                                isLoading={updateLoading}
                                onClick={handleSubmit}
                                className="text-center sm:w-1/3 mt-8"
                            >
                                {'UPDATE'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuperClientsFeatures;
