import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useFormDispatch, useFormState } from '../context/form';
import { enumPageTypes, enumSections } from '../utils/enums';
import { pageRefiner, sectionsDefault, useIsMobile, useLightHouse } from '../utils/helpers';
import FormConstructor from '../components/basic/FormConstructor';
import Menu from '../components/basic/Menu';
import Button from '../components/basic/Button';
import NavBar from '../components/basic/NavBar';
import WelcomeScreen from '../components/basic/WelcomeScreen';
import { useAuthState } from '../context/auth';
import useSectionLock from '../api/hooks/useSectionLocks';
import { build } from '../config';

interface FormSectionProps {
    id: string;
    welcome: string;
}

const TopNavigator: React.FC = () => {
    const { currentPage, sections } = useLightHouse();
    const isMobile = useIsMobile();

    return (
        <div className="flex justify-center w-full border border-gray-200 bg-white sticky top-0 z-30  pt-9">
            <div className="flex flex-grow max-w-5xl">
                {sections.map(({ name, subSections }, currentSectionIndex) => {
                    return (
                        <div className="flex-grow items-center justify-center border-r border-gray-200 ">
                            <div
                                className={classNames('font-title text-center py-3', {
                                    'text-xs': isMobile,
                                })}
                            >
                                {name}
                            </div>
                            <div className="flex w-full">
                                {subSections.map((page, index) => {
                                    let filled = 'future';

                                    if (page.overallPosition < currentPage.overallPosition)
                                        filled = 'past';
                                    else if (page.overallPosition === currentPage.overallPosition)
                                        filled = 'present';

                                    return (
                                        <div
                                            className={classNames('h-1 flex-grow relative', {
                                                'bg-gray-200': filled === 'future',
                                                'bg-gray-400': filled === 'present',
                                                'bg-primary-500': filled === 'past',
                                            })}
                                        >
                                            {filled === 'present' && (
                                                <div className="h-2 absolute top-2 w-full flex items-center justify-center">
                                                    <span className="material-icons">
                                                        arrow_drop_up
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const MainSection = () => {
    const { businessId, pageType, currentPage, address } = useLightHouse();
    const { formStructure } = currentPage;

    const formState = useFormState();
    const dispatch = useFormDispatch();

    const setFormState = ({ id, value }: { id: string; value: any }) => {
        dispatch({
            type: 'UPDATE_FORM',
            payload: { data: { [id]: value } },
        });
    };

    const setFormStateBusiness = ({ id, value }: { id: string; value: any }) => {
        dispatch({
            type: 'UPDATE_FORM_BUSINESS',
            payload: { businessId: businessId, data: { [id]: value } },
        });
    };

    let businessFormState = formState.business_data?.find(({ id }: any) => id === businessId) || {};
    businessFormState = { ...businessFormState, _errors: formState._errors[businessId || ''] };

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    const { irs_account_id } = useAuthState();
    const { lockDetails } = useSectionLock(irs_account_id);

    const sectionKey = businessId ? currentPage.address + ':' + businessId : currentPage.address;
    const isSectionLocked = lockDetails[sectionKey];

    if (pageType === enumPageTypes.FORM) {
        switch (currentPage.sectionId) {
            case enumSections.BUSINESS_INFORMATION:
                if (!businessFormState && address !== 'business_home')
                    return (
                        <Navigate
                            to={
                                iframe
                                    ? `/iframe-section/${enumSections.BUSINESS_INFORMATION}`
                                    : `/section/${enumSections.BUSINESS_INFORMATION}`
                            }
                        />
                    );

                if (address === 'business_home') {
                    return (
                        <div className="flex-grow flex justify-center">
                            <FormConstructor
                                disabled={build.section_locking && isSectionLocked}
                                className={classNames('flex flex-grow px-1 pb-16')}
                                structure={formStructure}
                                formState={formState}
                                setFormState={setFormState}
                            />
                        </div>
                    );
                }

                return (
                    <div className="flex-grow flex justify-center">
                        <FormConstructor
                            disabled={build.section_locking && isSectionLocked}
                            className={classNames('flex flex-grow px-1 pb-16')}
                            structure={formStructure}
                            formState={businessFormState}
                            setFormState={setFormStateBusiness}
                        />
                    </div>
                );

            default:
                return (
                    <div className="flex-grow flex justify-center">
                        <FormConstructor
                            disabled={build.section_locking && isSectionLocked}
                            className={classNames('flex flex-grow px-1 pb-16')}
                            structure={formStructure}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    </div>
                );
        }
    } else {
        return (
            <div className="flex-grow flex justify-center">
                <WelcomeScreen />
            </div>
        );
    }
};

const SectionLock = () => {
    return (
        <div
            className=" flex justify-center p-2 h-10 w-full"
            style={{
                backgroundImage: 'url(/locked.jpeg)',
                backgroundSize: '200px 200px',
                backgroundColor: '#FFFF',
            }}
        >
            <div className=" text-black font-semibold">This Section has been locked</div>
        </div>
    );
};

const FormSection: React.FC<{}> = ({}) => {
    const { address, serverData, pageType, currentPage, businessId } = useLightHouse();
    const { pages, sections } = pageRefiner();

    const location = useLocation();
    const iframe = location.pathname.includes('iframe');

    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile === false && openMenu === true) {
            setOpenMenu(false);
        }
    }, [isMobile]);

    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const formDispatch = useFormDispatch();

    useEffect(() => {
        formDispatch({ type: 'UPDATE_FORM', payload: { data: serverData } });
    }, [serverData]);

    const { irs_account_id } = useAuthState();
    const { lockDetails } = useSectionLock(irs_account_id);

    const sectionKey = businessId ? currentPage.address + ':' + businessId : currentPage.address;
    const isSectionLocked = lockDetails[sectionKey];

    if (Object.keys(pages).length === 0) return <div>RENDERING FORM</div>;
    if (address === 'null') return <div>INVALID PAGE</div>;

    return (
        <>
            {!iframe && <NavBar toggleMenu={() => setOpenMenu(!openMenu)} />}

            <div className="flex flex-col h-screen w-full">
                <div className="flex-grow flex flex-col w-full h-screen ">
                    {!iframe && <TopNavigator />}
                    <div className="w-full flex flex-grow relative">
                        {!isMobile && <Menu />}

                        <div className="w-full flex flex-col">
                            <div className="w-full flex">
                                {iframe && isMobile && (
                                    <div
                                        onClick={() => setOpenMenu(!openMenu)}
                                        className="flex m-2 items-center justify-center cursor-pointer"
                                    >
                                        <span className="material-symbols-outlined text-black">
                                            menu
                                        </span>
                                    </div>
                                )}
                                {build.section_locking && isSectionLocked && <SectionLock />}
                            </div>

                            <MainSection />
                        </div>

                        {openMenu && (
                            <div className="z-40 absolute h-full w-screen right-0 flex flex-col">
                                <div
                                    onClick={() => setOpenMenu(false)}
                                    className="absolute opacity-40 bg-black left-0 right-0 top-0 bottom-0 "
                                ></div>
                                <Menu close={() => setOpenMenu(false)} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormSection;
