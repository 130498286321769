//note - give id="scrollContainer" to the div in which the data will be scrolled! 

import { useEffect, useState } from "react";

const useInfiniteScroll = (data: any, initLength: number) => {

    const [visibleItems, setVisibleItems] = useState(initLength); // Number of initially visible items
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const target = event.currentTarget;
        if (target.scrollTop + target.clientHeight + 1 >= target.scrollHeight) {
            // User has scrolled to the bottom, load more items
            setVisibleItems((prevVisibleItems) => prevVisibleItems + 20);
        }
    };

    useEffect(() => {
        const scrollContainer = document.getElementById('scrollContainer'); // or Replace 'scrollContainer' with the actual id of your div
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll as any);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll as any);
            }
        };
    }, []);

    return { visibleItems }
};

export default useInfiniteScroll;
