import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isLoading: boolean;
  authProcess: 'manual' | 'sso';
  authError: string;
  authPage: 'sign-up' | 'sign-in' | '';
}

const initialState: AuthState = {
  isLoading: false,
  authProcess: 'manual',
  authError: '',
  authPage: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading;
    },
    setAuthProcess: (state, action: PayloadAction<{ authProcess: 'manual' | 'sso' }>) => {
      state.authProcess = action.payload.authProcess;
    },
    setAuthError: (state, action: PayloadAction<{ authError: string }>) => {
      state.authError = action.payload.authError;
    },
    setAuthPage: (state, action: PayloadAction<{ authPage: 'sign-up' | 'sign-in' | '' }>) => {
      state.authPage = action.payload.authPage;
    },
  },
});

export const { setAuthLoading, setAuthProcess, setAuthError, setAuthPage } = authSlice.actions;
export default authSlice.reducer;
