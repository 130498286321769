import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useTaxPrepClientData from './useTaxPrepClientData';
import useTaxPrepStructure, { sectionsDefault } from '../../resource/form_structures_taxprep';
import { enumSections } from '../../utils/enums';
import { Link as ScrollLink } from 'react-scroll';
import classNames from 'classnames';
import { PageInterface, SectionInterface } from '../../utils/interfaces';
import { useFormState } from '../../context/form';
import { useClientFillupPercentage } from './ClientFillupPercentage';
import { RootState } from '../../stateManager/rootReducers';
import { useSelector } from 'react-redux';
import Modal from '../basic/Modal';
import SectionSelection from './SectionSelection';
import { AuditLog } from '../../pages/AuditLog';
import useTaxPrepEvalData from './useTaxPrepEvalData';
import { build } from '../../config';

const EditSectionTaxPrep: React.FC<{ close: () => void; state_id: string }> = ({
    close,
    state_id,
}) => {
    return (
        <div className="px-5 z-50 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="flex w-full">
                <SectionSelection Close={close} edit={true} state_id={state_id} />
            </div>
        </div>
    );
};

const AuditLogTaxPrep: React.FC<{ close: () => void; tax_id: string }> = ({ close, tax_id }) => {
    return (
        <div className="px-5 py-8 rounded-md" style={{ minWidth: 500 }}>
            <div className="flex w-full">
                <AuditLog id={tax_id} auditType={'tax-prep'} close={close} />
            </div>
        </div>
    );
};

const LineSection: React.FC<{ isSelected: boolean; isCurrent: boolean; index: number | null }> = ({
    isSelected,
    isCurrent,
    index,
}) => {
    return (
        <div className="flex items-center justify-center relative h-full w-12">
            <div
                className={classNames(
                    'h-5 w-5 rounded-2xl  z-20 flex items-center justify-center',
                    {
                        'bg-primary-500': isSelected && !isCurrent,
                        'bg-gray-700 ': isCurrent,
                        'bg-gray-300': !isSelected,
                    },
                )}
            >
                {index !== null && (
                    <div className={'text-sm text-center font-semibold text-white'}>
                        {typeof index === 'number' ? index + 1 : index}
                    </div>
                )}
            </div>
            {/* Section to render if current page is selected */}
            {isCurrent && <div className="absolute h-5 w-1/2 right-0 bg-gray-700 z-10" />}
        </div>
    );
};

const PageProducer: React.FC<{
    businessId?: string;
    currentPage: PageInterface;
    index: number;
    isSelected: boolean;
    isError: boolean;
    isCurrent: boolean;
}> = ({ currentPage, index, isSelected, isCurrent, businessId, isError }) => {
    return (
        <ScrollLink
            to={currentPage.address}
            offset={-130}
            duration={500}
            smooth
            className="w-full cursor-pointer "
        >
            <div
                className={classNames('flex items-center h-8', {
                    'bg-white hover:bg-gray-100': !isError,
                    'bg-red-100 hover:bg-red-200': isError,
                })}
            >
                <div className={classNames('flex-grow text-right font-body text-sm')}>
                    {currentPage.name}
                </div>

                <LineSection {...{ index, isSelected, isCurrent }} />
            </div>
        </ScrollLink>
    );
};

const BusinessSection: React.FC<{
    key: number;
    business: any;
    pages: any[];
    meta: any;
}> = ({ business, pages, meta }) => {
    const { meta_data, _error_sections } = useFormState();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { address, businessId, businessGroupId } = useTaxPrepStructure();
    const { year } = useParams();
    return (
        <div className="w-full">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={classNames('cursor-pointer flex items-center bg-gray-50 h-9', {
                    // 'text-gray-400': future || start,
                })}
            >
                <div className="h-px flex-grow bg-gray-500 mr-2"></div>
                <div className={classNames('text-right text-gray-600 font-title text-sm')}>
                    {business.name}
                </div>

                <LineSection
                    {...{
                        index: null,
                        isSelected: false,
                        isCurrent: false,
                    }}
                />
            </div>

            {isOpen &&
                pages.map((subSection, index) => {
                    const selectionStatus = meta?.page_json?.[subSection.address] === true;
                    return (
                        <PageProducer
                            index={index}
                            currentPage={subSection}
                            // link={`${subSection.baseLink}`}
                            isError={false}
                            isCurrent={
                                address + '.' + businessId + '.' + businessGroupId ===
                                subSection.address
                            }
                            isSelected={selectionStatus}
                        />
                    );
                })}
        </div>
    );
};

const SectionComponent: React.FC<{
    key: number;
    section: SectionInterface;
    data: any[];
    meta: any;
}> = ({ key, section, data, meta }) => {
    const { address } = useTaxPrepStructure();
    const { year } = useParams();

    const Section: React.FC = () => {
        return (
            <div
                // onClick={() => setIsOpen(!isOpen)}
                className={classNames('flex items-center h-9 bg-white pr-7')}
            >
                <div className="h-px flex-grow bg-primary-500 mr-2"></div>
                <div className={classNames('text-right text-gray-600 font-title text-sm')}>
                    {section.name}
                </div>
            </div>
        );
    };

    switch (section.id) {
        case enumSections.BUSINESS_INFORMATION: {
            return (
                <div key={key} className="w-full">
                    <Section />
                    {data?.map(([b, pages]: any, index: number) => {
                        if (pages[0].address === 'business_data') {
                            const selectionStatus = meta?.page_json?.['business_data'] === true;
                            return (
                                <PageProducer
                                    // link={`${subSection.baseLink}`}
                                    isError={false}
                                    index={index}
                                    currentPage={pages[0]}
                                    isCurrent={address === pages[0].address}
                                    isSelected={selectionStatus}
                                />
                            );
                        }

                        return (
                            <BusinessSection meta={meta} key={index} business={b} pages={pages} />
                        );
                    })}
                </div>
            );
        }

        default:
            return (
                <div key={key} className="w-full">
                    <Section />
                    {data?.map((subSection, index) => {
                        const selectionStatus = meta?.page_json?.[subSection.address] === true;
                        return (
                            <PageProducer
                                // link={`${subSection.baseLink}`}
                                isError={false}
                                index={index}
                                currentPage={subSection}
                                isCurrent={address === subSection.address}
                                isSelected={selectionStatus}
                            />
                        );
                    })}
                </div>
            );
    }
};

const Menu: React.FC<{ close?: () => void; iframe?: boolean; closed?: boolean; user?: any }> = ({
    close,
    closed,
    iframe,
    user,
}) => {
    const [cofirmEdit, setConfirmEdit] = useState(false);
    const [auditLog, setAuditLog] = useState(false);
    const { formState } = useTaxPrepClientData();
    const { year } = useParams();
    const { getCompletionPercentage, meta } = useTaxPrepEvalData();

    const { pagesRaw } = useTaxPrepStructure();
    console.log('pagesRaw: ', pagesRaw);
    const defaultStructure = sectionsDefault.map((section) => {
        return [section, []] as any;
    });

    const menuStructure = pagesRaw.reduce((structure, page) => {
        return structure.map((s) => {
            let [section, data] = s;

            if (section.id === page.sectionId) {
                if (section.id === enumSections.BUSINESS_INFORMATION) {
                    if (page.address === 'business_data') {
                        return [
                            section,
                            [...data, [{ id: `business_data`, name: 'Business Home' }, [page]]],
                        ];
                    }

                    const business = data.find(([b, _]: any) => b.id === page.businessId);

                    if (business) {
                        return [
                            section,
                            data.map(([b, pages]: any) => {
                                if (b.id === page.businessId) {
                                    return [b, [...pages, page]];
                                }

                                return [b, pages];
                            }),
                        ];
                    }

                    return [
                        section,
                        [...data, [{ id: page.businessId, name: page.businessName }, [page]]],
                    ];
                }

                return [section, [...data, page]];
            }

            return s;
        });
    }, defaultStructure as typeof defaultStructure);

    const navigate = useNavigate();

    //const { completionPercentage } = useClientFillupPercentage({
    //pages,
    //meta: meta?.page_json,
    //  currentYear: year,
    //});
    const completionPercentage = getCompletionPercentage(meta?.page_json, year);
    return (
        <div
            className={'relative flex flex-col flex-grow'}
            style={{
                maxWidth: 300,
                minWidth: 300,
                backgroundImage: 'url(/menu_pattern.png)',
                backgroundSize: '400px 400px',
            }}
        >
            {/* Background screen with opacity to manage visibility of the background */}
            <div className="absolute h-full w-full bg-gray-100 top-0 left-0 opacity-80"></div>

            {/* Menu Section cards */}

            <div className={'flex flex-col sticky top-10 z-10 h-screen overflow-y-auto py-2'}>
                <div className="w-full mt-1 px-2 mb-2">
                    <div className="bg-white py-3 px-2 flex">
                        {!closed && (
                            <span
                                onClick={() => navigate('/client')}
                                className="material-icons cursor-pointer hover:bg-gray-100 h-8 w-8 text-black-400 items-center"
                            >
                                arrow_back
                            </span>
                        )}
                        <div className="flex-grow">
                            <div className="w-full flex mb-1">
                                <div className="flex-grow font-title text-sm">{`Completed`}</div>
                                <div className="font-title text-sm">{`${
                                    completionPercentage > 100 ? '100' : completionPercentage
                                }%`}</div>
                            </div>
                            <div className=" w-full relative">
                                <div className="h-2 bg-gray-200 w-full" />
                                <div
                                    className="absolute left-0 top-0 h-2 bg-primary-500"
                                    style={{
                                        width: `${
                                            completionPercentage > 100
                                                ? '100'
                                                : completionPercentage
                                        }%`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* {true && (
                    <div className=" py-1 px-2 h-10">
                        <div className="w-full h-8 cursor-pointer hover:bg-primary-700 bg-primary-500 flex justify-center items-center">
                            <div className="font-title text-sm font-medium text-white">
                                GENERATE FORM
                            </div>
                        </div>
                    </div>
                )} */}
                <div className="cursor-pointer font-title  text-white my-2 text-sm ml-2 flex items-center justify-around">
                    <span
                        onClick={() => setConfirmEdit(true)}
                        className=" rounded-full bg-primary-400 px-3 py-1"
                    >{`Edit Sections`}</span>
                    {build.tp_audit_log && (
                        <span
                            onClick={() => setAuditLog(true)}
                            className=" rounded-full bg-primary-400 px-4 py-1"
                        >{`Change Log`}</span>
                    )}
                </div>
                {menuStructure.map(([section, data], index) => {
                    if (!data.length) {
                        return <></>;
                    }

                    return (
                        <SectionComponent meta={meta} key={index} section={section} data={data} />
                    );
                })}
            </div>
            <Modal close={() => setConfirmEdit(false)} isOpen={!!cofirmEdit}>
                <EditSectionTaxPrep close={() => setConfirmEdit(false)} state_id={formState?.id} />
            </Modal>
            <Modal close={() => setAuditLog(false)} isOpen={!!auditLog}>
                <AuditLogTaxPrep close={() => setAuditLog(false)} tax_id={formState?.id} />
            </Modal>
        </div>
    );
};

export default Menu;
