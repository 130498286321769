import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import taxPreparerSlice from './taxPreparerSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  taxPrep: taxPreparerSlice
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;