import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "..";
import { clearNotifications } from "../admin";
import { showToast } from "../../components/basic/Toaster";

const useNotifications = (msgState: any) => {
    const queryClient = useQueryClient();

    // Function to fetch notifications
    const getNotifs = async (): Promise<any> => {
        // const { data } = await api.get(`/getNotifications?get_all=${false}`);
        return data;
    };

    // Query to fetch notifications with options
    const { data, isLoading } = useQuery({
        queryKey: ['notifs'],
        queryFn: getNotifs,
        staleTime: Infinity, // Data remains fresh indefinitely
        refetchInterval: 10000, // Refetch every 10 seconds
        refetchIntervalInBackground: true, // Refetch even if tab is in background
    });

    // Determine bell icon based on notification status
    const bellIcon = data?.notify ? 'notifications' : 'notifications';

    // Mutation to clear notifications
    const { mutate: clearNotifs, isLoading: clearNotifLoading } = useMutation(clearNotifications, {
        onSuccess: (data: any) => {
            // Invalidate queries to trigger refetch
            queryClient.invalidateQueries('notifs-all');
            queryClient.invalidateQueries('notifs');
            console.log('msgState', msgState)
            if (msgState === 'Read') {
                // only showing msj when the msj is read from table
                showToast(data?.data?.message, 'success'); // Show success toast only when marked unread
            }
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.message, 'error'); // Show error toast
        },
    });

    // Return notifications data and related functions
    return {
        data: data?.data?.notifications,
        isLoading,
        notify: {
            notify: data?.notify,
            icon: bellIcon,
            count: data?.data?.notifications?.length
        },
        clearNotifs,
        clearNotifLoading
    };
};

export default useNotifications;
