import Calendar from './Calendar';
import Dropdown from './Dropdown';
import InputGroup from './InputGroup';
import RadioGroup from './Radio';
import SeperatorGroup from './SeperatorGroup';
import { fieldTypes } from '../../utils/enums';
import {
    BrickInterface,
    ConcreteInterface,
    FormStateInterface,
    FormActionInterface,
    FormInterface,
    SectionInterface,
} from '../../utils/interfaces';
import { Table } from './Table';
import classNames from 'classnames';
import Crossroad from './Crossroad';
import TerminalGroup from './TerminalGroup';
import EditableTable from './EditableTable';
import InputDisplay from './InputDisplay';
import AmountInputGroup from './AmountInput';
import MultiForm from './MultiForm';

type WallInterface = {
    className?: string;
    structure: FormInterface;
    formState: FormStateInterface;
    disabled?: boolean;
    setFormState: (update: { id: string; value: any }) => void;
    getOptionValue: (path: string) => OptionsValues;
    setOptionValue: (update: { path: string; value: OptionsValues }) => void;
};

function isBrick(field: BrickInterface | ConcreteInterface): field is BrickInterface {
    return (field as BrickInterface).id !== undefined;
}

type OptionsValues = 'no' | 'one' | 'multiple' | undefined | null;

type FieldResolverProps = {
    field: BrickInterface | ConcreteInterface;
    disabled?: boolean;
    formState: FormStateInterface;
    setFormState: (update: { id: string; value: any }) => void;
    getOptionValue: (path: string) => OptionsValues;
    setOptionValue: (update: { path: string; value: OptionsValues }) => void;
};
const FieldResolver: React.FC<FieldResolverProps> = ({
    field,
    formState = {},
    setFormState,
    disabled,
    getOptionValue,
    setOptionValue,
}) => {
    if (isBrick(field)) {
        /** Component is a brick, hence needs to utilize formStates */
        const brick = field as BrickInterface;

        const value = formState[brick.id];
        const error = formState._errors?.[brick.id];

        const setValue = (value: any) => {
            setFormState({ id: brick.id, value });
        };

        switch (brick.type) {
            case fieldTypes.TEXT_INPUT:
                return <InputGroup {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.AMOUNT_INPUT:
                return <AmountInputGroup {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.DROPDOWN:
                return (
                    <Dropdown
                        {...{ ...brick, error, value, setValue, disabled, formData: formState }}
                    />
                );

            case fieldTypes.RADIO:
                return <RadioGroup {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.CALENDAR:
                return <Calendar {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.TABLE:
                return <Table {...{ ...brick, error, value, setValue, disabled }} />;

            case fieldTypes.EDITABLE_TABLE:
                return (
                    <EditableTable
                        {...{ ...brick, error, value, setValue, disabled, formState, setFormState }}
                    />
                );

            case fieldTypes.CROSSROAD:
                return (
                    <Crossroad
                        {...{
                            ...brick,
                            value,
                            setValue,
                            disabled,
                            formState,
                            setFormState,
                            getOptionValue,
                            setOptionValue,
                        }}
                    />
                );

            case fieldTypes.MULTIFORM:
                return (
                    <MultiForm
                        {...{
                            ...brick,
                            value,
                            setValue,
                            disabled,
                            formState,
                            setFormState,
                            getOptionValue,
                            setOptionValue,
                        }}
                    />
                );

            case fieldTypes.TEXT_DISPLAY:
                return <></>;

            default:
                return <div>FAILED BRICK</div>;
        }
    } else {
        /** Component is a concrete, can't alter form State */
        const concrete = field as ConcreteInterface;

        switch (concrete.type) {
            case fieldTypes.SEPERATOR:
                return <SeperatorGroup {...{ ...concrete, disabled }} />;

            case fieldTypes.TERMINAL:
                return <></>;

            default:
                return <div>FAILED CONCRETE</div>;
        }
    }
};

const Branches: React.FC<FieldResolverProps> = ({
    field,
    formState,
    getOptionValue,
    setOptionValue,
    setFormState,
    disabled,
}) => {
    if (!formState) return <></>;

    if (isBrick(field)) {
        /**
         * Component is a brick, hence can form branches
         * */
        // if (field.type === fieldTypes.CROSSROAD) return <></>;
        if (field.branches) {
            const paths = field.branches.paths;

            if (paths) {
                const path = paths.find((b) => b.value === formState[field.id]);

                if (path)
                    return (
                        <FormConstructor
                            disabled={disabled}
                            structure={path.form}
                            formState={formState}
                            setFormState={setFormState}
                            getOptionValue={getOptionValue}
                            setOptionValue={setOptionValue}
                        />
                    );

                if (!field.branches.inline)
                    return (
                        <>
                            <SeperatorGroup type={fieldTypes.SEPERATOR} />
                            <div className="flex flex-col col-span-4 w-full items-center">
                                <div className="w-full h-0.5 bg-gray-200"></div>
                                <div className="text-sm text-gray-300 font-semibold mt-5">{`Answer to continue`}</div>
                            </div>
                        </>
                    );
            }
        }

        /** Component is a concrete, can't extend branches */
    }

    return <></>;
};

const FormConstructor: React.FC<WallInterface> = (wall) => {
    const { structure, formState, setFormState, disabled, getOptionValue, setOptionValue } = wall;

    return (
        <div className={classNames('grid grid-cols-4 col-span-4 w-full', wall.className)}>
            {structure.map((component, index) => {
                return (
                    <>
                        <FieldResolver
                            disabled={disabled}
                            key={index}
                            field={component}
                            {...{ formState, setFormState, getOptionValue, setOptionValue }}
                        />

                        <Branches
                            key={index}
                            field={component}
                            {...{
                                formState,
                                setFormState,
                                disabled,
                                getOptionValue,
                                setOptionValue,
                            }}
                        />
                    </>
                );
            })}
        </div>
    );
};

export default FormConstructor;
