import dayjs from 'dayjs';
import moment from 'moment';

export const phone = (value: string): string => {
    const sanitized = value.replace(/[^0-9]/g, '');
    const array = sanitized.split('');

    let newValue = ``;
    array?.map((char, index) => {
        if (index < 10) {
            if (index === 3) newValue += `-`;
            if (index === 6) newValue += `-`;
            newValue += char;
        }
    });

    return newValue;
};

export const ssn = (value: string): string => {
    const sanitized = value.replace(/[^0-9]/g, '');
    const array = sanitized.split('');

    let newValue = ``;
    array?.map((char, index) => {
        if (index < 9) {
            if (index === 3) newValue += `-`;
            if (index === 5) newValue += `-`;
            newValue += char;
        }
    });

    return newValue;
};

export const ein = (value: string): string => {
    const sanitized = value.replace(/[^0-9]/g, '');
    const array = sanitized.split('');

    let newValue = ``;
    array?.map((char, index) => {
        if (index < 9) {
            if (index === 2) newValue += `-`;
            newValue += char;
        }
    });

    return newValue;
};

export const amount = (value: string | number | undefined) => {
    if (value === undefined) return value;

    const formatValue = typeof value === 'string' ? parseFloat(value) : value;
    return '$ ' + Intl.NumberFormat('en-US').format(formatValue);
};

export const date = (value: string | undefined | null) => {
    const date = moment(value, 'YYYY-MM-DD HH:MM:SS');

    if (!date) return date;

    const now = moment();

    if (moment(date).isBefore(now.subtract(1, 'week'))) {
        console.log('DATE VALUE A', { value });
        return dayjs(value, 'Y-m-d H:i:s').format('YYYY-MM-DD');
    } else {
        const datetime = dayjs(value, 'Y-m-d H:i:s').format();

        let date: string | undefined;
        date = datetime.slice(0, -6);

        console.log('DATE VALUE B', { date, datetime, return: moment(date + '+00:00').fromNow() });
        return moment(date + '+00:00').fromNow();
        // return moment(datetime).fromNow();
    }
};
