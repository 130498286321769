import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSalesForce, { LoginArgs } from '../api/hooks/useSalesForce';

import FormSection from './FormSection';
import { useAuthDispatch, useAuthState } from '../context/auth';
import EvalScreen from './EvalScreen';

const Loading: React.FC<{ errors: { [key: string]: string } }> = ({ errors }) => {
    const isError = Object.keys(errors).length;
    return (
        <div className="w-full h-screen flex flex-col items-center justify-center">
            {isError ? (
                <>
                    <div>{errors.message}</div>
                    <div>{JSON.stringify(errors.data)}</div>
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

const IFrameForm: React.FC<{}> = ({}) => {
    const { login } = useSalesForce();

    const { irs_account_id } = useParams();
    const [searchParams] = useSearchParams();

    const [errors, setErrors] = useState({});
    const tax_pro_id = searchParams.get('tax_pro_id');

    const { success, user_id } = useAuthState();
    const authDispatch = useAuthDispatch();

    const navigate = useNavigate();
    if (!success) {
        navigate('/login?iframe-evaluate=' + irs_account_id + '&tax_pro_id=' + tax_pro_id);
    }

    useEffect(() => {
        if (!tax_pro_id) {
            setErrors({ message: 'Tax pro ID is missing' });
            return;
        }

        if (!irs_account_id) {
            setErrors({ message: 'Client ID is missing' });
            return;
        }
    }, [irs_account_id, tax_pro_id]);

    // if ((success && user_id !== tax_pro_id) || !success) {
    if (!success) {
        return <Loading errors={errors} />;
    }

    return <EvalScreen iframe />;
};

export default IFrameForm;
