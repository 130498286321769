import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { graphConfig, loginRequest } from '../../authConfig';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMutation, useQuery } from 'react-query';
import { useAuthDispatch, useAuthState } from '../../context/auth';
import axios from 'axios';
import { api } from '../../api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useSsoSignInMutation from '../../api/hooks/useSsoSignInMutation';
import useSignUpMutation from '../../api/hooks/useSignUpMutation ';
import { useSelector } from 'react-redux';
import { RootState } from '../../stateManager/rootReducers';

const apiMS = axios.create({
    baseURL: 'https://graph.microsoft.com',
});

export const MicrosoftCallback = () => {
    const { instance, accounts } = useMsal();
    const { signInUserWithSso, errors: signInError } = useSsoSignInMutation();
    const { registerUser: sign_up, errors: signUpError } = useSignUpMutation({ type: 'sso' });
    const authState = useSelector((state: RootState) => state.auth);

    //ms handle where we fetch user information
    useEffect(() => {
        const acquireEmailAndLogin = async () => {
            try {
                const request = {
                    ...loginRequest,
                    account: accounts[0],
                };
                const { accessToken } = await instance.acquireTokenSilent(request);
                const { data } = await apiMS.get('/v1.0/me', {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                console.log(data);
                //call api based on sign-in or up
                if (authState.authPage === 'sign-in') {
                    signInUserWithSso(data.mail);
                } else {
                    sign_up(data.mail);
                }
            } catch (error) {
                console.log('Error occurred during authentication:', error);
                // Handle error gracefully, e.g., display a user-friendly message or redirect to an error page
                // setErrors(error);
            }
        };

        acquireEmailAndLogin();

        return () => {
            // Cleanup code here if needed
        };
    }, [instance]);

    return (
        <div className="h-screen w-full flex items-center justify-center">
            <div>
                {signInError || signUpError
                    ? 'Error Occured: ' + signInError ||
                      JSON.stringify(signUpError?.u_email) ||
                      authState?.authError
                    : `Authenticating with Microsoft Account...`}
            </div>
        </div>
    );
};
