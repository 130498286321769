import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { fieldTypes } from '../../utils/enums';
import {
    FormStateInterface,
    interface_editable_table,
    interface_editable_table_cell,
    interface_option,
} from '../../utils/interfaces';
import AmountInputGroup from './AmountInput';
import Dropdown from './Dropdown';
import InputDisplay from './InputDisplay';
import InputGroup from './InputGroup';
import ParentGroup from './ParentGroup';
import RadioGroup from './Radio';

type EditableTableProps = interface_editable_table & {
    className?: string;
    error?: any;
    value: any[];
    setValue: (str: any) => void;
    disabled?: boolean;
    formState: FormStateInterface;
    setFormState: ({ id, value }: any) => void;
};

const RowCreator: React.FC<any> = ({ data, setCellValue, addRow, deleteRow, disabled }) => {
    return (
        <>
            {addRow ? (
                <InputGroup
                    id={data.id_name}
                    className="flex justify-end"
                    label={''}
                    type={fieldTypes.TEXT_INPUT}
                    value={data.value_name}
                    error={data.error_name}
                    setValue={(value) => setCellValue({ id: data.id_name, value })}
                    placeholder={'0'}
                    span={5}
                    disabled={disabled}
                />
            ) : (
                <InputDisplay
                    id={`${data.id}_display`}
                    label={''}
                    type={fieldTypes.TEXT_DISPLAY}
                    value={data.label}
                    span={5}
                    className=" items-center"
                />
            )}

            <AmountInputGroup
                id={data.id}
                className="flex justify-end"
                label={''}
                type={fieldTypes.AMOUNT_INPUT}
                value={data.value}
                setValue={(value) => setCellValue({ id: data.id, value })}
                placeholder={'0'}
                error={data.error_value}
                span={6}
                disabled={disabled}
            />

            {addRow ? (
                <div className="flex items-center cursor-pointer hover:bg-gray-100">
                    <span onClick={deleteRow} className="material-icons  text-red-400">
                        cancel
                    </span>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

const EditableTable: React.FC<EditableTableProps> = ({
    id,
    addColumns = [],
    defaultRows,
    addButtonText,
    addLimit,
    formState,
    setFormState,
    totalRow,
    error,
    disabled,
}) => {
    const [emptyRows, setEmptyRows] = useState<Array<{ id: string }>>([]);

    // Just to stave off initial render conditions

    useEffect(() => {
        if (addColumns) {
            let count = 0;

            [...Array(addLimit)].map((_, index) => {
                let addRow = false;

                addColumns.map(({ id_suffix, id_prefix }) => {
                    if (formState[id_prefix + `${index + 1}` + id_suffix]) {
                        addRow = true;
                    }
                });

                if (addRow) {
                    count++;
                }
            });

            setEmptyRows([...Array(count)].map((i, index) => ({ id: `${index + 1}` })));
        }
    }, [formState]);

    const addNewRow = ({ id: entryId }: any) => {
        if (emptyRows.length < (addLimit || Infinity)) {
            if (!emptyRows.find(({ id }) => id === entryId)) {
                setEmptyRows([...emptyRows, { id: entryId }]);
            }
        }
    };

    const setCellValue = ({ id, value }: any) => {
        setFormState({ id, value });
    };

    const defaultRowData = defaultRows.map((item) => {
        if (typeof item[1] !== 'string') {
            const id = item[1].id;
            return {
                label: item[0],
                id: id,
                value: formState[id],
                error_value: error?.[id],
                sharable: formState[id + '_sharable'],
                allowable: formState[id + '_allowable'],
            };
        }
    });

    const addRowData = emptyRows.map((item, index) => {
        if (typeof item !== 'string') {
            const id_name = addColumns[0].id_prefix + `${index + 1}` + addColumns[0].id_suffix;
            const id_value = addColumns[1].id_prefix + `${index + 1}` + addColumns[1].id_suffix;

            return {
                id_name: id_name,
                value_name: formState[id_name],
                error_name: error?.[id_name],
                id: id_value,
                value: formState[id_value],
                error_value: error?.[id_value],
            };
        }
    });

    const deleteRow = (deleteIndex: number) => {
        for (let index = 0; index < emptyRows.length - 1; index++) {
            if (deleteIndex <= index) {
                const id_name_current =
                    addColumns[0].id_prefix + `${index + 1}` + addColumns[0].id_suffix;
                const id_name_next =
                    addColumns[0].id_prefix + `${index + 2}` + addColumns[0].id_suffix;

                const id_value_current =
                    addColumns[1].id_prefix + `${index + 1}` + addColumns[1].id_suffix;
                const id_value_next =
                    addColumns[1].id_prefix + `${index + 2}` + addColumns[1].id_suffix;

                setFormState({ id: id_value_current, value: formState[id_value_next] });
                setFormState({ id: id_name_current, value: formState[id_name_next] });
            }
        }

        const id_name_last =
            addColumns[0].id_prefix + `${emptyRows.length - 1 + 1}` + addColumns[0].id_suffix;
        const id_value_last =
            addColumns[1].id_prefix + `${emptyRows.length - 1 + 1}` + addColumns[1].id_suffix;

        setFormState({ id: id_value_last, value: null });
        setFormState({ id: id_name_last, value: null });

        setEmptyRows([
            ...emptyRows
                .filter(({ id }) => id !== `${deleteIndex + 1}`)
                .map((item, index) => {
                    return { ...item, id: `${index + 1}` };
                }),
        ]);
    };

    return (
        <ParentGroup id={id} className={`w-full col-span-2`}>
            <div className={classNames(`w-full grid grid-cols-12`)}>
                {defaultRowData.map((data) => (
                    <RowCreator data={data} disabled={disabled} setCellValue={setCellValue} />
                ))}
            </div>

            <div className={classNames(`w-full grid grid-cols-12`)}>
                {addRowData.map((data, index) => (
                    <RowCreator
                        addRow
                        data={data}
                        setCellValue={setCellValue}
                        disabled={disabled}
                        deleteRow={() => deleteRow(index)}
                    />
                ))}
            </div>

            {addButtonText && emptyRows.length < (addLimit || Infinity) && (
                <div
                    onClick={() => !disabled && addNewRow({ id: `${emptyRows.length + 1}` })}
                    className={classNames('cursor-pointer px-2 py-2 text-sm', {
                        'hover:bg-primary-200 bg-primary-100': !disabled,
                        'hover:bg-gray-200 bg-gray-100': disabled,
                    })}
                >
                    {addButtonText}
                </div>
            )}
        </ParentGroup>
    );
};

export default EditableTable;
