import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getIRSStandards } from '../../api/form';
import { fieldTypes } from '../../utils/enums';
import {
    FormStateInterface,
    interface_editable_table,
    interface_editable_table_cell,
    interface_option,
} from '../../utils/interfaces';
import AmountInputGroup from './AmountInput';
import Dropdown from './Dropdown';
import InputDisplay from './InputDisplay';
import InputGroup from './InputGroup';
import ParentGroup from './ParentGroup';
import RadioGroup from './Radio';

type EditableTableProps = interface_editable_table & {
    className?: string;
    error?: any;
    value: any[];
    setValue: (str: any) => void;
    formState: FormStateInterface;
    setFormState: ({ id, value }: any) => void;
};

const RowCreator: React.FC<any> = ({ data, setCellValue, addRow, deleteRow, totalRow }) => {
    const [totalFormValue, setTotalValue] = useState(0);

    useEffect(() => {
        if (totalRow && data.lesserGreaterId) {
            const standard = parseFloat(data.code);
            const expense = data.value ? parseFloat(data.value) : 0;
            let displayValue = 0;

            if (data.lesserGreaterValue === 'y') {
                displayValue = Math.min(standard, expense);
            } else {
                displayValue = Math.max(standard, expense);
            }

            setTotalValue(displayValue);
        }
    }, [data]);

    const amount = (value: string | number | undefined) => {
        if (value === undefined) return value;

        const formatValue = typeof value === 'string' ? parseFloat(value) : value;
        return '$ ' + Intl.NumberFormat('en-US').format(formatValue);
    };

    return (
        <>
            <div className="col-span-5 flex flex-col">
                {addRow ? (
                    <InputGroup
                        id={data.id_name}
                        className="flex justify-end"
                        label={''}
                        type={fieldTypes.TEXT_INPUT}
                        value={data.value_name}
                        setValue={(value) => setCellValue({ id: data.id_name, value })}
                        placeholder={'0'}
                        error={data.error_name}
                    />
                ) : (
                    <InputDisplay
                        id={`${data.id}_display`}
                        label={''}
                        type={fieldTypes.TEXT_DISPLAY}
                        value={data.label}
                        className=" items-center"
                    />
                )}
            </div>

            <div className="col-span-2">
                <InputDisplay
                    id={`${data.id}_code`}
                    label={''}
                    type={fieldTypes.TEXT_DISPLAY}
                    value={data.code}
                    formatFunction={amount}
                />
            </div>
            <div className="col-span-2">
                {totalRow && !data.isInput ? (
                    <InputDisplay
                        id={data.id}
                        label={''}
                        type={fieldTypes.TEXT_DISPLAY}
                        value={data.value}
                        span={2}
                        formatFunction={amount}
                    />
                ) : (
                    <AmountInputGroup
                        id={data.id}
                        className="flex justify-end"
                        label={''}
                        type={fieldTypes.AMOUNT_INPUT}
                        value={data.value}
                        error={data.error_value}
                        setValue={(value) => setCellValue({ id: data.id, value })}
                        placeholder={'0'}
                        span={2}
                    />
                )}
            </div>

            {totalRow && data.lesserGreaterId ? (
                <div className="col-span-5 flex border border-gray-200 mt-1">
                    <div className="flex-grow pb-1">
                        <div className="flex flex-grow">
                            <RadioGroup
                                id={data.lesserGreaterId}
                                label={''}
                                type={fieldTypes.RADIO}
                                options={[{ value: 'y', label: 'Lesser' }]}
                                setValue={(value) =>
                                    setCellValue({ id: data.lesserGreaterId, value: value || 'n' })
                                }
                                value={data.lesserGreaterValue}
                                smallPadding
                            />
                            <RadioGroup
                                id={data.lesserGreaterId}
                                label={''}
                                type={fieldTypes.RADIO}
                                options={[{ value: 'n', label: 'Greater' }]}
                                setValue={(value) =>
                                    setCellValue({
                                        id: data.lesserGreaterId,
                                        value: value || 'y',
                                    })
                                }
                                value={data.lesserGreaterValue !== 'y' ? 'n' : 'y'}
                                smallPadding
                            />
                        </div>
                        <div className="mx-2 text-xs">{`Expense value v Standard value`}</div>
                    </div>
                    <div className="bg-gray-200 pl-2 pb-1">
                        <div className="flex flex-grow h-10 items-center text-sm">
                            <div>{'$'}</div>
                            <div className="font-semibold">
                                {Intl.NumberFormat('en-US').format(totalFormValue)}
                            </div>
                        </div>
                        <div className="mr-2 text-xs">{`Using value`}</div>
                    </div>
                </div>
            ) : (
                data.overrideBooleanId && (
                    <div className="col-span-2 flex">
                        <RadioGroup
                            id={data.overrideBooleanId}
                            label={''}
                            type={fieldTypes.RADIO}
                            options={[{ value: 'y', label: 'Lesser' }]}
                            setValue={(value) =>
                                setCellValue({ id: data.overrideBooleanId, value: value || 'n' })
                            }
                            value={data.overrideBoolean}
                            smallPadding
                        />

                        <RadioGroup
                            id={data.overrideBooleanId}
                            label={''}
                            type={fieldTypes.RADIO}
                            options={[{ value: 'n', label: 'Greater' }]}
                            setValue={(value) =>
                                setCellValue({ id: data.overrideBooleanId, value: value || 'y' })
                            }
                            value={data.overrideBoolean !== 'y' ? 'n' : 'y'}
                            smallPadding
                        />
                    </div>
                )
            )}

            <div className="col-span-1">
                {addRow ? (
                    <div className="flex items-center cursor-pointer hover:bg-gray-100">
                        <span onClick={deleteRow} className="material-icons  text-red-400">
                            cancel
                        </span>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

const EditableTable: React.FC<EditableTableProps> = ({
    id,
    addColumns = [],
    defaultRows,
    totalRow,
    addButtonText,
    addLimit,
    formState,
    setFormState,
    label,
    error,
}) => {
    const [emptyRows, setEmptyRows] = useState<Array<{ id: string }>>([]);
    const { irs_account_id } = useParams();

    const { data: IRSStandardData } = useQuery(
        ['irs-standard', irs_account_id],
        () => getIRSStandards(irs_account_id),
        { enabled: !!irs_account_id },
    );

    // Just to stave off initial render conditions

    useEffect(() => {
        if (addColumns) {
            let count = 0;

            [...Array(addLimit)].map((_, index) => {
                let addRow = false;

                addColumns.map(({ id_suffix, id_prefix }) => {
                    if (formState[id_prefix + `${index + 1}` + id_suffix]) {
                        addRow = true;
                    }
                });

                if (addRow) {
                    count++;
                }
            });

            setEmptyRows([...Array(count)].map((i, index) => ({ id: `${index + 1}` })));
        }
    }, [formState]);

    const addNewRow = ({ id: entryId }: any) => {
        if (emptyRows.length < (addLimit || Infinity)) {
            if (!emptyRows.find(({ id }) => id === entryId)) {
                setEmptyRows([...emptyRows, { id: entryId }]);
            }
        }
    };

    const setCellValue = ({ id, value }: any) => {
        setFormState({ id, value });
    };

    const defaultRowData = defaultRows.map((item) => {
        if (typeof item[1] !== 'string') {
            const id = item[1].id;
            return {
                label: item[0],
                code: IRSStandardData && IRSStandardData[id],
                id: id,
                value: formState[id],
                error_value: error?.[id],
                overrideBooleanId: item[1].overrideBooleanId,
                overrideBoolean: item[1].overrideBooleanId && formState[item[1].overrideBooleanId],
                overrideValueId: item[1].overrideValueId,
                overrideValue: item[1].overrideValueId && formState[item[1].overrideValueId],
                sharable: formState[id + '_sharable'],
                allowable: formState[id + '_allowable'],
            };
        }
    });

    const totalRowData = totalRow?.map((item) => {
        return {
            label: item.label,
            code: IRSStandardData && IRSStandardData[item.id],
            id: item.id,
            value: formState[item.id],
            error_value: error?.[item.id],
            sharable_id: item.sharable,
            sharable_value: item.sharable && formState[item.sharable],
            allowable_id: item.allowable,
            allowable_value: item.allowable && formState[item.allowable],
            lesserGreaterId: item.lesserGreaterId,
            lesserGreaterValue: item.lesserGreaterId && formState[item.lesserGreaterId],
            isInput: item.isInput,
        };
    });

    const addRowData = emptyRows.map((item, index) => {
        if (typeof item !== 'string') {
            const id_name = addColumns[0].id_prefix + `${index + 1}` + addColumns[0].id_suffix;
            const id_value = addColumns[1].id_prefix + `${index + 1}` + addColumns[1].id_suffix;

            return {
                id_name: id_name,
                value_name: formState[id_name],
                error_name: id_name && error?.[id_name],
                id: id_value,
                value: formState[id_value],
                error_value: error?.[id_value],
                code: IRSStandardData && IRSStandardData[id],
            };
        }
    });

    if (id === 'additional_expense_data') {
    }

    const deleteRow = (deleteIndex: number) => {
        for (let index = 0; index < emptyRows.length - 1; index++) {
            if (deleteIndex <= index) {
                const id_name_current =
                    addColumns[0].id_prefix + `${index + 1}` + addColumns[0].id_suffix;
                const id_name_next =
                    addColumns[0].id_prefix + `${index + 2}` + addColumns[0].id_suffix;

                const id_value_current =
                    addColumns[1].id_prefix + `${index + 1}` + addColumns[1].id_suffix;
                const id_value_next =
                    addColumns[1].id_prefix + `${index + 2}` + addColumns[1].id_suffix;

                setFormState({ id: id_value_current, value: formState[id_value_next] });
                setFormState({ id: id_name_current, value: formState[id_name_next] });
            }
        }

        const id_name_last =
            addColumns[0].id_prefix + `${emptyRows.length - 1 + 1}` + addColumns[0].id_suffix;
        const id_value_last =
            addColumns[1].id_prefix + `${emptyRows.length - 1 + 1}` + addColumns[1].id_suffix;

        setFormState({ id: id_value_last, value: undefined });
        setFormState({ id: id_name_last, value: undefined });

        setEmptyRows([
            ...emptyRows
                .filter(({ id }) => id !== `${deleteIndex + 1}`)
                .map((item, index) => {
                    return { ...item, id: `${index + 1}` };
                }),
        ]);
    };

    return (
        <ParentGroup id={id} className={`col-span-4`}>
            <div className="overflow-y-auto h-full px-0.5">
                <div
                    className="w-full grid mt-2"
                    style={{ gridTemplateColumns: `repeat(14, minmax(0, 1fr))`, minWidth: 500 }}
                >
                    <div
                        className="grid col-span-full bg-gray-700 text-sm px-2 text-white"
                        style={{ gridTemplateColumns: `repeat(14, minmax(0, 1fr))` }}
                    >
                        <div className="col-span-5">{label}</div>
                        <div className="col-span-2 ml-3">{`IRS Standard`}</div>
                        <div className="col-span-2 ml-3">{`Amount`}</div>
                        <div className="col-span-4 ml-3">{`Value used in form`}</div>
                    </div>

                    {defaultRowData.map((data) => (
                        <RowCreator data={data} setCellValue={setCellValue} />
                    ))}

                    {addRowData.map((data, index) => (
                        <RowCreator
                            addRow
                            data={data}
                            setCellValue={setCellValue}
                            deleteRow={() => deleteRow(index)}
                        />
                    ))}

                    {addButtonText && emptyRows.length < (addLimit || Infinity) && (
                        <div
                            onClick={() =>
                                addNewRow({
                                    id: `${emptyRows.length + 1}_${new Date().getMilliseconds()}`,
                                })
                            }
                            className="col-span-full cursor-pointer hover:bg-primary-200 px-2 py-2 bg-primary-100 text-sm"
                        >
                            {addButtonText}
                        </div>
                    )}

                    {totalRow && (
                        <>
                            <div className="col-span-full h-px bg-gray-400 mt-1"></div>
                            {totalRowData?.map((data) => (
                                <RowCreator totalRow setCellValue={setCellValue} data={data} />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </ParentGroup>
    );
};

export default EditableTable;
