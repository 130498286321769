import { upsertBankAccounts } from '../../api/finance';
import { enumSections, fieldTypes } from '../../utils/enums';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const bankAccountsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'company_name',
        label: 'Bank Name',
        placeholder: 'Chase Bank',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'type_of_account',
        label: 'Type',
        options: [
            { value: 'Cash', label: 'Cash' },
            { value: 'Checking', label: 'Checking' },
            { value: 'Savings', label: 'Savings' },
            { value: 'Moneymarket/CD', label: 'Moneymarket/CD' },
            { value: 'Online', label: 'Online' },
            { value: 'Stored Value', label: 'Stored Value' },
            { value: 'Card', label: 'Card' },
        ],
        placeholder: 'Retired',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'account_number',
        label: 'Bank Account number',
        placeholder: 'Chase Bank',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'amount',
        label: 'Value',
        placeholder: '$20,000',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        id: 'company_address',
        label: 'Bank Address',
        placeholder: 'Chase Bank Office, 270 Park Avenue, New York, NY',
        span: 2,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'bank_account_data',
        label: 'Do you have any bank accounts?',
        header: 'Bank Account Details',
        formStructure: bankAccountsForm,
        displayColumns: [
            {
                name: 'Bank Name',
                key: 'company_name',
            },
            {
                name: 'Account Number',
                key: 'account_number',
            },
            {
                name: 'Value',
                key: 'amount',
            },
        ],
        preForm: [
            {
                type: fieldTypes.AMOUNT_INPUT,
                id: 'cash_on_hand',
                label: 'Cash on hand',
                placeholder: '1000',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'bank_account',
    name: 'Bank Accounts',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertBankAccounts,
};
