import { upsertOtherPersonalAssets } from '../../api/finance';
import { usa_states } from '../../utils/constants';
import { enumSections, fieldTypes } from '../../utils/enums';
import { amount, phone } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const otherAssetsForm: FormInterface = [
    {
        type: fieldTypes.TEXT_INPUT,
        area: true,
        id: 'description',
        label: 'Description',
        placeholder: 'Coin Collection',
        span: 2,
    },

    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.CALENDAR,
        id: 'purchase_lease_date',
        label: 'Purchase Date',
        placeholder: 'Aug 2021',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'monthly_payment',
        label: 'Monthly Payment',
        placeholder: '$2000',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'current_loan_balance',
        label: 'Loan Balance',
        placeholder: '12 June 2026',
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'payoff_date',
        label: 'Pay off Date',
        placeholder: '12 June 2026',
    },
    {
        type: fieldTypes.AMOUNT_INPUT,
        id: 'fmv',
        label: 'Fair Market Value',
        placeholder: '$4,780',
    },
    { type: fieldTypes.SEPERATOR, heading: 'Asset Location Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_address',
        area: true,
        span: 2,
        label: 'Address',
        placeholder: `15498 Plantation Oaks Drive`,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_city',
        label: 'City',
        placeholder: `Tampa Bay`,
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'location_state',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_zip',
        label: 'Zip',
        placeholder: '32003',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'location_county',
        label: 'County',
        placeholder: '32003',
    },
    { type: fieldTypes.SEPERATOR },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_name',
        label: 'Lender Name',
        placeholder: 'Jane Doe',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_phone',
        label: 'Lender Phone',
        placeholder: '1234567890',
        formatFunction: phone,
    },
    { type: fieldTypes.SEPERATOR, heading: 'Lender Address' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_address',
        label: 'Address',
        placeholder: `15498 Plantation Oaks Drive`,
        span: 2,
        area: true,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_city',
        label: 'City',
        placeholder: `Tampa Bay`,
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'lender_state',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'lender_zip',
        label: 'ZIP',
        placeholder: '32003',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'qsv',
        label: 'QSV',
        placeholder: '',
        formatFunction: amount,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'equity',
        label: 'Equity',
        placeholder: '',
        formatFunction: amount,
    },
];

const form: FormInterface = [
    {
        type: fieldTypes.CROSSROAD,
        id: 'other_assets_data',
        label: 'Do you have any other personal assets?',
        formStructure: otherAssetsForm,
        header: 'Personal Asset Details',
        displayColumns: [
            {
                name: 'Description',
                key: 'description',
            },

            {
                name: 'QSV',
                key: 'qsv',
            },
        ],
    },
];

export const page: PageInputInterface = {
    address: 'other_personal_assets',
    name: 'Other Personal Assets',

    sectionId: enumSections.FINANCIAL_INFORMATION,
    formStructure: form,

    save: upsertOtherPersonalAssets,
};
