import { apiTaxPrep } from '.';
import { useQuery } from 'react-query';

const useFormsList = (irs_account_id: any) => {
    const get = async (): Promise<any> => {
        const { data } = await apiTaxPrep.get(
            `/TaxprepList?irs_account_id=${irs_account_id.irs_account_id}`,
        );
        return data;
    };

    const { data, isSuccess, isLoading } = useQuery({
        queryKey: ['TaxprepList', irs_account_id],
        queryFn: get,
        staleTime: 1000 * 60,
    });

    data && sessionStorage.setItem('serviceType', data && data?.data?.u_service_type);
    return {
        isSuccess,
        isLoading,
        data: data?.data,
        business: data?.transcriptEntitiesArray?.business,
        years: (data?.data?.taxprep
            ?.map(({ taxprep_year }: any) => taxprep_year)
            ?.sort((a: string, b: string) => parseInt(a) - parseInt(b)) || []) as string[],
    };
};

export default useFormsList;
