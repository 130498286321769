/**
 * UI for MultiForm decider
 */
import classNames from 'classnames';
import ParentGroup from './ParentGroup';
import { FormStateInterface, interface_field_multiform } from '../../utils/interfaces';
import { useState } from 'react';
import { fieldTypes } from '../../utils/enums';
import moment from 'moment';
import FormConstructor from './FormContructor';

type MultiFormProps = interface_field_multiform & {
    className?: string;
    disabled?: boolean;
    formState: FormStateInterface;
    setFormState: ({ id, value }: any) => void;
};

const FormDeterminer: React.FC<MultiFormProps> = ({
    id,
    identifier = 'id',
    formStructure,
    header,
    formState,
    setFormState,
    displayColumns,
    disabled,
}) => {
    const [isMulti, setIsMulti] = useState(false);
    const value = formState[id];

    const oneError = value ? formState._errors?.[id]?.[value[0][identifier]] || {} : {};
    const oneFormState = value ? { ...value[0], _errors: oneError } || {} : {};

    const manyFormState = formState; // formState already has formState._errors
    const manyError = formState._errors;

    const setOne = (state: any) => {
        let put_value = [{ [identifier]: `new_${moment.now()}`, [state.id]: state.value }];

        if (Array.isArray(value) && value?.length) {
            put_value = value.map((item: any, index: number) => {
                if (index === 0) return { ...item, [state.id]: state.value };
                else return item;
            });
        }

        setFormState({ id, value: put_value });
    };

    const setMany = (state: any) => {
        setFormState(state);
    };

    if ((!value || value?.length === 1) && !isMulti) {
        return (
            <>
                <FormConstructor
                    disabled={disabled}
                    structure={[...formStructure]}
                    formState={oneFormState}
                    setFormState={setOne}
                    getOptionValue={() => 'one'}
                    setOptionValue={() => {}}
                />
                <div
                    onClick={() => setIsMulti(true)}
                    className="font-semibold text-sm text-primary-500 pt-1 pb-3"
                >{`+ ADD ANOTHER ADDRESS`}</div>
            </>
        );
    } else {
        return (
            <FormConstructor
                disabled={disabled}
                structure={[
                    {
                        type: fieldTypes.TABLE,
                        id,
                        displayColumns,
                        rowStructure: formStructure,
                        identifier,
                        header,
                    },
                ]}
                formState={manyFormState}
                setFormState={setMany}
                getOptionValue={() => 'one'}
                setOptionValue={() => {}}
            />
        );
    }
};

const MultiForm: React.FC<MultiFormProps> = (props) => {
    const { id, displayColumns, formStructure, className, formState } = props;

    return (
        <ParentGroup className={classNames('col-span-2', className)}>
            {formStructure && displayColumns && <FormDeterminer {...{ ...props }} />}
        </ParentGroup>
    );
};

export default MultiForm;
