import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { interface_field_textInput } from '../../utils/interfaces';
import ParentGroup from './ParentGroup';

/**
 * UI Component for Text Input and Text Area
 **/

type InputGroupProps = interface_field_textInput & {
    className?: string;
    inputType?: string;
    value: string | number;
    error?: string;
    setValue: (str: any) => void;
    onEnter?: (str: any) => void;
    business?: any;
};

const InputGroup: React.FC<InputGroupProps> = (props) => {
    const {
        id,
        value,
        setValue,
        label,
        className,
        inputType: type = 'text',
        area,
        span,
        formatFunction,
        placeholderText,
        onEnter,
        hidden,
        error,
    } = props;

    const [inputValue, setInputValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);
    const [hide, setHide] = useState(!!(hidden && inputValue));

    useEffect(() => {
        if (value !== inputValue) setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (!isFocused && value !== inputValue && inputValue !== undefined) {
            setValue(inputValue);
        }
    }, [inputValue]);

    const handleChange = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        let newValue = e.target.value;

        if (formatFunction) {
            newValue = formatFunction(newValue);
        }

        setInputValue(newValue);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnter) {
            onEnter(inputValue);
        }
    };

    const toggleHide = () => {
        setHide(!hide);
    };

    return (
        <ParentGroup
            id={id}
            className={className}
            label={label}
            value={inputValue}
            error={error}
            styles={{ gridColumn: `span ${span}` }}
        >
            {area ? (
                <textarea
                    id={id + '_input'}
                    className="focus:outline-none hover:bg-gray-50 focus:bg-gray-100 focus:border-primary-500 w-full  border-b border-gray-200"
                    value={value}
                    rows={isFocused ? 3 : 1}
                    onFocus={() => setIsFocused(true)}
                    onBlur={(e) => {
                        setIsFocused(false);
                        setValue(e.target.value);
                    }}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={placeholderText}
                />
            ) : (
                <div className="flex">
                    <input
                        id={id + '_input'}
                        className="focus:outline-none hover:bg-gray-50 focus:bg-gray-100 focus:border-primary-500 w-full  border-b border-gray-200"
                        type={hide ? 'password' : 'text'}
                        value={inputValue}
                        onChange={handleChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={(e) => {
                            setIsFocused(false);
                            setValue(e.target.value);
                        }}
                        placeholder={placeholderText}
                        onKeyPress={handleKeyPress}
                    />
                    {hidden && (
                        <span onClick={toggleHide} className="h-1 cursor-pointer">
                            {hide ? (
                                <span className="material-symbols-outlined">visibility</span>
                            ) : (
                                <span className="material-symbols-outlined">visibility_off</span>
                            )}
                        </span>
                    )}
                </div>
            )}
        </ParentGroup>
    );
};

export default InputGroup;
