import { updateClient } from '../../api/yourInfo';
import { usa_counties, usa_states } from '../../utils/constants';
import { fieldTypes, enumSections } from '../../utils/enums';
import { phone, ssn } from '../../utils/format';
import { FormInterface, PageInputInterface } from '../../utils/interfaces';

const form: FormInterface = [
    { type: fieldTypes.SEPERATOR, title: 'Primary Client Personal Information' },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'first_name',
        label: 'First Name',
        placeholder: 'John',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'last_business_name',
        label: 'Last Name',
        placeholder: 'Doe',
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.CALENDAR,
        id: 'birth_dt',
        label: 'Date of Birth',
        placeholder: '10 August 1998',
        restrict: 'Future',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'email_address',
        label: 'E mail',
        placeholder: 'johndoe@email.com',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'home_phone',
        label: 'Phone',
        placeholder: '(555) 555-1234',
        formatFunction: phone,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'fax_phone',
        label: 'Fax',
        placeholder: '(555) 555-1234',
        formatFunction: phone,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'customer_code',
        label: 'Case ID',
        placeholder: '(555) 555-1234',
    },
    {
        type: fieldTypes.SEPERATOR,
        heading: 'Address',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'address_line_1',
        label: 'Street Name',
        placeholder: `15498 Plantation Oaks Drive`,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'address_line_2',
        label: 'Apartment/unit no.',
        placeholder: `Apt 18`,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'city',
        label: 'City',
        placeholder: `Tampa Bay`,
    },
    {
        type: fieldTypes.DROPDOWN,
        options: usa_states,
        id: 'state_cd',
        label: 'State',
        placeholder: 'Florida',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'zip_code',
        label: 'Zip',
        placeholder: '32003',
    },
    {
        type: fieldTypes.DROPDOWN,
        id: 'county',
        label: 'County',
        placeholder: 'Brevard County',
        options: usa_counties,
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'drivers_license_number',
        label: `Driver's License`,
        placeholder: 'F255-921-50-094-0',
    },
    {
        type: fieldTypes.TEXT_INPUT,
        id: 'ssn_ein',
        label: 'SSN',
        placeholder: '778-62-8144',
        formatFunction: ssn,
        hidden: true,
    },
    {
        type: fieldTypes.SEPERATOR,
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'tp_adults_under_65',
        label: `Adults Under 65`,
        placeholder: 'F255-921-50-094-0',
    },
    {
        type: fieldTypes.TEXT_DISPLAY,
        id: 'tp_adults_over_65',
        label: 'Adults Over 65',
        placeholder: '778-62-8144',
    },
    { type: fieldTypes.TERMINAL },
];

export const page: PageInputInterface = {
    address: 'personal_information',
    name: 'Primary Client Personal Information',
    sectionId: enumSections.YOUR_INFORMATION,

    formStructure: form,
    save: updateClient,
};
